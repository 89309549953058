export const ORDER_SERVICES_NOTES_FETCH_REQUESTED =
  'ORDER_SERVICES_NOTES_FETCH_REQUESTED'
export const ORDER_SERVICES_NOTES_FETCH_SUCCEEDED =
  'ORDER_SERVICES_NOTES_FETCH_SUCCEEDED'
export const ORDER_SERVICES_NOTES_FETCH_FAILED =
  'ORDER_SERVICES_NOTES_FETCH_FAILED'

export const ORDER_SERVICES_NOTES_ADD_FORM_OPEN_REQUESTED =
  'ORDER_SERVICES_NOTES_ADD_FORM_OPEN_REQUESTED'

export const ORDER_SERVICES_NOTES_ADD_FORM_CLOSE_REQUESTED =
  'ORDER_SERVICES_NOTES_ADD_FORM_CLOSE_REQUESTED'

export const ORDER_SERVICES_NOTES_ADD_FORM_SAVE_REQUESTED =
  'ORDER_SERVICES_NOTES_ADD_FORM_SAVE_REQUESTED'
export const ORDER_SERVICES_NOTES_ADD_FORM_SAVE_SUCCEEDED =
  'ORDER_SERVICES_NOTES_ADD_FORM_SAVE_SUCCEEDED'
export const ORDER_SERVICES_NOTES_ADD_FORM_SAVE_FAILED =
  'ORDER_SERVICES_NOTES_ADD_FORM_SAVE_FAILED'
