export const countAppliedFilters = (filters, hiddenFilters = []) => {
  let total = 0
  for (const key in filters) {
    if (filters[key] && hiddenFilters.includes(key)) {
      const value = filters[key]
      if (Array.isArray(value)) {
        total += value.length
      } else {
        if (
          (key === 'period' && value !== 'today') ||
          (key === 'orderBy' && value !== 'checkin') ||
          (key === 'status' && value !== 'all')
        ) {
          total += 1
        }
      }
    }
  }
  return total
}
