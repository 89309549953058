import React from 'react'
import { Redirect, matchPath, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { appRoutes } from 'routes'
import { find, isEmpty } from 'lodash'
import useAppDetector from '@doinn/shared/src/hooks/useAppDetector'
import { useDidMount } from '@doinn/shared/src/hooks'
import { getUserLoggedInAs } from '@doinn/shared/src/containers/user-logged-in-as/actions'
import { getRoutePath } from '@doinn/vendor/src/containers/app/util.js'

const AppRedirects = ({
  getUserLoggedInAs,
  userLoggedInAs,
  location,
  loggedUser
}) => {
  useDidMount(() => {
    getUserLoggedInAs()
  })

  const isApp = useAppDetector()
  const currentPathName = location.pathname
  const loginRoutePath = getRoutePath('Login')
  const recoverPasswordRoutePath = getRoutePath('RecoverPassword')
  const resetPasswordRoutePath = getRoutePath('ResetPassword')
  const servicesRoutePath = getRoutePath('Services')
  // const onboardingRoutePath = getRoutePath('Onboarding')
  // const waitingRoutePath = getRoutePath('Waiting')
  // const dashboardRoutePath = getRoutePath('Dashboard')
  const chatRoutePath = getRoutePath('Messages')
  const hasAppRoute = !!find(appRoutes, route =>
    matchPath(currentPathName, route.path)
  )
  const publicRoutes = [recoverPasswordRoutePath, resetPasswordRoutePath]
  const isPublicRoute = !!find(publicRoutes, path =>
    matchPath(currentPathName, path)
  )

  if (isApp) {
    if (!loggedUser) {
      if (isPublicRoute) {
        return null
      }

      return <Redirect to={loginRoutePath} />
    }

    if (!hasAppRoute) {
      return <Redirect to={servicesRoutePath} />
    }

    if (currentPathName === chatRoutePath && !isEmpty(userLoggedInAs)) {
      return <Redirect to={servicesRoutePath} />
    }
  }

  // try {
  //   const shouldRedirectToWaiting =
  //     !loggedUser.business.isSaas &&
  //     !loggedUser.business.isApproved &&
  //     loggedUser.business.vendorOnboarding.status === 'completed'
  //   const shouldRedirectToOnboarding =
  //     !loggedUser.business.isSaas && !loggedUser.business.isApproved
  //   const shouldRedirectToDashboard =
  //     loggedUser.business.isSaas || loggedUser.business.isApproved

  //   if (shouldRedirectToWaiting) {
  //     if (currentPathName !== waitingRoutePath) {
  //       return <Redirect to={waitingRoutePath} />
  //     }
  //   } else if (shouldRedirectToOnboarding) {
  //     if (currentPathName !== onboardingRoutePath) {
  //       return <Redirect to={onboardingRoutePath} />
  //     }
  //   } else if (shouldRedirectToDashboard) {
  //     if ([onboardingRoutePath, waitingRoutePath].includes(currentPathName)) {
  //       return <Redirect to={dashboardRoutePath} />
  //     }
  //   }
  // } catch (e) {}

  return null
}

const mapStateToProps = state => ({
  loggedUser: state.user.loggedUser,
  userLoggedInAs: state.userLoggedInAs.data
})

const mapDispatchToProps = dispatch => ({
  getUserLoggedInAs: bindActionCreators(getUserLoggedInAs, dispatch)
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(AppRedirects)
