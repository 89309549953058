import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getCatalogueSharedApi } from '@doinn/shared/src/api/catalogues'
import {
  CATALOGUE_GET_SHARED_FAILED,
  CATALOGUE_GET_SHARED_REQUESTED,
  CATALOGUE_GET_SHARED_SUCCEEDED
} from '@doinn/shared/src/containers/catalogue/shared/get/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* getCatalogueShared(action) {
  try {
    const response = yield call(
      getCatalogueSharedApi,
      action.payload.catalogueId
    )
    yield put({
      type: CATALOGUE_GET_SHARED_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: CATALOGUE_GET_SHARED_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* catalogueSharedSaga() {
  yield all([takeLatest(CATALOGUE_GET_SHARED_REQUESTED, getCatalogueShared)])
}
