import React from 'react'
import { Provider } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { history, store } from 'store'
import { pick } from 'lodash'
import {
  API_BASE_URL,
  API_KEY,
  GOOGLE_ANALYTICS,
  PUSHER_CONFIG
} from 'config/env'
import { Provider as EchoProvider } from '@doinn/shared/src/hoc/echo'
import withTracker from '@doinn/shared/src/hoc/tracker/withTracker'
import { setupInterceptorsWithReduxStore } from '@doinn/shared/src/config/doinnApi'
import App from 'containers/app/App'
import Automations from '@doinn/host/src/scenes/Automations'
import AutomationsAdd from '@doinn/host/src/scenes/AutomationsAdd'
import AutomationsEdit from '@doinn/host/src/scenes/AutomationsEdit'
import Catalogues from '@doinn/vendor/src/scenes/Catalogues'
import CatalogueDetails from '@doinn/vendor/src/scenes/CatalogueDetails'
import Chats from 'scenes/Chats'
import Checklists from '@doinn/host/src/scenes/Checklists'
import Checkout from '@doinn/vendor/src/scenes/Checkout'
import CustomerDetails from '@doinn/vendor/src/scenes/CustomerDetails'
import Dashboard from 'scenes/Dashboard'
import Insights from 'scenes/Insights'
import Integrations from '@doinn/host/src/scenes/Integrations'
import IntegrationsEdit from '@doinn/host/src/scenes/IntegrationsEdit'
import Invoice from 'scenes/Invoice'
import Login from 'scenes/Login'
import MemberDetails from '@doinn/vendor/src/scenes/MemberDetails'
import NotFound from 'scenes/NotFound'
import Onboarding from 'scenes/Onboarding'
import Properties from '@doinn/vendor/src/scenes/Properties'
import PropertyDetails from '@doinn/vendor/src/scenes/PropertyDetails'
import ProviderDetails from '@doinn/vendor/src/scenes/ProviderDetails'
import RecoverPassword from 'scenes/RecoverPassword'
import ResetPassword from '@doinn/vendor/src/scenes/ResetPassword'
import Reports from 'scenes/Reports'
import ReportDetails from 'scenes/ReportDetails'
import Services from 'scenes/Services'
import ServicesAdd from '@doinn/host/src/scenes/ServicesAdd'
import ServicesDispatch from 'scenes/ServicesDispatch'
import Staff from 'scenes/Staff'
import UserProfile from 'scenes/UserProfile'
import Waiting from 'scenes/Waiting'
import Billing from '@doinn/vendor/src/scenes/Billing'
import BillingEdit from '@doinn/vendor/src/scenes/BillingEdit'
import BillingAdd from '@doinn/vendor/src/scenes/BillingAdd'
import PaymentEdit from '@doinn/vendor/src/scenes/PaymentEdit'
import PaymentAdd from '@doinn/vendor/src/scenes/PaymentAdd'
import Reservations from '@doinn/vendor/src/scenes/Reservations'
import Plans from '@doinn/vendor/src/scenes/Plans'
import PlanCheckout from '@doinn/vendor/src/scenes/PlanCheckout'
import RegistrationSurvey from '@doinn/vendor/src/scenes/RegistrationSurvey'
import Register from '@doinn/vendor/src/scenes/Register'

const privateRoute = Component => isUserAuthed => {
  const RedirectLogin = props => (
    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  )

  return isUserAuthed ? Component : RedirectLogin
}

const allRoutes = {
  Auth: {
    key: 'RegistrationSurvey',
    name: 'RegistrationSurvey',
    path: '/registration',
    component: () => RegistrationSurvey,
    navbar: true,
    exact: true
  },

  Register: {
    key: 'Register',
    name: 'Register',
    path: '/registration/form',
    component: () => Register,
    navbar: true,
    exact: true
  },

  Login: {
    key: 'Login',
    name: 'Login',
    path: '/login',
    component: () => Login,
    navbar: true,
    exact: true
  },

  RecoverPassword: {
    key: 'RecoverPassword',
    name: 'RecoverPassword',
    path: '/recover-password',
    component: () => RecoverPassword,
    navbar: true,
    exact: true
  },

  ResetPassword: {
    key: 'ResetPassword',
    name: 'ResetPassword',
    path: '/password/reset/:token',
    component: () => ResetPassword,
    navbar: true,
    exact: true
  },

  Messages: {
    key: 'Messages',
    name: 'Messages',
    path: '/messages',
    component: privateRoute(Chats),
    navbar: true,
    exact: true
  },

  Catalogues: {
    key: 'Catalogues',
    name: 'Catalogues',
    path: '/catalogues',
    component: privateRoute(Catalogues),
    navbar: true,
    exact: true,
    allowedPermissions: ['read-catalogues']
  },

  CataloguesTab: {
    key: 'CataloguesTab',
    name: 'CataloguesTab',
    path: '/catalogues/:tab',
    component: privateRoute(Catalogues),
    navbar: true,
    exact: true,
    allowedPermissions: ['read-catalogues']
  },

  CataloguesTabDetails: {
    key: 'CataloguesTabDetails',
    name: 'CataloguesTabDetails',
    path: '/catalogues/:tab/:catalogueId',
    component: privateRoute(CatalogueDetails),
    navbar: true,
    exact: true,
    allowedPermissions: ['read-catalogues']
  },

  CataloguesTabDetailsTabArea: {
    key: 'CataloguesTabDetailsTabArea',
    name: 'CataloguesTabDetailsTabArea',
    path: '/catalogues/:tab/:catalogueId/:tabArea',
    component: privateRoute(CatalogueDetails),
    navbar: true,
    exact: true,
    allowedPermissions: ['read-catalogues']
  },

  Checklists: {
    key: 'Checklists',
    name: 'Checklists',
    path: '/checklists',
    component: privateRoute(Checklists),
    navbar: true,
    exact: true,
    allowedPermissions: ['read-checklists']
  },

  ChecklistsTab: {
    key: 'ChecklistsTab',
    name: 'ChecklistsTab',
    path: '/checklists/:tab',
    component: privateRoute(Checklists),
    navbar: true,
    exact: true,
    allowedPermissions: ['read-checklists']
  },

  Checkout: {
    key: 'Checkout',
    name: 'Checkout',
    path: '/checkout',
    component: privateRoute(Checkout),
    navbar: true,
    exact: true,
    allowedSomePermissions: ['create-checkouts', 'read-checkouts']
  },

  Dashboard: {
    key: 'Dashboard',
    name: 'Dashboard',
    path: '/',
    component: privateRoute(Dashboard),
    navbar: true,
    exact: true
  },

  Services: {
    key: 'Services',
    name: 'Services',
    path: '/services',
    component: privateRoute(Services),
    navbar: true,
    exact: true,
    allowedPermissions: ['read-jobs']
  },

  ServicesAdd: {
    key: 'ServicesAdd',
    name: 'ServicesAdd',
    path: '/services/add',
    component: privateRoute(ServicesAdd),
    navbar: true,
    exact: true,
    allowedPermissions: ['create-jobs']
  },

  ServicesDispatch: {
    key: 'ServicesDispatch',
    name: 'ServicesDispatch',
    path: '/services/dispatch',
    component: privateRoute(ServicesDispatch),
    className: 'services-dispatch-view',
    navbar: false,
    exact: true,
    allowedPermissions: [
      'read-jobs',
      'update-job-estimated-start',
      'update-job-staff'
    ]
  },

  Insights: {
    key: 'Insights',
    name: 'Insights',
    path: '/insights',
    component: privateRoute(Insights),
    navbar: true,
    exact: true,
    allowedPermissions: ['read-reports'],
    allowedSomePermissions: ['read-operational-info', 'read-financial-info']
  },

  InsightsTab: {
    key: 'InsightsTab',
    name: 'InsightsTab',
    path: '/insights/:tab',
    component: privateRoute(Insights),
    navbar: true,
    exact: true,
    allowedPermissions: ['read-reports'],
    allowedSomePermissions: ['read-operational-info', 'read-financial-info']
  },

  Integrations: {
    key: 'Integrations',
    name: 'Integrations',
    path: '/integrations',
    component: privateRoute(Integrations),
    navbar: true,
    exact: true,
    allowedPermissions: ['read-integrations']
  },

  IntegrationsTab: {
    key: 'IntegrationsTab',
    name: 'IntegrationsTab',
    path: '/integrations/:tab',
    component: privateRoute(Integrations),
    navbar: true,
    exact: true,
    allowedPermissions: ['read-integrations']
  },

  IntegrationsEdit: {
    key: 'IntegrationsEdit',
    name: 'IntegrationsEdit',
    path: '/integrations/edit/:id',
    component: privateRoute(IntegrationsEdit),
    allowedPermissions: ['read-user-integrations', 'update-user-integrations']
  },

  Invoice: {
    key: 'Invoices',
    name: 'Invoices',
    path: '/invoices',
    component: privateRoute(Invoice),
    navbar: true,
    exact: true,
    allowedSomePermissions: ['read-sent-invoices', 'read-received-invoices']
  },

  InvoiceTab: {
    key: 'InvoicesTab',
    name: 'InvoicesTab',
    path: '/invoices/:tab',
    component: privateRoute(Invoice),
    navbar: true,
    exact: true,
    allowedSomePermissions: ['read-sent-invoices', 'read-received-invoices']
  },

  Reports: {
    key: 'Reports',
    name: 'Reports',
    path: '/reports',
    component: privateRoute(Reports),
    navbar: true,
    exact: true,
    allowedPermissions: ['read-reports', 'read-financial-info']
  },

  ReportDetails: {
    key: 'ReportDetails',
    name: 'ReportDetails',
    path: '/reports/:reportOf/:reportBy/:id',
    component: privateRoute(ReportDetails),
    navbar: false,
    exact: true,
    allowedPermissions: ['read-reports', 'read-financial-info']
  },

  ReportType: {
    key: 'ReportType',
    name: 'Report Type',
    path: '/reports/:reportOf',
    component: privateRoute(Reports),
    navbar: false,
    exact: true,
    allowedPermissions: ['read-reports', 'read-financial-info']
  },

  Staff: {
    key: 'Staff',
    name: 'Staff',
    path: '/staff',
    component: privateRoute(Staff),
    navbar: true,
    exact: true,
    allowedPermissions: ['read-people']
  },

  StaffType: {
    key: 'StaffType',
    name: 'Staff Type',
    path: '/staff/:tab',
    component: privateRoute(Staff),
    navbar: false,
    exact: true,
    allowedPermissions: ['read-people']
  },

  StaffMemberDetails: {
    key: 'StaffMemberDetails',
    name: 'Staff Type Details',
    path: '/staff/members/:memberId',
    component: privateRoute(MemberDetails),
    navbar: false,
    exact: true,
    allowedSomePermissions: [
      'read-staff',
      'read-employees',
      'read-members',
      'read-people-staff',
      'read-people-admins',
      'read-people-superadmins'
    ]
  },

  StaffMemberDetailsTabArea: {
    key: 'StaffMemberDetailsTabArea',
    name: 'StaffMemberDetailsTabArea',
    path: '/staff/members/:memberId/:tabArea',
    component: privateRoute(MemberDetails),
    navbar: true,
    exact: true,
    allowedSomePermissions: [
      'read-staff',
      'read-employees',
      'read-members',
      'read-people-staff',
      'read-people-admins',
      'read-people-superadmins'
    ]
  },

  StaffCustomerDetails: {
    key: 'StaffCustomerDetails',
    name: 'Staff Type Details',
    path: '/staff/customers/:customerId',
    component: privateRoute(CustomerDetails),
    navbar: false,
    exact: true,
    allowedPermissions: ['read-people-customers']
  },

  StaffCustomerDetailsTabArea: {
    key: 'StaffCustomerDetailsTabArea',
    name: 'StaffCustomerDetailsTabArea',
    path: '/staff/customers/:customerId/:tabArea',
    component: privateRoute(CustomerDetails),
    navbar: true,
    exact: true,
    allowedPermissions: ['read-people-customers']
  },

  StaffProviderDetails: {
    key: 'StaffProviderDetails',
    name: 'Staff Type Details',
    path: '/staff/providers/:providerId',
    component: privateRoute(ProviderDetails),
    navbar: false,
    exact: true,
    allowedPermissions: ['read-people-providers']
  },

  StaffProviderDetailsTabArea: {
    key: 'StaffProviderDetailsTabArea',
    name: 'StaffProviderDetailsTabArea',
    path: '/staff/providers/:providerId/:tabArea',
    component: privateRoute(ProviderDetails),
    navbar: true,
    exact: true,
    allowedPermissions: ['read-people-providers']
  },

  Properties: {
    key: 'Properties',
    name: 'Properties',
    path: '/properties',
    component: privateRoute(Properties),
    navbar: true,
    exact: true,
    allowedPermissions: ['read-spaces']
  },

  PropertyDetails: {
    key: 'PropertyDetails',
    name: 'PropertyDetails',
    path: '/properties/:propertyId',
    component: privateRoute(PropertyDetails),
    navbar: true,
    exact: true,
    allowedPermissions: ['read-spaces']
  },

  PropertyDetailsTabArea: {
    key: 'PropertyDetailsTabArea',
    name: 'PropertyDetailsTabArea',
    path: '/properties/:propertyId/:tabArea',
    component: privateRoute(PropertyDetails),
    navbar: true,
    exact: true,
    allowedPermissions: ['read-spaces']
  },

  Automations: {
    key: 'Automations',
    name: 'Automations',
    path: '/automations',
    component: privateRoute(Automations),
    navbar: true,
    exact: true,
    allowedPermissions: [
      'read-automations',
      'read-catalogs' // TODO: only remove after migrate Laravel
    ]
  },

  AutomationsTab: {
    key: 'AutomationsTab',
    name: 'AutomationsTab',
    path: '/automations/:tab',
    component: privateRoute(Automations),
    navbar: true,
    exact: true,
    allowedPermissions: [
      'read-automations',
      'read-catalogs' // TODO: only remove after migrate Laravel
    ]
  },

  AutomationsAdd: {
    key: 'AutomationsAdd',
    name: 'AutomationsAdd',
    path: '/automations/add/:catalogId',
    component: privateRoute(AutomationsAdd),
    allowedPermissions: [
      'read-automations',
      'read-catalogs', // TODO: only remove after migrate Laravel
      'create-automations'
    ]
  },

  AutomationsEdit: {
    key: 'AutomationsEdit',
    name: 'AutomationsEdit',
    path: '/automations/edit/:automationId',
    component: privateRoute(AutomationsEdit),
    allowedPermissions: ['read-automations', 'update-automations']
  },

  UserProfile: {
    key: 'UserProfile',
    name: 'Profile',
    path: '/profile',
    component: privateRoute(UserProfile),
    navbar: false,
    exact: true
  },

  Billing: {
    key: 'Billing',
    name: 'Billing',
    path: '/billing',
    component: privateRoute(Billing),
    navbar: false,
    exact: true
  },

  BillingTab: {
    key: 'BillingTab',
    name: 'BillingTab',
    path: '/billing/:tab',
    component: privateRoute(Billing),
    navbar: true,
    exact: true,
    allowedSomePermissions: ['read-billings', 'read-payment-methods']
  },

  PaymentAdd: {
    key: 'PaymentAdd',
    name: 'PaymentAdd',
    path: '/payment/add/:gateway',
    component: privateRoute(PaymentAdd),
    navbar: true,
    exact: true,
    allowedSomePermissions: ['create-payment-methods']
  },

  PaymentEdit: {
    key: 'PaymentEdit',
    name: 'PaymentEdit',
    path: '/payment/edit/:gateway',
    component: privateRoute(PaymentEdit),
    navbar: true,
    exact: true,
    allowedSomePermissions: ['update-payment-methods']
  },

  PaymentAddVariant: {
    key: 'PaymentAdd',
    name: 'PaymentAdd',
    path: '/payment/add/:gateway/:variant',
    component: privateRoute(PaymentAdd),
    navbar: true,
    exact: true,
    allowedSomePermissions: ['create-payment-methods']
  },

  PaymentEditVariant: {
    key: 'PaymentEdit',
    name: 'PaymentEdit',
    path: '/payment/edit/:gateway/:variant',
    component: privateRoute(PaymentEdit),
    navbar: true,
    exact: true,
    allowedSomePermissions: ['update-payment-methods']
  },

  BillingEdit: {
    key: 'BillingEdit',
    name: 'BillingEdit',
    path: '/billing/edit/:billingId',
    component: privateRoute(BillingEdit),
    navbar: true,
    exact: true,
    allowedPermissions: ['update-billings']
  },

  BillingAdd: {
    key: 'BillingAdd',
    name: 'BillingAdd',
    path: '/billing/add/:type',
    component: privateRoute(BillingAdd),
    navbar: true,
    exact: true,
    allowedPermissions: ['create-billings']
  },

  Onboarding: {
    key: 'Onboarding',
    name: 'Onboarding',
    path: '/onboarding',
    component: privateRoute(Onboarding),
    navbar: false,
    exact: false
  },

  Waiting: {
    key: 'Waiting',
    name: 'Waiting',
    path: '/waiting',
    component: privateRoute(Waiting),
    navbar: false,
    exact: true
  },

  Reservations: {
    key: 'Reservations',
    name: 'Reservations',
    path: '/reservations',
    component: privateRoute(Reservations),
    navbar: true,
    exact: true,
    allowedPermissions: ['read-stays']
  },

  Plans: {
    key: 'Plans',
    name: 'Plans',
    path: '/plans',
    component: privateRoute(Plans),
    navbar: true,
    exact: true
  },

  PlansCheckout: {
    key: 'Plans',
    name: 'Plans',
    path: '/plans/checkout',
    component: privateRoute(PlanCheckout),
    navbar: true,
    exact: true
  },

  NotFound: {
    key: 'NotFound',
    name: 'Page not found',
    path: '*',
    component: privateRoute(NotFound)
  }
}

const appRoutes = pick(allRoutes, [
  'Login',
  'RecoverPassword',
  'ResetPassword',
  'Services',
  'ServicesAdd',
  'Staff',
  'StaffType',
  'StaffMemberDetails',
  'StaffMemberDetailsTabArea',
  'StaffCustomerDetails',
  'StaffCustomerDetailsTabArea',
  'StaffProviderDetails',
  'StaffProviderDetailsTabArea',
  'Messages',
  'PriceList',
  'Properties',
  'PropertyDetails',
  'PropertyDetailsTabArea',
  'UserProfile',
  'Checkout',
  'Waiting',
  'PaymentAdd',
  'PaymentEdit',
  'PaymentAddVariant',
  'PaymentEditVariant'
])

const siteRoutes = pick(allRoutes, [
  'Auth',
  'Register',
  'Login',
  'RecoverPassword',
  'ResetPassword',
  'Dashboard',
  'Services',
  'ServicesAdd',
  'ServicesDispatch',
  'Insights',
  'InsightsTab',
  'Integrations',
  'IntegrationsTab',
  'IntegrationsEdit',
  'Invoice',
  'InvoiceTab',
  'Reports',
  'ReportDetails',
  'ReportType',
  'Staff',
  'StaffType',
  'StaffMemberDetails',
  'StaffMemberDetailsTabArea',
  'StaffCustomerDetails',
  'StaffCustomerDetailsTabArea',
  'StaffProviderDetails',
  'StaffProviderDetailsTabArea',
  'Messages',
  'PriceList',
  'Properties',
  'PropertyDetails',
  'PropertyDetailsTabArea',
  'Automations',
  'AutomationsTab',
  'AutomationsAdd',
  'AutomationsEdit',
  'UserProfile',
  'Billing',
  'BillingTab',
  'BillingEdit',
  'BillingAdd',
  'Onboarding',
  'Waiting',
  'Catalogues',
  'CataloguesTab',
  'CataloguesTabDetails',
  'CataloguesTabDetailsTabArea',
  'Checklists',
  'ChecklistsTab',
  'Checkout',
  'Reservations',
  'PaymentAdd',
  'PaymentEdit',
  'PaymentAddVariant',
  'PaymentEditVariant',
  'Plans',
  'PlansCheckout',
  'NotFound'
])

const Routes = () => (
  <Provider store={store}>
    <EchoProvider
      apiKey={API_KEY}
      apiBaseUrl={API_BASE_URL}
      config={PUSHER_CONFIG}
    >
      <ConnectedRouter history={history}>
        <Route component={withTracker(App, GOOGLE_ANALYTICS, {})} />
      </ConnectedRouter>
    </EchoProvider>
  </Provider>
)

setupInterceptorsWithReduxStore(store)

export { allRoutes, appRoutes, siteRoutes }
export default Routes
