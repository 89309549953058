import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function fetchMembersInvitationsApi(params = {}) {
  const config = {
    ...params
  }
  config.params = { accepted: false, ...config.params }

  return DoinnApi.get(`/people/invitations`, config)
}

export function addMembersInvitationsApi(invitations) {
  const config = {}
  return DoinnApi.post(`/people/invitations/members`, invitations, config)
}

export function importMembersApi(params) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  const formData = new window.FormData()
  const { file } = params

  if (file) {
    formData.append('file', file)
  }

  return DoinnApi.post(`/people/invitations/members/import`, formData, config)
}

export function addProvidersInvitationsApi(invitations) {
  const config = {}
  return DoinnApi.post(`/people/invitations/providers`, { invitations }, config)
}

export function addCustomersInvitationsApi(invitations) {
  const config = {}
  return DoinnApi.post(`/people/invitations/customers`, { invitations }, config)
}

export function deleteMemberInvitationApi(id) {
  const config = {}
  return DoinnApi.delete(`/people/invitations/${id}`, config)
}

export function resendMemberInvitationApi(id) {
  const config = {}
  return DoinnApi.patch(`/people/invitations/${id}/resend`, config)
}

export function refuseInvitationApi(id) {
  const config = {}
  return DoinnApi.patch(`/people/invitations/${id}/refuse`, config)
}

export function acceptInvitationApi(id) {
  const config = {}
  return DoinnApi.patch(`/people/invitations/${id}/accept`, config)
}

export function fetchMembersAvailableRolesApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/people/invitations/members/available-roles`, config)
}
