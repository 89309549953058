import { createSearchAction } from 'redux-search'
import {
  TEAMS_FETCH_REQUESTED,
  TEAMS_UPDATE_SELECTED_TEAM_REQUESTED
} from '@doinn/vendor/src/containers/staff/teams/constants'

export function fetchTeams(params) {
  return { type: TEAMS_FETCH_REQUESTED, payload: { params } }
}

export function updateSelectedTeams(teams) {
  return { type: TEAMS_UPDATE_SELECTED_TEAM_REQUESTED, payload: [...teams] }
}

export const searchTeams = createSearchAction('teams')
