import { createSelector } from 'reselect'
import { sortBy, uniqBy } from 'lodash'

const hasId = item => !!item.id

const extractJobProperty = job => ({
  id: job.space.id,
  name: job.space.name
})

const extractJobHost = job => ({
  id: job.space.user.id,
  name: job.space.user.name
})

const extractJobHostBusinessAccount = job => ({
  id: job.space?.businessAccount?.id,
  name: job.space?.businessAccount?.name
})

const extractJobTypology = job => {
  return {
    id: job.space.spaceTypology.id,
    name: job.space.spaceTypology.name
  }
}

const getJobsList = state => state.jobs.list

export const getListedJobsProperties = createSelector([getJobsList], jobs => {
  const allProperties = jobs.map(extractJobProperty)
  const uniqueProperties = uniqBy(allProperties, 'id')
  const sortedProperties = sortBy(uniqueProperties, 'name')
  return sortedProperties.filter(hasId)
})

export const getListedJobsHosts = createSelector([getJobsList], jobs => {
  const allHosts = jobs.map(extractJobHost)
  const uniqueHosts = uniqBy(allHosts, 'id')
  const sortedHosts = sortBy(uniqueHosts, 'name')
  return sortedHosts.filter(hasId)
})

export const getListedJobsHostBusinessAccounts = createSelector(
  [getJobsList],
  jobs => {
    const allHostBusinessAccounts = jobs.map(extractJobHostBusinessAccount)
    const uniqueHostBusinessAccounts = uniqBy(allHostBusinessAccounts, 'id')
    const sortedHostBusinessAccounts = sortBy(
      uniqueHostBusinessAccounts,
      'name'
    )
    return sortedHostBusinessAccounts.filter(hasId)
  }
)

export const getListedJobsTypologies = createSelector([getJobsList], jobs => {
  const allTypologies = jobs.map(extractJobTypology)
  const uniqueTypologies = uniqBy(allTypologies, 'id')
  const sortedTypologies = sortBy(uniqueTypologies, 'name')
  return sortedTypologies.filter(hasId)
})
