import React from 'react'
import Cookies from 'js-cookie'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { Redirect, Route, Switch, matchPath } from 'react-router-dom'
import { isEqual as isObjEqual, toArray } from 'lodash'
import { withTranslation } from 'react-i18next'
import { appRoutes, siteRoutes } from 'routes'
import { BASE_URL } from 'config/env'
import { getICULocale } from '@doinn/shared/src/util/getLocale'
import { start } from '@doinn/shared/src/containers/app/actions'
import AppRedirects from 'containers/app/AppRedirects'
import TemplateBlank from '@doinn/shared/src/components/templates/TemplateBlank'
import SnackbarContainer from '@doinn/shared/src/containers/snackbar/Snackbar'
import AppLoading from '@doinn/shared/src/components/app/AppLoading'
import AppErrorBoundary from '@doinn/shared/src/components/app/AppErrorBoundary'
import HubspotConversationsProvider from '@doinn/vendor/src/containers/support/SupportChat'
import withAuthorization from '@doinn/shared/src/hoc/authorization/withAuthorization'
import withAppDetector from '@doinn/shared/src/hoc/appDetector/withAppDetector'
import NestedDialogs from '@doinn/shared/src/components/app/NestedDialogs'
import NewReleaseNotification from '@doinn/shared/src/components/app/NewReleaseNotification'
import UnreadMessagesCounterListener from '@doinn/shared/src/containers/chats/UnreadMessagesCounterListener'
import NotificationCounterListener from '@doinn/shared/src/containers/notifications/NotificationCounterListener'
import PushNotificationRegister from '@doinn/shared/src/containers/device/PushNotificationRegister'
import FloatingOnboardingListener from '@doinn/shared/src/containers/onboarding/floating/components/FloatingOnboardingListener'
import SubscriptionUpdatedListener from '@doinn/shared/src/containers/subscription/components/SubscriptionListener'
import { getQueryStringObject } from '@doinn/shared/src/util/url'
import { getAllParamsButParam } from '@doinn/shared/src/util/object'
import {
  countTabsOpended,
  getRoutePath
} from '@doinn/vendor/src/containers/app/util'
import AppUrlListener from '@doinn/vendor/src/containers/app/AppUrlListener'

const getLogoutSiteUrl = (locale = 'en') =>
  `${BASE_URL}/${getICULocale(locale)}/auth/logout`

class App extends React.Component {
  componentDidMount() {
    if (!this.props.isApp) {
      countTabsOpended()
    }
    this.props.onStart()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const checkPathname = pathname => {
        return (
          prevProps.location.pathname === pathname &&
          this.props.location.pathname === pathname
        )
      }
      let scroll = true
      if (checkPathname('/services') || checkPathname('/services/dispatch')) {
        const previousParams = getAllParamsButParam(
          getQueryStringObject(prevProps.location.search),
          'jobId'
        )
        const currentParams = getAllParamsButParam(
          getQueryStringObject(this.props.location.search),
          'jobId'
        )
        scroll = !isObjEqual(previousParams, currentParams)
      } else if (checkPathname(getRoutePath('Dashboard'))) {
        scroll = false
      }
      if (scroll) {
        window.scrollTo(0, 0)
      }
    }
  }

  render() {
    const {
      isApp,
      i18n,
      loggedUser,
      isLoadingApp,
      isLoadingUser,
      userHasLoggedOut
    } = this.props
    const isSite = !isApp
    const isLoading = isLoadingApp || isLoadingUser
    const publicRoutes = [
      getRoutePath('Login'),
      getRoutePath('RecoverPassword'),
      getRoutePath('ResetPassword'),
      getRoutePath('RegistrationSurvey'),
      getRoutePath('Register')
    ]
    const isPublicRoute = !!publicRoutes.find(path =>
      matchPath(this.props.location?.pathname, path)
    )

    let routes = isApp ? appRoutes : siteRoutes
    routes = toArray(routes).filter(
      route =>
        (route.allowedPermissions === undefined ||
          this.props.canAll(route.allowedPermissions)) &&
        (route.allowedSomePermissions === undefined ||
          this.props.canAny(route.allowedSomePermissions))
    )

    if (isSite && userHasLoggedOut && !isPublicRoute) {
      window.location.href = getLogoutSiteUrl(i18n.language)
    }

    if (isLoading || (isSite && !loggedUser && !isPublicRoute)) {
      return <AppLoading />
    }

    return (
      <TemplateBlank>
        <HubspotConversationsProvider>
          <Switch>
            {routes.map(route => (
              <AppErrorBoundary {...route} key={`${route.key}-bond`}>
                <Route
                  key={route.key}
                  path={route.path}
                  component={route.component(!!loggedUser)}
                  exact={!!route.exact}
                />
              </AppErrorBoundary>
            ))}
          </Switch>
        </HubspotConversationsProvider>
        <AppRedirects />
        <NotificationCounterListener />
        <UnreadMessagesCounterListener />
        <SnackbarContainer />
        <NewReleaseNotification />
        {loggedUser && (
          <>
            <PushNotificationRegister />
            <NestedDialogs />
            <FloatingOnboardingListener />
            <SubscriptionUpdatedListener />
          </>
        )}
        {isApp && <AppUrlListener routes={routes} loggedUser={loggedUser} />}
      </TemplateBlank>
    )
  }
}

const mapStateToProps = state => ({
  isLoadingApp: state.app.isLoadingApp,
  isLoadingUser: state.user.isLoadingLoggedUser,
  loggedUser: state.user.loggedUser,
  userHasLoggedOut: state.user.userHasLoggedOut
})

const mapDispatchToProps = dispatch => ({
  onStart: bindActionCreators(start, dispatch)
})

export default compose(
  withAuthorization,
  withAppDetector,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(App)
