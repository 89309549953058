import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function getTeamApi(id) {
  const config = {}
  return DoinnApi.get(`/vendor/teams/${id}`, config)
}

export function addTeamApi(team) {
  const config = {}
  team.employees = team.employees.map(employee => employee.id)
  return DoinnApi.post(`vendor/teams`, team, config)
}

export function editTeamApi(team) {
  const config = {}
  team.employees = team.employees.map(employee => employee.id)
  return DoinnApi.put(`/vendor/teams/${team.id}`, team, config)
}

export function deleteTeamApi(id) {
  const config = {}
  return DoinnApi.delete(`/vendor/teams/${id}`, config)
}
