import {
  PROPERTIES_IMPORT_DIALOG_CLOSE,
  PROPERTIES_IMPORT_DIALOG_OPEN,
  PROPERTIES_IMPORT_FAILED,
  PROPERTIES_IMPORT_REQUESTED,
  PROPERTIES_IMPORT_SUCCEEDED
} from '@doinn/shared/src/containers/property/import/constants'

export const STATUS = {
  IDLE: 'idle',
  SAVING: 'saving',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  lastImportItem: null,
  error: null,
  isOpened: false,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case PROPERTIES_IMPORT_DIALOG_OPEN:
      return {
        ...initialState,
        isOpened: true
      }
    case PROPERTIES_IMPORT_DIALOG_CLOSE:
      return {
        ...initialState,
        isOpened: false
      }
    case PROPERTIES_IMPORT_REQUESTED:
      return {
        ...state,
        lastImportItem: null,
        error: null,
        status: STATUS.SAVING
      }
    case PROPERTIES_IMPORT_SUCCEEDED:
      return {
        ...state,
        lastImportItem: { ...payload },
        status: STATUS.SUCCESS
      }
    case PROPERTIES_IMPORT_FAILED:
      return {
        ...state,
        lastImportItem: null,
        error: { ...payload.error },
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
