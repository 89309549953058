import React, { useCallback, useMemo } from 'react'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core'
import { Formik } from 'formik'
import { isEmpty, omit } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { usePrevious } from '@doinn/shared/src/hooks'
import {
  getMetadataFromQuestions,
  getOptionsByType
} from '@doinn/shared/src/containers/onboarding/survey/util'
import {
  close,
  onboardingSurvey
} from '@doinn/shared/src/containers/onboarding/survey/actions'
import {
  onboardingSurveyCustomerSchema,
  onboardingSurveyProviderSchema
} from '@doinn/shared/src/containers/onboarding/survey/validation'
import { STATUS } from '@doinn/shared/src/containers/onboarding/survey/reducers'
import Dialog from '@doinn/shared/src/components/common/dialog/Dialog'
import DialogHeader from '@doinn/shared/src/components/common/dialog/DialogHeader'
import ButtonWithLoading from '@doinn/shared/src/components/common/ButtonWithLoading'
import SurveyForm from '@doinn/shared/src/containers/onboarding/survey/components/SurveyForm'

export const INITIAL_VALUES_STATE = {
  numberProperties: '',
  likeToDo: [],
  servicesPerMonth: '',
  automatedWorkflow: [],
  companyName: '',
  step: 1
}

const NUMBER_OF_STEPS = 2

const useStyles = makeStyles(theme => {
  return {
    dialogContent: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(0),
      '&::-webkit-scrollbar': {
        width: '7px'
      },

      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1'
      },

      '&::-webkit-scrollbar-thumb': {
        background: '#888'
      }
    },
    header: {
      paddingBottom: theme.spacing(0),
      marginBottom: theme.spacing(0)
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-start'
    },
    title: {
      fontSize: theme.typography.pxToRem(36)
    }
  }
})

const SurveyDialog = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { loggedUser } = useSelector(state => state.user)
  const { isOpened, status } = useSelector(state => state.onboardingSurvey)
  const { data: userLoggedInAs } = useSelector(state => state.userLoggedInAs)
  const [initialValues, setInitialValues] = React.useState({
    ...INITIAL_VALUES_STATE
  })
  const previousIsOpened = usePrevious(isOpened)

  const isSaving = status === STATUS.LOADING
  const isProviderSetup = ['provider'].includes(
    loggedUser?.business?.setupOnboarding?.type
  )
  const validationSchema = isProviderSetup
    ? onboardingSurveyProviderSchema
    : onboardingSurveyCustomerSchema

  React.useEffect(() => {
    if (previousIsOpened !== isOpened) {
      setInitialValues(INITIAL_VALUES_STATE)
    }
  }, [isOpened, previousIsOpened])

  const questionsArray = useMemo(() => {
    const setupOnboarding = loggedUser?.business?.setupOnboarding || {}
    let setUpQuestions = getOptionsByType(setupOnboarding?.type)

    if (setupOnboarding?.invited) {
      setUpQuestions = setUpQuestions.map(question => ({
        ...question,
        options: question.options.filter(option => !option?.removeInvited)
      }))
    }

    return setUpQuestions
  }, [loggedUser?.business])

  const ctaText = useCallback(
    step => {
      if (step === NUMBER_OF_STEPS) {
        return t('Save and start using Doinn')
      }

      return t('Continue')
    },
    [t]
  )

  const handleClose = useCallback(
    (event, reason) => {
      if (reason && reason === 'backdropClick') return
      dispatch(close())
    },
    [dispatch]
  )

  const handleClick = useCallback(
    formProps => () => {
      if (formProps?.values?.step === NUMBER_OF_STEPS) {
        formProps?.handleSubmit()
      } else {
        formProps?.setFieldValue('step', formProps?.values?.step + 1)
      }
    },
    []
  )

  const handleCloseClick = useCallback(() => {
    dispatch(close())
  }, [dispatch])

  const handleClickSave = useCallback(
    values => {
      const omitFields = ['companyName', 'step']
      if (isProviderSetup) {
        omitFields.push('automatedWorkflow')
      }

      const data = {
        metadata: getMetadataFromQuestions(
          questionsArray,
          omit(values, omitFields)
        ),
        companyName: values?.companyName
      }
      dispatch(onboardingSurvey(data))
    },
    [dispatch, isProviderSetup, questionsArray]
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleClickSave}
      enableReinitialize
    >
      {formProps => (
        <Dialog open={isOpened} onClose={handleClose} disableEscapeKeyDown>
          <DialogHeader
            classname={classes.header}
            overline={`${formProps?.values?.step}/${NUMBER_OF_STEPS}`}
            title={
              <Typography component='div' className={classes.title}>
                <b>{t('Just a few more details')}</b>
              </Typography>
            }
            subtitle={
              <>
                <Typography component='div' variant='body1'>
                  {`${t(
                    'Help us make your experience even better by telling us'
                  )}:`}
                </Typography>
                <Box py={2}>
                  <Divider />
                </Box>
              </>
            }
          />
          <DialogContent className={classes.dialogContent}>
            <SurveyForm formProps={formProps} questionsArray={questionsArray} />
          </DialogContent>
          <DialogActions className={classes.actions}>
            {!isEmpty(userLoggedInAs) && (
              <Button variant='outlined' onClick={handleCloseClick}>
                {t('Close')}
              </Button>
            )}
            <Box>
              <ButtonWithLoading
                aria-label={ctaText(formProps?.values?.step)}
                variant='contained'
                color='primary'
                edge='end'
                fullWidth
                isLoading={isSaving}
                disabled={!formProps.isValid || isSaving}
                onClick={handleClick(formProps)}
              >
                {ctaText(formProps?.values?.step)}
              </ButtonWithLoading>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

export default SurveyDialog
