import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  downloadPriceListApi,
  getCatalogueMyApi,
  getCatalogueMyItemsApi
} from '@doinn/shared/src/api/catalogues'
import {
  CATALOGUE_GET_MY_FAILED,
  CATALOGUE_GET_MY_ITEMS_FAILED,
  CATALOGUE_GET_MY_ITEMS_REQUESTED,
  CATALOGUE_GET_MY_ITEMS_SUCCEEDED,
  CATALOGUE_GET_MY_PRICE_LIST_DOWNLOAD_FAILED,
  CATALOGUE_GET_MY_PRICE_LIST_DOWNLOAD_REQUESTED,
  CATALOGUE_GET_MY_PRICE_LIST_DOWNLOAD_SUCCEEDED,
  CATALOGUE_GET_MY_REQUESTED,
  CATALOGUE_GET_MY_SUCCEEDED
} from '@doinn/shared/src/containers/catalogue/my/get/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* getCatalogueMy(action) {
  try {
    const response = yield call(getCatalogueMyApi, action.payload.catalogueId)
    yield put({
      type: CATALOGUE_GET_MY_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: CATALOGUE_GET_MY_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* getCatalogueMyItems(action) {
  try {
    const response = yield call(
      getCatalogueMyItemsApi,
      action.payload.catalogueId
    )
    yield put({
      type: CATALOGUE_GET_MY_ITEMS_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: CATALOGUE_GET_MY_ITEMS_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* downloadPriceList(action) {
  try {
    const { params } = action.payload
    const name = params?.name ?? ''
    const reportName = `doinn_pricing_${name}.xls`
    const { data } = yield call(downloadPriceListApi, params)

    yield put({
      type: CATALOGUE_GET_MY_PRICE_LIST_DOWNLOAD_SUCCEEDED,
      snackbar: {
        variant: 'success',
        message: i18n.t('The prices file have been downloaded successfully')
      },
      downloader: {
        data,
        type: 'application/vnd.ms-excel',
        name: reportName
      }
    })
  } catch (e) {
    yield put({
      type: CATALOGUE_GET_MY_PRICE_LIST_DOWNLOAD_FAILED,
      snackbar: {
        variant: 'error',
        message: e.message
      }
    })
  }
}

export default function* catalogueMySaga() {
  yield all([
    takeLatest(CATALOGUE_GET_MY_REQUESTED, getCatalogueMy),
    takeLatest(CATALOGUE_GET_MY_ITEMS_REQUESTED, getCatalogueMyItems),
    takeLatest(
      CATALOGUE_GET_MY_PRICE_LIST_DOWNLOAD_REQUESTED,
      downloadPriceList
    )
  ])
}
