import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getCatalogApi } from '@doinn/host/src/api/catalogs'
import {
  CATALOG_GET_FAILED,
  CATALOG_GET_REQUESTED,
  CATALOG_GET_SUCCEEDED
} from '@doinn/host/src/containers/catalog/get/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* getCatalog(action) {
  try {
    const params = action.payload
    const response = yield call(getCatalogApi, params)
    yield put({
      type: CATALOG_GET_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: CATALOG_GET_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* catalogSaga() {
  yield all([takeLatest(CATALOG_GET_REQUESTED, getCatalog)])
}
