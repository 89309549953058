import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function fetchMembersApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/business/members`, config)
}

export function deleteMemberApi(id) {
  const config = {}
  return DoinnApi.delete(`/business/members/${id}`, config)
}
