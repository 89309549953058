import { each, isArray, snakeCase } from 'lodash'
import i18n from '@doinn/shared/src/config/i18n'

const getCustomerArray = () => [
  {
    key: 'What is the number of properties your company manage/operates?',
    title: i18n.t(
      'What is the number of properties your company manage/operates?'
    ),
    options: [
      {
        key: '1-2',
        description: '1-2',
        value: '1-2'
      },
      {
        key: '3-10',
        description: '3-10',
        value: '3-10'
      },
      {
        key: '11-50',
        description: '11-50',
        value: '11-50'
      },
      {
        key: '51-200',
        description: '51-200',
        value: '51-200'
      },
      {
        key: '201-500',
        description: '201-500',
        value: '201-500'
      },
      {
        key: '500+',
        description: '500+',
        value: '500+'
      }
    ],
    name: 'numberProperties',
    optionsType: 'radio',
    step: 1
  },
  {
    key: 'What would you like to do with Doinn? You can select multiple choices.',
    title: i18n.t(
      'What would you like to do with Doinn? You can select multiple choices.'
    ),
    options: [
      {
        key: 'Professional Cleaning Outsourcing',
        description: i18n.t('Professional Cleaning Outsourcing'),
        value: 'marketplace',
        removeInvited: true
      },
      {
        key: 'Organize my internal teams more efficiently',
        description: i18n.t('Organize my internal teams more efficiently'),
        value: 'members'
      },
      {
        key: 'Organize better all service providers and automate their jobs in one platform',
        description: i18n.t(
          'Organize better all service providers and automate their jobs in one platform'
        ),
        value: 'externalTeams'
      }
    ],
    name: 'likeToDo',
    optionsType: 'checkbox',
    step: 1
  },
  {
    key: 'What is the number of services per month you are planning to operate in Doinn platform (approximately)',
    title: i18n.t(
      'What is the number of services per month you are planning to operate in Doinn platform (approximately)'
    ),
    options: [
      {
        key: '0-10',
        description: i18n.t('{{range}} services', { range: '0-10' }),
        value: '0-10'
      },
      {
        key: '10-100',
        description: i18n.t('{{range}} services', { range: '10-100' }),
        value: '10-100'
      },
      {
        key: '100-1000',
        description: i18n.t('{{range}} services', { range: '100-1000' }),
        value: '100-1000'
      },
      {
        key: '1000+',
        description: i18n.t('{{range}} services', { range: '1000+' }),
        value: '1000+'
      }
    ],
    name: 'servicesPerMonth',
    optionsType: 'radio',
    step: 1
  },
  {
    key: 'Do you need to automate workflows based in hospitality reservations or recurrent dates? You can select multiple choices.',
    title: i18n.t(
      'Do you need to automate workflows based in hospitality reservations or recurrent dates? You can select multiple choices.'
    ),
    options: [
      {
        key: 'Reservations',
        description: i18n.t('Reservations'),
        value: 'reservation'
      },
      {
        key: 'Recurrent',
        description: i18n.t('Recurrent'),
        value: 'recurrency'
      }
    ],
    name: 'automatedWorkflow',
    optionsType: 'checkbox',
    step: 1
  },
  {
    key: 'companyName',
    title: i18n.t('Company name'),
    options: [],
    name: 'companyName',
    optionsType: 'text',
    step: 2
  }
]

const getServiceProviderArray = () => [
  {
    key: 'What is the number of properties your company provide services to?',
    title: i18n.t(
      'What is the number of properties your company provide services to?'
    ),
    options: [
      {
        key: '1-10',
        description: '1-10',
        value: '1-10'
      },
      {
        key: '11-50',
        description: '11-50',
        value: '11-50'
      },
      {
        key: '51-200',
        description: '51-200',
        value: '51-200'
      },
      {
        key: '201-500',
        description: '201-500',
        value: '201-500'
      },
      {
        key: '500+',
        description: '500+',
        value: '500+'
      }
    ],
    name: 'numberProperties',
    optionsType: 'radio',
    step: 1
  },
  {
    key: 'What would you like to do with Doinn? You can select multiple choices.',
    title: i18n.t(
      'What would you like to do with Doinn? You can select multiple choices.'
    ),
    options: [
      {
        key: 'Go digital & Sell more services by having Doinn sending me customers',
        description: i18n.t(
          'Go digital & Sell more services by having Doinn sending me customers'
        ),
        value: 'marketplace'
      },
      {
        key: 'Organize my internal teams more efficiently',
        description: i18n.t('Organize my internal teams more efficiently'),
        value: 'members'
      },
      {
        key: 'Get all customers and jobs automatically scheduled in one platform',
        description: i18n.t(
          'Get all customers and jobs automatically scheduled in one platform'
        ),
        value: 'externalTeams'
      }
    ],
    name: 'likeToDo',
    optionsType: 'checkbox',
    step: 1
  },
  {
    key: 'What is the number of services per month you are planning to operate in Doinn platform (approximately)',
    title: i18n.t(
      'What is the number of services per month you are planning to operate in Doinn platform (approximately)'
    ),
    options: [
      {
        key: '0-10',
        description: i18n.t('{{range}} services', { range: '0-10' }),
        value: '0-10'
      },
      {
        key: '10-100',
        description: i18n.t('{{range}} services', { range: '10-100' }),
        value: '10-100'
      },
      {
        key: '100-1000',
        description: i18n.t('{{range}} services', { range: '100-1000' }),
        value: '100-1000'
      },
      {
        key: '1000+',
        description: i18n.t('{{range}} services', { range: '1000+' }),
        value: '1000+'
      }
    ],
    name: 'servicesPerMonth',
    optionsType: 'radio',
    step: 1
  },
  {
    key: 'companyName',
    title: i18n.t('Company name'),
    options: [],
    name: 'companyName',
    optionsType: 'text',
    step: 2
  }
]

export const getOptionsByType = type => {
  const options = {
    customer: getCustomerArray(),
    provider: getServiceProviderArray()
  }

  if (type in options) {
    return options[type]
  }

  return options.customer
}

export const getMetadataFromQuestions = (questionsArray, anwsers) => {
  const metadata = []
  let question = null
  let values = null
  let metaAwnsers = null
  let option = null
  each(anwsers, (val, key) => {
    question = questionsArray.find(q => q.name === key)
    values = isArray(val) ? val : [val]
    metaAwnsers = []
    values.forEach(value => {
      option = question.options.find(option => option.value === value)
      if (option) {
        metaAwnsers.push({
          answer: option.key,
          answer_slug: snakeCase(option.value)
        })
      }
    })
    metadata.push({
      question: question.key,
      question_slug: snakeCase(question.name),
      answers: metaAwnsers
    })
  })
  return metadata
}
