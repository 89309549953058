export const JOB_EXTRA_SERVICES_FETCH_REQUESTED =
  'JOB_EXTRA_SERVICES_FETCH_REQUESTED'
export const JOB_EXTRA_SERVICES_FETCH_SUCCEEDED =
  'JOB_EXTRA_SERVICES_FETCH_SUCCEEDED'
export const JOB_EXTRA_SERVICES_FETCH_FAILED = 'JOB_EXTRA_SERVICES_FETCH_FAILED'

export const JOB_EXTRA_SERVICE_ADD_FORM_OPEN_REQUESTED =
  'JOB_EXTRA_SERVICE_ADD_FORM_OPEN_REQUESTED'

export const JOB_EXTRA_SERVICE_ADD_FORM_CLOSE_REQUESTED =
  'JOB_EXTRA_SERVICE_ADD_FORM_CLOSE_REQUESTED'

export const JOB_EXTRA_SERVICE_ADD_FORM_SAVE_REQUESTED =
  'JOB_EXTRA_SERVICE_ADD_FORM_SAVE_REQUESTED'
export const JOB_EXTRA_SERVICE_ADD_FORM_SAVE_SUCCEEDED =
  'JOB_EXTRA_SERVICE_ADD_FORM_SAVE_SUCCEEDED'
export const JOB_EXTRA_SERVICE_ADD_FORM_SAVE_FAILED =
  'JOB_EXTRA_SERVICE_ADD_FORM_SAVE_FAILED'
