import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { addJobApi } from '@doinn/host/src/api/job'
import {
  JOB_ADD_FAILED,
  JOB_ADD_REQUESTED,
  JOB_ADD_SUCCEEDED
} from '@doinn/host/src/containers/job/add/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* addJob(action) {
  try {
    const { job } = action.payload
    const { data } = yield call(addJobApi, job)
    const payload = {
      ...data,
      job
    }

    yield put({
      type: JOB_ADD_SUCCEEDED,
      payload,
      snackbar: {
        variant: 'success',
        message: i18n.t('The service has been created successfully')
      }
    })
  } catch (e) {
    yield put({
      type: JOB_ADD_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* jobsAddSaga() {
  yield all([takeLatest(JOB_ADD_REQUESTED, addJob)])
}
