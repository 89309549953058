import { isEmpty } from 'lodash'
import {
  JOB_ADD_FAILED,
  JOB_ADD_REQUESTED,
  JOB_ADD_SUCCEEDED
} from '@doinn/host/src/containers/job/add/constants'

export const STATUS = {
  IDLE: 'idle',
  SAVING: 'saving',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  lastSavedItem: null,
  itemId: null,
  meta: null,
  isOpened: false,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case JOB_ADD_REQUESTED:
      return {
        ...state,
        lastSavedItem: null,
        itemId: null,
        meta: null,
        status: STATUS.SAVING
      }
    case JOB_ADD_SUCCEEDED:
      return {
        ...state,
        lastSavedItem: payload.job,
        itemId: isEmpty(payload.data) ? null : payload.data.shift()?.id,
        meta: { ...payload.meta },
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case JOB_ADD_FAILED:
      return {
        ...state,
        lastSavedItem: null,
        itemId: null,
        meta: null,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
