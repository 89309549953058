import { filter, includes } from 'lodash'
import i18n from '@doinn/shared/src/config/i18n'

export const getSwitchableApps = (user, appSlug, domain, roles) => {
  const apps = [
    {
      app: 'business',
      role: 'business_owner',
      roleName: i18n.t('Admin'),
      roleDescription: i18n.t('Admin'),
      url: `https://business${domain}`
    },
    {
      app: 'host',
      role: 'host',
      roleName: i18n.t('Manager'),
      roleDescription: i18n.t(
        'Responsible for planning, scheduling and automating property jobs.'
      ),
      url: `https://host${domain}`
    },
    {
      app: 'vendor',
      role: 'vendor',
      roleName: i18n.t('Coordinator'),
      roleDescription: i18n.t(
        'Responsible for receiving jobs and executing them. You can also dispatch jobs to the employees.'
      ),
      url: `https://vendor${domain}`
    }
  ]

  if (appSlug === 'vendor') {
    if (user?.business?.isDoinn && !user?.business?.isSaas) {
      return filter(
        apps,
        app =>
          includes(roles, app.role) &&
          app.app !== appSlug &&
          app.app !== 'business'
      )
    }

    return []
  }

  return filter(apps, app => includes(roles, app.role) && app.app !== appSlug)
}
