import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  downloadReportsBillingDetailsApi,
  fetchReportsBillingDetailsApi
} from '@doinn/shared/src/api/reports'
import {
  REPORTS_BILLING_DETAILS_DOWNLOAD_DETAILS_FAILED,
  REPORTS_BILLING_DETAILS_DOWNLOAD_DETAILS_REQUESTED,
  REPORTS_BILLING_DETAILS_DOWNLOAD_DETAILS_SUCCEEDED,
  REPORTS_BILLING_DETAILS_FETCH_FAILED,
  REPORTS_BILLING_DETAILS_FETCH_REQUESTED,
  REPORTS_BILLING_DETAILS_FETCH_SUCCEEDED
} from 'containers/reports/billingDetails/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchReportsBillingDetails(action) {
  try {
    const response = yield call(
      fetchReportsBillingDetailsApi,
      action.payload.params
    )
    yield put({
      type: REPORTS_BILLING_DETAILS_FETCH_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: REPORTS_BILLING_DETAILS_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* downloadReportsBillingDetails(action) {
  try {
    const { id, reportBy, start, end } = action.payload.params
    const reportName = `doinn_billing_services_${reportBy}_${id}_${start}_${end}.xls`
    const { data } = yield call(
      downloadReportsBillingDetailsApi,
      action.payload.params
    )

    yield put({
      type: REPORTS_BILLING_DETAILS_DOWNLOAD_DETAILS_SUCCEEDED,
      snackbar: {
        variant: 'success',
        message: i18n.t('The report has been downloaded successfully')
      },
      downloader: {
        data,
        type: 'application/vnd.ms-excel',
        name: reportName
      }
    })
  } catch (e) {
    yield put({
      type: REPORTS_BILLING_DETAILS_DOWNLOAD_DETAILS_FAILED,
      snackbar: {
        variant: 'error',
        message: e.message
      }
    })
  }
}

export default function* reportsBillingDetailsSaga() {
  yield all([
    takeLatest(
      REPORTS_BILLING_DETAILS_FETCH_REQUESTED,
      fetchReportsBillingDetails
    ),
    takeLatest(
      REPORTS_BILLING_DETAILS_DOWNLOAD_DETAILS_REQUESTED,
      downloadReportsBillingDetails
    )
  ])
}
