import React from 'react'
import { useTranslation } from 'react-i18next'
import { VariableSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import InfiniteLoader from 'react-window-infinite-loader'
import PropTypes from 'prop-types'
import { Box, makeStyles, useTheme } from '@material-ui/core'
import { useWindowSize } from '@doinn/shared/src/hooks'
import useAppDetector from '@doinn/shared/src/hooks/useAppDetector'
import EmptyList from '@doinn/shared/src/components/common/empty-state/EmptyList'
import PullToRefresh from '@doinn/shared/src/components/common/virtual-list/PullToRefresh'
import VirtualListItem from '@doinn/shared/src/components/common/virtual-list/VirtualListItem'
import VirtualListLoading from '@doinn/shared/src/components/common/virtual-list/VirtualListLoading'

const Context = React.createContext({})
const useStyles = makeStyles(theme => ({
  listContainer: {
    minHeight: theme.spacing(10)
  }
}))

const VirtualList = ({
  estimatedItemHeight,
  gutter,
  isLoading,
  items,
  itemProps,
  ItemComponent,
  LoadingItemComponent,
  hasNextPage,
  isNextPageLoading,
  onLoadNextPage,
  onItemClick,
  onItemsRendered,
  onScroll,
  disableFooterHeight,
  reduceHeightBy,
  height,
  totalLoadingItems,
  listRef: outerListRef,
  emptyComponent,
  onRefresh,
  refreshDisabled
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isApp = useAppDetector()
  const gutterSize = theme.spacing(gutter)
  const estimatedItemSize = theme.spacing(estimatedItemHeight) + gutterSize
  const listContainerRef = React.useRef()
  const listRef = React.useRef()
  const { height: windowHeight } = useWindowSize()
  const [visibleIndex, setVisibleIndex] = React.useState(0)
  const [listContainerHeight, setListContainerHeight] = React.useState(
    estimatedItemSize * 4
  )
  const [canRefresh, setCanRefresh] = React.useState(true)
  const sizeMap = React.useRef({})
  const setSize = React.useCallback(
    (index, size) => {
      sizeMap.current = {
        ...sizeMap.current,
        [index]: size + gutterSize
      }
    },
    [gutterSize]
  )
  const getSize = React.useCallback(
    index => sizeMap.current[index] || estimatedItemSize,
    [estimatedItemSize]
  )
  const itemCount = hasNextPage ? items.length + 1 : items.length
  const loadMoreItems = isNextPageLoading ? () => {} : onLoadNextPage
  const isItemLoaded = index => !hasNextPage || index < items.length

  const adjustHeight = React.useCallback(() => {
    if (listContainerRef.current) {
      const headerHeight = listContainerRef.current.offsetTop
      const bottomMainMenuElement = document.getElementById(
        'appBottomMainMenuSpacer'
      )
      const footerHeight =
        bottomMainMenuElement && !disableFooterHeight
          ? bottomMainMenuElement.offsetHeight
          : 0
      const reduceExtraHeight = reduceHeightBy || 0
      const newListHeight =
        height || windowHeight - headerHeight - footerHeight - reduceExtraHeight
      if (newListHeight !== listContainerHeight) {
        setListContainerHeight(newListHeight)
      }
    }
  }, [
    windowHeight,
    listContainerHeight,
    setListContainerHeight,
    disableFooterHeight,
    reduceHeightBy,
    height
  ])

  React.useLayoutEffect(() => {
    adjustHeight()
  }, [adjustHeight, isLoading, items])

  const innerElementTypeRenderer = React.forwardRef(
    ({ style, ...otherProps }, ref) => {
      const finalStyle = Object.assign({}, style, {
        height: `${parseFloat(style.height) + gutterSize * 2}px`
      })
      return <div ref={ref} style={finalStyle} {...otherProps} />
    }
  )

  const handleItemsRendered = callback => params => {
    callback(params)
    setVisibleIndex(params.visibleStartIndex)
    onItemsRendered && onItemsRendered(params)
  }

  const handleScroll = event => {
    const refresh = event?.scrollOffset === 0
    if (refresh !== canRefresh) {
      setCanRefresh(refresh)
    }
    onScroll && onScroll(event)
  }

  const handleRefresh = React.useCallback(() => {
    onRefresh && onRefresh()
  }, [onRefresh])

  if (isLoading) {
    return (
      <VirtualListLoading gutter={gutter} items={totalLoadingItems}>
        <LoadingItemComponent />
      </VirtualListLoading>
    )
  }

  if (!items.length) {
    return (
      <PullToRefresh
        disabled={refreshDisabled || !isApp}
        onRefresh={handleRefresh}
        canRefresh={canRefresh}
      >
        {emptyComponent || (
          <EmptyList
            title={t('No results')}
            description={t('There are currently no results to show')}
          />
        )}
      </PullToRefresh>
    )
  }

  return (
    <PullToRefresh
      disabled={refreshDisabled || !isApp}
      onRefresh={handleRefresh}
      canRefresh={canRefresh}
    >
      <Context.Provider value={{ setSize, sizeMap, listRef }}>
        <Box
          ref={listContainerRef}
          m={-2}
          className={classes.listContainer}
          style={{
            height: listContainerHeight
          }}
        >
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
              >
                {({ onItemsRendered: onItemsRenderedInfinityLoader, ref }) => (
                  <VariableSizeList
                    ref={list => {
                      ref(list)
                      listRef.current = list
                      if (outerListRef) {
                        outerListRef.current = list
                      }
                    }}
                    estimatedItemSize={estimatedItemSize}
                    height={height}
                    innerElementType={innerElementTypeRenderer}
                    itemCount={itemCount}
                    itemSize={getSize}
                    width={width}
                    onItemsRendered={handleItemsRendered(
                      onItemsRenderedInfinityLoader
                    )}
                    onScroll={handleScroll}
                  >
                    {({ index, style }) => {
                      const item = items[index]
                      return (
                        <div style={{ ...style, padding: gutterSize }}>
                          <VirtualListItem index={index}>
                            <ItemComponent
                              index={index}
                              item={item}
                              previousItem={index > 0 ? items[index - 1] : null}
                              isFirstVisible={visibleIndex === index}
                              isLoaded={isItemLoaded(index)}
                              onClick={
                                onItemClick ? () => onItemClick(item) : null
                              }
                              {...itemProps}
                            />
                          </VirtualListItem>
                        </div>
                      )
                    }}
                  </VariableSizeList>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </Box>
      </Context.Provider>
    </PullToRefresh>
  )
}

VirtualList.defaultProps = {
  estimatedItemHeight: 12,
  gutter: 2,
  reduceHeightBy: 0,
  height: null,
  onLoadNextPage: () => {},
  emptyComponent: null,
  onRefresh: () => {},
  refreshDisabled: true
}

VirtualList.propTypes = {
  estimatedItemHeight: PropTypes.number,
  gutter: PropTypes.number,
  reduceHeightBy: PropTypes.number,
  height: PropTypes.number,
  totalLoadingItems: PropTypes.number,
  onLoadNextPage: PropTypes.func,
  isNextPageLoading: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  emptyComponent: PropTypes.object,
  onRefresh: PropTypes.func,
  refreshDisabled: PropTypes.bool
}

export const VirtualListContext = Context
export default VirtualList
