import {
  STRIPE_PAYMENT_SETUP_CLOSE,
  STRIPE_PAYMENT_SETUP_ERROR,
  STRIPE_PAYMENT_SETUP_FAILED,
  STRIPE_PAYMENT_SETUP_OPEN,
  STRIPE_PAYMENT_SETUP_READY,
  STRIPE_PAYMENT_SETUP_SAVING,
  STRIPE_PAYMENT_SETUP_SAVING_FAILED,
  STRIPE_PAYMENT_SETUP_SAVING_REQUESTED,
  STRIPE_PAYMENT_SETUP_SAVING_SUCCEEDED,
  STRIPE_PAYMENT_SETUP_SUCCEEDED
} from '@doinn/shared/src/containers/gateways/stripe/setup/contants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SAVING: 'saving',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  status: STATUS.IDLE,
  isOpened: false,
  clientSecret: null,
  id: null,
  type: null,
  context: null,
  gatewaysContext: null
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case STRIPE_PAYMENT_SETUP_OPEN:
      return {
        ...initialState,
        isOpened: true,
        status: STATUS.LOADING,
        id: payload?.params?.id,
        type: payload?.params?.type,
        context: payload?.params?.context,
        gatewaysContext: payload?.params?.gatewaysContext
      }
    case STRIPE_PAYMENT_SETUP_CLOSE:
      return {
        ...initialState,
        isOpened: false
      }
    case STRIPE_PAYMENT_SETUP_SUCCEEDED:
      return {
        ...state,
        clientSecret: payload.data?.clientSecret
      }
    case STRIPE_PAYMENT_SETUP_READY:
      return {
        ...state,
        status: STATUS.IDLE
      }
    case STRIPE_PAYMENT_SETUP_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case STRIPE_PAYMENT_SETUP_SAVING:
      return {
        ...state,
        status: STATUS.SAVING
      }
    case STRIPE_PAYMENT_SETUP_ERROR:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case STRIPE_PAYMENT_SETUP_SAVING_REQUESTED:
      return {
        ...state,
        status: STATUS.SAVING
      }
    case STRIPE_PAYMENT_SETUP_SAVING_SUCCEEDED:
      return {
        ...initialState,
        status: STATUS.SUCCESS
      }
    case STRIPE_PAYMENT_SETUP_SAVING_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
