import {
  ACCEPT_TERMS_AND_CONDITIONS_REQUESTED,
  TOGGLE_ACCEPT_TERMS_CONDITIONS
} from 'containers/onboarding/steps/Terms/constants'

export function toggleAcceptTermsConditions() {
  return { type: TOGGLE_ACCEPT_TERMS_CONDITIONS }
}

export function acceptTermsConditions() {
  return {
    type: ACCEPT_TERMS_AND_CONDITIONS_REQUESTED
  }
}
