export function resizeImage(file, sizeBox, quality = 1) {
  if (!sizeBox || sizeBox.length !== 2) {
    throw Error('You need to give the sizeBox')
  }

  const fileReader = new FileReader()
  const canvas = document.createElement('canvas')
  const canvasContext = canvas.getContext('2d', { alpha: false })

  return new Promise(resolve => {
    fileReader.onload = event => {
      const img = new Image()
      img.onload = () => {
        const scaleRatio =
          Math.min(...sizeBox) / Math.max(img.width, img.height)
        const width = img.width * scaleRatio
        const height = img.height * scaleRatio
        canvas.width = width
        canvas.height = height
        canvasContext.drawImage(img, 0, 0, width, height)
        return resolve(canvas.toDataURL('image/jpeg', quality))
      }
      img.src = event.target.result
    }
    fileReader.readAsDataURL(file)
  })
}

export function dataURLtoBlob(dataUrl) {
  const imageInfo = dataUrl.split(',')
  const mime = imageInfo[0].match(/:(.*?);/)[1]
  const decodedBase64String = window.atob(imageInfo[1])
  let size = decodedBase64String.length
  const u8arr = new Uint8Array(size)
  while (size--) {
    u8arr[size] = decodedBase64String.charCodeAt(size)
  }
  return new Blob([u8arr], { type: mime })
}

export async function optimizeImageFile(file, size, qualityRate = 1) {
  const fileName = file.name
  const dataUrl = await resizeImage(file, size, qualityRate)
  const blob = dataURLtoBlob(dataUrl)
  return new File([blob], fileName)
}
