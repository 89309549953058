export const ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_OPEN_REQUESTED =
  'ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_OPEN_REQUESTED'

export const ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_CLOSE_REQUESTED =
  'ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_CLOSE_REQUESTED'

export const ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_SAVE_REQUESTED =
  'ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_SAVE_REQUESTED'
export const ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_SAVE_SUCCEEDED =
  'ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_SAVE_SUCCEEDED'
export const ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_SAVE_FAILED =
  'ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_SAVE_FAILED'
