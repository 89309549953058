import React from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'

import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import TemplateDefault from 'components/TemplateDefault'

const ServicesAddContainer = loadable(() =>
  lazyRetry(() => import('@doinn/host/src/containers/job/add/ServicesAdd'))
)

const ServicesAdd = () => {
  const { t } = useTranslation()
  return (
    <TemplateDefault title={t('Services')}>
      <Helmet>
        <title>{t('Services')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <ServicesAddContainer />
      </React.Suspense>
    </TemplateDefault>
  )
}

export default ServicesAdd
