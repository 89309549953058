import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { cloneDeep } from 'lodash'
import { Typography } from '@material-ui/core'
import Select from '@doinn/shared/src/components/common/filter/Select'
import RadioList from '@doinn/shared/src/components/common/filter/RadioList'

const ListFiltersBy = ({
  filters,
  onChangeFilters,
  filterOptions,
  subheader,
  filterName,
  variant
}) => {
  const handleChangeFilterBy = useCallback(
    (filterName, newValue) => {
      const params = cloneDeep({
        ...filters,
        page: 1
      })
      params[filterName] = newValue
      onChangeFilters && onChangeFilters(params)
    },
    [filters, onChangeFilters]
  )

  const renderListVariant = () => {
    return (
      <RadioList
        subheader={subheader}
        filterName={filterName}
        items={filterOptions}
        checkedItems={filters[filterName]}
        onChange={handleChangeFilterBy}
      />
    )
  }

  const renderMenuVariant = () => {
    return (
      <React.Fragment>
        <Typography variant='body2' gutterBottom>
          {subheader}
        </Typography>
        <Select
          filterOptions={filterOptions}
          selectedFilter={filters[filterName]}
          buttonLabel={subheader}
          filterName={filterName}
          onChange={handleChangeFilterBy}
        />
      </React.Fragment>
    )
  }

  if (variant === 'list') return renderListVariant()
  return renderMenuVariant()
}

ListFiltersBy.propTypes = {
  variant: PropTypes.oneOf(['list', 'menu']),
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  filters: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    period: PropTypes.string,
    type: PropTypes.string
  }),
  onChangeFilters: PropTypes.func,
  subheader: PropTypes.string.isRequired,
  filterName: PropTypes.string.isRequired
}

ListFiltersBy.defaultProps = {
  variant: 'menu'
}

export default ListFiltersBy
