import { all, call, put, takeLatest } from 'redux-saga/effects'
import { addJobExtraServiceApi, getJobApi } from 'api/job'
import {
  JOB_EXTRA_SERVICE_ADD_FORM_SAVE_FAILED,
  JOB_EXTRA_SERVICE_ADD_FORM_SAVE_REQUESTED,
  JOB_EXTRA_SERVICE_ADD_FORM_SAVE_SUCCEEDED
} from 'containers/job/extra-service/add/constants'
import { JOB_GET_SUCCEEDED } from '@doinn/vendor/src/containers/job/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* addJobExtraService(action) {
  const { jobId, service } = action.payload.params
  try {
    const response = yield call(addJobExtraServiceApi, {
      jobId,
      service
    })
    const updatedJob = yield call(getJobApi, jobId)
    yield put({
      type: JOB_GET_SUCCEEDED,
      payload: updatedJob.data
    })
    const createdService = {
      ...response.data,
      jobId
    }
    yield put({
      type: JOB_EXTRA_SERVICE_ADD_FORM_SAVE_SUCCEEDED,
      payload: createdService
    })
  } catch (e) {
    yield put({
      type: JOB_EXTRA_SERVICE_ADD_FORM_SAVE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* watchAddExtraService() {
  yield takeLatest(
    JOB_EXTRA_SERVICE_ADD_FORM_SAVE_REQUESTED,
    addJobExtraService
  )
}

export default function* extraServiceAddFormSaga() {
  yield all([watchAddExtraService()])
}
