import { all, call, put, takeLatest } from 'redux-saga/effects'
import i18n from '@doinn/shared/src/config/i18n'
import { saveBillingApi } from '@doinn/shared/src/api/billing'
import {
  BILLING_ADD_FAILED,
  BILLING_ADD_REQUESTED,
  BILLING_ADD_SUCCEEDED
} from '@doinn/shared/src/containers/subscription-billing/billing/add/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

export function* addBilling(action) {
  try {
    const { data } = action.payload
    yield call(saveBillingApi, { data })

    const messageText =
      data?.type === 'account'
        ? i18n.t('The general billing has been added successfuly!')
        : i18n.t('The group billing has been added successfuly!')

    yield put({
      type: BILLING_ADD_SUCCEEDED,
      payload: data,
      snackbar: {
        variant: 'success',
        message: messageText
      }
    })
  } catch (e) {
    yield put({
      type: BILLING_ADD_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* billingsAddSaga() {
  yield all([takeLatest(BILLING_ADD_REQUESTED, addBilling)])
}
