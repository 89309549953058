import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { editCatalogueMyApi } from '@doinn/shared/src/api/catalogues'
import {
  CATALOGUE_MY_EDIT_FAILED,
  CATALOGUE_MY_EDIT_REQUESTED,
  CATALOGUE_MY_EDIT_SUCCEEDED
} from '@doinn/shared/src/containers/catalogue/my/edit/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* editCatalogue(action) {
  try {
    const { catalogue } = action.payload
    yield call(editCatalogueMyApi, catalogue)
    yield put({
      type: CATALOGUE_MY_EDIT_SUCCEEDED,
      payload: catalogue,
      snackbar: {
        variant: 'success',
        message: i18n.t('The catalogue has been edited successfully')
      }
    })
  } catch (e) {
    yield put({
      type: CATALOGUE_MY_EDIT_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* cataloguesEditSaga() {
  yield all([takeLatest(CATALOGUE_MY_EDIT_REQUESTED, editCatalogue)])
}
