import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { editSettingsCatalogueMyApi } from '@doinn/shared/src/api/catalogues'
import {
  CATALOGUE_MY_EDIT_SETTINGS_FAILED,
  CATALOGUE_MY_EDIT_SETTINGS_REQUESTED,
  CATALOGUE_MY_EDIT_SETTINGS_SUCCEEDED
} from '@doinn/shared/src/containers/catalogue/my/edit-settings/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* editCatalogue(action) {
  try {
    const { catalogue } = action.payload
    const response = yield call(editSettingsCatalogueMyApi, catalogue)
    yield put({
      type: CATALOGUE_MY_EDIT_SETTINGS_SUCCEEDED,
      payload: response.data,
      snackbar: {
        variant: 'success',
        message: i18n.t('The catalog settings has been edited successfully')
      }
    })
  } catch (e) {
    yield put({
      type: CATALOGUE_MY_EDIT_SETTINGS_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* cataloguesAddSaga() {
  yield all([takeLatest(CATALOGUE_MY_EDIT_SETTINGS_REQUESTED, editCatalogue)])
}
