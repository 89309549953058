export const INVITATIONS_ANSWER_REFUSE_REQUESTED =
  'INVITATIONS_ANSWER_REFUSE_REQUESTED'
export const INVITATIONS_ANSWER_REFUSE_SUCCEEDED =
  'INVITATIONS_ANSWER_REFUSE_SUCCEEDED'
export const INVITATIONS_ANSWER_REFUSE_FAILED =
  'INVITATIONS_ANSWER_REFUSE_FAILED'
export const INVITATIONS_ANSWER_ACCEPT_REQUESTED =
  'INVITATIONS_ANSWER_ACCEPT_REQUESTED'
export const INVITATIONS_ANSWER_ACCEPT_SUCCEEDED =
  'INVITATIONS_ANSWER_ACCEPT_SUCCEEDED'
export const INVITATIONS_ANSWER_ACCEPT_FAILED =
  'INVITATIONS_ANSWER_ACCEPT_FAILED'
