import React from 'react'
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  makeStyles,
  useMediaQuery,
  useScrollTrigger,
  useTheme
} from '@material-ui/core'

import useAppDetector from '@doinn/shared/src/hooks/useAppDetector'
import DoinnLogo from '@doinn/shared/src/components/app/DoinnLogo'
import FlexSpacer from '@doinn/shared/src/components/common/FlexSpacer'

const useStyles = makeStyles(theme => {
  const drawerWidth = theme.spacing(39)
  const desktopBreakpoint = theme.breakpoints.up('md')
  const mobileBreakpoint = theme.breakpoints.down('xs')

  return {
    appBar: props => {
      const { isApp, isMobile } = props
      const appBarStyles = {}

      if (!isApp && isMobile) {
        appBarStyles.backgroundColor = theme.palette.background.dark
      }

      if (!isApp) {
        appBarStyles[desktopBreakpoint] = {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth
        }
      }

      if (isApp) {
        appBarStyles[mobileBreakpoint] = {
          paddingTop: `env(safe-area-inset-top, 0)`
        }
      }

      return appBarStyles
    },
    appBarSpacer: props => {
      const appSpaceStyles = { ...theme.mixins.toolbar }

      if (props.isApp) {
        appSpaceStyles[mobileBreakpoint] = {
          minHeight: `calc(env(safe-area-inset-top, 0) + ${theme.mixins.toolbar.minHeight}px)`
        }
      }

      return appSpaceStyles
    }
  }
})

const AuthHeader = ({ leftChildren, rightChildren, title }) => {
  const theme = useTheme()
  const isApp = useAppDetector()
  const isMobile = !useMediaQuery(theme.breakpoints.up('md'))
  const classes = useStyles({ isApp, isMobile })

  const isScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  })
  const elevation = isScrolled ? 4 : 0

  const headerLeftContent = !leftChildren ? (
    <Typography variant='h5'>
      <b>{title}</b>
    </Typography>
  ) : (
    leftChildren
  )

  const desktopContent = () => (
    <React.Fragment>
      {headerLeftContent}
      <FlexSpacer />
      <Box pr={2}>{rightChildren}</Box>
    </React.Fragment>
  )

  const mobileContent = () => (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      width='100%'
    >
      <DoinnLogo variant='negative' />
    </Box>
  )

  const renderContent = () => (isMobile ? mobileContent() : desktopContent())

  return (
    <React.Fragment>
      <AppBar
        className={classes.appBar}
        color='inherit'
        elevation={elevation}
        id='appHeader'
        position='fixed'
      >
        <Toolbar>{renderContent()}</Toolbar>
      </AppBar>
      <div className={classes.appBarSpacer} />
    </React.Fragment>
  )
}

export default AuthHeader
