export const countAppliedFilters = (
  filters,
  hasMainFilters,
  hiddenFilters = []
) => {
  let total = 0
  const filtersNumberOf = [
    'singleBeds',
    'doubleBeds',
    'kingSizeBeds',
    'bedrooms',
    'bathrooms'
  ]
  for (const key in filters) {
    if (filters[key] && !hiddenFilters.includes(key)) {
      const value = filters[key]
      if (Array.isArray(value)) {
        if (filtersNumberOf.includes(key)) {
          total += value.filter(item => {
            return item <= 5
          }).length
        } else {
          total += value.length
        }
      }
    }
  }
  return total
}
