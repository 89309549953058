import React from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import Icon from '@doinn/shared/src/components/common/Icon'
import TemplateAuth from '@doinn/shared/src/components/templates/TemplateAuth'
import MainMenuItem from '@doinn/vendor/src/containers/app/auth/components/MainMenuItem'

const RegisterContainer = loadable(() =>
  lazyRetry(() =>
    import('@doinn/vendor/src/containers/app/auth/register/Register')
  )
)

const Register = () => {
  const { t } = useTranslation()
  const location = useLocation()

  return (
    <TemplateAuth
      headerLeftChildren={
        <Button
          component={RouterLink}
          color='secondary'
          variant='outlined'
          to={`/registration${location.search}`}
          startIcon={<Icon icon='back' />}
        >
          {t('Previous')}
        </Button>
      }
      drawerChildren={<MainMenuItem />}
    >
      <Helmet>
        <title>{t('Register')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <RegisterContainer />
      </React.Suspense>
    </TemplateAuth>
  )
}

export default Register
