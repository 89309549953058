import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createBrowserHistory as createHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { reduxSearch } from 'redux-search'
import createRootReducer from 'store/reducers'
import sagas from 'store/sagas'
import snackbarMiddleware from '@doinn/shared/src/middlewares/snackbar'
import downloaderMiddleware from '@doinn/shared/src/middlewares/downloader'
import trackerMiddleware from '@doinn/shared/src/middlewares/tracker'

export const history = createHistory({ basename: '' })
export const store = configureStore()

let prevPath = null
window?.analytics?.page && window.analytics.page()
history.listen(location => {
  const currentFullPath = `${location.pathname}${location.search}`
  if (
    currentFullPath !== prevPath &&
    window.analytics &&
    window.analytics.page
  ) {
    prevPath = currentFullPath
    window?.analytics?.page && window.analytics.page()
  }
})

export default function configureStore(preloadedState) {
  const rootReducer = createRootReducer(history)
  const sagaMiddleware = createSagaMiddleware()
  const middlewares = [
    routerMiddleware(history),
    sagaMiddleware,
    downloaderMiddleware,
    snackbarMiddleware,
    trackerMiddleware
  ]
  const middlewareEnhancer = applyMiddleware(...middlewares)
  const reduxSearchEnhancer = reduxSearch({
    resourceIndexes: {
      chats: ({ resources, indexDocument, state }) => {
        resources.forEach(chat => {
          indexDocument(chat.id, chat.name)
          chat.participants.forEach(participant =>
            indexDocument(chat.id, participant.name)
          )
        })
      },
      chatsContacts: ['name'],
      chatsProperties: ['name', 'address'],
      customerList: ['name', 'email'],
      employees: ['name', 'email'],
      teams: ['name'],
      invoices: ['status'],
      properties: ['name', 'address'],
      providerList: ['name', 'email'],
      memberList: ['name', 'email']
    },
    resourceSelector: (resourceName, state) => {
      return state[resourceName].list
    }
  })
  const enhancers = [middlewareEnhancer, reduxSearchEnhancer]
  const composeEnhancersWithDevTools = composeWithDevTools({})
  const composedEnhancers = composeEnhancersWithDevTools(...enhancers)
  const store = createStore(rootReducer, preloadedState, composedEnhancers)

  sagaMiddleware.run(sagas)

  return store
}
