import { all, call, put, takeLatest } from 'redux-saga/effects'
import { applyCouponApi, removeCouponApi } from '@doinn/vendor/src/api/checkout'
import {
  CHECKOUT_COUPON_APPLY_REQUESTED,
  CHECKOUT_COUPON_FAILED,
  CHECKOUT_COUPON_REMOVE_REQUESTED,
  CHECKOUT_COUPON_SUCCEEDED
} from '@doinn/vendor/src/containers/checkout/coupon/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* applyCoupon(action) {
  try {
    const { params } = action.payload
    const response = yield call(applyCouponApi, params)

    yield put({
      type: CHECKOUT_COUPON_SUCCEEDED,
      payload: response.data.data
    })
  } catch (e) {
    yield put({
      type: CHECKOUT_COUPON_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* removeCoupon(action) {
  try {
    const { params } = action.payload
    const response = yield call(removeCouponApi, params)

    yield put({
      type: CHECKOUT_COUPON_SUCCEEDED,
      payload: response.data.data
    })
  } catch (e) {
    yield put({
      type: CHECKOUT_COUPON_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* checkoutCouponSaga() {
  yield all([
    takeLatest(CHECKOUT_COUPON_APPLY_REQUESTED, applyCoupon),
    takeLatest(CHECKOUT_COUPON_REMOVE_REQUESTED, removeCoupon)
  ])
}
