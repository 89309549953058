import Cookies from 'js-cookie'
import { SESSION_DOMAIN } from 'config/env'

const COOKIE_LANGUAGE_NAME = 'language'

const LanguageByCountry = {
  pt: 'pt_PT',
  es: 'es_ES',
  it: 'it_IT',
  fr: 'fr_FR',
  get: country => LanguageByCountry[country] || 'en_US'
}

export const setCookieLanguage = language => {
  Cookies.set(COOKIE_LANGUAGE_NAME, language, {
    domain: SESSION_DOMAIN,
    expires: 30
  })
}

export const getLanguage = () => {
  const referred = window.document.referrer
  const country = referred.includes('doinn.co')
    ? referred?.split('/')[2]?.split('.')[0]
    : null
  const langByCountry = country ? LanguageByCountry.get(country) : null
  return langByCountry || navigator.language.replace('-', '_')
}
