import React from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'

import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import TemplateDefault from 'components/TemplateDefault'
import HelperCard from '@doinn/shared/src/components/common/helper/HelperCard'

const IntegrationsContainer = loadable(() =>
  lazyRetry(() =>
    import('@doinn/host/src/containers/integrations/Integrations')
  )
)

const Integrations = () => {
  const { t } = useTranslation()
  return (
    <TemplateDefault title={t('Integrations')} helperContent={<HelperCard />}>
      <Helmet>
        <title>{t('Integrations')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <IntegrationsContainer />
      </React.Suspense>
    </TemplateDefault>
  )
}

export default Integrations
