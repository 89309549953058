import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import LogoImage from '@doinn/shared/src/components/app/imgs/doinn_logo_primary_2022.svg'
import LogoImageNegative from '@doinn/shared/src/components/app/imgs/doinn_logo_primary_negative.svg'
import LogoImageNegativeWithSlogan from '@doinn/shared/src/components/app/imgs/doinn_logo_primary_negative_with_slogan.svg'

export const LOGO_VARIANTS = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
  NEGATIVE_WITH_SLOGAN: 'negative-with-slogan'
}

const useStyles = makeStyles(theme => ({
  logo: {
    width: theme.spacing(16.25)
  }
}))

const DoinnLogo = props => {
  const { variant } = props
  const classes = useStyles(props)

  const LogoVariant = useMemo(() => {
    switch (variant) {
      case LOGO_VARIANTS.POSITIVE:
        return LogoImage
      case LOGO_VARIANTS.NEGATIVE:
        return LogoImageNegative
      case LOGO_VARIANTS.NEGATIVE_WITH_SLOGAN:
        return LogoImageNegativeWithSlogan
      default:
        return LogoImage
    }
  }, [variant])

  return <img className={classes.logo} src={LogoVariant} alt='Doinn' />
}

DoinnLogo.propTypes = {
  variant: PropTypes.oneOf(Object.values(LOGO_VARIANTS))
}

DoinnLogo.defaultProps = {
  variant: LOGO_VARIANTS.POSITIVE
}

export default DoinnLogo
