export const BILLING_GROUP_LIST_FETCH_REQUESTED =
  'BILLING_GROUP_LIST_FETCH_REQUESTED'
export const BILLING_GROUP_LIST_FETCH_SUCCEEDED =
  'BILLING_GROUP_LIST_FETCH_SUCCEEDED'
export const BILLING_GROUP_LIST_FETCH_FAILED = 'BILLING_GROUP_LIST_FETCH_FAILED'

export const BILLING_GROUP_LIST_FETCH_MORE_REQUESTED =
  'BILLING_GROUP_LIST_FETCH_MORE_REQUESTED'
export const BILLING_GROUP_LIST_FETCH_MORE_SUCCEEDED =
  'BILLING_GROUP_LIST_FETCH_MORE_SUCCEEDED'
export const BILLING_GROUP_LIST_FETCH_MORE_FAILED =
  'BILLING_GROUP_LIST_FETCH_MORE_FAILED'
