import React, { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { startOfToday } from 'date-fns'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { cloneDeep, isEmpty, isEqual as isObjEqual } from 'lodash'

import {
  useDidMount,
  useFilterState,
  useOpenState,
  usePrevious
} from '@doinn/shared/src/hooks'
import {
  getQueryStringObject,
  updateURLParams
} from '@doinn/shared/src/util/url'
import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import { formatSystemDate } from '@doinn/shared/src/util/date-fns'
import { getAllParamsButParam } from '@doinn/shared/src/util/object'
import { parseAppliedFilters } from '@doinn/shared/src/util/filters'
import { countAppliedFilters } from '@doinn/vendor/src/containers/reservation/util'
import TemplateDefault from 'components/TemplateDefault'
import FiltersDialog from '@doinn/shared/src/components/common/FiltersDialog'
import FiltersButton from '@doinn/shared/src/components/common/FiltersButton'
import HelperCard from '@doinn/shared/src/components/common/helper/HelperCard'
import ListFiltersDialogContent from '@doinn/vendor/src/containers/reservation/list/components/ListFiltersDialogContent'

const ReservationsContainer = loadable(() =>
  lazyRetry(() =>
    import('@doinn/vendor/src/containers/reservation/Reservations')
  )
)

const SAVED_FILTERS_KEY = 'scenesReservationsAppliedFilters'

const getDefaultFilters = () => {
  const startOfTodayDate = startOfToday()
  const startOfTodayFormatted = formatSystemDate(startOfTodayDate)

  return {
    spaces: [],
    period: 'today',
    start: formatSystemDate(startOfTodayFormatted),
    end: formatSystemDate(startOfTodayFormatted),
    status: 'all',
    orderBy: 'checkin',
    orderOrientation: 'asc',
    page: 1
  }
}

const getLastSavedFilters = () => {
  try {
    return JSON.parse(window.sessionStorage.getItem(SAVED_FILTERS_KEY))
  } catch (e) {}
  return {}
}

const saveFilters = filters => {
  try {
    const filtersWithoutPage = getAllParamsButParam(filters, 'page')
    return window.sessionStorage.setItem(
      SAVED_FILTERS_KEY,
      JSON.stringify(filtersWithoutPage)
    )
  } catch (e) {}
  return {}
}

const getQueryOrSavedFilters = location => {
  const queryFilters = getQueryStringObject(location.search)
  let filters = cloneDeep(getDefaultFilters())
  if (isEmpty(queryFilters)) {
    filters = {
      ...cloneDeep(getDefaultFilters()),
      ...cloneDeep(getLastSavedFilters())
    }
  } else {
    filters = parseAppliedFilters(filters, queryFilters)
  }
  return filters
}

const Reservations = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const previousLocation = usePrevious(location)
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))
  const isSM = useMediaQuery(theme.breakpoints.down('sm'))
  const isMD = useMediaQuery(theme.breakpoints.down('md'))
  const [justChangedLocation, setJustChangedLocation] = React.useState(false)
  const {
    appliedFilters,
    selectedFilters,
    hasAppliedFiltersChanges,
    onChangeAppliedFilters,
    onChangeSelectedFilters,
    onClearSelectedFilters,
    onConfirmSelectedFilters
  } = useFilterState(getDefaultFilters())
  const {
    justClosed: justClosedFilters,
    justOpened: justOpenedFilters,
    onClose: onCloseFilters,
    onOpen: onOpenFilters,
    open: isFiltersOpened
  } = useOpenState(false)

  const filtersLabel = isMobile ? t('Filters') : t('More filters')

  const [hiddenFilters, setHiddenFilters] = React.useState([])
  const [totalAppliedFilters, setTotalAppliedFilters] = React.useState(
    countAppliedFilters(appliedFilters)
  )

  useDidMount(() => {
    onChangeAppliedFilters(getQueryOrSavedFilters(location))
  })

  React.useEffect(() => {
    const hidden = []
    if (isMD) {
      hidden.push('orderBy')
      hidden.push('status')
    }
    if (isSM) hidden.push('period')
    setHiddenFilters(hidden)
  }, [isMD, isSM])

  React.useEffect(() => {
    if (!previousLocation) return
    setJustChangedLocation(
      !isObjEqual(location.search, previousLocation.search)
    )
  }, [location, previousLocation])

  React.useEffect(() => {
    if (!justChangedLocation) return
    saveFilters(appliedFilters)
    const savedFilters = getQueryOrSavedFilters(location)
    const shouldUpdateAppliedFilters = !isObjEqual(appliedFilters, savedFilters)
    if (shouldUpdateAppliedFilters) {
      onChangeAppliedFilters(savedFilters)
      saveFilters(appliedFilters)
    }
  }, [appliedFilters, justChangedLocation, location, onChangeAppliedFilters])

  React.useEffect(() => {
    if (justOpenedFilters) {
      onChangeSelectedFilters(appliedFilters)
    }
  }, [appliedFilters, justOpenedFilters, onChangeSelectedFilters])

  React.useEffect(() => {
    if (justClosedFilters) {
      onChangeSelectedFilters(appliedFilters)
    }
  }, [appliedFilters, justClosedFilters, onChangeSelectedFilters])

  React.useEffect(() => {
    const shouldUpdateUrl =
      hasAppliedFiltersChanges &&
      !isObjEqual(appliedFilters, getQueryOrSavedFilters(location))
    if (shouldUpdateUrl) {
      updateURLParams(appliedFilters, history)
    }
  }, [appliedFilters, hasAppliedFiltersChanges, history, location])

  React.useEffect(() => {
    setTotalAppliedFilters(countAppliedFilters(appliedFilters, hiddenFilters))
  }, [appliedFilters, hiddenFilters])

  const handleChangeAppliedFilters = useCallback(
    filters => {
      onChangeAppliedFilters(filters)
    },
    [onChangeAppliedFilters]
  )

  const handleConfirmSelectedFilters = useCallback(() => {
    onConfirmSelectedFilters()
    onCloseFilters()
  }, [onCloseFilters, onConfirmSelectedFilters])

  return (
    <TemplateDefault
      title={t('Reservations')}
      filtersContent={
        <FiltersButton
          label={filtersLabel}
          variant='icon'
          totalAppliedFilters={totalAppliedFilters}
          onOpen={onOpenFilters}
        />
      }
      helperContent={<HelperCard />}
    >
      <Helmet>
        <title>{t('Reservations')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <ReservationsContainer
          filters={appliedFilters}
          hiddenFilters={hiddenFilters}
          onChangeFilters={handleChangeAppliedFilters}
          onOpenFilters={onOpenFilters}
        />
      </React.Suspense>
      <FiltersDialog
        open={isFiltersOpened}
        title={filtersLabel}
        onClear={onClearSelectedFilters}
        onClose={onCloseFilters}
        onConfirm={handleConfirmSelectedFilters}
      >
        <ListFiltersDialogContent
          filters={selectedFilters}
          hiddenFilters={hiddenFilters}
          onChangeFilters={onChangeSelectedFilters}
        />
      </FiltersDialog>
    </TemplateDefault>
  )
}

export default Reservations
