import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { APP_ENV, SENTRY_URL } from 'config/env'
import Routes from 'routes'
import * as serviceWorker from 'serviceWorker'
import * as errorTracker from '@doinn/shared/src/config/errorTracker'
import 'react-hot-loader/patch'
import 'index.css'
import '@doinn/shared/src/config/i18n'

errorTracker.init(APP_ENV, SENTRY_URL)

const render = Component => {
  ReactDOM.render(
    <AppContainer>
      <Component />
    </AppContainer>,
    document.getElementById('root')
  )
}

render(Routes)

if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept('./containers/app/App', () => {
    const Next = Routes
    render(Next)
  })
}

serviceWorker.unregister()
