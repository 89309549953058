import React from 'react'
import { compose } from 'redux'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  ButtonBase,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { BASE_SITE_URL } from 'config/env'
import { getISOLocale } from '@doinn/shared/src/util/getLocale'
import Icon from '@doinn/shared/src/components/common/Icon'
import ChipNew from '@doinn/shared/src/components/common/ChipNew'
import UserAvatar from '@doinn/shared/src/components/common/UserAvatar'
import SwitchAppMenu from '@doinn/shared/src/components/app/SwitchApp/SwitchAppMenu'
import withAuthorization from '@doinn/shared/src/hoc/authorization/withAuthorization'
import Credit from 'containers/app/components/Credit'

const useStyles = makeStyles(theme => ({
  menu: {
    marginRight: theme.spacing(-1)
  },
  listItem: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  listItemAvatar: {
    minWidth: 'auto'
  },
  avatar: {
    marginRight: theme.spacing(1.5)
  },
  userName: {
    maxWidth: theme.spacing(20)
  },
  icon: {
    verticalAlign: 'text-bottom',
    marginLeft: theme.spacing(1.5)
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  creditContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

const HeaderUser = ({ can, loggedUser, onLogout }) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const isLargeDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const isOpened = Boolean(anchorEl)
  const icon = isOpened ? 'chevron_up_circle' : 'chevron_down_circle_outline'
  const todayTime = new Date().getTime()
  const hasCredit = loggedUser?.business?.credit?.value > 0
  const translationLanguage =
    loggedUser?.preferences?.translationLanguage || 'en'
  const helplink = ['en', 'pt', 'es'].includes(translationLanguage)
    ? `https://help.doinn.co/${translationLanguage}/`
    : 'https://help.doinn.co/en/'

  const renderMenuItemExternalLink = (label, href, isNewEndDate) => {
    return (
      <MenuItem
        component={ButtonBase}
        color='inherit'
        href={href}
        target='_blank'
        className={classes.menuItem}
      >
        <span>{label}</span>
        <Icon icon='open_in_new' fontSize='small' className={classes.icon} />
        {isNewEndDate ? (
          <ChipNew endDate={isNewEndDate} className={classes.icon} />
        ) : null}
      </MenuItem>
    )
  }

  const handleOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!loggedUser) return null

  return (
    <div className={classes.menu}>
      <List
        disablePadding
        aria-controls='header-user-menu'
        aria-haspopup='true'
      >
        <ListItem
          button
          disableGutters
          className={classes.listItem}
          onClick={handleOpen}
        >
          <ListItemAvatar className={classes.listItemAvatar}>
            <UserAvatar
              user={loggedUser}
              className={isLargeDesktop ? classes.avatar : ''}
              size='small'
            />
          </ListItemAvatar>
          {isLargeDesktop ? (
            <ListItemText
              primary={
                <Grid alignItems='center' container wrap='nowrap'>
                  <Grid item zeroMinWidth className={classes.userName}>
                    <Typography variant='body2' noWrap>
                      {loggedUser.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Icon icon={icon} className={classes.icon} />
                  </Grid>
                </Grid>
              }
            />
          ) : null}
        </ListItem>
      </List>
      <Menu
        id='header-user-menu'
        anchorEl={anchorEl}
        keepMounted
        open={isOpened}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={handleClose}
      >
        {!isDesktop && hasCredit ? (
          <Box>
            <MenuItem disabled={true} className={classes.menuItem}>
              <Credit
                symbol={loggedUser?.business?.credit?.symbol}
                value={loggedUser?.business?.credit?.value}
              />
            </MenuItem>
            <Divider className={classes.divider} />
          </Box>
        ) : null}
        <MenuItem component={RouterLink} to='/profile'>
          {t('Profile')}
        </MenuItem>
        {can('read-billings') ? (
          <MenuItem component={RouterLink} to='/billing'>
            {t('Subscription and billing')}
          </MenuItem>
        ) : null}
        <SwitchAppMenu loggedUser={loggedUser} />
        <Divider className={classes.divider} />
        {renderMenuItemExternalLink(t('Help Center'), helplink)}
        {renderMenuItemExternalLink(
          t('Service Protocols'),
          `${BASE_SITE_URL}/${getISOLocale(i18n.language)}/services`
        )}
        {renderMenuItemExternalLink(
          t('Privacy Policy'),
          `${BASE_SITE_URL}/${getISOLocale(i18n.language)}/privacy-policy`
        )}
        {renderMenuItemExternalLink(
          t('Cookie Policy'),
          `${BASE_SITE_URL}/${getISOLocale(i18n.language)}/cookie-policy`
        )}
        {renderMenuItemExternalLink(
          t('Terms of Use'),
          `${BASE_SITE_URL}/storage/app/media/${getISOLocale(
            i18n.language
          )}/terms-conditions.pdf?v=${todayTime}`
        )}
        <Divider className={classes.divider} />
        <MenuItem onClick={onLogout}>{t('Logout')}</MenuItem>
      </Menu>
    </div>
  )
}

export default compose(withAuthorization)(HeaderUser)
