import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, makeStyles } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { withEcho } from '@doinn/shared/src/hoc/echo'
import { usePrevious, useWillUnmount } from '@doinn/shared/src/hooks'
import { ECHO_EVENT_BUSINESS_CREDITS } from '@doinn/shared/src/hoc/echo/constants'
import { updateUserCredit } from '@doinn/shared/src/containers/user/actions'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

const Credit = ({ echoUserChannel, symbol, value }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isListening, setIsListening] = React.useState(false)
  const previousEchoUserChannel = usePrevious(echoUserChannel)

  const handleEchoUpdates = useCallback(() => {
    if (echoUserChannel && !isListening) {
      echoUserChannel.listen(ECHO_EVENT_BUSINESS_CREDITS, data => {
        dispatch(updateUserCredit(data.data))
      })
      setIsListening(true)
    }
  }, [dispatch, echoUserChannel, isListening])

  const stopListeningEchoUpdate = useCallback(
    echoChannel => {
      if (echoChannel && isListening) {
        echoChannel.stopListening(ECHO_EVENT_BUSINESS_CREDITS)
        setIsListening(false)
      }
    },
    [isListening, setIsListening]
  )

  React.useEffect(() => {
    handleEchoUpdates()
  }, [handleEchoUpdates])

  React.useEffect(() => {
    if (previousEchoUserChannel && !echoUserChannel) {
      stopListeningEchoUpdate(previousEchoUserChannel)
    }
  }, [previousEchoUserChannel, echoUserChannel, stopListeningEchoUpdate])

  useWillUnmount(() => {
    stopListeningEchoUpdate(echoUserChannel)
  })

  return (
    <Box px={1.5} className={classes.container}>
      <Typography variant='caption'>{t('Available credit')}</Typography>
      <Typography variant='overline'>
        <b>
          {symbol} {value}
        </b>
      </Typography>
    </Box>
  )
}

export default withEcho(Credit)
