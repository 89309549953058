export const PROPERTY_ASSIGN_STAFF_FORM_SEARCH_EMPLOYEES_REQUESTED =
  'PROPERTY_ASSIGN_STAFF_FORM_SEARCH_EMPLOYEES_REQUESTED'
export const PROPERTY_ASSIGN_STAFF_FORM_OPEN_REQUESTED =
  'PROPERTY_ASSIGN_STAFF_FORM_OPEN_REQUESTED'
export const PROPERTY_ASSIGN_STAFF_FORM_CLOSE_REQUESTED =
  'PROPERTY_ASSIGN_STAFF_FORM_CLOSE_REQUESTED'
export const PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEE_TOGGLE =
  'PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEE_TOGGLE'
export const PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_UNSELECTED =
  'PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_UNSELECTED'
export const PROPERTY_ASSIGN_STAFF_FORM_GET_PROPERTY_REQUESTED =
  'PROPERTY_ASSIGN_STAFF_FORM_GET_PROPERTY_REQUESTED'
export const PROPERTY_ASSIGN_STAFF_FORM_GET_PROPERTY_SUCCEEDED =
  'PROPERTY_ASSIGN_STAFF_FORM_GET_PROPERTY_SUCCEEDED'
export const PROPERTY_ASSIGN_STAFF_FORM_GET_PROPERTY_FAILED =
  'PROPERTY_ASSIGN_STAFF_FORM_GET_PROPERTY_FAILED'
export const PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_CONFIRM_REQUESTED =
  'PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_CONFIRM_REQUESTED'
export const PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_CONFIRM_SUCCEEDED =
  'PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_CONFIRM_SUCCEEDED'
export const PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_CONFIRM_FAILED =
  'PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_CONFIRM_FAILED'
export const PROPERTY_ASSIGN_STAFF_FORM_TEAM_SELECTED =
  'PROPERTY_ASSIGN_STAFF_FORM_TEAM_SELECTED'
export const PROPERTY_ASSIGN_STAFF_FORM_TEAM_UNSELECTED =
  'PROPERTY_ASSIGN_STAFF_FORM_TEAM_UNSELECTED'
export const PROPERTY_ASSIGN_STAFF_FORM_TEAM_CONFIRM_REQUESTED =
  'PROPERTY_ASSIGN_STAFF_FORM_TEAM_CONFIRM_REQUESTED'
export const PROPERTY_ASSIGN_STAFF_FORM_TEAM_CONFIRM_SUCCEEDED =
  'PROPERTY_ASSIGN_STAFF_FORM_TEAM_CONFIRM_SUCCEEDED'
export const PROPERTY_ASSIGN_STAFF_FORM_TEAM_CONFIRM_FAILED =
  'PROPERTY_ASSIGN_STAFF_FORM_TEAM_CONFIRM_FAILED'
