import React, { useCallback } from 'react'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { BASE_URL, SESSION_DOMAIN } from 'config/env'
import { getCurrentAuthTokenApi } from '@doinn/shared/src/api/auth'

const SwitchAppButton = ({
  switchableApp,
  Component,
  componentProps,
  isDoinn
}) => {
  const { t } = useTranslation()
  const formRef = React.useRef(null)

  const handleSubmit = useCallback(() => {
    formRef &&
      formRef.current &&
      formRef.current.submit &&
      formRef.current.submit()
  }, [])

  const handleClick = useCallback(
    e => {
      e.preventDefault()

      const authToken = getCurrentAuthTokenApi()

      Cookies.set(`${switchableApp.app}-web-token`, authToken, {
        domain: SESSION_DOMAIN,
        expires: 1
      })

      window.location.href = switchableApp.url
    },
    [switchableApp.app, switchableApp.url]
  )

  if (switchableApp.app === 'host' && isDoinn) {
    return (
      <form ref={formRef} action={`${BASE_URL}/switch/app`} method='POST'>
        <input type='hidden' name='token' value={getCurrentAuthTokenApi()} />
        <input type='hidden' name='switch_to' value={switchableApp.app} />
        <Component {...componentProps} onClick={handleSubmit}>
          {t('Switch to {{role}}', {
            role: switchableApp.roleName
          })}
        </Component>
      </form>
    )
  }

  return (
    <Component {...componentProps} onClick={handleClick}>
      {t('Switch to {{role}}', {
        role: switchableApp.roleName
      })}
    </Component>
  )
}

export default SwitchAppButton
