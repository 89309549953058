import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import DoinnLogo, {
  LOGO_VARIANTS
} from '@doinn/shared/src/components/app/DoinnLogo'

const useStyles = makeStyles(theme => ({
  content: {
    display: 'inline-flex',
    flex: '0 0 auto'
  }
}))

const MainMenuLogo = props => {
  const { variant, href } = props
  const classes = useStyles()

  return (
    <a className={classes.content} href={href ?? '/'}>
      <DoinnLogo variant={variant} />
    </a>
  )
}

MainMenuLogo.propTypes = {
  variant: PropTypes.oneOf(Object.values(LOGO_VARIANTS))
}

MainMenuLogo.defaultProps = {
  variant: LOGO_VARIANTS.POSITIVE
}

export default MainMenuLogo
