import {
  EMPLOYEES_DELETE_CONFIRM_CLOSE_REQUESTED,
  EMPLOYEES_DELETE_CONFIRM_OPEN_REQUESTED,
  EMPLOYEES_DELETE_FAILED,
  EMPLOYEES_DELETE_REQUESTED,
  EMPLOYEES_DELETE_SUCCEEDED
} from '@doinn/vendor/src/containers/staff/employees/delete/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  isOpened: false,
  isDeleting: false,
  isDeleteFailed: false,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case EMPLOYEES_DELETE_CONFIRM_OPEN_REQUESTED:
      return {
        ...initialState,
        isOpened: true
      }
    case EMPLOYEES_DELETE_CONFIRM_CLOSE_REQUESTED:
      return {
        ...initialState,
        isOpened: false
      }
    case EMPLOYEES_DELETE_REQUESTED:
      return {
        ...state,
        isDeleting: true,
        isDeleteFailed: false,
        status: STATUS.LOADING
      }
    case EMPLOYEES_DELETE_SUCCEEDED:
      return {
        ...state,
        isDeleting: false,
        isDeleteFailed: false,
        isOpened: false,
        status: STATUS.SUCCESS
      }
    case EMPLOYEES_DELETE_FAILED:
      return {
        ...state,
        isDeleting: false,
        isDeleteFailed: true,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
