import React, { useCallback } from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { fetchPropertiesElasticsearchApi } from '@doinn/shared/src/api/properties'
import ListFiltersContentComponent from '@doinn/vendor/src/containers/property/list/components/ListFiltersContent'
import { fetchPropertyCities } from '@doinn/shared/src/containers/property-cities/actions'
import { fetchEmployees } from '@doinn/vendor/src/containers/staff/employees/actions'
import { fetchPropertySpaceCategories } from '@doinn/shared/src/containers/property-space-categories/actions'
import { fetchPropertySources } from '@doinn/shared/src/containers/property-sources/actions'
import { fetchPropertyBusinessAccounts } from '@doinn/shared/src/containers/property-business-accounts/actions'
import { useDidMount } from '@doinn/shared/src/hooks'
import withAuthorization from '@doinn/shared/src/hoc/authorization/withAuthorization'
import { addOrRemove } from '@doinn/shared/src/util/array'

const userRole = 'vendor'

const DEFAULT_ROWS_PER_PAGE = 30

const mapIdsToFilter = items => {
  if (!items || !items.length) return []
  return items
    .map(item => {
      if (typeof item === 'object') {
        return item.id ? item.id : ''
      }
      return item
    })
    .filter(item => !!item)
}

const parseGetPropertiesAutocompleteOptionsParams = ({
  search = '',
  limit = DEFAULT_ROWS_PER_PAGE,
  loggedUser,
  filters
} = {}) => {
  const params = {
    search,
    limit
  }
  if (loggedUser?.business?.id) {
    if (loggedUser?.roles?.includes('vendor')) {
      params.vendorBusinessAccountId = loggedUser.business.id
    }
    if (loggedUser?.roles?.includes('host')) {
      params.hostBusinessAccountId = loggedUser.business.id
    }
  }
  if (filters) {
    if (filters.spaces) {
      params.ids = mapIdsToFilter(filters.spaces)
    }
  }
  return params
}

const getAutocompleteOptions =
  loggedUser => async (filterName, value, filters) => {
    const callbacks = {
      spaces: fetchPropertiesElasticsearchApi
    }
    if (callbacks[filterName]) {
      const params = parseGetPropertiesAutocompleteOptionsParams({
        search: value || '',
        limit: DEFAULT_ROWS_PER_PAGE,
        loggedUser,
        filters
      })
      const response = await callbacks[filterName]({ params })
      return response.data.data || []
    }
    return []
  }

const ListFiltersContent = ({
  canAll,
  displayEmployeeEmptyOption,
  cities,
  hostBusinessAccounts,
  disabledFilters,
  employees,
  fetchPropertyCities,
  fetchEmployees,
  fetchPropertySources,
  fetchPropertySpaceCategories,
  fetchPropertyBusinessAccounts,
  filters,
  hiddenFilters,
  loggedUser,
  isCitiesLoading,
  isEmployeesLoading,
  isPropertySourcesLoading,
  isPropertyTypesLoading,
  isPropertyBusinessAccountsLoading,
  onChangeFilters,
  types,
  sources
}) => {
  const [selectedProperties, setSelectedProperties] = React.useState([])

  useDidMount(() => {
    if (!isCitiesLoading) {
      fetchPropertyCities({
        flags: ['vendor'],
        userRole
      })
    }
    if (
      canAll(['read-people-staff', 'update-space-staff']) &&
      !isEmployeesLoading
    ) {
      fetchEmployees()
    }
    if (loggedUser?.roles?.includes('host') && !isPropertySourcesLoading) {
      fetchPropertySources()
    }
    if (
      loggedUser?.roles?.includes('vendor') &&
      !isPropertyBusinessAccountsLoading
    ) {
      fetchPropertyBusinessAccounts()
    }
    if (!isPropertyTypesLoading) {
      fetchPropertySpaceCategories()
    }
  })

  const memoizedGetAutocompleteOptions = React.useMemo(() => {
    return getAutocompleteOptions(loggedUser)
  }, [loggedUser])

  React.useEffect(() => {
    const fetchSelectedProperties = async () => {
      let newSelectedProperties = []
      if (filters.spaces && filters.spaces.length) {
        newSelectedProperties = await memoizedGetAutocompleteOptions(
          'spaces',
          '',
          { spaces: filters.spaces }
        )
      }
      setSelectedProperties(newSelectedProperties)
    }
    fetchSelectedProperties()
  }, [filters.spaces, memoizedGetAutocompleteOptions])

  const handleChangeFilter = useCallback(
    (filterName, newValue) => {
      const oldValue = filters[filterName]
      const newValueAsString =
        typeof newValue === 'object' &&
        !Array.isArray(newValue) &&
        newValue !== null
          ? String(newValue.id)
          : newValue
      const parsedNewValue = Array.isArray(oldValue)
        ? addOrRemove(oldValue, newValueAsString)
        : newValueAsString
      const params = {
        ...filters,
        page: 1
      }
      params[filterName] = parsedNewValue
      onChangeFilters && onChangeFilters(params)
    },
    [filters, onChangeFilters]
  )

  const filtersProps = {
    disabledFilters,
    hiddenFilters,
    displayEmployeeEmptyOption,
    isCitiesLoading,
    isPropertyBusinessAccountsLoading,
    isPropertySourcesLoading,
    isPropertyTypesLoading: isPropertyTypesLoading,
    isEmployeesLoading: isEmployeesLoading,
    cities: [...cities],
    hostBusinessAccounts: [...hostBusinessAccounts],
    sources: [...sources],
    types: [...types],
    employees: [...employees],
    filters: { ...filters, spaces: selectedProperties },
    onChange: handleChangeFilter,
    getAutocompleteOptions: memoizedGetAutocompleteOptions
  }

  return <ListFiltersContentComponent {...filtersProps} />
}

const mapStateToProps = state => {
  const { list: employeesList, isLoading: isEmployeesLoading } = state.employees
  const { list: propertyCitiesList, isLoading: isPropertyCitiesLoading } =
    state.propertyCities
  const { list: propertyTypesList, isLoading: isPropertyTypesLoading } =
    state.propertySpaceCategories
  const { list: propertySourcesList, isLoading: isPropertySourcesLoading } =
    state.propertySources
  const {
    list: propertyBusinessAccountsList,
    isLoading: isPropertyBusinessAccountsLoading
  } = state.propertyBusinessAccounts
  return {
    loggedUser: state.user.loggedUser,
    cities: propertyCitiesList,
    sources: propertySourcesList,
    employees: employeesList,
    types: propertyTypesList,
    hostBusinessAccounts: propertyBusinessAccountsList,
    isEmployeesLoading,
    isCitiesLoading: isPropertyCitiesLoading,
    isPropertyTypesLoading,
    isPropertySourcesLoading,
    isPropertyBusinessAccountsLoading
  }
}

const mapDispatchToProps = dispatch => ({
  fetchPropertyCities: bindActionCreators(fetchPropertyCities, dispatch),
  fetchEmployees: bindActionCreators(fetchEmployees, dispatch),
  fetchPropertySources: bindActionCreators(fetchPropertySources, dispatch),
  fetchPropertySpaceCategories: bindActionCreators(
    fetchPropertySpaceCategories,
    dispatch
  ),
  fetchPropertyBusinessAccounts: bindActionCreators(
    fetchPropertyBusinessAccounts,
    dispatch
  )
})

ListFiltersContent.propTypes = {
  filters: PropTypes.object.isRequired,
  displayEmployeeEmptyOption: PropTypes.bool
}

ListFiltersContent.defaultProps = {}

export default compose(
  withAuthorization,
  connect(mapStateToProps, mapDispatchToProps)
)(ListFiltersContent)
