import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { editReservationApi } from '@doinn/vendor/src/api/reservations'
import {
  RESERVATION_EDIT_FAILED,
  RESERVATION_EDIT_REQUESTED,
  RESERVATION_EDIT_SUCCEEDED
} from '@doinn/vendor/src/containers/reservation/edit/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* editReservation(action) {
  try {
    const { params } = action.payload
    const response = yield call(editReservationApi, params)
    const { data } = response.data

    yield put({
      type: RESERVATION_EDIT_SUCCEEDED,
      payload: { data },
      snackbar: {
        variant: 'success',
        message: i18n.t('Reservation has been edited successfully!')
      }
    })
  } catch (e) {
    yield put({
      type: RESERVATION_EDIT_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* reservationEditSaga() {
  yield all([takeLatest(RESERVATION_EDIT_REQUESTED, editReservation)])
}
