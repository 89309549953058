export const JOBS_FETCH_REQUESTED = 'JOBS_FETCH_REQUESTED'
export const JOBS_FETCH_SUCCEEDED = 'JOBS_FETCH_SUCCEEDED'
export const JOBS_FETCH_FAILED = 'JOBS_FETCH_FAILED'

export const JOBS_FETCH_MORE_REQUESTED = 'JOBS_FETCH_MORE_REQUESTED'
export const JOBS_FETCH_MORE_SUCCEEDED = 'JOBS_FETCH_MORE_SUCCEEDED'
export const JOBS_FETCH_MORE_FAILED = 'JOBS_FETCH_MORE_FAILED'

export const JOBS_FETCH_REPORT_URL_REQUESTED = 'JOBS_FETCH_REPORT_URL_REQUESTED'
export const JOBS_FETCH_REPORT_URL_SUCCEEDED = 'JOBS_FETCH_REPORT_URL_SUCCEEDED'
export const JOBS_FETCH_REPORT_URL_FAILED = 'JOBS_FETCH_REPORT_URL_FAILED'

export const JOBS_UPDATE_SELECTED_JOBS = 'JOBS_UPDATE_SELECTED_JOBS'

export const JOBS_FINISHED_LIST_EMPTIED = 'JOBS_FINISHED_LIST_EMPTIED'

export const JOB_RESET_EXECUTION_STATE = 'JOB_RESET_EXECUTION_STATE'
