export const STRIPE_PAYMENT_SETUP_OPEN = 'STRIPE_PAYMENT_SETUP_OPEN'
export const STRIPE_PAYMENT_SETUP_SUCCEEDED = 'STRIPE_SETUP_SUCCEEDED'
export const STRIPE_PAYMENT_SETUP_FAILED = 'STRIPE_SETUP_FAILED'
export const STRIPE_PAYMENT_SETUP_CLOSE = 'STRIPE_PAYMENT_SETUP_CLOSE'

export const STRIPE_PAYMENT_SETUP_READY = 'STRIPE_PAYMENT_SETUP_READY'
export const STRIPE_PAYMENT_SETUP_SAVING = 'STRIPE_PAYMENT_SETUP_SAVING'
export const STRIPE_PAYMENT_SETUP_ERROR = 'STRIPE_PAYMENT_SETUP_ERROR'

export const STRIPE_PAYMENT_SETUP_SAVING_REQUESTED =
  'STRIPE_PAYMENT_SETUP_SAVING_REQUESTED'
export const STRIPE_PAYMENT_SETUP_SAVING_SUCCEEDED =
  'STRIPE_PAYMENT_SETUP_SAVING_SUCCEEDED'
export const STRIPE_PAYMENT_SETUP_SAVING_FAILED =
  'STRIPE_PAYMENT_SETUP_SAVING_FAILED'

export const STRIPE_PAYMENT_SETUP_UPDATING_REQUESTED =
  'STRIPE_PAYMENT_SETUP_UPDATING_REQUESTED'
