import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Box, Typography, makeStyles } from '@material-ui/core'
import LoadingText from '@doinn/shared/src/components/common/loading/LoadingText'

const useStyles = makeStyles(theme => ({
  textDisabled: {
    color: theme.palette.text.disabled
  }
}))

const DetailsSectionLine = ({
  children,
  originalValue,
  label,
  extraGutterBottom,
  isLoading,
  isEditMode
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Box pb={extraGutterBottom ? 3 : 1}>
        <Typography variant='subtitle2' gutterBottom>
          <b>{label}</b>
        </Typography>
      </Box>
      {isLoading ? (
        <LoadingText textVariant='body1' width={100} />
      ) : isEditMode ? (
        <div>{children}</div>
      ) : (
        <>
          {originalValue ? (
            <Typography>{originalValue}</Typography>
          ) : (
            <Typography className={classes.textDisabled}>
              <i>{t('There is no information')}</i>
            </Typography>
          )}
        </>
      )}
    </>
  )
}

DetailsSectionLine.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node.isRequired
  ]).isRequired,
  isLoading: PropTypes.bool,
  isEditMode: PropTypes.bool
}

DetailsSectionLine.defaultProps = {
  isLoading: false,
  isEditMode: false
}

export default DetailsSectionLine
