import { saveAs } from 'file-saver'

export default store => next => action => {
  if (action.downloader) {
    const blob = new Blob([action.downloader.data], {
      type: action.downloader.type
    })
    saveAs(blob, action.downloader.name)
  }

  return next(action)
}
