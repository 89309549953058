import {
  AUTOMATIONS_FILTERS_FETCH_FAILED,
  AUTOMATIONS_FILTERS_FETCH_REQUESTED,
  AUTOMATIONS_FILTERS_FETCH_SUCCEEDED
} from './constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  filters: {
    catalogs: [],
    catalogues: [],
    providers: []
  },
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case AUTOMATIONS_FILTERS_FETCH_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING
      }
    case AUTOMATIONS_FILTERS_FETCH_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case AUTOMATIONS_FILTERS_FETCH_SUCCEEDED:
      return {
        ...state,
        filters: payload.data,
        status: STATUS.SUCCESS
      }
    default:
      return state
  }
}
