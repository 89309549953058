import {
  INTEGRATIONS_ACTIVATE_CONFIRM_CLOSE,
  INTEGRATIONS_ACTIVATE_CONFIRM_OPEN,
  INTEGRATIONS_ACTIVATE_FAILED,
  INTEGRATIONS_ACTIVATE_REQUESTED,
  INTEGRATIONS_ACTIVATE_SUCCEEDED
} from '@doinn/host/src/containers/integrations/list/activate/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  isOpened: false,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case INTEGRATIONS_ACTIVATE_CONFIRM_OPEN:
      return {
        ...initialState,
        isOpened: true
      }
    case INTEGRATIONS_ACTIVATE_CONFIRM_CLOSE:
      return {
        ...initialState,
        isOpened: false
      }
    case INTEGRATIONS_ACTIVATE_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING
      }
    case INTEGRATIONS_ACTIVATE_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case INTEGRATIONS_ACTIVATE_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
