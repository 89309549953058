import {
  PROPERTY_ADD_DIALOG_CLOSE,
  PROPERTY_ADD_DIALOG_OPEN,
  PROPERTY_ADD_FAILED,
  PROPERTY_ADD_REQUESTED,
  PROPERTY_ADD_SUCCEEDED
} from '@doinn/shared/src/containers/property/add/constants'

export const STATUS = {
  IDLE: 'idle',
  SAVING: 'saving',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  lastSavedItem: null,
  isOpened: false,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case PROPERTY_ADD_DIALOG_OPEN:
      return {
        ...initialState,
        isOpened: true
      }
    case PROPERTY_ADD_DIALOG_CLOSE:
      return {
        ...initialState,
        isOpened: false
      }
    case PROPERTY_ADD_REQUESTED:
      return {
        ...state,
        lastSavedItem: null,
        status: STATUS.SAVING
      }
    case PROPERTY_ADD_SUCCEEDED:
      return {
        ...state,
        lastSavedItem: { ...payload },
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case PROPERTY_ADD_FAILED:
      return {
        ...state,
        lastSavedItem: null,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
