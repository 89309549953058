import React from 'react'
import PropTypes from 'prop-types'
import SelectionList from '@doinn/shared/src/components/common/filter/SelectionList'

const CheckboxList = props => <SelectionList {...props} type='checkbox' />

CheckboxList.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  checkedItems: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  subheader: PropTypes.string,
  filterName: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  isMenu: PropTypes.bool,
  onChange: PropTypes.func
}

CheckboxList.defaultProps = {}

export default CheckboxList
