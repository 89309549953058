/* eslint-disable no-template-curly-in-string */
import { array, object, string } from 'yup'
import { isEmpty } from 'lodash'

import i18n from '@doinn/shared/src/config/i18n'

const isCompanyNameValid = (name, context) => {
  return context?.parent?.step !== 2 || !isEmpty(name)
}

const getOnboardingSurveyCustomerSchema = () =>
  object({
    numberProperties: string().required(i18n.t('Required')),
    likeToDo: array().of(string()).min(1, i18n.t('Required')),
    servicesPerMonth: string().required(i18n.t('Required')),
    automatedWorkflow: array().of(string()).min(1, i18n.t('Required')),
    companyName: string().test(
      'is-companyname-valid',
      i18n.t('Required'),
      isCompanyNameValid
    )
  })

const getOnboardingSurveyProviderSchema = () =>
  object({
    numberProperties: string().required(i18n.t('Required')),
    likeToDo: array().of(string()).min(1, i18n.t('Required')),
    servicesPerMonth: string().required(i18n.t('Required')),
    companyName: string().test(
      'is-companyname-valid',
      i18n.t('Required'),
      isCompanyNameValid
    )
  })

let onboardingSurveyCustomerSchema = getOnboardingSurveyCustomerSchema()
let onboardingSurveyProviderSchema = getOnboardingSurveyProviderSchema()

i18n.on('languageChanged init', () => {
  onboardingSurveyCustomerSchema = getOnboardingSurveyCustomerSchema()
  onboardingSurveyProviderSchema = getOnboardingSurveyProviderSchema()
})

export { onboardingSurveyCustomerSchema, onboardingSurveyProviderSchema }
