import {
  JOB_EXTRA_SERVICE_ADD_FORM_CLOSE_REQUESTED,
  JOB_EXTRA_SERVICE_ADD_FORM_OPEN_REQUESTED,
  JOB_EXTRA_SERVICE_ADD_FORM_SAVE_FAILED,
  JOB_EXTRA_SERVICE_ADD_FORM_SAVE_REQUESTED,
  JOB_EXTRA_SERVICE_ADD_FORM_SAVE_SUCCEEDED
} from 'containers/job/extra-service/add/constants'

export const STATUS = {
  IDLE: 'idle',
  SAVING: 'saving',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  isOpened: false,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case JOB_EXTRA_SERVICE_ADD_FORM_OPEN_REQUESTED:
      return {
        ...initialState,
        isOpened: true
      }
    case JOB_EXTRA_SERVICE_ADD_FORM_CLOSE_REQUESTED:
      return {
        ...initialState,
        isOpened: false
      }
    case JOB_EXTRA_SERVICE_ADD_FORM_SAVE_REQUESTED:
      return {
        ...state,
        status: STATUS.SAVING
      }
    case JOB_EXTRA_SERVICE_ADD_FORM_SAVE_SUCCEEDED:
      return {
        ...initialState,
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case JOB_EXTRA_SERVICE_ADD_FORM_SAVE_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
