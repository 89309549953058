import React from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'

import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import TemplateBlank from '@doinn/shared/src/components/templates/TemplateBlank'

const WaitingComponent = loadable(() =>
  lazyRetry(() => import('components/onboarding/CompletedStep'))
)

const Waiting = () => {
  const { t } = useTranslation()

  return (
    <TemplateBlank>
      <Helmet>
        <title>{t('Waiting')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <WaitingComponent />
      </React.Suspense>
    </TemplateBlank>
  )
}

export default Waiting
