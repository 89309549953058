import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import {
  fetchCityServicesApi,
  saveCityServicesApi
} from 'api/contracts/city-services'
import {
  CONTRACT_CITY_SERVICES_FETCH_FAILED,
  CONTRACT_CITY_SERVICES_FETCH_REQUESTED,
  CONTRACT_CITY_SERVICES_FETCH_SUCCEEDED,
  CONTRACT_CITY_SERVICES_SAVE_FAILED,
  CONTRACT_CITY_SERVICES_SAVE_REQUESTED,
  CONTRACT_CITY_SERVICES_SAVE_SUCCEEDED
} from 'containers/onboarding/steps/CityServices/constants'
import { ONBOARDING_NEXT_STEP_REQUESTED } from 'containers/onboarding/constants'

function* fetchCityServices(action) {
  try {
    const response = yield call(fetchCityServicesApi)
    const propertyTypeServicesResponse = response.data
    yield put({
      type: CONTRACT_CITY_SERVICES_FETCH_SUCCEEDED,
      payload: propertyTypeServicesResponse
    })
  } catch (e) {
    yield put({
      type: CONTRACT_CITY_SERVICES_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* saveCityServices(action) {
  try {
    yield call(saveCityServicesApi, {
      selectedCityServices: action.payload.selectedCityServices
    })
    yield put({
      type: ONBOARDING_NEXT_STEP_REQUESTED
    })
    yield put({
      type: CONTRACT_CITY_SERVICES_SAVE_SUCCEEDED
    })
  } catch (e) {
    yield put({
      type: CONTRACT_CITY_SERVICES_SAVE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* cityServicesSagas() {
  yield all([
    takeLatest(CONTRACT_CITY_SERVICES_FETCH_REQUESTED, fetchCityServices),
    takeLatest(CONTRACT_CITY_SERVICES_SAVE_REQUESTED, saveCityServices)
  ])
}
