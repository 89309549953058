import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { editCatalogueDescriptionMyApi } from '@doinn/shared/src/api/catalogues'
import {
  CATALOGUE_MY_EDIT_DESCRIPTION_FAILED,
  CATALOGUE_MY_EDIT_DESCRIPTION_REQUESTED,
  CATALOGUE_MY_EDIT_DESCRIPTION_SUCCEEDED
} from '@doinn/shared/src/containers/catalogue/my/edit-description/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* editCatalogueDescription(action) {
  try {
    const { catalogue } = action.payload
    const response = yield call(editCatalogueDescriptionMyApi, catalogue)
    yield put({
      type: CATALOGUE_MY_EDIT_DESCRIPTION_SUCCEEDED,
      payload: response.data,
      snackbar: {
        variant: 'success',
        message: i18n.t('The catalogue has been edited successfully')
      }
    })
  } catch (e) {
    yield put({
      type: CATALOGUE_MY_EDIT_DESCRIPTION_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* cataloguesEditSaga() {
  yield all([
    takeLatest(
      CATALOGUE_MY_EDIT_DESCRIPTION_REQUESTED,
      editCatalogueDescription
    )
  ])
}
