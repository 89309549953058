import React from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'

import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import TemplateDefault from 'components/TemplateDefault'

const AutomationsAddContainer = loadable(() =>
  lazyRetry(() =>
    import('@doinn/host/src/containers/automations/add/AutomationsAdd')
  )
)

const AutomationsAdd = () => {
  const { t } = useTranslation()
  return (
    <TemplateDefault title={t('Automations')}>
      <Helmet>
        <title>{t('Automations')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <AutomationsAddContainer />
      </React.Suspense>
    </TemplateDefault>
  )
}

export default AutomationsAdd
