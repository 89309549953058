import {
  ACCEPT_TERMS_AND_CONDITIONS_FAILED,
  ACCEPT_TERMS_AND_CONDITIONS_REQUESTED,
  ACCEPT_TERMS_AND_CONDITIONS_SUCCEEDED,
  TOGGLE_ACCEPT_TERMS_CONDITIONS
} from 'containers/onboarding/steps/Terms/constants'

export const initialState = {
  isAccepted: false,
  isLoading: false
}

export default (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case TOGGLE_ACCEPT_TERMS_CONDITIONS:
      return {
        ...state,
        isAccepted: !state.isAccepted
      }

    case ACCEPT_TERMS_AND_CONDITIONS_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case ACCEPT_TERMS_AND_CONDITIONS_SUCCEEDED:
      return {
        ...state,
        isLoading: false
      }
    case ACCEPT_TERMS_AND_CONDITIONS_FAILED:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
