import React, { useCallback, useEffect } from 'react'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core'
import { Formik } from 'formik'
import { isEmpty, omit } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { usePrevious } from '@doinn/shared/src/hooks'
import {
  getInitialSetup,
  getMetadataFromQuestions
} from '@doinn/shared/src/containers/onboarding/saas-announcement/util'
import {
  close,
  onboardingSurvey
} from '@doinn/shared/src/containers/onboarding/saas-announcement/actions'
import { STATUS } from '@doinn/shared/src/containers/onboarding/saas-announcement/reducers'
import { saasAnnouncementSchema } from '@doinn/shared/src/containers/onboarding/saas-announcement/validation'
import { useSaasAnnouncementForm } from '@doinn/shared/src/containers/onboarding/saas-announcement/hooks/useSaasAnnouncementForm'
import Dialog from '@doinn/shared/src/components/common/dialog/Dialog'
import DialogHeader from '@doinn/shared/src/components/common/dialog/DialogHeader'
import ButtonWithLoading from '@doinn/shared/src/components/common/ButtonWithLoading'
import SaasAnnouncementForm from '@doinn/shared/src/containers/onboarding/saas-announcement/components/SaasAnnouncementForm'

const useStyles = makeStyles(theme => {
  return {
    dialogContent: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(0),
      '&::-webkit-scrollbar': {
        width: '7px'
      },

      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1'
      },

      '&::-webkit-scrollbar-thumb': {
        background: '#888'
      }
    },
    header: {
      paddingBottom: theme.spacing(0),
      marginBottom: theme.spacing(0)
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-start'
    },
    title: {
      fontSize: theme.typography.pxToRem(36)
    }
  }
})

const SaasAnnouncementDialog = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { isOpened, status } = useSelector(
    state => state.onboardingSaasAnnouncement
  )
  const loggedUser = useSelector(state => state.user.loggedUser)
  const { data: userLoggedInAs } = useSelector(state => state.userLoggedInAs)

  const previousIsOpened = usePrevious(isOpened)
  const {
    state: { numberOfSteps, initialValues, activeOptions, options },
    onInitialStateChange,
    onNextStep
  } = useSaasAnnouncementForm()

  const isSaving = status === STATUS.LOADING

  useEffect(() => {
    if (previousIsOpened !== isOpened) {
      onInitialStateChange(
        getInitialSetup(
          loggedUser?.business?.saasAnnouncementOnboarding?.type || 'customer'
        )
      )
    }
  }, [isOpened, loggedUser, onInitialStateChange, previousIsOpened])

  const headerProps = useCallback(
    formProps => {
      if (formProps?.values?.step === 0) {
        return {
          overline: '🎉🍾',
          title: (
            <Typography component='div' className={classes.title}>
              <b>
                {t(
                  'We’re so excited to announce that Doinn is now an All-in-one platform.'
                )}
              </b>
            </Typography>
          ),
          subtitle: (
            <Typography component='div' variant='body1'>
              {t(
                'In order to keep improving the platform to provide you with an even better experience, we’d like to ask some questions. We promise it won’t take more than a minute.'
              )}
            </Typography>
          )
        }
      }

      return {
        overline: `${formProps?.values?.step}/${numberOfSteps}`,
        title: (
          <Typography component='div' className={classes.title}>
            <b>{t('Help us to further enhance the Doinn platform for you.')}</b>
          </Typography>
        ),
        subtitle: (
          <>
            <Typography component='div' variant='body1'>
              {t('We promise it won’t take more than a minute.')}
            </Typography>
            <Box py={2}>
              <Divider />
            </Box>
          </>
        )
      }
    },
    [classes, numberOfSteps, t]
  )

  const ctaText = useCallback(
    step => {
      if (step === numberOfSteps) {
        return t('Save and keep using Doinn')
      }

      return t('Continue')
    },
    [numberOfSteps, t]
  )

  const handleClose = useCallback(
    (event, reason) => {
      if (reason && reason === 'backdropClick') return
      dispatch(close())
    },
    [dispatch]
  )

  const handleClick = useCallback(
    formProps => () => {
      if (formProps?.values?.step === numberOfSteps) {
        return formProps?.handleSubmit()
      }

      formProps?.setFieldValue('step', formProps?.values?.step + 1)
      onNextStep()
    },
    [numberOfSteps, onNextStep]
  )

  const handleCloseClick = useCallback(() => {
    dispatch(close())
  }, [dispatch])

  const handleClickSave = useCallback(
    values => {
      const omitFields = ['companyName', 'step', 'place']
      if (
        ![loggedUser?.business?.saasAnnouncementOnboarding?.type].includes(
          'customer'
        )
      ) {
        omitFields.push('automatedWorkflow')
      }
      const data = {
        metadata: getMetadataFromQuestions(options, omit(values, omitFields)),
        companyName: values?.companyName,
        placeId: values?.place?.placeId
      }
      dispatch(onboardingSurvey(data))
    },
    [dispatch, loggedUser, options]
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={saasAnnouncementSchema}
      onSubmit={handleClickSave}
      enableReinitialize
    >
      {formProps => (
        <Dialog open={isOpened} onClose={handleClose} disableEscapeKeyDown>
          <DialogHeader
            classname={classes.header}
            {...headerProps(formProps)}
          />
          {formProps?.values?.step > 0 && (
            <DialogContent className={classes.dialogContent}>
              <SaasAnnouncementForm
                formProps={formProps}
                questionsArray={activeOptions}
              />
            </DialogContent>
          )}
          <DialogActions className={classes.actions}>
            {!isEmpty(userLoggedInAs) && (
              <Button variant='outlined' onClick={handleCloseClick}>
                {t('Close')}
              </Button>
            )}
            <Box>
              <ButtonWithLoading
                aria-label={ctaText(formProps?.values?.step)}
                variant='contained'
                color='primary'
                edge='end'
                fullWidth
                isLoading={isSaving}
                disabled={
                  (!formProps.isValid && formProps?.values?.step > 0) ||
                  isSaving
                }
                onClick={handleClick(formProps)}
              >
                {ctaText(formProps?.values?.step)}
              </ButtonWithLoading>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

export default SaasAnnouncementDialog
