import DoinnApi, { DoinnBlobApi } from '@doinn/shared/src/config/doinnApi'

export function fetchPriceListApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/vendor/price-list`, config)
}

export function downloadPriceListApi({ contract, params }) {
  const config = { params }
  return DoinnBlobApi.get(`/reports/pricing/${contract.id}/download`, config)
}
