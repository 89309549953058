import {
  CONTRACT_CITY_SERVICES_FETCH_REQUESTED,
  CONTRACT_CITY_SERVICES_FETCH_SUCCEEDED,
  CONTRACT_CITY_SERVICES_SAVE_FAILED,
  CONTRACT_CITY_SERVICES_SAVE_REQUESTED,
  CONTRACT_CITY_SERVICES_SAVE_SUCCEEDED
} from 'containers/onboarding/steps/CityServices/constants'

export const initialState = {
  cityServices: [],
  isLoading: false,
  isSavingCityServices: false
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case CONTRACT_CITY_SERVICES_FETCH_REQUESTED:
      return {
        ...state,
        cityServices: [],
        isLoading: true
      }
    case CONTRACT_CITY_SERVICES_FETCH_SUCCEEDED:
      return {
        ...state,
        cityServices: payload.data,
        isLoading: false
      }
    case CONTRACT_CITY_SERVICES_SAVE_REQUESTED:
      return {
        ...state,
        isSavingCityServices: true
      }
    case CONTRACT_CITY_SERVICES_SAVE_SUCCEEDED:
      return {
        ...state,
        isSavingCityServices: false
      }
    case CONTRACT_CITY_SERVICES_SAVE_FAILED:
      return {
        ...state,
        isSavingCityServices: false
      }
    default:
      return state
  }
}
