import { all, call, put, takeLatest } from 'redux-saga/effects'
import i18n from '@doinn/shared/src/config/i18n'
import { saveBillingApi } from '@doinn/shared/src/api/billing'
import {
  BILLING_EDIT_FAILED,
  BILLING_EDIT_REQUESTED,
  BILLING_EDIT_SUCCEEDED
} from '@doinn/shared/src/containers/subscription-billing/billing/edit/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

export function* editBilling(action) {
  try {
    const { data } = action.payload
    yield call(saveBillingApi, { data })

    const messageText =
      data?.type === 'account'
        ? i18n.t('The general billing has been edited successfuly!')
        : i18n.t('The group billing has been edited successfuly!')

    yield put({
      type: BILLING_EDIT_SUCCEEDED,
      payload: data,
      snackbar: {
        variant: 'success',
        message: messageText
      }
    })
  } catch (e) {
    yield put({
      type: BILLING_EDIT_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* billingsEditSaga() {
  yield all([takeLatest(BILLING_EDIT_REQUESTED, editBilling)])
}
