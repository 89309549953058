import React, { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { cloneDeep, isEmpty, isEqual as isObjEqual } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import {
  getNestedQueryStringObject,
  updateURLNestedParams
} from '@doinn/shared/src/util/url'
import { parseAppliedDynamicFilters } from '@doinn/shared/src/util/filters'
import { getAllParamsButParam } from '@doinn/shared/src/util/object'
import {
  useDidMount,
  useFilterState,
  usePrevious
} from '@doinn/shared/src/hooks'
import { METABASE_URL } from 'config/env'
import TemplateDefault from 'components/TemplateDefault'
import MetabaseDashboards from 'components/metabase/MetabaseDashboards'
import HelperCard from '@doinn/shared/src/components/common/helper/HelperCard'

const SAVED_FILTERS_KEY = 'scenesInsightsAppliedFilters'

const getDefaultFilters = () => {
  return {}
}

const getLastSavedFilters = () => {
  try {
    return JSON.parse(window.sessionStorage.getItem(SAVED_FILTERS_KEY))
  } catch (e) {}
  return {}
}

const saveFilters = filters => {
  try {
    const filtersWithoutPage = getAllParamsButParam(filters, 'page')
    return window.sessionStorage.setItem(
      SAVED_FILTERS_KEY,
      JSON.stringify(filtersWithoutPage)
    )
  } catch (e) {}
  return {}
}

const getQueryOrSavedFilters = location => {
  const queryFilters = getNestedQueryStringObject(location.search)
  let filters = cloneDeep(getDefaultFilters())
  if (isEmpty(queryFilters)) {
    filters = {
      ...cloneDeep(getDefaultFilters()),
      ...cloneDeep(getLastSavedFilters())
    }
  } else {
    filters = parseAppliedDynamicFilters(filters, queryFilters)
  }
  return filters
}

const Insights = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const previousLocation = usePrevious(location)
  const [justChangedLocation, setJustChangedLocation] = React.useState(false)
  const { appliedFilters, hasAppliedFiltersChanges, onChangeAppliedFilters } =
    useFilterState(getDefaultFilters())

  useDidMount(() => {
    onChangeAppliedFilters(getQueryOrSavedFilters(location))
  })

  React.useEffect(() => {
    if (!previousLocation) return
    setJustChangedLocation(
      !isObjEqual(location.search, previousLocation.search)
    )
  }, [location, previousLocation])

  React.useEffect(() => {
    if (!justChangedLocation) return
    saveFilters(appliedFilters)

    const savedFilters = getQueryOrSavedFilters(location)
    const shouldUpdateAppliedFilters = !isObjEqual(appliedFilters, savedFilters)

    if (shouldUpdateAppliedFilters) {
      saveFilters(appliedFilters)
    }
  }, [appliedFilters, justChangedLocation, location, onChangeAppliedFilters])

  React.useEffect(() => {
    const shouldUpdateUrl =
      hasAppliedFiltersChanges &&
      !isObjEqual(appliedFilters, getQueryOrSavedFilters(location))
    if (shouldUpdateUrl) {
      updateURLNestedParams(appliedFilters, history)
    }
  }, [appliedFilters, hasAppliedFiltersChanges, history, location])

  const handleChangeAppliedFilters = useCallback(
    filters => {
      onChangeAppliedFilters(filters)
    },
    [onChangeAppliedFilters]
  )

  return (
    <TemplateDefault title={t('Insights')} helperContent={<HelperCard />}>
      <Helmet>
        <title>{t('Insights')}</title>
        <script src={`${METABASE_URL}/app/iframeResizer.js`} async></script>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <MetabaseDashboards
          filters={appliedFilters}
          onChangeFilters={handleChangeAppliedFilters}
        />
      </React.Suspense>
    </TemplateDefault>
  )
}

export default Insights
