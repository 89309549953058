import React from 'react'
import PropTypes from 'prop-types'

import RefreshIndicator from '@doinn/shared/src/components/common/virtual-list/RefreshIndicator'

const initialState = {
  touchStart: null,
  drag: 0,
  dragging: false
}

function PullToRefresh({
  children,
  indicatorSize,
  maxDrag,
  onRefresh,
  style,
  disabled,
  resistance,
  canRefresh,
  ...otherProps
}) {
  const [state, setState] = React.useState(initialState)

  const reset = () => {
    setState(initialState)
  }

  React.useEffect(() => {
    if (!canRefresh && state.touchStart) {
      reset()
    }
  }, [canRefresh, state.touchStart])

  const onTouchStart = event => {
    if (canRefresh) {
      setState({
        ...state,
        touchStart: event.touches[0]
      })
    }
  }

  const onTouchMove = event => {
    if (!state.touchStart) {
      return
    }
    const drag = Math.max(
      0,
      event.touches[0].clientY / resistance -
        state.touchStart.clientY / resistance
    )
    if (drag !== 0) {
      setState({
        ...state,
        drag: Math.min(drag, maxDrag),
        dragging: true
      })
    } else if (!state.dragging) {
      reset()
    }
  }

  const onTouchEnd = () => {
    if (state.drag >= maxDrag) {
      onRefresh && onRefresh()
    }
    reset()
  }

  if (disabled) {
    return <div>{children}</div>
  }

  return (
    <div
      {...otherProps}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onTouchCancel={reset}
    >
      <RefreshIndicator
        isRefreshing={state.dragging || state.touchStart}
        size={indicatorSize}
        drag={state.drag}
        maxDrag={maxDrag}
      />
      {children}
    </div>
  )
}

PullToRefresh.propTypes = {
  children: PropTypes.node,
  indicatorSize: PropTypes.number,
  maxDrag: PropTypes.number,
  onRefresh: PropTypes.func.isRequired,
  style: PropTypes.shape(),
  disabled: PropTypes.bool,
  resistance: PropTypes.number,
  canRefresh: PropTypes.bool
}

PullToRefresh.defaultProps = {
  children: null,
  indicatorSize: 40,
  maxDrag: 50,
  style: {},
  disabled: false,
  resistance: 1,
  canRefresh: false
}

export default PullToRefresh
