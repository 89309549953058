import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function fetchJobsApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/vendor/jobs`, config)
}

export function fetchJobsReportUrlApi(params = {}) {
  const config = { params }
  return DoinnApi.get(`/vendor/reports/jobs`, config)
}

export function updateJobsStaffApi(params) {
  const config = {}
  return DoinnApi.put(`/vendor/jobs/employees`, params, config)
}
