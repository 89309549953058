import {
  JOB_CHECKLIST_EDIT_FAILED,
  JOB_CHECKLIST_EDIT_REQUESTED,
  JOB_CHECKLIST_EDIT_SUCCEEDED
} from 'containers/job/checklist/edit/constants'

export const STATUS = {
  IDLE: 'idle',
  SAVING: 'saving',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  lastSavedItem: null,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case JOB_CHECKLIST_EDIT_REQUESTED:
      return {
        ...state,
        lastSavedItem: null,
        status: STATUS.SAVING
      }
    case JOB_CHECKLIST_EDIT_SUCCEEDED:
      return {
        ...state,
        lastSavedItem: { ...payload },
        status: STATUS.SUCCESS
      }
    case JOB_CHECKLIST_EDIT_FAILED:
      return {
        ...state,
        lastSavedItem: null,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
