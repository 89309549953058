import {
  ONBOARDING_FLOATING_DIALOG_CLOSE,
  ONBOARDING_FLOATING_DIALOG_FINISH_REQUESTED,
  ONBOARDING_FLOATING_DIALOG_FINISH_SUCCEEDED,
  ONBOARDING_FLOATING_DIALOG_OPEN,
  ONBOARDING_FLOATING_DIALOG_SYNC
} from '@doinn/shared/src/containers/onboarding/floating/constants'
import { USER_GET_SUCCEEDED } from '@doinn/shared/src/containers/user/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  status: STATUS.IDLE,
  isOpened: true,
  isCompleted: false,
  steps: []
}

const getState = ({ floatingOnboarding = {} }) => ({
  isOpened: ['pending', 'completed'].includes(floatingOnboarding?.status),
  isCompleted: ['completed'].includes(floatingOnboarding?.status),
  steps: floatingOnboarding?.steps || []
})

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case USER_GET_SUCCEEDED:
      return {
        ...initialState,
        ...getState(payload?.data?.business)
      }
    case ONBOARDING_FLOATING_DIALOG_OPEN:
      return {
        ...state,
        isOpened: true
      }
    case ONBOARDING_FLOATING_DIALOG_CLOSE:
      return {
        ...state,
        isOpened: false
      }
    case ONBOARDING_FLOATING_DIALOG_FINISH_REQUESTED:
      return {
        ...state,
        isOpened: false
      }
    case ONBOARDING_FLOATING_DIALOG_FINISH_SUCCEEDED:
      return {
        ...state,
        isOpened: false
      }
    case ONBOARDING_FLOATING_DIALOG_SYNC:
      return {
        ...state,
        ...getState(payload?.params)
      }
    default:
      return state
  }
}
