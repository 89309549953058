import { all, call, put, takeLatest } from 'redux-saga/effects'
import { deleteCatalogApi } from '@doinn/host/src/api/catalogs'
import {
  CATALOG_DELETE_FAILED,
  CATALOG_DELETE_REQUESTED,
  CATALOG_DELETE_SUCCEEDED
} from '@doinn/host/src/containers/catalog/delete/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* deleteCatalog(action) {
  try {
    const { catalogId } = action.payload
    yield call(deleteCatalogApi, catalogId)
    yield put({
      type: CATALOG_DELETE_SUCCEEDED,
      payload: action.payload
    })
  } catch (e) {
    yield put({
      type: CATALOG_DELETE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* catalogDeleteSaga() {
  yield all([takeLatest(CATALOG_DELETE_REQUESTED, deleteCatalog)])
}
