import { get, map } from 'lodash'
import {
  PROPERTY_ASSIGN_STAFF_FORM_CLOSE_REQUESTED,
  PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_CONFIRM_FAILED,
  PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_CONFIRM_REQUESTED,
  PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_CONFIRM_SUCCEEDED,
  PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_UNSELECTED,
  PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEE_TOGGLE,
  PROPERTY_ASSIGN_STAFF_FORM_GET_PROPERTY_FAILED,
  PROPERTY_ASSIGN_STAFF_FORM_GET_PROPERTY_REQUESTED,
  PROPERTY_ASSIGN_STAFF_FORM_GET_PROPERTY_SUCCEEDED,
  PROPERTY_ASSIGN_STAFF_FORM_OPEN_REQUESTED,
  PROPERTY_ASSIGN_STAFF_FORM_SEARCH_EMPLOYEES_REQUESTED,
  PROPERTY_ASSIGN_STAFF_FORM_TEAM_CONFIRM_FAILED,
  PROPERTY_ASSIGN_STAFF_FORM_TEAM_CONFIRM_REQUESTED,
  PROPERTY_ASSIGN_STAFF_FORM_TEAM_CONFIRM_SUCCEEDED,
  PROPERTY_ASSIGN_STAFF_FORM_TEAM_SELECTED,
  PROPERTY_ASSIGN_STAFF_FORM_TEAM_UNSELECTED
} from '@doinn/shared/src/containers/property/assign-staff/constants'

export const initialState = {
  isOpened: false,
  property: null,
  propertyEmployees: [],
  propertyTeam: null,
  isPropertyLoaded: false,
  isPropertyLoading: false,
  isPropertyFailed: false,
  selectedEmployees: [],
  selectedTeam: null,
  isConfirmLoading: false,
  isConfirmFailed: false,
  searchText: ''
}

const getPropertySelectedEmployee = property => {
  const { employees, team } = property
  if (employees.length) {
    return map(employees, employee => employee.id)
  }

  if (team && team.employees.length) {
    return map(team.employees, employee => employee.id)
  }

  return []
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case PROPERTY_ASSIGN_STAFF_FORM_OPEN_REQUESTED:
      return {
        ...initialState,
        isOpened: true,
        property: payload.property
      }
    case PROPERTY_ASSIGN_STAFF_FORM_CLOSE_REQUESTED:
      return {
        ...state,
        isOpened: false,
        property: null
      }
    case PROPERTY_ASSIGN_STAFF_FORM_GET_PROPERTY_REQUESTED:
      return {
        ...initialState,
        isPropertyLoaded: false,
        isPropertyLoading: true,
        isPropertyFailed: false,
        isOpened: true,
        property: state.property
      }
    case PROPERTY_ASSIGN_STAFF_FORM_GET_PROPERTY_SUCCEEDED:
      return {
        ...state,
        propertyEmployees: [...payload.data.employees],
        propertyTeam: get(payload.data, 'team', null),
        selectedEmployees: getPropertySelectedEmployee(payload.data),
        selectedTeam: get(payload.data, 'team.id', null),
        isPropertyLoaded: true,
        isPropertyLoading: false,
        isPropertyFailed: false,
        isOpened: true,
        isConfirmDisabled: !payload.data.team && !payload.data.employees.length
      }
    case PROPERTY_ASSIGN_STAFF_FORM_GET_PROPERTY_FAILED:
      return {
        ...state,
        isPropertyLoaded: false,
        isPropertyLoading: false,
        isPropertyFailed: true,
        isOpened: true
      }
    case PROPERTY_ASSIGN_STAFF_FORM_SEARCH_EMPLOYEES_REQUESTED:
      return {
        ...state,
        searchText: payload.params
      }
    case PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEE_TOGGLE:
      return {
        ...state,
        selectedEmployees: [...payload.employees]
      }
    case PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_UNSELECTED:
      return {
        ...state,
        selectedEmployees: []
      }
    case PROPERTY_ASSIGN_STAFF_FORM_TEAM_SELECTED:
      return {
        ...state,
        selectedTeam: payload.teamId
      }
    case PROPERTY_ASSIGN_STAFF_FORM_TEAM_UNSELECTED:
      return {
        ...state,
        selectedTeam: null
      }
    case PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_CONFIRM_REQUESTED:
    case PROPERTY_ASSIGN_STAFF_FORM_TEAM_CONFIRM_REQUESTED:
      return {
        ...state,
        isConfirmLoading: true,
        isConfirmFailed: false
      }
    case PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_CONFIRM_SUCCEEDED:
    case PROPERTY_ASSIGN_STAFF_FORM_TEAM_CONFIRM_SUCCEEDED:
      return {
        ...initialState,
        isConfirmLoading: false,
        isConfirmFailed: false
      }
    case PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_CONFIRM_FAILED:
    case PROPERTY_ASSIGN_STAFF_FORM_TEAM_CONFIRM_FAILED:
      return {
        ...state,
        isConfirmLoading: false,
        isConfirmFailed: true
      }
    default:
      return state
  }
}
