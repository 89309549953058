import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchReservationsApi } from '@doinn/vendor/src/api/reservations'
import {
  RESERVATIONS_FETCH_FAILED,
  RESERVATIONS_FETCH_REQUESTED,
  RESERVATIONS_FETCH_SUCCEEDED
} from '@doinn/vendor/src/containers/reservation/list/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchReservations(action) {
  try {
    const { start, end, ...otherParams } = action.payload.params
    const params = {
      date: start,
      end_date: end,
      ...otherParams
    }

    const response = yield call(fetchReservationsApi, params)
    const { data, meta } = response.data
    yield put({
      type: RESERVATIONS_FETCH_SUCCEEDED,
      payload: {
        data,
        meta
      }
    })
  } catch (e) {
    yield put({
      type: RESERVATIONS_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* reservationsSaga() {
  yield all([takeLatest(RESERVATIONS_FETCH_REQUESTED, fetchReservations)])
}
