import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography, makeStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(2)
  }
}))

const StripeKYCAlert = ({ type, onButtonClick, actionUrl }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  let title = t('Waiting for approval!')
  let description = t(
    'We are waiting for approval of your additional information to continue. Thank you for waiting!'
  )
  let actions = null
  if (type === 'requires_kyc') {
    title = t('We are almost there!')
    description = t(
      'You need to set up some details required to enable payouts to your company.'
    )
    actions = (
      <Box pt={2}>
        <Button
          color='primary'
          size='medium'
          variant='contained'
          href={actionUrl}
        >
          {t('Do it right now')}
        </Button>
        <Button
          color='primary'
          size='medium'
          variant='text'
          className={classes.button}
          onClick={onButtonClick}
        >
          {t('See more details')}
        </Button>
      </Box>
    )
  }
  return (
    <Box pb={4}>
      <Alert icon={false} severity='warning' elevation={10}>
        <Typography component='div'>
          <b>{title}</b>
        </Typography>
        <Typography component='div'>{description}</Typography>
        {actions}
      </Alert>
    </Box>
  )
}

StripeKYCAlert.propTypes = {
  type: PropTypes.oneOf(['accepted', 'waiting_approval', 'requires_kyc'])
}

export default StripeKYCAlert
