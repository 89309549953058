import { all, call, put, takeLatest } from 'redux-saga/effects'
import i18n from '@doinn/shared/src/config/i18n'
import { deleteBillingApi } from '@doinn/shared/src/api/billing'
import {
  BILLING_DELETE_FAILED,
  BILLING_DELETE_REQUESTED,
  BILLING_DELETE_SUCCEEDED
} from '@doinn/shared/src/containers/subscription-billing/billing/delete/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

export function* deleteBilling(action) {
  try {
    const { id, type } = action.payload
    yield call(deleteBillingApi, { id })

    const messageText =
      type === 'account'
        ? i18n.t('The general billing has been deleted successfuly!')
        : i18n.t('The group billing has been deleted successfuly!')

    yield put({
      type: BILLING_DELETE_SUCCEEDED,
      payload: { id, type },
      snackbar: {
        variant: 'success',
        message: messageText
      }
    })
  } catch (e) {
    yield put({
      type: BILLING_DELETE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* billingsDeleteSaga() {
  yield all([takeLatest(BILLING_DELETE_REQUESTED, deleteBilling)])
}
