import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import { fetchPropertyTypesApi } from '@doinn/vendor/src/api/property-types'
import {
  ONBOARDING_PROPERTY_TYPES_FETCH_FAILED,
  ONBOARDING_PROPERTY_TYPES_FETCH_REQUESTED,
  ONBOARDING_PROPERTY_TYPES_FETCH_SUCCEEDED
} from 'containers/onboarding/steps/PropertyTypes/constants'

function* fetchPropertyTypes(action) {
  try {
    const response = yield call(fetchPropertyTypesApi, {
      flags: ['template']
    })
    const propertyTypesResponse = response.data
    yield put({
      type: ONBOARDING_PROPERTY_TYPES_FETCH_SUCCEEDED,
      payload: propertyTypesResponse
    })
  } catch (e) {
    yield put({
      type: ONBOARDING_PROPERTY_TYPES_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* PropertyTypesSagas() {
  yield all([
    takeLatest(ONBOARDING_PROPERTY_TYPES_FETCH_REQUESTED, fetchPropertyTypes)
  ])
}
