export const JOB_SERVICE_DELETE_CONFIRM_OPEN = 'JOB_SERVICE_DELETE_CONFIRM_OPEN'
export const JOB_SERVICE_DELETE_CONFIRM_CLOSE =
  'JOB_SERVICE_DELETE_CONFIRM_CLOSE'

export const JOB_SERVICE_DELETE_REQUESTED = 'JOB_SERVICE_DELETE_REQUESTED'
export const JOB_SERVICE_DELETE_SUCCEEDED = 'JOB_SERVICE_DELETE_SUCCEEDED'
export const JOB_SERVICE_DELETE_FAILED = 'JOB_SERVICE_DELETE_FAILED'

export const JOB_SERVICE_DETAILS_SUCCEEDED = 'JOB_SERVICE_DETAILS_SUCCEEDED'
export const JOB_SERVICE_DETAILS_FAILED = 'JOB_SERVICE_DETAILS_FAILED'
