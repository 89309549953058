import { all, call, put, takeLatest } from 'redux-saga/effects'
import { editAutomationApi } from '@doinn/host/src/api/automations'
import {
  AUTOMATION_EDIT_FAILED,
  AUTOMATION_EDIT_REQUESTED,
  AUTOMATION_EDIT_SUCCEEDED
} from '@doinn/host/src/containers/automations/edit/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

export function* editAutomation(action) {
  try {
    yield call(editAutomationApi, action.payload.params)
    yield put({
      type: AUTOMATION_EDIT_SUCCEEDED,
      payload: action.payload.params
    })
  } catch (e) {
    yield put({
      type: AUTOMATION_EDIT_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* automationsEditSaga() {
  yield all([takeLatest(AUTOMATION_EDIT_REQUESTED, editAutomation)])
}
