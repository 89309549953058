import {
  AUTH_LOGIN_FAILED,
  AUTH_LOGIN_REQUESTED,
  AUTH_LOGIN_SUCCEEDED,
  AUTH_RECOVER_PASSWORD_FAILED,
  AUTH_RECOVER_PASSWORD_REQUESTED,
  AUTH_RECOVER_PASSWORD_RESET,
  AUTH_RECOVER_PASSWORD_SUCCEEDED
} from '@doinn/shared/src/containers/auth/constants'

export const initialState = {
  isLoginLoading: false,
  isLoginFailed: false,
  loginError: '',
  isRecoverPasswordLoading: false,
  isRecoverPasswordFailed: false,
  isRecoverPasswordSucceed: null,
  recoverPasswordError: ''
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case AUTH_LOGIN_REQUESTED:
      return {
        ...state,
        ...initialState,
        isLoginLoading: true,
        isLoginFailed: false,
        loginError: ''
      }
    case AUTH_LOGIN_SUCCEEDED:
      return {
        ...state,
        isLoginLoading: false,
        isLoginFailed: false,
        loginError: ''
      }
    case AUTH_LOGIN_FAILED:
      return {
        ...state,
        isLoginLoading: false,
        isLoginFailed: true,
        loginError: payload.error
      }
    case AUTH_RECOVER_PASSWORD_REQUESTED:
      return {
        ...state,
        ...initialState,
        isRecoverPasswordLoading: true,
        isRecoverPasswordFailed: false,
        isRecoverPasswordSucceed: null,
        recoverPasswordError: ''
      }
    case AUTH_RECOVER_PASSWORD_SUCCEEDED:
      return {
        ...state,
        isRecoverPasswordLoading: false,
        isRecoverPasswordFailed: false,
        isRecoverPasswordSucceed: true,
        recoverPasswordError: ''
      }
    case AUTH_RECOVER_PASSWORD_FAILED:
      return {
        ...state,
        isRecoverPasswordLoading: false,
        isRecoverPasswordFailed: true,
        isRecoverPasswordSucceed: null,
        recoverPasswordError: payload.error
      }
    case AUTH_RECOVER_PASSWORD_RESET:
      return {
        ...state,
        isRecoverPasswordLoading: false,
        isRecoverPasswordFailed: false,
        isRecoverPasswordSucceed: false,
        recoverPasswordError: ''
      }
    default:
      return state
  }
}
