import {
  TEAMS_FETCH_FAILED,
  TEAMS_FETCH_REQUESTED,
  TEAMS_FETCH_SUCCEEDED,
  TEAMS_UPDATE_SELECTED_TEAM_REQUESTED
} from '@doinn/vendor/src/containers/staff/teams/constants'

export const initialState = {
  list: [],
  selected: [],
  isLoaded: false,
  isLoading: false,
  isFailed: false
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case TEAMS_FETCH_REQUESTED:
      return {
        ...initialState,
        isLoaded: false,
        isLoading: true,
        isFailed: false
      }
    case TEAMS_FETCH_SUCCEEDED:
      return {
        ...state,
        list: [...payload.data],
        isLoaded: true,
        isLoading: false,
        isFailed: false
      }
    case TEAMS_FETCH_FAILED:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        isFailed: true
      }
    case TEAMS_UPDATE_SELECTED_TEAM_REQUESTED:
      return {
        ...state,
        selected: [...payload]
      }
    default:
      return state
  }
}
