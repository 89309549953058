import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { blockReservationApi } from '@doinn/vendor/src/api/reservations'
import {
  RESERVATION_BLOCK_FAILED,
  RESERVATION_BLOCK_REQUESTED,
  RESERVATION_BLOCK_SUCCEEDED
} from '@doinn/vendor/src/containers/reservation/block/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* blockReservation(action) {
  try {
    const { params } = action.payload
    const response = yield call(blockReservationApi, params)
    const { data } = response.data

    yield put({
      type: RESERVATION_BLOCK_SUCCEEDED,
      payload: { data },
      snackbar: {
        variant: 'success',
        message: i18n.t('The reservation has been blocked successfully!')
      }
    })
  } catch (e) {
    yield put({
      type: RESERVATION_BLOCK_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* reservationBlockSaga() {
  yield all([takeLatest(RESERVATION_BLOCK_REQUESTED, blockReservation)])
}
