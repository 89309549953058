import {
  endOfMonth,
  isAfter,
  isBefore,
  startOfMonth,
  subMonths
} from 'date-fns'

export const BANNER_SLUG = {
  trial: 'trial',
  card_expired: 'card_expired',
  card_expiring: 'card_expiring'
}

export const getSubscriptionSlugs = paymentMethods => {
  const defaultSubscriptionPaymentMethod =
    paymentMethods
      ?.filter(
        method => !!method?.default && method?.usage?.includes('subscription')
      )
      ?.pop() ?? null
  const info = defaultSubscriptionPaymentMethod?.info ?? {}

  if (!defaultSubscriptionPaymentMethod || !info?.expYear || !info?.expMonth)
    return []

  const today = new Date()
  const endOfCurrentMonthDate = endOfMonth(today)
  const expirationDate = endOfMonth(
    new Date(`${info.expYear}-${info.expMonth}-01`)
  )
  const expiringDate = subMonths(
    startOfMonth(new Date(`${info.expYear}-${info.expMonth}-01`)),
    1
  )

  if (isBefore(today, expiringDate)) return []
  if (isAfter(endOfCurrentMonthDate, expirationDate))
    return [BANNER_SLUG.card_expired]

  return [BANNER_SLUG.card_expiring]
}

export const getBannerSlugs = businessAccount => {
  if (!businessAccount) return []

  const subscription = businessAccount?.subscription ?? {}

  if (subscription?.inTrial) {
    return [BANNER_SLUG.trial]
  }

  return getSubscriptionSlugs(businessAccount?.defaultPaymentMethods)
}
