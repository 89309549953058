export const can = (ability = '', permissions = []) => {
  return permissions.includes(ability)
}

export const canAll = (abilities = [], permissions = []) => {
  try {
    if (Array.isArray(abilities)) {
      return abilities.every(ability => permissions.includes(ability))
    } else if (typeof abilities === 'string') {
      return can(abilities, permissions)
    }
  } catch (e) {}
  return false
}

export const canAny = (abilities = [], permissions = []) => {
  try {
    if (Array.isArray(abilities)) {
      return abilities.some(ability => permissions.includes(ability))
    } else if (typeof abilities === 'string') {
      return can(abilities, permissions)
    }
  } catch (e) {}
  return false
}

export const canNot = (ability = '', permissions = []) => {
  return !can(ability, permissions)
}

export const canNotAny = (abilities = [], permissions = []) => {
  return !canAny(abilities, permissions)
}
