import i18n from '@doinn/shared/src/config/i18n'
import { uniq } from 'lodash'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { downloadJobsReportApi } from '@doinn/shared/src/api/reports'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import {
  JOBS_DISPATCH_DROP_JOB_FAILED,
  JOBS_DISPATCH_DROP_JOB_REQUESTED,
  JOBS_REPORT_DOWNLOAD_FAILED,
  JOBS_REPORT_DOWNLOAD_REQUESTED,
  JOBS_REPORT_DOWNLOAD_SUCCEEDED
} from 'containers/jobs/dispatch/constants'
import { editStaff } from 'containers/job/assign-staff/actions'
import { updateJobEstimatedStart } from 'containers/job/actions'

function* dropJob(action) {
  try {
    const { job, employee, start } = action.payload.params
    const employees = uniq([
      ...job.employees.map(employee => employee.id),
      employee.id
    ])
    yield put(
      editStaff({
        type: 'employees',
        jobId: job.id,
        jobIds: null,
        employees: employees,
        teamId: null
      })
    )
    yield put(
      updateJobEstimatedStart({
        jobId: job.id,
        scheduleStartTime: start
      })
    )
  } catch (e) {
    yield put({
      type: JOBS_DISPATCH_DROP_JOB_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* downloadJobReport(action) {
  try {
    const { queryParams, date } = action.payload.params
    const reportName = `doinn-services-report-${date}.pdf`
    const { data } = yield call(downloadJobsReportApi, {
      queryParams
    })

    yield put({
      type: JOBS_REPORT_DOWNLOAD_SUCCEEDED,
      snackbar: {
        variant: 'success',
        message: i18n.t('The report has been downloaded successfully')
      },
      downloader: {
        data,
        type: 'application/pdf',
        name: reportName
      }
    })
  } catch (e) {
    yield put({
      type: JOBS_REPORT_DOWNLOAD_FAILED,
      snackbar: {
        variant: 'error',
        message: e.message
      }
    })
  }
}

export default function* jobsDispatchSaga() {
  yield all([
    takeLatest(JOBS_DISPATCH_DROP_JOB_REQUESTED, dropJob),
    takeLatest(JOBS_REPORT_DOWNLOAD_REQUESTED, downloadJobReport)
  ])
}
