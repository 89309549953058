import { all, call, put, takeLatest } from 'redux-saga/effects'
import { editCatalogItemApi } from '@doinn/host/src/api/catalog-items'
import {
  CATALOG_ITEM_EDIT_FAILED,
  CATALOG_ITEM_EDIT_REQUESTED,
  CATALOG_ITEM_EDIT_SUCCEEDED
} from '@doinn/host/src/containers/catalog/item/edit/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* editCatalogItem(action) {
  try {
    const { catalogItem } = action.payload
    yield call(editCatalogItemApi, catalogItem)
    yield put({
      type: CATALOG_ITEM_EDIT_SUCCEEDED,
      payload: catalogItem
    })
  } catch (e) {
    yield put({
      type: CATALOG_ITEM_EDIT_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* catalogItemsAddSaga() {
  yield all([takeLatest(CATALOG_ITEM_EDIT_REQUESTED, editCatalogItem)])
}
