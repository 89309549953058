import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { addCatalogueMyApi } from '@doinn/shared/src/api/catalogues'
import {
  CATALOGUE_MY_ADD_FAILED,
  CATALOGUE_MY_ADD_REQUESTED,
  CATALOGUE_MY_ADD_SUCCEEDED
} from '@doinn/shared/src/containers/catalogue/my/add/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* addCatalogue(action) {
  try {
    const { catalogue } = action.payload
    yield call(addCatalogueMyApi, catalogue)
    yield put({
      type: CATALOGUE_MY_ADD_SUCCEEDED,
      payload: catalogue,
      snackbar: {
        variant: 'success',
        message: i18n.t('The catalogue has been created successfully')
      }
    })
  } catch (e) {
    yield put({
      type: CATALOGUE_MY_ADD_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* cataloguesAddSaga() {
  yield all([takeLatest(CATALOGUE_MY_ADD_REQUESTED, addCatalogue)])
}
