import { all, call, put, takeLatest } from 'redux-saga/effects'
import i18n from '@doinn/shared/src/config/i18n'
import { addfinishNoteApi } from 'api/job'
import {
  JOB_FINISH_FAILED,
  JOB_FINISH_REQUESTED,
  JOB_FINISH_SUCCEEDED
} from 'containers/job/finish/note/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* addJobFinishNote(action) {
  try {
    const { job, note } = action.payload
    const response = yield call(addfinishNoteApi, job, note)
    const jobFinished = { ...response.data }
    yield put({
      type: JOB_FINISH_SUCCEEDED,
      payload: jobFinished,
      snackbar: {
        variant: 'success',
        message: i18n.t('Note successfully sent!')
      }
    })
  } catch (e) {
    yield put({
      type: JOB_FINISH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* watchAddJobFinishNote() {
  yield takeLatest(JOB_FINISH_REQUESTED, addJobFinishNote)
}

export default function* noteFormSaga() {
  yield all([watchAddJobFinishNote()])
}
