import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  applyCreditsApi,
  removeCreditsApi
} from '@doinn/vendor/src/api/checkout'
import {
  CHECKOUT_CREDITS_APPLY_REQUESTED,
  CHECKOUT_CREDITS_FAILED,
  CHECKOUT_CREDITS_REMOVE_REQUESTED,
  CHECKOUT_CREDITS_SUCCEEDED
} from '@doinn/vendor/src/containers/checkout/credits/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* applyCredits(action) {
  try {
    const { params } = action.payload
    const response = yield call(applyCreditsApi, params)

    yield put({
      type: CHECKOUT_CREDITS_SUCCEEDED,
      payload: response.data.data
    })
  } catch (e) {
    yield put({
      type: CHECKOUT_CREDITS_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* removeCredits(action) {
  try {
    const { params } = action.payload
    const response = yield call(removeCreditsApi, params)

    yield put({
      type: CHECKOUT_CREDITS_SUCCEEDED,
      payload: response.data.data
    })
  } catch (e) {
    yield put({
      type: CHECKOUT_CREDITS_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* checkoutCouponSaga() {
  yield all([
    takeLatest(CHECKOUT_CREDITS_APPLY_REQUESTED, applyCredits),
    takeLatest(CHECKOUT_CREDITS_REMOVE_REQUESTED, removeCredits)
  ])
}
