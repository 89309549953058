import {
  APP_SET_LANGUAGE_FAILED,
  APP_SET_LANGUAGE_REQUESTED,
  APP_SET_LANGUAGE_SUCCEEDED
} from '@doinn/shared/src/containers/language/constants'

export const initialState = {
  language: 'en-GB',
  isLoading: false,
  isFailed: false
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case APP_SET_LANGUAGE_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isFailed: false
      }
    case APP_SET_LANGUAGE_SUCCEEDED:
      return {
        ...state,
        language: payload.language,
        isLoading: false,
        isFailed: false
      }
    case APP_SET_LANGUAGE_FAILED:
      return {
        ...state,
        language: initialState.language,
        isLoading: false,
        isFailed: true
      }
    default:
      return state
  }
}
