import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Badge,
  Box,
  DialogContent,
  IconButton,
  Popover,
  makeStyles,
  useTheme
} from '@material-ui/core'
import Icon from '@doinn/shared/src/components/common/Icon'
import NotificationsList from '@doinn/shared/src/containers/notifications/List'
import Dialog from '@doinn/shared/src/components/common/dialog/Dialog'
import DialogHeader from '@doinn/shared/src/components/common/dialog/DialogHeader'

const useStyles = makeStyles(theme => {
  return {
    badge: {
      '& > span': {
        transform: 'scale(0.8) translate(50%, -50%)'
      }
    },
    body: {
      padding: theme.spacing(0, 2)
    },
    desktop: {
      height: theme.spacing(75),
      width: theme.spacing(63.125),
      overflow: 'hidden',
      background: theme.palette.background.paper,
      borderRadius: theme.spacing(1),
      border: `1px solid ${theme.palette.background.default}`
    }
  }
})

const NotificationCounter = ({ counter, onClick, isApp, isDesktop }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()
  const [isOpened, setIsOpened] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const anchorOrigin = useCallback(
    () => ({
      vertical: 'bottom',
      horizontal: 'right'
    }),
    []
  )
  const transformOrigin = useCallback(
    () => ({
      vertical: 'top',
      horizontal: 'right'
    }),
    []
  )
  const paperProps = useCallback(
    () => ({
      style: {
        backgroundColor: 'transparent',
        marginTop: theme.spacing(1.5),
        overflow: 'visible'
      }
    }),
    [theme]
  )
  const arrowSx = useCallback(
    () => ({
      position: 'relative',
      '&::before': {
        backgroundColor: theme.palette.background.paper,
        content: '""',
        display: 'block',
        position: 'absolute',
        width: theme.spacing(2),
        height: theme.spacing(2),
        top: theme.spacing(-1),
        transform: 'rotate(45deg)',
        left: 'calc(96% - 8px)',
        border: `1px solid ${theme.palette.background.default}`,
        borderBottom: theme.spacing(0),
        borderRight: theme.spacing(0)
      }
    }),
    [theme]
  )

  const handleClick = event => {
    onClick(0)
    if (isDesktop) {
      setAnchorEl(event.currentTarget)
    }
    setIsOpened(true)
  }

  const handleClose = () => {
    setIsOpened(false)
  }

  const handleClickItem = useCallback(_ => handleClose(), [])

  const renderDesktop = useCallback(
    () => (
      <Popover
        id={isOpened ?? 'notifications'}
        open={isOpened}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin()}
        transformOrigin={transformOrigin()}
        PaperProps={paperProps()}
      >
        <Box className={classes.arrow} sx={arrowSx()} />
        <Box className={classes.desktop} padding={3}>
          <NotificationsList
            height={theme.spacing(62.875)}
            onClickItem={handleClickItem}
          />
        </Box>
      </Popover>
    ),
    [
      anchorEl,
      handleClickItem,
      isOpened,
      classes,
      theme,
      anchorOrigin,
      arrowSx,
      paperProps,
      transformOrigin
    ]
  )

  const renderMobile = useCallback(
    () => (
      <Dialog open={isOpened} onClose={handleClose}>
        <DialogHeader title={t('Notifications')} onClose={handleClose} />
        <DialogContent dividers className={classes.body}>
          <NotificationsList onClickItem={handleClickItem} />
        </DialogContent>
      </Dialog>
    ),
    [classes.body, handleClickItem, isOpened, t]
  )

  return (
    <div>
      <IconButton
        color='inherit'
        aria-label='open notifications'
        edge='end'
        onClick={handleClick}
      >
        <Badge
          badgeContent={counter > 99 ? '99+' : counter}
          color='primary'
          overlap='circle'
          className={classes.badge}
        >
          <Icon icon='notification' />
        </Badge>
      </IconButton>
      {isApp || !isDesktop ? renderMobile() : renderDesktop()}
    </div>
  )
}

export default NotificationCounter
