import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { deleteEmployeeApi } from '@doinn/vendor/src/api/employee'
import {
  EMPLOYEES_DELETE_FAILED,
  EMPLOYEES_DELETE_REQUESTED,
  EMPLOYEES_DELETE_SUCCEEDED
} from '@doinn/vendor/src/containers/staff/employees/delete/constants'
import { EMPLOYEES_UPDATE_SELECTED_EMPLOYEE_REQUESTED } from '@doinn/vendor/src/containers/staff/employees/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* deleteEmployees(action) {
  try {
    const { employeeIds } = action.payload
    const failedEmployees = []
    for (let i = 0; i < employeeIds.length; i++) {
      const employeeId = employeeIds[i]
      try {
        yield call(deleteEmployeeApi, employeeId)
      } catch (e) {
        failedEmployees.push(employeeId)
      }
    }
    const totalFailedEmployees = failedEmployees.length
    if (totalFailedEmployees) {
      let errorMessage = i18n.t('Not all employees could be deleted.')
      if (totalFailedEmployees === employeeIds.length) {
        errorMessage = i18n.t('No employees could be deleted.')
      }
      yield put({
        type: EMPLOYEES_DELETE_FAILED,
        snackbar: {
          variant: 'error',
          message: getSnackbarErrorMessage(errorMessage)
        }
      })
    } else {
      yield put({
        type: EMPLOYEES_DELETE_SUCCEEDED,
        snackbar: {
          variant: 'success',
          message: i18n.t('Employee successfully deleted.')
        }
      })
      yield put({
        type: EMPLOYEES_UPDATE_SELECTED_EMPLOYEE_REQUESTED,
        payload: []
      })
    }
  } catch (e) {
    yield put({
      type: EMPLOYEES_DELETE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* employeesDeleteSaga() {
  yield all([takeLatest(EMPLOYEES_DELETE_REQUESTED, deleteEmployees)])
}
