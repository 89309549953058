export const INVOICES_FETCH_REQUESTED = 'INVOICES_FETCH_REQUESTED'
export const INVOICES_FETCH_SUCCEEDED = 'INVOICES_FETCH_SUCCEEDED'
export const INVOICES_FETCH_FAILED = 'INVOICES_FETCH_FAILED'

export const INVOICES_FETCH_MORE_REQUESTED = 'INVOICES_FETCH_MORE_REQUESTED'
export const INVOICES_FETCH_MORE_SUCCEEDED = 'INVOICES_FETCH_MORE_SUCCEEDED'
export const INVOICES_FETCH_MORE_FAILED = 'INVOICES_FETCH_MORE_FAILED'

export const INVOICE_UPLOAD_REQUESTED = 'INVOICE_UPLOAD_REQUESTED'
export const INVOICE_UPLOAD_SUCCEEDED = 'INVOICE_UPLOAD_SUCCEEDED'
export const INVOICE_UPLOAD_FAILED = 'INVOICE_UPLOAD_FAILED'

export const INVOICE_EXPORT_REQUESTED = 'INVOICE_EXPORT_REQUESTED'
export const INVOICE_EXPORT_SUCCEEDED = 'INVOICE_EXPORT_SUCCEEDED'
export const INVOICE_EXPORT_FAILED = 'INVOICE_EXPORT_FAILED'
