import { all, call, put, takeLatest } from 'redux-saga/effects'
import { addTeamApi, editTeamApi } from '@doinn/vendor/src/api/team'
import {
  TEAM_ADD_EDIT_FORM_SAVE_FAILED,
  TEAM_ADD_EDIT_FORM_SAVE_REQUESTED,
  TEAM_ADD_EDIT_FORM_SAVE_SUCCEEDED
} from '@doinn/vendor/src/containers/team/add-edit/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* addTeam(team) {
  try {
    const response = yield call(addTeamApi, {
      ...team
    })
    const createdTeam = response.data
    yield put({
      type: TEAM_ADD_EDIT_FORM_SAVE_SUCCEEDED,
      payload: createdTeam
    })
  } catch (e) {
    yield put({
      type: TEAM_ADD_EDIT_FORM_SAVE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* editTeam(team) {
  try {
    const response = yield call(editTeamApi, {
      ...team
    })
    const editedTeam = response.data
    yield put({
      type: TEAM_ADD_EDIT_FORM_SAVE_SUCCEEDED,
      payload: editedTeam
    })
  } catch (e) {
    yield put({
      type: TEAM_ADD_EDIT_FORM_SAVE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* saveTeam(action) {
  try {
    const { team } = action.payload
    if (team.id) {
      yield call(editTeam, team)
    } else {
      yield call(addTeam, team)
    }
  } catch (e) {
    yield put({
      type: TEAM_ADD_EDIT_FORM_SAVE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* watchSaveTeam() {
  yield takeLatest(TEAM_ADD_EDIT_FORM_SAVE_REQUESTED, saveTeam)
}

export default function* teamFormSaga() {
  yield all([watchSaveTeam()])
}
