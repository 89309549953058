import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { cloneDeep, isEqual as isObjEqual } from 'lodash'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { startOfToday } from 'date-fns'
import { formatSystemDate } from '@doinn/shared/src/util/date-fns'
import {
  getQueryStringObject,
  updateURLParams
} from '@doinn/shared/src/util/url'
import { parseAppliedFilters } from '@doinn/shared/src/util/filters'
import { countAppliedFilters as countAppliedFiltersServices } from 'containers/jobs/util'
import { countAppliedFilters as countAppliedFiltersProperties } from '@doinn/shared/src/containers/property/list/util'
import {
  useDidMount,
  useFilterState,
  useOpenState,
  usePrevious
} from '@doinn/shared/src/hooks'
import FiltersButton from '@doinn/shared/src/components/common/FiltersButton'
import FiltersDialog from '@doinn/shared/src/components/common/FiltersDialog'
import JobsListFiltersContent from 'containers/jobs/ListFiltersContent'
import PropertiesListFiltersContent from '@doinn/vendor/src/containers/property/list/ListFiltersContent'
import CataloguesListFiltersContent from '@doinn/shared/src/containers/catalogue/components/ListFiltersContent'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export const DEFAULT_TAB_NAME_JOBS = 'services'
export const DEFAULT_TAB_NAME_PROPERTIES = 'properties'
export const DEFAULT_TAB_NAME_CATALOGUES = 'catalogues'
export const DEFAULT_FILTERED_AREAS = [
  DEFAULT_TAB_NAME_JOBS,
  DEFAULT_TAB_NAME_PROPERTIES,
  DEFAULT_TAB_NAME_CATALOGUES
]

const getDefaultFilters = ({ tabArea = '', fixedFilters = {} }) => {
  if (tabArea === DEFAULT_TAB_NAME_JOBS) {
    const startOfTodayDate = startOfToday()
    const startOfTodayFormatted = formatSystemDate(startOfTodayDate)
    return {
      cities: [],
      services: [],
      priorities: [],
      status: [],
      employees: [],
      spaces: [],
      hosts: [],
      hostBusinessAccounts: [],
      notes: 'all',
      period: 'today',
      start: startOfTodayFormatted,
      end: startOfTodayFormatted,
      page: 1,
      sourceBy: 'all',
      payable: 'false',
      ...fixedFilters
    }
  }
  if (tabArea === DEFAULT_TAB_NAME_PROPERTIES) {
    return {
      cities: [],
      sources: [],
      types: [],
      singleBeds: [],
      doubleBeds: [],
      kingSizeBeds: [],
      bedrooms: [],
      bathrooms: [],
      employees: [],
      page: 1,
      orderBy: 'name',
      orderOrientation: 'asc',
      lastImportAt: '',
      ...fixedFilters
    }
  }
  if (tabArea === DEFAULT_TAB_NAME_CATALOGUES) {
    return {
      page: 1,
      orderBy: 'name',
      orderOrientation: 'asc',
      categories: [],
      ...fixedFilters
    }
  }
  return {
    page: 1
  }
}

const parseFilters = (filters = {}) => {
  const parsedFilters = cloneDeep(filters)
  if (parsedFilters.notes === 'all') {
    delete parsedFilters.notes
  }
  return parsedFilters
}

const getQueryOrSavedFilters = ({
  location = { search: '' },
  tabArea = '',
  fixedFilters = {}
}) => {
  const queryFilters = getQueryStringObject(location.search)
  return parseAppliedFilters(
    getDefaultFilters({
      tabArea,
      fixedFilters
    }),
    queryFilters
  )
}

export const useDetailsFilters = ({ entityFilterName, entityIdParamName }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))
  const filtersLabel = useMemo(
    () => (isMobile ? t('More filters') : t('Filters')),
    [isMobile, t]
  )
  const location = useLocation()
  const history = useHistory()
  const previousLocation = usePrevious(location)
  const { [entityIdParamName]: entityId } = useParams()
  const { tabArea } = useParams()
  const hiddenFilters = useMemo(() => [entityFilterName], [entityFilterName])
  const fixedFilters = useMemo(
    () => ({
      [entityFilterName]: [entityId]
    }),
    [entityFilterName, entityId]
  )
  const [justChangedLocation, setJustChangedLocation] = useState(false)
  const {
    appliedFilters,
    selectedFilters,
    hasAppliedFiltersChanges,
    onChangeAppliedFilters,
    onChangeSelectedFilters,
    onClearSelectedFilters,
    onConfirmSelectedFilters
  } = useFilterState(
    getDefaultFilters({
      tabArea,
      fixedFilters
    })
  )
  const {
    justClosed: justClosedFilters,
    justOpened: justOpenedFilters,
    onClose: onCloseFilters,
    onOpen: onOpenFilters,
    open: isFiltersOpened
  } = useOpenState(false)
  const countAppliedFilters = useCallback(() => {
    const callbacks = {
      [DEFAULT_TAB_NAME_JOBS]: countAppliedFiltersServices,
      [DEFAULT_TAB_NAME_PROPERTIES]: countAppliedFiltersProperties
    }
    const callback = callbacks[tabArea] ?? (() => 0)
    return callback(appliedFilters, false, hiddenFilters)
  }, [appliedFilters, hiddenFilters, tabArea])
  const [totalAppliedFilters, setTotalAppliedFilters] = useState(
    countAppliedFilters()
  )

  useDidMount(() => {
    onChangeAppliedFilters(
      getQueryOrSavedFilters({
        location,
        tabArea,
        fixedFilters
      })
    )
  })

  useEffect(() => {
    if (!previousLocation) return
    setJustChangedLocation(
      !isObjEqual(location.search, previousLocation.search) ||
        !isObjEqual(location.pathname, previousLocation.pathname)
    )
  }, [location, previousLocation])

  useEffect(() => {
    if (!justChangedLocation) return
    const savedFilters = getQueryOrSavedFilters({
      location,
      tabArea,
      fixedFilters
    })
    const shouldUpdateAppliedFilters = !isObjEqual(appliedFilters, savedFilters)
    if (shouldUpdateAppliedFilters) {
      onChangeAppliedFilters(savedFilters)
    }
  }, [
    appliedFilters,
    fixedFilters,
    justChangedLocation,
    location,
    onChangeAppliedFilters,
    tabArea
  ])

  useEffect(() => {
    if (justOpenedFilters) {
      onChangeSelectedFilters(appliedFilters)
    }
  }, [appliedFilters, justOpenedFilters, onChangeSelectedFilters])

  useEffect(() => {
    if (justClosedFilters) {
      onChangeSelectedFilters(appliedFilters)
    }
  }, [appliedFilters, justClosedFilters, onChangeSelectedFilters])

  useEffect(() => {
    const shouldUpdateUrl =
      hasAppliedFiltersChanges &&
      !isObjEqual(
        appliedFilters,
        getQueryOrSavedFilters({
          location,
          tabArea,
          fixedFilters
        })
      )
    if (shouldUpdateUrl) {
      updateURLParams(parseFilters(appliedFilters), history)
    }
  }, [
    appliedFilters,
    fixedFilters,
    hasAppliedFiltersChanges,
    history,
    location,
    tabArea
  ])

  useEffect(() => {
    setTotalAppliedFilters(countAppliedFilters())
  }, [countAppliedFilters])

  const handleConfirmSelectedFilters = useCallback(() => {
    onConfirmSelectedFilters()
    onCloseFilters()
  }, [onCloseFilters, onConfirmSelectedFilters])

  const handleChangeAppliedFilters = useCallback(
    filters => {
      const params = {
        ...filters,
        page: 1 // Reset to first page but allow pagination
      }
      onChangeAppliedFilters(params)
    },
    [onChangeAppliedFilters]
  )

  const disabledFilters = useMemo(
    () => ({
      [entityFilterName]: true
    }),
    [entityFilterName]
  )

  const computedSelectedFilters = useMemo(
    () => ({
      ...selectedFilters,
      [entityFilterName]: [entityId]
    }),
    [entityFilterName, entityId, selectedFilters]
  )

  const computedAppliedFilters = useMemo(
    () => ({
      ...appliedFilters,
      [entityFilterName]: [entityId]
    }),
    [appliedFilters, entityFilterName, entityId]
  )

  const contentFilterProps = useMemo(() => {
    if (DEFAULT_FILTERED_AREAS.includes(tabArea)) {
      return {
        filters: computedAppliedFilters,
        hiddenFilters,
        onChangeFilters: handleChangeAppliedFilters,
        onOpenFilters
      }
    }
    return {}
  }, [
    computedAppliedFilters,
    hiddenFilters,
    handleChangeAppliedFilters,
    onOpenFilters,
    tabArea
  ])

  const filtersContent = useMemo(() => {
    if (DEFAULT_FILTERED_AREAS.includes(tabArea)) {
      return (
        <FiltersButton
          variant='icon'
          label={filtersLabel}
          totalAppliedFilters={totalAppliedFilters}
          onOpen={onOpenFilters}
        />
      )
    }
    return null
  }, [filtersLabel, onOpenFilters, tabArea, totalAppliedFilters])

  const filtersDialog = useMemo(() => {
    const allFiltersDialogContent = {
      [DEFAULT_TAB_NAME_JOBS]: (
        <JobsListFiltersContent
          disabledFilters={disabledFilters}
          hiddenFilters={hiddenFilters}
          filters={computedSelectedFilters}
          onChangeFilters={onChangeSelectedFilters}
        />
      ),
      [DEFAULT_TAB_NAME_PROPERTIES]: (
        <PropertiesListFiltersContent
          displayEmployeeEmptyOption
          disabledFilters={disabledFilters}
          hiddenFilters={hiddenFilters}
          filters={computedSelectedFilters}
          onChangeFilters={onChangeSelectedFilters}
        />
      ),
      [DEFAULT_TAB_NAME_CATALOGUES]: (
        <CataloguesListFiltersContent
          displayEmployeeEmptyOption
          disabledFilters={disabledFilters}
          hiddenFilters={hiddenFilters}
          filters={computedSelectedFilters}
          onChangeFilters={onChangeSelectedFilters}
        />
      )
    }
    return (
      <FiltersDialog
        open={isFiltersOpened}
        title={filtersLabel}
        onClear={onClearSelectedFilters}
        onClose={onCloseFilters}
        onConfirm={handleConfirmSelectedFilters}
      >
        {allFiltersDialogContent[tabArea] ?? null}
      </FiltersDialog>
    )
  }, [
    disabledFilters,
    filtersLabel,
    hiddenFilters,
    isFiltersOpened,
    onChangeSelectedFilters,
    onClearSelectedFilters,
    onCloseFilters,
    handleConfirmSelectedFilters,
    computedSelectedFilters,
    tabArea
  ])

  return {
    tabArea,
    contentFilterProps,
    filtersContent,
    filtersDialog,
    disabledFilters,
    hiddenFilters,
    appliedFilters: computedAppliedFilters,
    selectedFilters: computedSelectedFilters,
    isFiltersOpened,
    totalAppliedFilters,
    onOpenFilters,
    onCloseFilters,
    onClearSelectedFilters,
    onConfirmSelectedFilters: handleConfirmSelectedFilters,
    onChangeSelectedFilters,
    onChangeAppliedFilters: handleChangeAppliedFilters
  }
}
