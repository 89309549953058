import React from 'react'
import { compose } from 'redux'
import { Button, Popover } from '@material-ui/core'
import { find } from 'lodash'
import RadioList from '@doinn/shared/src/components/common/filter/RadioList'
import Icon from '@doinn/shared/src/components/common/Icon'

const Select = props => {
  const {
    filterOptions,
    selectedFilter,
    buttonLabel,
    filterName,
    disabled,
    onChange
  } = props

  const selectedFilterData = find(filterOptions, {
    id: selectedFilter
  }) || {
    name: buttonLabel
  }

  const selectedFilterButtonLabel = selectedFilterData.name

  const popoverPosition = {
    vertical: 'top',
    horizontal: 'left'
  }

  const [anchorEl, setAnchorEl] = React.useState(null)

  const isMenuOpened = Boolean(anchorEl)

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleOnChangeFilter = (filterName, itemId) => {
    onChange && onChange(filterName, itemId)
    handleCloseMenu()
  }

  return (
    <div>
      <Button
        disabled={disabled}
        variant='outlined'
        color='secondary'
        size='small'
        endIcon={<Icon icon='chevron_down' />}
        onClick={handleOpenMenu}
      >
        <b>{selectedFilterButtonLabel}</b>
      </Button>
      <Popover
        open={isMenuOpened}
        anchorEl={anchorEl}
        anchorOrigin={popoverPosition}
        transformOrigin={popoverPosition}
        onClose={handleCloseMenu}
      >
        <RadioList
          filterName={filterName}
          items={filterOptions}
          checkedItems={selectedFilter}
          isMenu
          onChange={handleOnChangeFilter}
        />
      </Popover>
    </div>
  )
}

export default compose()(Select)
