import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function fetchPlansApi({ params }) {
  const config = {
    params
  }
  return DoinnApi.get(`/plans`, config)
}

export function getSubscriptionApi({ params = {} }) {
  const config = {
    params
  }
  return DoinnApi.get(`/subscriptions/subscription`, config)
}

export function requestEnterpriseQuoteApi({ params }) {
  const config = {}
  const data = { ...params }
  return DoinnApi.put(`/subscriptions/enterprise/quote`, data, config)
}
