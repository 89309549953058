import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  exportSentInvoiceApi,
  fetchSentInvoicesApi,
  uploadInvoiceApi
} from '@doinn/shared/src/api/invoices'
import {
  INVOICES_FETCH_FAILED,
  INVOICES_FETCH_MORE_FAILED,
  INVOICES_FETCH_MORE_REQUESTED,
  INVOICES_FETCH_MORE_SUCCEEDED,
  INVOICES_FETCH_REQUESTED,
  INVOICES_FETCH_SUCCEEDED,
  INVOICE_EXPORT_FAILED,
  INVOICE_EXPORT_REQUESTED,
  INVOICE_EXPORT_SUCCEEDED,
  INVOICE_UPLOAD_FAILED,
  INVOICE_UPLOAD_REQUESTED,
  INVOICE_UPLOAD_SUCCEEDED
} from '@doinn/shared/src/containers/invoice/sent/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchInvoices(action) {
  try {
    const response = yield call(fetchSentInvoicesApi, action.payload.params)
    yield put({
      type: INVOICES_FETCH_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: INVOICES_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* fetchMoreInvoices(action) {
  try {
    const response = yield call(fetchSentInvoicesApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: INVOICES_FETCH_MORE_SUCCEEDED,
      payload: {
        data,
        meta
      }
    })
  } catch (e) {
    yield put({
      type: INVOICES_FETCH_MORE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* uploadInvoice(action) {
  try {
    const { invoiceId, file } = action.payload.params
    const response = yield call(uploadInvoiceApi, {
      invoiceId: invoiceId,
      file: file
    })
    const invoiceResponse = response.data
    yield put({
      type: INVOICE_UPLOAD_SUCCEEDED,
      payload: invoiceResponse
    })
  } catch (e) {
    yield put({
      type: INVOICE_UPLOAD_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* exportInvoice(action) {
  try {
    const { invoice } = action.payload.params
    const startedAt = new Date(invoice.startedAt).toDateString()
    const finishedAt = new Date(invoice.finishedAt).toDateString()
    const reportName = `doinn_vendor_invoice_${startedAt}_${finishedAt}_${invoice.id}.xls`
    const { data } = yield call(exportSentInvoiceApi, { id: invoice.id })

    yield put({
      type: INVOICE_EXPORT_SUCCEEDED,
      snackbar: {
        variant: 'success',
        message: i18n.t('The report has been downloaded successfully')
      },
      downloader: {
        data,
        type: 'application/vnd.ms-excel',
        name: reportName
      }
    })
  } catch (e) {
    yield put({
      type: INVOICE_EXPORT_FAILED,
      snackbar: {
        variant: 'error',
        message: e.message
      }
    })
  }
}

export default function* sentInvoicesSaga() {
  yield all([
    takeLatest(INVOICES_FETCH_REQUESTED, fetchInvoices),
    takeLatest(INVOICES_FETCH_MORE_REQUESTED, fetchMoreInvoices),
    takeLatest(INVOICE_UPLOAD_REQUESTED, uploadInvoice),
    takeLatest(INVOICE_EXPORT_REQUESTED, exportInvoice)
  ])
}
