import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  addMembersInvitationsApi,
  fetchMembersAvailableRolesApi
} from '@doinn/vendor/src/api/membersInvitations'
import { MEMBERS_FETCH_REQUESTED } from '@doinn/vendor/src/containers/member/list/constants'
import {
  MEMBERS_INVITATIONS_ADD_FAILED,
  MEMBERS_INVITATIONS_ADD_REQUESTED,
  MEMBERS_INVITATIONS_ADD_SUCCEEDED,
  MEMBERS_ROLE_OPTIONS_FAILED,
  MEMBERS_ROLE_OPTIONS_REQUESTED,
  MEMBERS_ROLE_OPTIONS_SUCCEEDED
} from '@doinn/vendor/src/containers/member/invitation/add/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import i18n from '@doinn/shared/src/config/i18n'

function* addMembersInvitations(action) {
  try {
    const { invitations } = action.payload
    yield call(addMembersInvitationsApi, invitations)
    yield put({
      type: MEMBERS_INVITATIONS_ADD_SUCCEEDED,
      payload: action.payload,
      snackbar: {
        variant: 'success',
        message: i18n.t('Invite has been successfully sent')
      }
    })
    yield put({ type: MEMBERS_FETCH_REQUESTED, payload: {} })
  } catch (e) {
    yield put({
      type: MEMBERS_INVITATIONS_ADD_FAILED,
      payload: {
        errorMessage: getSnackbarErrorMessage(e)
      },
      snackbar: {
        variant: 'error',
        message: i18n.t('Invitations could not be sent')
      }
    })
  }
}

function* getMemberRoleOptions(action) {
  try {
    const { params } = action.payload
    const response = yield call(fetchMembersAvailableRolesApi, params)
    yield put({
      type: MEMBERS_ROLE_OPTIONS_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: MEMBERS_ROLE_OPTIONS_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* membersInvitationsAddSaga() {
  yield all([
    takeLatest(MEMBERS_INVITATIONS_ADD_REQUESTED, addMembersInvitations),
    takeLatest(MEMBERS_ROLE_OPTIONS_REQUESTED, getMemberRoleOptions)
  ])
}
