import { all } from 'redux-saga/effects'
import i18n from '@doinn/shared/src/config/i18n'
import { DEFAULT_LANGUAGE_CODE } from 'config/env'
import appSagas from '@doinn/shared/src/containers/app/sagas'
import automationListSagas from '@doinn/host/src/containers/automations/list/sagas'
import automationGetSagas from '@doinn/host/src/containers/automations/get/sagas'
import automationAddSagas from '@doinn/host/src/containers/automations/add/sagas'
import automationEditSagas from '@doinn/host/src/containers/automations/edit/sagas'
import automationActivateSagas from '@doinn/host/src/containers/automations/list/activate/sagas'
import automationDeactivateSagas from '@doinn/host/src/containers/automations/list/deactivate/sagas'
import automationDeleteSagas from '@doinn/host/src/containers/automations/list/delete/sagas'
import automationsFiltersSagas from '@doinn/shared/src/containers/automations/filters/sagas'
import billingGeneralSagas from '@doinn/shared/src/containers/subscription-billing/billing/list/general/sagas'
import billingGroupSagas from '@doinn/shared/src/containers/subscription-billing/billing/list/group/sagas'
import billingGetSagas from '@doinn/shared/src/containers/subscription-billing/billing/get/sagas'
import billingEditSagas from '@doinn/shared/src/containers/subscription-billing/billing/edit/sagas'
import billingAddSagas from '@doinn/shared/src/containers/subscription-billing/billing/add/sagas'
import billingDeleteSagas from '@doinn/shared/src/containers/subscription-billing/billing/delete/sagas'
import cancelRequestSagas from '@doinn/vendor/src/containers/job/cancel-request/sagas'
import catalogAddSagas from '@doinn/host/src/containers/catalog/add/sagas'
import catalogItemImportSagas from '@doinn/host/src/containers/catalog/item/import/sagas'
import catalogEditSagas from '@doinn/host/src/containers/catalog/edit/sagas'
import catalogDeleteSagas from '@doinn/host/src/containers/catalog/delete/sagas'
import catalogListSagas from '@doinn/host/src/containers/catalog/list/sagas'
import catalogGetSagas from '@doinn/host/src/containers/catalog/get/sagas'
import catalogItemAddSagas from '@doinn/host/src/containers/catalog/item/add/sagas'
import catalogItemEditSagas from '@doinn/host/src/containers/catalog/item/edit/sagas'
import catalogItemDeleteSagas from '@doinn/host/src/containers/catalog/item/delete/sagas'
import catalogItemListSagas from '@doinn/host/src/containers/catalog/item/list/sagas'
import catalogItemGetSagas from '@doinn/host/src/containers/catalog/item/get/sagas'
import catalogueCopySagas from '@doinn/shared/src/containers/catalogue/copy/sagas'
import catalogueMyAddSagas from '@doinn/shared/src/containers/catalogue/my/add/sagas'
import catalogueMyEditSagas from '@doinn/shared/src/containers/catalogue/my/edit/sagas'
import catalogueMyEditDescriptionSagas from '@doinn/shared/src/containers/catalogue/my/edit-description/sagas'
import catalogueMyEditSettingsSagas from '@doinn/shared/src/containers/catalogue/my/edit-settings/sagas'
import catalogueMyEditPricesSagas from '@doinn/shared/src/containers/catalogue/my/edit-prices/sagas'
import catalogueMyDeleteSagas from '@doinn/shared/src/containers/catalogue/my/delete/sagas'
import catalogueMyListSagas from '@doinn/shared/src/containers/catalogue/my/list/sagas'
import catalogueMyGetSagas from '@doinn/shared/src/containers/catalogue/my/get/sagas'
import catalogueMyShareSagas from '@doinn/shared/src/containers/catalogue/my/share/sagas'
import catalogueMyUnshareSagas from '@doinn/shared/src/containers/catalogue/my/unshare/sagas'
import catalogueSharedListSagas from '@doinn/shared/src/containers/catalogue/shared/list/sagas'
import catalogueSharedGetSagas from '@doinn/shared/src/containers/catalogue/shared/get/sagas'
import catalogueTemplateListSagas from '@doinn/shared/src/containers/catalogue/templates/list/sagas'
import catalogueTemplateGetSagas from '@doinn/shared/src/containers/catalogue/templates/get/sagas'
import cataloguesFiltersSagas from '@doinn/shared/src/containers/catalogue/filters/sagas'
import checkoutSagas from '@doinn/vendor/src/containers/checkout/sagas'
import checkoutCouponSagas from '@doinn/vendor/src/containers/checkout/coupon/sagas'
import checkoutCreditsSagas from '@doinn/vendor/src/containers/checkout/credits/sagas'
import checkoutRemoveServiceSagas from '@doinn/vendor/src/containers/checkout/remove/sagas'
import chatsSagas from '@doinn/shared/src/containers/chats/sagas'
import chatsContactsSagas from '@doinn/shared/src/containers/chats/contacts/sagas'
import chatsJobsSagas from '@doinn/shared/src/containers/chats/jobs/sagas'
import chatsPropertiesSagas from '@doinn/shared/src/containers/chats/properties/sagas'
import chatAddSagas from '@doinn/shared/src/containers/chats/add/sagas'
import chatFollowSagas from '@doinn/shared/src/containers/chats/follow/sagas'
import chatGetSagas from '@doinn/shared/src/containers/chats/get/sagas'
import chatMessagesSagas from '@doinn/shared/src/containers/chats/messages/sagas'
import checklistCopySagas from '@doinn/host/src/containers/checklist/copy/sagas'
import checklistMyAddSagas from '@doinn/host/src/containers/checklist/my/add/sagas'
import checklistMyEditSagas from '@doinn/host/src/containers/checklist/my/edit/sagas'
import checklistMyDeleteSagas from '@doinn/host/src/containers/checklist/my/delete/sagas'
import checklistMyListSagas from '@doinn/host/src/containers/checklist/my/list/sagas'
import checklistMyGetSagas from '@doinn/host/src/containers/checklist/my/get/sagas'
import checklistMyImportSagas from '@doinn/host/src/containers/checklist/my/import/sagas'
import checklistPublicListSagas from '@doinn/host/src/containers/checklist/public/list/sagas'
import checklistPublicGetSagas from '@doinn/host/src/containers/checklist/public/get/sagas'
import customerGetSagas from '@doinn/vendor/src/containers/customer/get/sagas'
import customerListSagas from '@doinn/vendor/src/containers/customer/list/sagas'
import customerInvitationDeleteSagas from '@doinn/vendor/src/containers/customer/invitation/delete/sagas'
import cityListSagas from '@doinn/shared/src/containers/city/list/sagas'
import dashletsSagas from 'containers/dashboard/dashlets/sagas'
import deviceInfoSagas from '@doinn/shared/src/containers/device/sagas'
import editItemsRequestSagas from '@doinn/vendor/src/containers/job/edit-items-request/sagas'
import employeeGetSagas from '@doinn/vendor/src/containers/employee/get/sagas'
import employeeAddEditSagas from '@doinn/vendor/src/containers/employee/add-edit/sagas'
import employeesSagas from '@doinn/vendor/src/containers/staff/employees/sagas'
import employeesDeleteSagas from '@doinn/vendor/src/containers/staff/employees/delete/sagas'
import integrationAddSagas from '@doinn/host/src/containers/integrations/add/sagas'
import integrationEditSagas from '@doinn/host/src/containers/integrations/edit/configuration/sagas'
import integrationCatalogListSagas from '@doinn/host/src/containers/integrations/catalog/list/sagas'
import integrationListSagas from '@doinn/host/src/containers/integrations/list/sagas'
import integrationActivateSagas from '@doinn/host/src/containers/integrations/list/activate/sagas'
import integrationDeactivateSagas from '@doinn/host/src/containers/integrations/list/deactivate/sagas'
import integrationDeleteSagas from '@doinn/host/src/containers/integrations/list/delete/sagas'
import integrationGetSagas from '@doinn/host/src/containers/integrations/get/sagas'
import integrationRenameSagas from '@doinn/host/src/containers/integrations/edit/rename/sagas'
import integrationEditCredentialsSagas from '@doinn/host/src/containers/integrations/edit/credentials/sagas'
import sentInvoicesSagas from '@doinn/shared/src/containers/invoice/sent/sagas'
import jobSagas from 'containers/job/sagas'
import jobAvailableSchedulesSagas from '@doinn/host/src/containers/job/available-schedules/sagas'
import jobAddSagas from '@doinn/host/src/containers/job/add/sagas'
import jobAssignStaffSagas from 'containers/job/assign-staff/sagas'
import jobExtraServiceAddFormSagas from 'containers/job/extra-service/add/sagas'
import jobExtraServiceListSagas from 'containers/job/extra-service/list/sagas'
import jobsDispatchSaga from 'containers/jobs/dispatch/sagas'
import jobChecklistGetSagas from '@doinn/shared/src/containers/job/checklist/get/sagas'
import jobChecklistEditSagas from 'containers/job/checklist/edit/sagas'
import jobHistorySagas from '@doinn/shared/src/containers/job/history/sagas'
import jobReportProblemSagas from 'containers/job/report-problem/sagas'
import jobViewReportedProblemSagas from 'containers/job/report-problem/view/sagas'
import jobFiltersSagas from '@doinn/shared/src/containers/jobs/filters/sagas'
import jobServiceDeleteSagas from 'containers/job/delete/sagas'
import jobsSagas from 'containers/jobs/sagas'
import authSagas from '@doinn/shared/src/containers/auth/sagas'
import authRegisterSagas from '@doinn/vendor/src/containers/app/auth/register/sagas'
import notificationsSagas from '@doinn/shared/src/containers/notifications/sagas'
import orderServiceNoteAddFormSagas from '@doinn/shared/src/containers/order-service/notes/add/sagas'
import jobFinishNoteSagas from 'containers/job/finish/note/sagas'
import orderServiceDeleteSaga from 'containers/order-services/delete/sagas'
import orderServiceExtraItemListSaga from 'containers/order-services/extra-item/list/sagas'
import importGetSagas from '@doinn/shared/src/containers/import/get/sagas'
import paymentMethodsSagas from '@doinn/shared/src/containers/subscription-billing/payment/list/sagas'
import paymentMethodsDefaultSagas from '@doinn/shared/src/containers/subscription-billing/payment/default/sagas'
import paymentGatewaysSagas from '@doinn/shared/src/containers/gateways/sagas'
import paymentPaypalSetupSagas from '@doinn/shared/src/containers/gateways/paypal/setup/sagas'
import paymentStripeSetupSagas from '@doinn/shared/src/containers/gateways/stripe/setup/sagas'
import priceListSagas from 'containers/price-list/sagas'
import propertyAddSagas from '@doinn/shared/src/containers/property/add/sagas'
import propertyAutomationListSagas from '@doinn/shared/src/containers/property/automations/sagas'
import propertyImportSagas from '@doinn/shared/src/containers/property/import/sagas'
import propertyEditSagas from '@doinn/shared/src/containers/property/edit/sagas'
import propertyInstructionsEditSagas from '@doinn/shared/src/containers/property/instructions/edit/sagas'
import propertyDeleteSagas from '@doinn/shared/src/containers/property/delete/sagas'
import propertyGetSagas from '@doinn/shared/src/containers/property/get/sagas'
import propertySourcesSagas from '@doinn/shared/src/containers/property-sources/sagas'
import propertyBusinessAccountsSagas from '@doinn/shared/src/containers/property-business-accounts/sagas'
import propertyCitiesSagas from '@doinn/shared/src/containers/property-cities/sagas'
import propertyInstructionsSagas from '@doinn/shared/src/containers/property-instructions/sagas'
import propertyInstructionsImportSagas from '@doinn/shared/src/containers/property-instructions/import/sagas'
import propertyTypesSagas from '@doinn/shared/src/containers/property-types/sagas'
import propertyAssignStaffDialogSagas from '@doinn/shared/src/containers/property/assign-staff/sagas'
import propertyListSagas from '@doinn/shared/src/containers/property/list/sagas'
import propertySpaceCategoriesSagas from '@doinn/shared/src/containers/property-space-categories/sagas'
import propertiesSyncSagas from '@doinn/shared/src/containers/property/sync/sagas'
import providerGetSagas from '@doinn/vendor/src/containers/provider/get/sagas'
import providerListSagas from '@doinn/vendor/src/containers/provider/list/sagas'
import receivedInvoiceSagas from '@doinn/shared/src/containers/invoice/received/sagas'
import reportsBillingSagas from 'containers/reports/billing/sagas'
import reportsBillingDetailsSagas from 'containers/reports/billingDetails/sagas'
import rescheduleRequestSagas from '@doinn/vendor/src/containers/job/reschedule/sagas'
import reservationAddSagas from '@doinn/vendor/src/containers/reservation/add/sagas'
import reservationBlockSagas from '@doinn/vendor/src/containers/reservation/block/sagas'
import reservationDeleteSagas from '@doinn/vendor/src/containers/reservation/delete/sagas'
import reservationDeleteAllSagas from '@doinn/vendor/src/containers/reservation/delete-all/sagas'
import reservationEditSagas from '@doinn/vendor/src/containers/reservation/edit/sagas'
import reservationGetSagas from '@doinn/vendor/src/containers/reservation/get/sagas'
import reservationListSagas from '@doinn/vendor/src/containers/reservation/list/sagas'
import reservationUnblockSagas from '@doinn/vendor/src/containers/reservation/unblock/sagas'
import reservationSyncSagas from '@doinn/vendor/src/containers/reservation/sync/sagas'
import orderServiceExtraItemsEditFormSagas from 'containers/order-services/extra-item/edit/sagas'
import servicesSagas from 'containers/services/sagas'
import teamGetSagas from '@doinn/vendor/src/containers/team/get/sagas'
import teamsSagas from '@doinn/vendor/src/containers/staff/teams/sagas'
import teamAddEditSagas from '@doinn/vendor/src/containers/team/add-edit/sagas'
import teamsDeleteSagas from '@doinn/vendor/src/containers/staff/teams/delete/sagas'
import memberGetSagas from '@doinn/vendor/src/containers/member/get/sagas'
import memberEditSagas from '@doinn/vendor/src/containers/member/edit/sagas'
import memberPasswordEditSagas from '@doinn/vendor/src/containers/member/edit-password/sagas'
import memberListSagas from '@doinn/vendor/src/containers/member/list/sagas'
import memberDeleteSagas from '@doinn/shared/src/containers/members/delete/sagas'
import memberInvitationsSagas from '@doinn/vendor/src/containers/member/invitation/list/sagas'
import memberInvitationDeleteSagas from '@doinn/vendor/src/containers/member/invitation/delete/sagas'
import membersInvitationsAddSagas from '@doinn/vendor/src/containers/member/invitation/add/sagas'
import membersInvitationsResendSagas from '@doinn/vendor/src/containers/member/invitation/resend/sagas'
import membersInvitationsImportSagas from '@doinn/vendor/src/containers/member/invitation/import/sagas'
import subscriptionPlansSagas from '@doinn/shared/src/containers/subscription/plans/sagas'
import subscriptionOverviewSagas from '@doinn/shared/src/containers/subscription-billing/subscription/sagas'
import invitationsAnswerSagas from '@doinn/vendor/src/containers/member/invitation/answer/sagas'
import newPasswordSagas from '@doinn/shared/src/containers/new-password/sagas'
import providersInvitationsAddSagas from '@doinn/vendor/src/containers/provider/add/sagas'
import customersInvitationsAddSagas from '@doinn/vendor/src/containers/customer/add/sagas'
import updateRequestSagas from '@doinn/vendor/src/containers/job/update-request/sagas'
import userProfileChangePasswordSagas from '@doinn/shared/src/containers/profile/sections/change-password/sagas'
import userProfileSagas from '@doinn/shared/src/containers/profile/sagas'
import userSagas from '@doinn/shared/src/containers/user/sagas'
import onboardingSagas from 'containers/onboarding/sagas'
import onboardingSaasAnnouncementSagas from '@doinn/shared/src/containers/onboarding/saas-announcement/sagas'
import onboardingSurveySagas from '@doinn/shared/src/containers/onboarding/survey/sagas'
import onboardingDemoSagas from '@doinn/shared/src/containers/onboarding/demo/sagas'
import onboardingFloatingReducers from '@doinn/shared/src/containers/onboarding/floating/sagas'
import languageSagas from '@doinn/shared/src/containers/language/sagas'
import userLoggedInAsSagas from '@doinn/shared/src/containers/user-logged-in-as/sagas'
import cataloguePropertyOptionalItemsSagas from '@doinn/shared/src/containers/catalogue/property-optional-items/sagas'
import resetPasswordSagas from '@doinn/shared/src/containers/auth/reset-password/sagas'
import supportSagas from '@doinn/vendor/src/containers/support/sagas'

export default function* rootSaga() {
  yield all([
    appSagas(),
    automationListSagas(),
    automationGetSagas(),
    automationAddSagas(),
    automationEditSagas(),
    automationActivateSagas(),
    automationDeactivateSagas(),
    automationDeleteSagas(),
    automationsFiltersSagas(),
    billingGeneralSagas(),
    billingGroupSagas(),
    billingGetSagas(),
    billingEditSagas(),
    billingAddSagas(),
    billingDeleteSagas(),
    cancelRequestSagas(),
    catalogAddSagas(),
    catalogItemImportSagas(),
    catalogEditSagas(),
    catalogDeleteSagas(),
    catalogListSagas(),
    catalogGetSagas(),
    catalogItemAddSagas(),
    catalogItemEditSagas(),
    catalogItemDeleteSagas(),
    catalogItemListSagas(),
    catalogItemGetSagas(),
    catalogueCopySagas(),
    catalogueMyAddSagas(),
    catalogueMyEditSagas(),
    catalogueMyEditDescriptionSagas(),
    catalogueMyEditSettingsSagas(),
    catalogueMyEditPricesSagas(),
    catalogueMyDeleteSagas(),
    catalogueMyListSagas(),
    catalogueMyGetSagas(),
    catalogueMyShareSagas(),
    catalogueMyUnshareSagas(),
    catalogueSharedListSagas(),
    catalogueSharedGetSagas(),
    catalogueTemplateListSagas(),
    catalogueTemplateGetSagas(),
    cataloguesFiltersSagas(),
    checkoutSagas(),
    checkoutCouponSagas(),
    checkoutCreditsSagas(),
    checkoutRemoveServiceSagas(),
    chatsSagas(),
    chatsContactsSagas(),
    chatsJobsSagas(),
    chatsPropertiesSagas(),
    chatMessagesSagas(),
    chatAddSagas(),
    chatFollowSagas(),
    chatGetSagas(),
    checklistCopySagas(),
    checklistMyAddSagas(),
    checklistMyEditSagas(),
    checklistMyDeleteSagas(),
    checklistMyListSagas(),
    checklistMyGetSagas(),
    checklistMyImportSagas(),
    checklistPublicListSagas(),
    checklistPublicGetSagas(),
    cityListSagas(),
    customerGetSagas(),
    customerListSagas(),
    customerInvitationDeleteSagas(),
    dashletsSagas(),
    deviceInfoSagas(),
    editItemsRequestSagas(),
    employeeGetSagas(),
    employeeAddEditSagas(),
    employeesSagas(),
    employeesDeleteSagas(),
    sentInvoicesSagas(),
    integrationAddSagas(),
    integrationEditSagas(),
    integrationCatalogListSagas(),
    integrationListSagas(),
    integrationActivateSagas(),
    integrationDeactivateSagas(),
    integrationDeleteSagas(),
    integrationGetSagas(),
    integrationRenameSagas(),
    integrationEditCredentialsSagas(),
    jobSagas(),
    jobAvailableSchedulesSagas(),
    jobAddSagas(),
    jobAssignStaffSagas(),
    jobExtraServiceAddFormSagas(),
    jobExtraServiceListSagas(),
    jobsDispatchSaga(),
    jobChecklistGetSagas(),
    jobChecklistEditSagas(),
    jobHistorySagas(),
    jobReportProblemSagas(),
    jobViewReportedProblemSagas(),
    jobFiltersSagas(),
    jobServiceDeleteSagas(),
    jobsSagas(),
    authSagas(),
    authRegisterSagas(),
    languageSagas(DEFAULT_LANGUAGE_CODE, language =>
      i18n.changeLanguage(language)
    ),
    notificationsSagas(),
    orderServiceNoteAddFormSagas(),
    jobFinishNoteSagas(),
    orderServiceDeleteSaga(),
    orderServiceExtraItemListSaga(),
    importGetSagas(),
    paymentMethodsSagas(),
    paymentMethodsDefaultSagas(),
    paymentGatewaysSagas(),
    paymentPaypalSetupSagas(),
    paymentStripeSetupSagas(),
    priceListSagas(),
    propertyAddSagas(),
    propertyAutomationListSagas(),
    propertyImportSagas(),
    propertyEditSagas(),
    propertyInstructionsEditSagas(),
    propertyDeleteSagas(),
    propertyGetSagas(),
    propertySourcesSagas(),
    propertyBusinessAccountsSagas(),
    propertyCitiesSagas(),
    propertyInstructionsSagas(),
    propertyInstructionsImportSagas(),
    propertyTypesSagas(),
    propertyAssignStaffDialogSagas(),
    propertyListSagas(),
    propertySpaceCategoriesSagas(),
    propertiesSyncSagas(),
    providerGetSagas(),
    providerListSagas(),
    receivedInvoiceSagas(),
    reportsBillingSagas(),
    reportsBillingDetailsSagas(),
    rescheduleRequestSagas(),
    reservationAddSagas(),
    reservationBlockSagas(),
    reservationDeleteSagas(),
    reservationDeleteAllSagas(),
    reservationEditSagas(),
    reservationGetSagas(),
    reservationListSagas(),
    reservationSyncSagas(),
    reservationUnblockSagas(),
    orderServiceExtraItemsEditFormSagas(),
    servicesSagas(),
    teamGetSagas(),
    teamAddEditSagas(),
    teamsSagas(),
    teamsDeleteSagas(),
    memberEditSagas(),
    memberPasswordEditSagas(),
    memberGetSagas(),
    memberListSagas(),
    memberDeleteSagas(),
    memberInvitationsSagas(),
    memberInvitationDeleteSagas(),
    membersInvitationsAddSagas(),
    membersInvitationsResendSagas(),
    membersInvitationsImportSagas(),
    subscriptionPlansSagas(),
    subscriptionOverviewSagas(),
    invitationsAnswerSagas(),
    newPasswordSagas(),
    providersInvitationsAddSagas(),
    customersInvitationsAddSagas(),
    updateRequestSagas(),
    userProfileChangePasswordSagas(),
    userProfileSagas(),
    userSagas(),
    onboardingSagas(),
    onboardingSaasAnnouncementSagas(),
    onboardingSurveySagas(),
    onboardingDemoSagas(),
    onboardingFloatingReducers(),
    userLoggedInAsSagas(),
    cataloguePropertyOptionalItemsSagas(),
    resetPasswordSagas(),
    supportSagas()
  ])
}
