import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import { fetchCitiesApi } from '@doinn/vendor/src/api/contracts/cities'
import {
  fetchVendorPricesApi,
  saveCityServicePricesApi
} from 'api/contracts/pricing'
import {
  CONTRACT_VENDOR_CITIES_FETCH_FAILED,
  CONTRACT_VENDOR_CITIES_FETCH_REQUESTED,
  CONTRACT_VENDOR_CITIES_FETCH_SUCCEEDED,
  CONTRACT_VENDOR_PRICE_FETCH_FAILED,
  CONTRACT_VENDOR_PRICE_FETCH_REQUESTED,
  CONTRACT_VENDOR_PRICE_FETCH_SUCCEEDED,
  CONTRACT_VENDOR_PRICE_UPDATE_FAILED,
  CONTRACT_VENDOR_PRICE_UPDATE_REQUESTED,
  CONTRACT_VENDOR_PRICE_UPDATE_SUCCEEDED
} from 'containers/onboarding/steps/Prices/constants'

function* fetchVendorCities(action) {
  try {
    const response = yield call(fetchCitiesApi)
    const citiesResponse = response.data
    yield put({
      type: CONTRACT_VENDOR_CITIES_FETCH_SUCCEEDED,
      payload: citiesResponse.data
    })
  } catch (e) {
    yield put({
      type: CONTRACT_VENDOR_CITIES_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* fetchVendorPrices(action) {
  const { serviceId } = action.payload

  try {
    const response = yield call(fetchVendorPricesApi, serviceId)
    const pricesResponse = response.data

    yield put({
      type: CONTRACT_VENDOR_PRICE_FETCH_SUCCEEDED,
      payload: pricesResponse.data
    })
  } catch (e) {
    yield put({
      type: CONTRACT_VENDOR_PRICE_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* savePrices(action) {
  const { prices, serviceId } = action.payload

  try {
    yield call(saveCityServicePricesApi, serviceId, prices)
    yield put({
      type: CONTRACT_VENDOR_PRICE_UPDATE_SUCCEEDED,
      payload: { serviceId }
    })
  } catch (e) {
    yield put({
      type: CONTRACT_VENDOR_PRICE_UPDATE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* pricesSagas() {
  yield all([
    takeLatest(CONTRACT_VENDOR_CITIES_FETCH_REQUESTED, fetchVendorCities),
    takeLatest(CONTRACT_VENDOR_PRICE_FETCH_REQUESTED, fetchVendorPrices),
    takeEvery(CONTRACT_VENDOR_PRICE_UPDATE_REQUESTED, savePrices)
  ])
}
