import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  billingSubscriptionApi,
  confirmCheckoutApi,
  createCheckoutApi,
  getCheckoutApi,
  payCheckoutApi
} from '@doinn/vendor/src/api/checkout'
import {
  CHECKOUT_CONFIRM_FAILED,
  CHECKOUT_CONFIRM_REQUESTED,
  CHECKOUT_CONFIRM_SUCCEEDED,
  CHECKOUT_CREATE_FAILED,
  CHECKOUT_CREATE_REQUESTED,
  CHECKOUT_CREATE_SUCCEEDED,
  CHECKOUT_GET_FAILED,
  CHECKOUT_GET_REQUESTED,
  CHECKOUT_GET_SUCCEEDED,
  CHECKOUT_MULTIPLE_CREATE_REQUESTED,
  CHECKOUT_PAY_FAILED,
  CHECKOUT_PAY_REQUESTED,
  CHECKOUT_PAY_SUCCEEDED,
  CHECKOUT_SUBSCRIPTION_CREATE_REQUESTED,
  CHECKOUT_SUBSCRIPTION_REQUESTED,
  CHECKOUT_SUBSCRIPTION_SUCCEEDED
} from '@doinn/vendor/src/containers/checkout/constants'
import { PAYMENT_CONFIRM_ACTION_REQUESTED } from '@doinn/shared/src/containers/gateways/stripe/confirm-payment/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* createCheckout(action) {
  try {
    const { jobs } = action.payload.params
    const response = yield call(createCheckoutApi, { jobs })

    yield put({
      type: CHECKOUT_CREATE_SUCCEEDED,
      payload: response.data.data
    })
  } catch (e) {
    yield put({
      type: CHECKOUT_CREATE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* createSubscriptionCheckout(action) {
  try {
    const { plan } = action.payload.params
    const response = yield call(createCheckoutApi, { plan })

    yield put({
      type: CHECKOUT_CREATE_SUCCEEDED,
      payload: response.data.data
    })
  } catch (e) {
    yield put({
      type: CHECKOUT_CREATE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* getCheckout(action) {
  try {
    const { uuid } = action.payload.params
    const response = yield call(getCheckoutApi, uuid)

    yield put({
      type: CHECKOUT_GET_SUCCEEDED,
      payload: response.data.data
    })
  } catch (e) {
    yield put({
      type: CHECKOUT_GET_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* confirmCheckout(action) {
  try {
    const { params } = action.payload
    const response = yield call(confirmCheckoutApi, params)

    yield put({
      type: CHECKOUT_CONFIRM_SUCCEEDED,
      payload: response.data.data
    })
  } catch (e) {
    yield put({
      type: CHECKOUT_CONFIRM_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* payCheckout(action) {
  try {
    const { params } = action.payload
    const response = yield call(payCheckoutApi, params)

    yield put({
      type: CHECKOUT_PAY_SUCCEEDED,
      payload: response.data.data
    })
    if (action.payload?.isSubscription) {
      yield put({
        type: CHECKOUT_SUBSCRIPTION_SUCCEEDED
      })
    }
  } catch (e) {
    if (
      e?.response?.status === 400 &&
      e?.response?.data?.data?.requiredAction
    ) {
      const data = {
        clientSecret: e?.response?.data?.data?.clientSecret,
        gateway: e?.response?.data?.data?.gateway,
        params: action.payload?.params
      }

      yield put({
        type: PAYMENT_CONFIRM_ACTION_REQUESTED,
        payload: data
      })
    } else {
      yield put({
        type: CHECKOUT_PAY_FAILED,
        snackbar: {
          variant: 'error',
          message: getSnackbarErrorMessage(e)
        }
      })
    }
  }
}

function* subscriptionCheckout(action) {
  try {
    const { params } = action.payload
    const { billingParams, checkoutParams } = params
    if (Object.values(billingParams).length) {
      yield call(billingSubscriptionApi, billingParams)
    }
    yield call(payCheckout, {
      payload: { params: checkoutParams, isSubscription: true }
    })
  } catch (e) {
    yield put({
      type: CHECKOUT_PAY_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* checkoutSaga() {
  yield all([
    takeLatest(CHECKOUT_MULTIPLE_CREATE_REQUESTED, createCheckout),
    takeLatest(CHECKOUT_CREATE_REQUESTED, createCheckout),
    takeLatest(
      CHECKOUT_SUBSCRIPTION_CREATE_REQUESTED,
      createSubscriptionCheckout
    ),
    takeLatest(CHECKOUT_GET_REQUESTED, getCheckout),
    takeLatest(CHECKOUT_CONFIRM_REQUESTED, confirmCheckout),
    takeLatest(CHECKOUT_PAY_REQUESTED, payCheckout),
    takeLatest(CHECKOUT_SUBSCRIPTION_REQUESTED, subscriptionCheckout)
  ])
}
