export const AUTOMATIONS_DEACTIVATE_CONFIRM_OPEN =
  'AUTOMATIONS_DEACTIVATE_CONFIRM_OPEN'
export const AUTOMATIONS_DEACTIVATE_CONFIRM_CLOSE =
  'AUTOMATIONS_DEACTIVATE_CONFIRM_CLOSE'

export const AUTOMATIONS_DEACTIVATE_REQUESTED =
  'AUTOMATIONS_DEACTIVATE_REQUESTED'
export const AUTOMATIONS_DEACTIVATE_SUCCEEDED =
  'AUTOMATIONS_DEACTIVATE_SUCCEEDED'
export const AUTOMATIONS_DEACTIVATE_FAILED = 'AUTOMATIONS_DEACTIVATE_FAILED'
