export const INTEGRATIONS_DEACTIVATE_CONFIRM_OPEN =
  'INTEGRATIONS_DEACTIVATE_CONFIRM_OPEN'
export const INTEGRATIONS_DEACTIVATE_CONFIRM_CLOSE =
  'INTEGRATIONS_DEACTIVATE_CONFIRM_CLOSE'

export const INTEGRATIONS_DEACTIVATE_REQUESTED =
  'INTEGRATIONS_DEACTIVATE_REQUESTED'
export const INTEGRATIONS_DEACTIVATE_SUCCEEDED =
  'INTEGRATIONS_DEACTIVATE_SUCCEEDED'
export const INTEGRATIONS_DEACTIVATE_FAILED = 'INTEGRATIONS_DEACTIVATE_FAILED'
