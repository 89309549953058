export const PROPERTIES_SYNC_DIALOG_OPEN = 'PROPERTIES_SYNC_DIALOG_OPEN'
export const PROPERTIES_SYNC_DIALOG_CLOSE = 'PROPERTIES_SYNC_DIALOG_CLOSE'

export const PROPERTIES_SYNC_REQUESTED = 'PROPERTIES_SYNC_REQUESTED'
export const PROPERTIES_SYNC_SUCCEEDED = 'PROPERTIES_SYNC_SUCCEEDED'
export const PROPERTIES_SYNC_FAILED = 'PROPERTIES_SYNC_FAILED'

export const PROPERTIES_SYNC_STARTED = 'PROPERTIES_SYNC_STARTED'
export const PROPERTIES_SYNC_COMPLETED = 'PROPERTIES_SYNC_COMPLETED'
export const PROPERTIES_SYNC_CLEAR = 'PROPERTIES_SYNC_CLEAR'
