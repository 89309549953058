import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getJobAvailableSchedulesApi } from '@doinn/host/src/api/job'
import {
  JOB_GET_AVAILABLE_SCHEDULES_FAILED,
  JOB_GET_AVAILABLE_SCHEDULES_REQUESTED,
  JOB_GET_AVAILABLE_SCHEDULES_SUCCEEDED
} from '@doinn/host/src/containers/job/available-schedules/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* getJobAvailableSchedules(action) {
  try {
    const response = yield call(
      getJobAvailableSchedulesApi,
      action.payload.params
    )
    yield put({
      type: JOB_GET_AVAILABLE_SCHEDULES_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: JOB_GET_AVAILABLE_SCHEDULES_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* jobAvailableSchedulesSaga() {
  yield all([
    takeLatest(JOB_GET_AVAILABLE_SCHEDULES_REQUESTED, getJobAvailableSchedules)
  ])
}
