import {
  DASHLET_REPORTS_OVERVIEW_FAILED,
  DASHLET_REPORTS_OVERVIEW_REQUESTED,
  DASHLET_REPORTS_OVERVIEW_SUCCEEDED
} from 'containers/dashboard/dashlets/reports/constants'

export const initialState = {
  data: {},
  isLoaded: false,
  isLoading: false,
  isFailed: false
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case DASHLET_REPORTS_OVERVIEW_REQUESTED:
      return {
        ...state,
        ...initialState,
        isLoaded: false,
        isLoading: true,
        isFailed: false
      }
    case DASHLET_REPORTS_OVERVIEW_SUCCEEDED:
      return {
        ...state,
        data: { ...payload.data },
        isLoaded: true,
        isLoading: false,
        isFailed: false
      }
    case DASHLET_REPORTS_OVERVIEW_FAILED:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        isFailed: true
      }
    default:
      return state
  }
}
