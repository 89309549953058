export const TEAM_ADD_EDIT_FORM_OPEN_REQUESTED =
  'TEAM_ADD_EDIT_FORM_OPEN_REQUESTED'

export const TEAM_ADD_EDIT_FORM_CLOSE_REQUESTED =
  'TEAM_ADD_EDIT_FORM_CLOSE_REQUESTED'

export const TEAM_ADD_EDIT_FORM_SAVE_REQUESTED =
  'TEAM_ADD_EDIT_FORM_SAVE_REQUESTED'
export const TEAM_ADD_EDIT_FORM_SAVE_SUCCEEDED =
  'TEAM_ADD_EDIT_FORM_SAVE_SUCCEEDED'
export const TEAM_ADD_EDIT_FORM_SAVE_FAILED = 'TEAM_ADD_EDIT_FORM_SAVE_FAILED'
