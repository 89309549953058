import { useEffect, useState } from 'react'
import { Network } from '@capacitor/network'

export default function useNetworkStatus() {
  const [networkStatus, setNetworkStatus] = useState({ connected: true })

  useEffect(() => {
    const getStatus = async () => {
      const status = await Network.getStatus()
      setNetworkStatus(status)
    }
    getStatus()
  }, [setNetworkStatus])

  useEffect(() => {
    Network.addListener('networkStatusChange', status => {
      setNetworkStatus(status)
    })

    return () => Network.removeAllListeners()
  }, [setNetworkStatus])

  return networkStatus
}
