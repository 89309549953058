import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchPropertySourcesApi } from '@doinn/shared/src/api/property-sources'
import {
  PROPERTY_SOURCES_FETCH_FAILED,
  PROPERTY_SOURCES_FETCH_REQUESTED,
  PROPERTY_SOURCES_FETCH_SUCCEEDED
} from '@doinn/shared/src/containers/property-sources/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchPropertySources(action) {
  try {
    const response = yield call(fetchPropertySourcesApi)
    yield put({
      type: PROPERTY_SOURCES_FETCH_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: PROPERTY_SOURCES_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* propertySourcesSaga() {
  yield all([
    takeLatest(PROPERTY_SOURCES_FETCH_REQUESTED, fetchPropertySources)
  ])
}
