import {
  CHECKLIST_COPY_DIALOG_CLOSE,
  CHECKLIST_COPY_DIALOG_OPEN,
  CHECKLIST_COPY_FAILED,
  CHECKLIST_COPY_REQUESTED,
  CHECKLIST_COPY_SUCCEEDED
} from '@doinn/host/src/containers/checklist/copy/constants'

export const STATUS = {
  IDLE: 'idle',
  SAVING: 'saving',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  isOpened: false,
  status: STATUS.IDLE,
  lastCopiedItem: null
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case CHECKLIST_COPY_DIALOG_OPEN:
      return {
        ...initialState,
        isOpened: true
      }
    case CHECKLIST_COPY_DIALOG_CLOSE:
      return {
        ...initialState,
        isOpened: false
      }
    case CHECKLIST_COPY_REQUESTED:
      return {
        ...state,
        lastCopiedItem: null,
        status: STATUS.SAVING
      }
    case CHECKLIST_COPY_SUCCEEDED:
      return {
        ...state,
        lastCopiedItem: null,
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case CHECKLIST_COPY_FAILED:
      return {
        ...state,
        lastCopiedItem: null,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
