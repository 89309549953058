import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function getJobApi(id) {
  const config = {}
  return DoinnApi.get(`/host/jobs/${id}`, config)
}

export function fetchJobsApi(params) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/host/jobs`, config)
}

export function getJobAvailableSchedulesApi(jobId) {
  const config = {}
  return DoinnApi.get(`/host/jobs/${jobId}/available-schedules`, config)
}

export function updateJobRequestedStartApi(
  job,
  datetime,
  datetimeEnd = null,
  timeWindowId = null
) {
  const config = {}
  const params = {
    date_time: datetime
  }

  if (datetimeEnd) {
    params.date_time_end = datetimeEnd
  }

  if (timeWindowId) {
    params.time_window_id = timeWindowId
  }

  return DoinnApi.put(`/host/jobs/${job.id}/schedule`, params, config)
}

export function addJobApi(job) {
  const config = {}
  return DoinnApi.post(`/host/jobs`, { ...job }, config)
}

export function getJobChecklistApi(jobId) {
  const config = {}
  return DoinnApi.get(`/host/jobs/${jobId}/checklist`, config)
}
