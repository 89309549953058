import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { authRegisterApi } from '@doinn/shared/src/api/auth'
import { getLoggedUser } from '@doinn/shared/src/containers/user/actions'
import {
  REGISTER_FAILED,
  REGISTER_REQUESTED,
  REGISTER_SUCCEEDED
} from '@doinn/vendor/src/containers/app/auth/register/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* register(action) {
  try {
    const { params } = action.payload
    const response = yield call(authRegisterApi, params)
    const { token } = response.data.data
    window.localStorage.setItem('authToken', token)
    if (token) {
      yield put({
        type: REGISTER_SUCCEEDED,
        payload: token
      })
      yield put(getLoggedUser())
    }
  } catch (e) {
    let message = getSnackbarErrorMessage(e)

    if (
      e?.response?.status === 400 &&
      ['validation.unique.'].includes(e?.response?.data?.data?.errors?.message)
    ) {
      message = i18n.t('Email already exists!')
    }

    yield put({
      type: REGISTER_FAILED,
      snackbar: {
        variant: 'error',
        message
      }
    })
  }
}

export default function* authRegisterSagas() {
  yield all([takeLatest(REGISTER_REQUESTED, register)])
}
