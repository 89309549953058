import {
  JOB_SERVICE_DELETE_CONFIRM_CLOSE,
  JOB_SERVICE_DELETE_CONFIRM_OPEN,
  JOB_SERVICE_DELETE_FAILED,
  JOB_SERVICE_DELETE_REQUESTED,
  JOB_SERVICE_DELETE_SUCCEEDED,
  JOB_SERVICE_DETAILS_FAILED,
  JOB_SERVICE_DETAILS_SUCCEEDED
} from 'containers/job/delete/constants'

export const STATUS = {
  IDLE: 'idle',
  FETCHING: 'fetching',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  isOpened: false,
  status: STATUS.IDLE,
  item: null
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case JOB_SERVICE_DELETE_CONFIRM_OPEN:
      return {
        ...initialState,
        status: STATUS.FETCHING,
        isOpened: true
      }
    case JOB_SERVICE_DELETE_CONFIRM_CLOSE:
      return {
        ...initialState,
        isOpened: false,
        item: state.item
      }
    case JOB_SERVICE_DELETE_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING
      }
    case JOB_SERVICE_DELETE_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case JOB_SERVICE_DELETE_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case JOB_SERVICE_DETAILS_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case JOB_SERVICE_DETAILS_SUCCEEDED:
      return {
        ...state,
        status: STATUS.IDLE,
        item: action.payload.data
      }
    default:
      return state
  }
}
