import {
  REPORTS_BILLING_DOWNLOAD_FAILED,
  REPORTS_BILLING_DOWNLOAD_REQUESTED,
  REPORTS_BILLING_DOWNLOAD_SUCCEEDED,
  REPORTS_BILLING_EXPORT_EMAIL_FAILED,
  REPORTS_BILLING_EXPORT_EMAIL_REQUESTED,
  REPORTS_BILLING_EXPORT_EMAIL_SUCCEEDED,
  REPORTS_BILLING_FETCH_FAILED,
  REPORTS_BILLING_FETCH_REQUESTED,
  REPORTS_BILLING_FETCH_SUCCEEDED
} from 'containers/reports/billing/constants'

export const initialState = {
  data: [],
  meta: {},
  filters: {},
  isLoaded: false,
  isLoading: false,
  isFailed: false,
  isExporting: false
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case REPORTS_BILLING_FETCH_REQUESTED:
      return {
        ...state,
        ...initialState,
        isLoaded: false,
        isLoading: true,
        isFailed: false
      }
    case REPORTS_BILLING_FETCH_SUCCEEDED:
      return {
        ...state,
        data: [...payload.data],
        meta: { ...payload.meta },
        filters: { ...payload.filters },
        isLoaded: true,
        isLoading: false,
        isFailed: false
      }
    case REPORTS_BILLING_FETCH_FAILED:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        isFailed: true
      }
    case REPORTS_BILLING_DOWNLOAD_REQUESTED:
    case REPORTS_BILLING_EXPORT_EMAIL_REQUESTED:
      return {
        ...state,
        isExporting: true
      }
    case REPORTS_BILLING_DOWNLOAD_SUCCEEDED:
    case REPORTS_BILLING_EXPORT_EMAIL_SUCCEEDED:
      return {
        ...state,
        isExporting: false
      }
    case REPORTS_BILLING_DOWNLOAD_FAILED:
    case REPORTS_BILLING_EXPORT_EMAIL_FAILED:
      return {
        ...state,
        isExporting: false
      }
    default:
      return state
  }
}
