import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getProviderApi } from '@doinn/vendor/src/api/provider'
import {
  PROVIDER_GET_FAILED,
  PROVIDER_GET_REQUESTED,
  PROVIDER_GET_SUCCEEDED
} from '@doinn/vendor/src/containers/provider/get/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* getProvider(action) {
  try {
    const response = yield call(getProviderApi, action.payload.params)
    yield put({
      type: PROVIDER_GET_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: PROVIDER_GET_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* providerGetSaga() {
  yield all([takeLatest(PROVIDER_GET_REQUESTED, getProvider)])
}
