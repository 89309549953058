import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  deleteInvitationCatalogueMyApi,
  unshareCatalogueMyApi
} from '@doinn/shared/src/api/catalogues'
import {
  CATALOGUE_MY_UNSHARE_FAILED,
  CATALOGUE_MY_UNSHARE_REQUESTED,
  CATALOGUE_MY_UNSHARE_SUCCEEDED
} from '@doinn/shared/src/containers/catalogue/my/unshare/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* unshareCatalogue(action) {
  try {
    const { catalogueId, businessAccountId, email } = action.payload
    if (businessAccountId) {
      yield call(unshareCatalogueMyApi, { catalogueId, businessAccountId })
    } else {
      yield call(deleteInvitationCatalogueMyApi, { catalogueId, email })
    }
    yield put({
      type: CATALOGUE_MY_UNSHARE_SUCCEEDED,
      payload: action.payload,
      snackbar: {
        variant: 'success',
        message: businessAccountId
          ? i18n.t('The catalogue has been unshared successfully')
          : i18n.t('The invitation has been deleted successfully')
      }
    })
  } catch (e) {
    yield put({
      type: CATALOGUE_MY_UNSHARE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* catalogueMyDeleteSaga() {
  yield all([takeLatest(CATALOGUE_MY_UNSHARE_REQUESTED, unshareCatalogue)])
}
