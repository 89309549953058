import {
  AUTOMATIONS_FETCH_MORE_REQUESTED,
  AUTOMATIONS_FETCH_REQUESTED
} from '@doinn/host/src/containers/automations/list/constants'

export function fetchAutomations(params = {}) {
  return { type: AUTOMATIONS_FETCH_REQUESTED, payload: { params } }
}

export function fetchMoreAutomations(params = {}) {
  return {
    type: AUTOMATIONS_FETCH_MORE_REQUESTED,
    payload: { params }
  }
}
