export const CATALOGUE_LIST_TEMPLATES_FETCH_REQUESTED =
  'CATALOGUE_LIST_TEMPLATES_FETCH_REQUESTED'
export const CATALOGUE_LIST_TEMPLATES_FETCH_SUCCEEDED =
  'CATALOGUE_LIST_TEMPLATES_FETCH_SUCCEEDED'
export const CATALOGUE_LIST_TEMPLATES_FETCH_FAILED =
  'CATALOGUE_LIST_TEMPLATES_FETCH_FAILED'

export const CATALOGUE_LIST_TEMPLATES_FETCH_MORE_REQUESTED =
  'CATALOGUE_LIST_TEMPLATES_FETCH_MORE_REQUESTED'
export const CATALOGUE_LIST_TEMPLATES_FETCH_MORE_SUCCEEDED =
  'CATALOGUE_LIST_TEMPLATES_FETCH_MORE_SUCCEEDED'
export const CATALOGUE_LIST_TEMPLATES_FETCH_MORE_FAILED =
  'CATALOGUE_LIST_TEMPLATES_FETCH_MORE_FAILED'
