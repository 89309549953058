import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import { acceptTermsConditionsApi } from 'api/terms'
import {
  ACCEPT_TERMS_AND_CONDITIONS_FAILED,
  ACCEPT_TERMS_AND_CONDITIONS_REQUESTED,
  ACCEPT_TERMS_AND_CONDITIONS_SUCCEEDED
} from 'containers/onboarding/steps/Terms/constants'

function* acceptTermsConditions(action) {
  try {
    const responseTerms = yield call(acceptTermsConditionsApi)
    if (responseTerms.data.data.acceptedTerms) {
      yield put({
        type: ACCEPT_TERMS_AND_CONDITIONS_SUCCEEDED
      })
    }
  } catch (e) {
    yield put({
      type: ACCEPT_TERMS_AND_CONDITIONS_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* termsSagas() {
  yield all([
    takeLatest(ACCEPT_TERMS_AND_CONDITIONS_REQUESTED, acceptTermsConditions)
  ])
}
