import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import {
  getPropertyApi,
  updatePropertyEmployeesApi,
  updatePropertyTeamApi
} from '@doinn/shared/src/api/property'
import {
  PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_CONFIRM_FAILED,
  PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_CONFIRM_REQUESTED,
  PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_CONFIRM_SUCCEEDED,
  PROPERTY_ASSIGN_STAFF_FORM_GET_PROPERTY_FAILED,
  PROPERTY_ASSIGN_STAFF_FORM_GET_PROPERTY_REQUESTED,
  PROPERTY_ASSIGN_STAFF_FORM_GET_PROPERTY_SUCCEEDED,
  PROPERTY_ASSIGN_STAFF_FORM_TEAM_CONFIRM_FAILED,
  PROPERTY_ASSIGN_STAFF_FORM_TEAM_CONFIRM_REQUESTED,
  PROPERTY_ASSIGN_STAFF_FORM_TEAM_CONFIRM_SUCCEEDED
} from '@doinn/shared/src/containers/property/assign-staff/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

const getState = state => state.propertyAssignStaffDialog

function* getProperty(action) {
  try {
    const response = yield call(getPropertyApi, action.payload.params)

    yield put({
      type: PROPERTY_ASSIGN_STAFF_FORM_GET_PROPERTY_SUCCEEDED,
      payload: {
        data: response.data.data
      }
    })
  } catch (e) {
    yield put({
      type: PROPERTY_ASSIGN_STAFF_FORM_GET_PROPERTY_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* assignEmployees(action) {
  try {
    const propertyId = action.payload.propertyId
    const { selectedEmployees } = yield select(getState)
    const response = yield call(
      updatePropertyEmployeesApi,
      propertyId,
      selectedEmployees
    )
    const employees = response.data.data

    yield put({
      type: PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_CONFIRM_SUCCEEDED,
      payload: {
        data: {
          propertyId,
          employees
        }
      }
    })
  } catch (e) {
    yield put({
      type: PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_CONFIRM_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* assignTeam(action) {
  try {
    const propertyId = action.payload.propertyId
    const { selectedTeam } = yield select(getState)
    const response = yield call(updatePropertyTeamApi, propertyId, selectedTeam)
    const team = response.data.data

    yield put({
      type: PROPERTY_ASSIGN_STAFF_FORM_TEAM_CONFIRM_SUCCEEDED,
      payload: {
        data: {
          propertyId,
          team
        }
      }
    })
  } catch (e) {
    yield put({
      type: PROPERTY_ASSIGN_STAFF_FORM_TEAM_CONFIRM_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* watchGetProperty() {
  yield takeLatest(
    PROPERTY_ASSIGN_STAFF_FORM_GET_PROPERTY_REQUESTED,
    getProperty
  )
}

function* watchAssignEmployees() {
  yield takeLatest(
    PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_CONFIRM_REQUESTED,
    assignEmployees
  )
}

function* watchAssignTeam() {
  yield takeLatest(
    PROPERTY_ASSIGN_STAFF_FORM_TEAM_CONFIRM_REQUESTED,
    assignTeam
  )
}

export default function* propertyAssignStaffDialogSaga() {
  yield all([watchGetProperty(), watchAssignEmployees(), watchAssignTeam()])
}
