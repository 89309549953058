import {
  PROVIDERS_INVITATIONS_ADD_DIALOG_CLOSE,
  PROVIDERS_INVITATIONS_ADD_DIALOG_OPEN,
  PROVIDERS_INVITATIONS_ADD_FAILED,
  PROVIDERS_INVITATIONS_ADD_REQUESTED,
  PROVIDERS_INVITATIONS_ADD_SUCCEEDED
} from '@doinn/vendor/src/containers/provider/add/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  errorMessage: '',
  isOpened: false,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case PROVIDERS_INVITATIONS_ADD_DIALOG_OPEN:
      return {
        ...initialState,
        isOpened: true
      }
    case PROVIDERS_INVITATIONS_ADD_DIALOG_CLOSE:
      return {
        ...initialState,
        isOpened: false
      }
    case PROVIDERS_INVITATIONS_ADD_REQUESTED:
      return {
        ...state,
        errorMessage: '',
        status: STATUS.LOADING
      }
    case PROVIDERS_INVITATIONS_ADD_SUCCEEDED:
      return {
        ...state,
        errorMessage: '',
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case PROVIDERS_INVITATIONS_ADD_FAILED:
      return {
        ...state,
        errorMessage: payload.errorMessage,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
