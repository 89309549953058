import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function getJobApi(id) {
  const config = {}
  return DoinnApi.get(`/vendor/jobs/${id}`, config)
}

export function fetchJobsApi(params) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/vendor/jobs`, config)
}

export function fetchJobExtraServicesApi(id) {
  const config = {}
  return DoinnApi.get(`/vendor/jobs/${id}/extra-services`, config)
}

export function updateJobEstimatedStartApi(params) {
  const config = {}
  return DoinnApi.put(`/vendor/jobs/${params.jobId}/schedule`, params, config)
}

export function updateJobExecutionApi(id, action) {
  const config = {}
  config.action = action
  return DoinnApi.patch(`/vendor/jobs/${id}`, config)
}

export function updateJobStaffApi(params) {
  const config = {}
  return DoinnApi.put(`/vendor/jobs/${params.jobId}/employees`, params, config)
}

export function addJobExtraServiceApi(params) {
  const config = {}
  return DoinnApi.post(
    `/vendor/jobs/${params.jobId}/extra-services`,
    params,
    config
  )
}

export function getJobChecklistApi(jobId) {
  const config = {}
  return DoinnApi.get(`/vendor/jobs/${jobId}/checklist`, config)
}

export function editJobChecklistApi(params = {}) {
  const config = {}
  const data = { ...params }
  return DoinnApi.put(`/vendor/jobs/${params.jobId}/checklist`, data, config)
}

export function addfinishNoteApi(job, note) {
  const formData = new window.FormData()
  const request = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }

  formData.append('note', note.note)

  if (note.category) {
    formData.append('category', note.category)
  }

  note.photos.forEach((photo, index) =>
    formData.append(`photos[${index}]`, photo.file)
  )

  return DoinnApi.post(`/vendor/jobs/${job.id}/finish-note`, formData, request)
}

export function createJobUpdateRequestApi(id, params = {}) {
  const config = {}
  const data = { ...params }
  return DoinnApi.post(`/jobs/${id}/update-requests`, data, config)
}

export function aproveJobUpdateRequestApi(jobId, requestId) {
  const config = {}
  return DoinnApi.patch(
    `/jobs/${jobId}/update-requests/${requestId}/accept`,
    config
  )
}

export function refuseJobUpdateRequestApi(jobId, requestId, params = {}) {
  const config = {}
  const data = { ...params }
  return DoinnApi.patch(
    `/jobs/${jobId}/update-requests/${requestId}/refuse`,
    data,
    config
  )
}
