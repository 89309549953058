import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function fetchProvidersApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/people/providers`, config)
}

export function getProviderApi(provider) {
  const config = {}
  return DoinnApi.get(`/people/providers/${provider.id}`, config)
}
