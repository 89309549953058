import {
  RESERVATIONS_FETCH_FAILED,
  RESERVATIONS_FETCH_REQUESTED,
  RESERVATIONS_FETCH_SUCCEEDED
} from '@doinn/vendor/src/containers/reservation/list/constants'
import { RESERVATION_EDIT_SUCCEEDED } from '@doinn/vendor/src/containers/reservation/edit/constants'
import { RESERVATION_BLOCK_SUCCEEDED } from '@doinn/vendor/src/containers/reservation/block/constants'
import { RESERVATION_DELETE_SUCCEEDED } from '@doinn/vendor/src/containers/reservation/delete/constants'
import { RESERVATION_UNBLOCK_SUCCEEDED } from '@doinn/vendor/src/containers/reservation/unblock/constants'

export const STATUS = {
  IDLE: 'idle',
  FETCHING: 'fetching',
  // LOADING_MORE: 'loading-more',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  list: [],
  meta: {},
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case RESERVATIONS_FETCH_REQUESTED:
      return {
        ...state,
        ...initialState,
        status: STATUS.FETCHING
      }
    case RESERVATIONS_FETCH_SUCCEEDED:
      return {
        ...state,
        list: [...payload.data],
        meta: { ...payload.meta },
        status: STATUS.SUCCESS
      }
    case RESERVATIONS_FETCH_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case RESERVATION_EDIT_SUCCEEDED:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === payload.data.id) {
            return payload.data
          }
          return item
        })
      }
    case RESERVATION_BLOCK_SUCCEEDED:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === payload.data.id) {
            return payload.data
          }
          return item
        })
      }
    case RESERVATION_UNBLOCK_SUCCEEDED:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === payload.data.id) {
            return payload.data
          }
          return item
        })
      }
    case RESERVATION_DELETE_SUCCEEDED:
      return {
        ...state,
        list: state.list.filter(item => {
          return item.id !== payload.data.id
        })
      }
    default:
      return state
  }
}
