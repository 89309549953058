export const JOB_ASSIGN_STAFF_FORM_SEARCH_STAFF_REQUESTED =
  'JOB_ASSIGN_STAFF_FORM_SEARCH_STAFF_REQUESTED'

export const JOB_ASSIGN_STAFF_FORM_OPEN_REQUESTED =
  'JOB_ASSIGN_STAFF_FORM_OPEN_REQUESTED'

export const JOB_ASSIGN_STAFF_FORM_CLOSE_REQUESTED =
  'JOB_ASSIGN_STAFF_FORM_CLOSE_REQUESTED'

export const JOB_ASSIGN_STAFF_EDIT_REQUESTED = 'JOB_ASSIGN_STAFF_EDIT_REQUESTED'
export const JOB_ASSIGN_STAFF_EDIT_SUCCEEDED = 'JOB_ASSIGN_STAFF_EDIT_SUCCEEDED'
export const JOB_ASSIGN_STAFF_EDIT_FAILED = 'JOB_ASSIGN_STAFF_EDIT_FAILED'
