import React from 'react'
import { Helmet } from 'react-helmet'
import { makeStyles } from '@material-ui/core'
import TemplateBlank from '@doinn/shared/src/components/templates/TemplateBlank'

const useStyles = makeStyles(theme => ({
  '@global': {
    html: {
      height: '100%'
    },
    body: {
      height: '100%'
    },
    '#root': {
      height: '100%'
    }
  },
  container: {
    height: '100%',
    paddingBottom: theme.spacing(6.5)
  }
}))

const TemplateBlankFullHeight = props => {
  const classes = useStyles()

  const { children } = props

  return (
    <TemplateBlank>
      <Helmet defaultTitle='Doinn' titleTemplate='%s - Doinn'>
        <meta charSet='utf-8' />
      </Helmet>
      <div className={classes.container}>{children}</div>
    </TemplateBlank>
  )
}

export default TemplateBlankFullHeight
