import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getSubscriptionApi } from '@doinn/shared/src/api/subscription'
import {
  SUBSCRIPTION_OVERVIEW_FAILED,
  SUBSCRIPTION_OVERVIEW_REQUESTED,
  SUBSCRIPTION_OVERVIEW_SUCCEEDED
} from '@doinn/shared/src/containers/subscription-billing/subscription/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* getSubscriptionOverview(action) {
  try {
    const { params } = action.payload
    const response = yield call(getSubscriptionApi, params)

    yield put({
      type: SUBSCRIPTION_OVERVIEW_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: SUBSCRIPTION_OVERVIEW_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* subscriptionOverviewSagas() {
  yield all([
    takeLatest(SUBSCRIPTION_OVERVIEW_REQUESTED, getSubscriptionOverview)
  ])
}
