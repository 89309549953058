import React from 'react'
import { compose } from 'redux'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'

import withAuthorization from '@doinn/shared/src/hoc/authorization/withAuthorization'
import Address from '@doinn/shared/src/components/common/Address'
import CheckboxList from '@doinn/shared/src/components/common/filter/CheckboxList'
import AutocompleteMultiple from '@doinn/shared/src/components/common/filter/AutocompleteMultiple'
import CheckboxListNumberOf from '@doinn/shared/src/components/common/filter/CheckboxListNumberOf'

const PropertyOptionRenderer = option => {
  return (
    <Grid container alignItems='center'>
      <Grid item xs>
        <Typography variant='body1'>
          <b>{`${option.name} (${option.code})`}</b>
        </Typography>
        <Typography variant='body2'>
          <Address
            address={option.address}
            complement={option.complement}
            zipcode={option.zipcode}
            city={{
              name: option.name
            }}
            country={{
              name: option.country
            }}
          />
        </Typography>
      </Grid>
    </Grid>
  )
}

const ListFiltersContent = ({
  canAll,
  displayEmployeeEmptyOption,
  isCitiesLoading,
  isPropertyBusinessAccountsLoading,
  isPropertySourcesLoading,
  isPropertyTypesLoading,
  isEmployeesLoading,
  cities,
  hostBusinessAccounts,
  sources,
  types,
  employees,
  filters,
  onChange,
  getAutocompleteOptions
}) => {
  const { t } = useTranslation()

  const employeesOptions = displayEmployeeEmptyOption
    ? [
        {
          id: 'none',
          name: t('No employee')
        },
        ...employees
      ]
    : [...employees]

  const statusOptions = [
    {
      id: 'ready',
      name: t('Ready')
    },
    {
      id: 'error',
      name: t('Issues detected')
    }
  ]

  return (
    <>
      <AutocompleteMultiple
        subheader={t('Properties')}
        filterName='spaces'
        selectedItems={filters.spaces}
        optionRenderer={PropertyOptionRenderer}
        onChange={onChange}
        getAutocompleteOptions={getAutocompleteOptions}
      />
      <CheckboxList
        isLoading={isCitiesLoading}
        subheader={t('Cities')}
        filterName='cities'
        items={cities}
        checkedItems={filters.cities}
        onChange={onChange}
      />
      <CheckboxList
        isLoading={isPropertyTypesLoading}
        subheader={t('Property types')}
        filterName='types'
        items={types}
        checkedItems={filters.types}
        onChange={onChange}
      />
      <CheckboxList
        subheader={t('Status')}
        filterName='status'
        items={statusOptions}
        checkedItems={filters.status}
        onChange={onChange}
      />
      {filters.sources ? (
        <CheckboxList
          isLoading={isPropertySourcesLoading}
          subheader={t('Sources')}
          filterName='sources'
          items={sources}
          checkedItems={filters.sources}
          onChange={onChange}
        />
      ) : null}
      {filters.singleBeds ? (
        <CheckboxListNumberOf
          subheader={t('Single beds')}
          filterName='singleBeds'
          checkedItems={filters.singleBeds}
          onChange={onChange}
        />
      ) : null}
      {filters.doubleBeds ? (
        <CheckboxListNumberOf
          subheader={t('Double beds')}
          filterName='doubleBeds'
          checkedItems={filters.doubleBeds}
          onChange={onChange}
        />
      ) : null}
      {filters.kingSizeBeds ? (
        <CheckboxListNumberOf
          subheader={t('King beds')}
          filterName='kingSizeBeds'
          checkedItems={filters.kingSizeBeds}
          onChange={onChange}
        />
      ) : null}
      {filters.bedrooms ? (
        <CheckboxListNumberOf
          subheader={t('Bedrooms')}
          filterName='bedrooms'
          checkedItems={filters.bedrooms}
          onChange={onChange}
          allowZero
        />
      ) : null}
      {filters.bathrooms ? (
        <CheckboxListNumberOf
          subheader={t('Bathrooms')}
          filterName='bathrooms'
          checkedItems={filters.bathrooms}
          onChange={onChange}
        />
      ) : null}
      {filters.hostBusinessAccounts ? (
        <CheckboxList
          isLoading={isPropertyBusinessAccountsLoading}
          subheader={t('Hosts')}
          filterName='hostBusinessAccounts'
          items={hostBusinessAccounts}
          checkedItems={filters.hostBusinessAccounts}
          onChange={onChange}
        />
      ) : null}
      {canAll(['read-people-staff', 'update-space-staff']) ? (
        <CheckboxList
          isLoading={isEmployeesLoading}
          subheader={t('Employees')}
          filterName='employees'
          items={employeesOptions}
          checkedItems={filters.employees}
          onChange={onChange}
        />
      ) : null}
    </>
  )
}

export default compose(withAuthorization)(ListFiltersContent)
