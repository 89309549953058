import React from 'react'
import { Drawer, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'

import AuthMainMenuContent from '@doinn/shared/src/components/app/AuthMainMenuContent'
import BackgroundImage from '@doinn/shared/src/components/app/imgs/doinn_sidebar_mask.svg'

const useStyles = makeStyles(theme => {
  const drawerWidth = theme.spacing(39)
  const desktopBreakpoint = theme.breakpoints.up('md')

  return {
    drawer: {
      [desktopBreakpoint]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: theme.palette.background.dark,
      backgroundImage: `url(${BackgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'bottom'
    },
    chipNew: {
      background: theme.palette.primary.main,
      marginLeft: theme.spacing(2)
    }
  }
})

const AuthMainMenu = ({
  container,
  isMobileMenuOpened,
  onToggleMainMenu,
  drawerContent
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const items = []
  const isMobile = !useMediaQuery(theme.breakpoints.up('md'))

  const handleToggleMobileDrawer = () => {
    onToggleMainMenu && onToggleMainMenu()
  }

  let drawerProps = {
    classes: {
      paper: classes.drawerPaper
    },
    variant: 'permanent',
    open: true
  }

  const drawerPropsMobile = {
    container: container,
    variant: 'temporary',
    anchor: theme.direction === 'rtl' ? 'right' : 'left',
    open: isMobileMenuOpened,
    onClose: handleToggleMobileDrawer,
    ModalProps: {
      // Improve open performance on mobile
      keepMounted: true
    }
  }

  if (isMobile) {
    drawerProps = {
      ...drawerProps,
      ...drawerPropsMobile
    }
  }

  return (
    <nav className={classes.drawer} aria-label='main menu'>
      <Drawer {...drawerProps}>
        <AuthMainMenuContent items={items} drawerContent={drawerContent} />
      </Drawer>
    </nav>
  )
}

export default AuthMainMenu
