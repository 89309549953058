import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getpaymentMethodsAvailableApi } from '@doinn/shared/src/api/payments'
import {
  PAYMENT_METHODS_AVAILABLE_FETCH_FAILED,
  PAYMENT_METHODS_AVAILABLE_FETCH_REQUESTED,
  PAYMENT_METHODS_AVAILABLE_FETCH_SUCCEEDED
} from '@doinn/shared/src/containers/subscription-billing/payment/list/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchPaymentMethodsAvailable() {
  try {
    const response = yield call(getpaymentMethodsAvailableApi)

    yield put({
      type: PAYMENT_METHODS_AVAILABLE_FETCH_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: PAYMENT_METHODS_AVAILABLE_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* paymentGatewaysSagas() {
  yield all([
    takeLatest(
      PAYMENT_METHODS_AVAILABLE_FETCH_REQUESTED,
      fetchPaymentMethodsAvailable
    )
  ])
}
