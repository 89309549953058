import { map } from 'lodash'
import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function fetchVendorPricesApi(serviceId) {
  const config = {}

  return DoinnApi.get(`/vendor/onboarding/services/${serviceId}/prices`, config)
}

export function saveCityServicePricesApi(serviceId, prices) {
  const config = {}
  const unit = map(prices.unit, unit => ({
    weekDays: unit.weekDays,
    price: unit.price
  }))
  const items = map(prices.items, item => {
    const [serviceItemId, startTime, endTime, weekDays] = item.id.split('-')

    return {
      serviceItemId,
      startTime,
      endTime,
      weekDays,
      price: item.price
    }
  })
  const body = { items, unit }

  return DoinnApi.put(
    `/vendor/onboarding/services/${serviceId}/prices`,
    body,
    config
  )
}
