import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getJobHistoryApi } from '@doinn/shared/src/api/job'
import {
  JOB_HISTORY_GET_FAILED,
  JOB_HISTORY_GET_REQUESTED,
  JOB_HISTORY_GET_SUCCEEDED
} from '@doinn/shared/src/containers/job/history/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* getJobHistory(action) {
  try {
    const { jobId } = action.payload.params
    const response = yield call(getJobHistoryApi, { jobId })
    yield put({
      type: JOB_HISTORY_GET_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: JOB_HISTORY_GET_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* jobHistorySagas() {
  yield all([takeLatest(JOB_HISTORY_GET_REQUESTED, getJobHistory)])
}
