import React, { useCallback } from 'react'
import { compose } from 'redux'
import { useSelector } from 'react-redux'

import useAppDetector from '@doinn/shared/src/hooks/useAppDetector'
import withAuthorization from '@doinn/shared/src/hoc/authorization/withAuthorization'
import DemoDialog from '@doinn/shared/src/containers/onboarding/demo/DemoDialog'
import SurveyDialog from '@doinn/shared/src/containers/onboarding/survey/SurveyDialog'
import SaasAnnouncementDialog from '@doinn/shared/src/containers/onboarding/saas-announcement/SaasAnnouncementDialog'
import NewPasswordDialog from '@doinn/shared/src/containers/new-password/NewPasswordDialog'
import SubscriptionInvalidDialog from '@doinn/shared/src/containers/subscription/invalid/SubscriptionInvalidDialog'

function NestedDialogs({ can }) {
  const isApp = useAppDetector()
  const { isOpened: isNewPasswordDialogOpened } = useSelector(
    state => state.newPassword
  )
  const { isOpened: isSubscriptionInvalidDialogOpened } = useSelector(
    state => state.subscriptionInvalid
  )
  const { isOpened: isOnboardingSurveyDialogOpened } = useSelector(
    state => state.onboardingSurvey
  )
  const { isOpened: isSaasAnnouncementDialogOpened } = useSelector(
    state => state.onboardingSaasAnnouncement
  )
  const { isOpened: isOnboardingDemoDialogOpened } = useSelector(
    state => state.onboardingDemo
  )

  const renderContent = useCallback(() => {
    if (isOnboardingSurveyDialogOpened && !isApp) {
      return <SurveyDialog />
    }

    if (
      can('read-payment-methods') &&
      isSaasAnnouncementDialogOpened &&
      !isApp
    ) {
      return <SaasAnnouncementDialog />
    }

    if (isOnboardingDemoDialogOpened && !isApp) {
      return <DemoDialog />
    }

    if (isSubscriptionInvalidDialogOpened) {
      return <SubscriptionInvalidDialog />
    }

    if (isNewPasswordDialogOpened) {
      return <NewPasswordDialog />
    }

    return null
  }, [
    can,
    isOnboardingSurveyDialogOpened,
    isApp,
    isSaasAnnouncementDialogOpened,
    isOnboardingDemoDialogOpened,
    isSubscriptionInvalidDialogOpened,
    isNewPasswordDialogOpened
  ])

  return <div>{renderContent()}</div>
}

export default compose(withAuthorization)(NestedDialogs)
