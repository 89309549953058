import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function fetchBillingListApi({ params }) {
  const config = {
    params
  }
  return DoinnApi.get(`/host/billings`, config)
}

export function saveBillingApi({ data }) {
  const config = {}

  if (data?.id) {
    return DoinnApi.put(`/host/billings/${data.id}`, data)
  }

  return DoinnApi.post(`/host/billings`, { ...data }, config)
}

export function getBillingApi({ id }) {
  const config = {}
  return DoinnApi.get(`/host/billings/${id}`, config)
}

export function deleteBillingApi({ id }) {
  const config = {}
  return DoinnApi.delete(`/host/billings/${id}`, config)
}
