import { cloneDeep, xor } from 'lodash'

/**
 * @param {Array} array
 * @param {string|number|string[]|number[]} value
 * @returns {Array} newArray
 */
export const addOrRemove = (array = [], value) => {
  const clonedArray = cloneDeep(array)
  if (!value) return clonedArray

  const valuesArray = Array.isArray(value) ? cloneDeep(value) : [value]

  return xor(clonedArray, valuesArray)
}
