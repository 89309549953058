import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchAutomationsFiltersApi } from '@doinn/shared/src/api/automations-filters'
import {
  AUTOMATIONS_FILTERS_FETCH_FAILED,
  AUTOMATIONS_FILTERS_FETCH_REQUESTED,
  AUTOMATIONS_FILTERS_FETCH_SUCCEEDED
} from './constants'

function* fetchAutomationsFilters(action) {
  try {
    const { params } = action.payload
    const response = yield call(fetchAutomationsFiltersApi, params)
    const { data } = response.data
    yield put({
      type: AUTOMATIONS_FILTERS_FETCH_SUCCEEDED,
      payload: { data }
    })
  } catch (e) {
    yield put({ type: AUTOMATIONS_FILTERS_FETCH_FAILED })
  }
}

export default function* automationsFiltersSaga() {
  yield all([
    takeLatest(AUTOMATIONS_FILTERS_FETCH_REQUESTED, fetchAutomationsFilters)
  ])
}
