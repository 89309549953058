import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getBillingApi } from '@doinn/shared/src/api/billing'
import {
  BILLING_GET_FAILED,
  BILLING_GET_REQUESTED,
  BILLING_GET_SUCCEEDED
} from '@doinn/shared/src/containers/subscription-billing/billing/get/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* getBilling(action) {
  try {
    const { id } = action.payload
    const response = yield call(getBillingApi, { id })
    yield put({
      type: BILLING_GET_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: BILLING_GET_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* billingsSaga() {
  yield all([takeLatest(BILLING_GET_REQUESTED, getBilling)])
}
