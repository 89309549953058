import {
  PROPERTY_CLEAR_SELECTED_REQUESTED,
  PROPERTY_GET_FAILED,
  PROPERTY_GET_REQUESTED,
  PROPERTY_GET_SUCCEEDED
} from '@doinn/shared/src/containers/property/get/constants'
import {
  PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_CONFIRM_SUCCEEDED,
  PROPERTY_ASSIGN_STAFF_FORM_TEAM_CONFIRM_SUCCEEDED
} from '@doinn/shared/src/containers/property/assign-staff/constants'
import { PROPERTY_INSTRUCTIONS_EDIT_SUCCEEDED } from '@doinn/shared/src/containers/property/instructions/edit/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  item: null,
  isLoading: false,
  isFailed: false,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case PROPERTY_GET_REQUESTED:
      return {
        ...state,
        ...initialState,
        isLoading: true,
        isFailed: false,
        status: STATUS.LOADING
      }
    case PROPERTY_GET_SUCCEEDED:
      return {
        ...state,
        item: { ...payload.data },
        isLoading: false,
        isFailed: false,
        status: STATUS.SUCCESS
      }
    case PROPERTY_GET_FAILED:
      return {
        ...state,
        isLoading: false,
        isFailed: true,
        status: STATUS.FAILURE
      }
    case PROPERTY_CLEAR_SELECTED_REQUESTED:
      return {
        ...initialState
      }
    case PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_CONFIRM_SUCCEEDED:
      if (!state.item) {
        return state
      }
      return {
        ...state,
        item: {
          ...state.item,
          employees: payload.data.employees,
          team: null
        }
      }
    case PROPERTY_ASSIGN_STAFF_FORM_TEAM_CONFIRM_SUCCEEDED:
      if (!state.item) {
        return state
      }
      return {
        ...state,
        item: {
          ...state.item,
          employees: [],
          team: payload.data.team
        }
      }
    case PROPERTY_INSTRUCTIONS_EDIT_SUCCEEDED:
      if (!state.item) {
        return state
      }
      return {
        ...state,
        item: {
          ...state.item,
          instructions: payload.data.instructions
        }
      }
    default:
      return state
  }
}
