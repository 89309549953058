import {
  CHECKOUT_CONFIRM_CLOSE,
  CHECKOUT_CONFIRM_FAILED,
  CHECKOUT_CONFIRM_REQUESTED,
  CHECKOUT_CONFIRM_SUCCEEDED,
  CHECKOUT_CREATE_FAILED,
  CHECKOUT_CREATE_REQUESTED,
  CHECKOUT_CREATE_SUCCEEDED,
  CHECKOUT_GET_FAILED,
  CHECKOUT_GET_REQUESTED,
  CHECKOUT_GET_SUCCEEDED,
  CHECKOUT_MULTIPLE_CREATE_REQUESTED,
  CHECKOUT_PAY_FAILED,
  CHECKOUT_PAY_REQUESTED,
  CHECKOUT_PAY_SUCCEEDED,
  CHECKOUT_SUBSCRIPTION_CREATE_REQUESTED,
  CHECKOUT_SUBSCRIPTION_REQUESTED
} from '@doinn/vendor/src/containers/checkout/constants'

import { CHECKOUT_REMOVE_SUCCEEDED } from '@doinn/vendor/src/containers/checkout/remove/constants'
import { CHECKOUT_COUPON_SUCCEEDED } from '@doinn/vendor/src/containers/checkout/coupon/constants'
import { CHECKOUT_CREDITS_SUCCEEDED } from '@doinn/vendor/src/containers/checkout/credits/constants'
import { PAYMENT_CONFIRM_ACTION_FAILED } from '@doinn/shared/src/containers/gateways/stripe/confirm-payment/constants'

export const STATUS = {
  IDLE: 'idle',
  FETCHING: 'fetching',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
  CREATED: 'created'
}

export const initialState = {
  status: STATUS.IDLE,
  statusMultiple: STATUS.IDLE,
  data: {
    jobs: []
  }
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case CHECKOUT_CONFIRM_CLOSE:
      return {
        ...initialState
      }
    case CHECKOUT_CREATE_REQUESTED:
      return {
        ...state,
        status: `${STATUS.FETCHING}-${action.payload.params.jobs[0]}`
      }
    case CHECKOUT_MULTIPLE_CREATE_REQUESTED:
      return {
        ...state,
        statusMultiple: STATUS.FETCHING
      }
    case CHECKOUT_SUBSCRIPTION_CREATE_REQUESTED:
      return {
        ...state,
        status: STATUS.FETCHING
      }
    case CHECKOUT_CREATE_SUCCEEDED:
      return {
        ...state,
        status: STATUS.CREATED,
        statusMultiple: STATUS.IDLE,
        data: { ...action.payload }
      }
    case CHECKOUT_CREATE_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE,
        statusMultiple: STATUS.FAILURE
      }
    case CHECKOUT_GET_REQUESTED:
      return {
        ...state,
        status: STATUS.FETCHING
      }
    case CHECKOUT_GET_SUCCEEDED:
      return {
        ...state,
        status: STATUS.IDLE,
        statusMultiple: STATUS.IDLE,
        data: { ...action.payload }
      }
    case CHECKOUT_GET_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE,
        statusMultiple: STATUS.FAILURE
      }
    case CHECKOUT_CONFIRM_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING
      }
    case CHECKOUT_CONFIRM_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS,
        data: { ...action.payload }
      }
    case CHECKOUT_CONFIRM_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE,
        statusMultiple: STATUS.FAILURE
      }
    case CHECKOUT_COUPON_SUCCEEDED:
      return {
        ...state,
        statusCoupon: STATUS.SUCCESS,
        data: { ...action.payload }
      }
    case CHECKOUT_CREDITS_SUCCEEDED:
      return {
        ...state,
        statusCoupon: STATUS.SUCCESS,
        data: { ...action.payload }
      }
    case CHECKOUT_REMOVE_SUCCEEDED:
      return {
        ...state,
        data: { ...action.payload }
      }
    case CHECKOUT_SUBSCRIPTION_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING
      }
    case CHECKOUT_PAY_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING
      }
    case CHECKOUT_PAY_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS,
        data: { ...action.payload }
      }
    case CHECKOUT_PAY_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE,
        statusMultiple: STATUS.FAILURE
      }
    case PAYMENT_CONFIRM_ACTION_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE,
        statusMultiple: STATUS.FAILURE
      }
    default:
      return state
  }
}
