import { all, call, put, takeLatest } from 'redux-saga/effects'
import { deleteJobServiceApi } from 'api/job-services'
import { getJobApi } from 'api/job'
import {
  ORDER_SERVICE_DELETE_FAILED,
  ORDER_SERVICE_DELETE_REQUESTED,
  ORDER_SERVICE_DELETE_SUCCEEDED
} from 'containers/order-services/delete/constants'
import { JOB_GET_SUCCEEDED } from '@doinn/vendor/src/containers/job/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* deleteOrderService(action) {
  try {
    const { job, orderService } = action.payload
    const response = yield call(deleteJobServiceApi, job, orderService)
    const updatedJob = yield call(getJobApi, job.id)
    yield put({
      type: JOB_GET_SUCCEEDED,
      payload: updatedJob.data
    })

    yield put({
      type: ORDER_SERVICE_DELETE_SUCCEEDED,
      payload: {
        orderService: {
          ...orderService,
          vendorJob: response.data.data
        }
      }
    })
  } catch (e) {
    yield put({
      type: ORDER_SERVICE_DELETE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* orderServiceDeleteSaga() {
  yield all([takeLatest(ORDER_SERVICE_DELETE_REQUESTED, deleteOrderService)])
}
