import {
  MEMBER_PASSWORD_EDIT_FAILED,
  MEMBER_PASSWORD_EDIT_REQUESTED,
  MEMBER_PASSWORD_EDIT_SUCCEEDED
} from '@doinn/vendor/src/containers/member/edit-password/constants'

export const STATUS = {
  IDLE: 'idle',
  SAVING: 'saving',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  lastSavedItem: null,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case MEMBER_PASSWORD_EDIT_REQUESTED:
      return {
        ...state,
        lastSavedItem: null,
        status: STATUS.SAVING
      }
    case MEMBER_PASSWORD_EDIT_SUCCEEDED:
      return {
        ...state,
        lastSavedItem: { ...payload },
        status: STATUS.SUCCESS
      }
    case MEMBER_PASSWORD_EDIT_FAILED:
      return {
        ...state,
        lastSavedItem: null,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
