export const JOB_EDIT_ITEMS_REQUEST_DIALOG_OPEN =
  'JOB_EDIT_ITEMS_REQUEST_DIALOG_OPEN'
export const JOB_EDIT_ITEMS_REQUEST_DIALOG_CLOSE =
  'JOB_EDIT_ITEMS_REQUEST_DIALOG_CLOSE'

export const JOB_EDIT_ITEMS_REQUEST_REQUESTED =
  'JOB_EDIT_ITEMS_REQUEST_REQUESTED'
export const JOB_EDIT_ITEMS_REQUEST_SUCCEEDED =
  'JOB_EDIT_ITEMS_REQUEST_SUCCEEDED'
export const JOB_EDIT_ITEMS_REQUEST_FAILED = 'JOB_EDIT_ITEMS_REQUEST_FAILED'
