import React from 'react'
import { useTranslation } from 'react-i18next'
import { Badge, Button, makeStyles } from '@material-ui/core'

import { useHubspotConversations } from '@doinn/vendor/src/containers/support/SupportChat'

const useStyles = makeStyles(theme => {
  return {
    badge: {
      '& > span': {
        transform: 'scale(0.8) translate(80%, -35%)',
        top: 0,
        right: 0
      }
    }
  }
})

const SupportChatButton = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { toggleWidget, unreadMessagesCount, openButtonRef } =
    useHubspotConversations()

  return (
    <Button
      ref={openButtonRef}
      variant='text'
      color='secondary'
      size='small'
      onClick={toggleWidget}
    >
      <Badge
        badgeContent={unreadMessagesCount > 99 ? '99+' : unreadMessagesCount}
        color='primary'
        overlap='circle'
        className={classes.badge}
      >
        {t('Support')}
      </Badge>
    </Button>
  )
}

export default SupportChatButton
