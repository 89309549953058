import {
  NOTIFICATION_EMAIL_CREATE_REQUESTED,
  NOTIFICATION_EMAIL_DELETE_REQUESTED,
  PROFILE_DETAILS_FIELD_UPDATE,
  PROFILE_DETAILS_GET_REQUESTED,
  PROFILE_DETAILS_GET_SUCCEEDED,
  PROFILE_DETAILS_SET_EDITING_SECTION,
  PROFILE_DETAILS_UPDATE_REQUESTED,
  PROFILE_TRANSLATION_LANGUAGE_UPDATE_REQUESTED
} from '@doinn/shared/src/containers/profile/constants'

export function fetchProfileDetails() {
  return { type: PROFILE_DETAILS_GET_REQUESTED }
}

export function setProfileDetails(data) {
  return { type: PROFILE_DETAILS_GET_SUCCEEDED, payload: { data } }
}

export function setProfileDetailsEditingSection(params) {
  return { type: PROFILE_DETAILS_SET_EDITING_SECTION, payload: { params } }
}

export function deleteNotificationEmail(id) {
  return { type: NOTIFICATION_EMAIL_DELETE_REQUESTED, payload: { id } }
}

export function createNotificationEmail(email) {
  return { type: NOTIFICATION_EMAIL_CREATE_REQUESTED, payload: { email } }
}

export function updateProfileField(params) {
  return { type: PROFILE_DETAILS_FIELD_UPDATE, payload: { params } }
}

export function updateProfileDetails(params) {
  return { type: PROFILE_DETAILS_UPDATE_REQUESTED, payload: { params } }
}

export function updateProfileTranslationLanguage(translationLanguage) {
  return {
    type: PROFILE_TRANSLATION_LANGUAGE_UPDATE_REQUESTED,
    payload: { translationLanguage }
  }
}
