import { useEffect, useRef } from 'react'

const useRecaptcha = recaptchaSiteKey => {
  const loadRecaptchaPromise = useRef(null)

  const loadRecaptcha = () => {
    return new Promise(resolve => {
      const script = document.createElement('script')
      script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`
      script.onload = () => {
        resolve()
      }
      document.body.appendChild(script)
    })
  }

  const unloadRecaptcha = async () => {
    if (loadRecaptchaPromise.current) {
      await loadRecaptchaPromise.current
    }

    const nodeBadge = document.querySelector('.grecaptcha-badge')
    if (nodeBadge) {
      nodeBadge.remove()
    }

    const scriptSelector = `script[src='https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}']`
    const script = document.querySelector(scriptSelector)
    if (script) {
      script.remove()
    }
  }

  useEffect(() => {
    return () => {
      unloadRecaptcha()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recaptchaSiteKey])

  return { loadRecaptcha, unloadRecaptcha }
}

export default useRecaptcha
