import {
  INTEGRATIONS_CATALOG_FETCH_FAILED,
  INTEGRATIONS_CATALOG_FETCH_MORE_FAILED,
  INTEGRATIONS_CATALOG_FETCH_MORE_REQUESTED,
  INTEGRATIONS_CATALOG_FETCH_MORE_SUCCEEDED,
  INTEGRATIONS_CATALOG_FETCH_REQUESTED,
  INTEGRATIONS_CATALOG_FETCH_SUCCEEDED
} from '@doinn/host/src/containers/integrations/catalog/list/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  LOADING_MORE: 'loading-more',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  list: [],
  meta: {},
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case INTEGRATIONS_CATALOG_FETCH_REQUESTED:
      return {
        ...state,
        ...initialState,
        status: STATUS.LOADING
      }
    case INTEGRATIONS_CATALOG_FETCH_SUCCEEDED:
      return {
        ...state,
        list: [...payload.data],
        meta: { ...payload.meta },
        status: STATUS.SUCCESS
      }
    case INTEGRATIONS_CATALOG_FETCH_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case INTEGRATIONS_CATALOG_FETCH_MORE_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING_MORE
      }
    case INTEGRATIONS_CATALOG_FETCH_MORE_SUCCEEDED:
      return {
        ...state,
        list: [...state.list, ...payload.data],
        meta: { ...payload.meta },
        status: STATUS.SUCCESS
      }
    case INTEGRATIONS_CATALOG_FETCH_MORE_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
