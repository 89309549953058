import { useReducer } from 'react'

import {
  INITIAL_STATE_CHANGE,
  NEXT_STEP,
  OPTIONS_CHANGE,
  PREVIOUS_STEP
} from './constants'
import { defaultState, reducer } from './reducer'

export const useSaasAnnouncementForm = (initialState = {}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...defaultState,
    initialState
  })

  const onNextStep = () => {
    dispatch({ type: NEXT_STEP })
  }

  const onPreviousStep = () => {
    dispatch({ type: PREVIOUS_STEP })
  }

  const onInitialStateChange = (initialState = {}) => {
    dispatch({ type: INITIAL_STATE_CHANGE, payload: { initialState } })
  }

  const onOptionsChange = ({ options = [], numberOfSteps }) => {
    dispatch({ type: OPTIONS_CHANGE, payload: { options, numberOfSteps } })
  }

  return {
    state,
    onNextStep,
    onPreviousStep,
    onInitialStateChange,
    onOptionsChange
  }
}
