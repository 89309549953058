import { find, get } from 'lodash'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { updateServiceItemsApi } from 'api/job-services'
import { getJobApi } from 'api/job'
import {
  ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_SAVE_FAILED,
  ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_SAVE_REQUESTED,
  ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_SAVE_SUCCEEDED
} from 'containers/order-services/extra-item/edit/constants'
import { JOB_GET_SUCCEEDED } from '@doinn/vendor/src/containers/job/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* updateServiceExtraItems(action) {
  const { job, orderService, items } = action.payload
  try {
    const orderServiceId = orderService.id
    const response = yield call(updateServiceItemsApi, job, orderService, items)
    const updatedJob = yield call(getJobApi, job.id)
    const orderServices = get(response.data, 'data.orderServices', [])
    const orderServiceUpdated =
      find(orderServices, orderService => orderService.id === orderServiceId) ||
      {}
    yield put({
      type: JOB_GET_SUCCEEDED,
      payload: updatedJob.data
    })
    yield put({
      type: ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_SAVE_SUCCEEDED,
      payload: {
        serviceItems: orderServiceUpdated.serviceItems,
        orderServiceId: orderServiceId
      }
    })
  } catch (e) {
    yield put({
      type: ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_SAVE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* watchUpdateServiceExtraItems() {
  yield takeLatest(
    ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_SAVE_REQUESTED,
    updateServiceExtraItems
  )
}

export default function* serviceExtraItemsEditFormSaga() {
  yield all([watchUpdateServiceExtraItems()])
}
