import {
  PROPERTIES_CLEAR,
  PROPERTIES_FETCH_FAILED,
  PROPERTIES_FETCH_MORE_FAILED,
  PROPERTIES_FETCH_MORE_REQUESTED,
  PROPERTIES_FETCH_MORE_SUCCEEDED,
  PROPERTIES_FETCH_REQUESTED,
  PROPERTIES_FETCH_SUCCEEDED
} from '@doinn/shared/src/containers/property/list/constants'
import {
  PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_CONFIRM_SUCCEEDED,
  PROPERTY_ASSIGN_STAFF_FORM_TEAM_CONFIRM_SUCCEEDED
} from '@doinn/shared/src/containers/property/assign-staff/constants'
import { PROPERTY_INSTRUCTIONS_EDIT_SUCCEEDED } from '@doinn/shared/src/containers/property/instructions/edit/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  LOADING_MORE: 'loading-more',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  list: [],
  meta: {},
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case PROPERTIES_FETCH_REQUESTED:
      return {
        ...state,
        ...initialState,
        status: STATUS.LOADING
      }
    case PROPERTIES_FETCH_SUCCEEDED:
      return {
        ...state,
        list: [...payload.data],
        meta: { ...payload.meta },
        status: STATUS.SUCCESS
      }
    case PROPERTIES_FETCH_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case PROPERTIES_FETCH_MORE_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING_MORE
      }
    case PROPERTIES_FETCH_MORE_SUCCEEDED:
      return {
        ...state,
        list: [...state.list, ...payload.data],
        meta: { ...payload.meta },
        status: STATUS.SUCCESS
      }
    case PROPERTIES_FETCH_MORE_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case PROPERTY_ASSIGN_STAFF_FORM_EMPLOYEES_CONFIRM_SUCCEEDED:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === payload.data.propertyId) {
            return {
              ...item,
              employees: payload.data.employees,
              team: null
            }
          }
          return item
        })
      }
    case PROPERTY_ASSIGN_STAFF_FORM_TEAM_CONFIRM_SUCCEEDED:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === payload.data.propertyId) {
            return {
              ...item,
              employees: [],
              team: payload.data.team
            }
          }
          return item
        })
      }
    case PROPERTY_INSTRUCTIONS_EDIT_SUCCEEDED:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === payload.data.propertyId) {
            return {
              ...item,
              instructions: payload.data.instructions
            }
          }
          return item
        })
      }
    case PROPERTIES_CLEAR:
      return {
        ...initialState
      }
    default:
      return state
  }
}
