import { all, call, put, takeLatest } from 'redux-saga/effects'
import { shareCatalogueApi } from '@doinn/shared/src/api/catalogues'
import {
  CATALOGUE_MY_SHARE_FAILED,
  CATALOGUE_MY_SHARE_REQUESTED,
  CATALOGUE_MY_SHARE_SUCCEEDED
} from '@doinn/shared/src/containers/catalogue/my/share/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import i18n from '@doinn/shared/src/config/i18n'

function* shareCatalogue(action) {
  try {
    const { params } = action.payload
    const catalogueId = params.catalogue.id
    const catalogueName = params.catalogue.name
    const invitations = params.invitations
    yield call(shareCatalogueApi, { id: catalogueId, invitations: invitations })
    yield put({
      type: CATALOGUE_MY_SHARE_SUCCEEDED,
      payload: params,
      snackbar: {
        variant: 'success',
        message: i18n.t(`"{{name}}" has been shared!`, { name: catalogueName })
      }
    })
  } catch (e) {
    yield put({
      type: CATALOGUE_MY_SHARE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* cataloguesAddSaga() {
  yield all([takeLatest(CATALOGUE_MY_SHARE_REQUESTED, shareCatalogue)])
}
