import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { updateUserPasswordApi } from '@doinn/shared/src/api/profile'
import {
  NEW_PASSWORD_FAILED,
  NEW_PASSWORD_REQUESTED,
  NEW_PASSWORD_SUCCEEDED
} from '@doinn/shared/src/containers/new-password/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* newPassword(action) {
  try {
    const { params } = action.payload
    const response = yield call(updateUserPasswordApi, params)
    yield put({
      type: NEW_PASSWORD_SUCCEEDED,
      payload: response.data,
      snackbar: {
        variant: 'success',
        message: i18n.t('Password successfully changed.')
      }
    })
  } catch (e) {
    yield put({
      type: NEW_PASSWORD_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* newPasswordSagas() {
  yield all([takeLatest(NEW_PASSWORD_REQUESTED, newPassword)])
}
