import { merge } from 'lodash'

const fontFamilies = {
  main: '"Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif',
  title: '"Segoe UI", "Open Sans", "Helvetica", "Arial", sans-serif'
}

// Main typography settings
const typography = {
  // Tell Material-UI what's the font-size on the html element is.
  htmlFontSize: 10,
  fontFamily: fontFamilies.main,
  // Material UI body2 font size
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightMediumBold: 600,
  fontWeightBold: 700
}

export const withTypographyVariants = theme => {
  return merge(theme, {
    typography: {
      // Setting the type variants
      h1: {
        fontFamily: fontFamilies.title,
        fontWeight: 700,
        fontSize: '5.2rem',
        lineHeight: 1,
        letterSpacing: '0em',
        [theme.breakpoints.up('sm')]: {
          fontSize: '7.2rem'
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '8.4rem'
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '9.4rem'
        }
      },
      h2: {
        fontFamily: fontFamilies.title,
        fontWeight: 700,
        fontSize: '3.1rem',
        lineHeight: 1.1,
        letterSpacing: '-0.01666em', // -0.8px
        [theme.breakpoints.up('sm')]: {
          fontSize: '3.6rem'
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '4.4rem'
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '4.8rem'
        }
      },
      h3: {
        fontFamily: fontFamilies.title,
        fontWeight: 400,
        fontSize: '2.5rem',
        lineHeight: 1.2,
        letterSpacing: '0em',
        [theme.breakpoints.up('sm')]: {
          fontSize: '3.1rem'
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '3.5rem'
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '4rem'
        }
      },
      h4: {
        fontFamily: fontFamilies.title,
        fontWeight: 400,
        fontSize: '2.1rem',
        lineHeight: 1.25,
        // letterSpacing: '0em', // -0.5px
        letterSpacing: '-0.025em', // -0.8px
        [theme.breakpoints.up('sm')]: {
          fontSize: '2.8rem'
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '3.2rem'
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '3.2rem'
        }
      },
      h5: {
        fontFamily: fontFamilies.title,
        fontWeight: 400,
        fontSize: '1.9rem',
        lineHeight: 1.33,
        letterSpacing: '0em',
        [theme.breakpoints.up('sm')]: {
          fontSize: '2.6rem'
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '2.8rem'
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '2.8rem'
        }
      },
      h6: {
        fontFamily: fontFamilies.title,
        fontWeight: 400,
        fontSize: '1.7rem',
        lineHeight: 1.25,
        letterSpacing: '0em',
        [theme.breakpoints.up('sm')]: {
          fontSize: '2rem'
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '2.2rem'
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '2.4rem'
        }
      },
      subtitle1: {
        fontFamily: fontFamilies.main,
        fontWeight: 400,
        fontSize: '1.5rem',
        lineHeight: 1.25,
        letterSpacing: '0em',
        [theme.breakpoints.up('sm')]: {
          fontSize: '1.8rem'
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '1.8rem'
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '2rem'
        }
      },
      subtitle2: {
        fontFamily: fontFamilies.main,
        fontWeight: 400,
        fontSize: '1.4rem',
        lineHeight: 1.25,
        letterSpacing: '0em',
        [theme.breakpoints.up('sm')]: {
          fontSize: '1.6rem'
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '1.8rem'
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '1.8rem'
        }
      },
      body1: {
        fontFamily: fontFamilies.main,
        fontWeight: 400,
        fontSize: '1.3rem',
        lineHeight: 1.5,
        letterSpacing: '0em',
        [theme.breakpoints.up('sm')]: {
          fontSize: '1.4rem'
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '1.6rem'
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '1.6rem'
        }
      },
      body2: {
        fontFamily: fontFamilies.main,
        fontWeight: 400,
        fontSize: '1.2rem',
        lineHeight: 1.43,
        letterSpacing: '0em',
        [theme.breakpoints.up('sm')]: {
          fontSize: '1.4rem'
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '1.4rem'
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '1.4rem'
        }
      },
      button: {
        fontFamily: fontFamilies.main,
        fontWeight: 400,
        fontSize: '1.2rem',
        lineHeight: 1.75,
        letterSpacing: '0em',
        textTransform: 'none',
        [theme.breakpoints.up('sm')]: {
          fontSize: '1.4rem'
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '1.4rem'
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '1.4rem'
        }
      },
      caption: {
        fontFamily: fontFamilies.main,
        fontWeight: 400,
        fontSize: '1.1rem',
        lineHeight: 1.66,
        letterSpacing: '0em',
        [theme.breakpoints.up('sm')]: {
          fontSize: '1.2rem'
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '1.2rem'
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '1.2rem'
        }
      },
      overline: {
        fontFamily: fontFamilies.main,
        fontWeight: 400,
        fontSize: '1.4rem',
        lineHeight: 1.25,
        letterSpacing: '0em',
        textTransform: 'none',
        [theme.breakpoints.up('sm')]: {
          fontSize: '1.6rem'
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '1.6rem'
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: '1.8rem'
        }
      }
    }
  })
}

export default typography
