import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function getImportResultsApi(params = {}) {
  const { flags, importUuid, ...otherParams } = params
  const config = {
    params: otherParams
  }
  const parsedFlags = flags ? `?${flags.join('&')}` : ''
  return DoinnApi.get(`/imports/${importUuid}${parsedFlags}`, config)
}
