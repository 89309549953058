import {
  PRICE_LIST_DOWNLOAD_FAILED,
  PRICE_LIST_DOWNLOAD_REQUESTED,
  PRICE_LIST_DOWNLOAD_SUCCEEDED,
  PRICE_LIST_FETCH_FAILED,
  PRICE_LIST_FETCH_REQUESTED,
  PRICE_LIST_FETCH_SUCCEEDED
} from 'containers/price-list/constants'

export const initialState = {
  data: [],
  meta: {},
  isDownloading: false,
  isLoaded: false,
  isLoading: false,
  isFailed: false
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case PRICE_LIST_FETCH_REQUESTED:
      return {
        ...state,
        ...initialState,
        isLoaded: false,
        isLoading: true,
        isFailed: false
      }
    case PRICE_LIST_FETCH_SUCCEEDED:
      return {
        ...state,
        data: [...payload.data],
        meta: { ...payload.meta },
        isLoaded: true,
        isLoading: false,
        isFailed: false
      }
    case PRICE_LIST_FETCH_FAILED:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        isFailed: true
      }
    case PRICE_LIST_DOWNLOAD_REQUESTED:
      return {
        ...state,
        isDownloading: true
      }
    case PRICE_LIST_DOWNLOAD_SUCCEEDED:
    case PRICE_LIST_DOWNLOAD_FAILED:
      return {
        ...state,
        isDownloading: false
      }
    default:
      return state
  }
}
