import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { editPropertyInstructionsApi } from '@doinn/shared/src/api/property'
import {
  PROPERTY_INSTRUCTIONS_EDIT_FAILED,
  PROPERTY_INSTRUCTIONS_EDIT_REQUESTED,
  PROPERTY_INSTRUCTIONS_EDIT_SUCCEEDED
} from '@doinn/shared/src/containers/property/instructions/edit/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* editPropertyInstructions(action) {
  try {
    const { property } = action.payload
    const response = yield call(editPropertyInstructionsApi, property)
    yield put({
      type: PROPERTY_INSTRUCTIONS_EDIT_SUCCEEDED,
      payload: response.data,
      snackbar: {
        variant: 'success',
        message: i18n.t('The property has been edited successfully')
      }
    })
  } catch (e) {
    yield put({
      type: PROPERTY_INSTRUCTIONS_EDIT_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* propertiesInstructionsEditSaga() {
  yield all([
    takeLatest(PROPERTY_INSTRUCTIONS_EDIT_REQUESTED, editPropertyInstructions)
  ])
}
