import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { cloneDeep, isEqual as isObjEqual } from 'lodash'
import { usePrevious } from '@doinn/shared/src/hooks'
import { Box, Card, Grid, makeStyles } from '@material-ui/core'
import IframeResizer from 'iframe-resizer-react'
import { getDashboardUrl } from '@doinn/vendor/src/api/metabase'
import EmptyState from '@doinn/shared/src/components/common/empty-state/EmptyState'
import FilterDateRange from '@doinn/vendor/src/components/metabase/components/FilterDateRange'

const useStyles = makeStyles(theme => ({
  iframe: {
    border: 'none',
    width: '1px',
    minWidth: '100%',
    backgroundColor: 'transparent'
  }
}))

const MetabaseDashboard = ({
  metabaseUrl,
  dashboardId,
  periodOptions,
  filters,
  onChangeFilters
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(true)
  const [dashboardUrl, setDashboardUrl] = React.useState(null)
  const previousFilters = usePrevious(filters)

  const fetchDashboardUrl = useCallback(
    async filterParams => {
      setLoading(true)

      const queryFilters = {
        date_start: filterParams[dashboardId].start,
        date_end: filterParams[dashboardId].end
      }
      const { data: url } = await getDashboardUrl(dashboardId, {
        params: queryFilters
      })

      setDashboardUrl(url)
      setLoading(false)
    },
    [dashboardId]
  )

  React.useEffect(() => {
    if (dashboardId) {
      fetchDashboardUrl(filters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardId])

  React.useEffect(() => {
    const prev =
      previousFilters && previousFilters[dashboardId]
        ? previousFilters[dashboardId]
        : {}

    const hasFiltersChanged = !isObjEqual(
      {
        ...prev,
        period: ''
      },
      {
        ...filters[dashboardId],
        period: ''
      }
    )

    if (hasFiltersChanged) {
      fetchDashboardUrl(filters)
    }
  }, [filters, fetchDashboardUrl, previousFilters, dashboardId])

  const handleChangeDateRangeFilter = useCallback(
    dateRange => {
      const params = cloneDeep(filters)
      params[dashboardId] = cloneDeep(dateRange)
      onChangeFilters && onChangeFilters(params)
    },
    [filters, onChangeFilters, dashboardId]
  )

  const getContent = useCallback(() => {
    if ((!dashboardUrl && !loading) || !metabaseUrl || !dashboardId) {
      return (
        <EmptyState
          title={t('No results')}
          description={t('There are currently no results to show')}
        />
      )
    }

    if (loading) return null

    return (
      <Card>
        <IframeResizer
          key={dashboardId}
          className={classes.iframe}
          src={dashboardUrl}
          title='Metabase'
          checkOrigin={[metabaseUrl]}
          style={{ height: dashboardId }}
        />
      </Card>
    )
  }, [t, classes.iframe, dashboardId, dashboardUrl, loading, metabaseUrl])

  return (
    <Box pb={4}>
      <Box pb={3}>
        <Grid container alignItems='flex-end' justify='flex-start' spacing={2}>
          <Grid item>
            <FilterDateRange
              filters={filters[dashboardId]}
              periodOptions={periodOptions}
              onChangeFilters={handleChangeDateRangeFilter}
            />
          </Grid>
        </Grid>
      </Box>
      {getContent()}
    </Box>
  )
}

export default MetabaseDashboard
