import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function deleteJobServiceApi(job, jobService) {
  const config = {}
  return DoinnApi.delete(
    `/vendor/jobs/${job.id}/job-services/${jobService.id}`,
    config
  )
}

export function fetchJobServiceExtraItemsApi(job, jobService) {
  const config = {}
  return DoinnApi.get(
    `/vendor/jobs/${job.id}/job-services/${jobService.id}/items`,
    config
  )
}

export function updateServiceItemsApi(job, jobService, items) {
  const config = {}
  const params = { extras: items }
  return DoinnApi.put(
    `/vendor/jobs/${job.id}/job-services/${jobService.id}/items`,
    params,
    config
  )
}

export function fetchJobServiceDetailsApi(job, jobService) {
  const config = {}
  return DoinnApi.get(`/jobs/${job.id}/job-services/${jobService.id}`, config)
}
