export const PROFILE_DETAILS_FIELD_UPDATE = 'PROFILE_DETAILS_FIELD_UPDATE'

export const PROFILE_DETAILS_SET_EDITING_SECTION =
  'PROFILE_DETAILS_SET_EDITING_SECTION'

export const PROFILE_DETAILS_GET_REQUESTED = 'PROFILE_DETAILS_GET_REQUESTED'
export const PROFILE_DETAILS_GET_SUCCEEDED = 'PROFILE_DETAILS_GET_SUCCEEDED'
export const PROFILE_DETAILS_GET_FAILED = 'PROFILE_DETAILS_GET_FAILED'

export const PROFILE_DETAILS_UPDATE_REQUESTED =
  'PROFILE_DETAILS_UPDATE_REQUESTED'
export const PROFILE_DETAILS_UPDATE_SUCCEEDED =
  'PROFILE_DETAILS_UPDATE_SUCCEEDED'
export const PROFILE_DETAILS_UPDATE_FAILED = 'PROFILE_DETAILS_UPDATE_FAILED'

export const NOTIFICATION_EMAIL_CREATE_REQUESTED =
  'NOTIFICATION_EMAIL_CREATE_REQUESTED'
export const NOTIFICATION_EMAIL_CREATE_FAILED =
  'NOTIFICATION_EMAIL_CREATE_FAILED'
export const NOTIFICATION_EMAIL_CREATE_SUCCEEDED =
  'NOTIFICATION_EMAIL_CREATE_SUCCEEDED'

export const NOTIFICATION_EMAIL_DELETE_REQUESTED =
  'NOTIFICATION_EMAIL_DELETE_REQUESTED'
export const NOTIFICATION_EMAIL_DELETE_SUCCEEDED =
  'NOTIFICATION_EMAIL_DELETE_SUCCEEDED'
export const NOTIFICATION_EMAIL_DELETE_FAILED =
  'NOTIFICATION_EMAIL_DELETE_FAILED'

export const PROFILE_TRANSLATION_LANGUAGE_UPDATE_REQUESTED =
  'PROFILE_TRANSLATION_LANGUAGE_UPDATE_REQUESTED'
export const PROFILE_TRANSLATION_LANGUAGE_UPDATE_SUCCEEDED =
  'PROFILE_TRANSLATION_LANGUAGE_UPDATE_SUCCEEDED'
export const PROFILE_TRANSLATION_LANGUAGE_UPDATE_FAILED =
  'PROFILE_TRANSLATION_LANGUAGE_UPDATE_FAILED'
