import React, { useCallback } from 'react'
import { Formik } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  makeStyles
} from '@material-ui/core'

import styles from '@doinn/shared/src/containers/auth/components/styles'
import { loginValidationSchema } from '@doinn/shared/src/containers/auth/validation'
import Icon from '@doinn/shared/src/components/common/Icon'
import TextField from '@doinn/shared/src/components/common/inputs/TextField'
import ButtonWithLoading from '@doinn/shared/src/components/common/ButtonWithLoading'
import PasswordTextField from '@doinn/shared/src/components/common/inputs/PasswordTextField'

const useStyles = makeStyles(styles)

const LoginForm = ({ error, isLoading, onSubmit }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleFormSubmit = useCallback(
    values => onSubmit(values.email, values.password),
    [onSubmit]
  )

  return (
    <div>
      <Box textAlign='center' pt={3.75}>
        <Typography component='div' className={classes.title}>
          <b>{t('Welcome back!')}</b>
        </Typography>
        <Box pt={2} pb={5}>
          <Typography component='div' variant='body1'>
            <Trans
              defaults={t(
                'Don’t have an account yet? <button>Sign up here</button>'
              )}
              components={{
                button: (
                  <Button
                    component={RouterLink}
                    className={classes.link}
                    color='primary'
                    variant='text'
                    to='/registration'
                  />
                )
              }}
            />
          </Typography>
        </Box>
      </Box>
      <Formik
        initialValues={{ email: '', password: '', rememberMe: false }}
        validationSchema={loginValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {props => {
          const { handleSubmit, values, setFieldValue, isValid } = props

          return (
            <form noValidate autoComplete='off' onSubmit={handleSubmit}>
              <Box mb={3}>
                <Typography component='div' variant='body1'>
                  <Box pb={1} sx={{ lineHeight: 'normal' }}>
                    {t('Email address')}
                  </Box>
                </Typography>
                <TextField
                  type='email'
                  disabled={isLoading}
                  name='email'
                  hiddenLabel
                  fullWidth
                  className={classes.input}
                  margin='none'
                />
                <Box pt={2}>
                  <Typography component='div' variant='body1'>
                    <Box pb={1} lineHeight='normal'>
                      {t('Password')}
                    </Box>
                  </Typography>
                  <PasswordTextField
                    fieldName={'password'}
                    isDisabled={isLoading}
                  />
                </Box>
              </Box>
              {error && (
                <Box mb={3}>
                  <Typography className={classes.error} variant='body2'>
                    {error}
                  </Typography>
                </Box>
              )}
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      className={classes.checkboxButton}
                      name='values'
                      checked={values.rememberMe}
                      onChange={() =>
                        setFieldValue('rememberMe', !values.rememberMe)
                      }
                    />
                  }
                  label={t('Remember me')}
                />
                <Button
                  color='primary'
                  variant='text'
                  component={RouterLink}
                  to='/recover-password'
                >
                  {t('Forgot your password?')}
                </Button>
              </Box>
              <Box textAlign='center' pt={6}>
                <ButtonWithLoading
                  disabled={isLoading || !isValid}
                  isLoading={isLoading}
                  type='submit'
                  variant='contained'
                  endIcon={<Icon icon='arrow_right' />}
                >
                  {t('Sign In')}
                </ButtonWithLoading>
              </Box>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default LoginForm
