import {
  CATALOGUE_MY_EDIT_PRICES_FAILED,
  CATALOGUE_MY_EDIT_PRICES_REQUESTED,
  CATALOGUE_MY_EDIT_PRICES_SUCCEEDED
} from '@doinn/shared/src/containers/catalogue/my/edit-prices/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case CATALOGUE_MY_EDIT_PRICES_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING
      }
    case CATALOGUE_MY_EDIT_PRICES_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS
      }
    case CATALOGUE_MY_EDIT_PRICES_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
