import React from 'react'
import ListFiltersDateRange from 'containers/reports/billing/ListFiltersDateRange'
import ListFiltersSourceBy from 'containers/reports/billing/ListFiltersSourceBy'
import ListFiltersGroupBy from 'containers/reports/billing/ListFiltersGroupBy'

const ListFilters = ({ filters, businessIntentions, onChangeFilters }) => {
  return (
    <React.Fragment>
      <ListFiltersSourceBy
        variant='list'
        filters={filters}
        businessIntentions={businessIntentions}
        onChangeFilters={onChangeFilters}
      />
      <ListFiltersDateRange
        variant='list'
        filters={filters}
        onChangeFilters={onChangeFilters}
      />
      <ListFiltersGroupBy
        variant='list'
        filters={filters}
        onChangeFilters={onChangeFilters}
      />
    </React.Fragment>
  )
}

export default ListFilters
