import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function fetchCalendarApi(from, to) {
  const config = {}
  return DoinnApi.get(`/vendor/reports/calendar/${from}/${to}`, config)
}

export function fetchDayActivitiesApi(day) {
  const config = {}
  return DoinnApi.get(`/vendor/reports/activities/${day}`, config)
}
