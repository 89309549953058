export const REPORTS_BILLING_FETCH_REQUESTED = 'REPORTS_BILLING_FETCH_REQUESTED'
export const REPORTS_BILLING_FETCH_SUCCEEDED = 'REPORTS_BILLING_FETCH_SUCCEEDED'
export const REPORTS_BILLING_FETCH_FAILED = 'REPORTS_BILLING_FETCH_FAILED'

export const REPORTS_BILLING_EXPORT_EMAIL_REQUESTED =
  'REPORTS_BILLING_EXPORT_EMAIL_REQUESTED'
export const REPORTS_BILLING_EXPORT_EMAIL_SUCCEEDED =
  'REPORTS_BILLING_EXPORT_EMAIL_SUCCEEDED'
export const REPORTS_BILLING_EXPORT_EMAIL_FAILED =
  'REPORTS_BILLING_EXPORT_EMAIL_FAILED'

export const REPORTS_BILLING_DOWNLOAD_REQUESTED =
  'REPORTS_BILLING_DOWNLOAD_REQUESTED'
export const REPORTS_BILLING_DOWNLOAD_SUCCEEDED =
  'REPORTS_BILLING_DOWNLOAD_SUCCEEDED'
export const REPORTS_BILLING_DOWNLOAD_FAILED = 'REPORTS_BILLING_DOWNLOAD_FAILED'
