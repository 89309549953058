import { all, call, put, takeLatest } from 'redux-saga/effects'
import { syncPropertiesApi } from '@doinn/shared/src/api/properties'
import {
  PROPERTIES_SYNC_FAILED,
  PROPERTIES_SYNC_REQUESTED,
  PROPERTIES_SYNC_SUCCEEDED
} from '@doinn/shared/src/containers/property/sync/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* syncProperties(action) {
  try {
    const response = yield call(syncPropertiesApi)
    const { data } = response.data

    yield put({
      type: PROPERTIES_SYNC_SUCCEEDED,
      payload: data
    })
  } catch (e) {
    yield put({
      type: PROPERTIES_SYNC_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* propertiesSyncSagas() {
  yield all([takeLatest(PROPERTIES_SYNC_REQUESTED, syncProperties)])
}
