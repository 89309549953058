import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchJobExtraServicesApi } from 'api/job'
import {
  JOB_EXTRA_SERVICES_FETCH_FAILED,
  JOB_EXTRA_SERVICES_FETCH_REQUESTED,
  JOB_EXTRA_SERVICES_FETCH_SUCCEEDED
} from 'containers/job/extra-service/list/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchJobExtraServices(action) {
  const { jobId } = action.payload.params
  try {
    const response = yield call(fetchJobExtraServicesApi, jobId)
    yield put({
      type: JOB_EXTRA_SERVICES_FETCH_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: JOB_EXTRA_SERVICES_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* jobExtraServicesSaga() {
  yield all([
    takeLatest(JOB_EXTRA_SERVICES_FETCH_REQUESTED, fetchJobExtraServices)
  ])
}
