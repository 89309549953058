import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getCustomerApi } from '@doinn/vendor/src/api/customer'
import {
  CUSTOMER_GET_FAILED,
  CUSTOMER_GET_REQUESTED,
  CUSTOMER_GET_SUCCEEDED
} from '@doinn/vendor/src/containers/customer/get/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* getCustomer(action) {
  try {
    const response = yield call(getCustomerApi, action.payload.params)
    yield put({
      type: CUSTOMER_GET_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: CUSTOMER_GET_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* customerGetSaga() {
  yield all([takeLatest(CUSTOMER_GET_REQUESTED, getCustomer)])
}
