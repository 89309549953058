import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Collapse, Popover } from '@material-ui/core'
import { find } from 'lodash'
import { formatDate } from '@doinn/shared/src/util/date-fns'
import DateRangeTextField from '@doinn/shared/src/components/common/filter/DateRangeTextField'
import RadioList from '@doinn/shared/src/components/common/filter/RadioList'
import Icon from '@doinn/shared/src/components/common/Icon'

const DateRangeSelect = ({
  disabled,
  periodOptions,
  selectedPeriod,
  start,
  end,
  onChange,
  isRange = true
}) => {
  const { t } = useTranslation()

  const isCustomPeriod = selectedPeriod === 'custom'

  const selectedPeriodData = find(periodOptions, {
    id: selectedPeriod
  }) || {
    name: t('Period')
  }

  const formattedStart = formatDate(start, 'short')
  const formattedEnd = formatDate(end, 'short')
  const formattedPeriodDate = isRange
    ? `${formattedStart} - ${formattedEnd}`
    : formattedStart
  const selectedPeriodButtonLabel = isCustomPeriod
    ? formattedPeriodDate
    : selectedPeriodData.name

  const popoverPosition = {
    vertical: 'top',
    horizontal: 'left'
  }

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [isDatePickerOpened, setIsDatePickerOpened] = React.useState(false)

  const isMenuOpened = Boolean(anchorEl)

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  const handleOpenDatePicker = useCallback(() => {
    setIsDatePickerOpened(true)
    // Can't close the menu there, since the dialog is inside the menu
  }, [setIsDatePickerOpened])

  const handleCloseDatePicker = useCallback(() => {
    setIsDatePickerOpened(false)
  }, [setIsDatePickerOpened])

  const handleOnChangePeriod = useCallback(
    (filterName, itemId) => {
      onChange && onChange(filterName, itemId)
      if (filterName === 'range') {
        handleCloseMenu()
      }
      if (filterName === 'period' && itemId !== 'custom') {
        handleCloseMenu()
      }
    },
    [onChange, handleCloseMenu]
  )

  return (
    <div>
      <Button
        disabled={disabled}
        variant='outlined'
        color='secondary'
        size='small'
        endIcon={<Icon icon='chevron_down' />}
        onClick={handleOpenMenu}
      >
        <b>{selectedPeriodButtonLabel}</b>
      </Button>
      <Popover
        open={isMenuOpened}
        anchorEl={anchorEl}
        anchorOrigin={popoverPosition}
        transformOrigin={popoverPosition}
        onClose={handleCloseMenu}
      >
        <RadioList
          filterName='period'
          items={periodOptions}
          checkedItems={selectedPeriod}
          isMenu
          onChange={handleOnChangePeriod}
        />
        <div>
          <Collapse in={isCustomPeriod}>
            <DateRangeTextField
              isRange={isRange}
              isOpened={isDatePickerOpened}
              startValue={start}
              endValue={end}
              onChange={handleOnChangePeriod}
              onOpen={handleOpenDatePicker}
              onClose={handleCloseDatePicker}
            />
          </Collapse>
        </div>
      </Popover>
    </div>
  )
}

export default DateRangeSelect
