import { all, call, put, takeLatest } from 'redux-saga/effects'
import i18n from '@doinn/shared/src/config/i18n'
import { fetchJobsApi, fetchJobsReportUrlApi } from 'api/jobs'
import { showSnackbar } from '@doinn/shared/src/containers/snackbar/actions'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import {
  JOBS_FETCH_FAILED,
  JOBS_FETCH_MORE_FAILED,
  JOBS_FETCH_MORE_REQUESTED,
  JOBS_FETCH_MORE_SUCCEEDED,
  JOBS_FETCH_REPORT_URL_FAILED,
  JOBS_FETCH_REPORT_URL_REQUESTED,
  JOBS_FETCH_REPORT_URL_SUCCEEDED,
  JOBS_FETCH_REQUESTED,
  JOBS_FETCH_SUCCEEDED
} from 'containers/jobs/constants'

function* fetchJobs(action) {
  try {
    const response = yield call(fetchJobsApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: JOBS_FETCH_SUCCEEDED,
      payload: { data, meta }
    })
  } catch (e) {
    yield put({
      type: JOBS_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* fetchMoreJobs(action) {
  try {
    const response = yield call(fetchJobsApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: JOBS_FETCH_MORE_SUCCEEDED,
      payload: { data, meta }
    })
  } catch (e) {
    yield put({
      type: JOBS_FETCH_MORE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* fetchJobsReportUrl(action) {
  try {
    yield put(
      showSnackbar({
        message: i18n.t(
          'The report is being generated. It might take a few seconds.'
        )
      })
    )

    const response = yield call(fetchJobsReportUrlApi, action.payload.params)

    yield put({
      type: JOBS_FETCH_REPORT_URL_SUCCEEDED,
      payload: response.data,
      externalResource: { url: response.data.data.url }
    })

    yield put(
      showSnackbar({
        message: i18n.t('The report has been successfully generated.'),
        variant: 'success'
      })
    )
  } catch (e) {
    yield put({
      type: JOBS_FETCH_REPORT_URL_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* jobsSaga() {
  yield all([
    takeLatest(JOBS_FETCH_REQUESTED, fetchJobs),
    takeLatest(JOBS_FETCH_MORE_REQUESTED, fetchMoreJobs),
    takeLatest(JOBS_FETCH_REPORT_URL_REQUESTED, fetchJobsReportUrl)
  ])
}
