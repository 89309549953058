import React from 'react'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Link, Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { removeLoggedUserCookie } from '@doinn/shared/src/containers/user/util'

const Warning = ({ className, url, user, userLoggedInAs }) => {
  const { t } = useTranslation()

  if (isEmpty(user) || isEmpty(userLoggedInAs)) {
    return null
  }

  const redirect = () => {
    removeLoggedUserCookie()
    window.localStorage.clear()
    window.sessionStorage.clear()
  }

  return (
    <Alert elevation={10} severity='warning' className={className}>
      <Typography>
        {t('Attention! You are logged as {{name}}.', {
          name: user.name
        })}{' '}
        {
          <Link href={url} onClick={redirect}>
            {t('Go back to {{name}}.', {
              name: userLoggedInAs.name
            })}
          </Link>
        }
      </Typography>
    </Alert>
  )
}

export default Warning
