// @flow
import React from 'react'
import randomColor from 'randomcolor'
import {
  Avatar as AvatarMaterialUI,
  makeStyles,
  useTheme
} from '@material-ui/core'
// import { darken } from '@material-ui/core/styles'
import Icon from '@doinn/shared/src/components/common/Icon'

const mergeClassNames = names => names.join(' ')

const useStyles = makeStyles(theme => ({
  avatar: props => ({
    backgroundColor: props.backgroundColor,
    color: theme.palette.common.white // darken(props.backgroundColor, theme.palette.tonalOffset * 2)
  })
}))

export type Props = {
  // prettier-ignore
  hue?: string
    | 'random'
    | 'red'
    | 'orange'
    | 'yellow'
    | 'green'
    | 'blue'
    | 'purple'
    | 'pink'
    | 'monochrome',
  luminosity?: 'random' | 'bright' | 'light' | 'dark',
  count?: number,
  seed?: number | string,
  format?: 'hex' | 'rgb' | 'rgba' | 'rgbArray' | 'hsl' | 'hsla' | 'hslArray',
  alpha?: number,
  image?: string,
  icon?: string
}

const Avatar = (props: Props) => {
  const {
    hue,
    luminosity,
    count,
    seed,
    format,
    alpha,
    image,
    icon,
    className,
    ...avatarProps
  } = props
  const theme = useTheme()
  const colorProps = {
    hue: theme.palette.primary.main,
    luminosity: 'light',
    count,
    seed,
    format,
    alpha
  }
  const backgroundColor = randomColor(colorProps)
  const classes = useStyles({
    backgroundColor
  })

  const renderAvatarChildren = props => {
    if (props.image) return null
    if (props.icon) {
      return <Icon icon={props.icon} />
    }
    return props.children
  }

  return (
    <AvatarMaterialUI
      component={'div'}
      className={mergeClassNames([className, classes.avatar])}
      src={image}
      {...avatarProps}
    >
      {renderAvatarChildren(props)}
    </AvatarMaterialUI>
  )
}

Avatar.muiName = 'Avatar'

Avatar.defaultProps = {
  luminosity: 'light'
}

export default Avatar
