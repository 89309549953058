import React from 'react'
import { Helmet } from 'react-helmet'
import { BASE_SITE_URL } from 'config/env'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'

import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import TemplateDefault from 'components/TemplateDefault'

const UserProfileContainer = loadable(() =>
  lazyRetry(() => import('@doinn/shared/src/containers/profile/UserProfile'))
)

const UserProfile = () => {
  const { t } = useTranslation()

  return (
    <TemplateDefault title={t('Profile')}>
      <Helmet>
        <title>{t('Profile')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <UserProfileContainer baseSiteUrl={BASE_SITE_URL} />
      </React.Suspense>
    </TemplateDefault>
  )
}

export default UserProfile
