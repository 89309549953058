import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchIntegrationsApi } from '@doinn/host/src/api/integrations'
import {
  INTEGRATIONS_FETCH_FAILED,
  INTEGRATIONS_FETCH_MORE_FAILED,
  INTEGRATIONS_FETCH_MORE_REQUESTED,
  INTEGRATIONS_FETCH_MORE_SUCCEEDED,
  INTEGRATIONS_FETCH_REQUESTED,
  INTEGRATIONS_FETCH_SUCCEEDED
} from '@doinn/host/src/containers/integrations/list/constants'
import { INTEGRATIONS_ACTIVATE_SUCCEEDED } from '@doinn/host/src/containers/integrations/list/activate/constants'
import { INTEGRATIONS_DEACTIVATE_SUCCEEDED } from '@doinn/host/src/containers/integrations/list/deactivate/constants'
import { INTEGRATIONS_RENAME_SUCCEEDED } from '@doinn/host/src/containers/integrations/edit/rename/constants'
import { INTEGRATIONS_DELETE_SUCCEEDED } from '@doinn/host/src/containers/integrations/list/delete/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchIntegrations(action) {
  try {
    const response = yield call(fetchIntegrationsApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: INTEGRATIONS_FETCH_SUCCEEDED,
      payload: {
        data,
        meta
      }
    })
  } catch (e) {
    yield put({
      type: INTEGRATIONS_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* fetchMoreIntegrations(action) {
  try {
    const response = yield call(fetchIntegrationsApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: INTEGRATIONS_FETCH_MORE_SUCCEEDED,
      payload: { data, meta }
    })
  } catch (e) {
    yield put({
      type: INTEGRATIONS_FETCH_MORE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* integrationsSaga() {
  yield all([
    takeLatest(INTEGRATIONS_ACTIVATE_SUCCEEDED, fetchIntegrations),
    takeLatest(INTEGRATIONS_DEACTIVATE_SUCCEEDED, fetchIntegrations),
    takeLatest(INTEGRATIONS_DELETE_SUCCEEDED, fetchIntegrations),
    takeLatest(INTEGRATIONS_RENAME_SUCCEEDED, fetchIntegrations),
    takeLatest(INTEGRATIONS_FETCH_REQUESTED, fetchIntegrations),
    takeLatest(INTEGRATIONS_FETCH_MORE_REQUESTED, fetchMoreIntegrations)
  ])
}
