export const CUSTOMERS_FETCH_REQUESTED = 'CUSTOMERS_FETCH_REQUESTED'
export const CUSTOMERS_FETCH_SUCCEEDED = 'CUSTOMERS_FETCH_SUCCEEDED'
export const CUSTOMERS_FETCH_FAILED = 'CUSTOMERS_FETCH_FAILED'

export const CUSTOMERS_SHARE_CATALOGUE_FETCH_REQUESTED =
  'CUSTOMERS_SHARE_CATALOGUE_FETCH_REQUESTED'
export const CUSTOMERS_SHARE_CATALOGUE_FETCH_SUCCEEDED =
  'CUSTOMERS_SHARE_CATALOGUE_FETCH_SUCCEEDED'
export const CUSTOMERS_SHARE_CATALOGUE_FETCH_FAILED =
  'CUSTOMERS_SHARE_CATALOGUE_FETCH_FAILED'
