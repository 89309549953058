import {
  CATALOGUE_LIST_MY_FETCH_MORE_REQUESTED,
  CATALOGUE_LIST_MY_FETCH_REQUESTED
} from '@doinn/shared/src/containers/catalogue/my/list/constants'

export function fetchCatalogues(params = {}) {
  return { type: CATALOGUE_LIST_MY_FETCH_REQUESTED, payload: { params } }
}

export function fetchMoreCatalogues(params = {}) {
  return {
    type: CATALOGUE_LIST_MY_FETCH_MORE_REQUESTED,
    payload: { params }
  }
}
