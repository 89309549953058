import {
  PAYMENT_CONFIRM_ACTION_FAILED,
  PAYMENT_CONFIRM_ACTION_REQUESTED
} from '@doinn/shared/src/containers/gateways/stripe/confirm-payment/constants'
import {
  CHECKOUT_PAY_FAILED,
  CHECKOUT_PAY_REQUESTED,
  CHECKOUT_PAY_SUCCEEDED
} from '@doinn/vendor/src/containers/checkout/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  status: STATUS.IDLE,
  clientSecret: null,
  gateway: null,
  params: null
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case CHECKOUT_PAY_REQUESTED:
      return {
        ...initialState
      }
    case CHECKOUT_PAY_SUCCEEDED:
      return {
        ...initialState
      }
    case CHECKOUT_PAY_FAILED:
      return {
        ...initialState
      }
    case PAYMENT_CONFIRM_ACTION_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING,
        clientSecret: payload?.clientSecret,
        gateway: payload?.gateway,
        params: payload?.params
      }
    case PAYMENT_CONFIRM_ACTION_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
