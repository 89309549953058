import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function getPhrasesApi() {
  const config = {}
  return DoinnApi.get(`/evaluations/phrases`, config)
}

export function reportProblemApi(params) {
  const config = {}
  const data = { ...params }
  return DoinnApi.post(`/evaluations`, data, config)
}

export function getReportedProblemsApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/evaluations`, config)
}
