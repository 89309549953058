export const PROPERTY_INSTRUCTIONS_FETCH_REQUESTED =
  'PROPERTY_INSTRUCTIONS_FETCH_REQUESTED'
export const PROPERTY_INSTRUCTIONS_FETCH_SUCCEEDED =
  'PROPERTY_INSTRUCTIONS_FETCH_SUCCEEDED'
export const PROPERTY_INSTRUCTIONS_FETCH_FAILED =
  'PROPERTY_INSTRUCTIONS_FETCH_FAILED'
export const PROPERTIES_INSTRUCTIONS_DOWNLOAD_FAILED =
  'PROPERTIES_INSTRUCTIONS_DOWNLOAD_FAILED'
export const PROPERTIES_INSTRUCTIONS_DOWNLOAD_REQUESTED =
  'PROPERTIES_INSTRUCTIONS_DOWNLOAD_REQUESTED'
export const PROPERTIES_INSTRUCTIONS_DOWNLOAD_SUCCEEDED =
  'PROPERTIES_INSTRUCTIONS_DOWNLOAD_SUCCEEDED'
