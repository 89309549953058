export const MEMBERS_INVITATIONS_ADD_DIALOG_OPEN =
  'MEMBERS_INVITATIONS_ADD_DIALOG_OPEN'
export const MEMBERS_INVITATIONS_ADD_DIALOG_CLOSE =
  'MEMBERS_INVITATIONS_ADD_DIALOG_CLOSE'

export const MEMBERS_INVITATIONS_ADD_REQUESTED =
  'MEMBERS_INVITATIONS_ADD_REQUESTED'
export const MEMBERS_INVITATIONS_ADD_SUCCEEDED =
  'MEMBERS_INVITATIONS_ADD_SUCCEEDED'
export const MEMBERS_INVITATIONS_ADD_FAILED = 'MEMBERS_INVITATIONS_ADD_FAILED'

export const MEMBERS_ROLE_OPTIONS_REQUESTED = 'MEMBERS_ROLE_OPTIONS_REQUESTED'
export const MEMBERS_ROLE_OPTIONS_SUCCEEDED = 'MEMBERS_ROLE_OPTIONS_SUCCEEDED'
export const MEMBERS_ROLE_OPTIONS_FAILED = 'MEMBERS_ROLE_OPTIONS_FAILED'
