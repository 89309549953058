import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { editPropertyApi } from '@doinn/shared/src/api/property'
import {
  PROPERTY_EDIT_FAILED,
  PROPERTY_EDIT_REQUESTED,
  PROPERTY_EDIT_SUCCEEDED
} from '@doinn/shared/src/containers/property/edit/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* editProperty(action) {
  try {
    const { property } = action.payload
    yield call(editPropertyApi, property)
    yield put({
      type: PROPERTY_EDIT_SUCCEEDED,
      payload: property,
      snackbar: {
        variant: 'success',
        message: i18n.t('The property has been edited successfully')
      }
    })
  } catch (e) {
    yield put({
      type: PROPERTY_EDIT_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* propertiesEditSaga() {
  yield all([takeLatest(PROPERTY_EDIT_REQUESTED, editProperty)])
}
