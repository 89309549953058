/* eslint-disable no-template-curly-in-string */
import { object, string } from 'yup'
import i18n from '@doinn/shared/src/config/i18n'

export const MIN_LENGTH_PASSWORD = 8

const isConfirmPasswordValid = (confirmPassword, context) =>
  confirmPassword === context?.parent?.password

const getNewPasswordSchema = () =>
  object({
    password: string()
      .required(i18n.t('Required'))
      .min(MIN_LENGTH_PASSWORD, i18n.t('Must have ${min} characters or more')),
    confirmPassword: string()
      .required(i18n.t('Required'))
      .test(
        'is-confirm-password-valid',
        i18n.t('Both passwords has to match'),
        isConfirmPasswordValid
      )
  })

let newPasswordSchema = getNewPasswordSchema()

i18n.on('languageChanged init', () => {
  newPasswordSchema = getNewPasswordSchema()
})

export { newPasswordSchema }
