import {
  PAYMENT_METHODS_DEFAULT_UPDATE_FAILED,
  PAYMENT_METHODS_DEFAULT_UPDATE_REQUESTED,
  PAYMENT_METHODS_DEFAULT_UPDATE_SUCCEEDED
} from '@doinn/shared/src/containers/subscription-billing/payment/default/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  status: STATUS.IDLE,
  id: null
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case PAYMENT_METHODS_DEFAULT_UPDATE_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING,
        id: payload.params.id
      }
    case PAYMENT_METHODS_DEFAULT_UPDATE_SUCCEEDED:
      return {
        ...initialState,
        status: STATUS.SUCCESS
      }
    case PAYMENT_METHODS_DEFAULT_UPDATE_FAILED:
      return {
        ...initialState,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
