import { get } from 'lodash'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchProvidersApi } from '@doinn/vendor/src/api/provider'
import {
  PROVIDERS_FETCH_FAILED,
  PROVIDERS_FETCH_REQUESTED,
  PROVIDERS_FETCH_SUCCEEDED
} from '@doinn/vendor/src/containers/provider/list/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchProviders(action) {
  try {
    const params = get(action, 'payload.params', {})
    const response = yield call(fetchProvidersApi, params)
    yield put({
      type: PROVIDERS_FETCH_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: PROVIDERS_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* providersSaga() {
  yield all([takeLatest(PROVIDERS_FETCH_REQUESTED, fetchProviders)])
}
