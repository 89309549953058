import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchCatalogsApi } from '@doinn/host/src/api/catalogs'
import {
  CATALOG_LIST_FETCH_FAILED,
  CATALOG_LIST_FETCH_MORE_FAILED,
  CATALOG_LIST_FETCH_MORE_REQUESTED,
  CATALOG_LIST_FETCH_MORE_SUCCEEDED,
  CATALOG_LIST_FETCH_REQUESTED,
  CATALOG_LIST_FETCH_SUCCEEDED
} from '@doinn/host/src/containers/catalog/list/constants'
import { CATALOG_ADD_SUCCEEDED } from '@doinn/host/src/containers/catalog/add/constants'
import { CATALOG_EDIT_SUCCEEDED } from '@doinn/host/src/containers/catalog/edit/constants'
import { CATALOG_DELETE_SUCCEEDED } from '@doinn/host/src/containers/catalog/delete/constants'
import { fetchCatalogs as fetchCatalogsAction } from '@doinn/host/src/containers/catalog/list/actions'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* resetCatalogs() {
  yield put(fetchCatalogsAction())
}

function* fetchCatalog(action) {
  try {
    const response = yield call(fetchCatalogsApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: CATALOG_LIST_FETCH_SUCCEEDED,
      payload: {
        data,
        meta
      }
    })
  } catch (e) {
    yield put({
      type: CATALOG_LIST_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* fetchMoreCatalog(action) {
  try {
    const response = yield call(fetchCatalogsApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: CATALOG_LIST_FETCH_MORE_SUCCEEDED,
      payload: { data, meta }
    })
  } catch (e) {
    yield put({
      type: CATALOG_LIST_FETCH_MORE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* catalogSaga() {
  yield all([
    takeLatest(CATALOG_ADD_SUCCEEDED, resetCatalogs),
    takeLatest(CATALOG_EDIT_SUCCEEDED, resetCatalogs),
    takeLatest(CATALOG_DELETE_SUCCEEDED, resetCatalogs),
    takeLatest(CATALOG_LIST_FETCH_REQUESTED, fetchCatalog),
    takeLatest(CATALOG_LIST_FETCH_MORE_REQUESTED, fetchMoreCatalog)
  ])
}
