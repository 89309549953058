import React, { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { BASE_URL } from 'config/env'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'
import { Container, makeStyles } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { cloneDeep, isEqual as isObjEqual } from 'lodash'

import {
  useDidMount,
  useFilterState,
  usePrevious
} from '@doinn/shared/src/hooks'
import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import { getQueryStringObject } from '@doinn/shared/src/util/url'
import { parseAppliedFilters } from '@doinn/shared/src/util/filters'
import Alerts from '@doinn/shared/src/components/app/Alerts'
import MainContent from '@doinn/shared/src/components/app/MainContent'
import HeaderBlank from '@doinn/vendor/src/containers/app/HeaderBlank'
import HeaderCloseButton from '@doinn/shared/src/components/common/dialog/HeaderCloseButton'
import TemplateBlankFullHeight from '@doinn/shared/src/components/templates/TemplateBlankFullHeight'

const PlansContainer = loadable(() =>
  lazyRetry(() =>
    import('@doinn/shared/src/containers/subscription/plans/Plans')
  )
)

const defaultFilters = {
  plan: 'id',
  cycle: 'yearly'
}

const getQueryOrSavedFilters = location => {
  const queryFilters = getQueryStringObject(location.search)
  return parseAppliedFilters(defaultFilters, queryFilters)
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      background: theme.palette.common.white
    }
  }
}))

const Plans = () => {
  useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const previousLocation = usePrevious(location)
  const [justChangedLocation, setJustChangedLocation] = React.useState(false)
  const { appliedFilters, onChangeAppliedFilters } = useFilterState(
    cloneDeep(defaultFilters)
  )

  useDidMount(() => {
    onChangeAppliedFilters(getQueryOrSavedFilters(location))
  })

  React.useEffect(() => {
    if (!previousLocation) return
    setJustChangedLocation(
      !isObjEqual(location.search, previousLocation.search)
    )
  }, [location, previousLocation])

  React.useEffect(() => {
    if (!justChangedLocation) return
    const savedFilters = getQueryOrSavedFilters(location)
    const shouldUpdateAppliedFilters = !isObjEqual(appliedFilters, savedFilters)
    if (shouldUpdateAppliedFilters) {
      onChangeAppliedFilters(savedFilters)
    }
  }, [appliedFilters, justChangedLocation, location, onChangeAppliedFilters])

  const handleCloseClick = useCallback(() => {
    const previousLocation = location?.state?.closePath || '/'
    history.push(previousLocation)
  }, [history, location])

  return (
    <TemplateBlankFullHeight>
      <Helmet>
        <title>{t('Plans')}</title>
      </Helmet>
      <Container maxWidth='lg'>
        <HeaderBlank
          rightChildren={<HeaderCloseButton onClose={handleCloseClick} />}
        />
        <Alerts header baseUrl={BASE_URL} />
        <MainContent>
          <React.Suspense fallback={<div />}>
            <PlansContainer filters={appliedFilters} />
          </React.Suspense>
        </MainContent>
      </Container>
    </TemplateBlankFullHeight>
  )
}

export default Plans
