import { cloneDeep } from 'lodash'

const filledNameFilter = object => object?.name
const missingNameFilter = object => !object?.name
const missingItemsFilter = object => !object?.items?.length
const missingItemsNamesFilter = object => {
  return !!object?.items?.filter(missingNameFilter)?.length
}

export const removeChecklistEmptySectionsAndItems = checklist => {
  const newChecklist = cloneDeep(checklist)
  newChecklist.sections = newChecklist?.sections
    ?.filter(filledNameFilter)
    ?.map(section => {
      return {
        ...section,
        items: section?.items?.filter(filledNameFilter)
      }
    })
  return newChecklist
}

const listChecklistSectionsWithMissingNames = sections => {
  return sections?.filter(missingNameFilter)
}
const listChecklistSectionsWithMissingItems = sections => {
  return sections?.filter(missingItemsFilter)
}
const listChecklistSectionsWithMissingItemsNames = sections => {
  return sections?.filter(missingItemsNamesFilter)
}

export const validateChecklist = checklist => {
  if (!checklist) return false
  if (!checklist.name) return false
  if (!checklist.sections?.length) return false
  if (listChecklistSectionsWithMissingNames(checklist.sections)?.length) {
    return false
  }
  if (listChecklistSectionsWithMissingItems(checklist.sections)?.length) {
    return false
  }
  if (listChecklistSectionsWithMissingItemsNames(checklist.sections)?.length) {
    return false
  }
  return true
}

export const countTotalCheckedSectionItems = section => {
  const clonedSection = cloneDeep(section)
  if (!clonedSection?.items) return 0
  return clonedSection?.items?.reduce((carry, item) => {
    return carry + parseInt(item?.checked ? 1 : 0, 10)
  }, 0)
}

export const countTotalCheckedChecklistItems = checklist => {
  const clonedChecklist = cloneDeep(checklist)
  if (!clonedChecklist?.sections) return 0
  return clonedChecklist?.sections?.reduce((carry, section) => {
    return carry + countTotalCheckedSectionItems(section)
  }, 0)
}
