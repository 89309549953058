import { filter } from 'lodash'
import {
  BILLING_GROUP_LIST_FETCH_FAILED,
  BILLING_GROUP_LIST_FETCH_MORE_FAILED,
  BILLING_GROUP_LIST_FETCH_MORE_REQUESTED,
  BILLING_GROUP_LIST_FETCH_MORE_SUCCEEDED,
  BILLING_GROUP_LIST_FETCH_REQUESTED,
  BILLING_GROUP_LIST_FETCH_SUCCEEDED
} from '@doinn/shared/src/containers/subscription-billing/billing/list/group/constants'
import { BILLING_DELETE_SUCCEEDED } from '@doinn/shared/src/containers/subscription-billing/billing/delete/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  LOADING_MORE: 'loading-more',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  list: [],
  meta: {},
  status: STATUS.IDLE
}

const removeBilling = (state, payload) => {
  const { id, type } = payload
  if (type !== 'group') {
    return state
  }

  const newList = filter(state.list, item => item.id !== id)

  return {
    ...state,
    list: newList,
    meta: {
      ...state.meta,
      total: state.meta.total - 1
    }
  }
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case BILLING_GROUP_LIST_FETCH_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING
      }
    case BILLING_GROUP_LIST_FETCH_SUCCEEDED:
      return {
        ...state,
        list: [...payload.billings],
        meta: { ...payload.meta },
        status: STATUS.SUCCESS
      }
    case BILLING_GROUP_LIST_FETCH_FAILED:
      return {
        ...initialState,
        status: STATUS.FAILURE
      }
    case BILLING_DELETE_SUCCEEDED:
      return {
        ...removeBilling(state, payload)
      }
    case BILLING_GROUP_LIST_FETCH_MORE_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING_MORE
      }
    case BILLING_GROUP_LIST_FETCH_MORE_SUCCEEDED:
      return {
        ...state,
        list: [...state.list, ...payload.billings],
        meta: { ...payload.meta },
        status: STATUS.SUCCESS
      }
    case BILLING_GROUP_LIST_FETCH_MORE_FAILED:
      return {
        ...state,
        ...initialState,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
