import { merge } from 'lodash'
import { fade } from '@material-ui/core/styles'
import palette from '@doinn/shared/src/config/theme/palette'
import typography from '@doinn/shared/src/config/theme/typography'
import { segoeUI } from '@doinn/shared/src/config/theme/fonts'

// Overrides components styles
const overrides = {
  MuiFormLabel: {
    root: {
      '&.Mui-focused': {
        color: palette.secondary.main
      }
    }
  },
  MuiFilledInput: {
    underline: {
      '&:after': {
        borderBottomColor: palette.secondary.main
      }
    }
  },
  MuiCssBaseline: {
    '@global': {
      '@font-face': [segoeUI]
    }
  }
}

export const withOverridesVariants = theme => {
  const containedButtonShadow = `${
    theme.shadows[15].split(' rgba')[0]
  } rgba(0, 0, 0, 0.5)}`
  const containedButtonShadowDark = `${
    containedButtonShadow.split(' rgba')[0]
  } rgba(0, 0, 0, 0.85)}`
  const containedPrimaryButtonShadow = `${
    containedButtonShadow.split(' rgba')[0]
  } ${theme.palette.primary.boxShadow}`
  const containedPrimaryButtonShadowDark = `${
    containedButtonShadow.split(' rgba')[0]
  } ${theme.palette.primary.boxShadowDark}`
  const containedSecondaryButtonShadow = `${
    theme.shadows[15].split(' rgba')[0]
  } ${theme.palette.secondary.boxShadow}`
  const containedSecondaryButtonShadowDark = `${
    containedButtonShadow.split(' rgba')[0]
  } ${theme.palette.secondary.boxShadowDark}`

  const primaryMainFocusOpacity = 0.04
  const primaryMainHoverOpacity = 0.02
  const primaryMainFocusBackgroundColor = fade(
    theme.palette.primary.main,
    primaryMainFocusOpacity
  )
  const primaryMainHoverBackgroundColor = fade(
    theme.palette.primary.main,
    primaryMainHoverOpacity
  )

  return merge(theme, {
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [segoeUI]
        }
      },
      MuiAlert: {
        root: {
          padding: theme.spacing(2, 3)
        },
        standardWarning: {
          border: `1px solid ${theme.palette.warning.main}`
        },
        standardSuccess: {
          border: `1px solid ${theme.palette.success.main}`
        },
        standardInfo: {
          border: `1px solid ${theme.palette.info.main}`
        },
        standardError: {
          border: `1px solid ${theme.palette.error.main}`
        }
      },
      MuiBottomNavigationAction: {
        root: {
          minWidth: theme.spacing(9)
        },
        label: {
          fontSize: theme.typography.pxToRem(12),
          '&$selected': {
            fontSize: theme.typography.pxToRem(12)
          }
        }
      },
      MuiBadge: {
        colorPrimary: {
          backgroundColor: palette.priority.high
        }
      },
      MuiToolbar: {
        gutters: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3)
          }
        }
      },
      MuiListSubheader: {
        root: {
          ...theme.typography.subtitle2,
          padding: theme.spacing(1, 3),
          color: palette.text.primary,
          fontWeight: typography.fontWeightMedium
        },
        gutters: {
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3)
        }
      },
      MuiListItem: {
        root: {
          '&.Mui-focusVisible': {
            backgroundColor: primaryMainFocusBackgroundColor
          }
        },
        gutters: {
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3)
        },
        button: {
          '&:hover': {
            backgroundColor: primaryMainHoverBackgroundColor
          }
        },
        secondaryAction: {
          paddingRight: theme.spacing(7)
        }
      },
      MuiListItemSecondaryAction: {
        root: {
          right: theme.spacing(3)
        }
      },
      MuiButton: {
        root: {
          borderRadius: '9999px',
          padding: theme.spacing(1, 3)
        },
        label: {
          whiteSpace: 'nowrap'
        },
        text: {
          padding: theme.spacing(1, 1)
        },
        textSizeSmall: {
          ...theme.typography.button,
          padding: theme.spacing(0.75, 0.7)
        },
        textSizeLarge: {
          ...theme.typography.subtitle2,
          padding: theme.spacing(1.75, 2)
        },
        textPrimary: {
          textDecoration: 'none',
          backgroundColor: 'transparent',
          '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent'
            }
          }
        },
        textSecondary: {
          textDecoration: 'none',
          backgroundColor: 'transparent',
          '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent'
            }
          }
        },
        outlined: {
          color: theme.palette.secondary.main,
          padding: theme.spacing(0.875, 2.875),
          border: `1px solid ${theme.palette.secondary.main}`,
          '&:hover': {
            color: theme.palette.secondary.contrastText,
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: theme.palette.secondary.main,
            '@media (hover: none)': {
              color: theme.palette.secondary.main,
              backgroundColor: 'transparent'
            }
          }
        },
        outlinedPrimary: {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main}`,
          '&:hover': {
            color: theme.palette.primary.contrastText,
            border: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: theme.palette.primary.main,
            '@media (hover: none)': {
              color: theme.palette.primary.main,
              backgroundColor: 'transparent'
            }
          },
          '&$disabled': {
            color: fade(theme.palette.primary.main, 0.38),
            border: `1px solid ${fade(theme.palette.primary.main, 0.38)}`
          }
        },
        outlinedSecondary: {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.secondary.main,
          border: `1px solid ${theme.palette.secondary.main}`,
          '&:hover': {
            color: theme.palette.secondary.contrastText,
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: theme.palette.secondary.main,
            '@media (hover: none)': {
              color: theme.palette.secondary.main,
              backgroundColor: 'transparent'
            }
          },
          '&$disabled': {
            border: `1px solid ${theme.palette.action.disabled}`
          },
          '&.inverse': {
            border: `1px solid ${theme.palette.common.white}`,
            background: theme.palette.secondary.main,
            color: theme.palette.common.white,
            '&:hover': {
              color: theme.palette.secondary.main,
              background: theme.palette.common.white,
              '@media (hover: none)': {
                color: theme.palette.secondary.main,
                backgroundColor: theme.palette.common.white
              }
            }
          }
        },
        outlinedSizeSmall: {
          ...theme.typography.button,
          padding: theme.spacing(0.625, 2.875)
        },
        outlinedSizeLarge: {
          ...theme.typography.subtitle2,
          padding: theme.spacing(1.625, 3.875)
        },
        contained: {
          boxShadow: containedButtonShadow,
          '&:hover': {
            boxShadow: containedButtonShadowDark,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              boxShadow: containedButtonShadow
            },
            '&$disabled': {
              boxShadow: theme.shadows[0],
              backgroundColor: theme.palette.action.disabledBackground
            }
          },
          '&$focusVisible': {
            boxShadow: containedButtonShadow
          },
          '&:active': {
            boxShadow: containedButtonShadow
          },
          '&$disabled': {
            color: theme.palette.common.white,
            boxShadow: theme.shadows[0],
            backgroundColor: theme.palette.action.disabledBackground
          }
        },
        /* Styles applied to the root element if `variant="contained"` and `color="primary"`. */
        containedPrimary: {
          backgroundColor: theme.palette.primary.main,
          boxShadow: containedPrimaryButtonShadow,
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            boxShadow: containedPrimaryButtonShadowDark,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: theme.palette.primary.main,
              boxShadow: containedPrimaryButtonShadow
            },
            '&$disabled': {
              boxShadow: theme.shadows[0],
              backgroundColor: fade(theme.palette.primary.main, 0.38)
            }
          },
          '&$focusVisible': {
            boxShadow: containedPrimaryButtonShadowDark
          },
          '&:active': {
            boxShadow: containedPrimaryButtonShadowDark
          },
          '&$disabled': {
            color: theme.palette.common.white,
            boxShadow: theme.shadows[0],
            backgroundColor: fade(theme.palette.primary.main, 0.38)
          }
        },
        /* Styles applied to the root element if `variant="contained"` and `color="primary"`. */
        containedSecondary: {
          backgroundColor: theme.palette.secondary.main,
          boxShadow: containedSecondaryButtonShadow,
          '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            boxShadow: containedSecondaryButtonShadowDark,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: theme.palette.secondary.main,
              boxShadow: containedSecondaryButtonShadow
            },
            '&$disabled': {
              boxShadow: theme.shadows[0],
              backgroundColor: fade(theme.palette.secondary.main, 0.38)
            }
          },
          '&$focusVisible': {
            boxShadow: containedPrimaryButtonShadowDark
          },
          '&:active': {
            boxShadow: containedPrimaryButtonShadowDark
          },
          '&$disabled': {
            color: theme.palette.action.disabled,
            boxShadow: theme.shadows[0],
            backgroundColor: fade(theme.palette.secondary.main, 0.38)
          }
        },
        containedSizeSmall: {
          ...theme.typography.button,
          padding: theme.spacing(0.75, 3)
        },
        containedSizeLarge: {
          ...theme.typography.subtitle2,
          padding: theme.spacing(1.75, 4)
        }
      },
      MuiFab: {
        extended: {
          ...theme.typography.subtitle2,
          borderRadius: 999,
          height: theme.spacing(7),
          padding: theme.spacing(0, 4),
          '&$sizeSmall': {
            ...theme.typography.button,
            borderRadius: 999,
            height: theme.spacing(4.5),
            padding: theme.spacing(0, 3)
          },
          '&$sizeMedium': {
            ...theme.typography.button,
            borderRadius: 999,
            height: theme.spacing(5),
            padding: theme.spacing(0, 3)
          }
        }
      },
      MuiIconButton: {
        root: {
          padding: theme.spacing(1),
          fontSize: theme.typography.pxToRem(24)
        },
        sizeSmall: {
          padding: theme.spacing(0.875),
          fontSize: theme.typography.pxToRem(18)
        }
      },
      MuiTypography: {
        gutterBottom: {
          marginBottom: theme.spacing(1)
        }
      },
      MuiBackdrop: {
        root: {
          backgroundColor: fade(theme.palette.common.white, 0.8)
        }
      },
      MuiChip: {
        root: {
          fontSize: theme.typography.pxToRem(14),
          height: theme.spacing(5),
          borderRadius: theme.spacing(2.5),
          '& $avatar': {
            width: 24,
            height: 24,
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(-2),
            fontSize: theme.typography.pxToRem(12)
          },
          '& $avatarSmall': {
            width: 24,
            height: 24,
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(-2),
            fontSize: theme.typography.pxToRem(12)
          }
        },
        deleteIcon: {
          marginLeft: theme.spacing(-2),
          marginRight: theme.spacing(1),
          color: theme.palette.secondary.main,
          '&:hover': {
            color: theme.palette.secondary.main
          }
        },
        deleteIconSmall: {
          height: 22,
          width: 22,
          marginLeft: theme.spacing(-2),
          marginRight: theme.spacing(0.5)
        },
        icon: {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(-2)
        },
        iconSmall: {
          width: '1em',
          height: '1em',
          marginLeft: theme.spacing(0.5),
          marginRight: theme.spacing(-2)
        },
        label: {
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3)
        },
        labelSmall: {
          fontSize: theme.typography.pxToRem(12),
          fontWeight: theme.typography.fontWeightBold,
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3)
        },
        outlined: {
          borderColor: theme.palette.secondary.main,
          backgroundColor: theme.palette.common.white,
          '& $avatar': {
            marginLeft: theme.spacing(1)
          },
          '& $avatarSmall': {
            marginLeft: theme.spacing(0.5)
          },
          '& $icon': {
            marginLeft: theme.spacing(1)
          },
          '& $iconSmall': {
            marginLeft: theme.spacing(0.5)
          },
          '& $deleteIcon': {
            marginRight: theme.spacing(1)
          },
          '& $deleteIconSmall': {
            marginRight: theme.spacing(0.5)
          }
        },
        sizeSmall: {
          height: theme.spacing(4)
        }
      },
      MuiDialog: {
        paper: {
          border: '1px solid #e1e1e1'
        },
        paperFullScreen: {
          border: 'none'
        }
      },
      MuiDialogTitle: {
        root: {
          padding: theme.spacing(3)
        }
      },
      MuiDialogContent: {
        root: {
          padding: theme.spacing(3)
        },
        dividers: {
          padding: theme.spacing(3)
        }
      },
      MuiDialogActions: {
        root: {
          padding: theme.spacing(3)
        },
        spacing: {
          '& > :not(:first-child)': {
            marginLeft: theme.spacing(2)
          }
        }
      },
      MuiDrawer: {
        paperAnchorLeft: {
          borderRight: `1px solid ${theme.palette.divider}`
        },
        paperAnchorRight: {
          borderLeft: `1px solid ${theme.palette.divider}`
        }
      },
      MuiCardContent: {
        root: {
          padding: theme.spacing(3)
        }
      },
      MuiCardActionArea: {
        root: {
          '&:hover $focusHighlight': {
            opacity: primaryMainHoverOpacity
          },
          '&$focusVisible $focusHighlight': {
            opacity: primaryMainFocusOpacity
          }
        },
        focusHighlight: {
          backgroundColor: theme.palette.primary.main
        }
      },
      MuiCardActions: {
        root: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2)
        }
      },
      MuiTab: {
        root: {
          ...theme.typography.subtitle1,
          fontWeight: theme.typography.fontWeightBold,
          minHeight: theme.spacing(7)
        },
        textColorInherit: {
          opacity: 1,
          '&$selected': {
            opacity: 1
          },
          '&$disabled': {
            opacity: 0.5
          }
        }
      },
      MuiTableRow: {
        root: {
          '&$hover': {
            '&:hover': {
              backgroundColor: primaryMainHoverBackgroundColor
            }
          }
        },
        hover: {
          cursor: 'pointer'
        }
      },
      MuiTableCell: {
        root: {
          ...theme.typography.body1,
          paddingLeft: theme.spacing(1.5),
          paddingRight: theme.spacing(1.5),
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
          '&:first-child': {
            paddingLeft: theme.spacing(3)
          },
          '&:last-child': {
            paddingRight: theme.spacing(3)
          }
        },
        paddingCheckbox: {
          paddingRight: theme.spacing(1.5),
          '&:first-child': {
            paddingLeft: theme.spacing(1.5)
          },
          '&:last-child': {
            paddingRight: theme.spacing(1.5)
          }
        },
        paddingNone: {
          paddingRight: theme.spacing(1.5),
          '&:first-child': {
            paddingLeft: theme.spacing(3)
          },
          '&:last-child': {
            paddingRight: theme.spacing(3)
          }
        },
        head: {
          ...theme.typography.body2,
          fontWeight: theme.typography.fontWeightMediumBold,
          borderBottom: 'none',
          paddingBottom: 0
        },
        footer: {
          ...theme.typography.body1,
          fontWeight: theme.typography.fontWeightMediumBold,
          borderBottom: 'none',
          color: theme.palette.text.primary
        }
      },
      MuiStepIcon: {
        root: {
          '&$completed': {
            color: theme.palette.success.light
          }
        },
        text: {
          fontSize: theme.typography.body2.fontSize,
          fontWeight: theme.typography.fontWeightMediumBold
        }
      },
      MuiStepLabel: {
        label: {
          '&$completed': {
            fontWeight: theme.fontWeightRegular
          }
        },
        iconContainer: {
          paddingRight: theme.spacing(2)
        }
      },
      MuiStepConnector: {
        vertical: {
          padding: theme.spacing(0.5, 0)
        },
        line: {
          borderColor: theme.palette.divider
        },
        lineVertical: {
          minHeight: theme.spacing(4)
        }
      },
      MuiOutlinedInput: {
        root: {
          backgroundColor: theme.palette.common.white
        }
      },
      MuiTooltip: {
        tooltip: { ...theme.typography.body2 }
      },
      MuiPaginationItem: {
        textPrimary: {
          color: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: primaryMainFocusBackgroundColor
          },
          '&.Mui-selected': {
            color: theme.palette.primary.main,
            backgroundColor: fade(theme.palette.primary.main, 0.12),
            '&:hover,.Mui-focusVisible': {
              color: theme.palette.common.white
            }
          }
        }
      }
    }
  })
}

export default overrides
