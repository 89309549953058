import {
  PROPERTY_DETAILS_CLOSE_REQUESTED,
  PROPERTY_DETAILS_OPEN_REQUESTED
} from '@doinn/shared/src/containers/property/details/constants'

export const initialState = {
  isOpened: false
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case PROPERTY_DETAILS_OPEN_REQUESTED:
      return {
        ...initialState,
        isOpened: true
      }
    case PROPERTY_DETAILS_CLOSE_REQUESTED:
      return {
        ...initialState,
        isOpened: false
      }
    default:
      return state
  }
}
