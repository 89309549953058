import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { unblockReservationApi } from '@doinn/vendor/src/api/reservations'
import {
  RESERVATION_UNBLOCK_FAILED,
  RESERVATION_UNBLOCK_REQUESTED,
  RESERVATION_UNBLOCK_SUCCEEDED
} from '@doinn/vendor/src/containers/reservation/unblock/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* unblockReservation(action) {
  try {
    const { id } = action.payload.params
    const response = yield call(unblockReservationApi, id)
    const { data } = response.data

    yield put({
      type: RESERVATION_UNBLOCK_SUCCEEDED,
      payload: { data },
      snackbar: {
        variant: 'success',
        message: i18n.t('The reservation has been unblocked successfully!')
      }
    })
  } catch (e) {
    yield put({
      type: RESERVATION_UNBLOCK_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* reservationUnblockSaga() {
  yield all([takeLatest(RESERVATION_UNBLOCK_REQUESTED, unblockReservation)])
}
