import React from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'

import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import { useDetailsFilters } from '@doinn/vendor/src/scenes/hooks/useDetailsFilters'
import TemplateDefault from 'components/TemplateDefault'

const PropertyDetailsContainer = loadable(() =>
  lazyRetry(() =>
    import('@doinn/shared/src/containers/property/details/PropertyDetails')
  )
)

const PropertyDetails = () => {
  const { t } = useTranslation()

  const { contentFilterProps, filtersContent, filtersDialog } =
    useDetailsFilters({
      entityFilterName: 'spaces',
      entityIdParamName: 'propertyId'
    })

  return (
    <TemplateDefault title={t('Properties')} filtersContent={filtersContent}>
      <Helmet>
        <title>{t('Properties')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <PropertyDetailsContainer contentProps={contentFilterProps} />
      </React.Suspense>
      {filtersDialog}
    </TemplateDefault>
  )
}

export default PropertyDetails
