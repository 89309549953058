import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => {
  return {
    text: {
      color: theme.palette.common.white
    }
  }
})

function MainMenuItem() {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      className={classes.text}
      textAlign='center'
    >
      <Typography component='div' variant='subtitle1'>
        <Box pb={1} sx={{ lineHeight: 'normal' }}>
          <b>{t('Sign up with Doinn')}</b>
        </Box>
      </Typography>
      <Typography component='div' variant='body2'>
        <Box sx={{ lineHeight: 'normal' }}>
          {t('Start using Doinn in minutes.')}
        </Box>
        <Box sx={{ lineHeight: 'normal' }}>
          {t('Full access. No credit card needed.')}
        </Box>
      </Typography>
    </Box>
  )
}

export default MainMenuItem
