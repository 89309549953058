export const DOCUMENTS_FETCH_REQUESTED = 'DOCUMENTS_FETCH_REQUESTED'
export const DOCUMENTS_FETCH_SUCCEEDED = 'DOCUMENTS_FETCH_SUCCEEDED'
export const DOCUMENTS_FETCH_FAILED = 'DOCUMENTS_FETCH_FAILED'

export const DOCUMENTS_UPLOAD_REQUESTED = 'DOCUMENTS_UPLOAD_REQUESTED'
export const DOCUMENTS_UPLOAD_SUCCEEDED = 'DOCUMENTS_UPLOAD_SUCCEEDED'
export const DOCUMENTS_UPLOAD_FAILED = 'DOCUMENTS_UPLOAD_FAILED'

export const DOCUMENTS_DELETE_REQUESTED = 'DOCUMENTS_DELETE_REQUESTED'
export const DOCUMENTS_DELETE_SUCCEEDED = 'DOCUMENTS_DELETE_SUCCEEDED'
export const DOCUMENTS_DELETE_FAILED = 'DOCUMENTS_DELETE_FAILED'
