import { all, call, put, takeLatest } from 'redux-saga/effects'
import { addProvidersInvitationsApi } from '@doinn/vendor/src/api/membersInvitations'
import {
  PROVIDERS_INVITATIONS_ADD_FAILED,
  PROVIDERS_INVITATIONS_ADD_REQUESTED,
  PROVIDERS_INVITATIONS_ADD_SUCCEEDED
} from '@doinn/vendor/src/containers/provider/add/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import i18n from '@doinn/shared/src/config/i18n'

function* addProvidersInvitations(action) {
  try {
    const { invitations } = action.payload
    yield call(addProvidersInvitationsApi, invitations)
    yield put({
      type: PROVIDERS_INVITATIONS_ADD_SUCCEEDED,
      payload: action.payload,
      snackbar: {
        variant: 'success',
        message: i18n.t('Invites have been sent!')
      }
    })
  } catch (e) {
    yield put({
      type: PROVIDERS_INVITATIONS_ADD_FAILED,
      payload: {
        errorMessage: getSnackbarErrorMessage(e)
      },
      snackbar: {
        variant: 'error',
        message: i18n.t(
          'We cannot send invitations to some email addresses. Check the address and please try to send the invites again.'
        )
      }
    })
  }
}

export default function* providersInvitationsAddSaga() {
  yield all([
    takeLatest(PROVIDERS_INVITATIONS_ADD_REQUESTED, addProvidersInvitations)
  ])
}
