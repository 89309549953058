import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enGbTranslation from '@doinn/shared/src/translations/en-GB.json'
import esEsTranslation from '@doinn/shared/src/translations/es-ES.json'
import frFrTranslation from '@doinn/shared/src/translations/fr-FR.json'
import itItTranslation from '@doinn/shared/src/translations/it-IT.json'
import ptPtTranslation from '@doinn/shared/src/translations/pt-PT.json'

const IS_DEVELOPMENT = process?.env?.NODE_ENV === 'development'

const resources = {
  'en-GB': { translation: enGbTranslation },
  'en-US': { translation: enGbTranslation },
  'es-ES': { translation: esEsTranslation },
  'fr-FR': { translation: frFrTranslation },
  'it-IT': { translation: itItTranslation },
  'pt-PT': { translation: ptPtTranslation }
}

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources,
  lng: 'en-GB',
  keySeparator: false,
  interpolation: {
    escapeValue: false
  },
  // use `debug: true` to get more info about missing keys!
  // debug: IS_DEVELOPMENT,
  saveMissing: IS_DEVELOPMENT,
  missingKeyHandler: IS_DEVELOPMENT
    ? (lng, ns, key, fallbackValue) => {
        // eslint-disable-next-line
        // console.log('Missing key in translations', {
        //   lng,
        //   ns,
        //   key,
        //   fallbackValue
        // })
        // eslint-disable-next-line
        // console.log(`Missing key in translations "${key}"`)
        if (window.doinnI18nMissingKeys === undefined) {
          window.doinnI18nMissingKeys = []
          console.log(
            "Check the missing keys in translations on 'window.doinnI18nMissingKeys'"
          )
        }
        if (!window.doinnI18nMissingKeys.includes(key)) {
          window.doinnI18nMissingKeys.push(key)
        }
      }
    : false
})

export default i18n
