import {
  CHECKLISTS_MY_LIST_FETCH_MORE_REQUESTED,
  CHECKLISTS_MY_LIST_FETCH_REQUESTED
} from '@doinn/host/src/containers/checklist/my/list/constants'

export function fetchChecklists(params = {}) {
  return { type: CHECKLISTS_MY_LIST_FETCH_REQUESTED, payload: { params } }
}

export function fetchMoreChecklists(params = {}) {
  return {
    type: CHECKLISTS_MY_LIST_FETCH_MORE_REQUESTED,
    payload: { params }
  }
}
