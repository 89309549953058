import { all, call, put, takeLatest } from 'redux-saga/effects'
import { deleteChecklistMyApi } from '@doinn/host/src/api/checklists'
import {
  CHECKLIST_MY_DELETE_FAILED,
  CHECKLIST_MY_DELETE_REQUESTED,
  CHECKLIST_MY_DELETE_SUCCEEDED
} from '@doinn/host/src/containers/checklist/my/delete/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* deleteChecklistMy(action) {
  try {
    const { checklistId } = action.payload
    yield call(deleteChecklistMyApi, checklistId)
    yield put({
      type: CHECKLIST_MY_DELETE_SUCCEEDED,
      payload: action.payload
    })
  } catch (e) {
    yield put({
      type: CHECKLIST_MY_DELETE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* checklistMyDeleteSaga() {
  yield all([takeLatest(CHECKLIST_MY_DELETE_REQUESTED, deleteChecklistMy)])
}
