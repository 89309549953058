import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  downloadPropertiesInstructionsExcelApi,
  fetchPropertyInstructionsApi
} from '@doinn/shared/src/api/property-instructions'
import {
  PROPERTIES_INSTRUCTIONS_DOWNLOAD_FAILED,
  PROPERTIES_INSTRUCTIONS_DOWNLOAD_REQUESTED,
  PROPERTIES_INSTRUCTIONS_DOWNLOAD_SUCCEEDED,
  PROPERTY_INSTRUCTIONS_FETCH_FAILED,
  PROPERTY_INSTRUCTIONS_FETCH_REQUESTED,
  PROPERTY_INSTRUCTIONS_FETCH_SUCCEEDED
} from '@doinn/shared/src/containers/property-instructions/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchPropertyInstructions(action) {
  try {
    const response = yield call(
      fetchPropertyInstructionsApi,
      action.payload.params
    )
    yield put({
      type: PROPERTY_INSTRUCTIONS_FETCH_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: PROPERTY_INSTRUCTIONS_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* downloadPropertiesInstructions(action) {
  try {
    const reportName = `doinn-properties-instructions-export.xls`
    const { data } = yield call(downloadPropertiesInstructionsExcelApi)

    yield put({
      type: PROPERTIES_INSTRUCTIONS_DOWNLOAD_SUCCEEDED,
      snackbar: {
        variant: 'success',
        message: i18n.t(
          'The properties instructions have been downloaded successfully'
        )
      },
      downloader: {
        data,
        type: 'application/vnd.ms-excel',
        name: reportName
      }
    })
  } catch (e) {
    yield put({
      type: PROPERTIES_INSTRUCTIONS_DOWNLOAD_FAILED,
      snackbar: {
        variant: 'error',
        message: e.message
      }
    })
  }
}

export default function* propertyInstructionsSaga() {
  yield all([
    takeLatest(
      PROPERTIES_INSTRUCTIONS_DOWNLOAD_REQUESTED,
      downloadPropertiesInstructions
    ),
    takeLatest(PROPERTY_INSTRUCTIONS_FETCH_REQUESTED, fetchPropertyInstructions)
  ])
}
