import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function fetchCustomersApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/people/customers`, config)
}

export function fetchCustomersShareCatalogueApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/people/customers/share/catalogue/${params?.id}`, config)
}

export function getCustomerApi(customer) {
  const config = {}
  return DoinnApi.get(`/people/customers/${customer.id}`, config)
}
