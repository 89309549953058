import DoinnApi from '@doinn/shared/src/config/doinnApi'
import { fetchPropertiesElasticsearch } from '@doinn/shared/src/api/elasticsearch/properties'

export function fetchPropertiesApi(params = {}) {
  const { params: filters } = params
  const { userRole, ...otherFilters } = filters
  const config = {
    params: otherFilters
  }
  return DoinnApi.get(`/properties`, config)
}

export function fetchPropertiesElasticsearchApi(params = {}) {
  const { params: filters } = params
  return fetchPropertiesElasticsearch({ ...filters })
}

export function syncPropertiesApi() {
  const config = {}
  return DoinnApi.post('/vendor/properties/sync', config)
}
