import {
  ONBOARDING_DEMO_DIALOG_CLOSE,
  ONBOARDING_DEMO_DIALOG_OPEN,
  ONBOARDING_DEMO_REQUESTED
} from '@doinn/shared/src/containers/onboarding/demo/constants'

export function open(params) {
  return {
    type: ONBOARDING_DEMO_DIALOG_OPEN,
    payload: { params }
  }
}

export function close(params) {
  return {
    type: ONBOARDING_DEMO_DIALOG_CLOSE,
    payload: { params }
  }
}

export function onboardingDemo(params = {}) {
  return {
    type: ONBOARDING_DEMO_REQUESTED,
    payload: { params }
  }
}
