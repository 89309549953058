import { get } from 'lodash'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  fetchCustomersApi,
  fetchCustomersShareCatalogueApi
} from '@doinn/vendor/src/api/customer'
import {
  CUSTOMERS_FETCH_FAILED,
  CUSTOMERS_FETCH_REQUESTED,
  CUSTOMERS_FETCH_SUCCEEDED,
  CUSTOMERS_SHARE_CATALOGUE_FETCH_FAILED,
  CUSTOMERS_SHARE_CATALOGUE_FETCH_REQUESTED,
  CUSTOMERS_SHARE_CATALOGUE_FETCH_SUCCEEDED
} from '@doinn/vendor/src/containers/customer/list/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchCustomers(action) {
  try {
    const params = get(action, 'payload.params', {})
    const response = yield call(fetchCustomersApi, params)
    yield put({
      type: CUSTOMERS_FETCH_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: CUSTOMERS_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* fetchShareCatalogueCustomers(action) {
  try {
    const params = get(action, 'payload.params', {})
    const response = yield call(fetchCustomersShareCatalogueApi, params)
    yield put({
      type: CUSTOMERS_SHARE_CATALOGUE_FETCH_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: CUSTOMERS_SHARE_CATALOGUE_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* customersSaga() {
  yield all([
    takeLatest(CUSTOMERS_FETCH_REQUESTED, fetchCustomers),
    takeLatest(
      CUSTOMERS_SHARE_CATALOGUE_FETCH_REQUESTED,
      fetchShareCatalogueCustomers
    )
  ])
}
