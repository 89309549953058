import React, { useCallback } from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import withAuthorization from '@doinn/shared/src/hoc/authorization/withAuthorization'
import { useDidMount } from '@doinn/shared/src/hooks'
import ListFilters from 'components/job/ListFilters'
import { fetchJobFilters as fetchJobFiltersAction } from '@doinn/shared/src/containers/jobs/filters/actions'
import { fetchEmployees } from '@doinn/vendor/src/containers/staff/employees/actions'
import { fetchPropertiesElasticsearchApi } from '@doinn/shared/src/api/properties'
import { getListedJobsTypologies } from 'containers/jobs/selectors'
import { STATUS as STATUS_JOB } from 'containers/jobs/reducers'
import { addOrRemove } from '@doinn/shared/src/util/array'
import { fetchAutomations } from '@doinn/host/src/containers/automations/list/actions'

const DEFAULT_ROWS_PER_PAGE = 30

const mapIdsToFilter = items => {
  if (!items || !items.length) return []
  return items
    .map(item => {
      if (typeof item === 'object') {
        return item.id ? item.id : ''
      }
      return item
    })
    .filter(item => !!item)
}

const parseGetPropertiesAutocompleteOptionsParams = ({
  search = '',
  limit = DEFAULT_ROWS_PER_PAGE,
  loggedUser,
  filters
} = {}) => {
  const params = {
    search,
    limit
  }
  if (loggedUser?.business?.id) {
    if (loggedUser?.roles?.includes('vendor')) {
      params.vendorBusinessAccountId = loggedUser.business.id
    }
    if (loggedUser?.roles?.includes('host')) {
      params.hostBusinessAccountId = loggedUser.business.id
    }
  }
  if (filters) {
    if (filters.spaces) {
      params.ids = mapIdsToFilter(filters.spaces)
    }
  }
  return params
}

const getAutocompleteOptions =
  loggedUser => async (filterName, value, filters) => {
    const callbacks = {
      spaces: fetchPropertiesElasticsearchApi
    }
    if (callbacks[filterName]) {
      const params = parseGetPropertiesAutocompleteOptionsParams({
        search: value || '',
        limit: DEFAULT_ROWS_PER_PAGE,
        loggedUser,
        filters
      })
      const response = await callbacks[filterName]({ params })
      return response.data.data || []
    }
    return []
  }

const ListFiltersContent = ({
  alwaysDisplayDateRange,
  alwaysDisplaySource,
  loggedUser,
  canAll,
  cities,
  disabledFilters,
  employees,
  automations,
  fetchEmployees,
  fetchJobFilters,
  fetchAutomations,
  filters,
  hiddenFilters,
  hostBusinessAccounts,
  isCitiesLoading,
  isEmployeesLoading,
  isHostBusinessAccountsLoading,
  isServicesLoading,
  isTypologiesLoading,
  isAutomationsLoading,
  onChangeFilters,
  services,
  typologies
}) => {
  const [selectedProperties, setSelectedProperties] = React.useState([])
  const userRole = loggedUser?.roles?.includes('vendor') ? 'vendor' : 'host'

  useDidMount(() => {
    if (
      canAll(['read-people-staff', 'update-job-staff']) &&
      !isEmployeesLoading
    ) {
      fetchEmployees()
    }

    if (canAll(['read-automations']) && !isAutomationsLoading) {
      fetchAutomations()
    }
  })

  React.useEffect(() => {
    fetchJobFilters({})
  }, [fetchJobFilters])

  const memoizedGetAutocompleteOptions = React.useMemo(() => {
    return getAutocompleteOptions(loggedUser)
  }, [loggedUser])

  React.useEffect(() => {
    const fetchSelectedProperties = async () => {
      let newSelectedProperties = []
      if (filters.spaces && filters.spaces.length) {
        newSelectedProperties = await memoizedGetAutocompleteOptions(
          'spaces',
          '',
          { spaces: filters.spaces }
        )
      }
      setSelectedProperties(newSelectedProperties)
    }
    fetchSelectedProperties()
  }, [filters.spaces, memoizedGetAutocompleteOptions])

  const handleChangeFilter = useCallback(
    (filterName, newValue) => {
      const oldValue = filters[filterName]
      const newValueAsString =
        typeof newValue === 'object' &&
        !Array.isArray(newValue) &&
        newValue !== null
          ? String(newValue.id)
          : newValue
      const parsedNewValue = Array.isArray(oldValue)
        ? addOrRemove(oldValue, newValueAsString)
        : newValueAsString
      const params = {
        ...filters,
        page: 1
      }
      params[filterName] = parsedNewValue
      onChangeFilters && onChangeFilters(params)
    },
    [filters, onChangeFilters]
  )

  const filtersProps = {
    disabledFilters,
    hiddenFilters,
    alwaysDisplayDateRange: alwaysDisplayDateRange,
    alwaysDisplaySource: alwaysDisplaySource,
    isCitiesLoading,
    cities,
    isServicesLoading,
    services,
    isHostBusinessAccountsLoading,
    hostBusinessAccounts,
    isEmployeesLoading,
    isAutomationsLoading,
    employees: [...employees],
    automations: [...automations],
    isTypologiesLoading,
    typologies: [...typologies],
    filters: { ...filters, spaces: selectedProperties },
    userRole: userRole,
    onChange: handleChangeFilter,
    onChangeFilters: onChangeFilters,
    getAutocompleteOptions: memoizedGetAutocompleteOptions
  }

  return <ListFilters {...filtersProps} />
}

const mapStateToProps = state => {
  const { list: employeesList, isLoading: isEmployeesLoading } = state.employees
  const { list: automationsList, isLoading: isAutomationsLoading } =
    state.automationList
  const isJobsLoading = state.jobs.status === STATUS_JOB.LOADING

  return {
    loggedUser: state.user.loggedUser,

    cities: state.jobFilters.filters.cities,
    isCitiesLoading: state.jobFilters.isLoading,

    services: state.jobFilters.filters.services,
    isServicesLoading: state.jobFilters.isLoading,

    employees: employeesList,
    isEmployeesLoading,

    hostBusinessAccounts: state.jobFilters.filters.hostBusinessAccounts,
    isHostBusinessAccountsLoading: isJobsLoading,

    automations: automationsList,
    isAutomationsLoading,

    typologies: getListedJobsTypologies(state),
    isTypologiesLoading: isJobsLoading
  }
}

const mapDispatchToProps = dispatch => ({
  fetchEmployees: bindActionCreators(fetchEmployees, dispatch),
  fetchJobFilters: bindActionCreators(fetchJobFiltersAction, dispatch),
  fetchAutomations: bindActionCreators(fetchAutomations, dispatch)
})

ListFiltersContent.propTypes = {
  disabledFilters: PropTypes.object,
  filters: PropTypes.object.isRequired,
  alwaysDisplayDateRange: PropTypes.bool,
  alwaysDisplaySource: PropTypes.bool
}

ListFiltersContent.defaultProps = {
  disabledFilters: {},
  alwaysDisplayDateRange: false,
  alwaysDisplaySource: false
}

export default compose(
  withAuthorization,
  connect(mapStateToProps, mapDispatchToProps)
)(ListFiltersContent)
