import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
  makeStyles
} from '@material-ui/core'
import Dialog from '@doinn/shared/src/components/common/dialog/Dialog'
import DialogHeader from '@doinn/shared/src/components/common/dialog/DialogHeader'

const useStyles = makeStyles(theme => ({
  text: {
    marginTop: theme.spacing(2)
  },
  noPaddingTop: {
    paddingTop: theme.spacing(0)
  }
}))

const StripeKYCDialog = ({ isOpened, onClose, url }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Dialog open={isOpened} onClose={onClose}>
      <DialogHeader title={t('We are almost there!')} />
      <DialogContent className={classes.noPaddingTop}>
        <Typography className={classes.text}>
          {t(
            'You need to set up some details required to enable payouts to your company.'
          )}
        </Typography>
        <Typography className={classes.text}>
          {t(
            'Obligations require Doinn to collect and maintain information on all account holders. These requirements come from our regulators and are intended to prevent abuse of the financial system.'
          )}
        </Typography>
        <Typography className={classes.text}>
          {t(
            'By clicking on the button below, you will be directed to an external website, where you will define your additional information to enable payouts. It is quick and easy!'
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose}>
          {t('Do it later')}
        </Button>
        <Button variant='contained' href={url}>
          {t('Do it right now')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default StripeKYCDialog
