import React, { useCallback, useMemo } from 'react'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core'
import { isEmpty } from 'lodash'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  HUBSPOT_DEMO_ES,
  HUBSPOT_DEMO_IT,
  HUBSPOT_DEMO_PT,
  HUBSPOT_DEMO_ROW,
  HUBSPOT_DEMO_US
} from 'config/env'
import {
  close,
  onboardingDemo
} from '@doinn/shared/src/containers/onboarding/demo/actions'
import { STATUS } from '@doinn/shared/src/containers/onboarding/demo/reducers'
import Dialog from '@doinn/shared/src/components/common/dialog/Dialog'
import DialogHeader from '@doinn/shared/src/components/common/dialog/DialogHeader'
import ButtonWithLoading from '@doinn/shared/src/components/common/ButtonWithLoading'

const useStyles = makeStyles(theme => {
  return {
    dialogContent: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0)
    },
    header: {
      paddingBottom: theme.spacing(0),
      marginBottom: theme.spacing(0)
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-start'
    },
    iframe: {
      '& iframe': {
        height: '605px !important'
      }
    },
    title: {
      fontSize: theme.typography.pxToRem(36)
    }
  }
})

const DemoDialog = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { loggedUser } = useSelector(state => state.user)
  const { isOpened, status, type } = useSelector(state => state.onboardingDemo)
  const { data: userLoggedInAs } = useSelector(state => state.userLoggedInAs)

  const isSaving = status === STATUS.LOADING

  const hubspotDemoUrl = useMemo(() => {
    const countryCode = loggedUser?.country?.code || 'US'

    switch (countryCode) {
      case 'ES':
        return HUBSPOT_DEMO_ES
      case 'PT':
        return HUBSPOT_DEMO_PT
      case 'IT':
        return HUBSPOT_DEMO_IT
      case 'US':
        return HUBSPOT_DEMO_US
      default:
    }

    return HUBSPOT_DEMO_ROW
  }, [loggedUser?.country?.code])

  const headerProps = useCallback(() => {
    if (type === 'saas-announcement') {
      return {
        title: (
          <Typography component='div' className={classes.title}>
            <b>{t('Find out what’s new on the Doinn platform.')}</b>
          </Typography>
        ),
        subtitle: (
          <>
            <Typography component='div' variant='body1'>
              {t(
                'Pick a time slot and schedule your call with a Doinn’s platform expert'
              )}
            </Typography>
            <Box py={2}>
              <Divider />
            </Box>
          </>
        )
      }
    }

    return {
      title: (
        <Typography component='div' className={classes.title}>
          <b>{t('Fast-track your onboarding with a kick-off call')}</b>
        </Typography>
      ),
      subtitle: (
        <Box pb={2} pt={1}>
          <Divider />
        </Box>
      )
    }
  }, [classes, t, type])

  const handleClose = useCallback(
    (event, reason) => {
      if (reason && reason === 'backdropClick') return
      dispatch(close())
    },
    [dispatch]
  )

  const handleClick = useCallback(() => {
    dispatch(onboardingDemo({ type }))
  }, [dispatch])

  const handleCloseClick = useCallback(() => {
    dispatch(close())
  }, [dispatch])

  if (!loggedUser?.country?.code) return null

  return (
    <Dialog open={isOpened} onClose={handleClose} disableEscapeKeyDown>
      <Helmet>
        <script
          type='text/javascript'
          src='https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
        ></script>
      </Helmet>
      <DialogHeader classname={classes.header} {...headerProps()} />
      <DialogContent className={classes.dialogContent}>
        <div
          className={`${classes.iframe} meetings-iframe-container`}
          data-src={hubspotDemoUrl}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        {!isEmpty(userLoggedInAs) && (
          <Button variant='outlined' onClick={handleCloseClick}>
            {t('Close')}
          </Button>
        )}
        <Box>
          <ButtonWithLoading
            aria-label={t('Continue')}
            variant='contained'
            color='primary'
            edge='end'
            fullWidth
            isLoading={isSaving}
            disabled={isSaving}
            onClick={handleClick}
          >
            {t('Continue')}
          </ButtonWithLoading>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default DemoDialog
