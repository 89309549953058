import { each, isArray, snakeCase } from 'lodash'
import i18n from '@doinn/shared/src/config/i18n'

const FULL_SETUP_STEPS_SLUGS = {
  place: 'place',
  // company_type: 'company_type',
  company_size: 'company_size',
  company_name: 'company_name',
  customer_properties: 'customer_properties',
  customer_to_do: 'customer_to_do',
  customer_services: 'customer_services',
  customer_automated_workflow: 'customer_automated_workflow',
  provider_properties: 'provider_properties',
  provider_to_do: 'provider_to_do',
  provider_services: 'provider_services'
}

const customerSetup = [
  FULL_SETUP_STEPS_SLUGS.customer_properties,
  FULL_SETUP_STEPS_SLUGS.customer_to_do,
  FULL_SETUP_STEPS_SLUGS.customer_services,
  FULL_SETUP_STEPS_SLUGS.customer_automated_workflow
]
const providerSetup = [
  FULL_SETUP_STEPS_SLUGS.provider_properties,
  FULL_SETUP_STEPS_SLUGS.provider_to_do,
  FULL_SETUP_STEPS_SLUGS.provider_services
]

const fullSetupQuestions = () => ({
  [FULL_SETUP_STEPS_SLUGS.place]: {
    key: 'place',
    title: i18n.t('What is the main city of operations of your company?'),
    options: [],
    name: 'place',
    optionsType: 'city'
  },
  // [FULL_SETUP_STEPS_SLUGS.company_type]: {
  //   key: 'How do you better describe your company?',
  //   title: i18n.t('How do you better describe your company?'),
  //   options: [
  //     {
  //       key: 'Manage/Operate properties',
  //       description: i18n.t('Manage/Operate properties'),
  //       value: 'companyType-1'
  //     },
  //     {
  //       key: 'Housekeeping/Cleaning Company',
  //       description: i18n.t('Housekeeping/Cleaning Company'),
  //       value: 'companyType-2'
  //     },
  //     {
  //       key: 'Other services providers such as laundry or maintenance companies',
  //       description: i18n.t(
  //         'Other services providers such as laundry or maintenance companies'
  //       ),
  //       value: 'companyType-3'
  //     }
  //   ],
  //   name: 'companyType',
  //   optionsType: 'radio'
  // },
  [FULL_SETUP_STEPS_SLUGS.company_size]: {
    key: 'What is the size of your company?',
    title: i18n.t('What is the size of your company?'),
    options: [
      {
        key: '1',
        description: i18n.t('{{range}} person', { range: '1' }),
        value: 'companySize-1'
      },
      {
        key: '2-5',
        description: i18n.t('{{range}} people', { range: '2-5' }),
        value: 'companySize-2'
      },
      {
        key: '6-10',
        description: i18n.t('{{range}} people', { range: '6-10' }),
        value: 'companySize-3'
      },
      {
        key: '11-25',
        description: i18n.t('{{range}} people', { range: '11-25' }),
        value: 'companySize-4'
      },
      {
        key: '26-49',
        description: i18n.t('{{range}} people', { range: '26-49' }),
        value: 'companySize-5'
      },
      {
        key: '50+',
        description: i18n.t('{{range}} people', { range: '50+' }),
        value: 'companySize-6'
      }
    ],
    name: 'companySize',
    optionsType: 'radio'
  },
  [FULL_SETUP_STEPS_SLUGS.company_name]: {
    key: 'companyName',
    title: i18n.t('Company name'),
    options: [],
    name: 'companyName',
    optionsType: 'text'
  },
  [FULL_SETUP_STEPS_SLUGS.customer_properties]: {
    key: 'What is the number of properties your company manage/operates?',
    title: i18n.t(
      'What is the number of properties your company manage/operates?'
    ),
    options: [
      {
        key: '1-2',
        description: '1-2',
        value: '1-2'
      },
      {
        key: '3-10',
        description: '3-10',
        value: '3-10'
      },
      {
        key: '11-50',
        description: '11-50',
        value: '11-50'
      },
      {
        key: '51-200',
        description: '51-200',
        value: '51-200'
      },
      {
        key: '201-500',
        description: '201-500',
        value: '201-500'
      },
      {
        key: '500+',
        description: '500+',
        value: '500+'
      }
    ],
    name: 'numberProperties',
    optionsType: 'radio'
  },
  [FULL_SETUP_STEPS_SLUGS.customer_to_do]: {
    key: 'What would you like to do with Doinn? You can select multiple choices.',
    title: i18n.t(
      'What would you like to do with Doinn? You can select multiple choices.'
    ),
    options: [
      {
        key: 'Professional Cleaning Outsourcing',
        description: i18n.t('Professional Cleaning Outsourcing'),
        value: 'marketplace',
        removeInvited: true
      },
      {
        key: 'Organize my internal teams more efficiently',
        description: i18n.t('Organize my internal teams more efficiently'),
        value: 'members'
      },
      {
        key: 'Organize better all service providers and automate their jobs in one platform',
        description: i18n.t(
          'Organize better all service providers and automate their jobs in one platform'
        ),
        value: 'externalTeams'
      }
    ],
    name: 'likeToDo',
    optionsType: 'checkbox'
  },
  [FULL_SETUP_STEPS_SLUGS.customer_services]: {
    key: 'What is the number of services per month you are planning to operate in Doinn platform (approximately)',
    title: i18n.t(
      'What is the number of services per month you are planning to operate in Doinn platform (approximately)'
    ),
    options: [
      {
        key: '0-10',
        description: i18n.t('{{range}} services', { range: '0-10' }),
        value: '0-10'
      },
      {
        key: '10-100',
        description: i18n.t('{{range}} services', { range: '10-100' }),
        value: '10-100'
      },
      {
        key: '100-1000',
        description: i18n.t('{{range}} services', { range: '100-1000' }),
        value: '100-1000'
      },
      {
        key: '1000+',
        description: i18n.t('{{range}} services', { range: '1000+' }),
        value: '1000+'
      }
    ],
    name: 'servicesPerMonth',
    optionsType: 'radio'
  },
  [FULL_SETUP_STEPS_SLUGS.customer_automated_workflow]: {
    key: 'Do you need to automate workflows based in hospitality reservations or recurrent dates? You can select multiple choices.',
    title: i18n.t(
      'Do you need to automate workflows based in hospitality reservations or recurrent dates? You can select multiple choices.'
    ),
    options: [
      {
        key: 'Reservations',
        description: i18n.t('Reservations'),
        value: 'reservation'
      },
      {
        key: 'Recurrent',
        description: i18n.t('Recurrent'),
        value: 'recurrency'
      }
    ],
    name: 'automatedWorkflow',
    optionsType: 'checkbox'
  },
  [FULL_SETUP_STEPS_SLUGS.provider_properties]: {
    key: 'What is the number of properties your company provide services to?',
    title: i18n.t(
      'What is the number of properties your company provide services to?'
    ),
    options: [
      {
        key: '1-10',
        description: '1-10',
        value: '1-10'
      },
      {
        key: '11-50',
        description: '11-50',
        value: '11-50'
      },
      {
        key: '51-200',
        description: '51-200',
        value: '51-200'
      },
      {
        key: '201-500',
        description: '201-500',
        value: '201-500'
      },
      {
        key: '500+',
        description: '500+',
        value: '500+'
      }
    ],
    name: 'numberProperties',
    optionsType: 'radio'
  },
  [FULL_SETUP_STEPS_SLUGS.provider_to_do]: {
    key: 'What would you like to do with Doinn? You can select multiple choices.',
    title: i18n.t(
      'What would you like to do with Doinn? You can select multiple choices.'
    ),
    options: [
      {
        key: 'Go digital & Sell more services by having Doinn sending me customers',
        description: i18n.t(
          'Go digital & Sell more services by having Doinn sending me customers'
        ),
        value: 'marketplace'
      },
      {
        key: 'Organize my internal teams more efficiently',
        description: i18n.t('Organize my internal teams more efficiently'),
        value: 'members'
      },
      {
        key: 'Get all customers and jobs automatically scheduled in one platform',
        description: i18n.t(
          'Get all customers and jobs automatically scheduled in one platform'
        ),
        value: 'externalTeams'
      }
    ],
    name: 'likeToDo',
    optionsType: 'checkbox'
  },
  [FULL_SETUP_STEPS_SLUGS.provider_services]: {
    key: 'What is the number of services per month you are planning to operate in Doinn platform (approximately)',
    title: i18n.t(
      'What is the number of services per month you are planning to operate in Doinn platform (approximately)'
    ),
    options: [
      {
        key: '0-10',
        description: i18n.t('{{range}} services', { range: '0-10' }),
        value: '0-10'
      },
      {
        key: '10-100',
        description: i18n.t('{{range}} services', { range: '10-100' }),
        value: '10-100'
      },
      {
        key: '100-1000',
        description: i18n.t('{{range}} services', { range: '100-1000' }),
        value: '100-1000'
      },
      {
        key: '1000+',
        description: i18n.t('{{range}} services', { range: '1000+' }),
        value: '1000+'
      }
    ],
    name: 'servicesPerMonth',
    optionsType: 'radio'
  }
})

const getUserSetupByType = type => {
  const options = {
    customer: customerSetup,
    provider: providerSetup
  }

  if (type in options) {
    return options[type]
  }

  return options.customer
}

export const getInitialSetup = type => {
  const userSetup = getUserSetupByType(type)
  const fullSetupOptions = fullSetupQuestions()
  const setupSteps = [
    [FULL_SETUP_STEPS_SLUGS.place, FULL_SETUP_STEPS_SLUGS.company_size],
    [...userSetup],
    [FULL_SETUP_STEPS_SLUGS.company_name]
  ]

  const setupOptions = setupSteps.reduce(
    (acc, step, index) => [
      ...acc,
      ...step.map(slug => ({
        ...fullSetupOptions[slug],
        step: index + 1
      }))
    ],
    []
  )

  /**
   * If needed add initial values for each option or add logic to check other values
   */
  const initialValues = setupOptions.reduce((acc, option) => {
    let initialValue

    switch (option.optionsType) {
      case 'checkbox':
        initialValue = []
        break
      case 'city': {
        initialValue = {}
        break
      }
      default:
        initialValue = ''
    }

    return { ...acc, [option.name]: initialValue }
  }, {})

  return {
    numberOfSteps: setupSteps.length,
    options: setupOptions,
    initialValues: {
      step: 0,
      ...initialValues
    }
  }
}

export const getMetadataFromQuestions = (questionsArray, anwsers) => {
  const metadata = []
  let question = null
  let values = null
  let metaAwnsers = null
  let option = null
  each(anwsers, (val, key) => {
    question = questionsArray.find(q => q.name === key)
    values = isArray(val) ? val : [val]
    metaAwnsers = []
    values.forEach(value => {
      option = question.options.find(option => option.value === value)
      if (option) {
        metaAwnsers.push({
          answer: option.key,
          answer_slug: snakeCase(option.value)
        })
      }
    })
    metadata.push({
      question: question.key,
      question_slug: snakeCase(question.name),
      answers: metaAwnsers
    })
  })
  return metadata
}
