import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function getEmployeeApi(id) {
  const config = {}
  return DoinnApi.get(`/vendor/employees/${id}`, config)
}

export function addEmployeeApi(employee) {
  const config = {}
  return DoinnApi.post(`/vendor/employees`, employee, config)
}

export function editEmployeeApi(employee) {
  const config = {}
  return DoinnApi.put(`/vendor/employees/${employee.id}`, employee, config)
}

export function editEmployeeImageApi(employee) {
  const request = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  const formData = new window.FormData()
  const file = employee.profile_image
  if (file) {
    formData.append('profile_image', file)
  }
  return DoinnApi.post(
    `/vendor/employees/${employee.id}/image`,
    formData,
    request
  )
}

export function deleteEmployeeApi(id) {
  const config = {}
  return DoinnApi.delete(`/vendor/employees/${id}`, config)
}
