import { all, call, put, takeLatest } from 'redux-saga/effects'
import { copyCatalogueTemplateApi } from '@doinn/shared/src/api/catalogues'
import {
  CATALOGUE_COPY_FAILED,
  CATALOGUE_COPY_REQUESTED,
  CATALOGUE_COPY_SUCCEEDED
} from '@doinn/shared/src/containers/catalogue/copy/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import i18n from '@doinn/shared/src/config/i18n'
import { fetchCatalogues as fetchCataloguesAction } from '@doinn/shared/src/containers/catalogue/my/list/actions'

function* copyCatalogue(action) {
  try {
    const { params } = action.payload
    yield call(copyCatalogueTemplateApi, params)
    yield put({
      type: CATALOGUE_COPY_SUCCEEDED,
      payload: params,
      snackbar: {
        variant: 'success',
        message: i18n.t(`"{{name}}" has been created!`, { name: params.name })
      }
    })
  } catch (e) {
    yield put({
      type: CATALOGUE_COPY_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* resetCatalogues() {
  yield put(fetchCataloguesAction())
}

export default function* cataloguesAddSaga() {
  yield all([
    takeLatest(CATALOGUE_COPY_REQUESTED, copyCatalogue),
    takeLatest(CATALOGUE_COPY_SUCCEEDED, resetCatalogues)
  ])
}
