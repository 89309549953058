import {
  JOBS_DISPATCH_DROP_JOB_FAILED,
  JOBS_DISPATCH_DROP_JOB_REQUESTED,
  JOBS_DISPATCH_DROP_JOB_SUCCEEDED,
  JOBS_REPORT_DOWNLOAD_FAILED,
  JOBS_REPORT_DOWNLOAD_REQUESTED,
  JOBS_REPORT_DOWNLOAD_SUCCEEDED
} from 'containers/jobs/dispatch/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  status: STATUS.IDLE,
  isDownloading: false
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case JOBS_DISPATCH_DROP_JOB_REQUESTED:
      return {
        ...state,
        ...initialState,
        status: STATUS.LOADING
      }
    case JOBS_DISPATCH_DROP_JOB_SUCCEEDED:
      return {
        ...state,
        list: [...payload.data],
        status: STATUS.SUCCESS
      }
    case JOBS_DISPATCH_DROP_JOB_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case JOBS_REPORT_DOWNLOAD_REQUESTED:
      return {
        ...state,
        isDownloading: true
      }
    case JOBS_REPORT_DOWNLOAD_SUCCEEDED:
    case JOBS_REPORT_DOWNLOAD_FAILED:
      return {
        ...state,
        isDownloading: false
      }
    default:
      return state
  }
}
