import {
  DASHBOARD_JOBS_FILTER_CHANGED,
  DASHBOARD_STATE_INITIALIZED
} from 'containers/dashboard/constants'

export const initialState = {
  jobsFilter: {
    status: 'all',
    page: 1,
    limit: 5
  }
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case DASHBOARD_JOBS_FILTER_CHANGED:
      return {
        ...state,
        jobsFilter: { ...payload.filter }
      }
    case DASHBOARD_STATE_INITIALIZED:
      return {
        ...state,
        jobsFilter: { ...payload.jobsFilter }
      }
    default:
      return state
  }
}
