import React, { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { BASE_URL } from 'config/env'
import { useSelector } from 'react-redux'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'
import { cloneDeep, isEqual as isObjEqual } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Container, makeStyles } from '@material-ui/core'

import {
  useDidMount,
  useFilterState,
  useOpenState,
  usePrevious
} from '@doinn/shared/src/hooks'
import {
  getQueryStringObject,
  updateURLParams
} from '@doinn/shared/src/util/url'
import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import { parseAppliedFilters } from '@doinn/shared/src/util/filters'
import Icon from '@doinn/shared/src/components/common/Icon'
import Alerts from '@doinn/shared/src/components/app/Alerts'
import MainContent from '@doinn/shared/src/components/app/MainContent'
import HeaderBlank from '@doinn/vendor/src/containers/app/HeaderBlank'
import HeaderCloseButton from '@doinn/shared/src/components/common/dialog/HeaderCloseButton'
import TemplateBlankFullHeight from '@doinn/shared/src/components/templates/TemplateBlankFullHeight'

const CheckoutContainer = loadable(() =>
  lazyRetry(() => import('@doinn/vendor/src/containers/checkout/Checkout'))
)

const defaultFilters = {
  uuid: 'id'
}

const getQueryOrSavedFilters = location => {
  const queryFilters = getQueryStringObject(location.search)
  return parseAppliedFilters(defaultFilters, queryFilters)
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      background: theme.palette.common.white
    }
  }
}))

const Catalogues = () => {
  useStyles()
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const previousLocation = usePrevious(location)
  const [justChangedLocation, setJustChangedLocation] = React.useState(false)
  const {
    appliedFilters,
    hasAppliedFiltersChanges,
    onChangeAppliedFilters,
    onChangeSelectedFilters
  } = useFilterState(cloneDeep(defaultFilters))
  const { justClosed: justClosedFilters, justOpened: justOpenedFilters } =
    useOpenState(false)
  const { data: checkoutData } = useSelector(state => state.checkout)

  useDidMount(() => {
    onChangeAppliedFilters(getQueryOrSavedFilters(location))
  })

  React.useEffect(() => {
    if (!previousLocation) return
    setJustChangedLocation(
      !isObjEqual(location.search, previousLocation.search)
    )
  }, [location, previousLocation])

  React.useEffect(() => {
    if (!justChangedLocation) return
    const savedFilters = getQueryOrSavedFilters(location)
    const shouldUpdateAppliedFilters = !isObjEqual(appliedFilters, savedFilters)
    if (shouldUpdateAppliedFilters) {
      onChangeAppliedFilters(savedFilters)
    }
  }, [appliedFilters, justChangedLocation, location, onChangeAppliedFilters])

  React.useEffect(() => {
    if (justOpenedFilters) {
      onChangeSelectedFilters(appliedFilters)
    }
  }, [appliedFilters, justOpenedFilters, onChangeSelectedFilters])

  React.useEffect(() => {
    if (justClosedFilters) {
      onChangeSelectedFilters(appliedFilters)
    }
  }, [appliedFilters, justClosedFilters, onChangeSelectedFilters])

  React.useEffect(() => {
    const shouldUpdateUrl =
      hasAppliedFiltersChanges &&
      !isObjEqual(appliedFilters, getQueryOrSavedFilters(location))
    if (shouldUpdateUrl) {
      updateURLParams(appliedFilters, history)
    }
  }, [appliedFilters, hasAppliedFiltersChanges, history, location])

  const handleChangeAppliedFilters = useCallback(
    filters => {
      onChangeAppliedFilters(filters)
    },
    [onChangeAppliedFilters]
  )

  const handlePreviousClick = useCallback(() => {
    const previousPathname = location?.state?.previousPathname || '/plans'
    const previousSearch = location?.state?.previousSearch || ''
    history.push({
      pathname: previousPathname,
      search: previousSearch,
      state: {
        ...(location?.state && location?.state)
      }
    })
  }, [history, location])

  const handleCloseClick = useCallback(() => {
    const previousLocation = location?.state?.closePath || '/'
    history.push(previousLocation)
  }, [history, location])

  return (
    <TemplateBlankFullHeight>
      <Helmet>
        <title>{t('Plans')}</title>
      </Helmet>
      <Container maxWidth='lg'>
        <HeaderBlank
          leftChildren={
            checkoutData?.confirmed ? null : (
              <Button
                color='secondary'
                variant='outlined'
                startIcon={<Icon icon='back' />}
                onClick={handlePreviousClick}
              >
                {t('Previous')}
              </Button>
            )
          }
          rightChildren={<HeaderCloseButton onClose={handleCloseClick} />}
        />
        <Alerts header baseUrl={BASE_URL} />
        <MainContent>
          <React.Suspense fallback={<div />}>
            <CheckoutContainer
              type='subscription'
              filters={appliedFilters}
              onChangeFilters={handleChangeAppliedFilters}
            />
          </React.Suspense>
        </MainContent>
      </Container>
    </TemplateBlankFullHeight>
  )
}

export default Catalogues
