import {
  SUBSCRIPTION_ENTERPRISE_QUOTE_FAILED,
  SUBSCRIPTION_ENTERPRISE_QUOTE_REQUESTED,
  SUBSCRIPTION_ENTERPRISE_QUOTE_SUCCEEDED,
  SUBSCRIPTION_PLANS_FETCH_FAILED,
  SUBSCRIPTION_PLANS_FETCH_REQUESTED,
  SUBSCRIPTION_PLANS_FETCH_SUCCEEDED
} from '@doinn/shared/src/containers/subscription/plans/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
  SAVING: 'saving',
  ENTERPRISE_SAVING: 'enterprise_saving',
  ENTERPRISE_SUCCESS: 'enterprise_success',
  ENTERPRISE_FAILURE: 'enterprise_failure'
}

export const initialState = {
  list: [],
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SUBSCRIPTION_PLANS_FETCH_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING
      }
    case SUBSCRIPTION_PLANS_FETCH_SUCCEEDED:
      return {
        ...state,
        list: payload.data,
        status: STATUS.SUCCESS
      }
    case SUBSCRIPTION_PLANS_FETCH_FAILED:
      return {
        ...initialState,
        status: STATUS.FAILURE
      }
    case SUBSCRIPTION_ENTERPRISE_QUOTE_REQUESTED:
      return {
        ...state,
        status: STATUS.ENTERPRISE_SAVING
      }
    case SUBSCRIPTION_ENTERPRISE_QUOTE_SUCCEEDED:
      return {
        ...state,
        status: STATUS.ENTERPRISE_SUCCESS
      }
    case SUBSCRIPTION_ENTERPRISE_QUOTE_FAILED:
      return {
        ...state,
        status: STATUS.ENTERPRISE_FAILURE
      }
    default:
      return state
  }
}
