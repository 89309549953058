import Cookies from 'js-cookie'
import { Storage } from '@capacitor/storage'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { SESSION_DOMAIN, WEB_TOKEN_COOKIE_NAME } from 'config/env'
import { getCurrentAuthTokenApi } from '@doinn/shared/src/api/auth'
import { getLoggedUser } from '@doinn/shared/src/containers/user/actions'
import { setLanguage } from '@doinn/shared/src/containers/language/actions'
import { USER_LOGOUT_SUCCEEDED } from '@doinn/shared/src/containers/user/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import {
  APP_START_FAILED,
  APP_START_REQUESTED,
  APP_START_SUCCEEDED
} from '@doinn/shared/src/containers/app/constants'
import {
  getSavedLogoutLocalStorage,
  saveLogoutLocalStorage
} from '@doinn/vendor/src/containers/app/util'
import { getLanguage } from '@doinn/shared/src/containers/app/util'

function* start(action) {
  try {
    const authToken = yield call(getCurrentAuthTokenApi)

    if (!authToken) {
      yield resetAppStorage()
      yield put({ type: USER_LOGOUT_SUCCEEDED })
    } else {
      yield put(getLoggedUser())
    }

    yield put({ type: APP_START_SUCCEEDED })
  } catch (e) {
    yield put({
      type: APP_START_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export function* resetAppStorage(action) {
  const savedLogoutLocalStorage = getSavedLogoutLocalStorage()
  yield window.localStorage.clear()
  yield window.sessionStorage.clear()
  saveLogoutLocalStorage(savedLogoutLocalStorage)
  yield Storage.clear()
  yield Cookies.remove(WEB_TOKEN_COOKIE_NAME, {
    path: '/',
    domain: `${SESSION_DOMAIN}`
  })

  const language = getLanguage()
  if (language) {
    yield put(setLanguage({ language }))
  }
}

export default function* appSaga() {
  yield all([
    takeLatest(APP_START_REQUESTED, start),
    takeLatest(USER_LOGOUT_SUCCEEDED, resetAppStorage)
  ])
}
