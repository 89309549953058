import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => {
  return {
    link: {
      fontSize: 'inherit',
      lineHeight: 'inherit',
      padding: theme.spacing(0),
      marginTop: theme.spacing(-0.5),
      minWidth: 'auto'
    }
  }
})

function AuthMenu() {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Box>
      <Trans
        defaults={t('Already have an account? <button>Sign in</button>')}
        components={{
          button: (
            <Button
              component={RouterLink}
              className={classes.link}
              color='primary'
              variant='text'
              to='/login'
            />
          )
        }}
      />
    </Box>
  )
}

export default AuthMenu
