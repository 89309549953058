import { fade } from '@material-ui/core/styles'

const colors = {
  primary: '#6f5eff',
  secondary: '#4c4c4e', // rgb(76, 76, 78)
  error: '#dc362e',
  warning: '#ff9800',
  info: '#2196f3',
  success: '#4caf50',
  high: '#f04b4b'
}

const opacity = {
  shadow: 0.5,
  shadowDark: 0.85
}

const palette = {
  primary: {
    main: colors.primary,
    contrastText: 'rgba(255, 255, 255, 0.87)',
    boxShadow: fade(colors.primary, opacity.shadow),
    boxShadowDark: fade(colors.primary, opacity.shadowDark)
  },
  secondary: {
    main: colors.secondary,
    boxShadow: fade(colors.secondary, opacity.shadow),
    boxShadowDark: fade(colors.secondary, opacity.shadowDark)
  },
  dark: {
    text: {
      primary: 'rgba(255, 255, 255, 0.87)',
      secondary: 'rgba(255, 255, 255, 0.54)',
      disabled: 'rgba(255, 255, 255, 0.38)',
      hint: 'rgba(255, 255, 255, 0.38)'
    },
    divider: 'rgba(255, 255, 255, 0.12)',
    action: {
      active: 'rgba(255, 255, 255, 0.54)',
      hover: 'rgba(255, 255, 255, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(255, 255, 255, 0.14)',
      disabled: 'rgba(255, 255, 255, 0.26)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)'
    }
  },
  error: {
    main: colors.error
  },
  warning: {
    main: colors.warning
  },
  info: {
    main: colors.info
  },
  success: {
    main: colors.success
  },
  text: {
    primary: 'rgba(0, 0, 2, 0.7)',
    secondary: 'rgba(0, 0, 2, 0.54)',
    disabled: 'rgba(0, 0, 2, 0.38)',
    hint: 'rgba(0, 0, 2, 0.38)',
    caption: 'rgba(141, 141, 142, 1)',
    canceled: '#8e8e8e'
  },
  background: {
    default: '#f8f8f8',
    canceled: '#fbfbfb',
    high: '#fef6f6',
    dark: '#1b1464'
  },
  priority: {
    high: colors.high
  },
  alert: {
    text: {
      primary: '#333333'
    },
    background: {
      primary: '#ffe2ad'
    }
  },
  status: {
    warning: {
      text: {
        primary: '#ad7100'
      },
      background: {
        primary: '#ffe2ad'
      }
    },
    ready: {
      text: {
        primary: '#00601b'
      },
      background: {
        primary: '#b2f0da'
      }
    }
  }
}

export default palette
