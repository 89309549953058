import { all, call, put, takeLatest } from 'redux-saga/effects'
import { activateAutomationApi } from '@doinn/host/src/api/automations'
import {
  AUTOMATIONS_ACTIVATE_FAILED,
  AUTOMATIONS_ACTIVATE_REQUESTED,
  AUTOMATIONS_ACTIVATE_SUCCEEDED
} from '@doinn/host/src/containers/automations/list/activate/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* activateAutomation(action) {
  try {
    yield call(activateAutomationApi, action.payload.params)
    yield put({
      type: AUTOMATIONS_ACTIVATE_SUCCEEDED,
      payload: action.payload
    })
  } catch (e) {
    yield put({
      type: AUTOMATIONS_ACTIVATE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* automationsActivateSaga() {
  yield all([takeLatest(AUTOMATIONS_ACTIVATE_REQUESTED, activateAutomation)])
}
