import { all, call, put, takeLatest } from 'redux-saga/effects'
import { renameIntegrationApi } from '@doinn/host/src/api/integrations'
import {
  INTEGRATIONS_RENAME_FAILED,
  INTEGRATIONS_RENAME_REQUESTED,
  INTEGRATIONS_RENAME_SUCCEEDED
} from '@doinn/host/src/containers/integrations/edit/rename/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* renameIntegration(action) {
  try {
    const response = yield call(renameIntegrationApi, action.payload)
    yield put({
      type: INTEGRATIONS_RENAME_SUCCEEDED,
      payload: response.data.data
    })
  } catch (e) {
    yield put({
      type: INTEGRATIONS_RENAME_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* integrationsRenameSaga() {
  yield all([takeLatest(INTEGRATIONS_RENAME_REQUESTED, renameIntegration)])
}
