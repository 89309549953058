import {
  INVOICES_FETCH_FAILED,
  INVOICES_FETCH_MORE_FAILED,
  INVOICES_FETCH_MORE_REQUESTED,
  INVOICES_FETCH_MORE_SUCCEEDED,
  INVOICES_FETCH_REQUESTED,
  INVOICES_FETCH_SUCCEEDED,
  INVOICE_EXPORT_FAILED,
  INVOICE_EXPORT_REQUESTED,
  INVOICE_EXPORT_SUCCEEDED,
  INVOICE_UPLOAD_FAILED,
  INVOICE_UPLOAD_REQUESTED,
  INVOICE_UPLOAD_SUCCEEDED
} from '@doinn/shared/src/containers/invoice/sent/constants'

export const initialState = {
  list: [],
  meta: {},
  isLoading: false,
  isFailed: false,
  loadingItem: null
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case INVOICES_FETCH_REQUESTED:
      return {
        ...state,
        ...initialState,
        isLoading: true,
        isFailed: false
      }
    case INVOICES_FETCH_SUCCEEDED:
      return {
        ...state,
        list: [...payload.data],
        meta: { ...payload.meta },
        isLoading: false,
        isFailed: false
      }
    case INVOICES_FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        isFailed: true
      }
    case INVOICES_FETCH_MORE_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isFailed: false
      }
    case INVOICES_FETCH_MORE_SUCCEEDED:
      return {
        ...state,
        list: [...state.list, ...payload.data],
        meta: { ...payload.meta },
        isLoading: false,
        isFailed: false
      }
    case INVOICES_FETCH_MORE_FAILED:
      return {
        ...state,
        isLoading: false,
        isFailed: true
      }
    case INVOICE_UPLOAD_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isFailed: false
      }
    case INVOICE_UPLOAD_SUCCEEDED:
      return {
        ...state,
        list: state.list.map((item, index) => {
          if (item.id === payload.data.id) {
            return {
              ...item,
              status: payload.data.status,
              fileUrl: payload.data.fileUrl,
              uploadedAt: payload.data.uploadedAt
            }
          }
          return item
        }),
        isLoading: false,
        isFailed: false
      }
    case INVOICE_UPLOAD_FAILED:
      return {
        ...state,
        isLoading: false,
        isFailed: true
      }
    case INVOICE_EXPORT_REQUESTED:
      return {
        ...state,
        loadingItem: payload?.params?.invoice?.id
      }
    case INVOICE_EXPORT_SUCCEEDED:
      return {
        ...state,
        loadingItem: null
      }
    case INVOICE_EXPORT_FAILED:
      return {
        ...state,
        isLoading: false,
        isFailed: true,
        loadingItem: null
      }
    default:
      return state
  }
}
