export const getHelperLink = (location, language) => {
  const links = {
    'en-US': {
      dashboard: 'https://help.doinn.co/en/knowledge/dashboard-menu',
      services: 'https://help.doinn.co/en/knowledge/services-menu',
      reservations: 'https://help.doinn.co/en/knowledge/reservations-menu',
      properties: 'https://help.doinn.co/en/knowledge/properties-menu',
      staff: 'https://help.doinn.co/en/knowledge/people-menu',
      checklists: 'https://help.doinn.co/en/knowledge/checklists-menu',
      insights: 'https://help.doinn.co/en/knowledge/insights-menu',
      invoices: 'https://help.doinn.co/en/knowledge/invoices-menu',
      reports: 'https://help.doinn.co/en/knowledge/reports-menu',
      messages: 'https://help.doinn.co/en/knowledge/messages-menu',
      integrations: 'https://help.doinn.co/en/knowledge/integrations-menu',
      automations: 'https://help.doinn.co/en/knowledge/automations-menu',
      catalogues: 'https://help.doinn.co/en/knowledge/catalogs-menu'
    },
    'es-ES': {
      dashboard:
        'https://help.doinn.co/es/knowledge/men%C3%BA-dashboard-gu%C3%ADa-del-usuario',
      services:
        'https://help.doinn.co/es/knowledge/men%C3%BA-servicios-manual-del-usuario',
      reservations: 'https://help.doinn.co/es/knowledge/reservas-men%C3%BA',
      properties: 'https://help.doinn.co/es/knowledge/propiedades-men%C3%BA',
      staff: 'https://help.doinn.co/es/knowledge/personas-men%C3%BA',
      checklists: 'https://help.doinn.co/es/knowledge/checklist-men%C3%BA',
      insights: 'https://help.doinn.co/es/knowledge/informes-men%C3%BA-1',
      invoices: 'https://help.doinn.co/es/knowledge/facturas-men%C3%BA',
      reports: 'https://help.doinn.co/es/knowledge/informes-men%C3%BA-1',
      messages: 'https://help.doinn.co/es/knowledge/mensajes-men%C3%BA',
      integrations:
        'https://help.doinn.co/es/knowledge/integraciones-men%C3%BA',
      automations:
        'https://help.doinn.co/es/knowledge/automatizaciones-men%C3%BA',
      catalogues: 'https://help.doinn.co/es/knowledge/cat%C3%A1logos-men%C3%BA'
    },
    'pt-PT': {
      dashboard:
        'https://help.doinn.co/pt-pt/knowledge/menu-dashboard-guia-de-utilizador',
      services:
        'https://help.doinn.co/pt-pt/knowledge/menu-serviços-manual-de-utilizador',
      reservations: 'https://help.doinn.co/pt-pt/knowledge/menu-reservas',
      properties: 'https://help.doinn.co/pt-pt/knowledge/menu-propriedades',
      staff: 'https://help.doinn.co/pt-pt/knowledge/menu-pessoas',
      checklists: 'https://help.doinn.co/pt-pt/knowledge/menu-checklists',
      insights: 'https://help.doinn.co/pt-pt/knowledge/menu-indicadores',
      invoices: 'https://help.doinn.co/pt-pt/knowledge/menu-facturas',
      reports: 'https://help.doinn.co/pt-pt/knowledge/menu-relat%C3%B3rios',
      messages: 'https://help.doinn.co/pt-pt/knowledge/menu-mensagens',
      integrations:
        'https://help.doinn.co/pt-pt/knowledge/menu-integra%C3%A7%C3%B5es',
      automations:
        'https://help.doinn.co/pt-pt/knowledge/menu-automatiza%C3%A7%C3%B5es',
      catalogues: 'https://help.doinn.co/pt-pt/knowledge/menu-cat%C3%A1logos'
    },
    'it-IT': {
      dashboard: 'https://help.doinn.co/it/knowledge/dashboard-en-italiano',
      services:
        'https://help.doinn.co/it/knowledge/menù-servizi-manuale-utente',
      reservations: 'https://help.doinn.co/it/knowledge/menu-prenotazioni',
      properties: 'https://help.doinn.co/it/knowledge/menu-propriet%C3%A0',
      staff: 'https://help.doinn.co/it/knowledge/menu-persone',
      checklists: 'https://help.doinn.co/it/knowledge/menu-checklist',
      insights: 'https://help.doinn.co/it/knowledge/menu-approfondimenti',
      invoices: 'https://help.doinn.co/it/knowledge/men%C3%B9-fatture',
      reports: 'https://help.doinn.co/it/knowledge/menu-report',
      messages: 'https://help.doinn.co/it/knowledge/menu-messaggi',
      integrations: 'https://help.doinn.co/it/knowledge/menu-integrazioni',
      automations: 'https://help.doinn.co/it/knowledge/menu-automazioni',
      catalogues: 'https://help.doinn.co/it/knowledge/menu-listini'
    }
  }
  return links?.[language]?.[location] ?? links?.['en-US']?.[location] ?? null
}
