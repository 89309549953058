import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  acceptInvitationApi,
  refuseInvitationApi
} from '@doinn/vendor/src/api/membersInvitations'
import {
  INVITATIONS_ANSWER_ACCEPT_FAILED,
  INVITATIONS_ANSWER_ACCEPT_REQUESTED,
  INVITATIONS_ANSWER_ACCEPT_SUCCEEDED,
  INVITATIONS_ANSWER_REFUSE_FAILED,
  INVITATIONS_ANSWER_REFUSE_REQUESTED,
  INVITATIONS_ANSWER_REFUSE_SUCCEEDED
} from '@doinn/vendor/src/containers/member/invitation/answer/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import i18n from '@doinn/shared/src/config/i18n'

function* refuseInvitation(action) {
  try {
    const { id } = action.payload.params
    yield call(refuseInvitationApi, id)
    yield put({
      type: INVITATIONS_ANSWER_REFUSE_SUCCEEDED,
      payload: action.payload,
      message: i18n.t('The invite has been refused!')
    })
  } catch (e) {
    yield put({
      type: INVITATIONS_ANSWER_REFUSE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* acceptInvitation(action) {
  try {
    const { id } = action.payload.params
    yield call(acceptInvitationApi, id)
    yield put({
      type: INVITATIONS_ANSWER_ACCEPT_SUCCEEDED,
      payload: action.payload,
      message: i18n.t('The invite has been accepted!')
    })
  } catch (e) {
    yield put({
      type: INVITATIONS_ANSWER_ACCEPT_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* invitationsAnswerSagas() {
  yield all([
    takeLatest(INVITATIONS_ANSWER_REFUSE_REQUESTED, refuseInvitation),
    takeLatest(INVITATIONS_ANSWER_ACCEPT_REQUESTED, acceptInvitation)
  ])
}
