import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import i18n from '@doinn/shared/src/config/i18n'
import Select from '@doinn/shared/src/components/common/filter/Select'
import RadioList from '@doinn/shared/src/components/common/filter/RadioList'

export const sourcesWithTaxesIncluded = ['customer', 'provider', 'own']
export const getSourceOptionsFromBusinessIntentions = (
  businessIntentions,
  reportOf
) => {
  let options = []
  if (reportOf === 'customers' && businessIntentions?.sell) {
    options = []

    if (businessIntentions?.sell?.customer) {
      options.push({
        id: 'customer',
        name: i18n.t('My customers')
      })
    }

    if (businessIntentions?.sell?.internal) {
      options.push({
        id: 'own',
        name: i18n.t('My own services')
      })
    }

    if (businessIntentions?.sell?.doinn) {
      options.push({
        id: 'doinn',
        name: i18n.t('Doinn customers')
      })
    }
  }

  if (reportOf === 'providers' && businessIntentions?.buy) {
    options = []

    if (businessIntentions?.buy?.provider) {
      options.push({
        id: 'provider',
        name: i18n.t('My providers')
      })
    }

    if (businessIntentions?.buy?.internal) {
      options.push({
        id: 'own',
        name: i18n.t('My own services')
      })
    }

    if (businessIntentions?.buy?.doinn) {
      options.push({
        id: 'doinn',
        name: i18n.t('Doinn providers')
      })
    }
  }

  return options
}

const ListFiltersGroupBy = ({
  filters,
  disabled,
  businessIntentions,
  onChangeFilters,
  groupOptions,
  variant
}) => {
  const { t } = useTranslation()
  const parsedGroupOptions =
    groupOptions ||
    getSourceOptionsFromBusinessIntentions(businessIntentions, filters.reportOf)

  const handleChangeFilter = useCallback(
    (filterName, newValue) => {
      const params = cloneDeep({
        ...filters,
        page: 1
      })
      params[filterName] = newValue
      onChangeFilters && onChangeFilters(params)
    },
    [filters, onChangeFilters]
  )

  const renderListVariant = () => {
    return (
      <RadioList
        subheader={t('Source')}
        filterName='sourceServicesBy'
        items={parsedGroupOptions}
        checkedItems={filters.sourceServicesBy}
        onChange={handleChangeFilter}
      />
    )
  }

  const renderMenuVariant = () => {
    return (
      <React.Fragment>
        <Typography variant='body2' gutterBottom>
          {t('Source')}
        </Typography>
        <Select
          disabled={disabled}
          filterOptions={parsedGroupOptions}
          selectedFilter={filters.sourceServicesBy}
          buttonLabel={t('Source')}
          filterName='sourceServicesBy'
          onChange={handleChangeFilter}
        />
      </React.Fragment>
    )
  }

  if (variant === 'list') return renderListVariant()
  return renderMenuVariant()
}

ListFiltersGroupBy.propTypes = {
  variant: PropTypes.oneOf(['list', 'menu']),
  groupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  filters: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    period: PropTypes.string
  }),
  onChangeFilters: PropTypes.func
}

ListFiltersGroupBy.defaultProps = {
  variant: 'menu'
}

export default ListFiltersGroupBy
