import React from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { BASE_URL } from 'config/env'
import { Box, Container, makeStyles } from '@material-ui/core'

import Header from 'containers/app/Header'
import theme from '@doinn/shared/src/config/theme'
import StripeKYC from 'containers/stripe-kyc/Warning'
import MainMenu from 'containers/app/components/MainMenu'
import Alerts from '@doinn/shared/src/components/app/Alerts'
import Banner from '@doinn/shared/src/containers/banner/Banner'
import useAppDetector from '@doinn/shared/src/hooks/useAppDetector'
import BottomMainMenu from 'containers/app/components/BottomMainMenu'
import MainContent from '@doinn/shared/src/components/app/MainContent'
import TemplateBlank from '@doinn/shared/src/components/templates/TemplateBlank'

const useStyles = makeStyles(theme => ({
  container: {
    minWidth: 0
  },
  appBottomMainMenuSpacer: {
    height: `calc(env(safe-area-inset-bottom, 0) + ${theme.spacing(7)}px)`
  }
}))

const TemplateDefault = ({
  children,
  filtersContent,
  helperContent,
  header,
  title,
  containerClassName
}) => {
  const classes = useStyles()
  const isApp = useAppDetector()
  const { show: showBanner } = useSelector(state => state.banner)
  const isSite = !isApp
  const isBannerVisible = showBanner && isSite
  const [isMobileMainMenuOpened, setMobileMainMenuOpened] =
    React.useState(false)

  const handleToggleMainMenu = () => {
    setMobileMainMenuOpened(!isMobileMainMenuOpened)
  }

  return (
    <TemplateBlank>
      <div
        style={{
          marginTop: isBannerVisible ? theme.spacing(7) : theme.spacing(0)
        }}
      >
        <Helmet defaultTitle='Doinn' titleTemplate='%s - Doinn'>
          <meta charSet='utf-8' />
        </Helmet>
        {isBannerVisible && <Banner />}
        <Box display='flex'>
          {isSite && (
            <MainMenu
              isMobileMenuOpened={isMobileMainMenuOpened}
              onToggleMainMenu={handleToggleMainMenu}
              isBannerVisible={isBannerVisible}
            />
          )}
          <Container
            maxWidth='lg'
            className={`${classes.container} ${containerClassName || ''}`}
          >
            <Alerts
              header
              baseUrl={BASE_URL}
              isBannerVisible={isBannerVisible}
            />
            <Header
              filtersContent={filtersContent}
              title={title}
              onToggleMainMenu={handleToggleMainMenu}
              isBannerVisible={isBannerVisible}
            >
              {header}
            </Header>
            {helperContent && helperContent}
            <MainContent>
              <StripeKYC />
              {children}
            </MainContent>
            {isApp && (
              <React.Fragment>
                <div
                  id='appBottomMainMenuSpacer'
                  className={classes.appBottomMainMenuSpacer}
                />
                <BottomMainMenu />
              </React.Fragment>
            )}
          </Container>
        </Box>
      </div>
    </TemplateBlank>
  )
}

export default TemplateDefault
