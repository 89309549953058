import {
  ONBOARDING_SURVEY_DIALOG_CLOSE,
  ONBOARDING_SURVEY_DIALOG_OPEN,
  ONBOARDING_SURVEY_FAILED,
  ONBOARDING_SURVEY_REQUESTED,
  ONBOARDING_SURVEY_SUCCEEDED
} from '@doinn/shared/src/containers/onboarding/survey/constants'
import { USER_GET_SUCCEEDED } from '@doinn/shared/src/containers/user/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  isOpened: false,
  status: STATUS.IDLE
}

const getIsOpened = ({ setupOnboarding = null }) =>
  setupOnboarding?.status === 'pending' &&
  !!setupOnboarding?.steps.find(
    step => step?.slug === 'survey' && !step?.completed
  )

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case USER_GET_SUCCEEDED:
      return {
        ...initialState,
        isOpened: getIsOpened(payload?.data?.business)
      }
    case ONBOARDING_SURVEY_DIALOG_OPEN:
      return {
        ...state,
        isOpened: true
      }
    case ONBOARDING_SURVEY_DIALOG_CLOSE:
      return {
        ...state,
        isOpened: false
      }
    case ONBOARDING_SURVEY_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING
      }
    case ONBOARDING_SURVEY_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case ONBOARDING_SURVEY_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
