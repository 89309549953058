import { array, number, object, string } from 'yup'
import i18n from '@doinn/shared/src/config/i18n'

const getSaasAnnouncementSchema = () =>
  object({
    step: number(),
    place: object().when('step', {
      is: 1,
      then: object({
        placeId: string().required(i18n.t('Required'))
      })
    }),
    companySize: string().when('step', {
      is: 1,
      then: string().required(i18n.t('Required'))
    }),
    // companyType: string().when('step', {
    //   is: 1,
    //   then: string().required(i18n.t('Required'))
    // }),
    numberProperties: string().when('step', {
      is: 2,
      then: string().required(i18n.t('Required'))
    }),
    likeToDo: array()
      .of(string())
      .when('step', {
        is: 2,
        then: array().min(1, i18n.t('Required'))
      }),
    servicesPerMonth: string().when('step', {
      is: 2,
      then: string().required(i18n.t('Required'))
    }),
    automatedWorkflow: array()
      .of(string())
      .when(['step', 'companyType'], {
        is: (step, companyType) =>
          step === 2 && companyType === 'companyType-1',
        then: array().min(1, i18n.t('Required'))
      }),
    companyName: string().when('step', {
      is: 3,
      then: string().required(i18n.t('Required'))
    })
  })

let saasAnnouncementSchema = getSaasAnnouncementSchema()

i18n.on('languageChanged init', () => {
  saasAnnouncementSchema = getSaasAnnouncementSchema()
})

export { saasAnnouncementSchema }
