import React from 'react'
import { useTranslation } from 'react-i18next'

import ListFiltersBy from '@doinn/vendor/src/containers/reservation/list/components/ListFiltersBy'
import ListFiltersDateRange from '@doinn/vendor/src/containers/reservation/list/components/ListFiltersDateRange'

const ListFiltersDialogContent = ({
  filters,
  hiddenFilters,
  onChangeFilters
}) => {
  const { t } = useTranslation()

  const statusOptions = [
    {
      id: 'all',
      name: t('All')
    },
    {
      id: 'ok',
      name: t('Ready')
    },
    {
      id: 'error',
      name: t('Issues detected')
    },
    {
      id: 'blocked',
      name: t('Blocked')
    }
  ]

  const sortByOptions = [
    {
      id: 'checkin',
      name: t('Check-in')
    },
    {
      id: 'checkout',
      name: t('Check-out')
    },
    {
      id: 'space.name',
      name: t('Property')
    }
  ]

  const handleChangeFilters = newFilters => {
    onChangeFilters && onChangeFilters(newFilters)
  }

  return (
    <React.Fragment>
      {!hiddenFilters?.includes('period') ? null : (
        <ListFiltersDateRange
          variant='list'
          filters={filters}
          onChangeFilters={handleChangeFilters}
        />
      )}
      {!hiddenFilters?.includes('status') ? null : (
        <ListFiltersBy
          subheader={t('Status')}
          filterName='status'
          variant='list'
          filterOptions={statusOptions}
          filters={filters}
          onChangeFilters={handleChangeFilters}
        />
      )}
      {!hiddenFilters?.includes('orderBy') ? null : (
        <ListFiltersBy
          subheader={t('Sort by')}
          filterName='orderBy'
          variant='list'
          filterOptions={sortByOptions}
          filters={filters}
          onChangeFilters={handleChangeFilters}
        />
      )}
    </React.Fragment>
  )
}

export default ListFiltersDialogContent
