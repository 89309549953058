export const CUSTOMER_INVITATION_DELETE_CONFIRM_OPEN =
  'CUSTOMER_INVITATION_DELETE_CONFIRM_OPEN'
export const CUSTOMER_INVITATION_DELETE_CONFIRM_CLOSE =
  'CUSTOMER_INVITATION_DELETE_CONFIRM_CLOSE'

export const CUSTOMER_INVITATION_DELETE_REQUESTED =
  'CUSTOMER_INVITATION_DELETE_REQUESTED'
export const CUSTOMER_INVITATION_DELETE_SUCCEEDED =
  'CUSTOMER_INVITATION_DELETE_SUCCEEDED'
export const CUSTOMER_INVITATION_DELETE_FAILED =
  'CUSTOMER_INVITATION_DELETE_FAILED'
