import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getTeamApi } from '@doinn/vendor/src/api/team'
import {
  TEAM_GET_FAILED,
  TEAM_GET_REQUESTED,
  TEAM_GET_SUCCEEDED
} from '@doinn/vendor/src/containers/team/get/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* getTeam(action) {
  try {
    const response = yield call(getTeamApi, action.payload.params)
    yield put({
      type: TEAM_GET_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: TEAM_GET_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* teamSaga() {
  yield all([takeLatest(TEAM_GET_REQUESTED, getTeam)])
}
