import React from 'react'
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles
} from '@material-ui/core'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { BASE_SITE_URL } from 'config/env'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, withRouter } from 'react-router-dom'

import { getICULocale } from '@doinn/shared/src/util/getLocale'
import Icon from '@doinn/shared/src/components/common/Icon'
import MainMenuLogo from '@doinn/shared/src/components/app/MainMenuLogo'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    height: '100%'
  },
  content: {
    overflowY: 'auto'
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3)
  },
  divider: {
    margin: theme.spacing(1, 2)
  },
  mainMenuItem: {
    padding: theme.spacing(0.5, 2)
  },
  mainMenuActive: {
    '& span': {
      fontWeight: theme.typography.fontWeightMediumBold
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      width: theme.spacing(0.5),
      height: theme.spacing(4),
      marginTop: theme.spacing(-2),
      top: '50%',
      right: 0,
      backgroundColor: theme.palette.primary.main
    }
  },
  mainMenuListItemIcon: {
    minWidth: theme.spacing(4.5)
  },
  text: {
    fontSize: theme.typography.pxToRem(8.5),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    margin: '0 auto',
    marginTop: theme.spacing(2),
    color: theme.palette.common.white
  },
  drawerContent: {
    paddingTop: theme.spacing(18)
  }
}))

const AuthMainMenuContent = props => {
  const { i18n } = useTranslation()
  const { items, drawerContent } = props
  const classes = useStyles()
  const allowedItems = items.filter(item => item.isAllowed)
  const mainLogoHref = `${BASE_SITE_URL}/${getICULocale(i18n.language)}`

  const isItemSelected = item => {
    const currentPath = props.location.pathname
    if (item.link === '/') return currentPath === item.link
    return currentPath.indexOf(item.link) === 0
  }

  const getClassName = item => {
    if (isItemSelected(item)) {
      return `${classes.mainMenuItem} ${classes.mainMenuActive}`
    }
    return classes.mainMenuItem
  }

  return (
    <Grid
      container
      direction='column'
      justify='space-between'
      alignItems='stretch'
      wrap='nowrap'
      className={classes.container}
    >
      <Grid item className={classes.toolbar}>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <MainMenuLogo variant='negative-with-slogan' href={mainLogoHref} />
        </Box>
      </Grid>
      {drawerContent && (
        <Grid item className={classes.drawerContent}>
          {drawerContent}
        </Grid>
      )}
      <Grid item xs className={classes.content}>
        <Grid
          container
          direction='column'
          justify='space-between'
          alignItems='stretch'
          wrap='nowrap'
          className={classes.container}
        >
          <Grid item>
            <List>
              {allowedItems.map(item => (
                <React.Fragment key={item.id}>
                  {item.hasDivider ? (
                    <Divider className={classes.divider} />
                  ) : null}
                  <ListItem
                    component={RouterLink}
                    to={item.link}
                    disabled={item.disabled}
                    button
                    className={getClassName(item)}
                  >
                    {item.icon && (
                      <ListItemIcon className={classes.mainMenuListItemIcon}>
                        <Icon icon={item.icon} />
                      </ListItemIcon>
                    )}
                    <ListItemText primary={item.label} />
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          </Grid>
          <Grid item xs />
          <Grid item>{/* footer */}</Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

AuthMainMenuContent.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      icon: PropTypes.string,
      isAllowed: PropTypes.bool,
      hasDivider: PropTypes.bool
    }).isRequired
  ).isRequired
}

export default compose(withRouter)(AuthMainMenuContent)
