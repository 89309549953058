import React, { useCallback } from 'react'
import { compose } from 'redux'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useDebounceFn } from 'ahooks'
import { cloneDeep, isEmpty, sortBy } from 'lodash'
import { endOfMonth, startOfMonth } from 'date-fns'
import { formatSystemDate } from '@doinn/shared/src/util/date-fns'
import { Box } from '@material-ui/core'
import MetabaseTabBar from 'components/metabase/MetabaseTabBar'
import MetabaseDashboard from 'components/metabase/MetabaseDashboard'
import withAuthorization from '@doinn/shared/src/hoc/authorization/withAuthorization'

const MetabaseDashboards = ({ filters, onChangeFilters }) => {
  const { tab } = useParams()
  const { loggedUser } = useSelector(state => state.user)
  const [dashboards, setDashboards] = React.useState([])
  const [selectedTab, setSelectedTab] = React.useState(null)

  React.useEffect(() => {
    const getDashboardsTabs = () => {
      try {
        const { insights } = loggedUser
        const targetReports = sortBy(insights.reports, item => item.priority)
        const tabs = targetReports.map(report => ({
          id: report.id,
          label: report.reportName,
          to: `/insights/${report.reportSlug}`,
          value: report.reportSlug,
          periodOptions: report.filters || null
        }))

        setDashboards(tabs)
      } catch (_) {
        setDashboards([])
      }
    }

    getDashboardsTabs()
  }, [loggedUser])

  const getFiltersForTab = useCallback(
    targetTab => {
      if (!filters[targetTab.id]) {
        const params = cloneDeep(filters)

        const nowDate = new Date()
        const startOfMonthDate = startOfMonth(nowDate)
        const endOfMonthDate = endOfMonth(nowDate)

        let period = 'month_to_today'
        let dateStart = formatSystemDate(startOfMonthDate)
        let dateEnd = formatSystemDate(nowDate)

        if (targetTab.periodOptions.includes('today')) {
          period = 'today'
          dateStart = formatSystemDate(nowDate)
          dateEnd = formatSystemDate(nowDate)
        } else if (targetTab.periodOptions.includes('current_month')) {
          period = 'current_month'
          dateEnd = formatSystemDate(endOfMonthDate)
        }

        params[targetTab.id] = {
          period: period,
          start: dateStart,
          end: dateEnd
        }

        onChangeFilters && onChangeFilters(params)
      }
    },
    [filters, onChangeFilters]
  )

  const { run: debouncedSetSelectedTab } = useDebounceFn(setSelectedTab, {
    wait: 200
  })

  React.useEffect(() => {
    const getSelectedTab = () => {
      let targetTab = dashboards.filter(dash => {
        return dash.value === tab
      })

      targetTab =
        targetTab.length > 0
          ? targetTab[0]
          : isEmpty(dashboards)
          ? null
          : dashboards[0]

      if (targetTab) {
        getFiltersForTab(targetTab)
        debouncedSetSelectedTab(targetTab)
      }
    }

    getSelectedTab()
  }, [tab, dashboards, getFiltersForTab, debouncedSetSelectedTab])

  if (!selectedTab) return null

  return (
    <>
      <MetabaseTabBar tabs={dashboards} selectedTab={selectedTab.value} />
      <Box pt={3}>
        <MetabaseDashboard
          metabaseUrl={loggedUser?.insights?.metabaseUrl}
          dashboardId={selectedTab.id}
          periodOptions={selectedTab.periodOptions}
          filters={filters}
          onChangeFilters={onChangeFilters}
        />
      </Box>
    </>
  )
}

export default compose(withAuthorization)(MetabaseDashboards)
