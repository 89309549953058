import { all, call, put, takeLatest } from 'redux-saga/effects'
import { resendMemberInvitationApi } from '@doinn/vendor/src/api/membersInvitations'
import {
  MEMBERS_INVITATIONS_RESEND_FAILED,
  MEMBERS_INVITATIONS_RESEND_REQUESTED,
  MEMBERS_INVITATIONS_RESEND_SUCCEEDED
} from '@doinn/vendor/src/containers/member/invitation/resend/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import i18n from '@doinn/shared/src/config/i18n'

function* resendMemberInvitation(action) {
  try {
    const { id } = action.payload.params
    yield call(resendMemberInvitationApi, id)
    yield put({
      type: MEMBERS_INVITATIONS_RESEND_SUCCEEDED,
      payload: action.payload,
      snackbar: {
        variant: 'success',
        message: i18n.t('The invite has been resent!')
      }
    })
  } catch (e) {
    yield put({
      type: MEMBERS_INVITATIONS_RESEND_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* membersInvitationsResendSaga() {
  yield all([
    takeLatest(MEMBERS_INVITATIONS_RESEND_REQUESTED, resendMemberInvitation)
  ])
}
