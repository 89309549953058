export const CATALOG_ITEM_LIST_FETCH_REQUESTED =
  'CATALOG_ITEM_LIST_FETCH_REQUESTED'
export const CATALOG_ITEM_LIST_FETCH_SUCCEEDED =
  'CATALOG_ITEM_LIST_FETCH_SUCCEEDED'
export const CATALOG_ITEM_LIST_FETCH_FAILED = 'CATALOG_ITEM_LIST_FETCH_FAILED'

export const CATALOG_ITEM_LIST_FETCH_MORE_REQUESTED =
  'CATALOG_ITEM_LIST_FETCH_MORE_REQUESTED'
export const CATALOG_ITEM_LIST_FETCH_MORE_SUCCEEDED =
  'CATALOG_ITEM_LIST_FETCH_MORE_SUCCEEDED'
export const CATALOG_ITEM_LIST_FETCH_MORE_FAILED =
  'CATALOG_ITEM_LIST_FETCH_MORE_FAILED'
