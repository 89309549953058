import React, { useCallback } from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Button, Typography, makeStyles } from '@material-ui/core'

import styles from '@doinn/shared/src/containers/auth/components/styles'
import { loginValidationSchema } from '@doinn/shared/src/containers/auth/validation'
import DoinnLogo from '@doinn/shared/src/components/app/DoinnLogo'
import TextField from '@doinn/shared/src/components/common/inputs/TextField'

const useStyles = makeStyles(styles)

const LoginFormApp = ({ error, isLoading, onSubmit }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleFormSubmit = useCallback(
    values => onSubmit(values.email, values.password),
    [onSubmit]
  )

  return (
    <div className={classes.full}>
      <div>
        <div className={classes.logoWrapper}>
          <DoinnLogo classes={{ logo: classes.logo }} />
        </div>
        <Typography className={classes.text}>
          {'Housekeeping Operations Made Simple'}
        </Typography>
      </div>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={loginValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {props => {
          const { handleSubmit } = props

          return (
            <form
              className={classes.form}
              noValidate
              autoComplete='off'
              onSubmit={handleSubmit}
            >
              <Box mb={3}>
                <TextField
                  fullWidth
                  id='email'
                  name='email'
                  label={t('Email')}
                  type='email'
                />
                <TextField
                  fullWidth
                  name='password'
                  label={t('Password')}
                  type='password'
                />
              </Box>
              {error && (
                <Box mb={3}>
                  <Typography className={classes.error} variant='body2'>
                    {error}
                  </Typography>
                </Box>
              )}
              <Button
                disabled={isLoading}
                fullWidth
                size='large'
                type='submit'
                variant='contained'
              >
                {t('I have an account, Sign In')}
              </Button>
              <Button
                fullWidth
                size='large'
                component={RouterLink}
                to='/recover-password'
              >
                {t('I forgot the password')}
              </Button>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default LoginFormApp
