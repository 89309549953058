import { cloneDeep, find } from 'lodash'
import { countTotalCheckedChecklistItems } from '@doinn/shared/src/containers/checklist/util'
import {
  JOB_CLEAR_SELECTED_REQUESTED,
  JOB_GET_FAILED,
  JOB_GET_REQUESTED,
  JOB_GET_SUCCEEDED,
  JOB_UPDATE_ESTIMATED_START_FAILED,
  JOB_UPDATE_ESTIMATED_START_REQUESTED,
  JOB_UPDATE_ESTIMATED_START_SUCCEEDED,
  JOB_UPDATE_EXECUTION_SUCCEEDED,
  JOB_UPDATE_REQUESTED_START_FAILED,
  JOB_UPDATE_REQUESTED_START_REQUESTED,
  JOB_UPDATE_REQUESTED_START_SUCCEEDED
} from 'containers/job/constants'
import { ORDER_SERVICES_NOTES_ADD_FORM_SAVE_SUCCEEDED } from '@doinn/shared/src/containers/order-service/notes/add/constants'
import { ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_SAVE_SUCCEEDED } from 'containers/order-services/extra-item/edit/constants'
import { JOB_ASSIGN_STAFF_EDIT_SUCCEEDED } from 'containers/job/assign-staff/constants'
import { JOB_CHECKLIST_EDIT_SUCCEEDED } from 'containers/job/checklist/edit/constants'
import { JOB_FINISH_SUCCEEDED } from 'containers/job/finish/note/constants'
import { JOB_REPORT_PROBLEM_SUCCEEDED } from 'containers/job/report-problem/constants'
import { JOB_SERVICE_DELETE_SUCCEEDED } from 'containers/job/delete/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  item: null,
  isLoading: false,
  isFailed: false,
  updateJobRequestedStartStatus: STATUS.IDLE,
  updateJobEstimatedStartStatus: STATUS.IDLE
}

const parseAssignedStaffToState = (state, payload) => {
  const newState = cloneDeep(state)
  if (state?.item?.id) {
    const job = find(payload.data.jobs, {
      id: state.item.id
    })
    if (job) {
      newState.item.employees = [...job.employees]
      newState.item.team = job.team ? { ...job.team } : null
    }
  }
  return newState
}

const parseEditedJobRequestedStartToState = (state, payload) => {
  const newState = cloneDeep(state)
  if (state?.item?.id) {
    const job = payload.data
    if (job) {
      newState.item.id = job.id
      newState.item.date = job.date
      newState.item.duration = job.duration
      newState.item.isPayable = job.isPayable
      newState.item.estimatedStart = cloneDeep(job.estimatedStart)
      newState.item.requestedStart = cloneDeep(job.requestedStart)
    }
  }
  return newState
}

const parseEditedJobEstimatedStartToState = (state, payload) => {
  const newState = cloneDeep(state)
  if (state?.item?.id) {
    const job = payload.data
    if (job) {
      newState.item.id = job.id
      newState.item.date = job.date
      newState.item.duration = job.duration
      newState.item.estimatedStart = cloneDeep(job.estimatedStart)
      newState.item.requestedStart = cloneDeep(job.requestedStart)
    }
  }
  return newState
}

const parseEditedChecklistState = (state, payload) => {
  const newState = cloneDeep(state)
  if (state?.item?.id) {
    const checklist = cloneDeep(payload)
    if (checklist?.jobId && checklist?.jobId === state?.item?.id) {
      newState.item.checklist.totalCheckedItems =
        countTotalCheckedChecklistItems(checklist)
    }
  }
  return newState
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case JOB_GET_REQUESTED:
      return {
        ...state,
        ...initialState,
        isLoading: true,
        isFailed: false
      }
    case JOB_GET_SUCCEEDED:
      return {
        ...state,
        item: { ...payload.data },
        isLoading: false,
        isFailed: false
      }
    case JOB_GET_FAILED:
      return {
        ...state,
        isLoading: false,
        isFailed: true
      }
    case JOB_CLEAR_SELECTED_REQUESTED:
      return {
        ...initialState
      }
    case ORDER_SERVICES_NOTES_ADD_FORM_SAVE_SUCCEEDED:
      return {
        ...state,
        item: {
          ...state.item,
          orderServices: state.item.orderServices.map(orderService => {
            if (orderService.id === payload.orderServiceId) {
              return {
                ...orderService,
                notes: [payload.data, ...orderService.notes]
              }
            }
            return orderService
          })
        }
      }
    case JOB_REPORT_PROBLEM_SUCCEEDED:
      return {
        ...state,
        item: {
          ...state.item,
          evaluationsCount: state.item.evaluationsCount + 1
        }
      }
    case JOB_UPDATE_REQUESTED_START_REQUESTED:
      return {
        ...state,
        updateJobRequestedStartStatus: STATUS.LOADING
      }
    case JOB_UPDATE_REQUESTED_START_SUCCEEDED:
      return {
        ...parseEditedJobRequestedStartToState(state, payload),
        updateJobRequestedStartStatus: STATUS.SUCCESS
      }
    case JOB_UPDATE_REQUESTED_START_FAILED:
      return {
        ...state,
        updateJobRequestedStartStatus: STATUS.FAILURE
      }
    case JOB_FINISH_SUCCEEDED:
      if (state.item) {
        return {
          ...state,
          item: {
            ...state.item,
            execution: {
              ...state.item.execution,
              details: {
                ...state.item.execution.details,
                note: {
                  ...payload.data?.execution?.details?.note
                }
              }
            }
          }
        }
      }
      return state
    case ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_SAVE_SUCCEEDED:
      return {
        ...state,
        item: {
          ...state.item,
          orderServices: state.item.orderServices.map(orderService => {
            if (orderService.id === payload.orderServiceId) {
              return {
                ...orderService,
                serviceItems: payload.serviceItems
              }
            }
            return orderService
          })
        }
      }
    case JOB_ASSIGN_STAFF_EDIT_SUCCEEDED:
      return parseAssignedStaffToState(state, payload)
    case JOB_UPDATE_ESTIMATED_START_REQUESTED:
      return {
        ...state,
        updateJobEstimatedStartStatus: STATUS.LOADING
      }
    case JOB_UPDATE_ESTIMATED_START_SUCCEEDED:
      return {
        ...parseEditedJobEstimatedStartToState(state, payload),
        updateJobEstimatedStartStatus: STATUS.SUCCESS
      }
    case JOB_UPDATE_ESTIMATED_START_FAILED:
      return {
        ...state,
        updateJobEstimatedStartStatus: STATUS.FAILURE
      }
    case JOB_UPDATE_EXECUTION_SUCCEEDED:
      if (state.item && state.item.id === payload.data.id) {
        return {
          ...state,
          item: {
            ...state.item,
            execution: {
              ...state.item.execution,
              ...payload.data.execution
            },
            status: { ...payload.data.status }
          }
        }
      }
      return { ...state }
    case JOB_CHECKLIST_EDIT_SUCCEEDED:
      return {
        ...parseEditedChecklistState(state, payload)
      }
    case JOB_SERVICE_DELETE_SUCCEEDED: {
      return {
        ...state,
        item: {
          ...state.item,
          isPayable: false
        }
      }
    }
    default:
      return state
  }
}
