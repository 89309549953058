import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function fetchChecklistsMyApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/checklists/my`, config)
}

export function getChecklistMyApi(id) {
  const config = {}
  return DoinnApi.get(`/checklists/my/${id}`, config)
}

export function addChecklistMyApi(checklist) {
  const config = {}
  return DoinnApi.post(`/checklists/my`, { checklist }, config)
}

export function editChecklistMyApi(checklist = {}) {
  const config = {}
  const data = { checklist }
  return DoinnApi.patch(`/checklists/my/${checklist.id}`, data, config)
}

export function deleteChecklistMyApi(id) {
  const config = {}
  return DoinnApi.delete(`/checklists/my/${id}`, config)
}

export function fetchChecklistsPublicApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/checklists/public`, config)
}

export function getChecklistPublicApi(id) {
  const config = {}
  return DoinnApi.get(`/checklists/public/${id}`, config)
}

export function importChecklistMyApi(params) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  const formData = new window.FormData()
  const { file } = params

  if (file) {
    formData.append('file', file)
  }

  return DoinnApi.post(`/checklists/my/import`, formData, config)
}
