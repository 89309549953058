import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { Button, Typography, makeStyles } from '@material-ui/core'

import withAuthorization from '@doinn/shared/src/hoc/authorization/withAuthorization'

const useStyles = makeStyles(theme => ({
  button: {
    border: 'none',
    marginLeft: theme.spacing(1),
    fontWeight: 600,
    '&:hover': {
      border: 'none'
    }
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: theme.spacing(1, 2),
    backgroundColor: '#FFB528',
    fontWeight: 600
  }
}))

const ExpiringCard = ({ can }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Typography className={classes.content} component='div'>
      <div>
        {`${t(
          'Payment method is about to expire. Please update it to continue using Doinn software.'
        )} `}
      </div>
      {can('read-payment-methods') && (
        <Button
          aria-label={t('Update payment method')}
          component={RouterLink}
          color='secondary'
          size='small'
          variant='outlined'
          className={classes.button}
          to='/billing/payment-methods'
        >
          {t('Update payment method')}
        </Button>
      )}
    </Typography>
  )
}

export default withAuthorization(ExpiringCard)
