import { values } from 'lodash'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  DASHLET_SUMMARY_SERVICES_FETCH_FAILED,
  DASHLET_SUMMARY_SERVICES_FETCH_REQUESTED,
  DASHLET_SUMMARY_SERVICES_FETCH_SUCCEEDED
} from 'containers/dashboard/dashlets/summary-services/constants'
import { fetchServicesQuantitiesApi } from 'api/reports'
import { formatSystemDate } from '@doinn/shared/src/util/date-fns'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchServicesQuantities(action) {
  try {
    const dateFormatted = formatSystemDate(action.payload.date)
    const params = {
      start: dateFormatted,
      end: dateFormatted
    }

    const response = yield call(fetchServicesQuantitiesApi, { params })
    yield put({
      type: DASHLET_SUMMARY_SERVICES_FETCH_SUCCEEDED,
      payload: {
        data: values(response.data.data)
      }
    })
  } catch (e) {
    yield put({
      type: DASHLET_SUMMARY_SERVICES_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* dashletSummaryServicesSaga() {
  yield all([
    takeLatest(
      DASHLET_SUMMARY_SERVICES_FETCH_REQUESTED,
      fetchServicesQuantities
    )
  ])
}
