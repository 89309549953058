import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { getUserLoggedInAs } from '@doinn/shared/src/containers/user-logged-in-as/actions'
import { useDidMount } from '@doinn/shared/src/hooks'
import UserLoggedInAsWarningComponent from '@doinn/shared/src/containers/user-logged-in-as/components/Warning'

const Warning = ({
  baseUrl,
  className,
  getUserLoggedInAs,
  loggedUser,
  userLoggedInAs
}) => {
  useDidMount(() => {
    getUserLoggedInAs()
  })
  const url = `${baseUrl}/login-as/${userLoggedInAs.id}`

  return (
    <UserLoggedInAsWarningComponent
      url={url}
      user={loggedUser}
      userLoggedInAs={userLoggedInAs}
      className={className}
    />
  )
}

const mapStateToProps = state => ({
  loggedUser: state.user.loggedUser,
  userLoggedInAs: state.userLoggedInAs.data
})
const mapDispatchToProps = dispatch => ({
  getUserLoggedInAs: bindActionCreators(getUserLoggedInAs, dispatch)
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(Warning)
