export const PROVIDERS_INVITATIONS_ADD_DIALOG_OPEN =
  'PROVIDERS_INVITATIONS_ADD_DIALOG_OPEN'
export const PROVIDERS_INVITATIONS_ADD_DIALOG_CLOSE =
  'PROVIDERS_INVITATIONS_ADD_DIALOG_CLOSE'

export const PROVIDERS_INVITATIONS_ADD_REQUESTED =
  'PROVIDERS_INVITATIONS_ADD_REQUESTED'
export const PROVIDERS_INVITATIONS_ADD_SUCCEEDED =
  'PROVIDERS_INVITATIONS_ADD_SUCCEEDED'
export const PROVIDERS_INVITATIONS_ADD_FAILED =
  'PROVIDERS_INVITATIONS_ADD_FAILED'
