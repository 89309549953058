import { all } from 'redux-saga/effects'
import dashletJobListSaga from 'containers/dashboard/dashlets/job-list/sagas'
import dashletSummaryServicesSaga from 'containers/dashboard/dashlets/summary-services/sagas'
import dashletReportsSaga from 'containers/dashboard/dashlets/reports/sagas'
import dashletCalendarSaga from 'containers/dashboard/dashlets/calendar/sagas'
import dashletActivitiesSaga from 'containers/dashboard/dashlets/activities-list/sagas'

export default function* dashletsSaga() {
  yield all([
    dashletJobListSaga(),
    dashletSummaryServicesSaga(),
    dashletReportsSaga(),
    dashletCalendarSaga(),
    dashletActivitiesSaga()
  ])
}
