import { all, call, put, takeLatest } from 'redux-saga/effects'
import { importPropertiesApi } from '@doinn/shared/src/api/property'
import {
  PROPERTIES_IMPORT_FAILED,
  PROPERTIES_IMPORT_REQUESTED,
  PROPERTIES_IMPORT_SUCCEEDED
} from '@doinn/shared/src/containers/property/import/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* importProperties(action) {
  try {
    const { file } = action.payload.params
    const response = yield call(importPropertiesApi, {
      file: file
    })
    const responseData = response.data.data
    yield put({
      type: PROPERTIES_IMPORT_SUCCEEDED,
      payload: responseData
      // TODO: GLOBAL-911 - display snackbar after the success of pusher/echo event
      // snackbar: {
      //   variant: 'success',
      //   message: i18n.t('Properties have been imported successfully!')
      // }
    })
  } catch (e) {
    if (e?.response?.status === 400) {
      yield put({
        type: PROPERTIES_IMPORT_FAILED,
        payload: {
          error: {
            type: 'file',
            data: getSnackbarErrorMessage(e)
          }
        }
      })
    } else {
      yield put({
        type: PROPERTIES_IMPORT_FAILED,
        payload: {
          error: {
            type: 'other'
          }
        },
        snackbar: {
          variant: 'error',
          message: getSnackbarErrorMessage(e)
        }
      })
    }
  }
}

export default function* propertiesImportSaga() {
  yield all([takeLatest(PROPERTIES_IMPORT_REQUESTED, importProperties)])
}
