import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchCataloguesTemplatesApi } from '@doinn/shared/src/api/catalogues'
import {
  CATALOGUE_LIST_TEMPLATES_FETCH_FAILED,
  CATALOGUE_LIST_TEMPLATES_FETCH_MORE_FAILED,
  CATALOGUE_LIST_TEMPLATES_FETCH_MORE_REQUESTED,
  CATALOGUE_LIST_TEMPLATES_FETCH_MORE_SUCCEEDED,
  CATALOGUE_LIST_TEMPLATES_FETCH_REQUESTED,
  CATALOGUE_LIST_TEMPLATES_FETCH_SUCCEEDED
} from '@doinn/shared/src/containers/catalogue/templates/list/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchCatalogue(action) {
  try {
    const response = yield call(
      fetchCataloguesTemplatesApi,
      action.payload.params
    )
    const { data, meta } = response.data
    yield put({
      type: CATALOGUE_LIST_TEMPLATES_FETCH_SUCCEEDED,
      payload: {
        data,
        meta
      }
    })
  } catch (e) {
    yield put({
      type: CATALOGUE_LIST_TEMPLATES_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* fetchMoreCatalogue(action) {
  try {
    const response = yield call(
      fetchCataloguesTemplatesApi,
      action.payload.params
    )
    const { data, meta } = response.data
    yield put({
      type: CATALOGUE_LIST_TEMPLATES_FETCH_MORE_SUCCEEDED,
      payload: { data, meta }
    })
  } catch (e) {
    yield put({
      type: CATALOGUE_LIST_TEMPLATES_FETCH_MORE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* catalogueSaga() {
  yield all([
    takeLatest(CATALOGUE_LIST_TEMPLATES_FETCH_REQUESTED, fetchCatalogue),
    takeLatest(
      CATALOGUE_LIST_TEMPLATES_FETCH_MORE_REQUESTED,
      fetchMoreCatalogue
    )
  ])
}
