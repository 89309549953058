import {
  JOB_REPORT_VIEW_REPORTED_PROBLEMS_CLOSE_REQUESTED,
  JOB_REPORT_VIEW_REPORTED_PROBLEMS_FAILED,
  JOB_REPORT_VIEW_REPORTED_PROBLEMS_OPEN_REQUESTED,
  JOB_REPORT_VIEW_REPORTED_PROBLEMS_REQUESTED,
  JOB_REPORT_VIEW_REPORTED_PROBLEMS_SUCCEEDED
} from 'containers/job/report-problem/view/constants'

export const initialState = {
  isOpened: false,
  isLoading: false,
  reportedProblems: []
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case JOB_REPORT_VIEW_REPORTED_PROBLEMS_OPEN_REQUESTED:
      return {
        ...initialState,
        isOpened: true
      }
    case JOB_REPORT_VIEW_REPORTED_PROBLEMS_CLOSE_REQUESTED:
      return {
        ...initialState,
        isOpened: false
      }
    case JOB_REPORT_VIEW_REPORTED_PROBLEMS_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case JOB_REPORT_VIEW_REPORTED_PROBLEMS_FAILED:
      return {
        ...state,
        isLoading: false,
        reportedProblems: []
      }
    case JOB_REPORT_VIEW_REPORTED_PROBLEMS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        reportedProblems: payload.reportedProblems
      }
    default:
      return state
  }
}
