import React, { useCallback } from 'react'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'

import { BANNER_SLUG } from '@doinn/shared/src/containers/banner/util'
import Trial from '@doinn/shared/src/containers/banner/components/Trial'
import ExpiredCard from '@doinn/shared/src/containers/banner/components/ExpiredCard'
import ExpiringCard from '@doinn/shared/src/containers/banner/components/ExpiringCard'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    position: 'fixed',
    height: theme.spacing(7),
    justifyContent: 'center',
    top: '0',
    width: '100%',
    zIndex: '1200'
  }
}))

const Banner = () => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { slugs, show } = useSelector(state => state.banner)

  const handleCtaPlansClick = useCallback(() => {
    history.push({
      pathname: '/plans',
      state: {
        closePath: `${location.pathname}${location.search}`
      }
    })
  }, [history, location?.pathname, location?.search])

  const renderContent = useCallback(() => {
    // For now we only have one message at a time
    if (isEmpty(slugs)) return null

    if (slugs?.includes(BANNER_SLUG.trial)) {
      return <Trial onClick={handleCtaPlansClick} />
    }

    if (slugs?.includes(BANNER_SLUG.card_expiring)) {
      return <ExpiringCard />
    }

    if (slugs?.includes(BANNER_SLUG.card_expired)) {
      return <ExpiredCard />
    }

    return null
  }, [handleCtaPlansClick, slugs])

  if (!show) return null

  return (
    <div id='bannerInfo' className={classes.container}>
      {renderContent()}
    </div>
  )
}

export default Banner
