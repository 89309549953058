import {
  GATEWAYS_GET_METHODS_FAILED,
  GATEWAYS_GET_METHODS_REQUESTED,
  GATEWAYS_GET_METHODS_SUCCEEDED
} from '@doinn/shared/src/containers/gateways/constants'
import { PAYMENT_METHODS_DEFAULT_UPDATE_SUCCEEDED } from '@doinn/shared/src/containers/subscription-billing/payment/default/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  status: STATUS.IDLE,
  paymentMethods: []
}

const updateDefaultMethod = (state, paymentMethod) => {
  return {
    ...state,
    paymentMethods: state.paymentMethods.map(method => {
      if (method.id === paymentMethod.id) {
        return paymentMethod
      }
      return {
        ...method,
        default: false
      }
    })
  }
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GATEWAYS_GET_METHODS_REQUESTED:
      return {
        ...initialState,
        status: STATUS.LOADING
      }
    case GATEWAYS_GET_METHODS_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS,
        paymentMethods: payload.data
      }
    case GATEWAYS_GET_METHODS_FAILED:
      return {
        ...initialState,
        status: STATUS.FAILURE
      }
    case PAYMENT_METHODS_DEFAULT_UPDATE_SUCCEEDED:
      return updateDefaultMethod(state, payload.data)
    default:
      return state
  }
}
