export const EMPLOYEE_ADD_EDIT_FORM_OPEN_REQUESTED =
  'EMPLOYEE_ADD_EDIT_FORM_OPEN_REQUESTED'

export const EMPLOYEE_ADD_EDIT_FORM_CLOSE_REQUESTED =
  'EMPLOYEE_ADD_EDIT_FORM_CLOSE_REQUESTED'

export const EMPLOYEE_ADD_EDIT_FORM_SAVE_REQUESTED =
  'EMPLOYEE_ADD_EDIT_FORM_SAVE_REQUESTED'
export const EMPLOYEE_ADD_EDIT_FORM_SAVE_SUCCEEDED =
  'EMPLOYEE_ADD_EDIT_FORM_SAVE_SUCCEEDED'
export const EMPLOYEE_ADD_EDIT_FORM_SAVE_FAILED =
  'EMPLOYEE_ADD_EDIT_FORM_SAVE_FAILED'

export const EMPLOYEE_EDIT_IMAGE_REQUESTED = 'EMPLOYEE_EDIT_IMAGE_REQUESTED'
export const EMPLOYEE_EDIT_IMAGE_SUCCEEDED = 'EMPLOYEE_EDIT_IMAGE_SUCCEEDED'
export const EMPLOYEE_EDIT_IMAGE_FAILED = 'EMPLOYEE_EDIT_IMAGE_FAILED'
