import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchBillingListApi } from '@doinn/shared/src/api/billing'
import {
  BILLING_LIST_FETCH_FAILED,
  BILLING_LIST_FETCH_REQUESTED,
  BILLING_LIST_FETCH_SUCCEEDED
} from './constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

export function* fetchBillingList(action) {
  try {
    const response = yield call(fetchBillingListApi, action.payload)
    const data = {
      billings: response.data.data,
      meta: response.data.meta
    }
    yield put({
      type: BILLING_LIST_FETCH_SUCCEEDED,
      payload: data
    })
  } catch (e) {
    yield put({
      type: BILLING_LIST_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* billingSaga() {
  yield all([takeLatest(BILLING_LIST_FETCH_REQUESTED, fetchBillingList)])
}
