import { all, call, put, takeLatest } from 'redux-saga/effects'
import { deleteIntegrationApi } from '@doinn/host/src/api/integrations'
import {
  INTEGRATIONS_DELETE_FAILED,
  INTEGRATIONS_DELETE_REQUESTED,
  INTEGRATIONS_DELETE_SUCCEEDED
} from '@doinn/host/src/containers/integrations/list/delete/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* deleteIntegration(action) {
  try {
    const { id, deleteFutureServices, disableSpaces } = action.payload
    yield call(deleteIntegrationApi, {
      id,
      deleteFutureServices,
      disableSpaces
    })
    yield put({
      type: INTEGRATIONS_DELETE_SUCCEEDED,
      payload: action.payload
    })
  } catch (e) {
    yield put({
      type: INTEGRATIONS_DELETE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* integrationsDeleteSaga() {
  yield all([takeLatest(INTEGRATIONS_DELETE_REQUESTED, deleteIntegration)])
}
