export const UPDATE_REQUEST_DIALOG_OPEN = 'UPDATE_REQUEST_DIALOG_OPEN'
export const UPDATE_REQUEST_DIALOG_CLOSE = 'UPDATE_REQUEST_DIALOG_CLOSE'

export const UPDATE_REQUEST_APPROVE_REQUESTED =
  'UPDATE_REQUEST_APPROVE_REQUESTED'
export const UPDATE_REQUEST_APPROVE_SUCCEEDED =
  'UPDATE_REQUEST_APPROVE_SUCCEEDED'
export const UPDATE_REQUEST_APPROVE_FAILED = 'UPDATE_REQUEST_APPROVE_FAILED'
export const UPDATE_REQUEST_REFUSE_REQUESTED = 'UPDATE_REQUEST_REFUSE_REQUESTED'
export const UPDATE_REQUEST_REFUSE_SUCCEEDED = 'UPDATE_REQUEST_REFUSE_SUCCEEDED'
export const UPDATE_REQUEST_REFUSE_FAILED = 'UPDATE_REQUEST_REFUSE_FAILED'
