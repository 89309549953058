import { all, call, put, takeLatest } from 'redux-saga/effects'
import { removeJobFromCheckoutApi } from '@doinn/vendor/src/api/checkout'
import {
  CHECKOUT_REMOVE_FAILED,
  CHECKOUT_REMOVE_REQUESTED,
  CHECKOUT_REMOVE_SUCCEEDED
} from '@doinn/vendor/src/containers/checkout/remove/constants'
import i18n from '@doinn/shared/src/config/i18n'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* removeService(action) {
  try {
    const { params } = action.payload
    const response = yield call(removeJobFromCheckoutApi, params)

    yield put({
      type: CHECKOUT_REMOVE_SUCCEEDED,
      payload: response.data.data,
      snackbar: {
        variant: 'success',
        message: i18n.t('The services have been removed successfully!')
      }
    })
  } catch (e) {
    yield put({
      type: CHECKOUT_REMOVE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* checkoutRemoveServiceSagas() {
  yield all([takeLatest(CHECKOUT_REMOVE_REQUESTED, removeService)])
}
