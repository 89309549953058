export const CATALOGUE_MY_EDIT_DESCRIPTION_DIALOG_OPEN =
  'CATALOGUE_MY_EDIT_DESCRIPTION_DIALOG_OPEN'
export const CATALOGUE_MY_EDIT_DESCRIPTION_DIALOG_CLOSE =
  'CATALOGUE_MY_EDIT_DESCRIPTION_DIALOG_CLOSE'

export const CATALOGUE_MY_EDIT_DESCRIPTION_REQUESTED =
  'CATALOGUE_MY_EDIT_DESCRIPTION_REQUESTED'
export const CATALOGUE_MY_EDIT_DESCRIPTION_SUCCEEDED =
  'CATALOGUE_MY_EDIT_DESCRIPTION_SUCCEEDED'
export const CATALOGUE_MY_EDIT_DESCRIPTION_FAILED =
  'CATALOGUE_MY_EDIT_DESCRIPTION_FAILED'
