import {
  CONTRACT_COUNTRIES_FETCH_FAILED,
  CONTRACT_COUNTRIES_FETCH_REQUESTED,
  CONTRACT_COUNTRIES_FETCH_SUCCEEDED
} from 'containers/onboarding/steps/Country/constants'

export const initialState = {
  countries: [],
  isLoading: false,
  isLoaded: false
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case CONTRACT_COUNTRIES_FETCH_REQUESTED:
      return {
        ...state,
        countries: [],
        isLoading: true,
        isLoaded: false
      }
    case CONTRACT_COUNTRIES_FETCH_SUCCEEDED:
      return {
        ...state,
        countries: payload.data,
        isLoading: false,
        isLoaded: true
      }
    case CONTRACT_COUNTRIES_FETCH_FAILED: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true
      }
    }
    default:
      return state
  }
}
