import {
  RESERVATION_SYNC_CLEAR,
  RESERVATION_SYNC_COMPLETED,
  RESERVATION_SYNC_DIALOG_CLOSE,
  RESERVATION_SYNC_DIALOG_OPEN,
  RESERVATION_SYNC_FAILED,
  RESERVATION_SYNC_REQUESTED,
  RESERVATION_SYNC_STARTED,
  RESERVATION_SYNC_SUCCEEDED
} from '@doinn/vendor/src/containers/reservation/sync/constants'
import { RESERVATIONS_FETCH_SUCCEEDED } from '@doinn/vendor/src/containers/reservation/list/constants'

export const STATUS = {
  IDLE: 'idle',
  SAVING: 'saving',
  SYNC: 'sync',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  isOpened: false,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case RESERVATION_SYNC_DIALOG_OPEN:
      return {
        ...initialState,
        isOpened: true
      }
    case RESERVATION_SYNC_DIALOG_CLOSE:
      return {
        ...initialState,
        status: state.status === STATUS.SUCCESS ? STATUS.SYNC : state.status,
        isOpened: false
      }
    case RESERVATION_SYNC_REQUESTED:
      return {
        ...state,
        status: STATUS.SAVING,
        isOpened: true
      }
    case RESERVATION_SYNC_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS
      }
    case RESERVATION_SYNC_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE,
        isOpened: false
      }
    case RESERVATION_SYNC_STARTED:
      return {
        ...state,
        status: state.isOpened ? state.status : STATUS.SYNC
      }
    case RESERVATION_SYNC_COMPLETED:
      return {
        ...state,
        status: STATUS.IDLE,
        isOpened: false
      }
    case RESERVATION_SYNC_CLEAR:
      return {
        ...initialState
      }
    case RESERVATIONS_FETCH_SUCCEEDED:
      return {
        ...state,
        status: payload?.meta?.batch ? STATUS.SYNC : state.status
      }
    default:
      return state
  }
}
