import { orderBy } from 'lodash'
import {
  MEMBERS_INVITATIONS_FETCH_FAILED,
  MEMBERS_INVITATIONS_FETCH_MORE_FAILED,
  MEMBERS_INVITATIONS_FETCH_MORE_REQUESTED,
  MEMBERS_INVITATIONS_FETCH_MORE_SUCCEEDED,
  MEMBERS_INVITATIONS_FETCH_REQUESTED,
  MEMBERS_INVITATIONS_FETCH_SUCCEEDED
} from '@doinn/vendor/src/containers/member/invitation/list/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  LOADING_MORE: 'loading-more',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  list: [],
  meta: {},
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case MEMBERS_INVITATIONS_FETCH_REQUESTED:
      return {
        ...state,
        ...initialState,
        status: STATUS.LOADING
      }
    case MEMBERS_INVITATIONS_FETCH_SUCCEEDED:
      return {
        ...state,
        list: orderBy([...payload.data], ['sentAt'], ['desc']),
        meta: { ...payload.meta },
        status: STATUS.SUCCESS
      }
    case MEMBERS_INVITATIONS_FETCH_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case MEMBERS_INVITATIONS_FETCH_MORE_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING_MORE
      }
    case MEMBERS_INVITATIONS_FETCH_MORE_SUCCEEDED:
      return {
        ...state,
        list: orderBy([...state.list, ...payload.data], ['sentAt'], ['desc']),
        meta: { ...payload.meta },
        status: STATUS.SUCCESS
      }
    case MEMBERS_INVITATIONS_FETCH_MORE_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
