import { values } from 'lodash'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  getJobApi,
  updateJobEstimatedStartApi,
  updateJobExecutionApi
} from 'api/job'
import { updateJobRequestedStartApi } from '@doinn/host/src/api/job'
import {
  JOB_EXECUTION_FINISH_SUCCEEDED,
  JOB_GET_FAILED,
  JOB_GET_REQUESTED,
  JOB_GET_SUCCEEDED,
  JOB_UPDATE_ESTIMATED_START_FAILED,
  JOB_UPDATE_ESTIMATED_START_REQUESTED,
  JOB_UPDATE_ESTIMATED_START_SUCCEEDED,
  JOB_UPDATE_EXECUTION_FAILED,
  JOB_UPDATE_EXECUTION_REQUESTED,
  JOB_UPDATE_EXECUTION_SUCCEEDED,
  JOB_UPDATE_REQUESTED_START_FAILED,
  JOB_UPDATE_REQUESTED_START_REQUESTED,
  JOB_UPDATE_REQUESTED_START_SUCCEEDED
} from 'containers/job/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import { getJobUpdateRequestSagaTextBy } from '@doinn/vendor/src/containers/job/update-request/util'

function* getJob(action) {
  try {
    const response = yield call(getJobApi, action.payload.params)
    yield put({
      type: JOB_GET_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: JOB_GET_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* updateJobExecution(action) {
  try {
    const response = yield call(
      updateJobExecutionApi,
      ...values(action.payload)
    )
    const data = response.data

    yield put({
      type: JOB_UPDATE_EXECUTION_SUCCEEDED,
      payload: data
    })

    if (data?.data?.status?.slug === 'done') {
      yield put({
        type: JOB_EXECUTION_FINISH_SUCCEEDED,
        payload: data
      })
    }
  } catch (e) {
    yield put({
      type: JOB_UPDATE_EXECUTION_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* updateJobRequestedStart(action) {
  try {
    const { job, datetime, datetimeEnd, timeWindowId } = action.payload
    const response = yield call(
      updateJobRequestedStartApi,
      job,
      datetime,
      datetimeEnd,
      timeWindowId
    )
    yield put({
      type: JOB_UPDATE_REQUESTED_START_SUCCEEDED,
      payload: response.data,
      snackbar: {
        variant: 'success',
        message: getJobUpdateRequestSagaTextBy('reschedule.create.success')
      }
    })
  } catch (e) {
    yield put({
      type: JOB_UPDATE_REQUESTED_START_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* updateJobEstimatedStart(action) {
  try {
    const response = yield call(
      updateJobEstimatedStartApi,
      action.payload.params
    )
    yield put({
      type: JOB_UPDATE_ESTIMATED_START_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: JOB_UPDATE_ESTIMATED_START_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* jobSaga() {
  yield all([
    takeLatest(JOB_GET_REQUESTED, getJob),
    takeEvery(JOB_UPDATE_EXECUTION_REQUESTED, updateJobExecution),
    takeLatest(JOB_UPDATE_REQUESTED_START_REQUESTED, updateJobRequestedStart),
    takeEvery(JOB_UPDATE_ESTIMATED_START_REQUESTED, updateJobEstimatedStart)
  ])
}
