// @flow
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  CircularProgress,
  makeStyles,
  useTheme
} from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button/Button'
import { CircularProgressProps } from '@material-ui/core/CircularProgress/CircularProgress'

export type Props = {
  isLoading: boolean,
  loadingLabel?: string | Node,
  circularProgressProps?: CircularProgressProps
} & ButtonProps

const useStyles = makeStyles(theme => ({
  circularProgress: {
    marginRight: theme.spacing(1)
  }
}))

const ButtonWithLoading = ({
  children,
  isLoading,
  size,
  loadingLabel,
  circularProgressProps,
  ...otherProps
}: Props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const computedLoadingLabel = loadingLabel ?? t('processing...')
  const circularProgressSizes = {
    small: theme.spacing(2),
    medium: theme.spacing(2),
    large: theme.spacing(2.5)
  }

  return (
    <Button
      {...otherProps}
      size={size}
      disabled={isLoading || otherProps?.disabled}
    >
      {isLoading ? (
        <>
          <CircularProgress
            color='inherit'
            size={circularProgressSizes[size]}
            {...circularProgressProps}
            className={classes.circularProgress}
          />
          {computedLoadingLabel}
        </>
      ) : (
        children
      )}
    </Button>
  )
}

ButtonWithLoading.defaultProps = {
  isLoading: false,
  size: 'medium'
}

ButtonWithLoading.muiName = 'Button'

export default ButtonWithLoading
