import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import { fetchCountriesApi } from 'api/contracts/countries'
import {
  CONTRACT_COUNTRIES_FETCH_FAILED,
  CONTRACT_COUNTRIES_FETCH_REQUESTED,
  CONTRACT_COUNTRIES_FETCH_SUCCEEDED
} from 'containers/onboarding/steps/Country/constants'

function* fetchCountries(action) {
  try {
    const response = yield call(fetchCountriesApi)
    const countriesResponse = response.data

    yield put({
      type: CONTRACT_COUNTRIES_FETCH_SUCCEEDED,
      payload: countriesResponse
    })
  } catch (e) {
    yield put({
      type: CONTRACT_COUNTRIES_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* countrySagas() {
  yield all([takeLatest(CONTRACT_COUNTRIES_FETCH_REQUESTED, fetchCountries)])
}
