import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'
import DateRange from '@doinn/shared/src/components/common/filter/DateRange'
import { getPeriod } from '@doinn/shared/src/util/getLocale'

const FilterDateRange = ({
  filters,
  onChangeFilters,
  periodOptions,
  variant
}) => {
  const { t } = useTranslation()
  const parsedPeriodOptions = periodOptions || [
    {
      id: 'yesterday',
      name: t('Yesterday')
    },
    {
      id: 'today',
      name: t('Today')
    },
    {
      id: 'tomorrow',
      name: t('Tomorrow')
    },
    {
      id: 'last_month',
      name: t('Last month')
    },
    {
      id: 'current_month',
      name: t('Current month')
    },
    {
      id: 'next_month',
      name: t('Next month')
    },
    {
      id: 'custom',
      name: t('Custom')
    }
  ]

  const computedSelectedPeriod =
    filters?.period === 'custom'
      ? 'custom'
      : getPeriod(filters?.start, filters?.end)

  const handleChangeDateRangeFilter = useCallback(
    dateRange => {
      const params = cloneDeep({
        ...filters,
        ...dateRange
      })
      onChangeFilters && onChangeFilters(params)
    },
    [filters, onChangeFilters]
  )

  if (!filters?.start || !filters?.end) return null

  return (
    <DateRange
      selectedPeriod={computedSelectedPeriod}
      start={filters.start}
      end={filters.end}
      periodOptions={parsedPeriodOptions}
      variant={variant}
      onChange={handleChangeDateRangeFilter}
    />
  )
}

FilterDateRange.propTypes = {
  variant: PropTypes.oneOf(['list', 'menu']),
  periodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  filters: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    period: PropTypes.string
  }),
  onChangeFilters: PropTypes.func
}

FilterDateRange.defaultProps = {
  variant: 'menu'
}

export default FilterDateRange
