import { cloneDeep } from 'lodash'
import {
  INTEGRATION_CLEAR_SELECTED_REQUESTED,
  INTEGRATION_GET_FAILED,
  INTEGRATION_GET_REQUESTED,
  INTEGRATION_GET_SUCCEEDED
} from '@doinn/host/src/containers/integrations/get/constants'
import { INTEGRATION_STATUS_CHANGED } from '@doinn/host/src/containers/integrations/sync/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

const parseNewStatusToState = (state, integration = {}) => {
  const newState = cloneDeep(state)
  try {
    if (newState.item.id === integration.id) {
      newState.item = {
        ...newState.item,
        status: integration.status,
        defaultSpaceEnabled: integration.default_space_enabled
      }
    }
  } catch (e) {}
  return newState
}

export const initialState = {
  item: null,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case INTEGRATION_GET_REQUESTED:
      return {
        ...initialState,
        status: STATUS.LOADING
      }
    case INTEGRATION_GET_SUCCEEDED:
      return {
        ...state,
        item: { ...payload.data },
        status: STATUS.SUCCESS
      }
    case INTEGRATION_GET_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case INTEGRATION_CLEAR_SELECTED_REQUESTED:
      return {
        ...initialState
      }
    case INTEGRATION_STATUS_CHANGED:
      return parseNewStatusToState(state, payload?.integration)
    default:
      return state
  }
}
