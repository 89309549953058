import { cloneDeep, set } from 'lodash'
import {
  NOTIFICATION_EMAIL_CREATE_FAILED,
  NOTIFICATION_EMAIL_CREATE_REQUESTED,
  NOTIFICATION_EMAIL_CREATE_SUCCEEDED,
  NOTIFICATION_EMAIL_DELETE_FAILED,
  NOTIFICATION_EMAIL_DELETE_SUCCEEDED,
  PROFILE_DETAILS_FIELD_UPDATE,
  PROFILE_DETAILS_GET_REQUESTED,
  PROFILE_DETAILS_GET_SUCCEEDED,
  PROFILE_DETAILS_SET_EDITING_SECTION,
  PROFILE_DETAILS_UPDATE_FAILED,
  PROFILE_DETAILS_UPDATE_REQUESTED,
  PROFILE_DETAILS_UPDATE_SUCCEEDED,
  PROFILE_TRANSLATION_LANGUAGE_UPDATE_FAILED,
  PROFILE_TRANSLATION_LANGUAGE_UPDATE_SUCCEEDED
} from '@doinn/shared/src/containers/profile/constants'
import {
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_REQUESTED,
  CHANGE_PASSWORD_SUCCEEDED
} from '@doinn/shared/src/containers/profile/sections/change-password/constants'

const defaultProfileDetails = {
  name: '',
  email: '',
  mobilephone: '',
  preferences: {
    language: 'en_US',
    dateFormat: 'Y/m/d',
    translationLanguage: ''
  },
  business: {
    name: '',
    legalForm: '',
    taxNumber: '',
    numberOfPermanent: '',
    headOffice: '',
    notificationEmails: [],
    documents: []
  }
}

export const initialState = {
  profileDetails: { ...defaultProfileDetails },
  currentProfileDetails: { ...defaultProfileDetails },
  editingSection: '',
  isDirty: false,
  isSaving: false,
  isLoading: true,
  isFailed: false
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case PROFILE_DETAILS_GET_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case PROFILE_DETAILS_GET_SUCCEEDED:
      return {
        ...state,
        profileDetails: {
          ...defaultProfileDetails,
          ...payload.data
        },
        currentProfileDetails: {
          ...defaultProfileDetails,
          ...payload.data
        },
        isDirty: false,
        isLoading: false
      }
    case PROFILE_DETAILS_SET_EDITING_SECTION:
      return {
        ...state,
        profileDetails: {
          ...cloneDeep(state.profileDetails),
          ...cloneDeep(state.currentProfileDetails)
        },
        editingSection: payload.params,
        isDirty: false
      }
    case PROFILE_DETAILS_UPDATE_REQUESTED:
    case CHANGE_PASSWORD_REQUESTED:
      return {
        ...state,
        isSaving: true
      }
    case PROFILE_DETAILS_UPDATE_SUCCEEDED:
    case PROFILE_TRANSLATION_LANGUAGE_UPDATE_SUCCEEDED:
    case CHANGE_PASSWORD_SUCCEEDED:
      return {
        ...state,
        editingSection: '',
        isSaving: false
      }
    case PROFILE_DETAILS_UPDATE_FAILED:
    case PROFILE_TRANSLATION_LANGUAGE_UPDATE_FAILED:
    case CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        isSaving: false
      }
    case NOTIFICATION_EMAIL_CREATE_REQUESTED:
      return {
        ...state,
        isSaving: true
      }
    case NOTIFICATION_EMAIL_CREATE_SUCCEEDED:
    case NOTIFICATION_EMAIL_DELETE_SUCCEEDED:
      return {
        ...state,
        profileDetails: {
          ...state.profileDetails,
          business: {
            ...state.profileDetails.business,
            notificationEmails: payload.data.notificationEmails
          }
        },
        currentProfileDetails: {
          ...state.currentProfileDetails,
          business: {
            ...state.profileDetails.business,
            notificationEmails: payload.data.notificationEmails
          }
        },
        isFailed: false,
        isSaving: false
      }
    case NOTIFICATION_EMAIL_CREATE_FAILED:
    case NOTIFICATION_EMAIL_DELETE_FAILED:
      return {
        ...state,
        isFailed: true,
        isSaving: false
      }
    case PROFILE_DETAILS_FIELD_UPDATE:
      return {
        ...state,
        profileDetails: set(
          { ...state.profileDetails },
          payload.params.fieldName,
          payload.params.value
        ),
        isDirty: true
      }
    default:
      return state
  }
}
