import {
  CATALOGUE_MY_SHARE_DIALOG_CLOSE,
  CATALOGUE_MY_SHARE_DIALOG_OPEN,
  CATALOGUE_MY_SHARE_FAILED,
  CATALOGUE_MY_SHARE_REQUESTED,
  CATALOGUE_MY_SHARE_SUCCEEDED
} from '@doinn/shared/src/containers/catalogue/my/share/constants'

export const STATUS = {
  IDLE: 'idle',
  SAVING: 'saving',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  isOpened: false,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case CATALOGUE_MY_SHARE_DIALOG_OPEN:
      return {
        ...initialState,
        isOpened: true
      }
    case CATALOGUE_MY_SHARE_DIALOG_CLOSE:
      return {
        ...initialState,
        isOpened: false
      }
    case CATALOGUE_MY_SHARE_REQUESTED:
      return {
        ...state,
        status: STATUS.SAVING
      }
    case CATALOGUE_MY_SHARE_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case CATALOGUE_MY_SHARE_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
