import {
  USER_GET_IDENTIFICATION_TOKEN_FAILED,
  USER_GET_IDENTIFICATION_TOKEN_REQUESTED,
  USER_GET_IDENTIFICATION_TOKEN_SUCCEEDED
} from '@doinn/vendor/src/containers/support/constants'

export const STATUS = {
  IDLE: 'idle',
  SAVING: 'saving',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  identificationToken: null,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case USER_GET_IDENTIFICATION_TOKEN_REQUESTED:
      return {
        ...initialState,
        status: STATUS.SAVING
      }
    case USER_GET_IDENTIFICATION_TOKEN_SUCCEEDED:
      return {
        identificationToken: payload?.data?.token || null,
        status: STATUS.SUCCESS
      }
    case USER_GET_IDENTIFICATION_TOKEN_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
