export const PAYPAL_PAYMENT_SETUP_OPEN = 'PAYPAL_PAYMENT_SETUP_OPEN'
export const PAYPAL_PAYMENT_SETUP_SUCCEEDED = 'PAYPAL_SETUP_SUCCEEDED'
export const PAYPAL_PAYMENT_SETUP_FAILED = 'PAYPAL_SETUP_FAILED'
export const PAYPAL_PAYMENT_SETUP_CLOSE = 'PAYPAL_PAYMENT_SETUP_CLOSE'

export const PAYPAL_PAYMENT_SETUP_ERROR = 'PAYPAL_PAYMENT_SETUP_ERROR'

export const PAYPAL_PAYMENT_SETUP_SAVING_REQUESTED =
  'PAYPAL_PAYMENT_SETUP_SAVING_REQUESTED'
export const PAYPAL_PAYMENT_SETUP_SAVING_SUCCEEDED =
  'PAYPAL_PAYMENT_SETUP_SAVING_SUCCEEDED'
export const PAYPAL_PAYMENT_SETUP_SAVING_FAILED =
  'PAYPAL_PAYMENT_SETUP_SAVING_FAILED'

export const PAYPAL_PAYMENT_SETUP_UPDATING_REQUESTED =
  'PAYPAL_PAYMENT_SETUP_UPDATING_REQUESTED'
