import {
  ONBOARDING_SURVEY_DIALOG_CLOSE,
  ONBOARDING_SURVEY_DIALOG_OPEN,
  ONBOARDING_SURVEY_REQUESTED
} from '@doinn/shared/src/containers/onboarding/survey/constants'

export function open(params) {
  return {
    type: ONBOARDING_SURVEY_DIALOG_OPEN,
    payload: { params }
  }
}

export function close(params) {
  return {
    type: ONBOARDING_SURVEY_DIALOG_CLOSE,
    payload: { params }
  }
}

export function onboardingSurvey(params = {}) {
  return {
    type: ONBOARDING_SURVEY_REQUESTED,
    payload: { params }
  }
}
