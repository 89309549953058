import React from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'
import { cloneDeep, isEqual as isObjEqual } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'

import {
  useDidMount,
  useFilterState,
  useOpenState,
  usePrevious
} from '@doinn/shared/src/hooks'
import {
  getQueryStringObject,
  updateURLParams
} from '@doinn/shared/src/util/url'
import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import { parseAppliedFilters } from '@doinn/shared/src/util/filters'
import TemplateDefault from 'components/TemplateDefault'

const CatalogueDetailsContainer = loadable(() =>
  lazyRetry(() =>
    import('@doinn/shared/src/containers/catalogue/CatalogueDetails')
  )
)

const defaultFilters = {
  page: 1,
  updatedAt: ''
}

const getQueryOrSavedFilters = location => {
  const queryFilters = getQueryStringObject(location.search)
  return parseAppliedFilters(defaultFilters, queryFilters)
}

const CatalogueDetails = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const previousLocation = usePrevious(location)
  const [justChangedLocation, setJustChangedLocation] = React.useState(false)
  const {
    appliedFilters,
    // selectedFilters,
    hasAppliedFiltersChanges,
    onChangeAppliedFilters,
    onChangeSelectedFilters
    // onClearSelectedFilters,
    // onConfirmSelectedFilters
  } = useFilterState(cloneDeep(defaultFilters))
  const {
    justClosed: justClosedFilters,
    justOpened: justOpenedFilters
    // onClose: onCloseFilters,
    // onOpen: onOpenFilters,
    // open: isFiltersOpened
  } = useOpenState(false)
  // const [totalAppliedFilters, setTotalAppliedFilters] = React.useState(
  //   countAppliedFilters(appliedFilters)
  // )

  useDidMount(() => {
    onChangeAppliedFilters(getQueryOrSavedFilters(location))
  })

  React.useEffect(() => {
    if (!previousLocation) return
    setJustChangedLocation(
      !isObjEqual(location.search, previousLocation.search)
    )
  }, [location, previousLocation])

  React.useEffect(() => {
    if (!justChangedLocation) return
    const savedFilters = getQueryOrSavedFilters(location)
    const shouldUpdateAppliedFilters = !isObjEqual(appliedFilters, savedFilters)
    if (shouldUpdateAppliedFilters) {
      onChangeAppliedFilters(savedFilters)
    }
  }, [appliedFilters, justChangedLocation, location, onChangeAppliedFilters])

  React.useEffect(() => {
    if (justOpenedFilters) {
      onChangeSelectedFilters(appliedFilters)
    }
  }, [appliedFilters, justOpenedFilters, onChangeSelectedFilters])

  React.useEffect(() => {
    if (justClosedFilters) {
      onChangeSelectedFilters(appliedFilters)
    }
  }, [appliedFilters, justClosedFilters, onChangeSelectedFilters])

  React.useEffect(() => {
    const shouldUpdateUrl =
      hasAppliedFiltersChanges &&
      !isObjEqual(appliedFilters, getQueryOrSavedFilters(location))
    if (shouldUpdateUrl) {
      updateURLParams(appliedFilters, history)
    }
  }, [appliedFilters, hasAppliedFiltersChanges, history, location])

  // React.useEffect(() => {
  //   setTotalAppliedFilters(countAppliedFilters(appliedFilters))
  // }, [appliedFilters])

  // const handleConfirmSelectedFilters = useCallback(() => {
  //   onConfirmSelectedFilters()
  //   onCloseFilters()
  // }, [onCloseFilters, onConfirmSelectedFilters])

  const handleImportFinish = React.useCallback(() => {
    const newFilters = {
      ...cloneDeep(defaultFilters),
      // orderBy: 'created_at',
      // orderOrientation: 'desc',
      updatedAt: new Date().getTime()
    }
    onChangeAppliedFilters(newFilters)
  }, [onChangeAppliedFilters])

  return (
    <TemplateDefault title={t('Catalogues')}>
      <Helmet>
        <title>{t('Catalogues')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <CatalogueDetailsContainer
          filters={appliedFilters}
          onImportFinish={handleImportFinish}
        />
      </React.Suspense>
    </TemplateDefault>
  )
}

export default CatalogueDetails
