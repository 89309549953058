import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { deletePropertyApi } from '@doinn/shared/src/api/property'
import {
  PROPERTY_DELETE_FAILED,
  PROPERTY_DELETE_REQUESTED,
  PROPERTY_DELETE_SUCCEEDED
} from '@doinn/shared/src/containers/property/delete/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* deleteProperty(action) {
  try {
    const { propertyId } = action.payload
    yield call(deletePropertyApi, propertyId)
    yield put({
      type: PROPERTY_DELETE_SUCCEEDED,
      payload: action.payload,
      snackbar: {
        variant: 'success',
        message: i18n.t('The property has been deleted successfully')
      }
    })
  } catch (e) {
    yield put({
      type: PROPERTY_DELETE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* propertyDeleteSaga() {
  yield all([takeLatest(PROPERTY_DELETE_REQUESTED, deleteProperty)])
}
