const LIMIT_PHOTOS = 5

function usePhotoGallery() {
  const getFileFromMediaFile = async mediaFile => {
    return new Promise((resolve, reject) => {
      try {
        window.resolveLocalFileSystemURL(
          mediaFile.fullPath,
          fileEntry => {
            fileEntry.file(resolve)
          },
          reject
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  const createFileFromMediaFile = async mediaFile => {
    const fileData = await getFileFromMediaFile(mediaFile)

    const newFileData = await new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onloadend = () => {
        const blob = new Blob([new Uint8Array(reader.result)], {
          type: mediaFile.type
        })
        resolve({ file: blob, src: window.URL.createObjectURL(blob) })
      }

      reader.onerror = () => {
        reject(new Error('Error reading file'))
      }

      reader.readAsArrayBuffer(fileData)
    })

    return {
      name: mediaFile.name ?? null,
      photo: mediaFile,
      ...newFileData
    }
  }

  const takePhoto = async () => {
    const options = { limit: LIMIT_PHOTOS }

    const capture = () => {
      return new Promise((resolve, reject) => {
        const captureSuccess = async mediaFiles => {
          const filePromises = mediaFiles.map(createFileFromMediaFile)
          const files = await Promise.all(filePromises)
          resolve(files)
        }

        const captureError = error => {
          reject(error)
        }

        if (navigator.device && navigator.device.capture) {
          navigator.device.capture.captureImage(
            captureSuccess,
            captureError,
            options
          )
        } else {
          reject(new Error('Capture API not available'))
        }
      })
    }

    let files = []
    try {
      files = await capture()
    } catch (error) {
      files = []
    }

    return files
  }

  return {
    takePhoto
  }
}

export default usePhotoGallery
