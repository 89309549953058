import {
  MEMBERS_FETCH_FAILED,
  MEMBERS_FETCH_REQUESTED,
  MEMBERS_FETCH_SUCCEEDED,
  MEMBERS_LIST_UPDATE_SELECTED
} from '@doinn/vendor/src/containers/member/list/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  LOADING_MORE: 'loading-more',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  selected: [],
  list: [],
  meta: {},
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case MEMBERS_FETCH_REQUESTED:
      return {
        ...state,
        ...initialState,
        status: STATUS.LOADING
      }
    case MEMBERS_FETCH_SUCCEEDED:
      return {
        ...state,
        list: [...payload.data],
        meta: { ...payload.meta },
        status: STATUS.SUCCESS
      }
    case MEMBERS_FETCH_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case MEMBERS_LIST_UPDATE_SELECTED:
      return {
        ...state,
        selected: [...payload]
      }
    default:
      return state
  }
}
