import {
  ONBOARDING_DEMO_DIALOG_CLOSE,
  ONBOARDING_DEMO_DIALOG_OPEN,
  ONBOARDING_DEMO_FAILED,
  ONBOARDING_DEMO_REQUESTED,
  ONBOARDING_DEMO_SUCCEEDED
} from '@doinn/shared/src/containers/onboarding/demo/constants'
import { USER_GET_SUCCEEDED } from '@doinn/shared/src/containers/user/constants'
import { ONBOARDING_SURVEY_SUCCEEDED } from '@doinn/shared/src/containers/onboarding/survey/constants'
import { ONBOARDING_SAAS_ANNOUNCEMENT_SUCCEEDED } from '@doinn/shared/src/containers/onboarding/saas-announcement/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  isOpened: false,
  status: STATUS.IDLE,
  type: 'account-setup'
}

const getIsOpened = (onboarding = null) =>
  onboarding?.status === 'pending' &&
  !!onboarding?.steps.find(step => step?.slug === 'demo' && !step?.completed)

const getStatus = ({
  setupOnboarding = null,
  saasAnnouncementOnboarding = null
}) => {
  const isDemoSetup = getIsOpened(setupOnboarding)
  const isSaasAnnouncementSetup = getIsOpened(saasAnnouncementOnboarding)
  return {
    isOpened: isDemoSetup || isSaasAnnouncementSetup,
    type: isSaasAnnouncementSetup ? 'saas-announcement' : 'account-setup'
  }
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case USER_GET_SUCCEEDED:
      return {
        ...initialState,
        ...getStatus(payload?.data?.business)
      }
    case ONBOARDING_DEMO_DIALOG_OPEN:
      return {
        ...initialState,
        isOpened: true
      }
    case ONBOARDING_DEMO_DIALOG_CLOSE:
      return {
        ...initialState,
        isOpened: false
      }
    case ONBOARDING_DEMO_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING
      }
    case ONBOARDING_DEMO_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case ONBOARDING_DEMO_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case ONBOARDING_SURVEY_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS,
        isOpened: getIsOpened(payload?.data),
        type: 'account-setup'
      }
    case ONBOARDING_SAAS_ANNOUNCEMENT_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS,
        isOpened: getIsOpened(payload?.data),
        type: 'saas-announcement'
      }
    default:
      return state
  }
}
