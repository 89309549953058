import React from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { endOfMonth, startOfMonth } from 'date-fns'

import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import { getQueryStringObject } from '@doinn/shared/src/util/url'
import { formatSystemDate } from '@doinn/shared/src/util/date-fns'
import { parseAppliedFilters } from '@doinn/shared/src/util/filters'
import TemplateDefault from 'components/TemplateDefault'

const ReportDetailsContainer = loadable(() =>
  lazyRetry(() => import('containers/reports/billingDetails/ReportDetails'))
)

const getDefaultFilters = ({ reportOf, reportBy, id }) => {
  const nowDate = new Date()
  const startOfMonthDate = startOfMonth(nowDate)
  const endOfMonthDate = endOfMonth(nowDate)
  return {
    reportOf,
    reportBy,
    id,
    page: 1,
    start: formatSystemDate(startOfMonthDate),
    end: formatSystemDate(endOfMonthDate),
    sourceServicesBy: 'doinn'
  }
}

const ReportDetails = ({ location }) => {
  const { t } = useTranslation()
  const { reportOf, reportBy, id } = useParams()
  const queryFilters = getQueryStringObject(location.search)
  const filters = parseAppliedFilters(
    getDefaultFilters({ reportOf, reportBy, id }),
    queryFilters
  )

  return (
    <TemplateDefault title={t('Report Details')}>
      <Helmet>
        <title>{t('Report Details')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <ReportDetailsContainer filters={filters} />
      </React.Suspense>
    </TemplateDefault>
  )
}

export default ReportDetails
