import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { editMemberApi } from '@doinn/vendor/src/api/members'
import {
  MEMBER_EDIT_FAILED,
  MEMBER_EDIT_REQUESTED,
  MEMBER_EDIT_SUCCEEDED
} from '@doinn/vendor/src/containers/member/edit/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* editMember(action) {
  try {
    const { member } = action.payload
    yield call(editMemberApi, member)
    yield put({
      type: MEMBER_EDIT_SUCCEEDED,
      payload: member,
      snackbar: {
        variant: 'success',
        message: i18n.t('The member has been edited successfully')
      }
    })
  } catch (e) {
    yield put({
      type: MEMBER_EDIT_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* membersEditSaga() {
  yield all([takeLatest(MEMBER_EDIT_REQUESTED, editMember)])
}
