import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getMemberApi } from '@doinn/vendor/src/api/members'
import {
  MEMBER_GET_FAILED,
  MEMBER_GET_REQUESTED,
  MEMBER_GET_SUCCEEDED
} from '@doinn/vendor/src/containers/member/get/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* getMember(action) {
  try {
    const response = yield call(getMemberApi, action.payload.params)
    yield put({
      type: MEMBER_GET_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: MEMBER_GET_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* memberGetSaga() {
  yield all([takeLatest(MEMBER_GET_REQUESTED, getMember)])
}
