import {
  USER_CREDIT_UPDATE_SUCCEEDED,
  USER_GET_REQUESTED,
  USER_LOGOUT_REQUESTED
} from '@doinn/shared/src/containers/user/constants'

export function getLoggedUser() {
  return { type: USER_GET_REQUESTED }
}

export function logout() {
  return { type: USER_LOGOUT_REQUESTED }
}

export function updateUserCredit(params = {}) {
  return { type: USER_CREDIT_UPDATE_SUCCEEDED, payload: params }
}
