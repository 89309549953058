import {
  ONBOARDING_SERVICES_FETCH_FAILED,
  ONBOARDING_SERVICES_FETCH_REQUESTED,
  ONBOARDING_SERVICES_FETCH_SUCCEEDED
} from 'containers/onboarding/steps/Services/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  propertyTypes: [],
  isLoading: false,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case ONBOARDING_SERVICES_FETCH_REQUESTED:
      return {
        ...state,
        propertyTypes: [],
        isLoading: true,
        status: STATUS.LOADING
      }
    case ONBOARDING_SERVICES_FETCH_SUCCEEDED:
      return {
        ...state,
        propertyTypes: payload.data,
        isLoading: false,
        status: STATUS.SUCCESS
      }
    case ONBOARDING_SERVICES_FETCH_FAILED:
      return {
        ...state,
        ...initialState,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
