import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function updateOnboardingStepsApi(steps) {
  const config = {
    steps
  }
  return DoinnApi.put(`/vendor/onboarding/steps`, config)
}

export function getOnboardingStepsApi() {
  const config = {}
  return DoinnApi.get(`/vendor/onboarding/steps`, config)
}

export function finishOnboardingApi() {
  const config = {}
  return DoinnApi.post(`/vendor/onboarding/finish`, config)
}
