import { cloneDeep, reject } from 'lodash'
import {
  JOB_EXECUTION_FINISH_SUCCEEDED,
  JOB_UPDATE_ESTIMATED_START_REQUESTED,
  JOB_UPDATE_ESTIMATED_START_SUCCEEDED,
  JOB_UPDATE_EXECUTION_SUCCEEDED
} from 'containers/job/constants'
import { ORDER_SERVICE_DELETE_SUCCEEDED } from 'containers/order-services/delete/constants'
import {
  JOB_ASSIGN_STAFF_EDIT_REQUESTED,
  JOB_ASSIGN_STAFF_EDIT_SUCCEEDED
} from 'containers/job/assign-staff/constants'
import { JOB_EXTRA_SERVICE_ADD_FORM_SAVE_SUCCEEDED } from 'containers/job/extra-service/add/constants'
import {
  JOBS_FETCH_FAILED,
  JOBS_FETCH_MORE_FAILED,
  JOBS_FETCH_MORE_REQUESTED,
  JOBS_FETCH_MORE_SUCCEEDED,
  JOBS_FETCH_REPORT_URL_FAILED,
  JOBS_FETCH_REPORT_URL_REQUESTED,
  JOBS_FETCH_REPORT_URL_SUCCEEDED,
  JOBS_FETCH_REQUESTED,
  JOBS_FETCH_SUCCEEDED,
  JOBS_FINISHED_LIST_EMPTIED,
  JOBS_UPDATE_SELECTED_JOBS,
  JOB_RESET_EXECUTION_STATE
} from 'containers/jobs/constants'
import { JOB_FINISH_SUCCEEDED } from 'containers/job/finish/note/constants'
import { JOB_GET_SUCCEEDED } from '@doinn/vendor/src/containers/job/constants'
import { JOB_SERVICE_DELETE_SUCCEEDED } from 'containers/job/delete/constants'
import { JOB_CANCEL_REQUEST_SUCCEEDED } from '@doinn/vendor/src/containers/job/cancel-request/constants'
import { format } from 'date-fns'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  LOADING_MORE: 'loading-more',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  list: [],
  finishedJobList: [],
  meta: {},
  status: STATUS.IDLE,
  selectedList: [],
  isReportLoaded: false,
  isReportLoading: false,
  isReportFailed: false
}

const parseAssignedStaffToState = (state, payload) => {
  const newState = cloneDeep(state)
  payload.data.jobs.forEach(job => {
    newState.list = newState.list.map(item => {
      if (item.id === job.id) {
        return {
          ...item,
          employees: [...job.employees],
          team: job.team ? { ...job.team } : null,
          isLoading: false
        }
      }
      return item
    })
  })
  return newState
}

const parseOptimisticUIJobEditStaff = (state, payload) => {
  const newState = cloneDeep(state)
  newState.list = newState.list.map(item => {
    if (item.id === payload.params.jobId) {
      return {
        ...item,
        employees: payload.params.employees,
        isLoading: true
      }
    }
    return item
  })
  return newState
}

const parseOptimisticUIJobEstimatedStartToState = (state, payload) => {
  const newState = cloneDeep(state)
  newState.list = newState.list.map(item => {
    if (item.id === payload.params.jobId) {
      return {
        ...item,
        estimatedStart: {
          ...item.estimatedStart,
          time: format(new Date(payload.params.scheduleStartTime), 'HHmm')
        },
        isLoading: true
      }
    }
    return item
  })
  return newState
}

const parseEditedJobEstimatedStartToState = (state, payload) => {
  const newState = cloneDeep(state)
  const job = payload.data
  newState.list = newState.list.map(item => {
    if (item.id === job.id) {
      return {
        ...item,
        date: job.date,
        duration: job.duration,
        estimatedStart: cloneDeep(job.estimatedStart),
        requestedStart: cloneDeep(job.requestedStart),
        isLoading: false
      }
    }
    return item
  })
  return newState
}

const parseFinishedJobs = (list, finishedJob) => {
  return [...list, finishedJob]
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case JOBS_FETCH_REQUESTED:
      return {
        ...state,
        ...initialState,
        status: STATUS.LOADING
      }
    case JOBS_FETCH_SUCCEEDED:
      return {
        ...state,
        list: [...payload.data],
        meta: { ...payload.meta },
        status: STATUS.SUCCESS
      }
    case JOBS_FETCH_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case JOBS_FETCH_MORE_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING_MORE
      }
    case JOBS_FETCH_MORE_SUCCEEDED:
      return {
        ...state,
        list: [...state.list, ...payload.data],
        meta: { ...payload.meta },
        status: STATUS.SUCCESS
      }
    case JOBS_FETCH_MORE_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case JOB_EXTRA_SERVICE_ADD_FORM_SAVE_SUCCEEDED:
      return {
        ...state,
        list: state.list.map((item, index) => {
          if (item.id === payload.jobId) {
            return {
              ...item,
              orderServices: [...item.orderServices].concat({ ...payload.data })
            }
          }
          return item
        })
      }
    case ORDER_SERVICE_DELETE_SUCCEEDED:
      return {
        ...state,
        list: state.list.map(item => {
          item.orderServices = reject(
            item.orderServices,
            orderService => orderService.id === payload.orderService.id
          )
          return item
        })
      }
    case JOB_SERVICE_DELETE_SUCCEEDED:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === payload.job.id) {
            return {
              ...item,
              ...payload.job
            }
          }
          return item
        })
      }
    case JOB_CANCEL_REQUEST_SUCCEEDED:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === payload.job.id) {
            return {
              ...item,
              ...payload.job
            }
          }
          return item
        })
      }
    case JOB_GET_SUCCEEDED:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === payload.data.id) {
            return {
              ...item,
              ...payload.data
            }
          }
          return item
        })
      }
    case JOB_RESET_EXECUTION_STATE:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === payload.data.id) {
            return {
              ...item,
              ...payload.data
            }
          }
          return item
        })
      }
    case JOB_ASSIGN_STAFF_EDIT_REQUESTED:
      return parseOptimisticUIJobEditStaff(state, payload)
    case JOB_ASSIGN_STAFF_EDIT_SUCCEEDED:
      return {
        ...parseAssignedStaffToState(state, payload),
        selectedList: []
      }
    case JOB_UPDATE_ESTIMATED_START_REQUESTED:
      return {
        ...parseOptimisticUIJobEstimatedStartToState(state, payload)
      }
    case JOB_UPDATE_ESTIMATED_START_SUCCEEDED:
      return {
        ...parseEditedJobEstimatedStartToState(state, payload)
      }
    case JOB_UPDATE_EXECUTION_SUCCEEDED:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === payload.data.id) {
            return {
              ...item,
              ...payload.data
            }
          }
          return item
        })
      }
    case JOB_EXECUTION_FINISH_SUCCEEDED:
      return {
        ...state,
        finishedJobList: parseFinishedJobs(state.finishedJobList, payload.data)
      }
    case JOB_FINISH_SUCCEEDED:
      return {
        ...state,
        finishedJobList: state.finishedJobList.filter(
          finishedJob => finishedJob.id !== payload.data.id
        )
      }
    case JOBS_FINISHED_LIST_EMPTIED:
      return {
        ...state,
        finishedJobList: []
      }
    case JOBS_UPDATE_SELECTED_JOBS:
      return {
        ...state,
        selectedList: [...payload]
      }
    case JOBS_FETCH_REPORT_URL_REQUESTED:
      return {
        ...state,
        isReportLoaded: false,
        isReportLoading: true,
        isReportFailed: false
      }
    case JOBS_FETCH_REPORT_URL_SUCCEEDED:
      return {
        ...state,
        isReportLoaded: true,
        isReportLoading: false,
        isReportFailed: false
      }
    case JOBS_FETCH_REPORT_URL_FAILED:
      return {
        ...state,
        isReportLoaded: false,
        isReportLoading: false,
        isReportFailed: true
      }
    default:
      return state
  }
}
