import {
  ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_CLOSE_REQUESTED,
  ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_OPEN_REQUESTED,
  ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_SAVE_FAILED,
  ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_SAVE_REQUESTED,
  ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_SAVE_SUCCEEDED
} from 'containers/order-services/extra-item/edit/constants'

export const STATUS = {
  IDLE: 'idle',
  SAVING: 'saving',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  isOpened: false,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_OPEN_REQUESTED:
      return {
        ...initialState,
        isOpened: true
      }
    case ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_CLOSE_REQUESTED:
      return {
        ...initialState,
        isOpened: false
      }
    case ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_SAVE_REQUESTED:
      return {
        ...state,
        status: STATUS.SAVING
      }
    case ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_SAVE_SUCCEEDED:
      return {
        ...initialState,
        status: STATUS.SUCCESS
      }
    case ORDER_SERVICE_EXTRA_ITEMS_EDIT_FORM_SAVE_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
