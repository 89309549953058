import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchIntegrationsCatalogApi } from '@doinn/host/src/api/integrations'
import {
  INTEGRATIONS_CATALOG_FETCH_FAILED,
  INTEGRATIONS_CATALOG_FETCH_MORE_FAILED,
  INTEGRATIONS_CATALOG_FETCH_MORE_REQUESTED,
  INTEGRATIONS_CATALOG_FETCH_MORE_SUCCEEDED,
  INTEGRATIONS_CATALOG_FETCH_REQUESTED,
  INTEGRATIONS_CATALOG_FETCH_SUCCEEDED
} from '@doinn/host/src/containers/integrations/catalog/list/constants'
import { INTEGRATION_ADD_SUCCEEDED } from '@doinn/host/src/containers/integrations/add/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchIntegrationsCatalog(action) {
  try {
    const response = yield call(
      fetchIntegrationsCatalogApi,
      action.payload.params
    )
    const { data, meta } = response.data
    yield put({
      type: INTEGRATIONS_CATALOG_FETCH_SUCCEEDED,
      payload: {
        data,
        meta
      }
    })
  } catch (e) {
    yield put({
      type: INTEGRATIONS_CATALOG_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* fetchMoreIntegrationsCatalog(action) {
  try {
    const response = yield call(
      fetchIntegrationsCatalogApi,
      action.payload.params
    )
    const { data, meta } = response.data
    yield put({
      type: INTEGRATIONS_CATALOG_FETCH_MORE_SUCCEEDED,
      payload: { data, meta }
    })
  } catch (e) {
    yield put({
      type: INTEGRATIONS_CATALOG_FETCH_MORE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* integrationsCatalogSaga() {
  yield all([
    takeLatest(INTEGRATION_ADD_SUCCEEDED, fetchIntegrationsCatalog),
    takeLatest(INTEGRATIONS_CATALOG_FETCH_REQUESTED, fetchIntegrationsCatalog),
    takeLatest(
      INTEGRATIONS_CATALOG_FETCH_MORE_REQUESTED,
      fetchMoreIntegrationsCatalog
    )
  ])
}
