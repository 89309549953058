import {
  DOCUMENTS_DELETE_FAILED,
  DOCUMENTS_DELETE_REQUESTED,
  DOCUMENTS_DELETE_SUCCEEDED,
  DOCUMENTS_FETCH_FAILED,
  DOCUMENTS_FETCH_REQUESTED,
  DOCUMENTS_FETCH_SUCCEEDED,
  DOCUMENTS_UPLOAD_FAILED,
  DOCUMENTS_UPLOAD_REQUESTED,
  DOCUMENTS_UPLOAD_SUCCEEDED
} from 'containers/onboarding/steps/Documents/constants'

export const initialState = {
  documents: [],
  isLoading: false
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case DOCUMENTS_FETCH_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case DOCUMENTS_FETCH_SUCCEEDED:
      return {
        ...state,
        documents: payload.map(document => ({
          ...document,
          isLoading: false
        })),
        isLoading: false
      }
    case DOCUMENTS_FETCH_FAILED:
      return {
        ...state,
        ...initialState
      }
    case DOCUMENTS_UPLOAD_REQUESTED:
      return {
        ...state,
        documents: state.documents.map(document => {
          const isUploading = payload.params.documentSlug === document.slug
          if (!isUploading) return { ...document }
          return {
            ...document,
            isLoading: true
          }
        })
      }
    case DOCUMENTS_UPLOAD_SUCCEEDED:
      return {
        ...state,
        documents: state.documents.map(document => {
          const isUploadedDocument = payload.documentSlug === document.slug
          if (!isUploadedDocument) return { ...document }
          return {
            ...document,
            document: payload.document || null,
            isLoading: false
          }
        })
      }
    case DOCUMENTS_UPLOAD_FAILED:
      return {
        ...state,
        documents: state.documents.map(document => {
          const isUploadedDocument = payload.documentSlug === document.slug
          if (!isUploadedDocument) return { ...document }
          return {
            ...document,
            isLoading: false
          }
        })
      }
    case DOCUMENTS_DELETE_REQUESTED:
      return {
        ...state,
        documents: state.documents.map(document => {
          const isDeleting =
            document.document && document.document.id === payload.params.id
          if (!isDeleting) return { ...document }
          return {
            ...document,
            isLoading: true
          }
        })
      }
    case DOCUMENTS_DELETE_SUCCEEDED:
      return {
        ...state,
        documents: state.documents.map(document => {
          const isDeletedDocument =
            document.document && document.document.id === payload.id
          if (!isDeletedDocument) return { ...document }
          return {
            ...document,
            document: null,
            isLoading: false
          }
        })
      }
    case DOCUMENTS_DELETE_FAILED:
      return {
        ...state,
        documents: state.documents.map(document => {
          const isDeleting =
            document.document && document.document.id === payload.id
          if (!isDeleting) return { ...document }
          return {
            ...document,
            isLoading: false
          }
        })
      }
    default:
      return state
  }
}
