import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchMembersInvitationsApi } from '@doinn/vendor/src/api/membersInvitations'
import {
  MEMBERS_INVITATIONS_FETCH_FAILED,
  MEMBERS_INVITATIONS_FETCH_MORE_FAILED,
  MEMBERS_INVITATIONS_FETCH_MORE_REQUESTED,
  MEMBERS_INVITATIONS_FETCH_MORE_SUCCEEDED,
  MEMBERS_INVITATIONS_FETCH_REQUESTED,
  MEMBERS_INVITATIONS_FETCH_SUCCEEDED
} from '@doinn/vendor/src/containers/member/invitation/list/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchMembersInvitations(action) {
  try {
    const response = yield call(
      fetchMembersInvitationsApi,
      action.payload.params
    )
    const { data, meta } = response.data
    yield put({
      type: MEMBERS_INVITATIONS_FETCH_SUCCEEDED,
      payload: {
        data,
        meta
      }
    })
  } catch (e) {
    yield put({
      type: MEMBERS_INVITATIONS_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* fetchMoreMembersInvitations(action) {
  try {
    const response = yield call(
      fetchMembersInvitationsApi,
      action.payload.params
    )
    const { data, meta } = response.data
    yield put({
      type: MEMBERS_INVITATIONS_FETCH_MORE_SUCCEEDED,
      payload: { data, meta }
    })
  } catch (e) {
    yield put({
      type: MEMBERS_INVITATIONS_FETCH_MORE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* membersInvitationsSaga() {
  yield all([
    takeLatest(MEMBERS_INVITATIONS_FETCH_REQUESTED, fetchMembersInvitations),
    takeLatest(
      MEMBERS_INVITATIONS_FETCH_MORE_REQUESTED,
      fetchMoreMembersInvitations
    )
  ])
}
