import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Radio,
  makeStyles
} from '@material-ui/core'
import LoadingList from '@doinn/shared/src/components/common/loading/LoadingList'

const MAX_ONE_COLUMN_ITEMS = 5
const MAX_DISPLAYED_ITEMS = 10

const useStyles = makeStyles(theme => {
  const notMobileBreakpoint = theme.breakpoints.up('sm')

  return {
    list: {
      backgroundColor: theme.palette.background.paper,
      paddingTop: theme.spacing(1)
    },
    listSubheader: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      width: '100%'
    },
    listItem: {
      minHeight: theme.spacing(4),
      paddingTop: 0,
      paddingBottom: 0
    },
    listItemLoading: {
      minHeight: theme.spacing(4),
      padding: theme.spacing(0, 2.5),
      '& .MuiSkeleton-text': {
        margin: 0
      }
    },
    listItemIcon: {
      minWidth: theme.spacing(4)
    },
    listItemIconCheckbox: {
      padding: theme.spacing(0.5),
      marginLeft: theme.spacing(-0.75)
    },
    loadMoreButton: {
      width: '100%'
    },
    collapsedList: {
      width: '100%'
    },
    gridContainer: {
      [notMobileBreakpoint]: {
        display: 'flex',
        flexWrap: 'wrap'
      }
    },
    gridItemMultipleColumn: {
      [notMobileBreakpoint]: {
        width: '50%'
      }
    }
  }
})

const SelectionList = ({
  isLoading,
  isDisabled,
  checkedItems,
  subheader,
  filterName,
  items,
  type,
  isMenu,
  onChange
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const gridContainerClass = isMenu ? '' : classes.gridContainer
  const gridItemMultipleColumnClass = isMenu
    ? ''
    : classes.gridItemMultipleColumn

  const [isExpanded, setIsExpanded] = React.useState(false)

  const listSubheader = subheader ? (
    <ListSubheader className={classes.listSubheader}>{subheader}</ListSubheader>
  ) : null

  if (isLoading) {
    return (
      <LoadingList
        primary
        items={4}
        listProps={{
          className: `${classes.list} ${gridContainerClass}`,
          subheader: listSubheader
        }}
        listItemProps={{
          className: classes.listItemLoading
        }}
      />
    )
  }

  const totalItems = items ? items.length : 0

  if (!totalItems) return null

  const isFiltered = checkedItems && checkedItems.length

  const maxDisplayedItems = isFiltered ? totalItems : MAX_DISPLAYED_ITEMS
  const displayedItems = items.slice(0, maxDisplayedItems)
  const hiddenItems = items.slice(maxDisplayedItems, totalItems)
  const hasHiddenItems = !!hiddenItems.length
  const expanderText = isExpanded
    ? // i18next-extract-disable-next-line
      t('view less ' + filterName)
    : // i18next-extract-disable-next-line
      t('view more ' + filterName)
  const expanderPrimaryTypographyProps = {
    color: 'primary',
    variant: 'body2'
  }

  const handleExpandToggleClick = () => {
    const newExpanded = !isExpanded
    setIsExpanded(newExpanded)
  }

  const renderListItem = (filterName, item) => {
    const itemId = item.id.toString()
    let isChecked = !!(
      checkedItems &&
      checkedItems.length &&
      checkedItems.indexOf(itemId) !== -1
    )

    if (type === 'radio') {
      isChecked = isChecked && checkedItems.length === itemId.length
    }

    const isMultipleColumns = !isMenu && totalItems > MAX_ONE_COLUMN_ITEMS
    const multipleColumnClass = isMultipleColumns
      ? gridItemMultipleColumnClass
      : ''

    const handleClick = () => {
      onChange && onChange(filterName, itemId)
    }

    return (
      <ListItem
        key={item.id}
        className={`${classes.listItem} ${multipleColumnClass}`}
        role={undefined}
        button={!isDisabled}
        onClick={isDisabled ? null : handleClick}
      >
        <ListItemIcon className={classes.listItemIcon}>
          {renderListItemIcon(isChecked)}
        </ListItemIcon>
        <ListItemText primary={item.name} />
      </ListItem>
    )
  }

  const renderListItemIcon = isChecked => {
    const iconProps = {
      edge: 'start',
      checked: isChecked,
      disabled: isDisabled,
      tabIndex: -1,
      disableRipple: true,
      className: classes.listItemIconCheckbox
    }

    switch (type) {
      case 'radio':
        return <Radio {...iconProps} />
      case 'checkbox':
      default:
        return <Checkbox {...iconProps} />
    }
  }

  return (
    <List
      subheader={listSubheader}
      className={`${classes.list} ${gridContainerClass}`}
    >
      {displayedItems.map(item => renderListItem(filterName, item))}
      {hasHiddenItems && (
        <React.Fragment>
          <Collapse
            in={isExpanded}
            timeout='auto'
            unmountOnExit
            className={classes.collapsedList}
          >
            <div className={gridContainerClass}>
              {hiddenItems.map(item => renderListItem(filterName, item))}
            </div>
          </Collapse>
          <ListItem
            className={`${classes.listItem} ${classes.loadMoreButton}`}
            role={undefined}
            button
            onClick={handleExpandToggleClick}
          >
            <ListItemText
              primary={expanderText}
              primaryTypographyProps={expanderPrimaryTypographyProps}
            />
          </ListItem>
        </React.Fragment>
      )}
    </List>
  )
}

SelectionList.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  checkedItems: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  subheader: PropTypes.string,
  filterName: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  type: PropTypes.oneOf(['radio', 'checkbox']),
  isMenu: PropTypes.bool,
  onChange: PropTypes.func
}

SelectionList.defaultProps = {}

export default SelectionList
