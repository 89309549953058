import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getpaymentMethodsApi } from '@doinn/shared/src/api/payments'
import {
  GATEWAYS_GET_METHODS_FAILED,
  GATEWAYS_GET_METHODS_REQUESTED,
  GATEWAYS_GET_METHODS_SUCCEEDED
} from '@doinn/shared/src/containers/gateways/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* getPaymentMethods() {
  try {
    const response = yield call(getpaymentMethodsApi)

    yield put({
      type: GATEWAYS_GET_METHODS_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: GATEWAYS_GET_METHODS_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* paymentGatewaysSagas() {
  yield all([takeLatest(GATEWAYS_GET_METHODS_REQUESTED, getPaymentMethods)])
}
