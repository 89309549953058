import { omit } from 'lodash'
import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function getpaymentMethodsApi() {
  const config = {}
  return DoinnApi.get(`/payments/methods`, config)
}

export function getSetupIntentApi(data) {
  const config = {}
  const { gateway } = data
  const params = omit(data, ['gateway'])
  return DoinnApi.post(`/payments/${gateway}/setup-intents`, params, config)
}

export function addPaymentMethodApi(params) {
  const config = {}
  return DoinnApi.post(`/payments/methods`, params, config)
}

export function updatePaymentMethodApi(params) {
  const config = {}
  const { id, paymentMethod } = params
  const body = {
    external_id: paymentMethod
  }
  return DoinnApi.put(`/payments/methods/${id}`, body, config)
}

export function getpaymentMethodsAvailableApi() {
  const config = {}
  return DoinnApi.get(`/payments/methods/available`, config)
}

export function updatePaymentMethodDefaultApi({ id }) {
  const config = {}
  return DoinnApi.patch(`/payments/methods/${id}/default`, config)
}
