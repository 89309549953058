import React from 'react'
import { Helmet } from 'react-helmet'
import { Box, Container, makeStyles } from '@material-ui/core'

import useAppDetector from '@doinn/shared/src/hooks/useAppDetector'
import AuthHeader from '@doinn/vendor/src/containers/app/AuthHeader'
import TemplateBlank from '@doinn/shared/src/components/templates/TemplateBlank'
import AuthMainMenu from '@doinn/vendor/src/containers/app/components/AuthMainMenu'

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      background: theme.palette.common.white
    }
  },
  container: {
    minWidth: 0
  }
}))

const TemplateAuth = ({
  children,
  headerLeftChildren,
  headerRightChildren,
  drawerChildren,
  containerClassName,
  title
}) => {
  const isApp = useAppDetector()
  const classes = useStyles()

  const [isMobileMainMenuOpened, setMobileMainMenuOpened] =
    React.useState(false)

  const handleToggleMainMenu = () => {
    setMobileMainMenuOpened(!isMobileMainMenuOpened)
  }

  return (
    <TemplateBlank>
      <Helmet defaultTitle='Doinn' titleTemplate='%s - Doinn'>
        <meta charSet='utf-8' />
      </Helmet>
      <Box display='flex'>
        {!isApp && (
          <AuthMainMenu
            drawerContent={drawerChildren}
            isMobileMenuOpened={isMobileMainMenuOpened}
            onToggleMainMenu={handleToggleMainMenu}
          />
        )}
        <Container
          maxWidth='sm'
          className={`${classes.container} ${containerClassName || ''}`}
        >
          {!isApp && (
            <AuthHeader
              leftChildren={headerLeftChildren}
              rightChildren={headerRightChildren}
              title={title}
            />
          )}
          {children}
        </Container>
      </Box>
    </TemplateBlank>
  )
}

export default TemplateAuth
