import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchAutomationsApi } from '@doinn/host/src/api/automations'
import {
  AUTOMATIONS_FETCH_FAILED,
  AUTOMATIONS_FETCH_MORE_FAILED,
  AUTOMATIONS_FETCH_MORE_REQUESTED,
  AUTOMATIONS_FETCH_MORE_SUCCEEDED,
  AUTOMATIONS_FETCH_REQUESTED,
  AUTOMATIONS_FETCH_SUCCEEDED
} from '@doinn/host/src/containers/automations/list/constants'
import { AUTOMATIONS_ACTIVATE_SUCCEEDED } from '@doinn/host/src/containers/automations/list/activate/constants'
import { AUTOMATIONS_DEACTIVATE_SUCCEEDED } from '@doinn/host/src/containers/automations/list/deactivate/constants'
import { AUTOMATIONS_DELETE_SUCCEEDED } from '@doinn/host/src/containers/automations/list/delete/constants'
import { fetchAutomations as fetchAutomationsAction } from '@doinn/host/src/containers/automations/list/actions'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* resetAutomations() {
  yield put(fetchAutomationsAction())
}

function* fetchAutomations(action) {
  try {
    const response = yield call(fetchAutomationsApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: AUTOMATIONS_FETCH_SUCCEEDED,
      payload: {
        data,
        meta
      }
    })
  } catch (e) {
    yield put({
      type: AUTOMATIONS_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* fetchMoreAutomations(action) {
  try {
    const response = yield call(fetchAutomationsApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: AUTOMATIONS_FETCH_MORE_SUCCEEDED,
      payload: { data, meta }
    })
  } catch (e) {
    yield put({
      type: AUTOMATIONS_FETCH_MORE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* automationsSaga() {
  yield all([
    takeLatest(AUTOMATIONS_ACTIVATE_SUCCEEDED, resetAutomations),
    takeLatest(AUTOMATIONS_DEACTIVATE_SUCCEEDED, resetAutomations),
    takeLatest(AUTOMATIONS_DELETE_SUCCEEDED, resetAutomations),
    takeLatest(AUTOMATIONS_FETCH_REQUESTED, fetchAutomations),
    takeLatest(AUTOMATIONS_FETCH_MORE_REQUESTED, fetchMoreAutomations)
  ])
}
