import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography, makeStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { useNetworkStatus } from '@doinn/shared/src/hooks'

const useStyles = makeStyles(theme => ({
  alert: {
    background: theme.palette.secondary.main,
    color: theme.palette.common.white
  },
  button: {
    marginLeft: 15
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  }
}))

const Warning = ({ className }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const networkStatus = useNetworkStatus()
  const isOnline = networkStatus.connected

  if (isOnline) {
    return null
  }

  const handleReloadClick = () => window.location.reload()

  return (
    <Alert
      elevation={10}
      icon={false}
      className={clsx(classes.alert, {
        [className]: className
      })}
    >
      <Box className={classes.content}>
        <Typography>{t("Looks like you're offline")}</Typography>
        <Button
          className={`${classes.button} inverse`}
          color='secondary'
          onClick={handleReloadClick}
          variant='outlined'
        >
          {t('Try to connect')}
        </Button>
      </Box>
    </Alert>
  )
}

export default Warning
