import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function createCheckoutApi(params) {
  const config = {}
  return DoinnApi.post('/checkouts', params, config)
}

export function getCheckoutApi(checkoutId) {
  const config = {}
  return DoinnApi.get(`/checkouts/${checkoutId}`, config)
}

export function confirmCheckoutApi({ uuid, total }) {
  const config = {}
  const params = {
    total
  }
  return DoinnApi.post(`/checkouts/${uuid}/confirm`, params, config)
}

export function applyCouponApi({ uuid, coupon }) {
  const config = {}
  const params = {
    coupon
  }
  return DoinnApi.post(`/checkouts/${uuid}/coupon`, params, config)
}

export function removeCouponApi({ uuid }) {
  const config = {}
  return DoinnApi.delete(`/checkouts/${uuid}/coupon`, config)
}

export function removeJobFromCheckoutApi({ uuid, jobId }) {
  const config = {}
  return DoinnApi.delete(`/checkouts/${uuid}/jobs/${jobId}`, config)
}

export function payCheckoutApi({
  uuid,
  type,
  methodId,
  total,
  paymentIntentId
}) {
  const config = {}
  const params = {
    total,
    payment_method: {
      type,
      payment_method_id: methodId
    }
  }

  if (paymentIntentId) {
    params.payment_method = {
      ...params.payment_method,
      payment_intent_id: paymentIntentId
    }
  }

  return DoinnApi.post(`/checkouts/${uuid}/confirm`, params, config)
}

export function applyCreditsApi({ uuid, credits }) {
  const config = {}
  const params = {
    value: credits
  }
  return DoinnApi.patch(`/checkouts/${uuid}/credits`, params, config)
}

export function removeCreditsApi({ uuid }) {
  const config = {}
  return DoinnApi.delete(`/checkouts/${uuid}/credits`, config)
}

export function billingSubscriptionApi(params) {
  const config = {}
  return DoinnApi.post(`/host/billings/subscription`, params, config)
}
