import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function getPropertyApi({ id, userRole = 'host' }) {
  const config = {}
  if (userRole === 'vendor') {
    return DoinnApi.get(`/vendor/properties/${id}`, config)
  }
  return DoinnApi.get(`/host/properties/${id}`, config)
}

export function addPropertyApi(property) {
  const config = {}
  return DoinnApi.post(`/host/properties`, { ...property }, config)
}

export function editPropertyApi(property = {}) {
  const config = {}
  const data = { ...property }
  return DoinnApi.put(`/host/properties/${property.id}`, data, config)
}

export function editPropertyInstructionsApi(property = {}) {
  const config = {}
  const data = { ...property?.instructions }
  return DoinnApi.put(
    `/vendor/properties/${property.id}/instructions`,
    data,
    config
  )
}

export function deletePropertyApi(id) {
  const config = {}
  return DoinnApi.delete(`/host/properties/${id}`, config)
}

export function importPropertiesApi(params) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  const formData = new window.FormData()
  const { file } = params

  if (file) {
    formData.append('file', file)
  }

  return DoinnApi.post(`/host/properties/import`, formData, config)
}

export function updatePropertyEmployeesApi(propertyId, employees) {
  const config = {}
  const params = { employees }
  return DoinnApi.put(
    `/vendor/properties/${propertyId}/employees?update_services=not_assigned`,
    params,
    config
  )
}

export function updatePropertyTeamApi(propertyId, teamId) {
  const config = {}
  const params = { team_id: teamId }
  return DoinnApi.put(
    `/vendor/properties/${propertyId}/teams?update_services=not_assigned`,
    params,
    config
  )
}
