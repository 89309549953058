import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { getUserLoggedInAs } from '@doinn/shared/src/containers/user-logged-in-as/actions'
import { useDidMount } from '@doinn/shared/src/hooks'
import withAuthorization from '@doinn/shared/src/hoc/authorization/withAuthorization'
import BottomMainMenuComponent from '@doinn/shared/src/components/app/BottomMainMenu'

const BottomMainMenu = ({
  can,
  canAny,
  getUserLoggedInAs,
  userLoggedInAs,
  unreadMessageCounter
}) => {
  const { t } = useTranslation()

  useDidMount(() => {
    getUserLoggedInAs()
  })

  const items = [
    {
      id: 'services',
      link: '/services',
      label: t('Services'),
      icon: 'calendar',
      isAllowed: can('read-jobs'),
      badgeCounter: 0
    },
    {
      id: 'properties',
      link: '/properties',
      label: t('Properties'),
      icon: 'properties',
      isAllowed:
        can('read-spaces') && canAny(['update-spaces', 'update-space-staff']),
      badgeCounter: 0
    },
    {
      id: 'staff',
      link: '/staff',
      label: t('People'),
      icon: 'people',
      isAllowed: canAny([
        'read-staff',
        'read-employees',
        'read-members',
        'read-people-staff',
        'read-people-admins',
        'read-people-superadmins',
        'read-teams',
        'read-people',
        'read-people-providers',
        'read-people-customers',
        'read-invitations'
      ]),
      badgeCounter: 0
    },
    {
      id: 'messages',
      link: '/messages',
      label: t('Messages'),
      icon: 'message_text',
      isAllowed: can('read-chats') && isEmpty(userLoggedInAs),
      badgeCounter: unreadMessageCounter
    },
    {
      id: 'profile',
      link: '/profile',
      label: t('Profile'),
      icon: 'account',
      isAllowed: true,
      badgeCounter: 0
    }
  ]

  return <BottomMainMenuComponent items={items} />
}

const mapStateToProps = state => ({
  userLoggedInAs: state.userLoggedInAs.data,
  unreadMessageCounter: state.chats.unreadMessageCounter
})

const mapDispatchToProps = dispatch => ({
  getUserLoggedInAs: bindActionCreators(getUserLoggedInAs, dispatch)
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization
)(BottomMainMenu)
