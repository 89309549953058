import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getIntegrationApi } from '@doinn/host/src/api/integrations'
import {
  INTEGRATION_GET_FAILED,
  INTEGRATION_GET_REQUESTED,
  INTEGRATION_GET_SUCCEEDED
} from '@doinn/host/src/containers/integrations/get/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* getIntegration(action) {
  try {
    const { id } = action.payload
    const response = yield call(getIntegrationApi, { id })
    yield put({
      type: INTEGRATION_GET_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: INTEGRATION_GET_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* integrationsSaga() {
  yield all([takeLatest(INTEGRATION_GET_REQUESTED, getIntegration)])
}
