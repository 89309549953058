import React from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'

import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import TemplateDefault from 'components/TemplateDefault'

const BillingEditContainer = loadable(() =>
  lazyRetry(() =>
    import(
      '@doinn/shared/src/containers/subscription-billing/billing/add/BillingAdd'
    )
  )
)

const BillingEdit = () => {
  const { t } = useTranslation()
  return (
    <TemplateDefault title={t('Subscription and billing')}>
      <Helmet>
        <title>{t('Subscription and billing')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <BillingEditContainer />
      </React.Suspense>
    </TemplateDefault>
  )
}

export default BillingEdit
