import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { throttle } from 'lodash'
import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core'
import { Field } from 'formik'
import Autocomplete from '@material-ui/lab/Autocomplete'

const loadScript = (src, position, id) => {
  if (!position) return
  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  position.appendChild(script)
}

const autocompleteService = {
  current: null
}

const useStyles = makeStyles(theme => ({
  autocomplete: {}
}))

const GooglePlacesAutocompleteComponent = ({
  className,
  country,
  label,
  defaultInputValue,
  disabled,
  keepSelectedValue,
  noOptionsText,
  onChange,
  onChangePlaceDetails,
  onClear,
  placeTypes,
  field,
  form: { touched, errors },
  ...otherProps
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const parsedNoOptionsText = noOptionsText || t('No results')

  const [value, setValue] = React.useState('')
  const [inputValue, setInputValue] = React.useState(defaultInputValue)
  const [options, setOptions] = React.useState([])
  const loaded = React.useRef(false)

  if (typeof window !== 'undefined' && !loaded.current) {
    const googleMapsScriptId = 'google-maps-script'
    if (!document.querySelector(`#${googleMapsScriptId}`)) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyBzB4tRogZOGz5hmumcBEFamCh2yeTh8tk&libraries=places',
        document.querySelector('head'),
        googleMapsScriptId
      )
    }
    loaded.current = true
  }

  const handleCloseAutocomplete = event => {
    if (!keepSelectedValue) {
      setInputValue('')
      setValue('')
    }
  }

  const handleChangeInputValue = (event, newValue, reason) => {
    setInputValue(newValue || defaultInputValue)
  }

  const handleChangeAutocompleteWithPlaceDetails = (event, place) => {
    if (window?.google && place?.place_id) {
      const request = {
        placeId: place.place_id,
        fields: [
          'address_component',
          'adr_address',
          'business_status',
          'formatted_address',
          'geometry',
          'icon',
          'name',
          'place_id',
          'plus_code',
          'type',
          'url',
          'utc_offset_minutes',
          'vicinity'
        ]
      }
      const mapElement = document.createElement('div')
      const placeService = new window.google.maps.places.PlacesService(
        mapElement
      )
      placeService.getDetails(request, (placeDetails, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          onChangePlaceDetails && onChangePlaceDetails(placeDetails)
        }
      })
    }
  }

  const handleChangeAutocomplete = (event, selectedOption, reason) => {
    if (reason === 'clear') {
      onClear && onClear()
    }
    if (selectedOption) {
      onChange && onChange(selectedOption)
      onChangePlaceDetails &&
        handleChangeAutocompleteWithPlaceDetails(event, selectedOption)
    }
    if (keepSelectedValue) {
      setOptions(selectedOption ? [selectedOption, ...options] : options)
      setValue(selectedOption)
    } else {
      setInputValue('')
      setValue('')
    }
  }

  const getPlacePredictions = () =>
    throttle((request, callback) => {
      autocompleteService.current.getPlacePredictions(request, callback)
    }, 200)

  const memoizedGetPlacePredictions = React.useMemo(getPlacePredictions, [])

  React.useEffect(() => {
    let active = true

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) return undefined

    if (inputValue === '') {
      if (keepSelectedValue) {
        setOptions(value ? [value] : [])
      } else {
        setOptions([])
      }
      return undefined
    }

    memoizedGetPlacePredictions(
      {
        input: inputValue,
        types: placeTypes || [],
        componentRestrictions: {
          country: country || ''
        }
      },
      results => {
        if (active) {
          if (keepSelectedValue) {
            let newOptions = []
            if (value) {
              newOptions = [value]
            }
            if (results) {
              newOptions = [...newOptions, ...results]
            }
            setOptions(newOptions)
          } else {
            setOptions(results || [])
          }
        }
      }
    )

    return () => {
      active = false
    }
  }, [
    inputValue,
    memoizedGetPlacePredictions,
    country,
    placeTypes,
    keepSelectedValue,
    value
  ])

  React.useEffect(() => {
    setInputValue(defaultInputValue)
  }, [defaultInputValue])

  const isTouched = touched[field.name]
  const errorMessage = errors[field.name]?.placeId || ''
  const displayError = !!(isTouched && errorMessage)
  const helperText = displayError ? errorMessage : ''

  return (
    <Autocomplete
      getOptionLabel={option =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={options => options.filter(option => option !== '')}
      options={['', ...options]}
      filterSelectedOptions
      autoComplete
      includeInputInList
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          value={value}
          error={displayError}
          helperText={helperText}
          fullWidth
          {...field}
          {...otherProps}
        />
      )}
      noOptionsText={parsedNoOptionsText}
      renderOption={option => {
        return (
          <Grid container alignItems='center'>
            <Grid item xs>
              <Typography variant='body2' color='textSecondary'>
                <span>{option.structured_formatting.main_text}</span>
                <span>, </span>
                <span>{option.structured_formatting.secondary_text}</span>
              </Typography>
            </Grid>
          </Grid>
        )
      }}
      PaperComponent={({ children, ...otherProps }) => {
        return (
          <Paper {...otherProps}>
            {children}
            <Grid container alignItems='flex-end'>
              <Grid item xs />
              <Grid item>
                <Box px={2} pb={1}>
                  <img
                    src='https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png'
                    alt='powered by Google'
                    height={18}
                  />
                </Box>
              </Grid>
            </Grid>
          </Paper>
        )
      }}
      defaultValue=''
      disabled={disabled}
      value={value}
      inputValue={inputValue}
      className={`${className} ${classes.autocomplete}`}
      onChange={handleChangeAutocomplete}
      onInputChange={handleChangeInputValue}
      onClose={handleCloseAutocomplete}
    />
  )
}

GooglePlacesAutocompleteComponent.propTypes = {
  label: PropTypes.string,
  noOptionsText: PropTypes.string,
  placeTypes: PropTypes.arrayOf(PropTypes.string.isRequired),
  country: PropTypes.string,
  disabled: PropTypes.bool,
  defaultInputValue: PropTypes.string,
  keepSelectedValue: PropTypes.bool,
  onChange: PropTypes.func,
  onChangePlaceDetails: PropTypes.func,
  onClear: PropTypes.func
}

GooglePlacesAutocompleteComponent.defaultProps = {
  placeTypes: ['(cities)'],
  country: '',
  disabled: false,
  defaultInputValue: '',
  keepSelectedValue: false
}

class GooglePlacesAutocompleteField extends React.PureComponent {
  render() {
    return (
      <Field component={GooglePlacesAutocompleteComponent} {...this.props} />
    )
  }
}

GooglePlacesAutocompleteField.defaultProps = {}

export default GooglePlacesAutocompleteField
