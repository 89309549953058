import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import Select from '@doinn/shared/src/components/common/filter/Select'
import RadioList from '@doinn/shared/src/components/common/filter/RadioList'

const ListFiltersGroupBy = ({
  filters,
  disabled,
  onChangeFilters,
  groupOptions,
  variant
}) => {
  const { t } = useTranslation()
  let defaultOptions = [
    {
      id: 'spaces',
      name: t('Property')
    }
  ]

  if (filters.reportOf === 'customers') {
    defaultOptions = defaultOptions.concat([
      {
        id: 'hosts',
        name: t('Host')
      },
      {
        id: 'employees',
        name: t('Employee')
      }
    ])
  } else if (filters.reportOf === 'providers') {
    defaultOptions = defaultOptions.concat([
      {
        id: 'providers',
        name: t('Provider')
      }
    ])
  }

  const parsedGroupOptions = groupOptions || defaultOptions

  const handleChangeGroupByFilter = useCallback(
    (filterName, newValue) => {
      const params = cloneDeep({
        ...filters,
        page: 1 // Reset to first page but allow pagination
      })
      params[filterName] = newValue
      onChangeFilters && onChangeFilters(params)
    },
    [filters, onChangeFilters]
  )

  const renderListVariant = () => {
    return (
      <RadioList
        subheader={t('Group by')}
        filterName='reportBy'
        items={parsedGroupOptions}
        checkedItems={filters.reportBy}
        onChange={handleChangeGroupByFilter}
      />
    )
  }

  const renderMenuVariant = () => {
    return (
      <React.Fragment>
        <Typography variant='body2' gutterBottom>
          {t('Group by')}
        </Typography>
        <Select
          disabled={disabled}
          filterOptions={parsedGroupOptions}
          selectedFilter={filters.reportBy}
          buttonLabel={t('Group by')}
          filterName='reportBy'
          onChange={handleChangeGroupByFilter}
        />
      </React.Fragment>
    )
  }

  if (variant === 'list') return renderListVariant()
  return renderMenuVariant()
}

ListFiltersGroupBy.propTypes = {
  variant: PropTypes.oneOf(['list', 'menu']),
  groupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  filters: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    period: PropTypes.string
  }),
  onChangeFilters: PropTypes.func
}

ListFiltersGroupBy.defaultProps = {
  variant: 'menu'
}

export default ListFiltersGroupBy
