import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function fetchAutomationsApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/host/business-automations`, config)
}

export function getAutomationApi({ id }) {
  const config = {}
  return DoinnApi.get(`/host/business-automations/${id}`, config)
}

export function addAutomationApi(params = {}) {
  const config = {}
  return DoinnApi.post(`/host/business-automations`, params, config)
}

export function editAutomationApi(params = {}) {
  const config = {}
  const data = { ...params }
  return DoinnApi.put(`/host/business-automations/${params.id}`, data, config)
}

export function activateAutomationApi({ id }) {
  const config = {}
  return DoinnApi.patch(`/host/business-automations/${id}/activate`, config)
}

export function deactivateAutomationApi({ id, deleteNextServices = false }) {
  const config = {}
  config.options = {
    deleteNextServices
  }
  return DoinnApi.patch(`/host/business-automations/${id}/deactivate`, config)
}

export function deleteAutomationApi(params = {}) {
  const { id, ...otherParams } = params
  const config = {
    data: otherParams
  }
  return DoinnApi.delete(`/host/business-automations/${id}`, config)
}
