export const CATALOGUE_GET_MY_REQUESTED = 'CATALOGUE_GET_MY_REQUESTED'
export const CATALOGUE_GET_MY_SUCCEEDED = 'CATALOGUE_GET_MY_SUCCEEDED'
export const CATALOGUE_GET_MY_FAILED = 'CATALOGUE_GET_MY_FAILED'

export const CATALOGUE_GET_MY_ITEMS_REQUESTED =
  'CATALOGUE_GET_MY_ITEMS_REQUESTED'
export const CATALOGUE_GET_MY_ITEMS_SUCCEEDED =
  'CATALOGUE_GET_MY_ITEMS_SUCCEEDED'
export const CATALOGUE_GET_MY_ITEMS_FAILED = 'CATALOGUE_GET_MY_ITEMS_FAILED'

export const CATALOGUE_CLEAR_MY_SELECTED_REQUESTED =
  'CATALOGUE_CLEAR_MY_SELECTED_REQUESTED'

export const CATALOGUE_GET_MY_PRICE_LIST_DOWNLOAD_REQUESTED =
  'CATALOGUE_GET_MY_PRICE_LIST_DOWNLOAD_REQUESTED'
export const CATALOGUE_GET_MY_PRICE_LIST_DOWNLOAD_SUCCEEDED =
  'CATALOGUE_GET_MY_PRICE_LIST_DOWNLOAD_SUCCEEDED'
export const CATALOGUE_GET_MY_PRICE_LIST_DOWNLOAD_FAILED =
  'CATALOGUE_GET_MY_PRICE_LIST_DOWNLOAD_FAILED'
