import { all, call, put, takeLatest } from 'redux-saga/effects'
import { updateJobsStaffApi } from 'api/jobs'
import {
  JOB_ASSIGN_STAFF_EDIT_FAILED,
  JOB_ASSIGN_STAFF_EDIT_REQUESTED,
  JOB_ASSIGN_STAFF_EDIT_SUCCEEDED
} from 'containers/job/assign-staff/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

export function* editJobStaff(action) {
  try {
    const { type, jobId, jobIds, employees, teamId } = action.payload.params
    const params = {
      jobIds: jobId ? [jobId] : jobIds || []
    }
    if (type === 'employees') {
      params.employees = employees.map(employee => employee.id)
    } else {
      params.teamId = teamId
    }
    const response = yield call(updateJobsStaffApi, params)
    const responseData = response.data.data
    yield put({
      type: JOB_ASSIGN_STAFF_EDIT_SUCCEEDED,
      payload: {
        data: {
          jobs: responseData
        }
      }
    })
  } catch (e) {
    yield put({
      type: JOB_ASSIGN_STAFF_EDIT_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* jobAssignStaffDialogSaga() {
  yield all([takeLatest(JOB_ASSIGN_STAFF_EDIT_REQUESTED, editJobStaff)])
}
