import { find } from 'lodash'
import { allRoutes } from 'routes'

export const SAVED_LOGOUT_DATA = 'logoutData'

export const getRoutePath = routeKey => {
  const route = find(allRoutes, {
    key: routeKey
  })
  return route.path
}

export const countTabsOpended = () => {
  const tabsOpen = getSavedLogoutLocalStorage('tabsOpen')
  let numberOfTabs = 0
  if (tabsOpen == null) {
    numberOfTabs = 1
  } else {
    numberOfTabs = parseInt(tabsOpen) + 1
  }
  saveLogoutLocalStorage({ tabsOpen: numberOfTabs })

  window.onunload = function (e) {
    const tabsOpen = getSavedLogoutLocalStorage('tabsOpen')
    let numberOfTabs = 0
    if (tabsOpen !== null) {
      numberOfTabs = parseInt(tabsOpen) - 1
      numberOfTabs < 1
        ? removeSavedLogoutLocalStorage()
        : saveLogoutLocalStorage({ tabsOpen: numberOfTabs })
    }
  }
}

export const saveLogoutLocalStorage = (data = {}) => {
  let persistData = JSON.parse(window.localStorage.getItem(SAVED_LOGOUT_DATA))
  if (!persistData) {
    persistData = { ...data }
  } else {
    persistData = {
      ...persistData,
      ...data
    }
  }
  window.localStorage.setItem(SAVED_LOGOUT_DATA, JSON.stringify(persistData))
}

export const getSavedLogoutLocalStorage = key => {
  const persistData = JSON.parse(window.localStorage.getItem(SAVED_LOGOUT_DATA))
  if (!persistData) {
    return null
  }
  if (persistData && !key) {
    return persistData
  }
  if (!persistData[key]) {
    return null
  }
  return persistData[key]
}

export const removeSavedLogoutLocalStorage = key => {
  window.localStorage.removeItem(SAVED_LOGOUT_DATA)
}
