import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function uploadDocumentApi(params) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  const formData = new window.FormData()
  const { file, documentSlug } = params

  if (file) {
    formData.append(documentSlug, file)
  }

  return DoinnApi.post(`/vendor/terms/documents`, formData, config)
}

export function fetchDocumentsApi() {
  const config = {}

  return DoinnApi.get(`/vendor/terms/documents`, config)
}

export function deleteDocumentApi(id) {
  const config = {}
  return DoinnApi.delete(`/vendor/documents/${id}`, config)
}
