import {
  PAYMENT_METHODS_AVAILABLE_FETCH_FAILED,
  PAYMENT_METHODS_AVAILABLE_FETCH_REQUESTED,
  PAYMENT_METHODS_AVAILABLE_FETCH_SUCCEEDED
} from '@doinn/shared/src/containers/subscription-billing/payment/list/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  available: [],
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case PAYMENT_METHODS_AVAILABLE_FETCH_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING
      }
    case PAYMENT_METHODS_AVAILABLE_FETCH_SUCCEEDED:
      return {
        ...state,
        available: payload.data,
        status: STATUS.SUCCESS
      }
    case PAYMENT_METHODS_AVAILABLE_FETCH_FAILED:
      return {
        ...initialState,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
