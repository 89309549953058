import { all, call, put, takeLatest } from 'redux-saga/effects'
import i18n from '@doinn/shared/src/config/i18n'
import {
  addPaymentMethodApi,
  getSetupIntentApi,
  getpaymentMethodsApi,
  updatePaymentMethodApi
} from '@doinn/shared/src/api/payments'
import {
  STRIPE_PAYMENT_SETUP_FAILED,
  STRIPE_PAYMENT_SETUP_OPEN,
  STRIPE_PAYMENT_SETUP_SAVING_FAILED,
  STRIPE_PAYMENT_SETUP_SAVING_REQUESTED,
  STRIPE_PAYMENT_SETUP_SAVING_SUCCEEDED,
  STRIPE_PAYMENT_SETUP_SUCCEEDED,
  STRIPE_PAYMENT_SETUP_UPDATING_REQUESTED
} from '@doinn/shared/src/containers/gateways/stripe/setup/contants'
import { GATEWAYS_GET_METHODS_SUCCEEDED } from '@doinn/shared/src/containers/gateways/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* getSetupIntentMethods(action) {
  try {
    const { params } = action.payload
    const { type, gateway } = params
    const response = yield call(getSetupIntentApi, { type, gateway })

    yield put({
      type: STRIPE_PAYMENT_SETUP_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: STRIPE_PAYMENT_SETUP_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* saveSetupIntentMethod(action) {
  try {
    const { params } = action.payload
    const body = {
      payment_gateway: params.gateway,
      type: params.variant,
      external_id: params.setupIntent.payment_method
    }
    const responsePost = yield call(addPaymentMethodApi, body)
    const responseGet = yield call(getpaymentMethodsApi)

    yield put({
      type: GATEWAYS_GET_METHODS_SUCCEEDED,
      payload: responseGet.data
    })
    yield put({
      type: STRIPE_PAYMENT_SETUP_SAVING_SUCCEEDED,
      payload: responsePost.data,
      snackbar: {
        variant: 'success',
        message: i18n.t('Payment method has been added successfully!')
      }
    })
  } catch (e) {
    yield put({
      type: STRIPE_PAYMENT_SETUP_SAVING_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* updateSetupIntentMethod(action) {
  try {
    const { params } = action.payload
    const responsePost = yield call(updatePaymentMethodApi, params)
    const responseGet = yield call(getpaymentMethodsApi)

    yield put({
      type: GATEWAYS_GET_METHODS_SUCCEEDED,
      payload: responseGet.data
    })
    yield put({
      type: STRIPE_PAYMENT_SETUP_SAVING_SUCCEEDED,
      payload: responsePost.data,
      snackbar: {
        variant: 'success',
        message: i18n.t('Payment method has been added successfully!')
      }
    })
  } catch (e) {
    yield put({
      type: STRIPE_PAYMENT_SETUP_SAVING_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* stripeSetupSagas() {
  yield all([
    takeLatest(STRIPE_PAYMENT_SETUP_OPEN, getSetupIntentMethods),
    takeLatest(STRIPE_PAYMENT_SETUP_SAVING_REQUESTED, saveSetupIntentMethod),
    takeLatest(STRIPE_PAYMENT_SETUP_UPDATING_REQUESTED, updateSetupIntentMethod)
  ])
}
