export const CONTRACT_CITY_SERVICES_FETCH_REQUESTED =
  'CONTRACT_CITY_SERVICES_FETCH_REQUESTED'
export const CONTRACT_CITY_SERVICES_FETCH_SUCCEEDED =
  'CONTRACT_CITY_SERVICES_FETCH_SUCCEEDED'
export const CONTRACT_CITY_SERVICES_FETCH_FAILED =
  'CONTRACT_CITY_SERVICES_FETCH_FAILED'

export const ONBOARDING_TOGGLE_CITY_SERVICE = 'ONBOARDING_TOGGLE_CITY_SERVICE'

export const CONTRACT_CITY_SERVICES_SAVE_REQUESTED =
  'CONTRACT_CITY_SERVICES_SAVE_REQUESTED'
export const CONTRACT_CITY_SERVICES_SAVE_SUCCEEDED =
  'CONTRACT_CITY_SERVICES_SAVE_SUCCEEDED'
export const CONTRACT_CITY_SERVICES_SAVE_FAILED =
  'CONTRACT_CITY_SERVICES_SAVE_FAILED'
