import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function fetchMembersApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/people/members`, config)
}

export function getMemberApi(member) {
  const config = {}
  return DoinnApi.get(`/people/members/${member.id}`, config)
}

export function editMemberApi(member = {}) {
  const config = {}
  const data = { ...member }
  return DoinnApi.patch(`/people/members/${member.id}`, data, config)
}

export function editMemberPasswordApi(member = {}) {
  const config = {}
  const data = { ...member }
  return DoinnApi.post(`/people/members/${member.id}/password`, data, config)
}

export function deleteMemberApi(id) {
  const config = {}
  return DoinnApi.delete(`/people/members/${id}`, config)
}
