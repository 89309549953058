export const countAppliedFilters = (
  filters,
  hasMainFilters,
  hiddenFilters = []
) => {
  let total = 0
  for (const key in filters) {
    if (filters[key] && !hiddenFilters.includes(key)) {
      const value = filters[key]
      if (Array.isArray(value)) {
        total += value.length
      } else {
        if (
          (key === 'notes' && value !== 'all') ||
          (key === 'start' && !hasMainFilters) ||
          (key === 'sourceBy' && value !== 'all' && !hasMainFilters) ||
          (key === 'type' && value !== 'all')
        ) {
          total += 1
        }
      }
    }
  }
  return total
}
