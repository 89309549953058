import { all, call, put, takeLatest } from 'redux-saga/effects'
import i18n from '@doinn/shared/src/config/i18n'
import { addNoteApi } from '@doinn/shared/src/api/note'
import {
  ORDER_SERVICES_NOTES_ADD_FORM_SAVE_FAILED,
  ORDER_SERVICES_NOTES_ADD_FORM_SAVE_REQUESTED,
  ORDER_SERVICES_NOTES_ADD_FORM_SAVE_SUCCEEDED
} from '@doinn/shared/src/containers/order-service/notes/add/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

export function* addNote(job, jobService, note) {
  try {
    const response = yield call(addNoteApi, job, jobService, note)
    const createdNote = {
      ...response.data,
      orderServiceId: jobService.id
    }
    yield put({
      type: ORDER_SERVICES_NOTES_ADD_FORM_SAVE_SUCCEEDED,
      payload: createdNote,
      snackbar: {
        variant: 'success',
        message: i18n.t('Note successfully sent!')
      }
    })
  } catch (e) {
    yield put({
      type: ORDER_SERVICES_NOTES_ADD_FORM_SAVE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export function* saveNote(action) {
  try {
    const { job, jobService, note } = action.payload
    yield call(addNote, job, jobService, note)
  } catch (e) {
    yield put({
      type: ORDER_SERVICES_NOTES_ADD_FORM_SAVE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* watchSaveNote() {
  yield takeLatest(ORDER_SERVICES_NOTES_ADD_FORM_SAVE_REQUESTED, saveNote)
}

export default function* noteFormSaga() {
  yield all([watchSaveNote()])
}
