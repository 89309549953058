import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  STORE_DEVICE_INFO_FAILED,
  STORE_DEVICE_INFO_REQUESTED,
  STORE_DEVICE_INFO_SUCCEEDED
} from '@doinn/shared/src/containers/device/constants'
import { storeUserDeviceApi } from '@doinn/shared/src/api/userDevices'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* storeDeviceInfo(action) {
  try {
    yield call(storeUserDeviceApi, action.payload)
    yield put({ type: STORE_DEVICE_INFO_SUCCEEDED })
  } catch (e) {
    yield put({
      type: STORE_DEVICE_INFO_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* deviceInfoSaga() {
  yield all([takeLatest(STORE_DEVICE_INFO_REQUESTED, storeDeviceInfo)])
}
