import {
  JOB_FILTERS_FETCH_FAILED,
  JOB_FILTERS_FETCH_REQUESTED,
  JOB_FILTERS_FETCH_SUCCEEDED
} from './constants'

export const initialState = {
  filters: {
    cities: [],
    services: []
  },
  isLoading: false
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case JOB_FILTERS_FETCH_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case JOB_FILTERS_FETCH_FAILED:
      return {
        ...state,
        isLoading: false
      }
    case JOB_FILTERS_FETCH_SUCCEEDED:
      return {
        ...state,
        filters: payload.data,
        isLoading: false
      }
    default:
      return state
  }
}
