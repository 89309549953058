import {
  addMonths,
  endOfMonth,
  isToday,
  isTomorrow,
  isYesterday,
  parseISO,
  startOfMonth,
  subMonths
} from 'date-fns'
import { formatSystemDate } from '@doinn/shared/src/util/date-fns'

const localesISO = {
  'en-GB': 'en-GB',
  'en-US': 'en-US',
  'es-ES': 'es-ES',
  'fr-FR': 'fr-FR',
  'it-IT': 'it-IT',
  'pt-PT': 'pt-PT'
}

const localesICU = {
  'en-GB': 'en_US',
  'en-US': 'en_US',
  'es-ES': 'es_ES',
  'fr-FR': 'fr_FR',
  'it-IT': 'it_IT',
  'pt-PT': 'pt_PT'
}

export const getISOLocale = locale => localesISO[locale] || 'en-GB'
export const getICULocale = locale => localesICU[locale] || 'en_US'

const isLastMonth = (start, end) => {
  if (!start || !end) return false
  const firstDayOfTheLastMonthDate = subMonths(startOfMonth(new Date()), 1)
  const startOfLastMonthFormatted = formatSystemDate(
    startOfMonth(firstDayOfTheLastMonthDate)
  )
  const endOfLastMonthFormatted = formatSystemDate(
    endOfMonth(firstDayOfTheLastMonthDate)
  )
  return start === startOfLastMonthFormatted && end === endOfLastMonthFormatted
}

const isNextMonth = (start, end) => {
  if (!start || !end) return false
  const firstDayOfTheNextMonthDate = addMonths(startOfMonth(new Date()), 1)
  const startOfNextMonthFormatted = formatSystemDate(
    startOfMonth(firstDayOfTheNextMonthDate)
  )
  const endOfNextMonthFormatted = formatSystemDate(
    endOfMonth(firstDayOfTheNextMonthDate)
  )
  return start === startOfNextMonthFormatted && end === endOfNextMonthFormatted
}

const isCurrentMonth = (start, end) => {
  if (!start || !end) return false
  const nowDate = new Date()
  const startOfMonthFormatted = formatSystemDate(startOfMonth(nowDate))
  const endOfMonthFormatted = formatSystemDate(endOfMonth(nowDate))
  return start === startOfMonthFormatted && end === endOfMonthFormatted
}

export const getPeriod = (start, end) => {
  if (!start || !end) return 'custom'
  if (start === end) {
    const startDate = parseISO(start)
    if (isYesterday(startDate)) return 'yesterday'
    if (isToday(startDate)) return 'today'
    if (isTomorrow(startDate)) return 'tomorrow'
  } else {
    if (isCurrentMonth(start, end)) return 'current_month'
    if (isLastMonth(start, end)) return 'last_month'
    if (isNextMonth(start, end)) return 'next_month'
  }
  return 'custom'
}
