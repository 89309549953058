import React, { useCallback } from 'react'
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  makeStyles
} from '@material-ui/core'

import TextField from '@doinn/shared/src/components/common/inputs/TextField'
import SectionLineGooglePlacesAutocomplete from '@doinn/shared/src/components/common/sections/SectionLineGooglePlacesAutocomplete'

const useStyles = makeStyles(theme => {
  return {
    item: ({ noBorder }) => {
      return noBorder
        ? {}
        : {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.spacing(2),
            position: 'relative'
          }
    },
    itemContent: ({ noBorder }) => ({
      padding: noBorder ? theme.spacing(0) : theme.spacing(3),
      paddingBottom: noBorder ? `${theme.spacing(0)} !important` : ''
    }),
    title: {
      color: theme.palette.text.primary,
      lineHeight: 'normal',
      paddingBottom: theme.spacing(2)
    },
    control: {
      margin: theme.spacing(0),
      marginBottom: `${theme.spacing(0)}px !important`
    },
    rowItem: {
      '&:not(:first-child):not(:last-child)': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
      },
      '&:first-of-type:not(:last-of-type)': {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1)
      },
      '&:last-of-type:not(:first-of-type)': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(0)
      }
    },
    button: {
      padding: theme.spacing(0),
      margin: '0 9px'
    },
    city: {
      marginTop: `${theme.spacing(-1)}px !important`,
      '&> div': {
        paddingBottom: `${theme.spacing(0)} !important`
      }
    }
  }
})

function QuestionCard({
  title,
  options,
  name,
  optionsType,
  values,
  onClick,
  setFieldValue,
  noBorder,
  isDisabled,
  formProps
}) {
  const classes = useStyles({ noBorder })

  const handleOptionClick = useCallback(
    value => event => {
      onClick && onClick(name, optionsType, value)
    },
    [name, onClick, optionsType]
  )

  const renderContent = useCallback(() => {
    if (optionsType === 'checkbox') {
      return (
        <FormGroup aria-labelledby='form-question-label' name={name}>
          {options.map(option => (
            <Grid item xs={12} key={option.value} className={classes.rowItem}>
              <Box display='flex' alignItems='center'>
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      className={classes.button}
                      name={name}
                      checked={values[name].includes(option.value)}
                      onChange={handleOptionClick(option.value)}
                      value={option.value}
                      inputProps={{
                        'aria-label': option.description
                      }}
                    />
                  }
                  label={
                    <Typography component='div' variant='body1'>
                      {option.description}
                    </Typography>
                  }
                />
              </Box>
            </Grid>
          ))}
        </FormGroup>
      )
    }

    if (optionsType === 'radio') {
      return (
        <RadioGroup aria-labelledby='form-question-label'>
          {options.map(option => (
            <Grid item xs={12} key={option.value} className={classes.rowItem}>
              <Box display='flex' alignItems='center'>
                <FormControlLabel
                  control={
                    <Radio
                      className={classes.button}
                      name={name}
                      checked={[values[name]].includes(option.value)}
                      onChange={handleOptionClick(option.value)}
                      value={option.value}
                      inputProps={{
                        'aria-label': option.description
                      }}
                    />
                  }
                  label={
                    <Typography component='div' variant='body1'>
                      {option.description}
                    </Typography>
                  }
                />
              </Box>
            </Grid>
          ))}
        </RadioGroup>
      )
    }

    if (optionsType === 'city') {
      return (
        <Box className={classes.city}>
          <SectionLineGooglePlacesAutocomplete
            key={'field-city-item'}
            label=''
            isEditMode
            isLastLine={false}
            fieldName={name}
            currentValue={values?.place?.formattedPlace}
            value={values?.place?.formattedPlace}
            onChangeField={setFieldValue}
            formProps={formProps}
          />
        </Box>
      )
    }

    return (
      <TextField
        disabled={isDisabled}
        name={name}
        hiddenLabel
        fullWidth
        className={classes.control}
        margin='none'
      />
    )
  }, [
    classes,
    formProps,
    handleOptionClick,
    isDisabled,
    name,
    options,
    optionsType,
    setFieldValue,
    values
  ])

  return (
    <Card className={classes.item} elevation={0}>
      <CardContent className={classes.itemContent}>
        <FormControl component='fieldset' className={classes.control}>
          <FormLabel id='form-question-label' className={classes.title}>
            <b>{title}</b>
          </FormLabel>
          <Grid container direction='column'>
            {renderContent()}
          </Grid>
        </FormControl>
      </CardContent>
    </Card>
  )
}

export default QuestionCard
