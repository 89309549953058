import {
  RESERVATION_DELETE_ALL_DIALOG_CLOSE,
  RESERVATION_DELETE_ALL_DIALOG_OPEN,
  RESERVATION_DELETE_ALL_FAILED,
  RESERVATION_DELETE_ALL_REQUESTED,
  RESERVATION_DELETE_ALL_SUCCEEDED
} from '@doinn/vendor/src/containers/reservation/delete-all/constants'

export const STATUS = {
  IDLE: 'idle',
  SAVING: 'saving',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  isOpened: false,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case RESERVATION_DELETE_ALL_DIALOG_OPEN:
      return {
        ...initialState,
        isOpened: true
      }
    case RESERVATION_DELETE_ALL_DIALOG_CLOSE:
      return {
        ...initialState,
        isOpened: false
      }
    case RESERVATION_DELETE_ALL_REQUESTED:
      return {
        ...state,
        status: STATUS.SAVING
      }
    case RESERVATION_DELETE_ALL_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case RESERVATION_DELETE_ALL_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
