import React, { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { startOfToday } from 'date-fns'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'
import { cloneDeep, isEqual as isObjEqual } from 'lodash'

import {
  useDidMount,
  useFilterState,
  useOpenState,
  usePrevious
} from '@doinn/shared/src/hooks'
import {
  getQueryStringObject,
  updateURLParams
} from '@doinn/shared/src/util/url'
import { countAppliedFilters } from 'containers/jobs/util'
import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import { formatSystemDate } from '@doinn/shared/src/util/date-fns'
import { parseAppliedFilters } from '@doinn/shared/src/util/filters'
import TemplateDefault from 'components/TemplateDefault'
import JobsListFiltersContent from 'containers/jobs/ListFiltersContent'
import FiltersDialog from '@doinn/shared/src/components/common/FiltersDialog'
import FiltersButton from '@doinn/shared/src/components/common/FiltersButton'

const JobDispatchContainer = loadable(() =>
  lazyRetry(() => import('containers/jobs/dispatch/Dispatch'))
)

const getDefaultFilters = () => {
  const startOfTodayDate = startOfToday()
  const startOfTodayFormatted = formatSystemDate(startOfTodayDate)
  return {
    cities: [],
    services: [],
    priorities: [],
    status: [],
    employees: [],
    spaces: [],
    hosts: [],
    hostBusinessAccounts: [],
    typologies: [],
    notes: 'all',
    period: 'today',
    limit: '200',
    start: startOfTodayFormatted,
    end: startOfTodayFormatted
  }
}

const parseFilters = filters => {
  const parsedFilters = cloneDeep(filters)
  if (parsedFilters.notes === 'all') {
    delete parsedFilters.notes
  }
  return parsedFilters
}

const getQueryOrSavedFilters = location => {
  const queryFilters = getQueryStringObject(location.search)
  return parseAppliedFilters(getDefaultFilters(), queryFilters)
}

const ServicesDispatch = ({ location, history }) => {
  const { t } = useTranslation()
  const previousLocation = usePrevious(location)
  const [justChangedLocation, setJustChangedLocation] = React.useState(false)
  const {
    appliedFilters,
    selectedFilters,
    hasAppliedFiltersChanges,
    onChangeAppliedFilters,
    onChangeSelectedFilters,
    onClearSelectedFilters,
    onConfirmSelectedFilters
  } = useFilterState(getDefaultFilters())
  const {
    justClosed: justClosedFilters,
    justOpened: justOpenedFilters,
    onClose: onCloseFilters,
    onOpen: onOpenFilters,
    open: isFiltersOpened
  } = useOpenState(false)
  const [totalAppliedFilters, setTotalAppliedFilters] = React.useState(
    countAppliedFilters(appliedFilters)
  )

  useDidMount(() => {
    onChangeAppliedFilters(getQueryOrSavedFilters(location))
  })

  React.useEffect(() => {
    if (!previousLocation) return
    setJustChangedLocation(
      !isObjEqual(location.search, previousLocation.search)
    )
  }, [location, previousLocation])

  React.useEffect(() => {
    if (!justChangedLocation) return
    const savedFilters = getQueryOrSavedFilters(location)
    const shouldUpdateAppliedFilters = !isObjEqual(appliedFilters, savedFilters)
    if (shouldUpdateAppliedFilters) {
      onChangeAppliedFilters(savedFilters)
    }
  }, [appliedFilters, justChangedLocation, location, onChangeAppliedFilters])

  React.useEffect(() => {
    if (justOpenedFilters) {
      onChangeSelectedFilters(appliedFilters)
    }
  }, [appliedFilters, justOpenedFilters, onChangeSelectedFilters])

  React.useEffect(() => {
    if (justClosedFilters) {
      onChangeSelectedFilters(appliedFilters)
    }
  }, [appliedFilters, justClosedFilters, onChangeSelectedFilters])

  React.useEffect(() => {
    const shouldUpdateUrl =
      hasAppliedFiltersChanges &&
      !isObjEqual(appliedFilters, getQueryOrSavedFilters(location))
    if (shouldUpdateUrl) {
      updateURLParams(parseFilters(appliedFilters), history)
    }
  }, [appliedFilters, hasAppliedFiltersChanges, history, location])

  React.useEffect(() => {
    setTotalAppliedFilters(countAppliedFilters(appliedFilters))
  }, [appliedFilters])

  const handleConfirmSelectedFilters = useCallback(() => {
    onConfirmSelectedFilters()
    onCloseFilters()
  }, [onCloseFilters, onConfirmSelectedFilters])

  const handleChangeAppliedFilters = useCallback(
    filters => {
      onChangeAppliedFilters(filters)
    },
    [onChangeAppliedFilters]
  )

  return (
    <TemplateDefault
      title={t('Services')}
      filtersContent={
        <FiltersButton
          variant='icon'
          totalAppliedFilters={totalAppliedFilters}
          onOpen={onOpenFilters}
        />
      }
    >
      <Helmet>
        <title>{t('Services')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <JobDispatchContainer
          filters={appliedFilters}
          onChangeFilters={handleChangeAppliedFilters}
          onOpenFilters={onOpenFilters}
        />
      </React.Suspense>
      <FiltersDialog
        open={isFiltersOpened}
        onClear={onClearSelectedFilters}
        onClose={onCloseFilters}
        onConfirm={handleConfirmSelectedFilters}
      >
        <JobsListFiltersContent
          filters={selectedFilters}
          onChangeFilters={onChangeSelectedFilters}
        />
      </FiltersDialog>
    </TemplateDefault>
  )
}

export default ServicesDispatch
