import { get } from 'lodash'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  createNotificationEmailApi,
  deleteNotificationEmailApi,
  getProfileDetailsApi,
  updateProfileDetailsApi,
  updateProfileTranslationLanguageApi
} from '@doinn/shared/src/api/profile'
import { setLanguage } from '@doinn/shared/src/containers/language/actions'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import {
  NOTIFICATION_EMAIL_CREATE_FAILED,
  NOTIFICATION_EMAIL_CREATE_REQUESTED,
  NOTIFICATION_EMAIL_CREATE_SUCCEEDED,
  NOTIFICATION_EMAIL_DELETE_FAILED,
  NOTIFICATION_EMAIL_DELETE_REQUESTED,
  NOTIFICATION_EMAIL_DELETE_SUCCEEDED,
  PROFILE_DETAILS_GET_FAILED,
  PROFILE_DETAILS_GET_REQUESTED,
  PROFILE_DETAILS_UPDATE_FAILED,
  PROFILE_DETAILS_UPDATE_REQUESTED,
  PROFILE_DETAILS_UPDATE_SUCCEEDED,
  PROFILE_TRANSLATION_LANGUAGE_UPDATE_FAILED,
  PROFILE_TRANSLATION_LANGUAGE_UPDATE_REQUESTED,
  PROFILE_TRANSLATION_LANGUAGE_UPDATE_SUCCEEDED
} from '@doinn/shared/src/containers/profile/constants'
import { setProfileDetails } from '@doinn/shared/src/containers/profile/actions'

export function* updateProfileDetails(action) {
  try {
    const response = yield call(updateProfileDetailsApi, action.payload.params)
    const info = response.data.data
    yield put(setProfileDetails(info))
    const language = get(
      action,
      'payload.params.preferences.language',
      undefined
    )
    if (language) {
      yield put(
        setLanguage({
          language
        })
      )
    }
    yield put({
      type: PROFILE_DETAILS_UPDATE_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: PROFILE_DETAILS_UPDATE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export function* updateProfileTranslationLanguage(action) {
  try {
    const response = yield call(
      updateProfileTranslationLanguageApi,
      action.payload.translationLanguage
    )
    const info = response.data.data
    yield put(setProfileDetails(info))
    yield put({
      type: PROFILE_TRANSLATION_LANGUAGE_UPDATE_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: PROFILE_TRANSLATION_LANGUAGE_UPDATE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export function* getProfile() {
  try {
    const responseDetails = yield call(getProfileDetailsApi)
    const info = responseDetails.data.data
    yield put(setProfileDetails(info))
  } catch (e) {
    yield put({
      type: PROFILE_DETAILS_GET_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export function* deleteNotificationEmail(action) {
  try {
    const response = yield call(deleteNotificationEmailApi, action.payload.id)
    yield put({
      type: NOTIFICATION_EMAIL_DELETE_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: NOTIFICATION_EMAIL_DELETE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export function* createNotificationEmail(action) {
  try {
    const response = yield call(
      createNotificationEmailApi,
      action.payload.email
    )
    yield put({
      type: NOTIFICATION_EMAIL_CREATE_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: NOTIFICATION_EMAIL_CREATE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* profileSaga() {
  yield all([
    takeLatest(PROFILE_DETAILS_GET_REQUESTED, getProfile),
    takeEvery(NOTIFICATION_EMAIL_DELETE_REQUESTED, deleteNotificationEmail),
    takeEvery(NOTIFICATION_EMAIL_CREATE_REQUESTED, createNotificationEmail),
    takeLatest(PROFILE_DETAILS_UPDATE_REQUESTED, updateProfileDetails),
    takeLatest(
      PROFILE_TRANSLATION_LANGUAGE_UPDATE_REQUESTED,
      updateProfileTranslationLanguage
    )
  ])
}
