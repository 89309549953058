import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import StripeKYCDialog from 'components/stripe-kyc/StripeKYCDialog'
import StripeKYCAlert from 'components/stripe-kyc/StripeKYCAlert'
import { get } from 'lodash'

import { close, open } from '@doinn/vendor/src/containers/stripe-kyc/actions'

const Warning = props => {
  const { loggedUser, isOpened, close, open } = props
  const hideAlert =
    !get(loggedUser, 'business.stripe', undefined) ||
    !loggedUser.business.stripe ||
    !loggedUser.business.stripe.status ||
    loggedUser.business.stripe.status === 'accepted'

  const handleOpen = () => open()
  const handleClose = () => close()

  return (
    <React.Fragment>
      {!hideAlert && (
        <StripeKYCAlert
          type={loggedUser.business.stripe.status}
          onButtonClick={handleOpen}
          actionUrl={loggedUser.business.stripe.url}
        />
      )}
      <StripeKYCDialog
        isOpened={isOpened}
        onClose={handleClose}
        url={loggedUser?.business?.stripe?.url}
      />
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  loggedUser: state.user.loggedUser,
  isOpened: state.stripeKYC.isOpened
})

const mapDispatchToProps = dispatch => ({
  close: bindActionCreators(close, dispatch),
  open: bindActionCreators(open, dispatch)
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(Warning)
