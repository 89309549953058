import React from 'react'
import PropTypes from 'prop-types'
import { find } from 'lodash'
import { FormControl, makeStyles } from '@material-ui/core'
import DetailsSectionLine from '@doinn/shared/src/components/common/details/DetailsSectionLine'
import GooglePlacesAutocompleteField from '@doinn/shared/src/components/common/google/GooglePlacesAutocompleteField'

const useStyles = makeStyles(theme => ({
  input: {
    marginTop: 0,
    marginBottom: 0
  },
  autocomplete: {
    '&> div': {
      padding: 0,
      margin: 0
    }
  }
}))

const buildPlace = googlePlace => {
  if (!googlePlace) {
    return {}
  }
  const country = find(googlePlace.address_components, addressComponent =>
    addressComponent.types.includes('country')
  )
  return {
    placeId: googlePlace.place_id,
    city: {
      name: googlePlace.name
    },
    formattedPlace: googlePlace.formatted_address,
    country: {
      name: country.long_name,
      code: country.short_name
    }
  }
}

const SectionLineGooglePlacesAutocomplete = props => {
  const {
    fieldName,
    label,
    currentValue,
    value,
    isEditMode,
    isLoading,
    onChangeField,
    isDisabled
  } = props

  const classes = useStyles()

  const handleChange = value => {
    if (onChangeField) {
      onChangeField(fieldName, buildPlace(value))
    }
  }

  const handleClear = value => {
    if (onChangeField) {
      onChangeField(fieldName, {})
    }
  }

  return (
    <DetailsSectionLine
      label={label}
      originalValue={currentValue}
      isEditMode={isEditMode}
      isLoading={isLoading}
    >
      <FormControl hiddenLabel fullWidth className={classes.input}>
        <GooglePlacesAutocompleteField
          className={classes.autocomplete}
          defaultInputValue={value}
          keepSelectedValue={true}
          onChangePlaceDetails={handleChange}
          onClear={handleClear}
          disabled={isDisabled}
          name={fieldName}
        />
      </FormControl>
    </DetailsSectionLine>
  )
}

SectionLineGooglePlacesAutocomplete.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  currentValue: PropTypes.string,
  isEditMode: PropTypes.bool,
  isLoading: PropTypes.bool,
  onChangeField: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool
}

SectionLineGooglePlacesAutocomplete.defaultProps = {
  isEditMode: false,
  isLoading: false,
  value: '',
  currentValue: '',
  isDisabled: false
}

export default SectionLineGooglePlacesAutocomplete
