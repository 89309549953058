import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { DialogTitle, Typography } from '@material-ui/core'
import LoadingText from '@doinn/shared/src/components/common/loading/LoadingText'
import HeaderCloseButton from '@doinn/shared/src/components/common/dialog/HeaderCloseButton'

const DialogHeader = ({
  children,
  isLoadingOverline,
  isLoadingTitle,
  isLoadingSubtitle,
  overline,
  title,
  subtitle,
  onClose,
  classname
}) => {
  const titleHasGutterBottom =
    (isLoadingTitle || !!title) && (isLoadingSubtitle || !!subtitle)

  return (
    <DialogTitle disableTypography className={classname}>
      <HeaderCloseButton onClose={onClose} />
      <div>
        {isLoadingOverline ? (
          <LoadingText textVariant='overline' width='30%' />
        ) : (
          overline && <Typography variant='overline'>{overline}</Typography>
        )}
        {isLoadingTitle ? (
          <LoadingText
            textVariant='h2'
            width='80%'
            gutterBottom={titleHasGutterBottom}
          />
        ) : (
          title && (
            <Typography variant='h2' gutterBottom={titleHasGutterBottom}>
              {title}
            </Typography>
          )
        )}
        {isLoadingSubtitle ? (
          <LoadingText textVariant='subtitle1' width='60%' />
        ) : (
          subtitle && <Typography variant='subtitle1'>{subtitle}</Typography>
        )}
      </div>
      {children}
    </DialogTitle>
  )
}

DialogHeader.propTypes = {
  isLoadingOverline: PropTypes.bool,
  isLoadingTitle: PropTypes.bool,
  isLoadingSubtitle: PropTypes.bool,
  overline: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClose: PropTypes.func
}

DialogHeader.muiName = 'DialogHeader'

DialogHeader.defaultProps = {}

export default compose()(DialogHeader)
