import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function fetchCatalogItemsApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/host/catalog-items`, config)
}

export function getCatalogItemApi(catalogItemId) {
  const config = {}
  return DoinnApi.get(`/host/catalog-items/${catalogItemId}`, config)
}

export function addCatalogItemApi(catalogItem) {
  const config = {}
  return DoinnApi.post(`/host/catalog-items`, { ...catalogItem }, config)
}

export function importCatalogItemApi(params) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  const formData = new window.FormData()
  const { file } = params

  if (file) {
    formData.append('file', file)
  }

  return DoinnApi.post(`/host/catalog-items/import`, formData, config)
}

export function editCatalogItemApi(catalogItem = {}) {
  const config = {}
  const data = { ...catalogItem }
  return DoinnApi.put(`/host/catalog-items/${catalogItem.id}`, data, config)
}

export function deleteCatalogItemApi(id) {
  const config = {}
  return DoinnApi.delete(`/host/catalog-items/${id}`, config)
}
