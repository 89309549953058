import DoinnApi, { DoinnBlobApi } from '@doinn/shared/src/config/doinnApi'

/**
 * @param {Object} params
 * @param {string} params.start - Date in the format 'YYYY-MM-DD'
 * @param {string} params.end - Date in the format 'YYYY-MM-DD'
 * @param {'hosts' | 'spaces' | 'employees'} params.reportBy
 * @returns {AxiosPromise<any>}
 */
export function fetchReportsBillingApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(
    `/reports/${config.params.reportOf}/billing/${config.params.reportBy}`,
    config
  )
}

/**
 * @param {Object} params
 * @param {string} params.start - Date in the format 'YYYY-MM-DD'
 * @param {string} params.end - Date in the format 'YYYY-MM-DD'
 * @param {'hosts' | 'spaces' | 'employees'} params.reportBy
 * @param {number} params.id - The host, space or employee ID
 * @returns {AxiosPromise<any>}
 */
export function fetchReportsBillingDetailsApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(
    `/reports/${config.params.reportOf}/billing/${config.params.reportBy}/${config.params.id}`,
    config
  )
}

/**
 * @param {Object} params
 * @param {string} params.start - Date in the format 'YYYY-MM-DD'
 * @param {string} params.end - Date in the format 'YYYY-MM-DD'
 * @returns {AxiosPromise<any>}
 */
export function exportByEmailReportsBillingApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(
    `/reports/${config.params.reportOf}/billing/export/xls`,
    config
  )
}

export function downloadReportsBillingApi(params = {}) {
  const config = { ...params }
  return DoinnBlobApi.get(
    `/reports/${config.params.reportOf}/billing/download/xls`,
    config
  )
}

export function downloadReportsBillingDetailsApi(params = {}) {
  const config = { params }
  return DoinnBlobApi.get(
    `/reports/${config.params.reportOf}/billing/${params.reportBy}/${params.id}/download/xls`,
    config
  )
}

export function downloadJobsReportApi(params = {}) {
  const config = {}

  return DoinnBlobApi.get(
    `/vendor/reports/jobs/export/?${params.queryParams}`,
    config
  )
}
