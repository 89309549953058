import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  fetchPlansApi,
  requestEnterpriseQuoteApi
} from '@doinn/shared/src/api/subscription'
import {
  SUBSCRIPTION_ENTERPRISE_QUOTE_FAILED,
  SUBSCRIPTION_ENTERPRISE_QUOTE_REQUESTED,
  SUBSCRIPTION_ENTERPRISE_QUOTE_SUCCEEDED,
  SUBSCRIPTION_PLANS_FETCH_FAILED,
  SUBSCRIPTION_PLANS_FETCH_REQUESTED,
  SUBSCRIPTION_PLANS_FETCH_SUCCEEDED
} from '@doinn/shared/src/containers/subscription/plans/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

export function* fetchPlansList(action) {
  try {
    const response = yield call(fetchPlansApi, action.payload)
    yield put({
      type: SUBSCRIPTION_PLANS_FETCH_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: SUBSCRIPTION_PLANS_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export function* getEnterpriseQuote(action) {
  try {
    const response = yield call(requestEnterpriseQuoteApi, action.payload)
    yield put({
      type: SUBSCRIPTION_ENTERPRISE_QUOTE_SUCCEEDED,
      payload: response.data.data
    })
  } catch (e) {
    yield put({
      type: SUBSCRIPTION_ENTERPRISE_QUOTE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* subscriptionPlansSagas() {
  yield all([
    takeLatest(SUBSCRIPTION_PLANS_FETCH_REQUESTED, fetchPlansList),
    takeLatest(SUBSCRIPTION_ENTERPRISE_QUOTE_REQUESTED, getEnterpriseQuote)
  ])
}
