export const JOB_REPORT_VIEW_REPORTED_PROBLEMS_OPEN_REQUESTED =
  'JOB_REPORT_VIEW_REPORTED_PROBLEMS_OPEN_REQUESTED'
export const JOB_REPORT_VIEW_REPORTED_PROBLEMS_CLOSE_REQUESTED =
  'JOB_REPORT_VIEW_REPORTED_PROBLEMS_CLOSE_REQUESTED'

export const JOB_REPORT_VIEW_REPORTED_PROBLEMS_REQUESTED =
  'JOB_REPORT_VIEW_REPORTED_PROBLEMS_REQUESTED'
export const JOB_REPORT_VIEW_REPORTED_PROBLEMS_SUCCEEDED =
  'JOB_REPORT_VIEW_REPORTED_PROBLEMS_SUCCEEDED'
export const JOB_REPORT_VIEW_REPORTED_PROBLEMS_FAILED =
  'JOB_REPORT_VIEW_REPORTED_PROBLEMS_FAILED'
