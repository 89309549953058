import {
  JOB_REPORT_PROBLEM_FAILED,
  JOB_REPORT_PROBLEM_FORM_CLOSE_REQUESTED,
  JOB_REPORT_PROBLEM_FORM_OPEN_REQUESTED,
  JOB_REPORT_PROBLEM_GET_PHRASES_FAILED,
  JOB_REPORT_PROBLEM_GET_PHRASES_REQUESTED,
  JOB_REPORT_PROBLEM_GET_PHRASES_SUCCEEDED,
  JOB_REPORT_PROBLEM_REQUESTED,
  JOB_REPORT_PROBLEM_SUCCEEDED
} from 'containers/job/report-problem/constants'

export const initialState = {
  isOpened: false,
  isConfirmLoading: false,
  isConfirmFailed: false,
  isFetchingPhrases: false,
  phrases: []
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case JOB_REPORT_PROBLEM_FORM_OPEN_REQUESTED:
      return {
        ...initialState,
        isOpened: true
      }
    case JOB_REPORT_PROBLEM_FORM_CLOSE_REQUESTED:
      return {
        ...initialState,
        isOpened: false
      }
    case JOB_REPORT_PROBLEM_REQUESTED:
      return {
        ...state,
        isConfirmLoading: true,
        isConfirmFailed: false
      }
    case JOB_REPORT_PROBLEM_SUCCEEDED:
      return {
        ...initialState,
        isOpened: false,
        isConfirmLoading: false,
        isConfirmFailed: false,
        phrases: []
      }
    case JOB_REPORT_PROBLEM_FAILED:
      return {
        ...state,
        isConfirmLoading: false,
        isConfirmFailed: true
      }
    case JOB_REPORT_PROBLEM_GET_PHRASES_REQUESTED:
      return {
        ...state,
        isFetchingPhrases: true,
        isConfirmLoading: true
      }
    case JOB_REPORT_PROBLEM_GET_PHRASES_SUCCEEDED:
      return {
        ...state,
        isConfirmLoading: false,
        isFetchingPhrases: false,
        phrases: payload.phrases
      }
    case JOB_REPORT_PROBLEM_GET_PHRASES_FAILED:
      return {
        ...state,
        isConfirmLoading: false,
        isFetchingPhrases: false,
        phrases: []
      }
    default:
      return state
  }
}
