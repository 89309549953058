import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  downloadReportsBillingApi,
  exportByEmailReportsBillingApi,
  fetchReportsBillingApi
} from '@doinn/shared/src/api/reports'
import {
  REPORTS_BILLING_DOWNLOAD_FAILED,
  REPORTS_BILLING_DOWNLOAD_REQUESTED,
  REPORTS_BILLING_DOWNLOAD_SUCCEEDED,
  REPORTS_BILLING_EXPORT_EMAIL_FAILED,
  REPORTS_BILLING_EXPORT_EMAIL_REQUESTED,
  REPORTS_BILLING_EXPORT_EMAIL_SUCCEEDED,
  REPORTS_BILLING_FETCH_FAILED,
  REPORTS_BILLING_FETCH_REQUESTED,
  REPORTS_BILLING_FETCH_SUCCEEDED
} from 'containers/reports/billing/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchReportsBilling(action) {
  try {
    const response = yield call(fetchReportsBillingApi, action.payload.params)
    yield put({
      type: REPORTS_BILLING_FETCH_SUCCEEDED,
      payload: {
        ...response.data,
        filters: { ...action.payload.params.params }
      }
    })
  } catch (e) {
    yield put({
      type: REPORTS_BILLING_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* exportByEmailReportsBilling(action) {
  try {
    yield call(exportByEmailReportsBillingApi, action.payload.params)
    yield put({
      type: REPORTS_BILLING_EXPORT_EMAIL_SUCCEEDED,
      snackbar: {
        variant: 'success',
        message: i18n.t('We will send the report to your email')
      }
    })
  } catch (e) {
    yield put({
      type: REPORTS_BILLING_EXPORT_EMAIL_FAILED,
      snackbar: {
        variant: 'error',
        message: e.message
      }
    })
  }
}

function* downloadReportsBilling(action) {
  try {
    const { start, end } = action.payload.params.params
    const reportName = `doinn_billing_services_${start}_${end}.xls`
    const { data } = yield call(
      downloadReportsBillingApi,
      action.payload.params
    )

    yield put({
      type: REPORTS_BILLING_DOWNLOAD_SUCCEEDED,
      snackbar: {
        variant: 'success',
        message: i18n.t('The report has been downloaded successfully')
      },
      downloader: {
        data,
        type: 'application/vnd.ms-excel',
        name: reportName
      }
    })
  } catch (e) {
    yield put({
      type: REPORTS_BILLING_DOWNLOAD_FAILED,
      snackbar: {
        variant: 'error',
        message: e.message
      }
    })
  }
}

export default function* reportsBillingSaga() {
  yield all([
    takeLatest(REPORTS_BILLING_FETCH_REQUESTED, fetchReportsBilling),
    takeLatest(
      REPORTS_BILLING_EXPORT_EMAIL_REQUESTED,
      exportByEmailReportsBilling
    ),
    takeLatest(REPORTS_BILLING_DOWNLOAD_REQUESTED, downloadReportsBilling)
  ])
}
