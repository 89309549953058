import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function fetchReportsOverviewApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/vendor/reports/overview`, config)
}

/**
 * @param {Object} params
 * @param {string} params.start - Date in the format 'YYYY-MM-DD'
 * @param {string} params.end - Date in the format 'YYYY-MM-DD'
 * @param {'monthly' | 'daily'} params.periodicity
 * @returns {AxiosPromise<any>}
 */
export function fetchReportsFinancialsBillingApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/vendor/reports/financials/billing`, config)
}

/**
 * @param {Object} params
 * @param {string} params.start - Date in the format 'YYYY-MM-DD'
 * @param {string} params.end - Date in the format 'YYYY-MM-DD'
 * @param {'hosts' | 'spaces' | 'employees'} params.reportBy
 * @returns {AxiosPromise<any>}
 */
export function fetchReportsBillingApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(
    `/vendor/reports/billing/${config.params.reportBy}`,
    config
  )
}

/**
 * @param {Object} params
 * @param {string} params.start - Date in the format 'YYYY-MM-DD'
 * @param {string} params.end - Date in the format 'YYYY-MM-DD'
 * @param {'hosts' | 'spaces' | 'employees'} params.reportBy
 * @param {number} params.id - The host, space or employee ID
 * @returns {AxiosPromise<any>}
 */
export function fetchReportsBillingDetailsApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(
    `/vendor/reports/billing/${config.params.reportBy}/${config.params.id}`,
    config
  )
}

/**
 * @param {Object} params
 * @param {string} params.start - Date in the format 'YYYY-MM-DD'
 * @param {string} params.end - Date in the format 'YYYY-MM-DD'
 * @returns {AxiosPromise<any>}
 */
export function exportByEmailReportsBillingApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/vendor/reports/billing/export/xls`, config)
}

/**
 * @param {Object} params
 * @param {string} params.start - Date in the format 'YYYY-MM-DD'
 * @param {string} params.end - Date in the format 'YYYY-MM-DD'
 * @returns {AxiosPromise<any>}
 */
export function fetchServicesQuantitiesApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/vendor/reports/order-services/quantities`, config)
}
