import {
  CATALOG_LIST_FETCH_MORE_REQUESTED,
  CATALOG_LIST_FETCH_REQUESTED
} from '@doinn/host/src/containers/catalog/list/constants'

export function fetchCatalogs(params = {}) {
  return { type: CATALOG_LIST_FETCH_REQUESTED, payload: { params } }
}

export function fetchMoreCatalogs(params = {}) {
  return {
    type: CATALOG_LIST_FETCH_MORE_REQUESTED,
    payload: { params }
  }
}
