import {
  JOB_CHECKLIST_GET_FAILED,
  JOB_CHECKLIST_GET_REQUESTED,
  JOB_CHECKLIST_GET_SUCCEEDED
} from '@doinn/shared/src/containers/job/checklist/get/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  item: null,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case JOB_CHECKLIST_GET_REQUESTED:
      return {
        ...initialState,
        status: STATUS.LOADING
      }
    case JOB_CHECKLIST_GET_SUCCEEDED:
      return {
        ...state,
        item: { ...payload.data },
        status: STATUS.SUCCESS
      }
    case JOB_CHECKLIST_GET_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
