import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getReservationApi } from '@doinn/vendor/src/api/reservations'
import {
  RESERVATION_GET_FAILED,
  RESERVATION_GET_REQUESTED,
  RESERVATION_GET_SUCCEEDED
} from '@doinn/vendor/src/containers/reservation/get/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* getReservation(action) {
  try {
    const { id } = action.payload.params
    const response = yield call(getReservationApi, id)
    const { data } = response.data

    yield put({
      type: RESERVATION_GET_SUCCEEDED,
      payload: data
    })
  } catch (e) {
    yield put({
      type: RESERVATION_GET_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* reservationGetSaga() {
  yield all([takeLatest(RESERVATION_GET_REQUESTED, getReservation)])
}
