export const PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_DIALOG_OPEN =
  'PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_DIALOG_OPEN'
export const PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_DIALOG_CLOSE =
  'PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_DIALOG_CLOSE'

export const PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_REQUESTED =
  'PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_REQUESTED'
export const PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_SUCCEEDED =
  'PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_SUCCEEDED'
export const PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_FAILED =
  'PROPERTY_INSTRUCTIONS_EXCEL_DOWNLOAD_FAILED'
