import React, { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'
import { endOfMonth, startOfMonth } from 'date-fns'
import { useHistory, useLocation } from 'react-router-dom'
import { cloneDeep, isEmpty, isEqual as isObjEqual } from 'lodash'

import {
  useDidMount,
  useFilterState,
  usePrevious
} from '@doinn/shared/src/hooks'
import {
  getQueryStringObject,
  updateURLParams
} from '@doinn/shared/src/util/url'
import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import { formatSystemDate } from '@doinn/shared/src/util/date-fns'
import { parseAppliedFilters } from '@doinn/shared/src/util/filters'
import { getAllParamsButParam } from '@doinn/shared/src/util/object'
import TemplateDefault from 'components/TemplateDefault'
import HelperCard from '@doinn/shared/src/components/common/helper/HelperCard'

const InvoiceListContainer = loadable(() =>
  lazyRetry(() => import('@doinn/shared/src/containers/invoice/Invoices'))
)

const SAVED_FILTERS_KEY = 'scenesInvoicesReceivedAppliedFilters'

const getDefaultFilters = () => {
  const nowDate = new Date()
  const startOfMonthDate = startOfMonth(nowDate)
  const endOfMonthDate = endOfMonth(nowDate)

  return {
    page: 1,
    type: 'all',
    period: 'current_month',
    start: formatSystemDate(startOfMonthDate),
    end: formatSystemDate(endOfMonthDate)
  }
}

const getLastSavedFilters = () => {
  try {
    return JSON.parse(window.sessionStorage.getItem(SAVED_FILTERS_KEY))
  } catch (e) {}
  return {}
}

const saveFilters = filters => {
  try {
    const filtersWithoutPage = getAllParamsButParam(filters, 'page')
    return window.sessionStorage.setItem(
      SAVED_FILTERS_KEY,
      JSON.stringify(filtersWithoutPage)
    )
  } catch (e) {}
  return {}
}

const getQueryOrSavedFilters = location => {
  const queryFilters = getQueryStringObject(location.search)
  let filters = cloneDeep(getDefaultFilters())
  if (isEmpty(queryFilters)) {
    filters = {
      ...cloneDeep(getDefaultFilters()),
      ...cloneDeep(getLastSavedFilters())
    }
  } else {
    filters = parseAppliedFilters(filters, queryFilters)
  }
  filters.page = parseInt(filters.page, 10)
  return filters
}

const Invoice = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const previousLocation = usePrevious(location)
  const [justChangedLocation, setJustChangedLocation] = React.useState(false)
  const { appliedFilters, hasAppliedFiltersChanges, onChangeAppliedFilters } =
    useFilterState(getDefaultFilters())

  useDidMount(() => {
    onChangeAppliedFilters(getQueryOrSavedFilters(location))
  })

  React.useEffect(() => {
    if (!previousLocation) return
    setJustChangedLocation(
      !isObjEqual(location.search, previousLocation.search)
    )
  }, [location, previousLocation])

  React.useEffect(() => {
    if (!justChangedLocation) return
    saveFilters(appliedFilters)
    const savedFilters = getQueryOrSavedFilters(location)
    const shouldUpdateAppliedFilters = !isObjEqual(appliedFilters, savedFilters)
    if (shouldUpdateAppliedFilters) {
      onChangeAppliedFilters(savedFilters)
      saveFilters(appliedFilters)
    }
  }, [appliedFilters, justChangedLocation, location, onChangeAppliedFilters])

  React.useEffect(() => {
    const shouldUpdateUrl =
      hasAppliedFiltersChanges &&
      !isObjEqual(appliedFilters, getQueryOrSavedFilters(location))
    if (shouldUpdateUrl) {
      updateURLParams(appliedFilters, history)
    }
  }, [appliedFilters, hasAppliedFiltersChanges, history, location])

  const handleChangeAppliedFilters = useCallback(
    filters => {
      onChangeAppliedFilters(filters)
    },
    [onChangeAppliedFilters]
  )

  return (
    <TemplateDefault title={t('Invoices')} helperContent={<HelperCard />}>
      <Helmet>
        <title>{t('Invoices')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <InvoiceListContainer
          filters={appliedFilters}
          onChangeFilters={handleChangeAppliedFilters}
        />
      </React.Suspense>
    </TemplateDefault>
  )
}

export default Invoice
