import { cloneDeep, findIndex } from 'lodash'
import {
  CONTRACT_VENDOR_CITIES_FETCH_FAILED,
  CONTRACT_VENDOR_CITIES_FETCH_REQUESTED,
  CONTRACT_VENDOR_CITIES_FETCH_SUCCEEDED,
  CONTRACT_VENDOR_PRICE_FETCH_REQUESTED,
  CONTRACT_VENDOR_PRICE_FETCH_SUCCEEDED,
  CONTRACT_VENDOR_PRICE_UPDATE_SUCCEEDED
} from 'containers/onboarding/steps/Prices/constants'

export const initialState = {
  cities: [],
  service: [],
  isPriceLoading: true,
  isPricesUpdateLoading: false,
  isLoading: false
}

const mapSavedCityServices = (cities, serviceId) => {
  return cloneDeep(cities).map(city => {
    const spaceCategories = city.spaceCategories.map(spaceCategory => {
      const services = spaceCategory.services
      const serviceIndex = findIndex(
        spaceCategory.services,
        service => service.id === serviceId
      )

      if (serviceIndex !== -1) {
        services[serviceIndex].isNew = false
      }

      return { ...spaceCategory, services: services }
    })

    return { ...city, spaceCategories }
  })
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case CONTRACT_VENDOR_CITIES_FETCH_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case CONTRACT_VENDOR_CITIES_FETCH_SUCCEEDED:
      return {
        ...state,
        cities: payload,
        service: [],
        isPriceLoading: true,
        isLoading: false
      }
    case CONTRACT_VENDOR_CITIES_FETCH_FAILED:
      return {
        ...state,
        isLoading: false
      }
    case CONTRACT_VENDOR_PRICE_FETCH_REQUESTED:
      return {
        ...state,
        isPriceLoading: true
      }
    case CONTRACT_VENDOR_PRICE_FETCH_SUCCEEDED:
      return {
        ...state,
        service: payload,
        isPriceLoading: false
      }
    case CONTRACT_VENDOR_PRICE_UPDATE_SUCCEEDED:
      return {
        ...state,
        cities: mapSavedCityServices(state.cities, action.payload.serviceId)
      }
    default:
      return state
  }
}
