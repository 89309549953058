export const RESERVATION_DELETE_ALL_DIALOG_OPEN =
  'RESERVATION_DELETE_ALL_DIALOG_OPEN'
export const RESERVATION_DELETE_ALL_DIALOG_CLOSE =
  'RESERVATION_DELETE_ALL_DIALOG_CLOSE'

export const RESERVATION_DELETE_ALL_REQUESTED =
  'RESERVATION_DELETE_ALL_REQUESTED'
export const RESERVATION_DELETE_ALL_SUCCEEDED =
  'RESERVATION_DELETE_ALL_SUCCEEDED'
export const RESERVATION_DELETE_ALL_FAILED = 'RESERVATION_DELETE_ALL_FAILED'
