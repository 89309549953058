export const RECEIVED_INVOICES_FETCH_REQUESTED =
  'RECEIVED_INVOICES_FETCH_REQUESTED'
export const RECEIVED_INVOICES_FETCH_SUCCEEDED =
  'RECEIVED_INVOICES_FETCH_SUCCEEDED'
export const RECEIVED_INVOICES_FETCH_FAILED = 'RECEIVED_INVOICES_FETCH_FAILED'
export const RECEIVED_INVOICES_FETCH_MORE_REQUESTED =
  'RECEIVED_INVOICES_FETCH_MORE_REQUESTED'
export const RECEIVED_INVOICES_FETCH_MORE_SUCCEEDED =
  'RECEIVED_INVOICES_FETCH_MORE_SUCCEEDED'
export const RECEIVED_INVOICES_FETCH_MORE_FAILED =
  'RECEIVED_INVOICES_FETCH_MORE_FAILED'

export const RECEIVED_INVOICES_EXPORT_REQUESTED =
  'RECEIVED_INVOICES_EXPORT_REQUESTED'
export const RECEIVED_INVOICES_EXPORT_SUCCEEDED =
  'RECEIVED_INVOICES_EXPORT_SUCCEEDED'
export const RECEIVED_INVOICES_EXPORT_FAILED = 'RECEIVED_INVOICES_EXPORT_FAILED'

export const RECEIVED_INVOICES_DOWNLOAD_REQUESTED =
  'RECEIVED_INVOICES_DOWNLOAD_REQUESTED'
export const RECEIVED_INVOICES_DOWNLOAD_SUCCEEDED =
  'RECEIVED_INVOICES_DOWNLOAD_SUCCEEDED'
export const RECEIVED_INVOICES_DOWNLOAD_FAILED =
  'RECEIVED_INVOICES_DOWNLOAD_FAILED'
