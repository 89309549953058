import {
  ACTIVITIES_FETCH_FAILED,
  ACTIVITIES_FETCH_REQUESTED,
  ACTIVITIES_FETCH_SUCCEEDED
} from '@doinn/vendor/src/containers/dashboard/dashlets/activities-list/constants'

export const STATUS = {
  IDLE: 'idle',
  FETCHING: 'fetching',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  status: STATUS.IDLE,
  payments: [],
  reservations: [],
  requests: []
}

const activities = data => {
  const payments = data?.payments ?? []
  return {
    ...data,
    payments: payments.map(item => ({
      ...item,
      price: {
        ...item.orderServices.reduce(
          (totalPrice, service) => {
            return {
              total: totalPrice.total + service?.price?.total,
              currency: service?.price?.currency
            }
          },
          { total: 0, currency: '' }
        )
      }
    }))
  }
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case ACTIVITIES_FETCH_REQUESTED:
      return { ...initialState, status: STATUS.FETCHING }
    case ACTIVITIES_FETCH_SUCCEEDED:
      return { ...state, ...activities(payload.data), status: STATUS.SUCCESS }
    case ACTIVITIES_FETCH_FAILED:
      return { ...state, status: STATUS.FAILURE }
    default:
      return state
  }
}
