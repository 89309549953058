import { all, call, put, takeLatest } from 'redux-saga/effects'
import { addChecklistMyApi } from '@doinn/host/src/api/checklists'
import {
  CHECKLIST_MY_ADD_FAILED,
  CHECKLIST_MY_ADD_REQUESTED,
  CHECKLIST_MY_ADD_SUCCEEDED
} from '@doinn/host/src/containers/checklist/my/add/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* addChecklistMy(action) {
  try {
    const { checklist } = action.payload
    yield call(addChecklistMyApi, checklist)
    yield put({
      type: CHECKLIST_MY_ADD_SUCCEEDED,
      payload: checklist
    })
  } catch (e) {
    yield put({
      type: CHECKLIST_MY_ADD_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* checklistMyAddSaga() {
  yield all([takeLatest(CHECKLIST_MY_ADD_REQUESTED, addChecklistMy)])
}
