import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { addPropertyApi } from '@doinn/shared/src/api/property'
import {
  PROPERTY_ADD_FAILED,
  PROPERTY_ADD_REQUESTED,
  PROPERTY_ADD_SUCCEEDED
} from '@doinn/shared/src/containers/property/add/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* addProperty(action) {
  try {
    const { property } = action.payload
    yield call(addPropertyApi, property)
    yield put({
      type: PROPERTY_ADD_SUCCEEDED,
      payload: property,
      snackbar: {
        variant: 'success',
        message: i18n.t('The property has been created successfully')
      }
    })
  } catch (e) {
    yield put({
      type: PROPERTY_ADD_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* propertiesAddSaga() {
  yield all([takeLatest(PROPERTY_ADD_REQUESTED, addProperty)])
}
