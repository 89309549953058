import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { withEcho } from '@doinn/shared/src/hoc/echo'
import { usePrevious, useWillUnmount } from '@doinn/shared/src/hooks'
import convertToCamelCaseKeys from '@doinn/shared/src/util/convertToCamelCaseKeys'
import { ECHO_EVENT_SUBSCRIPTION_UPDATED } from '@doinn/shared/src/hoc/echo/constants'
import { updateSubscription } from '@doinn/shared/src/containers/subscription/actions'

const SubscriptionListener = ({ echoUserChannel }) => {
  const dispatch = useDispatch()
  const [isListening, setIsListening] = React.useState(false)
  const previousEchoUserChannel = usePrevious(echoUserChannel)

  const handleEchoUpdates = useCallback(() => {
    if (echoUserChannel && !isListening) {
      echoUserChannel.listen(ECHO_EVENT_SUBSCRIPTION_UPDATED, data => {
        const subscription = convertToCamelCaseKeys(data?.data) ?? null
        dispatch(updateSubscription(subscription))
      })
      setIsListening(true)
    }
  }, [echoUserChannel, isListening, setIsListening, dispatch])

  const stopListeningEchoUpdate = useCallback(
    echoChannel => {
      if (echoChannel && isListening) {
        echoChannel.stopListening(ECHO_EVENT_SUBSCRIPTION_UPDATED)
        setIsListening(false)
      }
    },
    [isListening, setIsListening]
  )

  React.useEffect(() => {
    handleEchoUpdates()
  }, [handleEchoUpdates])

  React.useEffect(() => {
    if (previousEchoUserChannel && !echoUserChannel) {
      stopListeningEchoUpdate(previousEchoUserChannel)
    }
  }, [previousEchoUserChannel, echoUserChannel, stopListeningEchoUpdate])

  useWillUnmount(() => {
    stopListeningEchoUpdate(echoUserChannel)
  })

  return null
}

export default withEcho(SubscriptionListener)
