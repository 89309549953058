import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchAutomationsApi } from '@doinn/host/src/api/automations'
import {
  PROPERTIES_AUTOMATIONS_FETCH_FAILED,
  PROPERTIES_AUTOMATIONS_FETCH_REQUESTED,
  PROPERTIES_AUTOMATIONS_FETCH_SUCCEEDED
} from '@doinn/shared/src/containers/property/automations/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchAutomations(action) {
  try {
    const response = yield call(fetchAutomationsApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: PROPERTIES_AUTOMATIONS_FETCH_SUCCEEDED,
      payload: {
        data,
        meta
      }
    })
  } catch (e) {
    yield put({
      type: PROPERTIES_AUTOMATIONS_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* propertiesAutomationsSaga() {
  yield all([
    takeLatest(PROPERTIES_AUTOMATIONS_FETCH_REQUESTED, fetchAutomations)
  ])
}
