export const CHECKOUT_CONFIRM_CLOSE = 'CHECKOUT_CONFIRM_CLOSE'

export const CHECKOUT_MULTIPLE_CREATE_REQUESTED =
  'CHECKOUT_MULTIPLE_CREATE_REQUESTED'
export const CHECKOUT_CREATE_REQUESTED = 'CHECKOUT_CREATE_REQUESTED'
export const CHECKOUT_SUBSCRIPTION_CREATE_REQUESTED =
  'CHECKOUT_SUBSCRIPTION_CREATE_REQUESTED'
export const CHECKOUT_CREATE_SUCCEEDED = 'CHECKOUT_CREATE_SUCCEEDED'
export const CHECKOUT_CREATE_FAILED = 'CHECKOUT_CREATE_FAILED'

export const CHECKOUT_GET_REQUESTED = 'CHECKOUT_GET_REQUESTED'
export const CHECKOUT_GET_SUCCEEDED = 'CHECKOUT_GET_SUCCEEDED'
export const CHECKOUT_GET_FAILED = 'CHECKOUT_GET_FAILED'

export const CHECKOUT_CONFIRM_REQUESTED = 'CHECKOUT_CONFIRM_REQUESTED'
export const CHECKOUT_CONFIRM_SUCCEEDED = 'CHECKOUT_CONFIRM_SUCCEEDED'
export const CHECKOUT_CONFIRM_FAILED = 'CHECKOUT_CONFIRM_FAILED'

export const CHECKOUT_PAY_REQUESTED = 'CHECKOUT_PAY_REQUESTED'
export const CHECKOUT_PAY_SUCCEEDED = 'CHECKOUT_PAY_SUCCEEDED'
export const CHECKOUT_PAY_FAILED = 'CHECKOUT_PAY_FAILED'

export const CHECKOUT_SUBSCRIPTION_REQUESTED = 'CHECKOUT_SUBSCRIPTION_REQUESTED'
export const CHECKOUT_SUBSCRIPTION_SUCCEEDED = 'CHECKOUT_SUBSCRIPTION_SUCCEEDED'
