import { createSelector } from 'reselect'
import { find } from 'lodash'

const getOnboardingStepsData = state => state.onboarding.data.steps
const getOnboardingCurrentStepData = state => state.onboarding.data.currentStep

export const getOnboardingStepCompleted = slug =>
  createSelector(
    [getOnboardingStepsData],
    steps => find(steps, step => step.slug === slug).completed
  )

export const getOnboardingStepData = slug =>
  createSelector(
    [getOnboardingStepsData],
    steps => find(steps, step => step.slug === slug).data
  )

export const getOnboardingSteps = createSelector(
  [getOnboardingStepsData],
  steps => steps
)

export const getOnboardingCurrentStep = createSelector(
  [getOnboardingCurrentStepData],
  currentStep => currentStep
)
