import React from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'

import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import { useDetailsFilters } from '@doinn/vendor/src/scenes/hooks/useDetailsFilters'
import TemplateDefault from 'components/TemplateDefault'

const CustomerDetailsContainer = loadable(() =>
  lazyRetry(() =>
    import('@doinn/vendor/src/containers/customer/details/CustomerDetails')
  )
)

const CustomerDetails = () => {
  const { t } = useTranslation()

  const { contentFilterProps, filtersContent, filtersDialog } =
    useDetailsFilters({
      entityFilterName: 'customers',
      entityIdParamName: 'customerId'
    })

  return (
    <TemplateDefault title={t('People')} filtersContent={filtersContent}>
      <Helmet>
        <title>{t('People')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <CustomerDetailsContainer contentProps={contentFilterProps} />
      </React.Suspense>
      {filtersDialog}
    </TemplateDefault>
  )
}

export default CustomerDetails
