import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchPropertyBusinessAccountsApi } from '@doinn/shared/src/api/property-business-accounts'
import {
  PROPERTY_BUSINESS_ACCOUNTS_FETCH_FAILED,
  PROPERTY_BUSINESS_ACCOUNTS_FETCH_REQUESTED,
  PROPERTY_BUSINESS_ACCOUNTS_FETCH_SUCCEEDED
} from '@doinn/shared/src/containers/property-business-accounts/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchPropertyBusinessAccounts(action) {
  try {
    const response = yield call(fetchPropertyBusinessAccountsApi)
    yield put({
      type: PROPERTY_BUSINESS_ACCOUNTS_FETCH_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: PROPERTY_BUSINESS_ACCOUNTS_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* propertyBusinessAccountsSaga() {
  yield all([
    takeLatest(
      PROPERTY_BUSINESS_ACCOUNTS_FETCH_REQUESTED,
      fetchPropertyBusinessAccounts
    )
  ])
}
