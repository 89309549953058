export const countAppliedFilters = (filters, hiddenFilters = []) => {
  let total = 0
  for (const key in filters) {
    if (filters[key]) {
      const value = filters[key]
      if (Array.isArray(value)) {
        total += value.length
      }
    }
  }
  return total
}
