import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import Select from '@doinn/shared/src/components/common/filter/Select'
import RadioList from '@doinn/shared/src/components/common/filter/RadioList'

const ListFiltersBy = ({
  filters,
  onChangeFilters,
  filterOptions,
  variant
}) => {
  const { t } = useTranslation()
  const parsedFilterOptions = filterOptions || [
    {
      id: 'all',
      name: t('All sources')
    },
    {
      id: 'owner',
      name: t('My own services')
    },
    {
      id: 'customers',
      name: t('My customers')
    },
    {
      id: 'doinn',
      name: t('Doinn customers')
    }
  ]

  const handleChangeFilterBy = useCallback(
    (filterName, newValue) => {
      const params = cloneDeep({
        ...filters,
        page: 1
      })
      params[filterName] = newValue
      onChangeFilters && onChangeFilters(params)
    },
    [filters, onChangeFilters]
  )

  const renderListVariant = () => {
    return (
      <RadioList
        subheader={t('Source')}
        filterName='sourceBy'
        items={parsedFilterOptions}
        checkedItems={filters.sourceBy}
        onChange={handleChangeFilterBy}
      />
    )
  }

  const renderMenuVariant = () => {
    return (
      <React.Fragment>
        <Typography variant='body2' gutterBottom>
          {t('Source')}
        </Typography>
        <Select
          filterOptions={parsedFilterOptions}
          selectedFilter={filters.sourceBy}
          buttonLabel={t('Source')}
          filterName='sourceBy'
          onChange={handleChangeFilterBy}
        />
      </React.Fragment>
    )
  }

  if (variant === 'list') return renderListVariant()
  return renderMenuVariant()
}

ListFiltersBy.propTypes = {
  variant: PropTypes.oneOf(['list', 'menu']),
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  filters: PropTypes.shape({
    sourceBy: PropTypes.string
  }),
  onChangeFilters: PropTypes.func
}

ListFiltersBy.defaultProps = {
  variant: 'menu'
}

export default ListFiltersBy
