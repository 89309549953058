import { cloneDeep, find, indexOf, map, without } from 'lodash'
import { combineReducers } from 'redux'
import citiesStepReducers from 'containers/onboarding/steps/Cities/reducers'
import cityServicesStepReducers from 'containers/onboarding/steps/CityServices/reducers'
import countryStepReducers from 'containers/onboarding/steps/Country/reducers'
import documentsStepReducers from 'containers/onboarding/steps/Documents/reducers'
import pricesStepReducers from 'containers/onboarding/steps/Prices/reducers'
import propertyTypesStepReducers from 'containers/onboarding/steps/PropertyTypes/reducers'
import servicesStepReducers from 'containers/onboarding/steps/Services/reducers'
import termsStepReducers from 'containers/onboarding/steps/Terms/reducers'
import {
  ONBOARDING_FINISH_FAILED,
  ONBOARDING_FINISH_REQUESTED,
  ONBOARDING_FINISH_SUCCEEDED,
  ONBOARDING_GET_STEPS_FAILED,
  ONBOARDING_GET_STEPS_SUCCEEDED,
  ONBOARDING_NEXT_STEP_FAILED,
  ONBOARDING_NEXT_STEP_REQUESTED,
  ONBOARDING_NEXT_STEP_SUCCEEDED,
  ONBOARDING_PREVIOUS_STEP,
  ONBOARDING_RESET_STEPPER,
  ONBOARDING_SET_CURRENT_STEP
} from 'containers/onboarding/constants'
import { ONBOARDING_UPDATE_SELECTED_COUNTRY_REQUESTED } from 'containers/onboarding/steps/Country/constants'
import { ONBOARDING_UPDATE_SELECTED_CITIES_REQUESTED } from 'containers/onboarding/steps/Cities/constants'
import {
  ONBOARDING_SET_SERVICES,
  ONBOARDING_TOGGLE_SERVICE
} from 'containers/onboarding/steps/Services/constants'
import { ONBOARDING_TOGGLE_PROPERTY_TYPE } from 'containers/onboarding/steps/PropertyTypes/constants'
import {
  CONTRACT_CITY_SERVICES_SAVE_FAILED,
  CONTRACT_CITY_SERVICES_SAVE_REQUESTED,
  ONBOARDING_TOGGLE_CITY_SERVICE
} from 'containers/onboarding/steps/CityServices/constants'

export const initialState = {
  steps: [
    {
      completed: false,
      data: {
        country: null
      },
      label: 'Welcome',
      slug: 'country'
    },
    {
      completed: false,
      data: {
        cities: []
      },
      label: 'Cities',
      slug: 'cities'
    },
    {
      completed: false,
      data: {
        propertyTypes: []
      },
      label: 'Property Types',
      slug: 'propertyTypes'
    },
    {
      completed: false,
      data: {
        services: []
      },
      label: 'Services',
      slug: 'services'
    },
    {
      completed: false,
      data: {
        cityServices: {}
      },
      label: 'City Services',
      slug: 'cityServices'
    },
    {
      completed: false,
      data: {},
      label: 'Prices',
      slug: 'prices'
    },
    {
      completed: false,
      data: {
        documents: {}
      },
      label: 'Documents',
      slug: 'documents'
    }
  ],
  currentStep: 0,
  isLoadingStep: true
}

const stepIndexes = initialState.steps.map(state => state.slug)

const mergeWithRemoteSteps = (steps, remoteSteps) =>
  map(steps, step => {
    const { slug } = step
    const remoteStep = find(remoteSteps, remoteStep => remoteStep.slug === slug)

    if (remoteStep) {
      return { ...step, ...remoteStep }
    }

    return step
  })

const data = (state = initialState, action) => {
  let steps = [...state.steps]
  const lastStepIndex = steps.length - 1
  const nextStepIndex =
    lastStepIndex === state.currentStep
      ? state.currentStep
      : state.currentStep + 1
  const { type, payload } = action
  let stepIndex = -1
  let propertyTypes
  let services
  let selectedCityServices

  switch (type) {
    case ONBOARDING_NEXT_STEP_REQUESTED:
      return {
        ...state,
        isLoadingStep: true
      }

    case ONBOARDING_GET_STEPS_SUCCEEDED:
      steps = mergeWithRemoteSteps(steps, payload.steps)
      return {
        ...state,
        steps: steps,
        isLoadingStep: false
      }

    case ONBOARDING_NEXT_STEP_SUCCEEDED:
      steps = mergeWithRemoteSteps(steps, payload.steps)
      steps[state.currentStep].completed = true
      return {
        ...state,
        steps: steps,
        isLoadingStep: false,
        currentStep: nextStepIndex
      }

    case ONBOARDING_GET_STEPS_FAILED:
    case ONBOARDING_NEXT_STEP_FAILED:
      return {
        ...state,
        isLoadingStep: false
      }

    case ONBOARDING_SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload.step
      }

    case ONBOARDING_PREVIOUS_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1
      }

    case ONBOARDING_RESET_STEPPER:
      return {
        ...state,
        currentStep: 0
      }

    case ONBOARDING_UPDATE_SELECTED_COUNTRY_REQUESTED:
      stepIndex = stepIndexes.indexOf('country')
      steps[stepIndex].data.country = payload.country

      return {
        ...state,
        steps: cloneDeep(steps)
      }

    case ONBOARDING_UPDATE_SELECTED_CITIES_REQUESTED:
      stepIndex = stepIndexes.indexOf('cities')

      steps[stepIndex].data.cities = [...payload.cities]

      return {
        ...state,
        steps: cloneDeep(steps)
      }

    case ONBOARDING_TOGGLE_PROPERTY_TYPE:
      stepIndex = stepIndexes.indexOf('propertyTypes')
      propertyTypes = steps[stepIndex].data.propertyTypes

      if (indexOf(propertyTypes, payload) === -1) {
        propertyTypes = [...propertyTypes, payload]
      } else {
        propertyTypes = without(propertyTypes, payload)
      }

      steps[stepIndex].data.propertyTypes = propertyTypes

      return {
        ...state,
        steps: cloneDeep(steps)
      }

    case ONBOARDING_SET_SERVICES:
      stepIndex = stepIndexes.indexOf('services')

      steps[stepIndex].data.services = payload

      return {
        ...state,
        steps: cloneDeep(steps)
      }

    case ONBOARDING_TOGGLE_SERVICE:
      stepIndex = stepIndexes.indexOf('services')
      services = steps[stepIndex].data.services

      if (indexOf(services, payload) === -1) {
        services = [...services, payload]
      } else {
        services = without(services, payload)
      }

      steps[stepIndex].data.services = services

      return {
        ...state,
        steps: cloneDeep(steps)
      }

    case ONBOARDING_TOGGLE_CITY_SERVICE:
      stepIndex = stepIndexes.indexOf('cityServices')
      selectedCityServices = { ...steps[stepIndex].data.cityServices }
      steps[stepIndex].data.cityServices[payload.city] = selectedCityServices[
        payload.city
      ].includes(payload.service)
        ? [...without(selectedCityServices[payload.city], payload.service)]
        : [...selectedCityServices[payload.city], payload.service]

      return {
        ...state,
        steps: cloneDeep(steps)
      }
    case CONTRACT_CITY_SERVICES_SAVE_REQUESTED:
      return {
        ...state,
        isLoadingStep: true
      }
    case CONTRACT_CITY_SERVICES_SAVE_FAILED:
      return {
        ...state,
        isLoadingStep: false
      }
    case ONBOARDING_FINISH_REQUESTED:
      return {
        ...state,
        isLoadingStep: true
      }
    case ONBOARDING_FINISH_SUCCEEDED:
      return {
        ...state,
        isLoadingStep: false
      }
    case ONBOARDING_FINISH_FAILED:
      return {
        ...state,
        isLoadingStep: false
      }
    default:
      return state
  }
}

export default combineReducers({
  data,
  countryStep: countryStepReducers,
  citiesStep: citiesStepReducers,
  propertyTypesStep: propertyTypesStepReducers,
  cityServicesStep: cityServicesStepReducers,
  servicesStep: servicesStepReducers,
  pricesStep: pricesStepReducers,
  termsStep: termsStepReducers,
  documentsStep: documentsStepReducers
})
