// @flow
import React from 'react'
import { List } from '@material-ui/core'
import LoadingListItem from '@doinn/shared/src/components/common/loading/LoadingListItem'

export type Props = {
  items: number,
  avatar: boolean,
  primary: boolean,
  secondary: boolean,
  listProps: null
}

const LoadingList = (props: Props) => {
  return (
    <List component='ul' {...props.listProps}>
      {[...Array(props.items)].map((value, index) => {
        return <LoadingListItem key={index} {...props} />
      })}
    </List>
  )
}

LoadingList.defaultProps = {
  items: 3,
  avatar: false,
  primary: true,
  secondary: false,
  listProps: {}
}

export default LoadingList
