import React from 'react'
import { compose } from 'redux'
import { useTranslation } from 'react-i18next'
import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core'

import withAuthorization from '@doinn/shared/src/hoc/authorization/withAuthorization'
import ListFiltersBy from 'components/job/ListFiltersBy'
import Address from '@doinn/shared/src/components/common/Address'
import RadioList from '@doinn/shared/src/components/common/filter/RadioList'
import CheckboxList from '@doinn/shared/src/components/common/filter/CheckboxList'
import FilterDateRange from '@doinn/shared/src/components/job/common/FilterDateRange'
import AutocompleteMultiple from '@doinn/shared/src/components/common/filter/AutocompleteMultiple'

const PropertyOptionRenderer = option => {
  return (
    <Grid container alignItems='center'>
      <Grid item xs>
        <Typography variant='body1'>
          <b>{`${option.name} (${option.code})`}</b>
        </Typography>
        <Typography variant='body2'>
          <Address
            address={option.address}
            complement={option.complement}
            zipcode={option.zipcode}
            city={{
              name: option.name
            }}
            country={{
              name: option.country
            }}
          />
        </Typography>
      </Grid>
    </Grid>
  )
}

const ListFilters = ({
  alwaysDisplayDateRange,
  alwaysDisplaySource,
  can,
  canAll,
  isCitiesLoading,
  isServicesLoading,
  isEmployeesLoading,
  isAutomationsLoading,
  isHostBusinessAccountsLoading,
  isTypologiesLoading,
  cities,
  services,
  employees,
  automations,
  hostBusinessAccounts,
  typologies,
  filters,
  disabledFilters,
  hiddenFilters,
  userRole,
  onChange,
  onChangeFilters,
  getAutocompleteOptions
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = !useMediaQuery(theme.breakpoints.up('md'))
  const isVendor = userRole === 'vendor'

  const statusOptions = [
    {
      id: 'not_confirmed',
      name: t('Not confirmed'),
      display: can('create-jobs')
    },
    {
      id: 'scheduled',
      name: t('Scheduled'),
      display: true
    },
    {
      id: 'confirmed',
      name: t('Confirmed'),
      display: true
    },
    {
      id: 'started',
      name: t('Started'),
      display: true
    },
    {
      id: 'done',
      name: t('Done'),
      display: true
    },
    {
      id: 'pending',
      name: t('Pending'),
      display: true
    },
    {
      id: 'canceled',
      name: t('Canceled'),
      display: true
    }
  ].filter(status => status.display)

  const priorityOptions = [
    {
      id: 'high',
      name: t('High')
    },
    {
      id: 'normal',
      name: t('Normal')
    },
    {
      id: 'low',
      name: t('Low')
    }
  ]

  const notesOptions = [
    {
      id: 'all',
      name: t('All')
    },
    {
      id: 'true',
      name: t('Yes')
    },
    {
      id: 'false',
      name: t('No')
    }
  ]

  const employeesOptions = [
    {
      id: 'none',
      name: t('No employee')
    },
    ...employees
  ]

  return (
    <React.Fragment>
      {(isMobile || alwaysDisplayDateRange) && (
        <FilterDateRange
          variant='list'
          filters={filters}
          onChangeFilters={onChangeFilters}
        />
      )}
      {isVendor && (isMobile || alwaysDisplaySource) && (
        <ListFiltersBy
          variant='list'
          filters={filters}
          onChangeFilters={onChangeFilters}
        />
      )}
      <CheckboxList
        isLoading={isCitiesLoading}
        subheader={t('Cities')}
        filterName='cities'
        items={cities}
        checkedItems={filters.cities}
        onChange={onChange}
      />
      <CheckboxList
        subheader={t('Status')}
        filterName='status'
        items={statusOptions}
        checkedItems={filters.status}
        onChange={onChange}
      />
      <CheckboxList
        subheader={t('Priorities')}
        filterName='priorities'
        items={priorityOptions}
        checkedItems={filters.priorities}
        onChange={onChange}
      />
      {can('read-order-service-notes') ? (
        <RadioList
          subheader={t('Notes')}
          filterName='notes'
          items={notesOptions}
          checkedItems={filters.notes}
          onChange={onChange}
        />
      ) : null}
      <CheckboxList
        isLoading={isServicesLoading}
        subheader={t('Services')}
        filterName='services'
        items={services}
        checkedItems={filters.services}
        onChange={onChange}
      />
      {canAll(['read-people-staff', 'update-job-staff']) &&
      !hiddenFilters?.includes('employees') ? (
        <CheckboxList
          isLoading={isEmployeesLoading}
          subheader={t('Employees')}
          filterName='employees'
          items={employeesOptions}
          checkedItems={filters.employees}
          onChange={onChange}
        />
      ) : null}
      {hiddenFilters?.includes('spaces') ? null : (
        <AutocompleteMultiple
          subheader={t('Properties')}
          filterName='spaces'
          selectedItems={filters.spaces}
          disabled={disabledFilters?.spaces}
          hideAutocomplete={disabledFilters?.spaces}
          optionRenderer={PropertyOptionRenderer}
          onChange={onChange}
          getAutocompleteOptions={getAutocompleteOptions}
        />
      )}
      {filters.hostBusinessAccounts ? (
        <CheckboxList
          isLoading={isHostBusinessAccountsLoading}
          subheader={t('Hosts')}
          filterName='hostBusinessAccounts'
          items={hostBusinessAccounts}
          checkedItems={filters.hostBusinessAccounts}
          onChange={onChange}
        />
      ) : null}
      {filters.typologies ? (
        <CheckboxList
          isLoading={isTypologiesLoading}
          subheader={t('Typologies')}
          filterName='typologies'
          items={typologies}
          checkedItems={filters.typologies}
          onChange={onChange}
        />
      ) : null}
      {can('read-automations') && !hiddenFilters?.includes('automations') ? (
        <CheckboxList
          isLoading={isAutomationsLoading}
          subheader={t('Automations')}
          filterName='automations'
          items={automations}
          checkedItems={filters.automations}
          onChange={onChange}
        />
      ) : null}
    </React.Fragment>
  )
}

export default compose(withAuthorization)(ListFilters)
