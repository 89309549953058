import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getLoggedUserPermissions } from '@doinn/shared/src/containers/user/selectors'
import {
  can,
  canAll,
  canAny,
  canNot,
  canNotAny
} from '@doinn/shared/src/hoc/authorization/util'

export default WrappedComponent => {
  const AuthorizeComponent = props => {
    const { permissions, ...passThroughProps } = props

    const addedProps = {
      can: ability => can(ability, permissions),
      canAll: abilities => canAll(abilities, permissions),
      canAny: abilities => canAny(abilities, permissions),
      canNot: ability => canNot(ability, permissions),
      canNotAny: abilities => canNotAny(abilities, permissions)
    }

    return <WrappedComponent {...passThroughProps} {...addedProps} />
  }

  const mapStateToProps = state => ({
    permissions: getLoggedUserPermissions(state)
  })

  const mapDispatchToProps = dispatch => ({})

  return compose(connect(mapStateToProps, mapDispatchToProps))(
    AuthorizeComponent
  )
}
