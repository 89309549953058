import { cloneDeep, map } from 'lodash'
import {
  DASHLET_JOB_LIST_FETCH_FAILED,
  DASHLET_JOB_LIST_FETCH_REQUESTED,
  DASHLET_JOB_LIST_FETCH_SUCCEEDED,
  DASHLET_JOB_LIST_UPDATED,
  DASHLET_JOB_LIST_UPDATE_SELECTED_JOBS
} from 'containers/dashboard/dashlets/job-list/constants'
import { JOB_GET_SUCCEEDED } from '@doinn/vendor/src/containers/job/constants'
import { JOB_SERVICE_DELETE_SUCCEEDED } from 'containers/job/delete/constants'
import { JOB_ASSIGN_STAFF_EDIT_SUCCEEDED } from 'containers/job/assign-staff/constants'
import { JOB_CANCEL_REQUEST_SUCCEEDED } from '@doinn/vendor/src/containers/job/cancel-request/constants'

export const initialState = {
  selectedJobs: [],
  data: [],
  meta: {},
  isLoaded: false,
  isLoading: false,
  isFailed: false
}

const parseAssignedStaffToState = (state, payload) => {
  const newState = cloneDeep(state)
  payload.data.jobs.forEach(job => {
    if (newState.data[job.id]) {
      newState.data[job.id].employees = [...job.employees]
      newState.data[job.id].team = job.team ? { ...job.team } : null
    }
  })
  return newState
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case DASHLET_JOB_LIST_FETCH_REQUESTED:
      return {
        ...state,
        ...initialState,
        isLoaded: false,
        isLoading: true,
        isFailed: false
      }
    case DASHLET_JOB_LIST_FETCH_SUCCEEDED:
      return {
        ...state,
        data: { ...payload.data },
        meta: { ...payload.meta },
        isLoaded: true,
        isLoading: false,
        isFailed: false
      }
    case DASHLET_JOB_LIST_FETCH_FAILED:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        isFailed: true
      }
    case DASHLET_JOB_LIST_UPDATED:
      return {
        ...state,
        data: { ...payload.data }
      }
    case JOB_ASSIGN_STAFF_EDIT_SUCCEEDED:
      return {
        ...parseAssignedStaffToState(state, payload),
        selectedJobs: []
      }
    case DASHLET_JOB_LIST_UPDATE_SELECTED_JOBS:
      return {
        ...state,
        selectedJobs: [...payload]
      }
    case JOB_SERVICE_DELETE_SUCCEEDED:
      return {
        ...state,
        data: map(state.data, (val, key) => {
          if (val?.id === payload?.job?.id) {
            return {
              ...val,
              ...payload.job
            }
          }
          return val
        })
      }
    case JOB_CANCEL_REQUEST_SUCCEEDED:
      return {
        ...state,
        data: map(state.data, (val, key) => {
          if (val?.id === payload?.job?.id) {
            return {
              ...val,
              ...payload.job
            }
          }
          return val
        })
      }
    case JOB_GET_SUCCEEDED:
      return {
        ...state,
        data: map(state.data, (val, key) => {
          if (val?.id === payload?.data?.id) {
            return {
              ...val,
              ...payload.data
            }
          }
          return val
        })
      }
    default:
      return state
  }
}
