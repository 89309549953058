import { all, call, put, takeLatest } from 'redux-saga/effects'
import { createJobUpdateRequestApi } from '@doinn/vendor/src/api/job'
import { getJobApi } from 'api/job'
import {
  JOB_RESCHEDULE_REQUEST_FAILED,
  JOB_RESCHEDULE_REQUEST_REQUESTED,
  JOB_RESCHEDULE_REQUEST_SUCCEEDED
} from '@doinn/vendor/src/containers/job/reschedule/constants'
import { JOB_GET_SUCCEEDED } from '@doinn/vendor/src/containers/job/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import { getJobUpdateRequestSagaTextBy } from '@doinn/vendor/src/containers/job/update-request/util'

function* rescheduleRequest(action) {
  try {
    const { jobId, isJobOwner, ...body } = action.payload.params
    const response = yield call(createJobUpdateRequestApi, jobId, body)
    const { data } = response.data

    if (data) {
      const responseUpdatedJob = yield call(getJobApi, jobId)
      const { data: updatedJob } = responseUpdatedJob.data
      const messagePath = isJobOwner
        ? 'reschedule.create.host.success'
        : 'reschedule.create.vendor.success'

      yield put({
        type: JOB_GET_SUCCEEDED,
        payload: { data: updatedJob }
      })
      yield put({
        type: JOB_RESCHEDULE_REQUEST_SUCCEEDED,
        payload: {
          job: updatedJob
        },
        snackbar: {
          variant: 'success',
          message: getJobUpdateRequestSagaTextBy(messagePath)
        }
      })
    }
  } catch (e) {
    yield put({
      type: JOB_RESCHEDULE_REQUEST_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* rescheduleRequestSagas() {
  yield all([takeLatest(JOB_RESCHEDULE_REQUEST_REQUESTED, rescheduleRequest)])
}
