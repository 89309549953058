import { formatSystemDate } from '@doinn/shared/src/util/date-fns'
import { cloneDeep, keyBy, merge } from 'lodash'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { fetchJobsApi } from 'api/job'
import { JOB_UPDATE_EXECUTION_SUCCEEDED } from 'containers/job/constants'
import {
  DASHLET_JOB_LIST_FETCH_FAILED,
  DASHLET_JOB_LIST_FETCH_REQUESTED,
  DASHLET_JOB_LIST_FETCH_SUCCEEDED,
  DASHLET_JOB_LIST_UPDATED
} from 'containers/dashboard/dashlets/job-list/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchJobs(action) {
  try {
    const { date, status, ...otherParams } = action.payload.params
    const dateFormatted = formatSystemDate(date)
    const params = {
      date: dateFormatted,
      end_date: dateFormatted,
      ...otherParams
    }

    if (status !== 'all') {
      params.status = status
    }

    const response = yield call(fetchJobsApi, { params })
    const { data, meta } = response.data
    yield put({
      type: DASHLET_JOB_LIST_FETCH_SUCCEEDED,
      payload: {
        data: keyBy(data, job => job.id),
        meta
      }
    })
  } catch (e) {
    yield put({
      type: DASHLET_JOB_LIST_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* updateJobList(action) {
  const job = action.payload.data
  const jobs = yield select(state => cloneDeep(state.dashlets.jobList.data))
  const { status } = yield select(state =>
    cloneDeep(state.dashboard.jobsFilter)
  )

  if (!status || status === 'all' || status === job.status.slug) {
    if (jobs[job.id]) {
      jobs[job.id] = merge(jobs[job.id], job)
    }
  } else {
    delete jobs[job.id]
  }

  yield put({
    type: DASHLET_JOB_LIST_UPDATED,
    payload: {
      data: jobs
    }
  })
}

export default function* dashletJobListSaga() {
  yield all([
    takeLatest(DASHLET_JOB_LIST_FETCH_REQUESTED, fetchJobs),
    takeLatest(JOB_UPDATE_EXECUTION_SUCCEEDED, updateJobList)
  ])
}
