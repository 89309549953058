import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'

import Address from '@doinn/shared/src/components/common/Address'
import CheckboxList from '@doinn/shared/src/components/common/filter/CheckboxList'
import withAuthorization from '@doinn/shared/src/hoc/authorization/withAuthorization'
import AutocompleteMultiple from '@doinn/shared/src/components/common/filter/AutocompleteMultiple'

const PropertyOptionRenderer = option => {
  return (
    <Grid container alignItems='center'>
      <Grid item xs>
        <Typography variant='body1'>
          <b>{`${option.name} (${option.code})`}</b>
        </Typography>
        <Typography variant='body2'>
          <Address
            address={option.address}
            complement={option.complement}
            zipcode={option.zipcode}
            city={{
              name: option.name
            }}
            country={{
              name: option.country
            }}
          />
        </Typography>
      </Grid>
    </Grid>
  )
}

const ListFilters = ({
  isLoading,
  catalogs,
  catalogues,
  vendorBusinessAccounts,
  filters,
  onChange,
  getAutocompleteOptions
}) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <AutocompleteMultiple
        subheader={t('Properties')}
        filterName='spaces'
        selectedItems={filters.spaces}
        optionRenderer={PropertyOptionRenderer}
        onChange={onChange}
        getAutocompleteOptions={getAutocompleteOptions}
      />
      <CheckboxList
        isLoading={isLoading}
        subheader={t('Categories')}
        filterName='catalogs'
        items={catalogs}
        checkedItems={filters.catalogs}
        onChange={onChange}
      />
      <CheckboxList
        isLoading={isLoading}
        subheader={t('Catalogues')}
        filterName='catalogues'
        items={catalogues}
        checkedItems={filters.catalogues}
        onChange={onChange}
      />
      <CheckboxList
        isLoading={isLoading}
        subheader={t('Services providers')}
        filterName='vendorBusinessAccounts'
        items={vendorBusinessAccounts}
        checkedItems={filters.vendorBusinessAccounts}
        onChange={onChange}
      />
    </React.Fragment>
  )
}

export default withAuthorization(ListFilters)
