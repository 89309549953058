import {
  CATALOGUE_CLEAR_SHARED_SELECTED_REQUESTED,
  CATALOGUE_GET_SHARED_FAILED,
  CATALOGUE_GET_SHARED_REQUESTED,
  CATALOGUE_GET_SHARED_SUCCEEDED
} from '@doinn/shared/src/containers/catalogue/shared/get/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  item: null,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case CATALOGUE_GET_SHARED_REQUESTED:
      return {
        ...initialState,
        status: STATUS.LOADING
      }
    case CATALOGUE_GET_SHARED_SUCCEEDED:
      return {
        ...state,
        item: { ...payload.data },
        status: STATUS.SUCCESS
      }
    case CATALOGUE_GET_SHARED_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case CATALOGUE_CLEAR_SHARED_SELECTED_REQUESTED:
      return {
        ...initialState
      }
    default:
      return state
  }
}
