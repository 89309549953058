import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { endOfDay, isAfter } from 'date-fns'
import { Chip, makeStyles } from '@material-ui/core'

const IS_DEVELOPMENT = process.env.NODE_ENV === 'development'

const useStyles = makeStyles(theme => ({
  chip: {
    ...theme.typography.caption,
    backgroundColor: '#f6aa74',
    color: theme.palette.common.white,
    cursor: 'inherit',
    height: theme.spacing(2.5),
    '& > span': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  }
}))

const ChipNew = ({ endDate, className, chipProps }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const isExpired = isAfter(new Date(), endOfDay(new Date(endDate)))
  if (IS_DEVELOPMENT && endDate && isExpired) {
    // console.log(`All <ChipNew endDate='${endDate}' /> can be removed.`)
    if (window.doinnChipNewCanBeRemoved === undefined) {
      window.doinnChipNewCanBeRemoved = []
      console.log(
        "There are <ChipNew /> to be removed. Check the full list on 'window.doinnChipNewCanBeRemoved'"
      )
    }
    const key = `<ChipNew endDate='${endDate}' />`
    if (!window.doinnChipNewCanBeRemoved.includes(key)) {
      window.doinnChipNewCanBeRemoved.push(key)
    }
  }
  if (isExpired) return null
  return (
    <Chip
      label={t('new')}
      className={`${classes.chip} ${className}`}
      {...chipProps}
    />
  )
}

ChipNew.propTypes = {
  endDate: PropTypes.string
}

ChipNew.defaultProps = {}

ChipNew.muiName = 'Chip'

export default ChipNew
