import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { Box, Button, Typography, makeStyles } from '@material-ui/core'

import { logout } from '@doinn/shared/src/containers/user/actions'
import useAppDetector from '@doinn/shared/src/hooks/useAppDetector'

const useStyles = makeStyles(theme => {
  return {
    header: {
      paddingBottom: theme.spacing(2)
    },
    title: {
      fontSize: theme.typography.pxToRem(36)
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(3)
    }
  }
})

const SubscriptionStatusTrialEnded = ({
  loggedUser,
  isBusinessOwner,
  onClick
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const isApp = useAppDetector()

  const ctaProps = useMemo(() => {
    if (!isBusinessOwner) {
      return {}
    }
    return isApp ? { target: '_blank', href: '/plans' } : { onClick: onClick }
  }, [isApp, isBusinessOwner, onClick])

  const handleLogoutClick = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  const renderContent = useCallback(() => {
    const username = loggedUser?.name
    let body = (
      <Typography component='div' variant='body1'>
        <Box sx={{ lineHeight: 'normal' }}>
          {t(
            'To keep using the Doinn’s platform you must talk to the Account Owner'
          )}
        </Box>
      </Typography>
    )

    if (isBusinessOwner) {
      body = (
        <Box pt={2}>
          <Typography component='div' variant='body1'>
            <Box sx={{ lineHeight: 'normal' }}>
              <Trans defaults='Want to continue using our all-in-one property operation platform to increase your productivity? <br/>There are a variety of plans to fit any of your needs' />
            </Box>
          </Typography>
        </Box>
      )
    }

    return (
      <Box>
        <Typography component='div' variant='body1'>
          <Box sx={{ lineHeight: 'normal' }}>
            {t(
              'Hey {{username}}, your trial period with Doinn has been ended.',
              {
                username
              }
            )}
          </Box>
        </Typography>
        {body}
      </Box>
    )
  }, [isBusinessOwner, loggedUser, t])

  return (
    <>
      <Box className={classes.header}>
        <Typography component='div' align='center' className={classes.title}>
          <Box sx={{ lineHeight: 'normal' }}>
            <b>{t('Your 14 days trial period has been ended')}</b>
          </Box>
        </Typography>
      </Box>
      {renderContent()}
      <Box className={classes.actions}>
        <Button
          aria-label={t('Logout')}
          variant='outlined'
          color='primary'
          onClick={handleLogoutClick}
        >
          {t('Logout')}
        </Button>
        {isBusinessOwner && (
          <>
            <Box pl={1} />
            <Button
              aria-label={t('See plans')}
              variant='contained'
              color='primary'
              {...ctaProps}
            >
              {t('See plans')}
            </Button>
          </>
        )}
      </Box>
    </>
  )
}

export default SubscriptionStatusTrialEnded
