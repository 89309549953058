import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { importCatalogItemApi } from '@doinn/host/src/api/catalog-items'
import {
  CATALOG_ITEM_IMPORT_FAILED,
  CATALOG_ITEM_IMPORT_REQUESTED,
  CATALOG_ITEM_IMPORT_SUCCEEDED
} from '@doinn/host/src/containers/catalog/item/import/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* importCatalogItem(action) {
  try {
    const { file } = action.payload.params
    const response = yield call(importCatalogItemApi, {
      file: file
    })
    const responseData = response.data.data
    yield put({
      type: CATALOG_ITEM_IMPORT_SUCCEEDED,
      payload: responseData,
      snackbar: {
        variant: 'success',
        message: i18n.t('Service types have been imported successfully!')
      }
    })
  } catch (e) {
    if (e?.response?.status === 400) {
      yield put({
        type: CATALOG_ITEM_IMPORT_FAILED,
        payload: {
          error: {
            type: 'file',
            data: getSnackbarErrorMessage(e)
          }
        }
      })
    } else if (e?.response?.status === 422) {
      yield put({
        type: CATALOG_ITEM_IMPORT_FAILED,
        payload: {
          error: {
            type: 'dataValidation',
            data: e?.response?.data?.data
          }
        }
      })
    } else {
      yield put({
        type: CATALOG_ITEM_IMPORT_FAILED,
        payload: {
          error: {
            type: 'other'
          }
        },
        snackbar: {
          variant: 'error',
          message: getSnackbarErrorMessage(e)
        }
      })
    }
  }
}

export default function* catalogItemImportSaga() {
  yield all([takeLatest(CATALOG_ITEM_IMPORT_REQUESTED, importCatalogItem)])
}
