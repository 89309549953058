import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function fetchReservationsApi(params = {}) {
  const config = {
    params
  }
  return DoinnApi.get(`/vendor/reservations`, config)
}

export function addReservationApi(reservation) {
  const config = {}
  return DoinnApi.post('/vendor/reservations', { ...reservation }, config)
}

export function getReservationApi(id) {
  const config = {}
  return DoinnApi.get(`/vendor/reservations/${id}`, config)
}

export function editReservationApi({ id, reservation }) {
  const config = {}
  return DoinnApi.put(`/vendor/reservations/${id}`, { ...reservation }, config)
}

export function blockReservationApi({ id, body }) {
  const config = {}
  return DoinnApi.patch(`/vendor/reservations/${id}/block`, { ...body }, config)
}

export function unblockReservationApi(id) {
  const config = {}
  return DoinnApi.patch(`/vendor/reservations/${id}/unblock`, config)
}

export function deleteReservationApi(id) {
  const config = {}
  return DoinnApi.delete(`/vendor/reservations/${id}`, config)
}

export function deleteIntegrationReservationsApi() {
  const config = {}
  return DoinnApi.delete(`/vendor/reservations/integrations`, config)
}

export function syncReservationApi() {
  const config = {}
  return DoinnApi.post('/vendor/reservations/sync', config)
}
