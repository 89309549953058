import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { downloadPriceListApi, fetchPriceListApi } from 'api/price-list'
import {
  PRICE_LIST_DOWNLOAD_FAILED,
  PRICE_LIST_DOWNLOAD_REQUESTED,
  PRICE_LIST_DOWNLOAD_SUCCEEDED,
  PRICE_LIST_FETCH_FAILED,
  PRICE_LIST_FETCH_REQUESTED,
  PRICE_LIST_FETCH_SUCCEEDED
} from 'containers/price-list/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchPriceList(action) {
  try {
    const response = yield call(fetchPriceListApi, action.payload.params)
    yield put({
      type: PRICE_LIST_FETCH_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: PRICE_LIST_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* downloadPriceList(action) {
  try {
    const { contract, spaceCategory } = action.payload
    const spaceCategoryName =
      spaceCategory === 'all' ? spaceCategory : spaceCategory.name
    const reportName = `doinn_pricing_${contract.id}_${contract.city.name}_${spaceCategoryName}.xls`
    const { data } = yield call(downloadPriceListApi, action.payload)

    yield put({
      type: PRICE_LIST_DOWNLOAD_SUCCEEDED,
      snackbar: {
        variant: 'success',
        message: i18n.t('The prices file have been downloaded successfully')
      },
      downloader: {
        data,
        type: 'application/vnd.ms-excel',
        name: reportName
      }
    })
  } catch (e) {
    yield put({
      type: PRICE_LIST_DOWNLOAD_FAILED,
      snackbar: {
        variant: 'error',
        message: e.message
      }
    })
  }
}

export default function* priceListSaga() {
  yield all([
    takeLatest(PRICE_LIST_DOWNLOAD_REQUESTED, downloadPriceList),
    takeLatest(PRICE_LIST_FETCH_REQUESTED, fetchPriceList)
  ])
}
