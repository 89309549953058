export const CUSTOMERS_INVITATIONS_ADD_DIALOG_OPEN =
  'CUSTOMERS_INVITATIONS_ADD_DIALOG_OPEN'
export const CUSTOMERS_INVITATIONS_ADD_DIALOG_CLOSE =
  'CUSTOMERS_INVITATIONS_ADD_DIALOG_CLOSE'

export const CUSTOMERS_INVITATIONS_ADD_REQUESTED =
  'CUSTOMERS_INVITATIONS_ADD_REQUESTED'
export const CUSTOMERS_INVITATIONS_ADD_SUCCEEDED =
  'CUSTOMERS_INVITATIONS_ADD_SUCCEEDED'
export const CUSTOMERS_INVITATIONS_ADD_FAILED =
  'CUSTOMERS_INVITATIONS_ADD_FAILED'
