import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function saveOboardingSetupApi(params = {}, stepper = 'account-setup') {
  const config = {}
  return DoinnApi.put(`/onboarding/${stepper}/survey`, params, config)
}

export function saveOboardingDemoApi(params = {}, stepper = 'account-setup') {
  const config = {}
  return DoinnApi.put(`/onboarding/${stepper}/demo`, params, config)
}

export function finishFloatingOnboardingApi(
  params = {},
  stepper = 'floating-onboarding'
) {
  const config = {}
  return DoinnApi.patch(`/onboarding/${stepper}/finish`, params, config)
}
