import {
  CHECKLIST_PUBLIC_DETAILS_CLOSE_REQUESTED,
  CHECKLIST_PUBLIC_DETAILS_OPEN_REQUESTED
} from '@doinn/host/src/containers/checklist/public/details/constants'

export const initialState = {
  isOpened: false
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case CHECKLIST_PUBLIC_DETAILS_OPEN_REQUESTED:
      return {
        ...state,
        isOpened: true
      }
    case CHECKLIST_PUBLIC_DETAILS_CLOSE_REQUESTED:
      return {
        ...initialState,
        isOpened: false
      }
    default:
      return state
  }
}
