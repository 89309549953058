import React from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'

import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import TemplateDefault from 'components/TemplateDefault'

const IntegrationsEditContainer = loadable(() =>
  lazyRetry(() =>
    import(
      '@doinn/host/src/containers/integrations/edit/configuration/IntegrationsEdit'
    )
  )
)

const IntegrationsEdit = () => {
  const { t } = useTranslation()
  return (
    <TemplateDefault title={t('Integrations')}>
      <Helmet>
        <title>{t('Integrations')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <IntegrationsEditContainer />
      </React.Suspense>
    </TemplateDefault>
  )
}

export default IntegrationsEdit
