import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { deleteIntegrationReservationsApi } from '@doinn/vendor/src/api/reservations'
import {
  RESERVATION_DELETE_ALL_FAILED,
  RESERVATION_DELETE_ALL_REQUESTED,
  RESERVATION_DELETE_ALL_SUCCEEDED
} from '@doinn/vendor/src/containers/reservation/delete-all/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* deleteAllReservation(action) {
  try {
    const response = yield call(deleteIntegrationReservationsApi)
    const { data } = response.data
    if (data.success) {
      yield put({
        type: RESERVATION_DELETE_ALL_SUCCEEDED,
        payload: {},
        snackbar: {
          variant: 'success',
          message: i18n.t('Reservations have been deleted successfully!')
        }
      })
    }
  } catch (e) {
    yield put({
      type: RESERVATION_DELETE_ALL_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* reservationDeleteAllSagas() {
  yield all([
    takeLatest(RESERVATION_DELETE_ALL_REQUESTED, deleteAllReservation)
  ])
}
