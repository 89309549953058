export const CATALOGUES_CATEGORIES_FILTERS_FETCH_REQUESTED =
  'CATALOGUES_CATEGORIES_FILTERS_FETCH_REQUESTED'
export const CATALOGUES_CATEGORIES_FILTERS_FETCH_SUCCEEDED =
  'CATALOGUES_CATEGORIES_FILTERS_FETCH_SUCCEEDED'
export const CATALOGUES_CATEGORIES_FILTERS_FETCH_FAILED =
  'CATALOGUES_CATEGORIES_FILTERS_FETCH_FAILED'

export const CATALOGUES_CUSTOMERS_FILTERS_FETCH_REQUESTED =
  'CATALOGUES_CUSTOMERS_FILTERS_FETCH_REQUESTED'
export const CATALOGUES_CUSTOMERS_FILTERS_FETCH_SUCCEEDED =
  'CATALOGUES_CUSTOMERS_FILTERS_FETCH_SUCCEEDED'
export const CATALOGUES_CUSTOMERS_FILTERS_FETCH_FAILED =
  'CATALOGUES_CUSTOMERS_FILTERS_FETCH_FAILED'

export const CATALOGUES_PROVIDERS_FILTERS_FETCH_REQUESTED =
  'CATALOGUES_PROVIDERS_FILTERS_FETCH_REQUESTED'
export const CATALOGUES_PROVIDERS_FILTERS_FETCH_SUCCEEDED =
  'CATALOGUES_PROVIDERS_FILTERS_FETCH_SUCCEEDED'
export const CATALOGUES_PROVIDERS_FILTERS_FETCH_FAILED =
  'CATALOGUES_PROVIDERS_FILTERS_FETCH_FAILED'

export const CATALOGUES_FILTERS_FETCH_REQUESTED =
  'CATALOGUES_FILTERS_FETCH_REQUESTED'
