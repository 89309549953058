export const ECHO_EVENT_ORDER_SERVICE_EXECUTION_STATUS_UPDATE =
  '.order-service.execution-status.update'
export const ECHO_EVENT_NOTIFICATIONS_COUNTER = '.notifications.counter'
export const ECHO_EVENT_CHAT_ADD = '.chat.add'
export const ECHO_EVENT_CHAT_UPDATE = '.chat.update'
export const ECHO_EVENT_CHAT_MESSAGE_SEND = '.chat-message.send'
export const ECHO_EVENT_CHAT_MESSAGE_ATTACHMENT_SEND =
  '.chat-message-attachment.send'
export const ECHO_EVENT_CHAT_UNREAD_MESSAGES_COUNTER =
  '.chat.unread-messages.counter'
export const ECHO_EVENT_INTEGRATION_SYNC_PROPERTIES_COMPLETED =
  '.integration-sync.spaces.completed'
export const ECHO_EVENT_IMPORT_STATUS_UPDATED = '.import.status.updated'
export const ECHO_EVENT_BUSINESS_CREDITS = '.business.credits'
export const ECHO_EVENT_RESERVATIONS_SYNC_STARTED =
  '.integrations.reservations.sync.started'
export const ECHO_EVENT_RESERVATIONS_SYNC_COMPLETED =
  '.integrations.reservations.sync.completed'
export const ECHO_EVENT_FLOATING_ONBOARDING = '.onboarding.floating-onboarding'
export const ECHO_EVENT_SUBSCRIPTION_UPDATED = '.subscription.updated'
export const ECHO_EVENT_PROPERTIES_SYNC_STARTED =
  '.integrations.properties.sync.started'
export const ECHO_EVENT_PROPERTIES_SYNC_COMPLETED =
  '.integrations.properties.sync.completed'
