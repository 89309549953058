import React from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { bindActionCreators, compose } from 'redux'
import { Drawer, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'

import { useDidMount } from '@doinn/shared/src/hooks'
import { getUserLoggedInAs } from '@doinn/shared/src/containers/user-logged-in-as/actions'
import ChipNew from '@doinn/shared/src/components/common/ChipNew'
import MainMenuContent from '@doinn/shared/src/components/app/MainMenuContent'
import withAuthorization from '@doinn/shared/src/hoc/authorization/withAuthorization'

const useStyles = makeStyles(theme => {
  const drawerWidth = theme.spacing(30)
  const desktopBreakpoint = theme.breakpoints.up('md')

  return {
    drawerPaper: () => {
      const drawerPaperStyles = {}
      drawerPaperStyles[desktopBreakpoint] = {
        width: drawerWidth,
        marginTop: theme.spacing(0)
      }

      return drawerPaperStyles
    },
    drawerPaperBannerOpen: props => {
      const drawerPaperStyles = {}
      drawerPaperStyles[desktopBreakpoint] = {
        width: drawerWidth,
        marginTop: theme.spacing(7)
      }

      return drawerPaperStyles
    },
    drawer: {
      [desktopBreakpoint]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    chipNew: {
      background: theme.palette.primary.main,
      marginLeft: theme.spacing(2)
    }
  }
})

const MainMenu = ({
  can,
  canAny,
  container,
  getUserLoggedInAs,
  userLoggedInAs,
  isMobileMenuOpened,
  isBannerVisible,
  loggedUser,
  onToggleMainMenu
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()

  useDidMount(() => {
    getUserLoggedInAs()
  })

  const isSaasUser = loggedUser?.business?.isSaas

  const items = [
    {
      id: 'dashboard',
      link: '/',
      label: t('Dashboard'),
      icon: 'dashboard',
      isAllowed: true
    },
    {
      id: 'services',
      link: '/services',
      label: t('Services'),
      icon: 'calendar',
      isAllowed: can('read-jobs')
    },
    {
      id: 'reservations',
      link: '/reservations',
      label: t('Reservations'),
      icon: 'reservations',
      isAllowed: can('read-stays') && isSaasUser // TODO: only remove isSaasUser after migrate Laravel
    },
    {
      id: 'properties',
      link: '/properties',
      label: t('Properties'),
      icon: 'properties',
      isAllowed: can('read-spaces')
    },
    {
      id: 'staff',
      link: '/staff',
      label: t('People'),
      icon: 'people',
      isAllowed: can('read-people')
    },
    {
      id: 'checklists',
      link: '/checklists',
      label: t('Checklists'),
      icon: 'checklists',
      isAllowed: can('read-checklists') && isSaasUser
    },
    {
      id: 'insights',
      link: '/insights',
      label: (
        <>
          {t('Insights')}
          <ChipNew className={classes.chipNew} endDate='2022-01-01' />
        </>
      ),
      icon: 'insights',
      hasDivider: true,
      isAllowed:
        can('read-reports') &&
        canAny(['read-operational-info', 'read-financial-info'])
    },
    {
      id: 'invoices',
      link: '/invoices',
      label: t('Invoices'),
      icon: 'invoices',
      isAllowed: canAny(['read-sent-invoices', 'read-received-invoices'])
    },
    {
      id: 'reports',
      link: '/reports',
      label: t('Reports'),
      icon: 'text_box',
      isAllowed: can('read-reports') && can('read-financial-info')
    },
    {
      id: 'messages',
      link: '/messages',
      label: t('Messages'),
      icon: 'message_text',
      hasDivider: true,
      isAllowed: can('read-chats')
    },
    {
      id: 'integrations',
      link: '/integrations',
      label: t('Integrations'),
      icon: 'integrations',
      hasDivider: true,
      isAllowed: can('read-integrations') && isSaasUser // TODO: only remove isSaasUser after migrate Laravel
    },
    {
      id: 'automations',
      link: '/automations',
      label: t('Automations'),
      icon: 'automations',
      isAllowed: can('read-automations') && isSaasUser // TODO: only remove isSaasUser after migrate Laravel
    },
    {
      id: 'catalogues',
      link: '/catalogues',
      label: t('Catalogues'),
      icon: 'catalogs',
      isAllowed: can('read-catalogues') && isSaasUser // TODO: only remove isSaasUser after migrate contract to catalogues
    }
  ]

  const isMobile = !useMediaQuery(theme.breakpoints.up('md'))

  const handleToggleMobileDrawer = () => {
    onToggleMainMenu && onToggleMainMenu()
  }

  let drawerProps = {
    classes: {
      paper: clsx(classes.drawerPaper, {
        [classes.drawerPaperBannerOpen]: isBannerVisible
      })
    },
    variant: 'permanent',
    open: true
  }

  const drawerPropsMobile = {
    container: container,
    variant: 'temporary',
    anchor: theme.direction === 'rtl' ? 'right' : 'left',
    open: isMobileMenuOpened,
    onClose: handleToggleMobileDrawer,
    ModalProps: {
      // Improve open performance on mobile
      keepMounted: true
    }
  }

  if (isMobile) {
    drawerProps = {
      ...drawerProps,
      ...drawerPropsMobile
    }
  }

  return (
    <nav className={classes.drawer} aria-label='main menu'>
      <Drawer {...drawerProps}>
        <MainMenuContent items={items} />
      </Drawer>
    </nav>
  )
}

const mapStateToProps = state => ({
  userLoggedInAs: state.userLoggedInAs.data,
  loggedUser: state.user.loggedUser
})

const mapDispatchToProps = dispatch => ({
  getUserLoggedInAs: bindActionCreators(getUserLoggedInAs, dispatch)
})

export default compose(
  withAuthorization,
  connect(mapStateToProps, mapDispatchToProps)
)(MainMenu)
