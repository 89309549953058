import {
  JOB_RESCHEDULE_DIALOG_CLOSE,
  JOB_RESCHEDULE_DIALOG_OPEN,
  JOB_RESCHEDULE_REQUEST_FAILED,
  JOB_RESCHEDULE_REQUEST_REQUESTED,
  JOB_RESCHEDULE_REQUEST_SUCCEEDED
} from '@doinn/vendor/src/containers/job/reschedule/constants'
import { JOB_UPDATE_REQUESTED_START_SUCCEEDED } from '@doinn/vendor/src/containers/job/constants'

export const STATUS = {
  IDLE: 'idle',
  SAVING: 'saving',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  isOpened: false,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case JOB_RESCHEDULE_DIALOG_OPEN:
      return {
        ...initialState,
        isOpened: true
      }
    case JOB_RESCHEDULE_DIALOG_CLOSE:
      return {
        ...state,
        isOpened: false
      }
    case JOB_RESCHEDULE_REQUEST_REQUESTED:
      return {
        ...state,
        status: STATUS.SAVING
      }
    case JOB_RESCHEDULE_REQUEST_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case JOB_RESCHEDULE_REQUEST_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case JOB_UPDATE_REQUESTED_START_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS,
        isOpened: false
      }
    default:
      return state
  }
}
