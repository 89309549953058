import { all, call, put, throttle } from 'redux-saga/effects'
import { fetchCalendarApi } from '@doinn/vendor/src/api/calendar'
import {
  CALENDAR_FETCH_FAILED,
  CALENDAR_FETCH_REQUESTED,
  CALENDAR_FETCH_SUCCEEDED
} from '@doinn/vendor/src/containers/dashboard/dashlets/calendar/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchCalendar(action) {
  try {
    const { from, to } = action.payload.params
    const response = yield call(fetchCalendarApi, from, to)
    yield put({
      type: CALENDAR_FETCH_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: CALENDAR_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* calendarSaga() {
  yield all([throttle(1000, CALENDAR_FETCH_REQUESTED, fetchCalendar)])
}
