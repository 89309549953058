import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  aproveJobUpdateRequestApi,
  getJobApi,
  refuseJobUpdateRequestApi
} from '@doinn/vendor/src/api/job'
import {
  UPDATE_REQUEST_APPROVE_FAILED,
  UPDATE_REQUEST_APPROVE_REQUESTED,
  UPDATE_REQUEST_APPROVE_SUCCEEDED,
  UPDATE_REQUEST_REFUSE_FAILED,
  UPDATE_REQUEST_REFUSE_REQUESTED,
  UPDATE_REQUEST_REFUSE_SUCCEEDED
} from '@doinn/vendor/src/containers/job/update-request/constants'
import { JOB_GET_SUCCEEDED } from '@doinn/vendor/src/containers/job/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import { getJobUpdateRequestSagaTextBy } from '@doinn/vendor/src/containers/job/update-request/util'

function* approveRequest(action) {
  try {
    const { jobId, type, requestId } = action.payload.params
    const response = yield call(aproveJobUpdateRequestApi, jobId, requestId)
    const { data } = response.data

    if (data) {
      const responseUpdatedJob = yield call(getJobApi, jobId)
      const { data: updatedJob } = responseUpdatedJob.data

      yield put({
        type: JOB_GET_SUCCEEDED,
        payload: { data: updatedJob }
      })
      yield put({
        type: UPDATE_REQUEST_APPROVE_SUCCEEDED,
        payload: { data },
        snackbar: {
          variant: 'success',
          message: getJobUpdateRequestSagaTextBy(`${type}.approve.success`)
        }
      })
    }
  } catch (e) {
    yield put({
      type: UPDATE_REQUEST_APPROVE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* refuseRequest(action) {
  try {
    const { jobId, type, requestId, ...body } = action.payload.params
    const response = yield call(
      refuseJobUpdateRequestApi,
      jobId,
      requestId,
      body
    )
    const { data } = response.data

    if (data) {
      const responseUpdatedJob = yield call(getJobApi, jobId)
      const { data: updatedJob } = responseUpdatedJob.data

      yield put({
        type: JOB_GET_SUCCEEDED,
        payload: { data: updatedJob }
      })
      yield put({
        type: UPDATE_REQUEST_REFUSE_SUCCEEDED,
        payload: { data },
        snackbar: {
          variant: 'success',
          message: getJobUpdateRequestSagaTextBy(`${type}.refuse.success`)
        }
      })
    }
  } catch (e) {
    yield put({
      type: UPDATE_REQUEST_REFUSE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* updateRequestSagas() {
  yield all([
    takeLatest(UPDATE_REQUEST_APPROVE_REQUESTED, approveRequest),
    takeLatest(UPDATE_REQUEST_REFUSE_REQUESTED, refuseRequest)
  ])
}
