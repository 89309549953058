import {
  USER_LOGGED_IN_AS_FAILED,
  USER_LOGGED_IN_AS_SUCCEEDED
} from '@doinn/shared/src/containers/user-logged-in-as/constants'

export const initialState = {
  data: {}
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case USER_LOGGED_IN_AS_SUCCEEDED:
      return {
        ...state,
        data: { ...payload.userLoggedInAs }
      }
    case USER_LOGGED_IN_AS_FAILED:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}
