import {
  SUBSCRIPTION_INVALID_DIALOG_CLOSE,
  SUBSCRIPTION_INVALID_DIALOG_OPEN
} from '@doinn/shared/src/containers/subscription/invalid/constants'
import { USER_GET_SUCCEEDED } from '@doinn/shared/src/containers/user/constants'
import { CHECKOUT_SUBSCRIPTION_SUCCEEDED } from '@doinn/vendor/src/containers/checkout/constants'

export const ERROR_SLUG = {
  invalid_subscription: 'invalid_subscription',
  trial_ended: 'trial_ended',
  feature_blacklisted: 'feature_blacklisted'
}

export const initialState = {
  isOpened: false,
  errorSlug: null
}

const getErrorCode = status => {
  switch (status) {
    case 'invalid':
      return ERROR_SLUG.invalid_subscription
    case ERROR_SLUG.feature_blacklisted:
      return ERROR_SLUG.feature_blacklisted
    case ERROR_SLUG.invalid_subscription:
      return ERROR_SLUG.invalid_subscription
    case ERROR_SLUG.trial_ended:
      return ERROR_SLUG.trial_ended
    default:
      return null
  }
}

const getSubscriptionStatus = ({ subscription = null }) => {
  if (subscription?.valid) {
    return {
      isOpened: !subscription?.valid,
      errorSlug: getErrorCode(subscription?.status)
    }
  }
  return { isOpened: false, errorSlug: null }
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case USER_GET_SUCCEEDED:
      return {
        ...initialState,
        ...getSubscriptionStatus(payload?.data?.business)
      }
    case SUBSCRIPTION_INVALID_DIALOG_OPEN:
      return {
        ...initialState,
        isOpened: true,
        errorSlug: getErrorCode(payload?.params?.message)
      }
    case SUBSCRIPTION_INVALID_DIALOG_CLOSE:
      return {
        ...initialState,
        isOpened: false,
        errorSlug: state.errorSlug
      }
    case CHECKOUT_SUBSCRIPTION_SUCCEEDED:
      return {
        ...initialState
      }
    default:
      return state
  }
}
