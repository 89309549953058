import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Button, Typography, makeStyles } from '@material-ui/core'
import { Trans, useTranslation } from 'react-i18next'
import { differenceInDays, parseISO, startOfToday } from 'date-fns'

const useStyles = makeStyles(theme => ({
  button: {
    border: 'none',
    marginLeft: theme.spacing(1),
    fontWeight: 600,
    '&:hover': {
      border: 'none'
    }
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: theme.spacing(1, 2),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontWeight: 600
  }
}))

const Trial = ({ onClick }) => {
  const { t } = useTranslation()
  const { loggedUser } = useSelector(state => state.user)
  const classes = useStyles()
  const today = startOfToday()
  const subscription = loggedUser?.business?.subscription ?? {}

  let daysLeft = 0
  daysLeft = differenceInDays(parseISO(subscription?.trialEnd), today)
  daysLeft = daysLeft > 0 ? daysLeft : 0

  const handleCtaPlansClick = useCallback(() => {
    onClick && onClick()
  }, [onClick])

  return (
    <Typography className={classes.content} component='div'>
      <div>
        {`${t('Thank you for choosing Doinn platform!')} `}
        <Trans
          defaults='You have <bold>{{days}} day</bold> left on your software free trial.'
          components={{
            bold: <b />
          }}
          values={{
            days: daysLeft,
            count: daysLeft
          }}
        />
      </div>
      {loggedUser?.roles?.includes('business_owner') && (
        <Button
          aria-label={t('See plans')}
          color='secondary'
          size='small'
          variant='outlined'
          onClick={handleCtaPlansClick}
          className={classes.button}
        >
          {t('See plans')}
        </Button>
      )}
    </Typography>
  )
}

export default Trial
