import React from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'
import { Box, makeStyles } from '@material-ui/core'
import { isValid, parseISO, startOfToday } from 'date-fns'

import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import { getQueryStringObject } from '@doinn/shared/src/util/url'
import TemplateDefault from 'components/TemplateDefault'
import HelperCard from '@doinn/shared/src/components/common/helper/HelperCard'

const DashboardContainer = loadable(() =>
  lazyRetry(() => import('containers/dashboard/Dashboard'))
)

const useStyles = makeStyles(theme => ({
  container: {
    overflowX: 'hidden'
  }
}))

const Dashboard = ({ location }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const queryFilters = getQueryStringObject(location.search)

  // Default filters
  const filters = {
    date: startOfToday(),
    status: 'all',
    page: 1
  }

  for (const key in queryFilters) {
    if (queryFilters[key] && filters[key]) {
      const value = queryFilters[key]
      if (key === 'date') {
        const parsedDate = parseISO(value)
        if (isValid(parsedDate)) {
          filters[key] = parsedDate
        }
      } else {
        if (Array.isArray(value)) {
          filters[key].push(...value)
        } else {
          filters[key] = value
        }
      }
    }
  }

  return (
    <TemplateDefault
      containerClassName={classes.container}
      helperContent={<HelperCard />}
    >
      <Helmet>
        <title>{t('Dashboard')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <Box pb={13.25}>
          <DashboardContainer filters={filters} />
        </Box>
      </React.Suspense>
    </TemplateDefault>
  )
}

export default Dashboard
