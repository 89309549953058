import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  DASHLET_REPORTS_OVERVIEW_FAILED,
  DASHLET_REPORTS_OVERVIEW_REQUESTED,
  DASHLET_REPORTS_OVERVIEW_SUCCEEDED
} from 'containers/dashboard/dashlets/reports/constants'
import { fetchReportsOverviewApi } from 'api/reports'

function* fetchReportsOverview(action) {
  try {
    const response = yield call(fetchReportsOverviewApi)

    yield put({
      type: DASHLET_REPORTS_OVERVIEW_SUCCEEDED,
      payload: { data: response.data.data }
    })
  } catch (e) {
    yield put({
      type: DASHLET_REPORTS_OVERVIEW_FAILED
    })
  }
}

export default function* dashletSummaryServicesSaga() {
  yield all([
    takeLatest(DASHLET_REPORTS_OVERVIEW_REQUESTED, fetchReportsOverview)
  ])
}
