import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchCatalogItemsApi } from '@doinn/host/src/api/catalog-items'
import {
  CATALOG_ITEM_LIST_FETCH_FAILED,
  CATALOG_ITEM_LIST_FETCH_MORE_FAILED,
  CATALOG_ITEM_LIST_FETCH_MORE_REQUESTED,
  CATALOG_ITEM_LIST_FETCH_MORE_SUCCEEDED,
  CATALOG_ITEM_LIST_FETCH_REQUESTED,
  CATALOG_ITEM_LIST_FETCH_SUCCEEDED
} from '@doinn/host/src/containers/catalog/item/list/constants'
import { CATALOG_ITEM_ADD_SUCCEEDED } from '@doinn/host/src/containers/catalog/item/add/constants'
import { CATALOG_ITEM_EDIT_SUCCEEDED } from '@doinn/host/src/containers/catalog/item/edit/constants'
import { CATALOG_ITEM_DELETE_SUCCEEDED } from '@doinn/host/src/containers/catalog/item/delete/constants'
import { fetchCatalogItems as fetchCatalogItemsAction } from '@doinn/host/src/containers/catalog/item/list/actions'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* resetCatalogItems() {
  yield put(fetchCatalogItemsAction())
}

function* fetchCatalogItem(action) {
  try {
    const response = yield call(fetchCatalogItemsApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: CATALOG_ITEM_LIST_FETCH_SUCCEEDED,
      payload: {
        data,
        meta
      }
    })
  } catch (e) {
    yield put({
      type: CATALOG_ITEM_LIST_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* fetchMoreCatalogItem(action) {
  try {
    const response = yield call(fetchCatalogItemsApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: CATALOG_ITEM_LIST_FETCH_MORE_SUCCEEDED,
      payload: { data, meta }
    })
  } catch (e) {
    yield put({
      type: CATALOG_ITEM_LIST_FETCH_MORE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* catalogItemSaga() {
  yield all([
    takeLatest(CATALOG_ITEM_ADD_SUCCEEDED, resetCatalogItems),
    takeLatest(CATALOG_ITEM_EDIT_SUCCEEDED, resetCatalogItems),
    takeLatest(CATALOG_ITEM_DELETE_SUCCEEDED, resetCatalogItems),
    takeLatest(CATALOG_ITEM_LIST_FETCH_REQUESTED, fetchCatalogItem),
    takeLatest(CATALOG_ITEM_LIST_FETCH_MORE_REQUESTED, fetchMoreCatalogItem)
  ])
}
