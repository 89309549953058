import React from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'

import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import { getQueryStringObject } from '@doinn/shared/src/util/url'
import { parseAppliedFilters } from '@doinn/shared/src/util/filters'
import TemplateDefault from 'components/TemplateDefault'
import HelperCard from '@doinn/shared/src/components/common/helper/HelperCard'

const MessageListContainer = loadable(() =>
  lazyRetry(() => import('@doinn/shared/src/containers/chats/List'))
)

const defaultFilters = {
  page: 1
}

const Chats = ({ location }) => {
  const { t } = useTranslation()

  const queryFilters = getQueryStringObject(location.search)

  const filters = parseAppliedFilters(defaultFilters, queryFilters)

  return (
    <TemplateDefault title={t('Messages')} helperContent={<HelperCard />}>
      <Helmet>
        <title>{t('Messages')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <MessageListContainer filters={filters} />
      </React.Suspense>
    </TemplateDefault>
  )
}

export default Chats
