import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getPropertyApi } from '@doinn/shared/src/api/property'
import {
  PROPERTY_GET_FAILED,
  PROPERTY_GET_REQUESTED,
  PROPERTY_GET_SUCCEEDED
} from '@doinn/shared/src/containers/property/get/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* getProperty(action) {
  try {
    const response = yield call(getPropertyApi, action.payload.params)
    yield put({
      type: PROPERTY_GET_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: PROPERTY_GET_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* propertyGetSaga() {
  yield all([takeLatest(PROPERTY_GET_REQUESTED, getProperty)])
}
