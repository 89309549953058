import {
  ONBOARDING_FLOATING_DIALOG_CLOSE,
  ONBOARDING_FLOATING_DIALOG_FINISH_REQUESTED,
  ONBOARDING_FLOATING_DIALOG_OPEN,
  ONBOARDING_FLOATING_DIALOG_SYNC
} from '@doinn/shared/src/containers/onboarding/floating/constants'

export function open(params = {}) {
  return {
    type: ONBOARDING_FLOATING_DIALOG_OPEN,
    payload: { params }
  }
}

export function close(params = {}) {
  return {
    type: ONBOARDING_FLOATING_DIALOG_CLOSE,
    payload: { params }
  }
}

export function finish(params = {}) {
  return {
    type: ONBOARDING_FLOATING_DIALOG_FINISH_REQUESTED,
    payload: { params }
  }
}

export function sync(params = {}) {
  return {
    type: ONBOARDING_FLOATING_DIALOG_SYNC,
    payload: { params }
  }
}
