import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import loadable from '@loadable/component'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import Icon from '@doinn/shared/src/components/common/Icon'
import TemplateAuth from '@doinn/shared/src/components/templates/TemplateAuth'

const RecoverPasswordContainer = loadable(() =>
  lazyRetry(() => import('@doinn/shared/src/containers/auth/RecoverPassword'))
)

const RecoverPassword = () => {
  const { t } = useTranslation()
  const { isRecoverPasswordSucceed } = useSelector(state => state.auth)

  return (
    <TemplateAuth
      headerLeftChildren={
        isRecoverPasswordSucceed ? null : (
          <Button
            component={RouterLink}
            color='secondary'
            variant='outlined'
            to='/login'
            startIcon={<Icon icon='back' />}
          >
            {t('Return to Sign in')}
          </Button>
        )
      }
    >
      <Helmet>
        <title>{t('Recover Password')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <RecoverPasswordContainer />
      </React.Suspense>
    </TemplateAuth>
  )
}

export default RecoverPassword
