import { all, call, put, takeLatest } from 'redux-saga/effects'
import { deleteCatalogItemApi } from '@doinn/host/src/api/catalog-items'
import {
  CATALOG_ITEM_DELETE_FAILED,
  CATALOG_ITEM_DELETE_REQUESTED,
  CATALOG_ITEM_DELETE_SUCCEEDED
} from '@doinn/host/src/containers/catalog/item/delete/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* deleteCatalogItem(action) {
  try {
    const { catalogItemId } = action.payload
    yield call(deleteCatalogItemApi, catalogItemId)
    yield put({
      type: CATALOG_ITEM_DELETE_SUCCEEDED,
      payload: action.payload
    })
  } catch (e) {
    yield put({
      type: CATALOG_ITEM_DELETE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* catalogItemDeleteSaga() {
  yield all([takeLatest(CATALOG_ITEM_DELETE_REQUESTED, deleteCatalogItem)])
}
