import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchTeamsApi } from '@doinn/vendor/src/api/teams'
import {
  TEAMS_FETCH_FAILED,
  TEAMS_FETCH_REQUESTED,
  TEAMS_FETCH_SUCCEEDED
} from '@doinn/vendor/src/containers/staff/teams/constants'
import { TEAM_ADD_EDIT_FORM_SAVE_SUCCEEDED } from '@doinn/vendor/src/containers/team/add-edit/constants'
import { TEAMS_DELETE_SUCCEEDED } from '@doinn/vendor/src/containers/staff/teams/delete/constants'
import { fetchTeams as fetchTeamsAction } from '@doinn/vendor/src/containers/staff/teams/actions'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* resetTeams() {
  yield put(fetchTeamsAction())
}

function* fetchTeams() {
  try {
    const response = yield call(fetchTeamsApi)
    yield put({
      type: TEAMS_FETCH_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: TEAMS_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* teamsSaga() {
  yield all([
    takeLatest(TEAMS_FETCH_REQUESTED, fetchTeams),
    takeLatest(TEAM_ADD_EDIT_FORM_SAVE_SUCCEEDED, resetTeams),
    takeLatest(TEAMS_DELETE_SUCCEEDED, resetTeams)
  ])
}
