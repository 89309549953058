export const ONBOARDING_FLOATING_DIALOG_OPEN = 'ONBOARDING_FLOATING_DIALOG_OPEN'
export const ONBOARDING_FLOATING_DIALOG_CLOSE =
  'ONBOARDING_FLOATING_DIALOG_CLOSE'

export const ONBOARDING_FLOATING_DIALOG_FINISH_REQUESTED =
  'ONBOARDING_FLOATING_DIALOG_FINISH_REQUESTED'
export const ONBOARDING_FLOATING_DIALOG_FINISH_SUCCEEDED =
  'ONBOARDING_FLOATING_DIALOG_FINISH_SUCCEEDED'
export const ONBOARDING_FLOATING_DIALOG_FINISH_FAILED =
  'ONBOARDING_FLOATING_DIALOG_FINISH_FAILED'

export const ONBOARDING_FLOATING_DIALOG_SYNC = 'ONBOARDING_FLOATING_DIALOG_SYNC'
