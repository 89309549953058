import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { addOrRemove } from '@doinn/shared/src/util/array'
import { STATUS } from '@doinn/shared/src/containers/catalogue/filters/reducers'
import withAuthorization from '@doinn/shared/src/hoc/authorization/withAuthorization'
import ListFilters from '@doinn/shared/src/containers/catalogue/components/ListFilters'
import { fetchCataloguesFilters } from '@doinn/shared/src/containers/catalogue/filters/actions'

const ListFiltersContent = ({
  filters,
  tab,
  hiddenFilters,
  disabledFilters,
  onChangeFilters
}) => {
  const dispatch = useDispatch()
  const { categories, customers, providers } = useSelector(
    state => state.cataloguesFilters
  )

  useEffect(() => {
    const params = {
      skip: hiddenFilters
    }

    // If the tab is not defined, we check if has a fixed filter
    if (!!disabledFilters?.customers && hiddenFilters?.includes('customers')) {
      params.type = 'my'
    } else if (
      !!disabledFilters?.providers &&
      hiddenFilters?.includes('providers')
    ) {
      params.type = 'shared'
    }

    switch (tab) {
      case 'my':
        params.type = 'my'
        break
      case 'shared':
        params.type = 'shared'
        break
      default:
        break
    }

    dispatch(fetchCataloguesFilters({ params }))
  }, [disabledFilters, dispatch, hiddenFilters, tab])

  const handleChangeFilter = useCallback(
    (filterName, newValue) => {
      const oldValue = filters[filterName]
      const newValueAsString =
        typeof newValue === 'object' &&
        !Array.isArray(newValue) &&
        newValue !== null
          ? String(newValue.id)
          : newValue
      let parsedNewValue = ''

      if (['customers', 'providers'].includes(filterName)) {
        parsedNewValue = oldValue.includes(newValueAsString)
          ? []
          : [newValueAsString]
      } else {
        parsedNewValue = Array.isArray(oldValue)
          ? addOrRemove(oldValue, newValueAsString)
          : newValueAsString
      }

      const params = {
        ...filters,
        page: 1
      }
      params[filterName] = parsedNewValue
      onChangeFilters && onChangeFilters(params)
    },
    [filters, onChangeFilters]
  )

  const filtersProps = {
    hiddenFilters,
    categories: {
      ...categories,
      isLoading: categories.status === STATUS.LOADING
    },
    customers: {
      ...customers,
      isLoading: customers.status === STATUS.LOADING
    },
    providers: {
      ...providers,
      isLoading: providers.status === STATUS.LOADING
    },
    filters,
    onChange: handleChangeFilter,
    onChangeFilters: onChangeFilters
  }

  return <ListFilters {...filtersProps} />
}

ListFiltersContent.propTypes = {
  disabledFilters: PropTypes.object,
  filters: PropTypes.object.isRequired,
  tab: PropTypes.string
}

ListFiltersContent.defaultProps = {
  disabledFilters: {},
  tab: ''
}

export default withAuthorization(ListFiltersContent)
