export const ONBOARDING_SAAS_ANNOUNCEMENT_DIALOG_OPEN =
  'ONBOARDING_SAAS_ANNOUNCEMENT_DIALOG_OPEN'
export const ONBOARDING_SAAS_ANNOUNCEMENT_DIALOG_CLOSE =
  'ONBOARDING_SAAS_ANNOUNCEMENT_DIALOG_CLOSE'

export const ONBOARDING_SAAS_ANNOUNCEMENT_REQUESTED =
  'ONBOARDING_SAAS_ANNOUNCEMENT_REQUESTED'
export const ONBOARDING_SAAS_ANNOUNCEMENT_SUCCEEDED =
  'ONBOARDING_SAAS_ANNOUNCEMENT_SUCCEEDED'
export const ONBOARDING_SAAS_ANNOUNCEMENT_FAILED =
  'ONBOARDING_SAAS_ANNOUNCEMENT_FAILED'
