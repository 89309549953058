import { all, call, put, takeLatest } from 'redux-saga/effects'
import { addIntegrationApi } from '@doinn/host/src/api/integrations'
import {
  INTEGRATION_ADD_FAILED,
  INTEGRATION_ADD_REQUESTED,
  INTEGRATION_ADD_SUCCEEDED
} from '@doinn/host/src/containers/integrations/add/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* addIntegration(action) {
  try {
    const response = yield call(addIntegrationApi, action.payload)
    yield put({
      type: INTEGRATION_ADD_SUCCEEDED,
      payload: response.data.data
    })
  } catch (e) {
    yield put({
      type: INTEGRATION_ADD_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* integrationsAddSaga() {
  yield all([takeLatest(INTEGRATION_ADD_REQUESTED, addIntegration)])
}
