import {
  PAYPAL_PAYMENT_SETUP_CLOSE,
  PAYPAL_PAYMENT_SETUP_ERROR,
  PAYPAL_PAYMENT_SETUP_FAILED,
  PAYPAL_PAYMENT_SETUP_OPEN,
  PAYPAL_PAYMENT_SETUP_SAVING_FAILED,
  PAYPAL_PAYMENT_SETUP_SAVING_REQUESTED,
  PAYPAL_PAYMENT_SETUP_SAVING_SUCCEEDED,
  PAYPAL_PAYMENT_SETUP_SUCCEEDED,
  PAYPAL_PAYMENT_SETUP_UPDATING_REQUESTED
} from '@doinn/shared/src/containers/gateways/paypal/setup/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SAVING: 'saving',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  status: STATUS.IDLE,
  isOpened: false,
  clientSecret: null,
  id: null,
  context: null,
  gatewaysContext: null
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case PAYPAL_PAYMENT_SETUP_OPEN:
      return {
        ...initialState,
        isOpened: true,
        status: STATUS.LOADING,
        id: payload?.params?.id,
        context: payload?.params?.context,
        gatewaysContext: payload?.params?.gatewaysContext
      }
    case PAYPAL_PAYMENT_SETUP_CLOSE:
      return {
        ...initialState,
        isOpened: false,
        id: null
      }
    case PAYPAL_PAYMENT_SETUP_SUCCEEDED:
      return {
        ...state,
        status: STATUS.IDLE,
        clientSecret: payload.data?.clientSecret
      }
    case PAYPAL_PAYMENT_SETUP_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case PAYPAL_PAYMENT_SETUP_ERROR:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case PAYPAL_PAYMENT_SETUP_SAVING_REQUESTED:
      return {
        ...state,
        status: STATUS.SAVING
      }
    case PAYPAL_PAYMENT_SETUP_UPDATING_REQUESTED:
      return {
        ...state,
        status: STATUS.SAVING
      }
    case PAYPAL_PAYMENT_SETUP_SAVING_SUCCEEDED:
      return {
        ...initialState,
        status: STATUS.SUCCESS
      }
    case PAYPAL_PAYMENT_SETUP_SAVING_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
