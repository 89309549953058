import React from 'react'
import { useTranslation } from 'react-i18next'

import RadioList from '@doinn/shared/src/components/common/filter/RadioList'
import CheckboxList from '@doinn/shared/src/components/common/filter/CheckboxList'
import withAuthorization from '@doinn/shared/src/hoc/authorization/withAuthorization'
import { isEmpty } from 'lodash'

const ListFilters = ({
  hiddenFilters,
  categories,
  customers,
  providers,
  filters,
  onChange
}) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <CheckboxList
        isLoading={categories?.isLoading}
        subheader={t('Categories')}
        filterName='categories'
        items={categories?.data}
        checkedItems={filters.categories}
        onChange={onChange}
      />
      {'providers' in filters && !hiddenFilters?.includes('providers') && (
        <RadioList
          isLoading={providers?.isLoading}
          subheader={t('Providers')}
          filterName='providers'
          items={providers?.data}
          checkedItems={
            isEmpty(filters?.providers) ? '' : filters?.providers[0]
          }
          onChange={onChange}
        />
      )}
      {'customers' in filters && !hiddenFilters?.includes('customers') && (
        <RadioList
          isLoading={customers?.isLoading}
          subheader={t('Customers')}
          filterName='customers'
          items={customers?.data}
          checkedItems={
            isEmpty(filters?.customers) ? '' : filters?.customers[0]
          }
          onChange={onChange}
        />
      )}
    </React.Fragment>
  )
}

export default withAuthorization(ListFilters)
