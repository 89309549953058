import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, InputAdornment, makeStyles } from '@material-ui/core'

import Icon from '@doinn/shared/src/components/common/Icon'
import TextField from '@doinn/shared/src/components/common/inputs/TextField'

const useStyles = makeStyles(theme => ({
  input: {
    marginTop: 0,
    marginBottom: 0
  }
}))

const PasswordTextField = props => {
  const { fieldName, isDisabled, isLoading, ...otherProps } = props
  const classes = useStyles()
  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => setShowPassword(show => !show)
  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  return (
    <TextField
      disabled={isDisabled}
      name={fieldName}
      hiddenLabel
      fullWidth
      className={classes.input}
      margin='none'
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge='end'
            >
              <Icon icon={showPassword ? 'eye_off' : 'eye'} />
            </IconButton>
          </InputAdornment>
        )
      }}
      {...otherProps}
    />
  )
}

PasswordTextField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool
}

PasswordTextField.defaultProps = {
  isDisabled: false,
  isLoading: false
}

export default PasswordTextField
