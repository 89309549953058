import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { find } from 'lodash'
import Cookies from 'js-cookie'
import { useSelector } from 'react-redux'
import { SESSION_DOMAIN } from 'config/env'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { fade } from '@material-ui/core/styles'

import Icon from '@doinn/shared/src/components/common/Icon'
import FlexSpacer from '@doinn/shared/src/components/common/FlexSpacer'
import { getHelperLink } from '@doinn/shared/src/components/common/helper/util'

export const HELP_CENTER_COOKIE_NAME = 'help-center'
export const HELP_CENTER_TRANSLATION_KEY = 'help_center'

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: theme.spacing(2)
  },
  button: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  icon: {
    verticalAlign: 'text-bottom',
    marginRight: theme.spacing(1)
  },
  card: {
    background: fade(theme.palette.primary.main, 0.05)
  },
  descriptionWrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}))

const HelperCard = ({ title, description, link, general }) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const [show, setShow] = useState(false)
  const { loggedUser } = useSelector(state => state.user)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))

  useEffect(() => {
    const currentCookieHelper = Cookies.getJSON(HELP_CENTER_COOKIE_NAME) || {}
    if (!find([currentCookieHelper], location.pathname)) {
      setShow(true)
    }
  }, [location])

  const handleClose = useCallback(() => {
    const currentCookieHelper = Cookies.getJSON(HELP_CENTER_COOKIE_NAME) || {}
    Cookies.set(
      HELP_CENTER_COOKIE_NAME,
      { ...currentCookieHelper, ...{ [location.pathname]: true } },
      {
        domain: SESSION_DOMAIN,
        expires: 90
      }
    )
    setShow(false)
  }, [location])

  const getTranslationKey = useCallback(
    key => {
      const pathname =
        location.pathname.split('/').slice(1, 2).shift() || 'dashboard'
      const translation = [HELP_CENTER_TRANSLATION_KEY, key, pathname]

      if (!general) {
        if (loggedUser?.roles?.includes('host')) {
          translation.push('host')
        }

        if (loggedUser?.roles?.includes('vendor')) {
          translation.push('vendor')
        }
      }

      const translationKey = translation.join('_')
      // i18next-extract-disable-next-line
      return t(translationKey)
    },
    [location, loggedUser, general, t]
  )

  const helperLink = useCallback(() => {
    const pathname =
      location.pathname.split('/').slice(1, 2).shift() || 'dashboard'
    return getHelperLink(pathname, i18n.language)
  }, [location, i18n])

  return (
    show &&
    !isMobile && (
      <Card elevation={0} className={classes.card}>
        <CardContent className={classes.content}>
          <Box display='flex' alignItems='center'>
            <Typography component='div' variant='subtitle1'>
              <b>{title || getTranslationKey('title')}</b>
            </Typography>
            <FlexSpacer />
            <IconButton
              className={classes.close}
              edge='start'
              color='inherit'
              onClick={handleClose}
              aria-label={t('Close')}
            >
              <Icon icon='close' fontSize='small' />
            </IconButton>
          </Box>
          <Box className={classes.descriptionWrapper}>
            <Typography component='div' variant='body2'>
              {description || getTranslationKey('description')}
            </Typography>
          </Box>
          {helperLink() && (
            <Button
              className={classes.button}
              variant='outlined'
              size='small'
              href={helperLink()}
              target='_blank'
              startIcon={
                <Icon
                  icon='open_in_new'
                  fontSize='small'
                  className={classes.icon}
                />
              }
            >
              {t('Go to Help Center')}
            </Button>
          )}
        </CardContent>
      </Card>
    )
  )
}

HelperCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  general: PropTypes.bool
}

HelperCard.defaultProps = {
  title: '',
  description: '',
  link: '',
  general: true
}

export default HelperCard
