import { cloneDeep } from 'lodash'

/**
 * @param {Object} params
 * @param {String} butParam
 * @returns {Pick<*, Exclude<keyof *, never>>}
 */
export const getAllParamsButParam = (params, butParam) => {
  const clonedObject = cloneDeep(params)
  const { [butParam]: param, ...otherParams } = clonedObject
  return otherParams
}

/**
 *
 * @param {Object} params
 * @param {string[]} butParams
 * @returns {*}
 */
export const getAllParamsButParams = (params, butParams = []) => {
  const clonedObject = cloneDeep(params)
  return butParams.reduce((carry, butParam) => {
    return getAllParamsButParam(carry, butParam)
  }, clonedObject)
}
