export const MEMBERS_INVITATIONS_DELETE_CONFIRM_OPEN =
  'MEMBERS_INVITATIONS_DELETE_CONFIRM_OPEN'
export const MEMBERS_INVITATIONS_DELETE_CONFIRM_CLOSE =
  'MEMBERS_INVITATIONS_DELETE_CONFIRM_CLOSE'

export const MEMBERS_INVITATIONS_DELETE_REQUESTED =
  'MEMBERS_INVITATIONS_DELETE_REQUESTED'
export const MEMBERS_INVITATIONS_DELETE_SUCCEEDED =
  'MEMBERS_INVITATIONS_DELETE_SUCCEEDED'
export const MEMBERS_INVITATIONS_DELETE_FAILED =
  'MEMBERS_INVITATIONS_DELETE_FAILED'
