import { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import { useHistory } from 'react-router-dom'
import { PushNotifications } from '@capacitor/push-notifications'
import convertToCamelCaseKeys from '@doinn/shared/src/util/convertToCamelCaseKeys'
import { storeDeviceInfo } from '@doinn/shared/src/containers/device/actions'
import { formatSystemDate } from '@doinn/shared/src/util/date-fns'

const buildDeepLink = (type, payload) => {
  if (!type || !payload) {
    return
  }

  const buildJobLink = jobId => `/services?jobId=${jobId}`
  const buildOrderServiceNoteLink = (jobId, orderServiceId) =>
    `${buildJobLink(jobId)}&orderServiceId=${orderServiceId}`
  const buildChatLink = chatId => `/messages?chatId=${chatId}`
  const buildNextDayServicesLink = datetime => {
    const formattedDate = formatSystemDate(datetime ?? new Date(), 'medium')
    return `/services?period=custom&start=${formattedDate}&end=${formattedDate}`
  }

  try {
    switch (type) {
      case 'note_creation':
        return buildOrderServiceNoteLink(
          payload.orderService.vendorJob.id,
          payload.orderService.id
        )
      case 'job':
      case 'job_finish_note_sent':
      case 'job_schedule_updated':
        return buildJobLink(payload.id)
      case 'chat_message':
        return buildChatLink(payload.chat.id)
      case 'next_day_services_sent':
      case 'vendor_next_day_services_notification':
      case 'host_next_day_services_notification':
      case 'employee_next_day_services_notification':
        return buildNextDayServicesLink(payload.datetime)
      default:
        return
    }
  } catch (e) {
    console.error(e)
  }
}

const PushNotificationRegister = ({ storeDeviceInfo }) => {
  const history = useHistory()

  const handleRegistration = useCallback(
    async token => {
      const { model, platform, operatingSystem, osVersion } =
        await Device.getInfo()
      storeDeviceInfo({
        model,
        platform,
        operatingSystem,
        osVersion,
        token: token.value
      })
    },
    [storeDeviceInfo]
  )

  const handleNotificationReceived = useCallback(notification => {}, [])

  const handleActionPerformed = useCallback(
    action => {
      const notificationData = action.notification.data
      const data = convertToCamelCaseKeys(JSON.parse(notificationData.data))
      const url = buildDeepLink(notificationData.type, data)
      history.push(url)
    },
    [history]
  )

  const handleRegistrationError = useCallback(error => {
    console.error('Error on registration: ' + JSON.stringify(error))
  }, [])

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      PushNotifications.addListener('registration', handleRegistration)
      PushNotifications.addListener(
        'registrationError',
        handleRegistrationError
      )
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        handleActionPerformed
      )
      PushNotifications.addListener(
        'pushNotificationReceived',
        handleNotificationReceived
      )

      return () => {
        PushNotifications.removeListener('registration', handleRegistration)
        PushNotifications.removeListener(
          'registrationError',
          handleRegistrationError
        )
        PushNotifications.removeListener(
          'pushNotificationActionPerformed',
          handleActionPerformed
        )
        PushNotifications.removeListener(
          'pushNotificationReceived',
          handleNotificationReceived
        )
      }
    }
  }, [
    handleActionPerformed,
    handleNotificationReceived,
    handleRegistrationError,
    handleRegistration
  ])

  if (Capacitor.isNativePlatform()) {
    const register = result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register()
      } else {
        console.error('Error while registering push notification.')
      }
    }

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(register)
  }

  return null
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  storeDeviceInfo: bindActionCreators(storeDeviceInfo, dispatch)
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PushNotificationRegister
)
