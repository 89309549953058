import { all, put, takeLatest } from 'redux-saga/effects'
import Cookies from 'js-cookie'
import {
  USER_LOGGED_IN_AS_FAILED,
  USER_LOGGED_IN_AS_REQUESTED,
  USER_LOGGED_IN_AS_SUCCEEDED
} from '@doinn/shared/src/containers/user-logged-in-as/constants'

export function* getUserLoggedInAs() {
  try {
    let userLoggedInAs = yield Cookies.get('loggedOriginalUser')

    if (userLoggedInAs) {
      userLoggedInAs = JSON.parse(userLoggedInAs)
    }

    yield put({
      type: USER_LOGGED_IN_AS_SUCCEEDED,
      payload: { userLoggedInAs }
    })
  } catch (e) {
    yield put({
      type: USER_LOGGED_IN_AS_FAILED
    })
  }
}

export default function* propertyAssignStaffDialogSaga() {
  yield all([takeLatest(USER_LOGGED_IN_AS_REQUESTED, getUserLoggedInAs)])
}
