import { get } from 'lodash'
import i18n from '@doinn/shared/src/config/i18n'

const UpdateRequestSagaMessages = {
  cancel: {
    create: {
      success: i18n.t(
        'The service cancelation request has been successfully sent!'
      )
    },
    approve: {
      success: i18n.t('This service has been successfully canceled!')
    },
    refuse: {
      success: i18n.t('The cancellation of this service has been refused!')
    }
  },
  reschedule: {
    create: {
      success: i18n.t('This service has been successfully rescheduled!'),
      host: {
        success: i18n.t(
          'The reschedule for this service has been successfully sent to the provider!'
        )
      },
      vendor: {
        success: i18n.t(
          'The reschedule for this service has been successfully sent to the host!'
        )
      }
    },
    approve: {
      success: i18n.t('This service was successfully rescheduled!')
    },
    refuse: {
      success: i18n.t(
        'The reschedule for this service has been successfully refused!'
      )
    }
  },
  'edit-items': {
    create: {
      success: i18n.t(
        'The changes to this service have been successfully sent!'
      )
    },
    approve: {
      success: i18n.t('The changes to this service have been accepted!')
    },
    refuse: {
      success: i18n.t(
        'The suggested changes to this service have been refused!'
      )
    }
  },
  'new-job': {
    approve: {
      success: i18n.t('This service has been accepted!')
    },
    refuse: {
      success: i18n.t('This service has been refused!')
    }
  }
}

export const UpdateRequestDialogTitle = {
  cancel: {
    read: i18n.t('Cancel service'),
    write: {
      refuse: i18n.t('Refuse service cancellation')
    }
  },
  reschedule: {
    read: i18n.t('Reschedule request'),
    write: {
      refuse: i18n.t('Refuse reschedule request')
    }
  },
  'edit-items': {
    read: i18n.t('Edit service'),
    write: {
      refuse: i18n.t('Refuse service edit')
    }
  },
  'new-job': {
    read: i18n.t('Service requested'),
    write: {
      refuse: i18n.t('Refuse service')
    }
  }
}

export const getJobUpdateRequestSagaTextBy = path => {
  if (!path) return ''
  return get(UpdateRequestSagaMessages, path, '')
}
