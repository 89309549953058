import { all, call, put, takeLatest } from 'redux-saga/effects'
import { deactivateIntegrationApi } from '@doinn/host/src/api/integrations'
import {
  INTEGRATIONS_DEACTIVATE_FAILED,
  INTEGRATIONS_DEACTIVATE_REQUESTED,
  INTEGRATIONS_DEACTIVATE_SUCCEEDED
} from '@doinn/host/src/containers/integrations/list/deactivate/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* deactivateIntegration(action) {
  try {
    yield call(deactivateIntegrationApi, action.payload.params)
    yield put({
      type: INTEGRATIONS_DEACTIVATE_SUCCEEDED,
      payload: action.payload
    })
  } catch (e) {
    yield put({
      type: INTEGRATIONS_DEACTIVATE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* integrationsDeactivateSaga() {
  yield all([
    takeLatest(INTEGRATIONS_DEACTIVATE_REQUESTED, deactivateIntegration)
  ])
}
