import {
  NEW_PASSWORD_DIALOG_CLOSE,
  NEW_PASSWORD_DIALOG_OPEN,
  NEW_PASSWORD_REQUESTED
} from '@doinn/shared/src/containers/new-password/constants'

export function open(params) {
  return {
    type: NEW_PASSWORD_DIALOG_OPEN,
    payload: { params }
  }
}

export function close(params) {
  return {
    type: NEW_PASSWORD_DIALOG_CLOSE,
    payload: { params }
  }
}

export function newPassword(params = {}) {
  return {
    type: NEW_PASSWORD_REQUESTED,
    payload: { params }
  }
}
