import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Divider, Tab, Tabs, makeStyles } from '@material-ui/core'

const useToolbarStyles = makeStyles(theme => {
  const mobileBreakpoint = theme.breakpoints.down('xs')
  return {
    tabBar: {
      [mobileBreakpoint]: {
        borderBottom: `1px solid ${theme.palette.secondary.main}`
      }
    }
  }
})

const MetabaseTabBar = ({ tabs, selectedTab }) => {
  const classes = useToolbarStyles()
  const a11yProps = index => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`
    }
  }

  return (
    <>
      <Tabs
        aria-label='metabase tab'
        className={classes.tabBar}
        value={selectedTab}
      >
        {tabs.map((tab, index) => {
          return (
            <Tab
              key={tab.id}
              label={tab.label}
              component={RouterLink}
              to={tab.to}
              value={tab.value}
              {...a11yProps(index)}
            />
          )
        })}
      </Tabs>
      <Divider />
    </>
  )
}

export default MetabaseTabBar
