import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: props => {
    const { size } = props
    return {
      position: 'absolute',
      margin: `0 auto ${-size}px`,
      top: theme.spacing(4),
      left: 0,
      right: 0,
      width: '100%',
      height: 24,
      zIndex: 2000,
      display: 'flex',
      justifyContent: 'center',
      transition: 'transform 0.3s ease-in-out',
      transform: 'translateY(-100%)'
    }
  },
  slideDown: props => {
    const { drag } = props
    return {
      transform: `translateY(calc(${drag * 0.5}px))`
    }
  },
  slideUp: {
    transform: 'translateY(-100%)'
  }
}))

function RefreshIndicator({ isRefreshing, size, drag, maxDrag }) {
  const classes = useStyles({ size, drag })

  let valuePerc = 0
  if (drag > 0) {
    valuePerc = Math.round((drag * 100) / maxDrag)
  }

  return (
    <div
      className={`${classes.container} ${
        isRefreshing ? classes.slideDown : classes.slideUp
      }`}
    >
      <CircularProgress
        size={size * 0.8}
        variant='determinate'
        value={valuePerc}
        defaultValue={0}
      />
    </div>
  )
}

RefreshIndicator.propTypes = {
  size: PropTypes.number.isRequired,
  drag: PropTypes.number.isRequired,
  maxDrag: PropTypes.number.isRequired
}

export default RefreshIndicator
