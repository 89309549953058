import { all, call, put, takeLatest } from 'redux-saga/effects'
import { editIntegrationCredentialsApi } from '@doinn/host/src/api/integrations'
import {
  INTEGRATION_EDIT_CREDENTIALS_FAILED,
  INTEGRATION_EDIT_CREDENTIALS_REQUESTED,
  INTEGRATION_EDIT_CREDENTIALS_SUCCEEDED
} from '@doinn/host/src/containers/integrations/edit/credentials/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* editCredentialsIntegration(action) {
  try {
    const response = yield call(editIntegrationCredentialsApi, action.payload)
    yield put({
      type: INTEGRATION_EDIT_CREDENTIALS_SUCCEEDED,
      payload: response.data.data
    })
  } catch (e) {
    yield put({
      type: INTEGRATION_EDIT_CREDENTIALS_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* integrationsEditCredentialsSaga() {
  yield all([
    takeLatest(
      INTEGRATION_EDIT_CREDENTIALS_REQUESTED,
      editCredentialsIntegration
    )
  ])
}
