import React, { useCallback } from 'react'
import { xor } from 'lodash'
import { Grid } from '@material-ui/core'

import QuestionCard from '@doinn/shared/src/components/common/form-fields/QuestionCard'

function SaasAnnouncementForm({ formProps, questionsArray }) {
  const { setFieldValue, values } = formProps

  const handleOptionClick = useCallback(
    (name, type, value) => {
      if (type === 'checkbox') {
        setFieldValue(name, xor(values[name], [value]))
        return
      }

      setFieldValue(name, value)
    },
    [setFieldValue, values]
  )

  return (
    <form noValidate autoComplete='off' onSubmit={formProps.handleSubmit}>
      <Grid container spacing={2}>
        {questionsArray?.map((formOption, index) => (
          <Grid item xs={12} key={index}>
            <QuestionCard
              {...formOption}
              noBorder
              values={values}
              setFieldValue={setFieldValue}
              onClick={handleOptionClick}
            />
          </Grid>
        ))}
      </Grid>
    </form>
  )
}

export default SaasAnnouncementForm
