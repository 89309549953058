import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  getCataloguesCategoriesApi,
  getCataloguesCustomersApi,
  getCataloguesProvidersApi
} from '@doinn/shared/src/api/catalogues'
import {
  CATALOGUES_CATEGORIES_FILTERS_FETCH_FAILED,
  CATALOGUES_CATEGORIES_FILTERS_FETCH_REQUESTED,
  CATALOGUES_CATEGORIES_FILTERS_FETCH_SUCCEEDED,
  CATALOGUES_CUSTOMERS_FILTERS_FETCH_FAILED,
  CATALOGUES_CUSTOMERS_FILTERS_FETCH_REQUESTED,
  CATALOGUES_CUSTOMERS_FILTERS_FETCH_SUCCEEDED,
  CATALOGUES_FILTERS_FETCH_REQUESTED,
  CATALOGUES_PROVIDERS_FILTERS_FETCH_FAILED,
  CATALOGUES_PROVIDERS_FILTERS_FETCH_REQUESTED,
  CATALOGUES_PROVIDERS_FILTERS_FETCH_SUCCEEDED
} from './constants'
import { omit } from 'lodash'

function* fetchCataloguesFilters(action) {
  const filtersType = action?.payload?.params?.params?.type || ''
  const skip = action?.payload?.params?.params?.skip || []
  omit(action?.payload?.params?.params, 'skip')

  yield call(fetchCataloguesCategoriesFilters, action)

  if (filtersType === 'my' && !skip.includes('customers')) {
    yield call(fetchCataloguesCustomersFilters, action)
  }

  if (filtersType === 'shared' && !skip.includes('providers')) {
    yield call(fetchCataloguesProvidersFilters, action)
  }
}

function* fetchCataloguesCategoriesFilters(action) {
  try {
    const { params } = action.payload
    const response = yield call(getCataloguesCategoriesApi, params)
    const { data } = response.data
    yield put({
      type: CATALOGUES_CATEGORIES_FILTERS_FETCH_SUCCEEDED,
      payload: { data }
    })
  } catch (e) {
    yield put({ type: CATALOGUES_CATEGORIES_FILTERS_FETCH_FAILED })
  }
}

function* fetchCataloguesCustomersFilters(action) {
  try {
    const response = yield call(getCataloguesCustomersApi)
    const { data } = response.data
    yield put({
      type: CATALOGUES_CUSTOMERS_FILTERS_FETCH_SUCCEEDED,
      payload: { data }
    })
  } catch (e) {
    yield put({ type: CATALOGUES_CUSTOMERS_FILTERS_FETCH_FAILED })
  }
}

function* fetchCataloguesProvidersFilters(action) {
  try {
    const response = yield call(getCataloguesProvidersApi)
    const { data } = response.data
    yield put({
      type: CATALOGUES_PROVIDERS_FILTERS_FETCH_SUCCEEDED,
      payload: { data }
    })
  } catch (e) {
    yield put({ type: CATALOGUES_PROVIDERS_FILTERS_FETCH_FAILED })
  }
}

export default function* cataloguesFiltersSaga() {
  yield all([
    takeLatest(CATALOGUES_FILTERS_FETCH_REQUESTED, fetchCataloguesFilters),
    takeLatest(
      CATALOGUES_CATEGORIES_FILTERS_FETCH_REQUESTED,
      fetchCataloguesCategoriesFilters
    ),
    takeLatest(
      CATALOGUES_CUSTOMERS_FILTERS_FETCH_REQUESTED,
      fetchCataloguesCustomersFilters
    ),
    takeLatest(
      CATALOGUES_PROVIDERS_FILTERS_FETCH_REQUESTED,
      fetchCataloguesProvidersFilters
    )
  ])
}
