import { isToday } from 'date-fns'
import { find } from 'lodash'
import {
  convertFromMilitaryTime,
  formatDate
} from '@doinn/shared/src/util/date-fns'

export const getNotificationSubheaderLabel = (dateString, t) => {
  if (!dateString || !t) return
  return isToday(new Date(dateString)) ? t('Recent') : t('Earlier')
}

export const getNotificationTextOrderServiceNote = (noteData, t) => {
  if (!noteData) return ''
  try {
    const orderService = noteData.orderService
    const serviceDateTime = orderService.dateTime || orderService.datetime
    const datetimeString = `${serviceDateTime.date} ${convertFromMilitaryTime(
      serviceDateTime.startTime
    )}`
    const translationParams = {
      propertyName: orderService.space.name,
      date: formatDate(datetimeString, 'longDmWeek')
    }
    switch (noteData.category) {
      case 'missing':
        return t(
          'A time note was added to your service on {{date}}, for the {{propertyName}} property',
          translationParams
        )
      case 'issue':
        return t(
          'An alert note was added to your service on {{date}}, for the {{propertyName}} property',
          translationParams
        )
      case 'damage':
        return t(
          'A maintenance was added to your service on {{date}}, for the {{propertyName}} property',
          translationParams
        )
      case 'simple':
        return t(
          'A general note was added to your service on {{date}}, for the {{propertyName}} property',
          translationParams
        )
      default:
        return t(
          'A general note was added to your service on {{date}}, for the {{propertyName}} property',
          translationParams
        )
    }
  } catch (e) {
    return t('A note was added to your service')
  }
}

export const getNotificationTextJobFinishingNote = (job, t) => {
  const translationParams = {
    serviceName: job?.title,
    propertyName: job?.space?.name
  }

  return t(
    'A job summary for the {{serviceName}} service at {{propertyName}} property is available',
    translationParams
  )
}

export const getNotificationTextJobScheduleUpdated = (job, t) => {
  const translationParams = {
    serviceName: job?.title,
    propertyName: job?.space?.name
  }

  return t(
    'The estimated time has been changed for the {{serviceName}} service at {{propertyName}}.',
    translationParams
  )
}

export const getNotificationTextNextDayServices = (data, t) => {
  if (!data?.datetime) {
    return ''
  }

  const translationParams = {
    date: formatDate(data.datetime, 'long')
  }

  return t("Tomorrow's services reminder for {{date}}.", translationParams)
}

export const getNotificationTextHostNextDayServices = (data, t) => {
  if (!data?.datetime) {
    return ''
  }

  const translationParams = {
    count: data.totalServices,
    date: formatDate(data.datetime, 'long')
  }

  return t(
    'You have {{count}} services confirmed for tomorrow, {{date}}.',
    translationParams
  )
}

export const getNotificationTextVendorNextDayServices = (data, t) => {
  if (!data?.datetime) {
    return ''
  }

  const translationParams = {
    count: data.totalServices,
    date: formatDate(data.datetime, 'long')
  }

  return t(
    'You have {{count}} services to carry out for tomorrow, {{date}}.',
    translationParams
  )
}

export const getNotificationTextEmployeeNextDayServices = (data, t) => {
  if (!data?.datetime) {
    return ''
  }

  const translationParams = {
    count: data.totalServices,
    date: formatDate(data.datetime, 'long')
  }

  return t(
    'You have {{count}} services assigned to you for tomorrow, {{date}}.',
    translationParams
  )
}

export const getNotificationTextRequestToCancelNotification = (data, t) => {
  const mainService = find(data?.job?.orderServices, order => order?.isMain)
  const translationParams = {
    orderId: mainService?.order?.id || '',
    username: data?.user?.name
  }

  return t(
    '#{{orderId}} - You have received a request from {{username}} to cancel this service.',
    translationParams
  )
}

export const getNotificationTextRequestToRescheduleNotification = (data, t) => {
  const mainService = find(data?.job?.orderServices, order => order?.isMain)
  const name = data?.user?.name || ''
  const businessAccountName = data?.businessAccount?.name || ''
  const username =
    name && businessAccountName
      ? `${name}, ${businessAccountName}`
      : name || businessAccountName || ''

  const translationParams = {
    orderId: mainService?.order?.id || '',
    username
  }

  return t(
    '#{{orderId}} - You have received a request from {{username}} to reschedule this service.',
    translationParams
  )
}

export const getNotificationTextRequestToEditJobNotification = (data, t) => {
  const mainService = find(data?.job?.orderServices, order => order?.isMain)
  const name = data?.user?.name || ''
  const businessAccountName = data?.businessAccount?.name || ''
  const username =
    name && businessAccountName
      ? `${name}, ${businessAccountName}`
      : name || businessAccountName || ''

  const translationParams = {
    orderId: mainService?.order?.id || '',
    username
  }

  return t(
    '#{{orderId}} - You have received a request from {{username}} for a service edit.',
    translationParams
  )
}

export const getNotificationTextRequestToNewJobNotification = (data, t) => {
  const mainService = find(data?.job?.orderServices, order => order?.isMain)
  const name = data?.user?.name || ''
  const businessAccountName = data?.businessAccount?.name || ''
  const username =
    name && businessAccountName
      ? `${name}, ${businessAccountName}`
      : name || businessAccountName || ''

  const translationParams = {
    orderId: mainService?.order?.id || '',
    username
  }

  return t(
    '#{{orderId}} - You have received a request from {{username}} for a new service.',
    translationParams
  )
}

export const getNotificationTextProviderInvitationNotification = (data, t) => {
  const username = data?.sender?.name || ''
  return t(
    'You have received an invitation from {{username}} to join as their service provider.',
    { username }
  )
}

export const getNotificationTextCustomerInvitationNotification = (data, t) => {
  const username = data?.sender?.name || ''
  return t('You have received an invitation from {{username}} provider.', {
    username
  })
}

export const getNotificationTextAcceptInvitationNotification = (data, t) => {
  const type = data?.type || ''
  const username = data?.receiver?.name || ''
  let message = ''

  switch (type) {
    case 'provider':
      message = t(
        'Provider {{username}} has accepted the invitation. You will be able to send service to this provider when having a catalogue shared with you.',
        { username }
      )
      break

    case 'customer':
      message = t(
        'Customer {{username}} has accepted the invitation. Now you can share catalogues with them.',
        { username }
      )
      break
    default:
      break
  }

  return message
}

export const getNotificationTextAcceptInvitationReceiverNotification = (
  data,
  t
) => {
  const type = data?.type || ''
  const username = data?.sender?.name || ''
  let message = ''

  switch (type) {
    case 'provider':
      message = t(
        'You have accepted {{username}} invitation! Now you will be able to share catalogues with this customer.',
        { username }
      )
      break

    case 'customer':
      message = t(
        'You have accepted {{username}} invitation! You will be able to send service to this provider when having a catalogue shared with you.',
        { username }
      )
      break
    default:
      break
  }

  return message
}

export const getNotificationText = (item, t) => {
  if (!item || !item.type || !t) return ''
  switch (item.type) {
    case 'App\\Notifications\\OrderServiceNoteCreation':
    case 'Doinn\\Notifications\\OrderServiceNoteCreation':
    case 'App\\Notifications\\NoteCreation':
      return getNotificationTextOrderServiceNote(item.data, t)
    case 'App\\Notifications\\JobFinishNoteSent':
      return getNotificationTextJobFinishingNote(item.data, t)
    case 'App\\Notifications\\JobScheduleUpdated':
      return getNotificationTextJobScheduleUpdated(item.data, t)
    case 'App\\Notifications\\NextDayServicesSent':
      return getNotificationTextNextDayServices(item.data, t)
    case 'App\\Notifications\\HostNextDayServicesNotification':
      return getNotificationTextHostNextDayServices(item.data, t)
    case 'App\\Notifications\\VendorNextDayServicesNotification':
      return getNotificationTextVendorNextDayServices(item.data, t)
    case 'App\\Notifications\\EmployeeNextDayServicesNotification':
      return getNotificationTextEmployeeNextDayServices(item.data, t)
    case 'App\\Notifications\\Jobs\\RequestToCancelNotification':
      return getNotificationTextRequestToCancelNotification(item.data, t)
    case 'App\\Notifications\\Jobs\\RequestToRescheduleNotification':
      return getNotificationTextRequestToRescheduleNotification(item.data, t)
    case 'App\\Notifications\\Jobs\\RequestToEditJobNotification':
      return getNotificationTextRequestToEditJobNotification(item.data, t)
    case 'App\\Notifications\\Jobs\\RequestToNewJobNotification':
      return getNotificationTextRequestToNewJobNotification(item.data, t)
    case 'App\\Notifications\\People\\ProviderInvitationNotification':
      return getNotificationTextProviderInvitationNotification(item.data, t)
    case 'App\\Notifications\\People\\CustomerInvitationNotification':
      return getNotificationTextCustomerInvitationNotification(item.data, t)
    case 'App\\Notifications\\People\\AcceptInvitationNotification':
      return getNotificationTextAcceptInvitationNotification(item.data, t)
    case 'App\\Notifications\\People\\AcceptInvitationReceiverNotification':
      return getNotificationTextAcceptInvitationReceiverNotification(
        item.data,
        t
      )
    default:
      return ''
  }
}
