import { all, call, put, takeLatest } from 'redux-saga/effects'
import { saveOboardingSetupApi } from '@doinn/shared/src/api/onboarding'
import {
  ONBOARDING_SURVEY_FAILED,
  ONBOARDING_SURVEY_REQUESTED,
  ONBOARDING_SURVEY_SUCCEEDED
} from '@doinn/shared/src/containers/onboarding/survey/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* onboardingSurvey(action) {
  try {
    const { params } = action.payload
    const response = yield call(saveOboardingSetupApi, params)
    yield put({
      type: ONBOARDING_SURVEY_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: ONBOARDING_SURVEY_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* onboardingSurveySagas() {
  yield all([takeLatest(ONBOARDING_SURVEY_REQUESTED, onboardingSurvey)])
}
