import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import {
  AppBar,
  Box,
  Divider,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
  useMediaQuery,
  useScrollTrigger,
  useTheme
} from '@material-ui/core'
import useAppDetector from '@doinn/shared/src/hooks/useAppDetector'
import Icon from '@doinn/shared/src/components/common/Icon'
import FlexSpacer from '@doinn/shared/src/components/common/FlexSpacer'
import HeaderUser from 'containers/app/components/HeaderUser'
import NotificationCounter from '@doinn/shared/src/components/app/NotificationCounter'
import SupportChatButton from '@doinn/vendor/src/containers/support/SupportChatButton'
import { setNotificationsCounter } from '@doinn/shared/src/containers/notifications/actions'
import { logout } from '@doinn/shared/src/containers/user/actions'
import Credit from 'containers/app/components/Credit'

const useStyles = makeStyles(theme => {
  const drawerWidth = theme.spacing(30)
  const desktopBreakpoint = theme.breakpoints.up('md')
  const mobileBreakpoint = theme.breakpoints.down('xs')

  return {
    appBar: props => {
      const { isApp, isBannerVisible } = props
      const appBarStyles = {
        backgroundColor: theme.palette.background.default,
        marginTop: isBannerVisible ? theme.spacing(7) : theme.spacing(0)
      }

      if (!isApp) {
        appBarStyles[desktopBreakpoint] = {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth
        }
      }

      if (isApp) {
        appBarStyles[mobileBreakpoint] = {
          paddingTop: `env(safe-area-inset-top, 0)`
        }
      }

      return appBarStyles
    },
    appBarSpacer: props => {
      const appSpaceStyles = { ...theme.mixins.toolbar }

      if (props.isApp) {
        appSpaceStyles[mobileBreakpoint] = {
          minHeight: `calc(env(safe-area-inset-top, 0) + ${theme.mixins.toolbar.minHeight}px)`
        }
      }

      return appSpaceStyles
    },
    menuButton: {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[10],
      marginLeft: 0,
      marginRight: theme.spacing(2),
      [desktopBreakpoint]: {
        display: 'none'
      }
    },
    divider: {
      height: theme.spacing(1.5),
      alignSelf: 'center',
      backgroundColor: theme.palette.text.primary
    }
  }
})

const Header = ({
  children,
  filtersContent,
  isBannerVisible,
  loggedUser,
  notificationsCounter,
  onLogout,
  onToggleMainMenu,
  setNotificationsCounter,
  title
}) => {
  const isApp = useAppDetector()
  const isSite = !isApp
  const classes = useStyles({ isApp, isBannerVisible })
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const hasCredit = loggedUser?.business?.credit?.value > 0

  const isScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  })
  const elevation = isScrolled ? 4 : 0

  const headerContent = !children ? (
    <Typography variant='h5'>
      <b>{title}</b>
    </Typography>
  ) : (
    children
  )

  const handleToggleMainMenu = () => {
    onToggleMainMenu && onToggleMainMenu()
  }

  return (
    <React.Fragment>
      <AppBar
        className={classes.appBar}
        color='inherit'
        elevation={elevation}
        id='appHeader'
        position='fixed'
      >
        <Toolbar>
          {isSite && !isDesktop && (
            <IconButton
              color='inherit'
              aria-label='open main menu'
              edge='start'
              onClick={handleToggleMainMenu}
              className={classes.menuButton}
            >
              <Icon icon='menu' />
            </IconButton>
          )}
          {headerContent}
          <FlexSpacer />
          {isSite && <SupportChatButton />}
          <Box pr={1}>
            <Grid container>
              {isMobile && !!filtersContent && (
                <React.Fragment>
                  {filtersContent}
                  <Divider
                    orientation='vertical'
                    flexItem
                    className={classes.divider}
                  />
                </React.Fragment>
              )}
              {
                <NotificationCounter
                  counter={notificationsCounter}
                  onClick={setNotificationsCounter}
                  isApp={isApp}
                  isDesktop={isDesktop}
                />
              }
            </Grid>
          </Box>
          {isDesktop && hasCredit && (
            <Credit
              symbol={loggedUser?.business?.credit?.symbol}
              value={loggedUser?.business?.credit?.value}
            />
          )}
          {isSite && <HeaderUser loggedUser={loggedUser} onLogout={onLogout} />}
        </Toolbar>
      </AppBar>
      <div className={classes.appBarSpacer} />
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  loggedUser: state.user.loggedUser,
  notificationsCounter: state.notifications.counter
})

const mapDispatchToProps = dispatch => ({
  onLogout: bindActionCreators(logout, dispatch),
  setNotificationsCounter: bindActionCreators(setNotificationsCounter, dispatch)
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(Header)
