import { all, call, put, takeLatest } from 'redux-saga/effects'
import i18n from '@doinn/shared/src/config/i18n'
import { uploadPropertyInstructionsExcelApi } from '@doinn/shared/src/api/property-instructions'
import {
  PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_FAILED,
  PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_REQUESTED,
  PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_SUCCEEDED
} from '@doinn/shared/src/containers/property-instructions/import/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* uploadPropertyInstructions(action) {
  try {
    const response = yield call(
      uploadPropertyInstructionsExcelApi,
      action.payload.file
    )
    yield put({
      type: PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_SUCCEEDED,
      payload: response.data,
      snackbar: {
        variant: 'success',
        message: i18n.t(
          'Properties instructions have been imported successfully!'
        )
      }
    })
  } catch (e) {
    yield put({
      type: PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* propertyInstructionsSaga() {
  yield all([
    takeLatest(
      PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_REQUESTED,
      uploadPropertyInstructions
    )
  ])
}
