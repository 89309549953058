import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { bindActionCreators, compose } from 'redux'

import useAppDetector from '@doinn/shared/src/hooks/useAppDetector'
import { login as loginAction } from '@doinn/shared/src/containers/auth/actions'
import LoginForm from '@doinn/shared/src/containers/auth/components/LoginForm'
import LoginFormApp from '@doinn/shared/src/containers/auth/components/LoginFormApp'

const Login = ({ isLoading, login, error, loggedUser }) => {
  const isApp = useAppDetector()

  if (loggedUser) {
    return <Redirect to={'/'} />
  }

  const handleSubmit = (email, password) => login(email, password)

  return isApp ? (
    <LoginFormApp error={error} isLoading={isLoading} onSubmit={handleSubmit} />
  ) : (
    <LoginForm error={error} isLoading={isLoading} onSubmit={handleSubmit} />
  )
}

const mapStateToProps = state => ({
  loggedUser: state.user.loggedUser,
  error: state.auth.loginError,
  isLoading: state.auth.isLoginLoading
})
const mapDispatchToProps = dispatch => ({
  login: bindActionCreators(loginAction, dispatch)
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(Login)
