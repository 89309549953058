export const INTEGRATION_EDIT_CREDENTIALS_DIALOG_OPEN =
  'INTEGRATION_EDIT_CREDENTIALS_DIALOG_OPEN'
export const INTEGRATION_EDIT_CREDENTIALS_DIALOG_CLOSE =
  'INTEGRATION_EDIT_CREDENTIALS_DIALOG_CLOSE'

export const INTEGRATION_EDIT_CREDENTIALS_REQUESTED =
  'INTEGRATION_EDIT_CREDENTIALS_REQUESTED'
export const INTEGRATION_EDIT_CREDENTIALS_SUCCEEDED =
  'INTEGRATION_EDIT_CREDENTIALS_SUCCEEDED'
export const INTEGRATION_EDIT_CREDENTIALS_FAILED =
  'INTEGRATION_EDIT_CREDENTIALS_FAILED'
