import { all, call, put, takeLatest } from 'redux-saga/effects'
import i18n from '@doinn/shared/src/config/i18n'
import {
  addPaymentMethodApi,
  getSetupIntentApi,
  getpaymentMethodsApi,
  updatePaymentMethodApi
} from '@doinn/shared/src/api/payments'
import {
  PAYPAL_PAYMENT_SETUP_FAILED,
  PAYPAL_PAYMENT_SETUP_OPEN,
  PAYPAL_PAYMENT_SETUP_SAVING_FAILED,
  PAYPAL_PAYMENT_SETUP_SAVING_REQUESTED,
  PAYPAL_PAYMENT_SETUP_SAVING_SUCCEEDED,
  PAYPAL_PAYMENT_SETUP_SUCCEEDED,
  PAYPAL_PAYMENT_SETUP_UPDATING_REQUESTED
} from '@doinn/shared/src/containers/gateways/paypal/setup/constants'
import { GATEWAYS_GET_METHODS_SUCCEEDED } from '@doinn/shared/src/containers/gateways/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* getSetupToken(action) {
  try {
    const { params } = action.payload
    const { type, gateway } = params
    const response = yield call(getSetupIntentApi, { type, gateway })

    yield put({
      type: PAYPAL_PAYMENT_SETUP_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: PAYPAL_PAYMENT_SETUP_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* savePaypalSetupMethod(action) {
  try {
    const { params } = action.payload
    const body = {
      payment_gateway: params.gateway,
      external_id: params.nonce
    }
    const responsePost = yield call(addPaymentMethodApi, body)
    const responseGet = yield call(getpaymentMethodsApi)

    yield put({
      type: GATEWAYS_GET_METHODS_SUCCEEDED,
      payload: responseGet.data
    })
    yield put({
      type: PAYPAL_PAYMENT_SETUP_SAVING_SUCCEEDED,
      payload: responsePost.data,
      snackbar: {
        variant: 'success',
        message: i18n.t('Payment method has been added successfully!')
      }
    })
  } catch (e) {
    yield put({
      type: PAYPAL_PAYMENT_SETUP_SAVING_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* updatePaypalSetupMethod(action) {
  try {
    const { params } = action.payload
    const responsePost = yield call(updatePaymentMethodApi, params)
    const responseGet = yield call(getpaymentMethodsApi)

    yield put({
      type: GATEWAYS_GET_METHODS_SUCCEEDED,
      payload: responseGet.data
    })
    yield put({
      type: PAYPAL_PAYMENT_SETUP_SAVING_SUCCEEDED,
      payload: responsePost.data,
      snackbar: {
        variant: 'success',
        message: i18n.t('Payment method has been added successfully!')
      }
    })
  } catch (e) {
    yield put({
      type: PAYPAL_PAYMENT_SETUP_SAVING_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* paypalSetupSagas() {
  yield all([
    takeLatest(PAYPAL_PAYMENT_SETUP_OPEN, getSetupToken),
    takeLatest(PAYPAL_PAYMENT_SETUP_SAVING_REQUESTED, savePaypalSetupMethod),
    takeLatest(PAYPAL_PAYMENT_SETUP_UPDATING_REQUESTED, updatePaypalSetupMethod)
  ])
}
