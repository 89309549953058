import { formatSystemDate } from '@doinn/shared/src/util/date-fns'
import {
  CALENDAR_FETCH_FAILED,
  CALENDAR_FETCH_REQUESTED,
  CALENDAR_FETCH_SUCCEEDED
} from '@doinn/vendor/src/containers/dashboard/dashlets/calendar/constants'

export const STATUS = {
  IDLE: 'idle',
  FETCHING: 'fetching',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  status: STATUS.IDLE,
  data: {},
  period: {}
}

const dataFormatted = data => {
  const keys = Object.keys(data)
  const allowedIds = [1, 2]
  const formatted = {}
  let date = null
  let events = null

  keys.forEach(key => {
    date = formatSystemDate(key)
    events = data[key].events.filter(value => allowedIds.includes(value.id))
    formatted[date] = { ...data[key], events }
  })

  return formatted
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case CALENDAR_FETCH_REQUESTED:
      return {
        ...initialState,
        status: STATUS.FETCHING,
        period: payload.params
      }
    case CALENDAR_FETCH_SUCCEEDED:
      return {
        ...state,
        data: { ...dataFormatted(payload.data) },
        status: STATUS.SUCCESS
      }
    case CALENDAR_FETCH_FAILED:
      return {
        ...state,
        data: { ...initialState.data },
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
