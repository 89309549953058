import { createSearchAction } from 'redux-search'
import {
  EMPLOYEES_FETCH_REQUESTED,
  EMPLOYEES_UPDATE_SELECTED_EMPLOYEE_REQUESTED
} from '@doinn/vendor/src/containers/staff/employees/constants'

export function fetchEmployees(params = {}) {
  return { type: EMPLOYEES_FETCH_REQUESTED, payload: { params } }
}

export function updateSelectedEmployees(employees) {
  return {
    type: EMPLOYEES_UPDATE_SELECTED_EMPLOYEE_REQUESTED,
    payload: [...employees]
  }
}

export const searchEmployees = createSearchAction('employees')
