import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getCataloguePropertyOptionalItemsApi } from '@doinn/shared/src/api/catalogues'
import {
  GET_CATALOGUE_PROPERTY_OPTIONAL_ITEMS_FAILED,
  GET_CATALOGUE_PROPERTY_OPTIONAL_ITEMS_REQUESTED,
  GET_CATALOGUE_PROPERTY_OPTIONAL_ITEMS_SUCCEEDED
} from '@doinn/shared/src/containers/catalogue/property-optional-items/constants'

function* getCataloguePropertyOptionalItems(action) {
  try {
    const { catalogue, properties } = action.payload
    const response = yield call(
      getCataloguePropertyOptionalItemsApi,
      catalogue,
      properties
    )

    const { data } = response.data
    yield put({
      type: GET_CATALOGUE_PROPERTY_OPTIONAL_ITEMS_SUCCEEDED,
      payload: data
    })
  } catch (e) {
    yield put({
      type: GET_CATALOGUE_PROPERTY_OPTIONAL_ITEMS_FAILED
    })
  }
}

export default function* cataloguePropertyOptionalItemsSagas() {
  yield all([
    takeLatest(
      GET_CATALOGUE_PROPERTY_OPTIONAL_ITEMS_REQUESTED,
      getCataloguePropertyOptionalItems
    )
  ])
}
