import {
  CATALOGUES_CATEGORIES_FILTERS_FETCH_REQUESTED,
  CATALOGUES_FILTERS_FETCH_REQUESTED
} from './constants'

export function fetchCataloguesCategoriesFilters(params = {}) {
  return {
    type: CATALOGUES_CATEGORIES_FILTERS_FETCH_REQUESTED,
    payload: { params }
  }
}

export function fetchCataloguesFilters(params = {}) {
  return {
    type: CATALOGUES_FILTERS_FETCH_REQUESTED,
    payload: { params }
  }
}
