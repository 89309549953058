import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { addOrRemove } from '@doinn/shared/src/util/array'
import { fetchPropertiesElasticsearchApi } from '@doinn/shared/src/api/properties'
import { STATUS } from '@doinn/shared/src/containers/automations/filters/reducers'
import withAuthorization from '@doinn/shared/src/hoc/authorization/withAuthorization'
import { fetchAutomationsFilters } from '@doinn/shared/src/containers/automations/filters/actions'
import ListFilters from '@doinn/host/src/containers/automations/list/components/ListFilters'

const DEFAULT_ROWS_PER_PAGE = 30

const mapIdsToFilter = items => {
  if (!items || !items.length) return []
  return items
    .map(item => {
      if (typeof item === 'object') {
        return item.id ? item.id : ''
      }
      return item
    })
    .filter(item => !!item)
}

const parseGetPropertiesAutocompleteOptionsParams = ({
  search = '',
  limit = DEFAULT_ROWS_PER_PAGE,
  loggedUser,
  filters
} = {}) => {
  const params = {
    search,
    limit
  }
  if (loggedUser?.business?.id) {
    if (loggedUser?.roles?.includes('vendor')) {
      params.vendorBusinessAccountId = loggedUser.business.id
    }
    if (loggedUser?.roles?.includes('host')) {
      params.hostBusinessAccountId = loggedUser.business.id
    }
  }
  if (filters) {
    if (filters.spaces) {
      params.ids = mapIdsToFilter(filters.spaces)
    }
  }
  return params
}

const getAutocompleteOptions =
  loggedUser => async (filterName, value, filters) => {
    const callbacks = {
      spaces: fetchPropertiesElasticsearchApi
    }
    if (callbacks[filterName]) {
      const params = parseGetPropertiesAutocompleteOptionsParams({
        search: value || '',
        limit: DEFAULT_ROWS_PER_PAGE,
        loggedUser,
        filters
      })
      const response = await callbacks[filterName]({ params })
      return response.data.data || []
    }
    return []
  }

const ListFiltersContent = ({ filters, hiddenFilters, onChangeFilters }) => {
  const dispatch = useDispatch()
  const { loggedUser } = useSelector(state => state.user)
  const { status, filters: automationsFilters } = useSelector(
    state => state.automationsFilters
  )
  const { catalogs, catalogues, vendorBusinessAccounts } = automationsFilters
  const [selectedProperties, setSelectedProperties] = useState([])

  const isLoading = status === STATUS.LOADING

  useEffect(() => {
    dispatch(fetchAutomationsFilters())
  }, [dispatch])

  const memoizedGetAutocompleteOptions = useMemo(() => {
    return getAutocompleteOptions(loggedUser)
  }, [loggedUser])

  useEffect(() => {
    const fetchSelectedProperties = async () => {
      let newSelectedProperties = []
      if (filters.spaces && filters.spaces.length) {
        newSelectedProperties = await memoizedGetAutocompleteOptions(
          'spaces',
          '',
          { spaces: filters.spaces }
        )
      }
      setSelectedProperties(newSelectedProperties)
    }
    fetchSelectedProperties()
  }, [filters.spaces, memoizedGetAutocompleteOptions])

  const handleChangeFilter = useCallback(
    (filterName, newValue) => {
      const oldValue = filters[filterName]
      const newValueAsString =
        typeof newValue === 'object' &&
        !Array.isArray(newValue) &&
        newValue !== null
          ? String(newValue.id)
          : newValue
      const parsedNewValue = Array.isArray(oldValue)
        ? addOrRemove(oldValue, newValueAsString)
        : newValueAsString
      const params = {
        ...filters,
        page: 1
      }
      params[filterName] = parsedNewValue
      onChangeFilters && onChangeFilters(params)
    },
    [filters, onChangeFilters]
  )

  const filtersProps = {
    hiddenFilters,
    isLoading,
    catalogs,
    catalogues,
    vendorBusinessAccounts,
    filters: { ...filters, spaces: selectedProperties },
    onChange: handleChangeFilter,
    onChangeFilters: onChangeFilters,
    getAutocompleteOptions: memoizedGetAutocompleteOptions
  }

  return <ListFilters {...filtersProps} />
}

ListFiltersContent.propTypes = {
  disabledFilters: PropTypes.object,
  filters: PropTypes.object.isRequired
}

ListFiltersContent.defaultProps = {
  disabledFilters: {}
}

export default withAuthorization(ListFiltersContent)
