import { all, call, put, takeLatest } from 'redux-saga/effects'
import { saveOboardingSetupApi } from '@doinn/shared/src/api/onboarding'
import {
  ONBOARDING_SAAS_ANNOUNCEMENT_FAILED,
  ONBOARDING_SAAS_ANNOUNCEMENT_REQUESTED,
  ONBOARDING_SAAS_ANNOUNCEMENT_SUCCEEDED
} from '@doinn/shared/src/containers/onboarding/saas-announcement/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* onboardingSaasSurvey(action) {
  try {
    const { params } = action.payload
    const response = yield call(
      saveOboardingSetupApi,
      params,
      'saas-announcement'
    )
    yield put({
      type: ONBOARDING_SAAS_ANNOUNCEMENT_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: ONBOARDING_SAAS_ANNOUNCEMENT_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* onboardingSaasAnnouncementSagas() {
  yield all([
    takeLatest(ONBOARDING_SAAS_ANNOUNCEMENT_REQUESTED, onboardingSaasSurvey)
  ])
}
