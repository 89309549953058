import { useCallback, useEffect } from 'react'
import { App } from '@capacitor/app'
import { CUSTOM_URL_SCHEME } from 'config/env'
import { matchPath, useHistory } from 'react-router-dom'

const AppUrlListener = ({ routes, loggedUser }) => {
  const history = useHistory()

  const isRouteAllowed = useCallback(
    pathname => !!routes.find(path => matchPath(pathname, path)),
    [routes]
  )

  useEffect(() => {
    App.addListener('appUrlOpen', event => {
      const containScheme = event?.url.includes(CUSTOM_URL_SCHEME) ?? false

      if (!containScheme) return

      const pathname = event.url.split(`${CUSTOM_URL_SCHEME}:/`).pop()
      const isAllowed = isRouteAllowed(pathname)

      if (!isAllowed) {
        !loggedUser && history.push('/login')
        return
      }

      history.push(pathname)
    })
  }, [history, isRouteAllowed, loggedUser])

  return null
}

export default AppUrlListener
