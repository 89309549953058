import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as searchReducer } from 'redux-search'
import appReducers from '@doinn/shared/src/containers/app/reducers'
import automationListReducers from '@doinn/host/src/containers/automations/list/reducers'
import automationGetReducers from '@doinn/host/src/containers/automations/get/reducers'
import automationAddReducers from '@doinn/host/src/containers/automations/add/reducers'
import automationEditReducers from '@doinn/host/src/containers/automations/edit/reducers'
import automationActivateReducers from '@doinn/host/src/containers/automations/list/activate/reducers'
import automationDeactivateReducers from '@doinn/host/src/containers/automations/list/deactivate/reducers'
import automationDeleteReducers from '@doinn/host/src/containers/automations/list/delete/reducers'
import automationsFiltersReducers from '@doinn/shared/src/containers/automations/filters/reducers'
import bannerReducers from '@doinn/shared/src/containers/banner/reducers'
import billingGeneralReducers from '@doinn/shared/src/containers/subscription-billing/billing/list/general/reducers'
import billingGroupReducers from '@doinn/shared/src/containers/subscription-billing/billing/list/group/reducers'
import billingGetReducers from '@doinn/shared/src/containers/subscription-billing/billing/get/reducers'
import billingEditReducers from '@doinn/shared/src/containers/subscription-billing/billing/edit/reducers'
import billingAddReducers from '@doinn/shared/src/containers/subscription-billing/billing/add/reducers'
import billingDeleteReducers from '@doinn/shared/src/containers/subscription-billing/billing/delete/reducers'
import cancelRequestReducers from '@doinn/vendor/src/containers/job/cancel-request/reducers'
import catalogAddReducers from '@doinn/host/src/containers/catalog/add/reducers'
import catalogItemImportReducers from '@doinn/host/src/containers/catalog/item/import/reducers'
import catalogEditReducers from '@doinn/host/src/containers/catalog/edit/reducers'
import catalogDeleteReducers from '@doinn/host/src/containers/catalog/delete/reducers'
import catalogListReducers from '@doinn/host/src/containers/catalog/list/reducers'
import catalogGetReducers from '@doinn/host/src/containers/catalog/get/reducers'
import catalogItemAddReducers from '@doinn/host/src/containers/catalog/item/add/reducers'
import catalogItemEditReducers from '@doinn/host/src/containers/catalog/item/edit/reducers'
import catalogItemDeleteReducers from '@doinn/host/src/containers/catalog/item/delete/reducers'
import catalogItemListReducers from '@doinn/host/src/containers/catalog/item/list/reducers'
import catalogItemGetReducers from '@doinn/host/src/containers/catalog/item/get/reducers'
import catalogueCopyReducers from '@doinn/shared/src/containers/catalogue/copy/reducers'
import catalogueMyAddReducers from '@doinn/shared/src/containers/catalogue/my/add/reducers'
import catalogueMyEditReducers from '@doinn/shared/src/containers/catalogue/my/edit/reducers'
import catalogueMyEditDescriptionReducers from '@doinn/shared/src/containers/catalogue/my/edit-description/reducers'
import catalogueMyEditSettingsReducers from '@doinn/shared/src/containers/catalogue/my/edit-settings/reducers'
import catalogueMyEditPricesReducers from '@doinn/shared/src/containers/catalogue/my/edit-prices/reducers'
import catalogueMyDeleteReducers from '@doinn/shared/src/containers/catalogue/my/delete/reducers'
import catalogueMyListReducers from '@doinn/shared/src/containers/catalogue/my/list/reducers'
import catalogueMyGetReducers from '@doinn/shared/src/containers/catalogue/my/get/reducers'
import catalogueMyShareReducers from '@doinn/shared/src/containers/catalogue/my/share/reducers'
import catalogueMyUnshareReducers from '@doinn/shared/src/containers/catalogue/my/unshare/reducers'
import catalogueSharedListReducers from '@doinn/shared/src/containers/catalogue/shared/list/reducers'
import catalogueSharedGetReducers from '@doinn/shared/src/containers/catalogue/shared/get/reducers'
import catalogueTemplateListReducers from '@doinn/shared/src/containers/catalogue/templates/list/reducers'
import catalogueTemplatesGetReducers from '@doinn/shared/src/containers/catalogue/templates/get/reducers'
import cataloguesFiltersReducers from '@doinn/shared/src/containers/catalogue/filters/reducers'
import checkoutReducers from '@doinn/vendor/src/containers/checkout/reducers'
import checkoutCouponReducers from '@doinn/vendor/src/containers/checkout/coupon/reducers'
import checkoutCreditsReducers from '@doinn/vendor/src/containers/checkout/credits/reducers'
import checkoutRemoveServiceReducers from '@doinn/vendor/src/containers/checkout/remove/reducers'
import cityListReducers from '@doinn/shared/src/containers/city/list/reducers'
import chatsReducers from '@doinn/shared/src/containers/chats/reducers'
import chatsContactsReducers from '@doinn/shared/src/containers/chats/contacts/reducers'
import chatsJobsReducers from '@doinn/shared/src/containers/chats/jobs/reducers'
import chatsPropertiesReducers from '@doinn/shared/src/containers/chats/properties/reducers'
import chatAddReducers from '@doinn/shared/src/containers/chats/add/reducers'
import chatFollowReducers from '@doinn/shared/src/containers/chats/follow/reducers'
import chatGetReducers from '@doinn/shared/src/containers/chats/get/reducers'
import chatMessagesReducers from '@doinn/shared/src/containers/chats/messages/reducers'
import checklistCopyReducers from '@doinn/host/src/containers/checklist/copy/reducers'
import checklistMyAddReducers from '@doinn/host/src/containers/checklist/my/add/reducers'
import checklistMyEditReducers from '@doinn/host/src/containers/checklist/my/edit/reducers'
import checklistMyDeleteReducers from '@doinn/host/src/containers/checklist/my/delete/reducers'
import checklistMyListReducers from '@doinn/host/src/containers/checklist/my/list/reducers'
import checklistMyGetReducers from '@doinn/host/src/containers/checklist/my/get/reducers'
import checklistMyDetailsReducers from '@doinn/host/src/containers/checklist/my/details/reducers'
import checklistMyImportReducers from '@doinn/host/src/containers/checklist/my/import/reducers'
import checklistPublicListReducers from '@doinn/host/src/containers/checklist/public/list/reducers'
import checklistPublicGetReducers from '@doinn/host/src/containers/checklist/public/get/reducers'
import checklistPublicDetailsReducers from '@doinn/host/src/containers/checklist/public/details/reducers'
import customerGetReducers from '@doinn/vendor/src/containers/customer/get/reducers'
import customerListReducers from '@doinn/vendor/src/containers/customer/list/reducers'
import customerInvitationDeleteReducers from '@doinn/vendor/src/containers/customer/invitation/delete/reducers'
import dashboardReducers from 'containers/dashboard/reducers'
import dashletsReducers from 'containers/dashboard/dashlets/reducers'
import editItemsRequestReducers from '@doinn/vendor/src/containers/job/edit-items-request/reducers'
import employeeGetReducers from '@doinn/vendor/src/containers/employee/get/reducers'
import employeeAddEditFormReducers from '@doinn/vendor/src/containers/employee/add-edit/reducers'
import employeesReducers from '@doinn/vendor/src/containers/staff/employees/reducers'
import employeesDeleteConfirmReducers from '@doinn/vendor/src/containers/staff/employees/delete/reducers'
import importGetReducers from '@doinn/shared/src/containers/import/get/reducers'
import integrationAddReducers from '@doinn/host/src/containers/integrations/add/reducers'
import integrationEditReducers from '@doinn/host/src/containers/integrations/edit/configuration/reducers'
import integrationCatalogListReducers from '@doinn/host/src/containers/integrations/catalog/list/reducers'
import integrationListReducers from '@doinn/host/src/containers/integrations/list/reducers'
import integrationActivateReducers from '@doinn/host/src/containers/integrations/list/activate/reducers'
import integrationDeactivateReducers from '@doinn/host/src/containers/integrations/list/deactivate/reducers'
import integrationDeleteReducers from '@doinn/host/src/containers/integrations/list/delete/reducers'
import integrationGetReducers from '@doinn/host/src/containers/integrations/get/reducers'
import integrationRenameReducers from '@doinn/host/src/containers/integrations/edit/rename/reducers'
import integrationEditCredentialsReducers from '@doinn/host/src/containers/integrations/edit/credentials/reducers'
import invoicesReducers from '@doinn/shared/src/containers/invoice/sent/reducers'
import jobReducers from 'containers/job/reducers'
import jobAvailableSchedulesReducers from '@doinn/host/src/containers/job/available-schedules/reducers'
import jobAddReducers from '@doinn/host/src/containers/job/add/reducers'
import jobAssignStaffFormReducers from 'containers/job/assign-staff/reducers'
import jobDetailsReducers from 'containers/job/details/reducers'
import jobExtraServiceAddFormReducers from 'containers/job/extra-service/add/reducers'
import jobExtraServicesListReducers from 'containers/job/extra-service/list/reducers'
import jobReportProblemReducers from 'containers/job/report-problem/reducers'
import jobRescheduleReducers from '@doinn/vendor/src/containers/job/reschedule/reducers'
import jobViewReportProblemReducers from 'containers/job/report-problem/view/reducers'
import jobServiceDeleteReducer from '@doinn/vendor/src/containers/job/delete/reducers'
import jobsReducers from 'containers/jobs/reducers'
import jobsDispatchReducers from 'containers/jobs/dispatch/reducers'
import jobChecklistGetReducers from '@doinn/shared/src/containers/job/checklist/get/reducers'
import jobHistoryReducers from '@doinn/shared/src/containers/job/history/reducers'
import jobChecklistEditReducers from 'containers/job/checklist/edit/reducers'
import jobFiltersReducers from '@doinn/shared/src/containers/jobs/filters/reducers'
import authReducers from '@doinn/shared/src/containers/auth/reducers'
import notificationsReducers from '@doinn/shared/src/containers/notifications/reducers'
import orderServiceNoteAddFormReducers from '@doinn/shared/src/containers/order-service/notes/add/reducers'
import jobFinishNoteReducers from 'containers/job/finish/note/reducers'
import orderServiceDeleteReducers from 'containers/order-services/delete/reducers'
import orderServiceExtraItemListReducers from 'containers/order-services/extra-item/list/reducers'
import orderServiceExtraItemsEditFormReducers from 'containers/order-services/extra-item/edit/reducers'
import paymentMethodsReducers from '@doinn/shared/src/containers/subscription-billing/payment/list/reducers'
import paymentMethodsDefaultReducers from '@doinn/shared/src/containers/subscription-billing/payment/default/reducers'
import paymentGatewaysReducers from '@doinn/shared/src/containers/gateways/reducers'
import paymentPaypalSetupReducers from '@doinn/shared/src/containers/gateways/paypal/setup/reducers'
import paymentStripeSetupReducers from '@doinn/shared/src/containers/gateways/stripe/setup/reducers'
import paymentStripeConfirmPaymentReducers from '@doinn/shared/src/containers/gateways/stripe/confirm-payment/reducers'
import priceListReducers from 'containers/price-list/reducers'
import propertyGetReducers from '@doinn/shared/src/containers/property/get/reducers'
import propertyAddReducers from '@doinn/shared/src/containers/property/add/reducers'
import propertyAutomationListReducers from '@doinn/shared/src/containers/property/automations/reducers'
import propertyImportReducers from '@doinn/shared/src/containers/property/import/reducers'
import propertyEditReducers from '@doinn/shared/src/containers/property/edit/reducers'
import propertyDeleteReducers from '@doinn/shared/src/containers/property/delete/reducers'
import propertyDetailsReducers from '@doinn/shared/src/containers/property/details/reducers'
import propertySourcesReducers from '@doinn/shared/src/containers/property-sources/reducers'
import propertyBusinessAccountsReducers from '@doinn/shared/src/containers/property-business-accounts/reducers'
import propertyCitiesReducers from '@doinn/shared/src/containers/property-cities/reducers'
import propertyInstructionsReducers from '@doinn/shared/src/containers/property-instructions/reducers'
import propertyInstructionsImportReducers from '@doinn/shared/src/containers/property-instructions/import/reducers'
import propertyInstructionsEditReducers from '@doinn/shared/src/containers/property/instructions/edit/reducers'
import propertyTypesReducers from '@doinn/shared/src/containers/property-types/reducers'
import propertyAssignStaffDialogReducers from '@doinn/shared/src/containers/property/assign-staff/reducers'
import propertyListReducers from '@doinn/shared/src/containers/property/list/reducers'
import propertySpaceCategoriesReducers from '@doinn/shared/src/containers/property-space-categories/reducers'
import propertiesSyncReducers from '@doinn/shared/src/containers/property/sync/reducers'
import providerGetReducers from '@doinn/vendor/src/containers/provider/get/reducers'
import providerListReducers from '@doinn/vendor/src/containers/provider/list/reducers'
import receivedInvoicesReducers from '@doinn/shared/src/containers/invoice/received/reducers'
import reportsBillingReducers from 'containers/reports/billing/reducers'
import reportsBillingDetailsReducers from 'containers/reports/billingDetails/reducers'
import reservationAddReducers from '@doinn/vendor/src/containers/reservation/add/reducers'
import reservationBlockReducers from '@doinn/vendor/src/containers/reservation/block/reducers'
import reservationDeleteReducers from '@doinn/vendor/src/containers/reservation/delete/reducers'
import reservationDeleteAllReducers from '@doinn/vendor/src/containers/reservation/delete-all/reducers'
import reservationEditReducers from '@doinn/vendor/src/containers/reservation/edit/reducers'
import reservationGetReducers from '@doinn/vendor/src/containers/reservation/get/reducers'
import reservationListReducers from '@doinn/vendor/src/containers/reservation/list/reducers'
import reservationServicesReducers from '@doinn/vendor/src/containers/reservation/services/reducers'
import reservationSyncReducers from '@doinn/vendor/src/containers/reservation/sync/reducers'
import reservationUnblockReducers from '@doinn/vendor/src/containers/reservation/unblock/reducers'
import servicesReducers from 'containers/services/reducers'
import snackbarReducers from '@doinn/shared/src/containers/snackbar/reducers'
import userProfileReducers from '@doinn/shared/src/containers/profile/reducers'
import userReducers from '@doinn/shared/src/containers/user/reducers'
import teamGetReducers from '@doinn/vendor/src/containers/team/get/reducers'
import teamAddEditFormReducers from '@doinn/vendor/src/containers/team/add-edit/reducers'
import teamsReducers from '@doinn/vendor/src/containers/staff/teams/reducers'
import teamsDeleteConfirmReducers from '@doinn/vendor/src/containers/staff/teams/delete/reducers'
import memberGetReducers from '@doinn/vendor/src/containers/member/get/reducers'
import memberEditReducers from '@doinn/vendor/src/containers/member/edit/reducers'
import memberPasswordEditReducers from '@doinn/vendor/src/containers/member/edit-password/reducers'
import memberListReducers from '@doinn/vendor/src/containers/member/list/reducers'
import memberDeleteReducers from '@doinn/shared/src/containers/members/delete/reducers'
import memberInvitationListReducers from '@doinn/vendor/src/containers/member/invitation/list/reducers'
import memberInvitationDeleteReducers from '@doinn/vendor/src/containers/member/invitation/delete/reducers'
import membersInvitationsAddReducers from '@doinn/vendor/src/containers/member/invitation/add/reducers'
import membersInvitationsResendReducers from '@doinn/vendor/src/containers/member/invitation/resend/reducers'
import membersInvitationsImportReducers from '@doinn/vendor/src/containers/member/invitation/import/reducers'
import subscriptionInvalidReducers from '@doinn/shared/src/containers/subscription/invalid/reducers'
import subscriptionPlansReducers from '@doinn/shared/src/containers/subscription/plans/reducers'
import subscriptionOverviewReducers from '@doinn/shared/src/containers/subscription-billing/subscription/reducers'
import invitationsAnswerReducers from '@doinn/vendor/src/containers/member/invitation/answer/reducers'
import providersInvitationsAddReducers from '@doinn/vendor/src/containers/provider/add/reducers'
import customersInvitationsAddReducers from '@doinn/vendor/src/containers/customer/add/reducers'
import newPasswordReducers from '@doinn/shared/src/containers/new-password/reducers'
import onboardingSaasAnnouncementReducers from '@doinn/shared/src/containers/onboarding/saas-announcement/reducers'
import onboardingSurveyReducers from '@doinn/shared/src/containers/onboarding/survey/reducers'
import onboardingDemoReducers from '@doinn/shared/src/containers/onboarding/demo/reducers'
import onboardingFloatingReducers from '@doinn/shared/src/containers/onboarding/floating/reducers'
import onboardingReducers from 'containers/onboarding/reducers'
import languageReducers from '@doinn/shared/src/containers/language/reducers'
import updateRequestReducers from '@doinn/vendor/src/containers/job/update-request/reducers'
import userLoggedInAsReducers from '@doinn/shared/src/containers/user-logged-in-as/reducers'
import cataloguePropertyOptionalItemsReducers from '@doinn/shared/src/containers/catalogue/property-optional-items/reducers'
import registerReducers from '@doinn/vendor/src/containers/app/auth/register/reducers'
import resetPasswordReducers from '@doinn/shared/src/containers/auth/reset-password/reducers'
import stripeKYCReducers from '@doinn/vendor/src/containers/stripe-kyc/reducers'
import supportReducers from '@doinn/vendor/src/containers/support/reducers'

const createRootReducer = history =>
  combineReducers({
    search: searchReducer,
    router: connectRouter(history),
    app: appReducers,
    automationList: automationListReducers,
    automationGet: automationGetReducers,
    automationAdd: automationAddReducers,
    automationEdit: automationEditReducers,
    automationActivate: automationActivateReducers,
    automationDeactivate: automationDeactivateReducers,
    automationDelete: automationDeleteReducers,
    automationsFilters: automationsFiltersReducers,
    banner: bannerReducers,
    billingGeneral: billingGeneralReducers,
    billingGroup: billingGroupReducers,
    billingGet: billingGetReducers,
    billingEdit: billingEditReducers,
    billingAdd: billingAddReducers,
    billingDelete: billingDeleteReducers,
    cancelRequest: cancelRequestReducers,
    catalogAdd: catalogAddReducers,
    catalogItemImport: catalogItemImportReducers,
    catalogEdit: catalogEditReducers,
    catalogDelete: catalogDeleteReducers,
    catalogList: catalogListReducers,
    catalogGet: catalogGetReducers,
    catalogItemAdd: catalogItemAddReducers,
    catalogItemEdit: catalogItemEditReducers,
    catalogItemDelete: catalogItemDeleteReducers,
    catalogItemList: catalogItemListReducers,
    catalogItemGet: catalogItemGetReducers,
    catalogueCopy: catalogueCopyReducers,
    catalogueMyAdd: catalogueMyAddReducers,
    catalogueMyEdit: catalogueMyEditReducers,
    catalogueMyEditDescription: catalogueMyEditDescriptionReducers,
    catalogueMyEditSettings: catalogueMyEditSettingsReducers,
    catalogueMyEditPrices: catalogueMyEditPricesReducers,
    catalogueMyDelete: catalogueMyDeleteReducers,
    catalogueMyList: catalogueMyListReducers,
    catalogueMyGet: catalogueMyGetReducers,
    catalogueMyShare: catalogueMyShareReducers,
    catalogueMyUnshare: catalogueMyUnshareReducers,
    catalogueSharedList: catalogueSharedListReducers,
    catalogueSharedGet: catalogueSharedGetReducers,
    catalogueTemplateList: catalogueTemplateListReducers,
    catalogueTemplatesGet: catalogueTemplatesGetReducers,
    cataloguesFilters: cataloguesFiltersReducers,
    checkout: checkoutReducers,
    checkoutCoupon: checkoutCouponReducers,
    checkoutCredits: checkoutCreditsReducers,
    checkoutRemoveService: checkoutRemoveServiceReducers,
    cityList: cityListReducers,
    chats: chatsReducers,
    chatsContacts: chatsContactsReducers,
    chatsJobs: chatsJobsReducers,
    chatsProperties: chatsPropertiesReducers,
    chatAdd: chatAddReducers,
    chatFollow: chatFollowReducers,
    chatGet: chatGetReducers,
    chatMessages: chatMessagesReducers,
    checklistCopy: checklistCopyReducers,
    checklistMyAdd: checklistMyAddReducers,
    checklistMyEdit: checklistMyEditReducers,
    checklistMyDelete: checklistMyDeleteReducers,
    checklistMyList: checklistMyListReducers,
    checklistMyGet: checklistMyGetReducers,
    checklistMyDetails: checklistMyDetailsReducers,
    checklistMyImport: checklistMyImportReducers,
    checklistPublicList: checklistPublicListReducers,
    checklistPublicGet: checklistPublicGetReducers,
    checklistPublicDetails: checklistPublicDetailsReducers,
    customerGet: customerGetReducers,
    customerList: customerListReducers,
    customerInvitationDelete: customerInvitationDeleteReducers,
    dashboard: dashboardReducers,
    dashlets: dashletsReducers,
    editItemsRequest: editItemsRequestReducers,
    employeeGet: employeeGetReducers,
    employeeAddEditForm: employeeAddEditFormReducers,
    employees: employeesReducers,
    employeesDeleteConfirm: employeesDeleteConfirmReducers,
    integrationAdd: integrationAddReducers,
    integrationEdit: integrationEditReducers,
    integrationCatalogList: integrationCatalogListReducers,
    integrationList: integrationListReducers,
    integrationActivate: integrationActivateReducers,
    integrationDeactivate: integrationDeactivateReducers,
    integrationDelete: integrationDeleteReducers,
    integrationGet: integrationGetReducers,
    integrationRename: integrationRenameReducers,
    integrationEditCredentials: integrationEditCredentialsReducers,
    invoices: invoicesReducers,
    job: jobReducers,
    jobAvailableSchedules: jobAvailableSchedulesReducers,
    jobAdd: jobAddReducers,
    jobExtraServiceAddForm: jobExtraServiceAddFormReducers,
    jobExtraServiceList: jobExtraServicesListReducers,
    jobAssignStaffForm: jobAssignStaffFormReducers,
    jobDetails: jobDetailsReducers,
    jobs: jobsReducers,
    jobsDispatch: jobsDispatchReducers,
    jobChecklistGet: jobChecklistGetReducers,
    jobHistory: jobHistoryReducers,
    jobFilters: jobFiltersReducers,
    jobChecklistEdit: jobChecklistEditReducers,
    jobReportProblem: jobReportProblemReducers,
    jobReschedule: jobRescheduleReducers,
    jobViewReportProblem: jobViewReportProblemReducers,
    jobServiceDelete: jobServiceDeleteReducer,
    auth: authReducers,
    language: languageReducers,
    notifications: notificationsReducers,
    orderServiceNoteAddForm: orderServiceNoteAddFormReducers,
    jobFinishNote: jobFinishNoteReducers,
    orderServiceDelete: orderServiceDeleteReducers,
    orderServiceExtraItemList: orderServiceExtraItemListReducers,
    importGet: importGetReducers,
    priceList: priceListReducers,
    propertyAdd: propertyAddReducers,
    propertyAutomationList: propertyAutomationListReducers,
    propertyImport: propertyImportReducers,
    propertyEdit: propertyEditReducers,
    propertyDelete: propertyDeleteReducers,
    propertyGet: propertyGetReducers,
    propertyDetails: propertyDetailsReducers,
    propertySources: propertySourcesReducers,
    propertyBusinessAccounts: propertyBusinessAccountsReducers,
    propertyCities: propertyCitiesReducers,
    propertyInstructions: propertyInstructionsReducers,
    propertyInstructionsImport: propertyInstructionsImportReducers,
    propertyInstructionsEdit: propertyInstructionsEditReducers,
    propertyTypes: propertyTypesReducers,
    propertyAssignStaffDialog: propertyAssignStaffDialogReducers,
    propertySpaceCategories: propertySpaceCategoriesReducers,
    properties: propertyListReducers,
    propertiesSync: propertiesSyncReducers,
    providerGet: providerGetReducers,
    providerList: providerListReducers,
    receivedInvoices: receivedInvoicesReducers,
    reportsBilling: reportsBillingReducers,
    reportsBillingDetails: reportsBillingDetailsReducers,
    reservationAdd: reservationAddReducers,
    reservationBlock: reservationBlockReducers,
    reservationDelete: reservationDeleteReducers,
    reservationDeleteAll: reservationDeleteAllReducers,
    reservationEdit: reservationEditReducers,
    reservationGet: reservationGetReducers,
    reservationList: reservationListReducers,
    reservationServices: reservationServicesReducers,
    reservationSync: reservationSyncReducers,
    reservationUnblock: reservationUnblockReducers,
    orderServiceExtraItemsEditForm: orderServiceExtraItemsEditFormReducers,
    paymentMethods: paymentMethodsReducers,
    paymentMethodsDefault: paymentMethodsDefaultReducers,
    paymentGateways: paymentGatewaysReducers,
    paymentPaypalSetup: paymentPaypalSetupReducers,
    paymentStripeSetup: paymentStripeSetupReducers,
    paymentStripeConfirmPayment: paymentStripeConfirmPaymentReducers,
    services: servicesReducers,
    teamGet: teamGetReducers,
    teamAddEditForm: teamAddEditFormReducers,
    teams: teamsReducers,
    teamsDeleteConfirm: teamsDeleteConfirmReducers,
    memberGet: memberGetReducers,
    memberEdit: memberEditReducers,
    memberPasswordEdit: memberPasswordEditReducers,
    memberList: memberListReducers,
    memberDelete: memberDeleteReducers,
    memberInvitationList: memberInvitationListReducers,
    memberInvitationDelete: memberInvitationDeleteReducers,
    membersInvitationsAdd: membersInvitationsAddReducers,
    membersInvitationsResend: membersInvitationsResendReducers,
    membersInvitationsImport: membersInvitationsImportReducers,
    subscriptionInvalid: subscriptionInvalidReducers,
    subscriptionPlans: subscriptionPlansReducers,
    subscriptionOverview: subscriptionOverviewReducers,
    invitationsAnswer: invitationsAnswerReducers,
    providersInvitationsAdd: providersInvitationsAddReducers,
    customersInvitationsAdd: customersInvitationsAddReducers,
    newPassword: newPasswordReducers,
    onboardingSaasAnnouncement: onboardingSaasAnnouncementReducers,
    onboardingSurvey: onboardingSurveyReducers,
    onboardingDemo: onboardingDemoReducers,
    onboardingFloating: onboardingFloatingReducers,
    user: userReducers,
    userProfile: userProfileReducers,
    snackbar: snackbarReducers,
    onboarding: onboardingReducers,
    updateRequest: updateRequestReducers,
    userLoggedInAs: userLoggedInAsReducers,
    cataloguePropertyOptionalItems: cataloguePropertyOptionalItemsReducers,
    register: registerReducers,
    resetPassword: resetPasswordReducers,
    stripeKYC: stripeKYCReducers,
    support: supportReducers
  })

export default createRootReducer
