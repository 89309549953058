import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import { fetchPropertyTypesApi } from '@doinn/vendor/src/api/property-types'
import {
  ONBOARDING_SERVICES_FETCH_FAILED,
  ONBOARDING_SERVICES_FETCH_REQUESTED,
  ONBOARDING_SERVICES_FETCH_SUCCEEDED
} from 'containers/onboarding/steps/Services/constants'

function* fetchPropertyTypeServices(action) {
  try {
    const response = yield call(fetchPropertyTypesApi, {
      ids: action.payload.ids,
      flags: ['template']
    })
    const propertyTypeServicesResponse = response.data
    yield put({
      type: ONBOARDING_SERVICES_FETCH_SUCCEEDED,
      payload: propertyTypeServicesResponse
    })
  } catch (e) {
    yield put({
      type: ONBOARDING_SERVICES_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* servicesSagas() {
  yield all([
    takeLatest(ONBOARDING_SERVICES_FETCH_REQUESTED, fetchPropertyTypeServices)
  ])
}
