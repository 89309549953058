import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getPhrasesApi, reportProblemApi } from 'api/evaluations'
import {
  JOB_REPORT_PROBLEM_FAILED,
  JOB_REPORT_PROBLEM_GET_PHRASES_FAILED,
  JOB_REPORT_PROBLEM_GET_PHRASES_REQUESTED,
  JOB_REPORT_PROBLEM_GET_PHRASES_SUCCEEDED,
  JOB_REPORT_PROBLEM_REQUESTED,
  JOB_REPORT_PROBLEM_SUCCEEDED
} from 'containers/job/report-problem/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import i18n from '@doinn/shared/src/config/i18n'

export function* reportProblem(action) {
  try {
    const params = {
      ...action.payload.params,
      phrase_ids: [action?.payload?.params?.phrase?.id]
    }
    const response = yield call(reportProblemApi, params)
    const responseData = response.data.data
    yield put({
      type: JOB_REPORT_PROBLEM_SUCCEEDED,
      payload: {
        data: responseData
      },
      snackbar: {
        variant: 'success',
        message: i18n.t('The problem was reported successfully!')
      }
    })
  } catch (e) {
    yield put({
      type: JOB_REPORT_PROBLEM_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export function* getPhrases(action) {
  try {
    const response = yield call(getPhrasesApi)

    yield put({
      type: JOB_REPORT_PROBLEM_GET_PHRASES_SUCCEEDED,
      payload: {
        phrases: response.data.data
      }
    })
  } catch (e) {
    yield put({
      type: JOB_REPORT_PROBLEM_GET_PHRASES_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* jobReportProblemSaga() {
  yield all([takeLatest(JOB_REPORT_PROBLEM_REQUESTED, reportProblem)])
  yield all([takeLatest(JOB_REPORT_PROBLEM_GET_PHRASES_REQUESTED, getPhrases)])
}
