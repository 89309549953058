import { all, call, put, takeLatest } from 'redux-saga/effects'
import { saveOboardingDemoApi } from '@doinn/shared/src/api/onboarding'
import {
  ONBOARDING_DEMO_FAILED,
  ONBOARDING_DEMO_REQUESTED,
  ONBOARDING_DEMO_SUCCEEDED
} from '@doinn/shared/src/containers/onboarding/demo/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import { omit } from 'lodash'

function* onboardingDemo(action) {
  try {
    const { params } = action.payload
    const { type } = params
    const data = omit(params, ['type'])
    const response = yield call(saveOboardingDemoApi, data, type)
    yield put({
      type: ONBOARDING_DEMO_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: ONBOARDING_DEMO_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* onboardingDemoSagas() {
  yield all([takeLatest(ONBOARDING_DEMO_REQUESTED, onboardingDemo)])
}
