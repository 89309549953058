import React, { useCallback } from 'react'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
  makeStyles
} from '@material-ui/core'
import { pick } from 'lodash'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { usePrevious } from '@doinn/shared/src/hooks'
import {
  close,
  newPassword
} from '@doinn/shared/src/containers/new-password/actions'
import { logout } from '@doinn/shared/src/containers/user/actions'
import { STATUS } from '@doinn/shared/src/containers/new-password/reducers'
import { newPasswordSchema } from '@doinn/shared/src/containers/new-password/validation'
import Dialog from '@doinn/shared/src/components/common/dialog/Dialog'
import DialogHeader from '@doinn/shared/src/components/common/dialog/DialogHeader'
import ButtonWithLoading from '@doinn/shared/src/components/common/ButtonWithLoading'

import Icon from '@doinn/shared/src/components/common/Icon'
import PasswordTextField from '@doinn/shared/src/components/common/inputs/PasswordTextField'

const INITIAL_VALUES_STATE = {
  password: '',
  confirmPassword: ''
}

const useStyles = makeStyles(theme => {
  return {
    dialogContent: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(0)
    },
    header: {
      paddingBottom: theme.spacing(0),
      marginBottom: theme.spacing(0)
    },
    actions: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  }
})

const NewPasswordDialog = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { isOpened, status } = useSelector(state => state.newPassword)
  const { loggedUser } = useSelector(state => state.user)
  const [initialValues, setInitialValues] = React.useState({
    ...INITIAL_VALUES_STATE
  })
  const previousIsOpened = usePrevious(isOpened)

  const isSaving = status === STATUS.LOADING

  React.useEffect(() => {
    if (previousIsOpened !== isOpened) {
      setInitialValues(INITIAL_VALUES_STATE)
    }
  }, [isOpened, previousIsOpened])

  const handleClose = useCallback(
    (event, reason) => {
      if (reason && reason === 'backdropClick') return
      dispatch(close())
    },
    [dispatch]
  )

  const handleClickSave = useCallback(
    values => {
      const params = {
        ...pick(values, ['password']),
        userId: loggedUser?.id
      }
      dispatch(newPassword(params))
    },
    [dispatch, loggedUser?.id]
  )

  const handleBackToLogin = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={newPasswordSchema}
      onSubmit={handleClickSave}
      enableReinitialize
    >
      {formProps => (
        <Dialog open={isOpened} onClose={handleClose} disableEscapeKeyDown>
          <DialogHeader
            classname={classes.header}
            title={t('Create a new password')}
          />
          <DialogContent className={classes.dialogContent}>
            <Typography component='div' variant='subtitle2'>
              <Box pb={1} sx={{ lineHeight: 'normal' }}>
                {t(
                  'Before using the Doinn platform, you must create a new password. The new password has to be different from the previous one.'
                )}
              </Box>
            </Typography>
            <Box pt={2}>
              <Typography component='div' variant='body1'>
                <Box pb={1} sx={{ lineHeight: 'normal' }}>
                  <b>{t('New password')}</b>
                </Box>
              </Typography>
              <PasswordTextField
                id={'field-password'}
                key={'field-password'}
                fieldName={'password'}
                isDisabled={isSaving}
                autoComplete='new-password'
              />
            </Box>
            <Box pt={2}>
              <Typography component='div' variant='body1'>
                <Box pb={1} sx={{ lineHeight: 'normal' }}>
                  <b>{t('Confirm new password')}</b>
                </Box>
              </Typography>
              <PasswordTextField
                id={'field-confirm-password'}
                key={'field-confirm-password'}
                fieldName={'confirmPassword'}
                isDisabled={isSaving}
                autoComplete='new-password'
              />
            </Box>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <ButtonWithLoading
              aria-label={t('Save password')}
              variant='contained'
              color='primary'
              edge='end'
              fullWidth
              isLoading={isSaving}
              disabled={!formProps.isValid || isSaving}
              onClick={formProps.handleSubmit}
            >
              {t('Save password')}
            </ButtonWithLoading>
            <Button
              aria-label={t('Back to Log in')}
              variant='text'
              color='secondary'
              startIcon={<Icon icon='back' />}
              onClick={handleBackToLogin}
              disabled={isSaving}
            >
              {t('Back to Log in')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

export default NewPasswordDialog
