import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  CHECKLIST_MY_IMPORT_FAILED,
  CHECKLIST_MY_IMPORT_REQUESTED,
  CHECKLIST_MY_IMPORT_SUCCEEDED
} from '@doinn/host/src/containers/checklist/my/import/constants'
import { importChecklistMyApi } from '@doinn/host/src/api/checklists'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* importChecklist(action) {
  try {
    const { file } = action.payload.params
    const response = yield call(importChecklistMyApi, {
      file: file
    })
    const responseData = response.data.data
    yield put({
      type: CHECKLIST_MY_IMPORT_SUCCEEDED,
      payload: responseData,
      snackbar: {
        variant: 'success',
        message: i18n.t('Checklist file have been imported successfully!')
      }
    })
  } catch (e) {
    if (e?.response?.status === 422) {
      yield put({
        type: CHECKLIST_MY_IMPORT_FAILED,
        payload: {
          error: {
            type: 'file',
            data: e.response.data.data
          }
        }
      })
    } else {
      yield put({
        type: CHECKLIST_MY_IMPORT_FAILED,
        payload: {
          error: {
            type: 'other'
          }
        },
        snackbar: {
          variant: 'error',
          message: getSnackbarErrorMessage(e)
        }
      })
    }
  }
}

export default function* checklistImportSaga() {
  yield all([takeLatest(CHECKLIST_MY_IMPORT_REQUESTED, importChecklist)])
}
