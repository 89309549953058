import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  downloadReceivedInvoiceApi,
  exportReceivedInvoiceApi,
  fetchReceivedInvoicesApi
} from '@doinn/shared/src/api/invoices'
import {
  RECEIVED_INVOICES_DOWNLOAD_FAILED,
  RECEIVED_INVOICES_DOWNLOAD_REQUESTED,
  RECEIVED_INVOICES_DOWNLOAD_SUCCEEDED,
  RECEIVED_INVOICES_EXPORT_FAILED,
  RECEIVED_INVOICES_EXPORT_REQUESTED,
  RECEIVED_INVOICES_EXPORT_SUCCEEDED,
  RECEIVED_INVOICES_FETCH_FAILED,
  RECEIVED_INVOICES_FETCH_MORE_FAILED,
  RECEIVED_INVOICES_FETCH_MORE_REQUESTED,
  RECEIVED_INVOICES_FETCH_MORE_SUCCEEDED,
  RECEIVED_INVOICES_FETCH_REQUESTED,
  RECEIVED_INVOICES_FETCH_SUCCEEDED
} from '@doinn/shared/src/containers/invoice/received/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchInvoices(action) {
  try {
    const response = yield call(fetchReceivedInvoicesApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: RECEIVED_INVOICES_FETCH_SUCCEEDED,
      payload: {
        data,
        meta
      }
    })
  } catch (e) {
    yield put({
      type: RECEIVED_INVOICES_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* fetchMoreInvoices(action) {
  try {
    const response = yield call(fetchReceivedInvoicesApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: RECEIVED_INVOICES_FETCH_MORE_SUCCEEDED,
      payload: {
        data,
        meta
      }
    })
  } catch (e) {
    yield put({
      type: RECEIVED_INVOICES_FETCH_MORE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* exportInvoice(action) {
  try {
    const { invoice } = action.payload.params
    const reportName = `doinn_${
      invoice?.document?.series
        ? invoice?.document?.series.replace('/', '_')
        : invoice.id
    }.xls`
    const { data } = yield call(exportReceivedInvoiceApi, { id: invoice.id })

    yield put({
      type: RECEIVED_INVOICES_EXPORT_SUCCEEDED,
      snackbar: {
        variant: 'success',
        message: i18n.t('The report has been downloaded successfully')
      },
      downloader: {
        data,
        type: 'application/vnd.ms-excel',
        name: reportName
      }
    })
  } catch (e) {
    yield put({
      type: RECEIVED_INVOICES_EXPORT_FAILED,
      snackbar: {
        variant: 'error',
        message: e.message
      }
    })
  }
}

function* downloadInvoice(action) {
  try {
    const { invoice } = action.payload.params
    const reportName = `doinn_${
      invoice?.document?.series
        ? invoice?.document?.series.replace('/', '_')
        : invoice.id
    }.pdf`
    const { data } = yield call(downloadReceivedInvoiceApi, {
      id: invoice.id
    })

    yield put({
      type: RECEIVED_INVOICES_DOWNLOAD_SUCCEEDED,
      snackbar: {
        variant: 'success',
        message: i18n.t('The report has been downloaded successfully')
      },
      downloader: {
        data,
        type: 'application/pdf',
        name: reportName
      }
    })
  } catch (e) {
    yield put({
      type: RECEIVED_INVOICES_DOWNLOAD_FAILED,
      snackbar: {
        variant: 'error',
        message: e.message
      }
    })
  }
}

export default function* receivedInvoicesSagas() {
  yield all([
    takeLatest(RECEIVED_INVOICES_FETCH_REQUESTED, fetchInvoices),
    takeLatest(RECEIVED_INVOICES_FETCH_MORE_REQUESTED, fetchMoreInvoices),
    takeLatest(RECEIVED_INVOICES_EXPORT_REQUESTED, exportInvoice),
    takeLatest(RECEIVED_INVOICES_DOWNLOAD_REQUESTED, downloadInvoice)
  ])
}
