import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchJobFiltersApi } from '@doinn/shared/src/api/job-filters'
import {
  JOB_FILTERS_FETCH_FAILED,
  JOB_FILTERS_FETCH_REQUESTED,
  JOB_FILTERS_FETCH_SUCCEEDED
} from './constants'

function* fetchJobFilters(action) {
  try {
    const response = yield call(fetchJobFiltersApi, action.payload.params)
    const { data } = response.data
    yield put({
      type: JOB_FILTERS_FETCH_SUCCEEDED,
      payload: { data }
    })
  } catch (e) {
    yield put({ type: JOB_FILTERS_FETCH_FAILED })
  }
}

export default function* jobFiltersSaga() {
  yield all([takeLatest(JOB_FILTERS_FETCH_REQUESTED, fetchJobFilters)])
}
