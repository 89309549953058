import { all, call, put, throttle } from 'redux-saga/effects'
import { fetchDayActivitiesApi } from '@doinn/vendor/src/api/calendar'
import {
  ACTIVITIES_FETCH_FAILED,
  ACTIVITIES_FETCH_REQUESTED,
  ACTIVITIES_FETCH_SUCCEEDED
} from '@doinn/vendor/src/containers/dashboard/dashlets/activities-list/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchDayActivities(action) {
  try {
    const { day } = action.payload.params
    const response = yield call(fetchDayActivitiesApi, day)
    yield put({
      type: ACTIVITIES_FETCH_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: ACTIVITIES_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* calendarSaga() {
  yield all([throttle(1000, ACTIVITIES_FETCH_REQUESTED, fetchDayActivities)])
}
