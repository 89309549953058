import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getCatalogItemApi } from '@doinn/host/src/api/catalog-items'
import {
  CATALOG_ITEM_GET_FAILED,
  CATALOG_ITEM_GET_REQUESTED,
  CATALOG_ITEM_GET_SUCCEEDED
} from '@doinn/host/src/containers/catalog/item/get/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* getCatalogItem(action) {
  try {
    const response = yield call(getCatalogItemApi, action.payload.catalogItemId)
    yield put({
      type: CATALOG_ITEM_GET_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: CATALOG_ITEM_GET_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* catalogItemGetSaga() {
  yield all([takeLatest(CATALOG_ITEM_GET_REQUESTED, getCatalogItem)])
}
