import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function fetchCityServicesApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/vendor/onboarding/city-services`, config)
}

export function saveCityServicesApi(params = {}) {
  const config = {}
  const body = {
    cities: []
  }
  for (const [cityId, services] of Object.entries(
    params.selectedCityServices
  )) {
    const city = {
      id: cityId,
      services: services
    }
    body.cities.push(city)
  }
  return DoinnApi.put(`/vendor/onboarding/city-services`, body, config)
}
