import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  addEmployeeApi,
  editEmployeeApi,
  editEmployeeImageApi
} from '@doinn/vendor/src/api/employee'
import {
  EMPLOYEE_ADD_EDIT_FORM_SAVE_FAILED,
  EMPLOYEE_ADD_EDIT_FORM_SAVE_REQUESTED,
  EMPLOYEE_ADD_EDIT_FORM_SAVE_SUCCEEDED,
  EMPLOYEE_EDIT_IMAGE_FAILED,
  EMPLOYEE_EDIT_IMAGE_REQUESTED,
  EMPLOYEE_EDIT_IMAGE_SUCCEEDED
} from '@doinn/vendor/src/containers/employee/add-edit/constants'
import { formatSystemDate } from '@doinn/shared/src/util/date-fns'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* addEmployee(employee) {
  try {
    const response = yield call(addEmployeeApi, {
      ...employee,
      profile_image: ''
    })
    const createdEmployee = response.data
    if (employee.profileImageFile) {
      yield call(editEmployeeImage, {
        id: createdEmployee.id,
        profile_image: employee.profileImageFile
      })
    }
    yield put({
      type: EMPLOYEE_ADD_EDIT_FORM_SAVE_SUCCEEDED,
      payload: createdEmployee
    })
  } catch (e) {
    yield put({
      type: EMPLOYEE_ADD_EDIT_FORM_SAVE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* editEmployee(employee) {
  try {
    const response = yield call(editEmployeeApi, {
      ...employee
    })
    const editedEmployee = response.data
    if (employee.profileImageFile) {
      yield call(editEmployeeImage, {
        id: employee.id,
        profile_image: employee.profileImageFile
      })
    }
    yield put({
      type: EMPLOYEE_ADD_EDIT_FORM_SAVE_SUCCEEDED,
      payload: editedEmployee
    })
  } catch (e) {
    yield put({
      type: EMPLOYEE_ADD_EDIT_FORM_SAVE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* editEmployeeImage(employee) {
  try {
    const response = yield call(editEmployeeImageApi, {
      id: employee.id,
      profile_image: employee.profile_image
    })
    const editedEmployee = response.data
    yield put({
      type: EMPLOYEE_EDIT_IMAGE_SUCCEEDED,
      payload: editedEmployee
    })
  } catch (e) {
    yield put({
      type: EMPLOYEE_EDIT_IMAGE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* saveEmployee(action) {
  try {
    let { employee } = action.payload
    if (employee.birthday) {
      employee = {
        ...employee,
        birthday: formatSystemDate(employee.birthday)
      }
    }
    if (employee.id) {
      yield call(editEmployee, employee)
    } else {
      yield call(addEmployee, employee)
    }
  } catch (e) {
    yield put({
      type: EMPLOYEE_ADD_EDIT_FORM_SAVE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* watchSaveEmployee() {
  yield takeLatest(EMPLOYEE_ADD_EDIT_FORM_SAVE_REQUESTED, saveEmployee)
}

function* watchEditEmployeeImage() {
  yield takeLatest(EMPLOYEE_EDIT_IMAGE_REQUESTED, editEmployeeImage)
}

export default function* employeeFormSaga() {
  yield all([watchSaveEmployee(), watchEditEmployeeImage()])
}
