import { cloneDeep, findIndex, orderBy, some } from 'lodash'
import {
  CHAT_MESSAGES_FETCH_FAILED,
  CHAT_MESSAGES_FETCH_MORE_FAILED,
  CHAT_MESSAGES_FETCH_MORE_REQUESTED,
  CHAT_MESSAGES_FETCH_MORE_SUCCEEDED,
  CHAT_MESSAGES_FETCH_REQUESTED,
  CHAT_MESSAGES_FETCH_SUCCEEDED,
  CHAT_MESSAGES_NEW_MESSAGE_ATTACHMENT_RECEIVED,
  CHAT_MESSAGES_NEW_MESSAGE_RECEIVED,
  CHAT_MESSAGES_READ_NEW_MESSAGES,
  CHAT_MESSAGES_RESEND_REQUESTED,
  CHAT_MESSAGES_SELECTED_CHAT_UPDATED,
  CHAT_MESSAGES_SEND_FAILED,
  CHAT_MESSAGES_SEND_REQUESTED,
  CHAT_MESSAGES_SEND_SUCCEEDED,
  CHAT_MESSAGE_DIALOG_PHOTOS_OPENED,
  CHAT_MESSAGE_STATUS_FAILED,
  CHAT_MESSAGE_STATUS_SENDING,
  CHAT_MESSAGE_STATUS_SENT,
  CHAT_MESSAGE_TYPE_PHOTOS
} from '@doinn/shared/src/containers/chats/messages/constants'
import {
  CHAT_ADD_SUCCEEDED,
  CHAT_PARTICIPANT_ADD_SUCCEEDED,
  CHAT_PARTICIPANT_REMOVE_SUCCEEDED,
  CHAT_UPDATE_SUCCEEDED
} from '@doinn/shared/src/containers/chats/constants'
import {
  CHAT_FOLLOW_SUCCEEDED,
  CHAT_UNFOLLOW_SUCCEEDED
} from '@doinn/shared/src/containers/chats/follow/constants'

const parseNewMessagesReceivedToState = (state, message) => {
  if (some(state.list, { id: message.id })) {
    return state
  }

  const optmisticMessageIndex = findIndex(state.list, {
    id: message.content.uiUuid
  })

  if (optmisticMessageIndex >= 0) {
    let newMessagesList = [...state.list]
    newMessagesList[optmisticMessageIndex] = message
    newMessagesList = orderBy(newMessagesList, ['timestamp'], ['desc'])
    return { ...state, list: [...newMessagesList] }
  }

  return { ...state, newMessages: [message, ...state.newMessages] }
}

const parseNewMessageAttachmentsReceivedToState = (state, message) => {
  const messageIndex = findIndex(state.list, {
    id: message.id
  })

  if (messageIndex >= 0) {
    const newMessagesList = [...state.list]
    newMessagesList[messageIndex] = message
    return { ...state, list: [...newMessagesList] }
  }

  return state
}

const parseUpdatedChatToState = (state, payload) => {
  const newSelectedChat = cloneDeep(state.selectedChat)

  if (newSelectedChat && newSelectedChat.id === payload.chat.id) {
    return { ...state, selectedChat: payload.chat }
  }

  return state
}

const parseSelectedChatIsFollowingToState = (state, payload) => {
  const newSelectedChat = cloneDeep(state.selectedChat)

  if (newSelectedChat && newSelectedChat.id === payload?.chat?.id) {
    return {
      ...state,
      selectedChat: {
        ...state.selectedChat,
        isFollowing: payload?.chat?.isFollowing
      }
    }
  }

  return state
}

const findMessageById = (state, messageId) => {
  const possiblePaths = ['list', 'newMessages']
  return possiblePaths.reduce((carry, path) => {
    const index = findIndex(state[path], {
      id: messageId
    })
    if (index >= 0) {
      return {
        ...carry,
        path: path,
        index: index
      }
    }
    return carry
  }, {})
}

const parseFailedMessageToState = (state, payload) => {
  if (!payload?.message?.uiUuid) {
    return state
  }
  const { path, index } = findMessageById(state, payload.message.uiUuid)
  if (path && index >= 0) {
    const clonedState = cloneDeep(state)
    clonedState[path][index].status = CHAT_MESSAGE_STATUS_FAILED
    return cloneDeep(clonedState)
  }
  return state
}

const parseResendMessageToState = (state, payload) => {
  if (!payload?.message?.uiUuid) {
    return state
  }
  const { path, index } = findMessageById(state, payload.message.uiUuid)
  if (path && index >= 0) {
    const clonedState = cloneDeep(state)
    clonedState[path][index].status = CHAT_MESSAGE_STATUS_SENDING
    return cloneDeep(clonedState)
  }
  return state
}

const createOptmisticMessage = payload => {
  const { chat, message, sender } = payload
  const now = new Date()
  const uiOptimisticMessage = {
    id: message.uiUuid,
    status: CHAT_MESSAGE_STATUS_SENDING,
    chat,
    content: {
      type: message.type,
      uiUuid: message.uiUuid,
      value: cloneDeep(message.value)
    },
    sender,
    createdAt: now,
    timestamp: now.getTime(),
    payload
  }

  if (message.type === CHAT_MESSAGE_TYPE_PHOTOS) {
    uiOptimisticMessage.content.value.photos.forEach(photo => {
      const url = URL.createObjectURL(photo.file)
      photo.url = url
      photo.thumbnailUrl = url
    })
  }

  return uiOptimisticMessage
}

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  LOADING_MORE: 'loading-more',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  selectedChat: null,
  list: [],
  newMessages: [],
  meta: {},
  status: STATUS.IDLE,
  dialogPhotos: {
    index: 0,
    photos: []
  }
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case CHAT_MESSAGES_FETCH_REQUESTED:
      return {
        ...state,
        list: [],
        newMessages: [],
        status: STATUS.LOADING
      }
    case CHAT_MESSAGES_FETCH_SUCCEEDED:
      return {
        ...state,
        list: [...payload.data],
        meta: { ...payload.meta },
        status: STATUS.SUCCESS
      }
    case CHAT_MESSAGES_FETCH_FAILED:
      return {
        ...state,
        ...initialState,
        status: STATUS.FAILURE
      }
    case CHAT_MESSAGES_FETCH_MORE_REQUESTED:
      return { ...state }
    case CHAT_MESSAGES_FETCH_MORE_SUCCEEDED:
      return {
        ...state,
        list: [...state.list, ...payload.data],
        meta: { ...payload.meta }
      }
    case CHAT_MESSAGES_FETCH_MORE_FAILED:
      return { ...state, ...initialState }
    case CHAT_MESSAGES_SELECTED_CHAT_UPDATED:
      return { ...state, selectedChat: payload.chat }
    case CHAT_ADD_SUCCEEDED:
    case CHAT_UPDATE_SUCCEEDED:
    case CHAT_PARTICIPANT_ADD_SUCCEEDED:
    case CHAT_PARTICIPANT_REMOVE_SUCCEEDED:
      return parseUpdatedChatToState(state, payload)
    case CHAT_FOLLOW_SUCCEEDED:
    case CHAT_UNFOLLOW_SUCCEEDED:
      return parseSelectedChatIsFollowingToState(state, payload)
    case CHAT_MESSAGES_SEND_SUCCEEDED:
    case CHAT_MESSAGES_NEW_MESSAGE_RECEIVED:
      payload.message.status = CHAT_MESSAGE_STATUS_SENT
      return parseNewMessagesReceivedToState(state, payload.message)
    case CHAT_MESSAGES_NEW_MESSAGE_ATTACHMENT_RECEIVED:
      return parseNewMessageAttachmentsReceivedToState(state, payload.message)
    case CHAT_MESSAGES_READ_NEW_MESSAGES:
      return {
        ...state,
        list: [...state.newMessages, ...state.list],
        newMessages: []
      }
    case CHAT_MESSAGE_DIALOG_PHOTOS_OPENED:
      return {
        ...state,
        dialogPhotos: {
          index: payload.index,
          photos: [...payload.photos]
        }
      }
    case CHAT_MESSAGES_SEND_REQUESTED:
      return parseNewMessagesReceivedToState(
        state,
        createOptmisticMessage(payload)
      )
    case CHAT_MESSAGES_RESEND_REQUESTED:
      return parseResendMessageToState(state, payload)
    case CHAT_MESSAGES_SEND_FAILED:
      return parseFailedMessageToState(state, payload)
    default:
      return state
  }
}
