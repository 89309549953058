import { all, call, put, takeLatest } from 'redux-saga/effects'
import { finishFloatingOnboardingApi } from '@doinn/shared/src/api/onboarding'
import {
  ONBOARDING_FLOATING_DIALOG_FINISH_FAILED,
  ONBOARDING_FLOATING_DIALOG_FINISH_REQUESTED,
  ONBOARDING_FLOATING_DIALOG_FINISH_SUCCEEDED
} from '@doinn/shared/src/containers/onboarding/floating/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* finishFloatingOnboarding(action) {
  try {
    const { params } = action.payload
    const response = yield call(finishFloatingOnboardingApi, params)
    yield put({
      type: ONBOARDING_FLOATING_DIALOG_FINISH_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: ONBOARDING_FLOATING_DIALOG_FINISH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* onboardingFloatingSagas() {
  yield all([
    takeLatest(
      ONBOARDING_FLOATING_DIALOG_FINISH_REQUESTED,
      finishFloatingOnboarding
    )
  ])
}
