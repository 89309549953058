import {
  MEMBERS_INVITATIONS_ADD_DIALOG_CLOSE,
  MEMBERS_INVITATIONS_ADD_DIALOG_OPEN,
  MEMBERS_INVITATIONS_ADD_FAILED,
  MEMBERS_INVITATIONS_ADD_REQUESTED,
  MEMBERS_INVITATIONS_ADD_SUCCEEDED,
  MEMBERS_ROLE_OPTIONS_FAILED,
  MEMBERS_ROLE_OPTIONS_REQUESTED,
  MEMBERS_ROLE_OPTIONS_SUCCEEDED
} from '@doinn/vendor/src/containers/member/invitation/add/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  errorMessage: '',
  isOpened: false,
  status: STATUS.IDLE,
  options: {
    role: {
      status: STATUS.IDLE,
      data: []
    }
  }
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case MEMBERS_INVITATIONS_ADD_DIALOG_OPEN:
      return {
        ...initialState,
        isOpened: true
      }
    case MEMBERS_INVITATIONS_ADD_DIALOG_CLOSE:
      return {
        ...initialState,
        isOpened: false
      }
    case MEMBERS_INVITATIONS_ADD_REQUESTED:
      return {
        ...state,
        errorMessage: '',
        status: STATUS.LOADING
      }
    case MEMBERS_INVITATIONS_ADD_SUCCEEDED:
      return {
        ...state,
        errorMessage: '',
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case MEMBERS_INVITATIONS_ADD_FAILED:
      return {
        ...state,
        errorMessage: payload.errorMessage,
        status: STATUS.FAILURE
      }
    case MEMBERS_ROLE_OPTIONS_REQUESTED:
      return {
        ...state,
        options: {
          ...state.options,
          role: {
            ...initialState.options.role,
            status: STATUS.LOADING
          }
        }
      }
    case MEMBERS_ROLE_OPTIONS_SUCCEEDED:
      return {
        ...state,
        options: {
          ...state.options,
          role: {
            status: STATUS.SUCCESS,
            data: payload.data
          }
        }
      }
    case MEMBERS_ROLE_OPTIONS_FAILED:
      return {
        ...state,
        options: {
          ...state.options,
          role: {
            ...state.options.role,
            status: STATUS.FAILURE
          }
        }
      }
    default:
      return state
  }
}
