import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getAutomationApi } from '@doinn/host/src/api/automations'
import {
  AUTOMATION_GET_FAILED,
  AUTOMATION_GET_REQUESTED,
  AUTOMATION_GET_SUCCEEDED
} from '@doinn/host/src/containers/automations/get/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* getAutomation(action) {
  try {
    const { id } = action.payload
    const response = yield call(getAutomationApi, { id })
    yield put({
      type: AUTOMATION_GET_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: AUTOMATION_GET_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* automationsSaga() {
  yield all([takeLatest(AUTOMATION_GET_REQUESTED, getAutomation)])
}
