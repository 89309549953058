import {
  CATALOGUES_CATEGORIES_FILTERS_FETCH_FAILED,
  CATALOGUES_CATEGORIES_FILTERS_FETCH_REQUESTED,
  CATALOGUES_CATEGORIES_FILTERS_FETCH_SUCCEEDED,
  CATALOGUES_CUSTOMERS_FILTERS_FETCH_FAILED,
  CATALOGUES_CUSTOMERS_FILTERS_FETCH_REQUESTED,
  CATALOGUES_CUSTOMERS_FILTERS_FETCH_SUCCEEDED,
  CATALOGUES_FILTERS_FETCH_REQUESTED,
  CATALOGUES_PROVIDERS_FILTERS_FETCH_FAILED,
  CATALOGUES_PROVIDERS_FILTERS_FETCH_REQUESTED,
  CATALOGUES_PROVIDERS_FILTERS_FETCH_SUCCEEDED
} from './constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  categories: {
    data: [],
    status: STATUS.IDLE
  },
  customers: {
    data: [],
    status: STATUS.IDLE
  },
  providers: {
    data: [],
    status: STATUS.IDLE
  }
}

const mapCategories = categories =>
  categories.map(category => ({
    ...category,
    id: category.categories.map(c => c.id).join(',')
  }))

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case CATALOGUES_FILTERS_FETCH_REQUESTED:
      return {
        ...initialState,
        categories: {
          ...initialState.categories,
          status: STATUS.LOADING
        },
        customers: {
          ...initialState.customers,
          status: STATUS.LOADING
        },
        providers: {
          ...initialState.providers,
          status: STATUS.LOADING
        }
      }
    case CATALOGUES_CATEGORIES_FILTERS_FETCH_REQUESTED:
      return {
        ...state,
        categories: {
          ...initialState.categories,
          status: STATUS.LOADING
        }
      }
    case CATALOGUES_CATEGORIES_FILTERS_FETCH_FAILED:
      return {
        ...state,
        categories: {
          ...state.categories,
          status: STATUS.FAILURE
        }
      }
    case CATALOGUES_CATEGORIES_FILTERS_FETCH_SUCCEEDED:
      return {
        ...state,
        categories: {
          ...state.categories,
          data: mapCategories(payload.data),
          status: STATUS.SUCCESS
        }
      }
    case CATALOGUES_CUSTOMERS_FILTERS_FETCH_REQUESTED:
      return {
        ...state,
        customers: {
          ...initialState.customers,
          status: STATUS.LOADING
        }
      }
    case CATALOGUES_CUSTOMERS_FILTERS_FETCH_FAILED:
      return {
        ...state,
        customers: {
          ...state.customers,
          status: STATUS.FAILURE
        }
      }
    case CATALOGUES_CUSTOMERS_FILTERS_FETCH_SUCCEEDED:
      return {
        ...state,
        customers: {
          ...state.customers,
          data: payload.data,
          status: STATUS.SUCCESS
        }
      }
    case CATALOGUES_PROVIDERS_FILTERS_FETCH_REQUESTED:
      return {
        ...state,
        providers: {
          ...initialState.providers,
          status: STATUS.LOADING
        }
      }
    case CATALOGUES_PROVIDERS_FILTERS_FETCH_FAILED:
      return {
        ...state,
        providers: {
          ...state.providers,
          status: STATUS.FAILURE
        }
      }
    case CATALOGUES_PROVIDERS_FILTERS_FETCH_SUCCEEDED:
      return {
        ...state,
        providers: {
          ...state.providers,
          data: payload.data,
          status: STATUS.SUCCESS
        }
      }
    default:
      return state
  }
}
