import DoinnApi, { DoinnBlobApi } from '@doinn/shared/src/config/doinnApi'

export function fetchSentInvoicesApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/invoices/sent`, config)
}

export function exportSentInvoiceApi(params = {}) {
  const config = {}
  return DoinnBlobApi.get(`/invoices/sent/${params.id}/export`, config)
}

export function uploadInvoiceApi(params) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  const formData = new window.FormData()
  const { file, invoiceId } = params

  if (file) {
    formData.append('file', file)
  }

  formData.append('_method', 'PATCH')
  return DoinnApi.post(`/invoices/sent/${invoiceId}/upload`, formData, config)
}

export function fetchReceivedInvoicesApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/invoices/received`, config)
}

export function exportReceivedInvoiceApi(params = {}) {
  const config = {}
  return DoinnBlobApi.get(`/invoices/received/${params.id}/export`, config)
}

export function downloadReceivedInvoiceApi(params = {}) {
  const config = {}
  return DoinnBlobApi.get(`/invoices/received/${params.id}/download`, config)
}
