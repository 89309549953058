import React, { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { BASE_URL } from 'config/env'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'
import { cloneDeep, isEqual as isObjEqual } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import { Box, Container, makeStyles } from '@material-ui/core'

import {
  useDidMount,
  useFilterState,
  useOpenState,
  usePrevious
} from '@doinn/shared/src/hooks'
import {
  getQueryStringObject,
  updateURLParams
} from '@doinn/shared/src/util/url'
import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import { parseAppliedFilters } from '@doinn/shared/src/util/filters'
import Alerts from '@doinn/shared/src/components/app/Alerts'
import MainContent from '@doinn/shared/src/components/app/MainContent'
import TemplateBlank from '@doinn/shared/src/components/templates/TemplateBlank'

const CheckoutContainer = loadable(() =>
  lazyRetry(() => import('@doinn/vendor/src/containers/checkout/Checkout'))
)

const defaultFilters = {
  uuid: 'id'
}

const getQueryOrSavedFilters = location => {
  const queryFilters = getQueryStringObject(location.search)
  return parseAppliedFilters(defaultFilters, queryFilters)
}

const useStyles = makeStyles(theme => ({
  container: {
    minWidth: 0,
    backgroundColor: theme.palette.common.white
  },
  wrapper: {
    backgroundColor: theme.palette.common.white,
    height: '100vh'
  }
}))

const Catalogues = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const previousLocation = usePrevious(location)
  const [justChangedLocation, setJustChangedLocation] = React.useState(false)
  const {
    appliedFilters,
    hasAppliedFiltersChanges,
    onChangeAppliedFilters,
    onChangeSelectedFilters
  } = useFilterState(cloneDeep(defaultFilters))
  const { justClosed: justClosedFilters, justOpened: justOpenedFilters } =
    useOpenState(false)

  useDidMount(() => {
    onChangeAppliedFilters(getQueryOrSavedFilters(location))
  })

  React.useEffect(() => {
    if (!previousLocation) return
    setJustChangedLocation(
      !isObjEqual(location.search, previousLocation.search)
    )
  }, [location, previousLocation])

  React.useEffect(() => {
    if (!justChangedLocation) return
    const savedFilters = getQueryOrSavedFilters(location)
    const shouldUpdateAppliedFilters = !isObjEqual(appliedFilters, savedFilters)
    if (shouldUpdateAppliedFilters) {
      onChangeAppliedFilters(savedFilters)
    }
  }, [appliedFilters, justChangedLocation, location, onChangeAppliedFilters])

  React.useEffect(() => {
    if (justOpenedFilters) {
      onChangeSelectedFilters(appliedFilters)
    }
  }, [appliedFilters, justOpenedFilters, onChangeSelectedFilters])

  React.useEffect(() => {
    if (justClosedFilters) {
      onChangeSelectedFilters(appliedFilters)
    }
  }, [appliedFilters, justClosedFilters, onChangeSelectedFilters])

  React.useEffect(() => {
    const shouldUpdateUrl =
      hasAppliedFiltersChanges &&
      !isObjEqual(appliedFilters, getQueryOrSavedFilters(location))
    if (shouldUpdateUrl) {
      updateURLParams(appliedFilters, history)
    }
  }, [appliedFilters, hasAppliedFiltersChanges, history, location])

  const handleChangeAppliedFilters = useCallback(
    filters => {
      onChangeAppliedFilters(filters)
    },
    [onChangeAppliedFilters]
  )

  return (
    <TemplateBlank>
      <Helmet defaultTitle='Doinn' titleTemplate='%s - Doinn'>
        <meta charSet='utf-8' />
      </Helmet>
      <Box className={classes.wrapper}>
        <Container maxWidth='lg' className={classes.container}>
          <Alerts header baseUrl={BASE_URL} />
          <MainContent>
            <Helmet>
              <title>{t('Checkout')}</title>
            </Helmet>
            <React.Suspense fallback={<div />}>
              <CheckoutContainer
                filters={appliedFilters}
                onChangeFilters={handleChangeAppliedFilters}
              />
            </React.Suspense>
          </MainContent>
        </Container>
      </Box>
    </TemplateBlank>
  )
}

export default Catalogues
