import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { throttle } from 'lodash'
import {
  Box,
  Chip,
  Grid,
  ListSubheader,
  Paper,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Icon from '@doinn/shared/src/components/common/Icon'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.paper
  },
  listSubheader: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    width: '100%'
  },
  autocomplete: {},
  chipsContainer: {
    paddingTop: theme.spacing(1)
  },
  chipsItem: {
    maxWidth: '100%',
    '& > div': {
      maxWidth: '100%'
    }
  }
}))

const AutocompleteMultiple = ({
  className,
  disabled,
  hideAutocomplete,
  label,
  subheader,
  filterName,
  selectedItems,
  optionRenderer,
  optionLabelName,
  noOptionsText,
  onChange,
  onInputChange,
  getAutocompleteOptions
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const noOptionsResultsText = noOptionsText || t('No results')

  const [options, setOptions] = React.useState([])
  const [inputValue, setInputValue] = React.useState('')

  const defaultOptionRenderer = option => {
    return (
      <Grid container alignItems='center'>
        <Grid item xs>
          <Typography variant='subtitle2'>
            <b>{option.name}</b>
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const renderOption = optionRenderer || defaultOptionRenderer

  const handleCloseAutocomplete = event => {
    setInputValue('')
  }

  const handleChangeInputValue = event => {
    const value = event.target.value
    setInputValue(value)
    onInputChange && onInputChange(value)
  }

  const handleChangeAutocomplete = (event, values) => {
    onChange && values && onChange(filterName, values)
    setInputValue('')
  }

  const memoizedGetOptions = React.useMemo(
    () =>
      throttle(async (filterName, value, callback) => {
        const newOptions = await getAutocompleteOptions(filterName, value)
        callback(newOptions)
      }, 200),
    [getAutocompleteOptions]
  )

  React.useEffect(() => {
    let active = true

    memoizedGetOptions(filterName, inputValue, results => {
      if (active) {
        setOptions(results || [])
      }
    })

    return () => {
      active = false
    }
  }, [inputValue, filterName, memoizedGetOptions])

  const handleRemoveSelectedItem = item => () => {
    onChange && item && onChange(filterName, item)
  }

  const listSubheader = subheader ? (
    <ListSubheader component='div' className={classes.listSubheader}>
      {subheader}
    </ListSubheader>
  ) : null

  return (
    <Box py={1} className={classes.container}>
      {listSubheader}
      <Box px={3}>
        {hideAutocomplete ? null : (
          <Autocomplete
            getOptionLabel={option =>
              typeof option === 'string' ? option : option[optionLabelName]
            }
            disabled={disabled}
            filterOptions={x => x}
            freeSolo
            options={options}
            includeInputInList
            renderInput={params => (
              <TextField
                {...params}
                margin='none'
                label={label}
                fullWidth
                onChange={handleChangeInputValue}
              />
            )}
            noOptionsText={noOptionsResultsText}
            renderOption={renderOption}
            PaperComponent={props => {
              const { children, ...otherProps } = props
              return <Paper {...otherProps}>{children}</Paper>
            }}
            defaultValue=''
            inputValue={inputValue}
            className={`${className} ${classes.autocomplete}`}
            onChange={handleChangeAutocomplete}
            onClose={handleCloseAutocomplete}
          />
        )}
        {selectedItems.length > 0 ? (
          <Grid container spacing={1} className={classes.chipsContainer}>
            {selectedItems.map((item, index) => (
              <Grid item key={index} className={classes.chipsItem}>
                <Chip
                  label={item[optionLabelName]}
                  variant='outlined'
                  deleteIcon={<Icon icon='close' />}
                  onDelete={disabled ? null : handleRemoveSelectedItem(item)}
                />
              </Grid>
            ))}
          </Grid>
        ) : null}
      </Box>
    </Box>
  )
}

AutocompleteMultiple.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hideAutocomplete: PropTypes.bool,
  label: PropTypes.string,
  selectedItems: PropTypes.arrayOf(PropTypes.object),
  noOptionsText: PropTypes.string,
  optionLabelName: PropTypes.string,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  getAutocompleteOptions: PropTypes.func
}

AutocompleteMultiple.defaultProps = {
  optionLabelName: 'name',
  className: '',
  disabled: false,
  hideAutocomplete: false,
  selectedItems: [],
  getAutocompleteOptions: () => null
}

export default AutocompleteMultiple
