import React, { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'
import { endOfMonth, startOfMonth } from 'date-fns'
import { first, isEqual as isObjEqual } from 'lodash'

import {
  useDidMount,
  useFilterState,
  useOpenState,
  usePrevious
} from '@doinn/shared/src/hooks'
import {
  getQueryStringObject,
  updateURLParams
} from '@doinn/shared/src/util/url'
import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import { formatSystemDate } from '@doinn/shared/src/util/date-fns'
import { parseAppliedFilters } from '@doinn/shared/src/util/filters'
import { countAppliedFilters } from 'containers/reports/billing/util'
import FiltersDialog from '@doinn/shared/src/components/common/FiltersDialog'
import FiltersButton from '@doinn/shared/src/components/common/FiltersButton'
import ReportsListFiltersContent from 'containers/reports/billing/ListFiltersContent'
import { getSourceOptionsFromBusinessIntentions } from 'containers/reports/billing/ListFiltersSourceBy'
import TemplateDefault from 'components/TemplateDefault'
import HelperCard from '@doinn/shared/src/components/common/helper/HelperCard'

const ReportsContainer = loadable(() =>
  lazyRetry(() => import('containers/reports/billing/Reports'))
)

const getDefaultFilters = user => {
  const nowDate = new Date()
  const startOfMonthDate = startOfMonth(nowDate)
  const endOfMonthDate = endOfMonth(nowDate)
  const businessIntentions = user?.business?.intentions
  const reportTabs = []

  if (businessIntentions?.sell) {
    reportTabs.push('customers')
  }

  if (businessIntentions?.buy) {
    reportTabs.push('providers')
  }

  const reportOf = first(reportTabs)
  const reportBy = reportOf === 'customers' ? 'hosts' : 'providers'
  const firstSourceOption = first(
    getSourceOptionsFromBusinessIntentions(businessIntentions, reportOf)
  )
  const sourceServicesBy = firstSourceOption?.id ?? 'doinn'

  return {
    reportOf: reportOf,
    reportBy: reportBy,
    periodicity: 'monthly',
    period: 'current_month',
    page: 1,
    start: formatSystemDate(startOfMonthDate),
    end: formatSystemDate(endOfMonthDate),
    sourceServicesBy
  }
}

const getQueryOrSavedFilters = (location, user) => {
  const queryFilters = getQueryStringObject(location.search)
  return parseAppliedFilters(getDefaultFilters(user), queryFilters)
}

const Reports = ({ location, history }) => {
  const loggedUser = useSelector(state => state?.user?.loggedUser)
  const { t } = useTranslation()
  const previousLocation = usePrevious(location)
  const [justChangedLocation, setJustChangedLocation] = React.useState(false)
  const {
    appliedFilters,
    selectedFilters,
    hasAppliedFiltersChanges,
    onChangeAppliedFilters,
    onChangeSelectedFilters,
    onClearSelectedFilters,
    onConfirmSelectedFilters
  } = useFilterState(getDefaultFilters(loggedUser))
  const {
    justClosed: justClosedFilters,
    justOpened: justOpenedFilters,
    onClose: onCloseFilters,
    onOpen: onOpenFilters,
    open: isFiltersOpened
  } = useOpenState(false)
  const [totalAppliedFilters, setTotalAppliedFilters] = React.useState(
    countAppliedFilters(appliedFilters)
  )

  useDidMount(() => {
    onChangeAppliedFilters(getQueryOrSavedFilters(location, loggedUser))
  })

  React.useEffect(() => {
    if (!previousLocation) return
    setJustChangedLocation(
      !isObjEqual(location.search, previousLocation.search)
    )
  }, [location, previousLocation])

  React.useEffect(() => {
    if (!justChangedLocation) return
    const savedFilters = getQueryOrSavedFilters(location, loggedUser)
    const shouldUpdateAppliedFilters = !isObjEqual(appliedFilters, savedFilters)
    if (shouldUpdateAppliedFilters) {
      onChangeAppliedFilters(savedFilters)
    }
  }, [
    appliedFilters,
    justChangedLocation,
    location,
    onChangeAppliedFilters,
    loggedUser
  ])

  React.useEffect(() => {
    if (justOpenedFilters) {
      onChangeSelectedFilters(appliedFilters)
    }
  }, [appliedFilters, justOpenedFilters, onChangeSelectedFilters])

  React.useEffect(() => {
    if (justClosedFilters) {
      onChangeSelectedFilters(appliedFilters)
    }
  }, [appliedFilters, justClosedFilters, onChangeSelectedFilters])

  React.useEffect(() => {
    const shouldUpdateUrl =
      hasAppliedFiltersChanges &&
      !isObjEqual(appliedFilters, getQueryOrSavedFilters(location, loggedUser))
    if (shouldUpdateUrl) {
      updateURLParams(appliedFilters, history)
    }
  }, [appliedFilters, hasAppliedFiltersChanges, history, location, loggedUser])

  React.useEffect(() => {
    setTotalAppliedFilters(countAppliedFilters(appliedFilters))
  }, [appliedFilters])

  const handleConfirmSelectedFilters = useCallback(() => {
    onConfirmSelectedFilters()
    onCloseFilters()
  }, [onCloseFilters, onConfirmSelectedFilters])

  const handleChangeAppliedFilters = useCallback(
    filters => {
      onChangeAppliedFilters(filters)
    },
    [onChangeAppliedFilters]
  )

  return (
    <TemplateDefault
      title={t('Reports')}
      filtersContent={
        <FiltersButton
          variant='icon'
          totalAppliedFilters={totalAppliedFilters}
          onOpen={onOpenFilters}
        />
      }
      helperContent={<HelperCard />}
    >
      <Helmet>
        <title>{t('Reports')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <ReportsContainer
          filters={appliedFilters}
          onChangeFilters={handleChangeAppliedFilters}
        />
      </React.Suspense>
      <FiltersDialog
        open={isFiltersOpened}
        onClear={onClearSelectedFilters}
        onClose={onCloseFilters}
        onConfirm={handleConfirmSelectedFilters}
      >
        <ReportsListFiltersContent
          filters={selectedFilters}
          onChangeFilters={onChangeSelectedFilters}
        />
      </FiltersDialog>
    </TemplateDefault>
  )
}

export default Reports
