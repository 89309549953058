import { combineReducers } from 'redux'
import jobListReducers, {
  initialState as initialStateJobList
} from 'containers/dashboard/dashlets/job-list/reducers'
import summaryServicesReducers, {
  initialState as initialStateSummaryServices
} from 'containers/dashboard/dashlets/summary-services/reducers'
import reportsReducers, {
  initialState as initialStateReports
} from 'containers/dashboard/dashlets/reports/reducers'
import calendarReducers, {
  initialState as initialStateCalendar
} from 'containers/dashboard/dashlets/calendar/reducers'
import activitiesReducers, {
  initialState as initialStateActivities
} from 'containers/dashboard/dashlets/activities-list/reducers'

export const initialState = {
  jobList: initialStateJobList,
  summaryServices: initialStateSummaryServices,
  reports: initialStateReports,
  calendar: initialStateCalendar,
  activities: initialStateActivities
}

export default combineReducers({
  jobList: jobListReducers,
  summaryServices: summaryServicesReducers,
  reports: reportsReducers,
  calendar: calendarReducers,
  activities: activitiesReducers
})
