import { cloneDeep } from 'lodash'
import {
  CATALOGUE_CLEAR_MY_SELECTED_REQUESTED,
  CATALOGUE_GET_MY_FAILED,
  CATALOGUE_GET_MY_ITEMS_FAILED,
  CATALOGUE_GET_MY_ITEMS_REQUESTED,
  CATALOGUE_GET_MY_ITEMS_SUCCEEDED,
  CATALOGUE_GET_MY_PRICE_LIST_DOWNLOAD_FAILED,
  CATALOGUE_GET_MY_PRICE_LIST_DOWNLOAD_REQUESTED,
  CATALOGUE_GET_MY_PRICE_LIST_DOWNLOAD_SUCCEEDED,
  CATALOGUE_GET_MY_REQUESTED,
  CATALOGUE_GET_MY_SUCCEEDED
} from '@doinn/shared/src/containers/catalogue/my/get/constants'
import { CATALOGUE_MY_EDIT_SETTINGS_SUCCEEDED } from '@doinn/shared/src/containers/catalogue/my/edit-settings/constants'
import { CATALOGUE_MY_EDIT_PRICES_SUCCEEDED } from '@doinn/shared/src/containers/catalogue/my/edit-prices/constants'
import { CATALOGUE_MY_EDIT_DESCRIPTION_SUCCEEDED } from '@doinn/shared/src/containers/catalogue/my/edit-description/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  LOADING_ITEMS: 'loading-items',
  SUCCESS: 'success',
  FAILURE: 'failure',
  DOWNLOADING: 'downloading'
}

export const initialState = {
  item: null,
  catalogueItems: null,
  status: STATUS.IDLE
}

const parseEditedCatalogueState = (state, payload) => {
  const newState = cloneDeep(state)
  if (state?.item?.id) {
    const catalogue = cloneDeep(payload)
    if (catalogue?.id && catalogue?.id === state?.item?.id) {
      newState.item = catalogue
    }
  }
  return newState
}

const parseCatalogueItemsState = (state, data) => {
  const items = [...data]
  const catalogueItems = items.reduce((carry, item) => {
    const serviceId = item?.serviceItem?.service?.id
    const targetService = carry.find(service => service?.id === serviceId) || {
      id: serviceId,
      serviceItems: []
    }
    const otherServices = carry.filter(service => service?.id !== serviceId)
    const targetItem = {
      ...item,
      serviceItemId: item?.serviceItem?.id
    }
    targetService.serviceItems.push(targetItem)
    return [{ ...targetService }, ...otherServices]
  }, [])

  return {
    ...state,
    catalogueItems,
    status: STATUS.SUCCESS
  }
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case CATALOGUE_GET_MY_REQUESTED:
      return {
        ...initialState,
        status: STATUS.LOADING
      }
    case CATALOGUE_GET_MY_SUCCEEDED:
      return {
        ...state,
        item: { ...payload.data },
        status: STATUS.SUCCESS
      }
    case CATALOGUE_GET_MY_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case CATALOGUE_CLEAR_MY_SELECTED_REQUESTED:
      return {
        ...initialState
      }
    case CATALOGUE_MY_EDIT_SETTINGS_SUCCEEDED:
      return {
        ...parseEditedCatalogueState(state, payload?.data)
      }
    case CATALOGUE_MY_EDIT_PRICES_SUCCEEDED:
      return {
        ...parseEditedCatalogueState(state, payload?.data)
      }
    case CATALOGUE_MY_EDIT_DESCRIPTION_SUCCEEDED:
      return {
        ...parseEditedCatalogueState(state, payload?.data)
      }
    case CATALOGUE_GET_MY_ITEMS_REQUESTED:
      return {
        ...state,
        catalogueItems: null,
        status: STATUS.LOADING_ITEMS
      }
    case CATALOGUE_GET_MY_ITEMS_SUCCEEDED:
      return {
        ...parseCatalogueItemsState(state, payload?.data)
      }
    case CATALOGUE_GET_MY_ITEMS_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case CATALOGUE_GET_MY_PRICE_LIST_DOWNLOAD_REQUESTED:
      return {
        ...state,
        status: `${payload?.params?.id}-${STATUS.DOWNLOADING}`
      }
    case CATALOGUE_GET_MY_PRICE_LIST_DOWNLOAD_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS
      }
    case CATALOGUE_GET_MY_PRICE_LIST_DOWNLOAD_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
