import React, { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'
import { cloneDeep, isEqual as isObjEqual } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'

import {
  useDidMount,
  useFilterState,
  useOpenState,
  usePrevious
} from '@doinn/shared/src/hooks'
import {
  getQueryStringObject,
  updateURLParams
} from '@doinn/shared/src/util/url'
import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import { parseAppliedFilters } from '@doinn/shared/src/util/filters'
import TemplateDefault from 'components/TemplateDefault'
import HelperCard from '@doinn/shared/src/components/common/helper/HelperCard'

const StaffContainer = loadable(() =>
  lazyRetry(() => import('@doinn/vendor/src/containers/staff/Container'))
)

const defaultFilters = {
  invite: 'all'
}

const parseFilters = filters => {
  const parsedFilters = cloneDeep(filters)
  if (parsedFilters.invite === 'all') {
    delete parsedFilters.invite
  }
  return parsedFilters
}

const getQueryOrSavedFilters = location => {
  const queryFilters = getQueryStringObject(location.search)
  return parseAppliedFilters(defaultFilters, queryFilters)
}

const Staff = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const previousLocation = usePrevious(location)
  const [justChangedLocation, setJustChangedLocation] = React.useState(false)
  const {
    appliedFilters,
    hasAppliedFiltersChanges,
    onChangeAppliedFilters,
    onChangeSelectedFilters
  } = useFilterState(cloneDeep(defaultFilters))
  const { justClosed: justClosedFilters, justOpened: justOpenedFilters } =
    useOpenState(false)

  useDidMount(() => {
    onChangeAppliedFilters(getQueryOrSavedFilters(location))
  })

  React.useEffect(() => {
    if (!previousLocation) return
    setJustChangedLocation(
      !isObjEqual(location.search, previousLocation.search)
    )
  }, [location, previousLocation])

  React.useEffect(() => {
    if (!justChangedLocation) return
    const savedFilters = getQueryOrSavedFilters(location)
    const shouldUpdateAppliedFilters = !isObjEqual(appliedFilters, savedFilters)
    if (shouldUpdateAppliedFilters) {
      onChangeAppliedFilters(savedFilters)
    }
  }, [appliedFilters, justChangedLocation, location, onChangeAppliedFilters])

  React.useEffect(() => {
    if (justOpenedFilters) {
      onChangeSelectedFilters(appliedFilters)
    }
  }, [appliedFilters, justOpenedFilters, onChangeSelectedFilters])

  React.useEffect(() => {
    if (justClosedFilters) {
      onChangeSelectedFilters(appliedFilters)
    }
  }, [appliedFilters, justClosedFilters, onChangeSelectedFilters])

  React.useEffect(() => {
    const shouldUpdateUrl =
      hasAppliedFiltersChanges &&
      !isObjEqual(appliedFilters, getQueryOrSavedFilters(location))
    if (shouldUpdateUrl) {
      updateURLParams(parseFilters(appliedFilters), history)
    }
  }, [appliedFilters, hasAppliedFiltersChanges, history, location])

  const handleChangeAppliedFilters = useCallback(
    filters => {
      onChangeAppliedFilters(parseFilters(filters))
    },
    [onChangeAppliedFilters]
  )

  return (
    <TemplateDefault title={t('People')} helperContent={<HelperCard />}>
      <Helmet>
        <title>{t('People')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <StaffContainer
          filters={appliedFilters}
          onChangeFilters={handleChangeAppliedFilters}
        />
      </React.Suspense>
    </TemplateDefault>
  )
}

export default Staff
