import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import { IconButton, makeStyles } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { HUBSPOT_TRACKING_CODE } from 'config/env'

import { usePrevious } from '@doinn/shared/src/hooks'
import useAppDetector from '@doinn/shared/src/hooks/useAppDetector'
import Icon from '@doinn/shared/src/components/common/Icon'

const HubspotConversationsContext = createContext()

const HUBSPOT_INLINE_EMBED_ELEMENT_ID =
  'hubspot-conversations-inline-embed-selector'

const useStyles = makeStyles(theme => ({
  chatWidgetContainer: ({ isOpen }) => ({
    overflow: 'hidden',
    zIndex: 2147483647,
    position: 'fixed',
    top: 75,
    right: theme.spacing(2),
    height: 500,
    width: 376,
    display: isOpen ? 'block' : 'none',
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 5px 20px rgb(0 0 0 / 10%)',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      right: 0
    },
    '& #hubspot-conversations-inline-parent': {
      width: '100%',
      height: '100%'
    },
    '& #hubspot-conversations-inline-iframe': {
      width: '100%',
      height: '100%',
      border: 'none'
    }
  }),
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 10,
    color: theme.palette.common.white
  }
}))

const HubspotConversationsProvider = ({ children }) => {
  const isApp = useAppDetector()
  const location = useLocation()
  const [isReady, setIsReady] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0)
  const classes = useStyles({ isOpen })
  const previousPathname = usePrevious(location?.pathname)
  const { loggedUser } = useSelector(state => state.user)
  const { identificationToken } = useSelector(state => state.support)
  const chatWidgetRef = useRef(null)
  const openButtonRef = useRef(null)

  const hideWidget = useCallback(() => {
    setIsOpen(false)
  }, [])

  const showWidget = useCallback(() => {
    if (!isReady) return

    window.HubSpotConversations.widget.load()
    window.HubSpotConversations.widget.open()

    setIsOpen(true)
  }, [isReady])

  const toggleWidget = useCallback(
    event => {
      event.stopPropagation()

      if (isOpen) {
        hideWidget()
      } else {
        showWidget()
      }
    },
    [hideWidget, isOpen, showWidget]
  )

  const onConversationsReady = useCallback(() => {
    setIsReady(true)
  }, [])

  const handleDocumentClick = useCallback(
    event => {
      if (!isReady || !isOpen) {
        return
      }

      if (
        chatWidgetRef.current &&
        !chatWidgetRef.current.contains(event.target) &&
        openButtonRef.current &&
        !openButtonRef.current.contains(event.target) &&
        isOpen
      ) {
        event.stopPropagation()
        hideWidget()
      }
    },
    [hideWidget, isOpen, isReady]
  )

  useEffect(
    function addDocumentClickListenter() {
      if (isReady) {
        document.addEventListener('click', handleDocumentClick)
      }
      return () => {
        document.removeEventListener('click', handleDocumentClick)
      }
    },
    [isReady, handleDocumentClick]
  )

  useEffect(
    function init() {
      if (isApp || !loggedUser?.email || !identificationToken) return

      if (window.HubSpotConversations) {
        onConversationsReady()
        return
      }
      const script = document.createElement('script')
      script.src = `//js.hs-scripts.com/${HUBSPOT_TRACKING_CODE}.js`
      script.async = true

      window.hsConversationsSettings = {
        loadImmediately: false,
        inlineEmbedSelector: '#hubspot-conversations-inline-embed-selector',
        identificationEmail: loggedUser?.email,
        identificationToken
      }
      document.body.appendChild(script)
      window.hsConversationsOnReady = [onConversationsReady]

      return () => {
        window.HubSpotConversations.clear({ resetWidget: true })
        document.body.removeChild(script)
        window.hsConversationsOnReady = []
      }
    },
    [identificationToken, isApp, loggedUser?.email, onConversationsReady]
  )

  useEffect(
    function addEventListeners() {
      if (!isReady) return

      const listener = payload => {
        setUnreadMessagesCount(payload?.unreadCount)
      }

      window.HubSpotConversations.on('unreadConversationCountChanged', listener)

      return () => {
        window.HubSpotConversations.off(
          'unreadConversationCountChanged',
          listener
        )
      }
    },
    [isReady]
  )

  useEffect(
    function refreshConversationsOnRouteChange() {
      if (!isReady || previousPathname === location?.pathname) return

      window.HubSpotConversations.widget.refresh()
    },
    [isReady, location?.pathname, previousPathname]
  )

  return (
    <HubspotConversationsContext.Provider
      value={{ isOpen, toggleWidget, unreadMessagesCount, openButtonRef }}
    >
      {children}
      {ReactDOM.createPortal(
        <div
          ref={chatWidgetRef}
          className={classes.chatWidgetContainer}
          id={HUBSPOT_INLINE_EMBED_ELEMENT_ID}
        >
          <IconButton
            className={classes.closeButton}
            onClick={hideWidget}
            size='small'
          >
            <Icon icon='close' fontSize='small' />
          </IconButton>
        </div>,
        document.body
      )}
    </HubspotConversationsContext.Provider>
  )
}

export const useHubspotConversations = () => {
  const context = useContext(HubspotConversationsContext)

  if (context === null) {
    throw new Error(
      'useHubspotConversations must be used within HubspotConversationsProvider'
    )
  }

  return context
}

export default HubspotConversationsProvider
