import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchServicesApi } from 'api/services'
import {
  SERVICES_FETCH_FAILED,
  SERVICES_FETCH_REQUESTED,
  SERVICES_FETCH_SUCCEEDED
} from 'containers/services/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchServices() {
  try {
    const response = yield call(fetchServicesApi, {
      flags: ['jobs', 'vendorActive']
    })
    yield put({
      type: SERVICES_FETCH_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: SERVICES_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* servicesSaga() {
  yield all([takeLatest(SERVICES_FETCH_REQUESTED, fetchServices)])
}
