import React, { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'
import { cloneDeep, isEmpty, isEqual as isObjEqual } from 'lodash'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import {
  useDidMount,
  useFilterState,
  useOpenState,
  usePrevious
} from '@doinn/shared/src/hooks'
import {
  getQueryStringObject,
  updateURLParams
} from '@doinn/shared/src/util/url'
import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import { parseAppliedFilters } from '@doinn/shared/src/util/filters'
import { getAllParamsButParam } from '@doinn/shared/src/util/object'
import withAuthorization from '@doinn/shared/src/hoc/authorization/withAuthorization'
import { countAppliedFilters } from '@doinn/host/src/containers/automations/list/util'
import TemplateDefault from 'components/TemplateDefault'
import FiltersDialog from '@doinn/shared/src/components/common/FiltersDialog'
import FiltersButton from '@doinn/shared/src/components/common/FiltersButton'
import HelperCard from '@doinn/shared/src/components/common/helper/HelperCard'
import ListFiltersContent from '@doinn/shared/src/containers/catalogue/components/ListFiltersContent'

const CataloguesContainer = loadable(() =>
  lazyRetry(() => import('@doinn/shared/src/containers/catalogue/Catalogues'))
)

const SAVED_FILTERS_KEY = {
  getKey(tab) {
    return tab in this ? this[tab] : ''
  },
  my: 'scenesCataloguesMyAppliedFilters',
  shared: 'scenesCataloguesSharedAppliedFilters',
  templates: 'scenesCataloguesTemplatesAppliedFilters'
}

const defaultFilters = {
  getFilters(tab) {
    return tab in this ? this[tab] : {}
  },
  my: {
    page: 1,
    orderBy: 'name',
    orderOrientation: 'asc',
    categories: [],
    customers: []
  },
  shared: {
    page: 1,
    orderBy: 'name',
    orderOrientation: 'asc',
    categories: [],
    providers: []
  },
  templates: {
    page: 1,
    orderBy: 'name',
    orderOrientation: 'asc',
    categories: []
  }
}

const getLastSavedFilters = tab => {
  try {
    return JSON.parse(
      window.sessionStorage.getItem(SAVED_FILTERS_KEY.getKey(tab))
    )
  } catch (e) {}
  return {}
}

const saveFilters = (filters, tab) => {
  try {
    const filtersWithoutPage = getAllParamsButParam(filters, 'page')
    return window.sessionStorage.setItem(
      SAVED_FILTERS_KEY.getKey(tab),
      JSON.stringify(filtersWithoutPage)
    )
  } catch (e) {}
  return {}
}

const getQueryOrSavedFilters = (location, tab) => {
  const queryFilters = getQueryStringObject(location.search)
  let filters = cloneDeep(defaultFilters.getFilters(tab))
  if (isEmpty(queryFilters)) {
    return {
      ...cloneDeep(filters),
      ...cloneDeep(getLastSavedFilters(tab))
    }
  } else {
    filters = parseAppliedFilters(filters, queryFilters)
  }
  return filters
}

const Catalogues = ({ can, canAll }) => {
  const { tab } = useParams()
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const previousLocation = usePrevious(location)
  const previousTab = usePrevious(tab)
  const [justChangedLocation, setJustChangedLocation] = React.useState(false)
  const {
    appliedFilters,
    selectedFilters,
    hasAppliedFiltersChanges,
    onChangeAppliedFilters,
    onChangeSelectedFilters,
    onClearSelectedFilters,
    onConfirmSelectedFilters
  } = useFilterState(cloneDeep(defaultFilters.getFilters(tab)))
  const {
    justClosed: justClosedFilters,
    justOpened: justOpenedFilters,
    onClose: onCloseFilters,
    onOpen: onOpenFilters,
    open: isFiltersOpened
  } = useOpenState(false)
  const [totalAppliedFilters, setTotalAppliedFilters] = React.useState(
    countAppliedFilters(appliedFilters)
  )

  useDidMount(() => {
    const redirectIfTabInvalid = () => {
      const allowedTabs = []
      if (can('create-catalogues')) {
        allowedTabs.push('my', 'templates')
      }
      if (canAll(['read-catalogues', 'create-jobs'])) {
        allowedTabs.push('shared')
      }

      const tabByDefault = !isEmpty(allowedTabs) && allowedTabs[0]
      if (!allowedTabs.includes(tab)) {
        return history.push(`/catalogues/${tabByDefault}`)
      }
    }

    redirectIfTabInvalid()
    onChangeAppliedFilters(getQueryOrSavedFilters(location, tab))
  })

  React.useEffect(() => {
    if (!previousLocation) return
    setJustChangedLocation(
      !isObjEqual(location.search, previousLocation.search) ||
        previousTab !== tab
    )
  }, [location, previousLocation, previousTab, tab])

  React.useEffect(() => {
    if (!justChangedLocation) return
    const savedFilters = getQueryOrSavedFilters(location, tab)
    onChangeAppliedFilters(savedFilters)
    saveFilters(savedFilters, tab)
  }, [justChangedLocation, location, onChangeAppliedFilters, tab])

  React.useEffect(() => {
    if (justOpenedFilters) {
      onChangeSelectedFilters(appliedFilters)
    }
  }, [appliedFilters, justOpenedFilters, onChangeSelectedFilters])

  React.useEffect(() => {
    if (justClosedFilters) {
      onChangeSelectedFilters(appliedFilters)
    }
  }, [appliedFilters, justClosedFilters, onChangeSelectedFilters])

  React.useEffect(() => {
    const shouldUpdateUrl =
      hasAppliedFiltersChanges &&
      previousTab === tab &&
      !isObjEqual(appliedFilters, getQueryOrSavedFilters(location, tab))
    if (shouldUpdateUrl) {
      updateURLParams(appliedFilters, history)
    }
  }, [
    appliedFilters,
    hasAppliedFiltersChanges,
    history,
    location,
    previousTab,
    tab
  ])

  React.useEffect(() => {
    setTotalAppliedFilters(countAppliedFilters(appliedFilters))
  }, [appliedFilters])

  const handleConfirmSelectedFilters = useCallback(() => {
    onConfirmSelectedFilters()
    onCloseFilters()
  }, [onCloseFilters, onConfirmSelectedFilters])

  const handleImportFinish = React.useCallback(() => {
    const newFilters = {
      ...cloneDeep(defaultFilters.getFilters(tab)),
      updatedAt: new Date().getTime()
    }
    onChangeAppliedFilters(newFilters)
  }, [onChangeAppliedFilters, tab])

  return (
    <TemplateDefault
      title={t('Catalogues')}
      filtersContent={
        <FiltersButton
          label={t('Filters')}
          variant='icon'
          totalAppliedFilters={totalAppliedFilters}
          onOpen={onOpenFilters}
        />
      }
      helperContent={<HelperCard />}
    >
      <Helmet>
        <title>{t('Catalogues')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <CataloguesContainer
          filters={appliedFilters}
          onOpenFilters={onOpenFilters}
          onImportFinish={handleImportFinish}
        />
      </React.Suspense>
      <FiltersDialog
        open={isFiltersOpened}
        title={t('Filters')}
        onClear={onClearSelectedFilters}
        onClose={onCloseFilters}
        onConfirm={handleConfirmSelectedFilters}
      >
        <ListFiltersContent
          filters={selectedFilters}
          onChangeFilters={onChangeSelectedFilters}
          tab={tab}
        />
      </FiltersDialog>
    </TemplateDefault>
  )
}

export default withAuthorization(Catalogues)
