import DoinnApi, { DoinnBlobApi } from '@doinn/shared/src/config/doinnApi'

export function fetchCataloguesMyApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/catalogues/my`, config)
}

export function getCatalogueMyApi(catalogueId) {
  const config = {}
  return DoinnApi.get(`/catalogues/my/${catalogueId}`, config)
}

export function getCatalogueMyItemsApi(catalogueId) {
  const config = {}
  return DoinnApi.get(`/catalogues/my/${catalogueId}/items`, config)
}

export function addCatalogueMyApi(catalogue = {}) {
  const config = {}
  return DoinnApi.post(`/catalogues/my`, { catalogue }, config)
}

export function editCatalogueMyApi(catalogue = {}) {
  const config = {}
  const data = { catalogue }
  return DoinnApi.patch(`/catalogues/my/${catalogue.id}`, data, config)
}

export function editSettingsCatalogueMyApi(params = {}) {
  const config = {}
  const data = { ...params }
  return DoinnApi.put(
    `/catalogues/my/${params.catalogueId}/settings`,
    data,
    config
  )
}

export function editPricesCatalogueMyApi(params = {}) {
  const config = {}
  const data = { ...params }
  return DoinnApi.put(
    `/catalogues/my/${params.catalogueId}/prices`,
    data,
    config
  )
}

export function deleteCatalogueMyApi(id) {
  const config = {}
  return DoinnApi.delete(`/catalogues/my/${id}`, config)
}

export function fetchCataloguesSharedApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/catalogues/shared`, config)
}

export function getCatalogueSharedApi(catalogueId) {
  const config = {}
  return DoinnApi.get(`/catalogues/shared/${catalogueId}`, config)
}

export function addCatalogueSharedApi(catalogue = {}) {
  const config = {}
  return DoinnApi.post(`/catalogues/shared`, { catalogue }, config)
}

export function editCatalogueSharedApi(catalogue = {}) {
  const config = {}
  const data = { catalogue }
  return DoinnApi.patch(`/catalogues/shared/${catalogue.id}`, data, config)
}

export function deleteCatalogueSharedApi(id) {
  const config = {}
  return DoinnApi.delete(`/catalogues/shared/${id}`, config)
}

export function fetchCataloguesTemplatesApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/catalogues/templates`, config)
}

export function getCatalogueTemplatesApi(catalogueId) {
  const config = {}
  return DoinnApi.get(`/catalogues/templates/${catalogueId}`, config)
}

export function copyCatalogueTemplateApi(params) {
  const config = {}
  const data = { ...params }
  return DoinnApi.post(`/catalogues/${params.id}/copy`, data, config)
}

export function shareCatalogueApi(params) {
  const config = {}
  const data = { ...params }
  return DoinnApi.post(`/catalogues/my/${params.id}/share`, data, config)
}

export function unshareCatalogueMyApi(params) {
  const { catalogueId, businessAccountId } = params
  return DoinnApi.patch(
    `/catalogues/my/${catalogueId}/customers/${businessAccountId}/unshare`
  )
}

export function deleteInvitationCatalogueMyApi(params) {
  const { catalogueId, email } = params
  return DoinnApi.delete(`/catalogues/my/${catalogueId}/invitations/${email}`)
}

export function getCataloguePropertyOptionalItemsApi(catalogue, properties) {
  const config = {
    params: {
      spaces: properties.map(property => property.id)
    }
  }
  return DoinnApi.get(
    `/catalogues/${catalogue.id}/space-optional-items`,
    config
  )
}

export function editCatalogueDescriptionMyApi(params) {
  const config = {}
  const { id, description } = params
  const data = {
    description
  }
  return DoinnApi.put(`/catalogues/my/${id}/description`, data, config)
}

export function downloadPriceListApi(params) {
  const config = {}
  const { id } = params
  return DoinnBlobApi.get(`/catalogues/my/${id}/download/prices`, config)
}

export function getCataloguesCategoriesApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/catalogues/categories`, config)
}

export function getCataloguesCustomersApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/catalogues/customers`, config)
}

export function getCataloguesProvidersApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/catalogues/providers`, config)
}
