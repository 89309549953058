import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getReportedProblemsApi } from 'api/evaluations'
import {
  JOB_REPORT_VIEW_REPORTED_PROBLEMS_FAILED,
  JOB_REPORT_VIEW_REPORTED_PROBLEMS_REQUESTED,
  JOB_REPORT_VIEW_REPORTED_PROBLEMS_SUCCEEDED
} from 'containers/job/report-problem/view/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

export function* getReportedProblems(action) {
  try {
    const config = {
      params: {
        job: action?.payload?.params?.id
      }
    }
    const response = yield call(getReportedProblemsApi, config)

    yield put({
      type: JOB_REPORT_VIEW_REPORTED_PROBLEMS_SUCCEEDED,
      payload: {
        reportedProblems: response.data.data
      }
    })
  } catch (e) {
    yield put({
      type: JOB_REPORT_VIEW_REPORTED_PROBLEMS_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* jobReportProblemSaga() {
  yield all([
    takeLatest(JOB_REPORT_VIEW_REPORTED_PROBLEMS_REQUESTED, getReportedProblems)
  ])
}
