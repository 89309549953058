export const JOB_REPORT_PROBLEM_FORM_OPEN_REQUESTED =
  'JOB_REPORT_PROBLEM_FORM_OPEN_REQUESTED'

export const JOB_REPORT_PROBLEM_FORM_CLOSE_REQUESTED =
  'JOB_REPORT_PROBLEM_FORM_CLOSE_REQUESTED'

export const JOB_REPORT_PROBLEM_REQUESTED = 'JOB_REPORT_PROBLEM_REQUESTED'
export const JOB_REPORT_PROBLEM_SUCCEEDED = 'JOB_REPORT_PROBLEM_SUCCEEDED'
export const JOB_REPORT_PROBLEM_FAILED = 'JOB_REPORT_PROBLEM_FAILED'

export const JOB_REPORT_PROBLEM_GET_PHRASES_REQUESTED =
  'JOB_REPORT_PROBLEM_GET_PHRASES_REQUESTED'
export const JOB_REPORT_PROBLEM_GET_PHRASES_SUCCEEDED =
  'JOB_REPORT_PROBLEM_GET_PHRASES_SUCCEEDED'
export const JOB_REPORT_PROBLEM_GET_PHRASES_FAILED =
  'JOB_REPORT_PROBLEM_GET_PHRASES_FAILED'
