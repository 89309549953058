import { all, call, put, takeLatest } from 'redux-saga/effects'
import { deleteTeamApi } from '@doinn/vendor/src/api/team'
import {
  TEAMS_DELETE_FAILED,
  TEAMS_DELETE_REQUESTED,
  TEAMS_DELETE_SUCCEEDED
} from '@doinn/vendor/src/containers/staff/teams/delete/constants'
import { TEAMS_UPDATE_SELECTED_TEAM_REQUESTED } from '@doinn/vendor/src/containers/staff/teams/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* deleteTeams(action) {
  try {
    const { teamIds } = action.payload
    const failedTeams = []
    for (let i = 0; i < teamIds.length; i++) {
      const teamId = teamIds[i]
      try {
        yield call(deleteTeamApi, teamId)
      } catch (e) {
        failedTeams.push(teamId)
      }
    }
    const totalFailedTeams = failedTeams.length
    if (totalFailedTeams) {
      let errorMessage = 'Not all teams could be deleted'
      if (totalFailedTeams === teamIds.length) {
        errorMessage = 'No teams could be deleted'
      }
      yield put({
        type: TEAMS_DELETE_FAILED,
        snackbar: {
          variant: 'error',
          message: getSnackbarErrorMessage(errorMessage)
        }
      })
    } else {
      yield put({
        type: TEAMS_DELETE_SUCCEEDED
        // payload: response.data
      })
      yield put({
        type: TEAMS_UPDATE_SELECTED_TEAM_REQUESTED,
        payload: []
      })
    }
  } catch (e) {
    yield put({
      type: TEAMS_DELETE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* teamsDeleteSaga() {
  yield all([takeLatest(TEAMS_DELETE_REQUESTED, deleteTeams)])
}
