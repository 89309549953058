import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Collapse, Typography } from '@material-ui/core'
import DateRangeSelect from '@doinn/shared/src/components/common/filter/DateRangeSelect'
import RadioList from '@doinn/shared/src/components/common/filter/RadioList'
import DateRangeTextField from '@doinn/shared/src/components/common/filter/DateRangeTextField'
import {
  addDays,
  addMonths,
  endOfMonth,
  startOfMonth,
  subDays,
  subMonths
} from 'date-fns'
import { formatSystemDate } from '@doinn/shared/src/util/date-fns'

const getPeriodDates = period => {
  const nowDate = new Date()
  const startOfMonthDate = startOfMonth(nowDate)
  const endOfMonthDate = endOfMonth(nowDate)
  const dates = {
    period
  }

  switch (period) {
    case 'current_month':
      dates.start = formatSystemDate(startOfMonthDate)
      dates.end = formatSystemDate(endOfMonthDate)
      break
    case 'last_month':
      dates.start = formatSystemDate(subMonths(startOfMonthDate, 1))
      dates.end = formatSystemDate(endOfMonth(subMonths(endOfMonthDate, 1)))
      break
    case 'next_month':
      dates.start = formatSystemDate(addMonths(startOfMonthDate, 1))
      dates.end = formatSystemDate(endOfMonth(addMonths(endOfMonthDate, 1)))
      break
    case 'month_to_today':
      dates.start = formatSystemDate(startOfMonthDate)
      dates.end = formatSystemDate(nowDate)
      break
    case 'before_yesterday':
      dates.start = formatSystemDate(subDays(nowDate, 2))
      dates.end = formatSystemDate(subDays(nowDate, 2))
      break
    case 'yesterday':
      dates.start = formatSystemDate(subDays(nowDate, 1))
      dates.end = formatSystemDate(subDays(nowDate, 1))
      break
    case 'today':
      dates.start = formatSystemDate(nowDate)
      dates.end = formatSystemDate(nowDate)
      break
    case 'tomorrow':
      dates.start = formatSystemDate(addDays(nowDate, 1))
      dates.end = formatSystemDate(addDays(nowDate, 1))
      break
    case 'after_tomorrow':
      dates.start = formatSystemDate(addDays(nowDate, 2))
      dates.end = formatSystemDate(addDays(nowDate, 2))
      break
    case 'next_3_days':
      dates.start = formatSystemDate(nowDate)
      dates.end = formatSystemDate(addDays(nowDate, 3))
      break
    case 'next_7_days':
      dates.start = formatSystemDate(nowDate)
      dates.end = formatSystemDate(addDays(nowDate, 7))
      break
    case 'next_15_days':
      dates.start = formatSystemDate(nowDate)
      dates.end = formatSystemDate(addDays(nowDate, 15))
      break
    case 'next_30_days':
      dates.start = formatSystemDate(nowDate)
      dates.end = formatSystemDate(addDays(nowDate, 30))
      break
    default:
      break
  }

  return dates
}

const DateRange = ({
  selectedPeriod,
  start,
  end,
  periodOptions,
  variant,
  disabled,
  onChange,
  isRange = true
}) => {
  const { t } = useTranslation()
  const parsedPeriodOptions = periodOptions || [
    {
      id: 'before_yesterday',
      name: t('Before yesterday')
    },
    {
      id: 'yesterday',
      name: t('Yesterday')
    },
    {
      id: 'today',
      name: t('Today')
    },
    {
      id: 'tomorrow',
      name: t('Tomorrow')
    },
    {
      id: 'after_tomorrow',
      name: t('After tomorrow')
    },
    {
      id: 'current_month',
      name: t('Current month')
    },
    {
      id: 'last_month',
      name: t('Last month')
    },
    {
      id: 'month_to_today',
      name: t('Month to today')
    },
    {
      id: 'custom',
      name: t('Custom')
    }
  ]

  const computedSelectedPeriod = parsedPeriodOptions
    ?.map(option => option.id)
    ?.includes(selectedPeriod)
    ? selectedPeriod
    : 'custom'

  const handleChangeDateRange = useCallback(
    (filterName, newValue) => {
      if (!onChange) return
      if (filterName === 'range') {
        onChange({
          start: newValue[0],
          end: newValue[1],
          period: 'custom'
        })
      } else if (filterName === 'period') {
        onChange({
          start,
          end,
          ...getPeriodDates(newValue)
        })
      }
    },
    [start, end, onChange]
  )

  const renderListVariant = () => {
    const isCustomPeriod = computedSelectedPeriod === 'custom'

    return (
      <React.Fragment>
        <RadioList
          subheader={t('Period')}
          filterName='period'
          items={parsedPeriodOptions}
          checkedItems={computedSelectedPeriod}
          onChange={handleChangeDateRange}
        />
        <div>
          <Collapse in={isCustomPeriod}>
            <DateRangeTextField
              startValue={start}
              endValue={end}
              onChange={handleChangeDateRange}
            />
          </Collapse>
        </div>
      </React.Fragment>
    )
  }

  const renderMenuVariant = () => {
    return (
      <React.Fragment>
        <Typography variant='body2' gutterBottom>
          {t('Period')}
        </Typography>
        <DateRangeSelect
          disabled={disabled}
          periodOptions={parsedPeriodOptions}
          selectedPeriod={computedSelectedPeriod}
          start={start}
          end={end}
          isRange={isRange}
          onChange={handleChangeDateRange}
        />
      </React.Fragment>
    )
  }

  if (variant === 'list') return renderListVariant()
  return renderMenuVariant()
}

DateRange.propTypes = {
  variant: PropTypes.oneOf(['list', 'menu']),
  periodOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  selectedPeriod: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  onChange: PropTypes.func
}

DateRange.defaultProps = {
  variant: 'menu'
}

export default DateRange
