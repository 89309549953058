import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function fetchCatalogsApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/host/catalogs`, config)
}

export function getCatalogApi({ catalogId, params = {} }) {
  const config = { params }
  return DoinnApi.get(`/host/catalogs/${catalogId}`, config)
}

export function addCatalogApi(catalog) {
  const config = {}
  return DoinnApi.post(`/host/catalogs`, { catalog }, config)
}

export function editCatalogApi(catalog = {}) {
  const config = {}
  const data = { catalog }
  return DoinnApi.patch(`/host/catalogs/${catalog.id}`, data, config)
}

export function deleteCatalogApi(id) {
  const config = {}
  return DoinnApi.delete(`/host/catalogs/${id}`, config)
}
