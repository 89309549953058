import {
  CATALOGUE_COPY_DIALOG_CLOSE,
  CATALOGUE_COPY_DIALOG_OPEN,
  CATALOGUE_COPY_FAILED,
  CATALOGUE_COPY_REQUESTED,
  CATALOGUE_COPY_SUCCEEDED
} from '@doinn/shared/src/containers/catalogue/copy/constants'

export const STATUS = {
  IDLE: 'idle',
  SAVING: 'saving',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  isOpened: false,
  status: STATUS.IDLE,
  lastCopiedItem: null
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case CATALOGUE_COPY_DIALOG_OPEN:
      return {
        ...initialState,
        isOpened: true
      }
    case CATALOGUE_COPY_DIALOG_CLOSE:
      return {
        ...initialState,
        isOpened: false
      }
    case CATALOGUE_COPY_REQUESTED:
      return {
        ...state,
        lastCopiedItem: null,
        status: STATUS.SAVING
      }
    case CATALOGUE_COPY_SUCCEEDED:
      return {
        ...state,
        lastCopiedItem: null,
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case CATALOGUE_COPY_FAILED:
      return {
        ...state,
        lastCopiedItem: null,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
