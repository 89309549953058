import {
  RECEIVED_INVOICES_DOWNLOAD_FAILED,
  RECEIVED_INVOICES_DOWNLOAD_REQUESTED,
  RECEIVED_INVOICES_DOWNLOAD_SUCCEEDED,
  RECEIVED_INVOICES_EXPORT_FAILED,
  RECEIVED_INVOICES_EXPORT_REQUESTED,
  RECEIVED_INVOICES_EXPORT_SUCCEEDED,
  RECEIVED_INVOICES_FETCH_FAILED,
  RECEIVED_INVOICES_FETCH_MORE_FAILED,
  RECEIVED_INVOICES_FETCH_MORE_REQUESTED,
  RECEIVED_INVOICES_FETCH_MORE_SUCCEEDED,
  RECEIVED_INVOICES_FETCH_REQUESTED,
  RECEIVED_INVOICES_FETCH_SUCCEEDED
} from '@doinn/shared/src/containers/invoice/received/constants'

export const initialState = {
  list: [],
  meta: {},
  isLoading: false,
  isFailed: false,
  loadingItem: null
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case RECEIVED_INVOICES_FETCH_REQUESTED:
      return {
        ...state,
        ...initialState,
        isLoading: true,
        isFailed: false
      }
    case RECEIVED_INVOICES_FETCH_SUCCEEDED:
      return {
        ...state,
        list: [...payload.data],
        meta: { ...payload.meta },
        isLoading: false,
        isFailed: false
      }
    case RECEIVED_INVOICES_FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        isFailed: true
      }
    case RECEIVED_INVOICES_FETCH_MORE_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isFailed: false
      }
    case RECEIVED_INVOICES_FETCH_MORE_SUCCEEDED:
      return {
        ...state,
        list: [...state.list, ...payload.data],
        meta: { ...payload.meta },
        isLoading: false,
        isFailed: false
      }
    case RECEIVED_INVOICES_FETCH_MORE_FAILED:
      return {
        ...state,
        isLoading: false,
        isFailed: true
      }
    case RECEIVED_INVOICES_DOWNLOAD_REQUESTED:
    case RECEIVED_INVOICES_EXPORT_REQUESTED:
      return {
        ...state,
        isLoading: false,
        isFailed: false,
        loadingItem: payload?.params?.invoice?.id
      }
    case RECEIVED_INVOICES_DOWNLOAD_SUCCEEDED:
    case RECEIVED_INVOICES_EXPORT_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        isFailed: false,
        loadingItem: null
      }
    case RECEIVED_INVOICES_DOWNLOAD_FAILED:
    case RECEIVED_INVOICES_EXPORT_FAILED:
      return {
        ...state,
        isLoading: false,
        isFailed: true,
        loadingItem: null
      }
    default:
      return state
  }
}
