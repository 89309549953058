import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import CheckboxList from '@doinn/shared/src/components/common/filter/CheckboxList'

const TOTAL_OPTIONS = 10
const TOTAL_VISIBLE_OPTIONS = 5
const APPEND_CHAR = '+'

const CheckboxListNumberOf = ({
  allowZero,
  checkedItems,
  onChange,
  ...otherProps
}) => {
  const { t } = useTranslation()

  const [parsedCheckedItems, setParsedCheckedItems] = React.useState([])

  React.useEffect(() => {
    if (!Array.isArray(checkedItems)) {
      setParsedCheckedItems([])
      return
    }
    if (checkedItems && checkedItems.includes(String(TOTAL_VISIBLE_OPTIONS))) {
      const filteredCheckedItems = checkedItems.filter(
        item => Number(item) < TOTAL_VISIBLE_OPTIONS
      )
      filteredCheckedItems.push(`${TOTAL_VISIBLE_OPTIONS}${APPEND_CHAR}`)
      setParsedCheckedItems(filteredCheckedItems)
    } else {
      setParsedCheckedItems(checkedItems)
    }
  }, [checkedItems])

  const computedOptions = [...Array(TOTAL_VISIBLE_OPTIONS)].map(
    (value, index) => {
      const parsedIndex = index + 1
      const isLastVisibleOption = TOTAL_VISIBLE_OPTIONS === parsedIndex
      if (isLastVisibleOption) {
        return {
          id: `${parsedIndex}${APPEND_CHAR}`,
          name: t('{{total}} or more', { total: parsedIndex })
        }
      }
      return {
        id: `${parsedIndex}`,
        name: `${parsedIndex}`
      }
    }
  )

  if (allowZero) {
    computedOptions.unshift({
      id: '0',
      name: '0'
    })
  }

  const handleChange = useCallback(
    (filterName, newValue) => {
      if (!onChange) return
      const isLastVisibleOption = newValue.indexOf(APPEND_CHAR) > -1
      if (isLastVisibleOption) {
        const newParsedValue = [
          ...Array(TOTAL_OPTIONS - TOTAL_VISIBLE_OPTIONS + 1)
        ].map((value, index) => String(TOTAL_VISIBLE_OPTIONS + index))
        onChange(filterName, newParsedValue)
      } else {
        onChange(filterName, newValue)
      }
    },
    [onChange]
  )

  if (!checkedItems) return null

  return (
    <CheckboxList
      {...otherProps}
      items={computedOptions}
      checkedItems={parsedCheckedItems}
      onChange={handleChange}
    />
  )
}

CheckboxListNumberOf.propTypes = {
  allowZero: PropTypes.bool,
  isLoading: PropTypes.bool,
  checkedItems: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  subheader: PropTypes.string,
  filterName: PropTypes.string,
  isMenu: PropTypes.bool,
  onChange: PropTypes.func
}

CheckboxListNumberOf.defaultProps = {}

export default CheckboxListNumberOf
