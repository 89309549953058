export const countAppliedFilters = (filters, hasMainFilters) => {
  let total = 0
  for (const key in filters) {
    if (filters[key]) {
      const value = filters[key]
      if (Array.isArray(value)) {
        total += filters[key].length
      } else {
        if (key === 'reportBy' || (key === 'start' && !hasMainFilters)) {
          total += 1
        }
      }
    }
  }
  return total
}
