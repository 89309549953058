export const CONTRACT_VENDOR_CITIES_FETCH_REQUESTED =
  'CONTRACT_VENDOR_CITIES_FETCH_REQUESTED'
export const CONTRACT_VENDOR_CITIES_FETCH_SUCCEEDED =
  'CONTRACT_VENDOR_CITIES_FETCH_SUCCEEDED'
export const CONTRACT_VENDOR_CITIES_FETCH_FAILED =
  'CONTRACT_VENDOR_CITIES_FETCH_FAILED'

export const CONTRACT_VENDOR_PRICE_FETCH_REQUESTED =
  'CONTRACT_VENDOR_PRICE_FETCH_REQUESTED'
export const CONTRACT_VENDOR_PRICE_FETCH_SUCCEEDED =
  'CONTRACT_VENDOR_PRICE_FETCH_SUCCEEDED'
export const CONTRACT_VENDOR_PRICE_FETCH_FAILED =
  'CONTRACT_VENDOR_PRICE_FETCH_FAILED'

export const CONTRACT_VENDOR_PRICE_UPDATE_REQUESTED =
  'CONTRACT_VENDOR_PRICE_UPDATE_REQUESTED'
export const CONTRACT_VENDOR_PRICE_UPDATE_SUCCEEDED =
  'CONTRACT_VENDOR_PRICE_UPDATE_SUCCEEDED'
export const CONTRACT_VENDOR_PRICE_UPDATE_FAILED =
  'CONTRACT_VENDOR_PRICE_UPDATE_FAILED'
