import {
  JOB_FINISH_FAILED,
  JOB_FINISH_FORM_CLOSE,
  JOB_FINISH_FORM_OPEN,
  JOB_FINISH_REQUESTED,
  JOB_FINISH_SUCCEEDED
} from 'containers/job/finish/note/constants'

export const STATUS = {
  IDLE: 'idle',
  SAVING: 'saving',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  isOpened: false,
  status: STATUS.IDLE,
  selectedJob: null
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case JOB_FINISH_FORM_OPEN:
      return {
        ...initialState,
        selectedJob: payload.job,
        isOpened: true
      }
    case JOB_FINISH_FORM_CLOSE:
      return {
        ...initialState,
        selectedJob: null,
        isOpened: false
      }
    case JOB_FINISH_REQUESTED:
      return {
        ...state,
        status: STATUS.SAVING
      }
    case JOB_FINISH_SUCCEEDED:
      return {
        ...initialState,
        status: STATUS.SUCCESS
      }
    case JOB_FINISH_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
