import {
  SERVICES_FETCH_FAILED,
  SERVICES_FETCH_REQUESTED,
  SERVICES_FETCH_SUCCEEDED
} from 'containers/services/constants'

export const initialState = {
  list: [],
  isLoaded: false,
  isLoading: false,
  isFailed: false
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SERVICES_FETCH_REQUESTED:
      return {
        ...state,
        ...initialState,
        isLoaded: false,
        isLoading: true,
        isFailed: false
      }
    case SERVICES_FETCH_SUCCEEDED:
      return {
        ...state,
        list: [...payload.data],
        isLoaded: true,
        isLoading: false,
        isFailed: false
      }
    case SERVICES_FETCH_FAILED:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        isFailed: true
      }
    default:
      return state
  }
}
