import { all, call, put, takeLatest } from 'redux-saga/effects'
import i18n from '@doinn/shared/src/config/i18n'
import { updateUserPasswordApi } from '@doinn/shared/src/api/profile'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import {
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_REQUESTED,
  CHANGE_PASSWORD_SUCCEEDED
} from '@doinn/shared/src/containers/profile/sections/change-password/constants'

function* submitPasswordForm(action) {
  const body = {
    userId: action.payload.data.userId,
    currentPassword: action.payload.data.currentPassword,
    password: action.payload.data.password
  }
  try {
    const response = yield call(updateUserPasswordApi, body)
    yield put({
      type: CHANGE_PASSWORD_SUCCEEDED,
      payload: response.data,
      snackbar: {
        variant: 'success',
        message: i18n.t('Password successfully changed.')
      }
    })
    action.payload.data.resetForm()
  } catch (e) {
    yield put({
      type: CHANGE_PASSWORD_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* profileSaga() {
  yield all([takeLatest(CHANGE_PASSWORD_REQUESTED, submitPasswordForm)])
}
