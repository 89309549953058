import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Avatar,
  Badge,
  Button,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import Icon from '@doinn/shared/src/components/common/Icon'

const useStyles = makeStyles(theme => {
  return {
    badge: {
      '& > span': {
        transform: 'scale(0.8) translate(50%, -50%)'
      }
    },
    totalAppliedFilters: {
      ...theme.typography.caption,
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      width: theme.spacing(2.25),
      height: theme.spacing(2.25),
      marginLeft: theme.spacing(1),
      fontWeight: theme.typography.fontWeightBold
    }
  }
})

const FiltersButton = ({ label, onOpen, totalAppliedFilters, variant }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))
  const parsedLabel = label || t('Filters')

  if (variant === 'icon') {
    return (
      <IconButton
        color='inherit'
        aria-label='open filters dialog'
        onClick={onOpen}
      >
        <Badge
          badgeContent={totalAppliedFilters}
          color='primary'
          overlap='circle'
          className={classes.badge}
        >
          <Icon icon='filter' />
        </Badge>
      </IconButton>
    )
  }

  return (
    <Button
      variant='outlined'
      color='primary'
      size='small'
      fullWidth={isMobile}
      onClick={onOpen}
    >
      {parsedLabel}
      {totalAppliedFilters > 0 && (
        <Avatar className={classes.totalAppliedFilters}>
          {totalAppliedFilters}
        </Avatar>
      )}
    </Button>
  )
}

FiltersButton.propTypes = {
  label: PropTypes.string,
  onOpen: PropTypes.func,
  totalAppliedFilters: PropTypes.number,
  variant: PropTypes.oneOf(['button', 'icon'])
}

FiltersButton.defaultProps = {
  variant: 'button'
}

export default FiltersButton
