import DoinnApi from '@doinn/shared/src/config/doinnApi'
import { optimizeImageFile } from '@doinn/shared/src/util/images'
import { CHAT_MESSAGE_TYPE_PHOTOS } from '@doinn/shared/src/containers/chats/messages/constants'
import { fetchPropertiesElasticsearch } from '@doinn/shared/src/api/elasticsearch/properties'

export function fetchChatsApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/chats`, config)
}

export function getChatApi(id) {
  const config = {}
  return DoinnApi.get(`/chats/${id}`, config)
}

export function fetchChatMessagesApi(chat, params = {}) {
  const config = {
    params
  }
  return DoinnApi.get(`/chats/${chat.id}/messages`, config)
}

export function markChatAsReadApi(chat) {
  return DoinnApi.patch(`/chats/${chat.id}/read`)
}

export async function sendChatMessageApi(chat, message) {
  const { type, uiUuid, value } = message

  if (type === CHAT_MESSAGE_TYPE_PHOTOS) {
    return sendChatMessagePhotosApi(chat, message)
  }

  return DoinnApi.post(`/chats/${chat.id}/messages`, {
    type,
    ui_uuid: uiUuid,
    value
  })
}

export async function sendChatMessagePhotosApi(chat, message) {
  const rateQuality = 0.5
  const sizeBox = [500, 500]
  const { type, uiUuid, value } = message
  const newValue = { ...value }
  const request = {
    headers: { 'Content-Type': 'multipart/form-data' }
  }
  const formData = new window.FormData()

  formData.append('type', type)
  formData.append('ui_uuid', uiUuid)
  formData.append('value[text]', value.text)

  const optimizeImageFiles = await Promise.all(
    value.photos.map(photo =>
      optimizeImageFile(photo.file, sizeBox, rateQuality)
    )
  )

  newValue.photos = optimizeImageFiles.forEach((image, index) => {
    formData.append(`value[photos][${index}]`, image)
  })

  return DoinnApi.post(`/chats/${chat.id}/messages`, formData, request)
}

export function sendChatMessageAttachmentApi(message, attachments) {
  const request = {
    headers: { 'Content-Type': 'multipart/form-data' }
  }

  const formData = new window.FormData()
  attachments.forEach((attachment, index) => {
    formData.append(`attachments[${index}][file]`, attachment.file)
  })

  return DoinnApi.post(
    `/chats/${message.chat.id}/messages/${message.id}/attachments`,
    formData,
    request
  )
}

export function fetchChatsContactsApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/chats/contacts`, config)
}

export function fetchChatsPropertiesApi(params = {}) {
  const { params: filters } = params
  return fetchPropertiesElasticsearch(filters)
}

export function addChatApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.post('/chats', config)
}

export function addChatParticipantsApi(chat, participants) {
  return DoinnApi.post(`/chats/${chat.id}/participants`, { participants })
}

export function removeChatParticipantApi(chat, participant) {
  const config = {}

  return DoinnApi.delete(
    `/chats/${chat.id}/participants/${participant.id}`,
    config
  )
}

export function deleteChatApi(chat) {
  return DoinnApi.delete(`/chats/${chat.id}`)
}

export function followChatApi(chat) {
  return DoinnApi.patch(`/chats/${chat.id}/follow`)
}

export function unfollowChatApi(chat) {
  return DoinnApi.patch(`/chats/${chat.id}/unfollow`)
}
