import {
  SUBSCRIPTION_OVERVIEW_FAILED,
  SUBSCRIPTION_OVERVIEW_REQUESTED,
  SUBSCRIPTION_OVERVIEW_SUCCEEDED
} from '@doinn/shared/src/containers/subscription-billing/subscription/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  status: STATUS.IDLE,
  data: null
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SUBSCRIPTION_OVERVIEW_REQUESTED:
      return {
        ...initialState,
        status: STATUS.LOADING
      }
    case SUBSCRIPTION_OVERVIEW_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS,
        data: payload.data
      }
    case SUBSCRIPTION_OVERVIEW_FAILED:
      return {
        ...initialState,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
