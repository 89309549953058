import {
  REPORTS_BILLING_DETAILS_DOWNLOAD_DETAILS_FAILED,
  REPORTS_BILLING_DETAILS_DOWNLOAD_DETAILS_REQUESTED,
  REPORTS_BILLING_DETAILS_DOWNLOAD_DETAILS_SUCCEEDED,
  REPORTS_BILLING_DETAILS_FETCH_FAILED,
  REPORTS_BILLING_DETAILS_FETCH_REQUESTED,
  REPORTS_BILLING_DETAILS_FETCH_SUCCEEDED
} from 'containers/reports/billingDetails/constants'

export const initialState = {
  data: [],
  meta: {},
  isLoaded: false,
  isLoading: false,
  isFailed: false
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case REPORTS_BILLING_DETAILS_FETCH_REQUESTED:
      return {
        ...state,
        ...initialState,
        isLoaded: false,
        isLoading: true,
        isFailed: false
      }
    case REPORTS_BILLING_DETAILS_FETCH_SUCCEEDED:
      return {
        ...state,
        data: [...payload.data],
        meta: { ...payload.meta },
        isLoaded: true,
        isLoading: false,
        isFailed: false
      }
    case REPORTS_BILLING_DETAILS_FETCH_FAILED:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        isFailed: true
      }
    case REPORTS_BILLING_DETAILS_DOWNLOAD_DETAILS_REQUESTED:
      return {
        ...state,
        isDownloading: true
      }
    case REPORTS_BILLING_DETAILS_DOWNLOAD_DETAILS_SUCCEEDED:
    case REPORTS_BILLING_DETAILS_DOWNLOAD_DETAILS_FAILED:
      return {
        ...state,
        isDownloading: false
      }
    default:
      return state
  }
}
