import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import {
  deleteDocumentApi,
  fetchDocumentsApi,
  uploadDocumentApi
} from 'api/documents'
import {
  DOCUMENTS_DELETE_FAILED,
  DOCUMENTS_DELETE_REQUESTED,
  DOCUMENTS_DELETE_SUCCEEDED,
  DOCUMENTS_FETCH_FAILED,
  DOCUMENTS_FETCH_REQUESTED,
  DOCUMENTS_FETCH_SUCCEEDED,
  DOCUMENTS_UPLOAD_FAILED,
  DOCUMENTS_UPLOAD_REQUESTED,
  DOCUMENTS_UPLOAD_SUCCEEDED
} from 'containers/onboarding/steps/Documents/constants'

function* fetchDocuments() {
  try {
    const response = yield call(fetchDocumentsApi)
    const documentsResponse = response.data
    yield put({
      type: DOCUMENTS_FETCH_SUCCEEDED,
      payload: documentsResponse.data
    })
  } catch (e) {
    yield put({
      type: DOCUMENTS_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* uploadDocument(action) {
  try {
    const { documentSlug, file } = action.payload.params
    const response = yield call(uploadDocumentApi, {
      documentSlug: documentSlug,
      file: file
    })
    const responseDocuments = response.data.data
    yield put({
      type: DOCUMENTS_UPLOAD_SUCCEEDED,
      payload: {
        documentSlug: documentSlug,
        fileName: file.name,
        document: responseDocuments[0]
      }
    })
  } catch (e) {
    const { documentSlug, file } = action.payload.params
    yield put({
      type: DOCUMENTS_UPLOAD_FAILED,
      payload: {
        documentSlug: documentSlug,
        fileName: file.name
      },
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* deleteDocument(action) {
  try {
    const { id } = action.payload.params
    yield call(deleteDocumentApi, id)
    yield put({
      type: DOCUMENTS_DELETE_SUCCEEDED,
      payload: {
        id: id
      }
    })
  } catch (e) {
    const { id } = action.payload.params
    yield put({
      type: DOCUMENTS_DELETE_FAILED,
      payload: {
        id: id
      },
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* documentsSagas() {
  yield all([
    takeLatest(DOCUMENTS_FETCH_REQUESTED, fetchDocuments),
    takeEvery(DOCUMENTS_UPLOAD_REQUESTED, uploadDocument),
    takeEvery(DOCUMENTS_DELETE_REQUESTED, deleteDocument)
  ])
}
