import React from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'

import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import AuthMenu from '@doinn/vendor/src/containers/app/components/AuthMenu'
import TemplateAuth from '@doinn/shared/src/components/templates/TemplateAuth'
import MainMenuItem from '@doinn/vendor/src/containers/app/auth/components/MainMenuItem'

const RegisterQuestionsContainer = loadable(() =>
  lazyRetry(() =>
    import('@doinn/vendor/src/containers/app/auth/register/RegisterQuestions')
  )
)

const Auth = () => {
  const { t } = useTranslation()

  return (
    <TemplateAuth
      headerRightChildren={<AuthMenu />}
      drawerChildren={<MainMenuItem />}
    >
      <Helmet>
        <title>{t('Registration')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <RegisterQuestionsContainer />
      </React.Suspense>
    </TemplateAuth>
  )
}

export default Auth
