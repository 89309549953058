import {
  INTEGRATION_EDIT_FAILED,
  INTEGRATION_EDIT_REQUESTED,
  INTEGRATION_EDIT_SUCCEEDED
} from '@doinn/host/src/containers/integrations/edit/configuration/constants'

export const STATUS = {
  IDLE: 'idle',
  SAVING: 'saving',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  status: STATUS.IDLE,
  numberOfSpacesWithError: 0
}

const countNumberOfSpacesWithError = ({ enabledSpaces }) =>
  enabledSpaces.reduce((acc, space) => {
    const counter = space?.status?.slug?.includes('error') ? 1 : 0 ?? 0
    return acc + counter
  }, 0)

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case INTEGRATION_EDIT_REQUESTED:
      return {
        ...state,
        status: STATUS.SAVING
      }
    case INTEGRATION_EDIT_SUCCEEDED:
      return {
        ...state,
        numberOfSpacesWithError: countNumberOfSpacesWithError(action.payload),
        status: STATUS.SUCCESS
      }
    case INTEGRATION_EDIT_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
