import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getChecklistMyApi } from '@doinn/host/src/api/checklists'
import {
  CHECKLIST_MY_GET_FAILED,
  CHECKLIST_MY_GET_REQUESTED,
  CHECKLIST_MY_GET_SUCCEEDED
} from '@doinn/host/src/containers/checklist/my/get/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* getChecklistMy(action) {
  try {
    const response = yield call(getChecklistMyApi, action.payload.params)
    yield put({
      type: CHECKLIST_MY_GET_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: CHECKLIST_MY_GET_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* checklistMyGetSaga() {
  yield all([takeLatest(CHECKLIST_MY_GET_REQUESTED, getChecklistMy)])
}
