import {
  API_KEY,
  APP_SCOPE,
  SESSION_DOMAIN,
  WEB_TOKEN_COOKIE_NAME
} from 'config/env'
import Cookies from 'js-cookie'
import { camelizeKeys, decamelizeKeys } from 'humps'

import { getCurrentAuthTokenApi } from '@doinn/shared/src/api/auth'
import { open as OpenSubscriptionInvalidDialog } from '@doinn/shared/src/containers/subscription/invalid/actions'

export const camelizeResponseInterceptor = response => {
  response.data = camelizeKeys(response.data)
  return response
}

export const elasticsearchResponseInterceptor = response => {
  const { from, size } = JSON.parse(response.config.data)
  const total = response.data.hits.total.value

  response.data = {
    data: response.data.hits.hits.map(item => item.source),
    meta: {
      pagination: {
        count: response.data.hits.hits.length,
        currentPage: Math.ceil((from + 1) / size),
        links: [],
        perPage: size,
        total: total,
        totalPages: Math.ceil(total / size)
      }
    }
  }

  return response
}

export const decamelizeRequestInterceptor = config => {
  const currentContentType = config.headers['Content-Type']

  // Converts URL get params to underscored
  if (config.params) {
    config.params = decamelizeKeys(config.params)
  }

  if (!currentContentType) {
    config.headers['Content-Type'] = 'application/json;charset=utf-8'
    config.transformRequest = [
      data => {
        if (data) {
          return JSON.stringify(decamelizeKeys(data))
        }

        return data
      }
    ]
  }

  return config
}

export const tokenRequestInterceptor = config => {
  const authToken = getCurrentAuthTokenApi()
  config.headers.Authorization = `Bearer ${authToken}`
  config.headers['api-key'] = `${API_KEY}`
  config.headers.scope = `${APP_SCOPE}`
  return config
}

export const promiseRejectInterceptorError = error => {
  if (error.response) {
    if (error.response.data) {
      camelizeResponseInterceptor(error.response)
    }
    if (error.response.status === 401) {
      window.localStorage.clear()
      window.sessionStorage.clear()
      Cookies.remove(WEB_TOKEN_COOKIE_NAME, {
        path: '/',
        domain: `${SESSION_DOMAIN}`
      })

      const currentPath = window.location.pathname
      const loginPath = '/login'

      if (currentPath !== loginPath) {
        window.location.href = `${window.location.origin}${loginPath}`
      }
    }
  }
  return Promise.reject(error)
}

export const promiseRejectSubscriptionInvalidInterceptorError = (
  error,
  dispatch
) => {
  if (error.response) {
    if (error.response.data) {
      camelizeResponseInterceptor(error.response)
    }
    if (error.response.status === 402) {
      const data = error.response.data.data.errors
      dispatch(OpenSubscriptionInvalidDialog(data))
    }
  }
  return Promise.reject(error)
}
