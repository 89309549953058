import React from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'

import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import TemplateBlankFullHeight from '@doinn/shared/src/components/templates/TemplateBlankFullHeight'

const OnboardingContainer = loadable(() =>
  lazyRetry(() => import('containers/onboarding/Onboarding'))
)

const Onboarding = () => {
  const { t } = useTranslation()

  return (
    <TemplateBlankFullHeight>
      <Helmet>
        <title>{t('Onboarding')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <OnboardingContainer />
      </React.Suspense>
    </TemplateBlankFullHeight>
  )
}

export default Onboarding
