import {
  PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_DIALOG_CLOSE,
  PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_DIALOG_OPEN,
  PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_FAILED,
  PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_REQUESTED,
  PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_SUCCEEDED
} from '@doinn/shared/src/containers/property-instructions/import/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  status: STATUS.IDLE,
  isOpened: false
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_DIALOG_OPEN:
      return {
        ...initialState,
        isOpened: true
      }
    case PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_DIALOG_CLOSE:
      return {
        ...initialState,
        isOpened: false
      }
    case PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING
      }
    case PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case PROPERTY_INSTRUCTIONS_EXCEL_IMPORT_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
