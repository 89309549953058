import {
  INVITATIONS_ANSWER_ACCEPT_FAILED,
  INVITATIONS_ANSWER_ACCEPT_REQUESTED,
  INVITATIONS_ANSWER_ACCEPT_SUCCEEDED,
  INVITATIONS_ANSWER_REFUSE_FAILED,
  INVITATIONS_ANSWER_REFUSE_REQUESTED,
  INVITATIONS_ANSWER_REFUSE_SUCCEEDED
} from '@doinn/vendor/src/containers/member/invitation/answer/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  isOpened: false,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case INVITATIONS_ANSWER_REFUSE_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING
      }
    case INVITATIONS_ANSWER_REFUSE_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case INVITATIONS_ANSWER_REFUSE_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case INVITATIONS_ANSWER_ACCEPT_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING
      }
    case INVITATIONS_ANSWER_ACCEPT_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case INVITATIONS_ANSWER_ACCEPT_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
