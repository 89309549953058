import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchJobServiceExtraItemsApi } from 'api/job-services'
import {
  ORDER_SERVICE_EXTRA_ITEMS_FETCH_FAILED,
  ORDER_SERVICE_EXTRA_ITEMS_FETCH_REQUESTED,
  ORDER_SERVICE_EXTRA_ITEMS_FETCH_SUCCEEDED
} from 'containers/order-services/extra-item/list/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchOrderServiceExtraItems(action) {
  const { job, orderService } = action.payload
  try {
    const response = yield call(fetchJobServiceExtraItemsApi, job, orderService)
    yield put({
      type: ORDER_SERVICE_EXTRA_ITEMS_FETCH_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: ORDER_SERVICE_EXTRA_ITEMS_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* orderServiceExtraItemListSaga() {
  yield all([
    takeLatest(
      ORDER_SERVICE_EXTRA_ITEMS_FETCH_REQUESTED,
      fetchOrderServiceExtraItems
    )
  ])
}
