import { useReducer } from 'react'
import { filter, isEmpty, slice, uniqBy, uniqueId } from 'lodash'

const CLEANUP = 'CLEANUP'
const EMPTY = 'EMPTY'
const LOAD = 'LOAD'
const LOADED = 'LOADED'
const ADD_FILES = 'ADD_FILES'
const REMOVE_FILE = 'REMOVE_FILE'
const SET_LIMIT = 'SET_LIMIT'

export const initialState = {
  files: [],
  limit: 0,
  status: EMPTY
}

const limitFiles = (files, limit) => (limit ? slice(files, 0, limit) : files)

const reducer = (state, action) => {
  switch (action.type) {
    case LOAD:
      return { ...state, files: action.files, status: LOADED }
    case CLEANUP:
      return { ...state, files: [], status: CLEANUP }
    case ADD_FILES: {
      const files = limitFiles(
        uniqBy([...state.files, ...action.files], 'name'),
        state.limit
      )
      return { ...state, files, status: LOADED }
    }
    case REMOVE_FILE: {
      const files = limitFiles(
        filter(state.files, file => file.id !== action.id),
        state.limit
      )
      const status = files.length ? state.status : EMPTY
      return { ...state, files, status }
    }
    case SET_LIMIT:
      return { ...state, limit: action.limit }
    default:
      return state
  }
}

const parseArrayFiles = files => {
  return files.map(file => {
    const src = window.URL.createObjectURL(file)
    return { file, id: uniqueId(), src, name: file.name }
  })
}

const parseEventFiles = e => {
  if (e.target.files.length) {
    const arrFiles = Array.from(e.target.files)
    return parseArrayFiles(arrFiles)
  }
  return []
}

const useUploadFileHandler = (
  initialFiles = initialState.files,
  initialLimit = initialState.limit
) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    files: initialFiles,
    limit: initialLimit
  })

  const onAddFiles = e => {
    const files = parseEventFiles(e)
    if (files.length) {
      dispatch({ type: ADD_FILES, files })
    }
  }

  const onAddFileFromCamera = dataFiles => {
    if (isEmpty(dataFiles)) return

    const arrFiles = Array.from(dataFiles)
    const files = arrFiles.map(data => ({
      file: data.file,
      id: uniqueId(),
      src: data.src,
      name: data.name
    }))
    dispatch({
      type: ADD_FILES,
      files
    })
  }

  const onRemoveFile = id => dispatch({ type: REMOVE_FILE, id })

  const onCleanup = e => dispatch({ type: CLEANUP })

  const onChange = e => {
    const files = parseEventFiles(e)
    if (files.length) {
      setFiles(files)
    }
  }

  const setFiles = files => {
    dispatch({ type: LOAD, files })
  }

  const setLimit = limit => {
    dispatch({ type: SET_LIMIT, limit })
  }

  return {
    ...state,
    onAddFileFromCamera,
    onAddFiles,
    onChange,
    onCleanup,
    onRemoveFile,
    setFiles,
    setLimit
  }
}

export default useUploadFileHandler
