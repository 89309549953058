import {
  MEMBERS_INVITATIONS_RESEND_FAILED,
  MEMBERS_INVITATIONS_RESEND_REQUESTED,
  MEMBERS_INVITATIONS_RESEND_SUCCEEDED
} from '@doinn/vendor/src/containers/member/invitation/resend/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  errorMessage: '',
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case MEMBERS_INVITATIONS_RESEND_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING
      }
    case MEMBERS_INVITATIONS_RESEND_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS
      }
    case MEMBERS_INVITATIONS_RESEND_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
