import {
  CHECKOUT_REMOVE_FAILED,
  CHECKOUT_REMOVE_REQUESTED,
  CHECKOUT_REMOVE_SUCCEEDED
} from '@doinn/vendor/src/containers/checkout/remove/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case CHECKOUT_REMOVE_REQUESTED:
      return {
        ...state,
        status: `${STATUS.LOADING}-${action.payload.params.jobId}`
      }
    case CHECKOUT_REMOVE_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS
      }
    case CHECKOUT_REMOVE_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
