import { all, call, put, takeLatest } from 'redux-saga/effects'
import { deleteAutomationApi } from '@doinn/host/src/api/automations'
import {
  AUTOMATIONS_DELETE_FAILED,
  AUTOMATIONS_DELETE_REQUESTED,
  AUTOMATIONS_DELETE_SUCCEEDED
} from '@doinn/host/src/containers/automations/list/delete/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* deleteAutomation(action) {
  try {
    yield call(deleteAutomationApi, action.payload.params)
    yield put({
      type: AUTOMATIONS_DELETE_SUCCEEDED,
      payload: action.payload
    })
  } catch (e) {
    yield put({
      type: AUTOMATIONS_DELETE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* automationsDeleteSaga() {
  yield all([takeLatest(AUTOMATIONS_DELETE_REQUESTED, deleteAutomation)])
}
