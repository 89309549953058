import {
  JOB_ASSIGN_STAFF_EDIT_FAILED,
  JOB_ASSIGN_STAFF_EDIT_REQUESTED,
  JOB_ASSIGN_STAFF_EDIT_SUCCEEDED,
  JOB_ASSIGN_STAFF_FORM_CLOSE_REQUESTED,
  JOB_ASSIGN_STAFF_FORM_OPEN_REQUESTED,
  JOB_ASSIGN_STAFF_FORM_SEARCH_STAFF_REQUESTED
} from 'containers/job/assign-staff/constants'

export const initialState = {
  isOpened: false,
  isConfirmLoading: false,
  isConfirmFailed: false,
  searchText: ''
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case JOB_ASSIGN_STAFF_FORM_OPEN_REQUESTED:
      return {
        ...initialState,
        isOpened: true
      }
    case JOB_ASSIGN_STAFF_FORM_CLOSE_REQUESTED:
      return {
        ...initialState,
        isOpened: false
      }
    case JOB_ASSIGN_STAFF_FORM_SEARCH_STAFF_REQUESTED:
      return {
        ...state,
        searchText: payload.params
      }
    case JOB_ASSIGN_STAFF_EDIT_REQUESTED:
      return {
        ...state,
        isConfirmLoading: true,
        isConfirmFailed: false
      }
    case JOB_ASSIGN_STAFF_EDIT_SUCCEEDED:
      return {
        ...initialState,
        isOpened: false,
        isConfirmLoading: false,
        isConfirmFailed: false
      }
    case JOB_ASSIGN_STAFF_EDIT_FAILED:
      return {
        ...state,
        isConfirmLoading: false,
        isConfirmFailed: true
      }
    default:
      return state
  }
}
