import {
  PROPERTY_BUSINESS_ACCOUNTS_FETCH_FAILED,
  PROPERTY_BUSINESS_ACCOUNTS_FETCH_REQUESTED,
  PROPERTY_BUSINESS_ACCOUNTS_FETCH_SUCCEEDED
} from '@doinn/shared/src/containers/property-business-accounts/constants'

export const initialState = {
  list: [],
  isLoaded: false,
  isLoading: false,
  isFailed: false
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case PROPERTY_BUSINESS_ACCOUNTS_FETCH_REQUESTED:
      return {
        ...state,
        ...initialState,
        isLoaded: false,
        isLoading: true,
        isFailed: false
      }
    case PROPERTY_BUSINESS_ACCOUNTS_FETCH_SUCCEEDED:
      return {
        ...state,
        list: [...payload.data],
        isLoaded: true,
        isLoading: false,
        isFailed: false
      }
    case PROPERTY_BUSINESS_ACCOUNTS_FETCH_FAILED:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        isFailed: true
      }
    default:
      return state
  }
}
