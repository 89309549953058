export const JOB_GET_REQUESTED = 'JOB_GET_REQUESTED'
export const JOB_GET_SUCCEEDED = 'JOB_GET_SUCCEEDED'
export const JOB_GET_FAILED = 'JOB_GET_FAILED'

export const JOB_EXECUTION_FINISH_SUCCEEDED = 'JOB_EXECUTION_FINISH_SUCCEEDED'
export const JOB_CLEAR_SELECTED_REQUESTED = 'JOB_CLEAR_SELECTED_REQUESTED'

export const JOB_UPDATE_EXECUTION_REQUESTED = 'JOB_UPDATE_EXECUTION_REQUESTED'
export const JOB_UPDATE_EXECUTION_SUCCEEDED = 'JOB_UPDATE_EXECUTION_SUCCEEDED'
export const JOB_UPDATE_EXECUTION_FAILED = 'JOB_UPDATE_EXECUTION_FAILED'

export const JOB_UPDATE_REQUESTED_START_REQUESTED =
  'JOB_UPDATE_REQUESTED_START_REQUESTED'
export const JOB_UPDATE_REQUESTED_START_SUCCEEDED =
  'JOB_UPDATE_REQUESTED_START_SUCCEEDED'
export const JOB_UPDATE_REQUESTED_START_FAILED =
  'JOB_UPDATE_REQUESTED_START_FAILED'

export const JOB_UPDATE_ESTIMATED_START_REQUESTED =
  'JOB_UPDATE_ESTIMATED_START_REQUESTED'
export const JOB_UPDATE_ESTIMATED_START_SUCCEEDED =
  'JOB_UPDATE_ESTIMATED_START_SUCCEEDED'
export const JOB_UPDATE_ESTIMATED_START_FAILED =
  'JOB_UPDATE_ESTIMATED_START_FAILED'
