import { showSnackbar } from '@doinn/shared/src/containers/snackbar/actions'

export default store => next => action => {
  if (action.snackbar) {
    if (action.snackbar.variant === 'error' && !window.navigator.onLine) {
      return next(action)
    }
    if (
      action.snackbar.variant === 'error' &&
      store.getState().subscriptionInvalid.isOpened
    ) {
      return next(action)
    }

    store.dispatch(showSnackbar(action.snackbar))
  }

  return next(action)
}
