import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getHubSpotIdentificationTokenApi } from '@doinn/vendor/src/api/support'
import {
  USER_GET_IDENTIFICATION_TOKEN_FAILED,
  USER_GET_IDENTIFICATION_TOKEN_REQUESTED,
  USER_GET_IDENTIFICATION_TOKEN_SUCCEEDED
} from '@doinn/vendor/src/containers/support/constants'

function* getHubSpotIdentificationToken(action) {
  try {
    const { params } = action.payload
    const response = yield call(getHubSpotIdentificationTokenApi, params)
    const { data } = response.data

    yield put({
      type: USER_GET_IDENTIFICATION_TOKEN_SUCCEEDED,
      payload: { data }
    })
  } catch (e) {
    yield put({
      type: USER_GET_IDENTIFICATION_TOKEN_FAILED
    })
  }
}

export default function* supportSagas() {
  yield all([
    takeLatest(
      USER_GET_IDENTIFICATION_TOKEN_REQUESTED,
      getHubSpotIdentificationToken
    )
  ])
}
