import {
  EMPLOYEE_ADD_EDIT_FORM_CLOSE_REQUESTED,
  EMPLOYEE_ADD_EDIT_FORM_OPEN_REQUESTED,
  EMPLOYEE_ADD_EDIT_FORM_SAVE_FAILED,
  EMPLOYEE_ADD_EDIT_FORM_SAVE_REQUESTED,
  EMPLOYEE_ADD_EDIT_FORM_SAVE_SUCCEEDED
} from '@doinn/vendor/src/containers/employee/add-edit/constants'

export const STATUS = {
  IDLE: 'idle',
  SAVING: 'saving',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  isOpened: false,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case EMPLOYEE_ADD_EDIT_FORM_OPEN_REQUESTED:
      return {
        ...initialState,
        isOpened: true
      }
    case EMPLOYEE_ADD_EDIT_FORM_CLOSE_REQUESTED:
      return {
        ...initialState,
        isOpened: false
      }
    case EMPLOYEE_ADD_EDIT_FORM_SAVE_REQUESTED:
      return {
        ...state,
        status: STATUS.SAVING
      }
    case EMPLOYEE_ADD_EDIT_FORM_SAVE_SUCCEEDED:
      return {
        ...initialState,
        status: STATUS.SUCCESS
      }
    case EMPLOYEE_ADD_EDIT_FORM_SAVE_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
