import React, { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import TemplateDefault from 'components/TemplateDefault'

const PaymentEditContainer = loadable(() =>
  lazyRetry(() =>
    import(
      '@doinn/shared/src/containers/subscription-billing/payment/setup/PaymentSetup'
    )
  )
)

const PaymentEdit = () => {
  const { t } = useTranslation()
  const { gateway, variant } = useParams()

  const getTitle = useCallback(() => {
    let title = t('Add paypal')
    if (!gateway) return title

    if (gateway.toLowerCase() === 'stripe') {
      if (!variant) return t('Add card')
      switch (variant.toLowerCase()) {
        case 'card':
          title = t('Add card')
          break
        case 'sepa':
          title = t('Add SEPA')
          break
        default:
          break
      }
    }

    return title
  }, [gateway, t, variant])

  const pageTitle = getTitle()

  return (
    <TemplateDefault title={pageTitle}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <PaymentEditContainer context={'add'} />
      </React.Suspense>
    </TemplateDefault>
  )
}

export default PaymentEdit
