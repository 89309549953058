import { all, call, put, takeLatest } from 'redux-saga/effects'
import { deleteCustomerInvitationApi } from '@doinn/vendor/src/api/customerInvitation'
import {
  CUSTOMER_INVITATION_DELETE_FAILED,
  CUSTOMER_INVITATION_DELETE_REQUESTED,
  CUSTOMER_INVITATION_DELETE_SUCCEEDED
} from '@doinn/vendor/src/containers/customer/invitation/delete/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* deleteCustomerInvitation(action) {
  try {
    const { customerInvitationId } = action.payload
    yield call(deleteCustomerInvitationApi, customerInvitationId)
    yield put({
      type: CUSTOMER_INVITATION_DELETE_SUCCEEDED,
      payload: action.payload
    })
  } catch (e) {
    yield put({
      type: CUSTOMER_INVITATION_DELETE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* customersInvitationsDeleteSaga() {
  yield all([
    takeLatest(CUSTOMER_INVITATION_DELETE_REQUESTED, deleteCustomerInvitation)
  ])
}
