import {
  REGISTER_FAILED,
  REGISTER_QUESTIONS_SAVE,
  REGISTER_REQUESTED,
  REGISTER_SUCCEEDED
} from '@doinn/vendor/src/containers/app/auth/register/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  status: STATUS.IDLE,
  questions: null
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case REGISTER_QUESTIONS_SAVE:
      return {
        ...initialState,
        questions: { ...payload.params }
      }
    case REGISTER_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING
      }
    case REGISTER_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS
      }
    case REGISTER_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
