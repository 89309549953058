export const REPORTS_BILLING_DETAILS_FETCH_REQUESTED =
  'REPORTS_BILLING_DETAILS_FETCH_REQUESTED'
export const REPORTS_BILLING_DETAILS_FETCH_SUCCEEDED =
  'REPORTS_BILLING_DETAILS_FETCH_SUCCEEDED'
export const REPORTS_BILLING_DETAILS_FETCH_FAILED =
  'REPORTS_BILLING_DETAILS_FETCH_FAILED'

export const REPORTS_BILLING_DETAILS_DOWNLOAD_DETAILS_REQUESTED =
  'REPORTS_BILLING_DETAILS_DOWNLOAD_DETAILS_REQUESTED'
export const REPORTS_BILLING_DETAILS_DOWNLOAD_DETAILS_SUCCEEDED =
  'REPORTS_BILLING_DETAILS_DOWNLOAD_DETAILS_SUCCEEDED'
export const REPORTS_BILLING_DETAILS_DOWNLOAD_DETAILS_FAILED =
  'REPORTS_BILLING_DETAILS_DOWNLOAD_DETAILS_FAILED'
