export const SUBSCRIPTION_PLANS_FETCH_REQUESTED =
  'SUBSCRIPTION_PLANS_FETCH_REQUESTED'
export const SUBSCRIPTION_PLANS_FETCH_SUCCEEDED =
  'SUBSCRIPTION_PLANS_FETCH_SUCCEEDED'
export const SUBSCRIPTION_PLANS_FETCH_FAILED = 'SUBSCRIPTION_PLANS_FETCH_FAILED'

export const SUBSCRIPTION_ENTERPRISE_QUOTE_REQUESTED =
  'SUBSCRIPTION_ENTERPRISE_QUOTE_REQUESTED'
export const SUBSCRIPTION_ENTERPRISE_QUOTE_SUCCEEDED =
  'SUBSCRIPTION_ENTERPRISE_QUOTE_SUCCEEDED'
export const SUBSCRIPTION_ENTERPRISE_QUOTE_FAILED =
  'SUBSCRIPTION_ENTERPRISE_QUOTE_FAILED'
