import React from 'react'
import { map } from 'lodash'
import { Divider, MenuItem, makeStyles } from '@material-ui/core'
import { APP_NAME, SESSION_DOMAIN } from 'config/env'
import { getSwitchableApps } from '@doinn/shared/src/components/app/SwitchApp/utils'
import SwitchAppButton from '@doinn/shared/src/components/app/SwitchApp/SwitchAppButton'

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

const SwitchAppMenu = ({ loggedUser }) => {
  const classes = useStyles()
  const appSlug = APP_NAME.replace('@doinn/', '')
  const switchableAppList = getSwitchableApps(
    loggedUser,
    appSlug,
    SESSION_DOMAIN,
    loggedUser.roles
  )
  const { isDoinn } = loggedUser.business

  if (!switchableAppList.length) {
    return null
  }

  return (
    <>
      <Divider className={classes.divider} />
      {map(switchableAppList, switchableApp => (
        <SwitchAppButton
          key={switchableApp.app}
          switchableApp={switchableApp}
          Component={MenuItem}
          isDoinn={isDoinn}
        />
      ))}
      <Divider className={classes.divider} />
    </>
  )
}

export default SwitchAppMenu
