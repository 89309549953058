import React, { useCallback } from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withEcho } from '@doinn/shared/src/hoc/echo'
import { usePrevious, useWillUnmount } from '@doinn/shared/src/hooks'
import { ECHO_EVENT_CHAT_UNREAD_MESSAGES_COUNTER } from '@doinn/shared/src/hoc/echo/constants'
import { updateUnreadMessagesCounter } from '@doinn/shared/src/containers/chats/actions'

const UnreadMessagesCounterListener = ({
  echoUserChannel,
  updateUnreadMessagesCounter
}) => {
  const [isListening, setIsListening] = React.useState(false)
  const previousEchoUserChannel = usePrevious(echoUserChannel)

  const handleEchoUpdates = useCallback(() => {
    if (echoUserChannel && !isListening) {
      echoUserChannel.listen(ECHO_EVENT_CHAT_UNREAD_MESSAGES_COUNTER, data => {
        const counter = get(data, 'data.counter', 0)
        const totalNotifications = counter > 0 ? counter : 0
        updateUnreadMessagesCounter(totalNotifications)
      })

      setIsListening(true)
    }
  }, [
    echoUserChannel,
    isListening,
    updateUnreadMessagesCounter,
    setIsListening
  ])

  const stopListeningEchoUpdate = useCallback(
    echoChannel => {
      if (echoChannel && isListening) {
        echoChannel.stopListening(ECHO_EVENT_CHAT_UNREAD_MESSAGES_COUNTER)
        setIsListening(false)
      }
    },
    [isListening, setIsListening]
  )

  React.useEffect(() => {
    handleEchoUpdates()
  }, [handleEchoUpdates])

  React.useEffect(() => {
    if (previousEchoUserChannel && !echoUserChannel) {
      stopListeningEchoUpdate(previousEchoUserChannel)
    }
  }, [previousEchoUserChannel, echoUserChannel, stopListeningEchoUpdate])

  useWillUnmount(() => {
    stopListeningEchoUpdate(echoUserChannel)
  })

  return null
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  updateUnreadMessagesCounter: bindActionCreators(
    updateUnreadMessagesCounter,
    dispatch
  )
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withEcho
)(UnreadMessagesCounterListener)
