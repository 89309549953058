import {
  UPDATE_REQUEST_APPROVE_FAILED,
  UPDATE_REQUEST_APPROVE_REQUESTED,
  UPDATE_REQUEST_APPROVE_SUCCEEDED,
  UPDATE_REQUEST_DIALOG_CLOSE,
  UPDATE_REQUEST_DIALOG_OPEN,
  UPDATE_REQUEST_REFUSE_FAILED,
  UPDATE_REQUEST_REFUSE_REQUESTED,
  UPDATE_REQUEST_REFUSE_SUCCEEDED
} from '@doinn/vendor/src/containers/job/update-request/constants'

export const STATUS = {
  IDLE: 'idle',
  APPROVE: 'approve',
  REFUSE: 'refuse',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const TYPE = {
  READ: 'read',
  WRITE: 'write'
}

export const ANSWER = {
  REFUSE: 'refuse'
}

export const initialState = {
  isOpened: false,
  status: STATUS.IDLE,
  id: null,
  type: TYPE.READ,
  answer: null
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case UPDATE_REQUEST_DIALOG_OPEN:
      return {
        ...initialState,
        isOpened: true,
        ...payload.params
      }
    case UPDATE_REQUEST_DIALOG_CLOSE:
      return {
        ...initialState,
        isOpened: false
      }
    case UPDATE_REQUEST_APPROVE_REQUESTED:
      return {
        ...state,
        status: STATUS.APPROVE
      }
    case UPDATE_REQUEST_APPROVE_SUCCEEDED:
      return {
        ...initialState,
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case UPDATE_REQUEST_APPROVE_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case UPDATE_REQUEST_REFUSE_REQUESTED:
      return {
        ...state,
        status: STATUS.REFUSE
      }
    case UPDATE_REQUEST_REFUSE_SUCCEEDED:
      return {
        ...initialState,
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case UPDATE_REQUEST_REFUSE_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
