import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getImportResultsApi } from '@doinn/shared/src/api/import'
import {
  IMPORT_GET_FAILED,
  IMPORT_GET_REQUESTED,
  IMPORT_GET_SUCCEEDED
} from '@doinn/shared/src/containers/import/get/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchImportResults(action) {
  try {
    const response = yield call(getImportResultsApi, action.payload.params)
    const { data } = response.data
    yield put({
      type: IMPORT_GET_SUCCEEDED,
      payload: {
        data
      }
    })
  } catch (e) {
    yield put({
      type: IMPORT_GET_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* importGetSaga() {
  yield all([takeLatest(IMPORT_GET_REQUESTED, fetchImportResults)])
}
