import {
  MEMBER_CLEAR_SELECTED_REQUESTED,
  MEMBER_GET_FAILED,
  MEMBER_GET_REQUESTED,
  MEMBER_GET_SUCCEEDED
} from '@doinn/vendor/src/containers/member/get/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  item: null,
  isLoading: false,
  isFailed: false,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case MEMBER_GET_REQUESTED:
      return {
        ...state,
        ...initialState,
        isLoading: true,
        isFailed: false,
        status: STATUS.LOADING
      }
    case MEMBER_GET_SUCCEEDED:
      return {
        ...state,
        item: { ...payload.data },
        isLoading: false,
        isFailed: false,
        status: STATUS.SUCCESS
      }
    case MEMBER_GET_FAILED:
      return {
        ...state,
        isLoading: false,
        isFailed: true,
        status: STATUS.FAILURE
      }
    case MEMBER_CLEAR_SELECTED_REQUESTED:
      return {
        ...initialState
      }
    default:
      return state
  }
}
