import {
  GET_CATALOGUE_PROPERTY_OPTIONAL_ITEMS_FAILED,
  GET_CATALOGUE_PROPERTY_OPTIONAL_ITEMS_REQUESTED,
  GET_CATALOGUE_PROPERTY_OPTIONAL_ITEMS_SUCCEEDED
} from '@doinn/shared/src/containers/catalogue/property-optional-items/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  status: STATUS.IDLE,
  list: []
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case GET_CATALOGUE_PROPERTY_OPTIONAL_ITEMS_REQUESTED:
      return {
        ...initialState,
        status: STATUS.LOADING
      }
    case GET_CATALOGUE_PROPERTY_OPTIONAL_ITEMS_SUCCEEDED:
      return {
        ...state,
        list: action.payload,
        status: STATUS.SUCCESS
      }
    case GET_CATALOGUE_PROPERTY_OPTIONAL_ITEMS_FAILED:
      return {
        ...initialState,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
