import {
  STRIPE_KYC_CLOSE,
  STRIPE_KYC_OPEN
} from '@doinn/vendor/src/containers/stripe-kyc/constants'

export function open(params) {
  return { type: STRIPE_KYC_OPEN, payload: { params } }
}

export function close(params) {
  return { type: STRIPE_KYC_CLOSE, payload: { params } }
}
