import { fade } from '@material-ui/core/styles'

export default theme => ({
  text: {
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    margin: '0 auto',
    width: '80%'
  },
  form: {
    maxWidth: 500,
    width: '100%'
  },
  error: {
    color: theme.palette.error.main
  },
  link: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
    padding: theme.spacing(0),
    marginTop: theme.spacing(-0.5),
    minWidth: 'auto'
  },
  title: {
    fontSize: theme.typography.pxToRem(36),
    lineHeight: 'normal'
  },
  full: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: '100vh'
  },
  logoWrapper: {
    marginBottom: theme.spacing(3),
    textAlign: 'center'
  },
  logo: {
    height: 'auto',
    width: '160px'
  },
  input: {
    marginTop: 0,
    marginBottom: 0
  },
  previousButton: {
    position: 'absolute',
    left: theme.spacing(2)
  },
  iconWrapper: {
    color: theme.palette.primary.main,
    background: fade(theme.palette.primary.main, 0.1)
  },
  icon: {
    width: '28px',
    height: '24px'
  }
})
