import { get } from 'lodash'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchMembersApi } from '@doinn/vendor/src/api/members'
import {
  MEMBERS_FETCH_FAILED,
  MEMBERS_FETCH_REQUESTED,
  MEMBERS_FETCH_SUCCEEDED
} from '@doinn/vendor/src/containers/member/list/constants'
import { EMPLOYEE_ADD_EDIT_FORM_SAVE_SUCCEEDED } from '@doinn/vendor/src/containers/employee/add-edit/constants'
import { EMPLOYEES_DELETE_SUCCEEDED } from '@doinn/vendor/src/containers/staff/employees/delete/constants'
import { MEMBERS_DELETE_SUCCEEDED } from '@doinn/shared/src/containers/members/delete/constants'
import { fetchMembers as fetchMembersAction } from '@doinn/vendor/src/containers/member/list/actions'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* resetMembers() {
  yield put(fetchMembersAction())
}

function* fetchMembers(action) {
  try {
    const params = get(action, 'payload.params', {})
    const response = yield call(fetchMembersApi, params)
    yield put({
      type: MEMBERS_FETCH_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: MEMBERS_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* employeesSaga() {
  yield all([
    takeLatest(MEMBERS_FETCH_REQUESTED, fetchMembers),
    takeLatest(EMPLOYEE_ADD_EDIT_FORM_SAVE_SUCCEEDED, resetMembers),
    takeLatest(EMPLOYEES_DELETE_SUCCEEDED, resetMembers),
    takeLatest(MEMBERS_DELETE_SUCCEEDED, resetMembers)
  ])
}
