import {
  SUBSCRIPTION_INVALID_DIALOG_CLOSE,
  SUBSCRIPTION_INVALID_DIALOG_OPEN
} from '@doinn/shared/src/containers/subscription/invalid/constants'

export function open(params) {
  return {
    type: SUBSCRIPTION_INVALID_DIALOG_OPEN,
    payload: { params }
  }
}

export function close(params) {
  return {
    type: SUBSCRIPTION_INVALID_DIALOG_CLOSE,
    payload: { params }
  }
}
