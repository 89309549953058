export const RESERVATION_SYNC_DIALOG_OPEN = 'RESERVATION_SYNC_DIALOG_OPEN'
export const RESERVATION_SYNC_DIALOG_CLOSE = 'RESERVATION_SYNC_DIALOG_CLOSE'

export const RESERVATION_SYNC_REQUESTED = 'RESERVATION_SYNC_REQUESTED'
export const RESERVATION_SYNC_SUCCEEDED = 'RESERVATION_SYNC_SUCCEEDED'
export const RESERVATION_SYNC_FAILED = 'RESERVATION_SYNC_FAILED'

export const RESERVATION_SYNC_STARTED = 'RESERVATION_SYNC_STARTED'
export const RESERVATION_SYNC_COMPLETED = 'RESERVATION_SYNC_COMPLETED'
export const RESERVATION_SYNC_CLEAR = 'RESERVATION_SYNC_CLEAR'
