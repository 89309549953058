import Cookies from 'js-cookie'
import { SESSION_DOMAIN } from 'config/env'
import i18n from '@doinn/shared/src/config/i18n'

const LOGGED_USER_COOKIE = 'loggedUser'

export const getRoleTranslation = role => {
  const rolesTranslation = {
    host: i18n.t('Manager'),
    vendor: i18n.t('Coordinator'),
    business_owner: i18n.t('Admin'),
    employee: i18n.t('Employee'),
    supervisor: i18n.t('Supervisor')
  }
  return rolesTranslation[role] ?? role
}

export const setLoggedUserCookie = user => {
  const { id, name } = user

  if (!Cookies.get(LOGGED_USER_COOKIE)) {
    Cookies.set(
      LOGGED_USER_COOKIE,
      { id, name },
      {
        domain: SESSION_DOMAIN,
        expires: 10
      }
    )
  }
}

export const removeLoggedUserCookie = () => {
  Cookies.remove(LOGGED_USER_COOKIE, { domain: SESSION_DOMAIN })
}
