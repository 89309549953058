import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import NetworkConnectWarning from '@doinn/shared/src/components/network-connection/Warning'
import UserLoggedInAsWarning from '@doinn/shared/src/containers/user-logged-in-as/Warning'

const useStyles = makeStyles(theme => ({
  alertContainer: {
    padding: theme.spacing(0, 2),
    margin: theme.spacing(0, -2),
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    zIndex: theme.zIndex.appBar,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 3),
      margin: theme.spacing(0, -3)
    }
  },
  header: {
    // keep sync with 'src/config/material-ui-theme/mixins.js' dimensions
    top: theme.spacing(8),
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      top: theme.spacing(6)
    },
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(10)
    }
  },
  top: {
    top: 0
  },
  bottom: {
    bottom: 0
  },
  alert: ({ isBannerVisible }) => ({
    alignItems: 'center',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1, 2),
    marginTop: isBannerVisible ? theme.spacing(10) : theme.spacing(3),
    '&:first-child': {
      marginTop: isBannerVisible ? theme.spacing(10) : theme.spacing(3)
    },
    '&:last-child': {
      marginBottom: theme.spacing(3)
    }
  })
}))

const Alerts = ({ baseUrl, header, position, isBannerVisible }) => {
  const classes = useStyles({ isBannerVisible })

  return (
    <div
      className={clsx(classes.alertContainer, {
        [classes.bottom]: position === 'bottom',
        [classes.top]: position === 'top',
        [classes.header]: header
      })}
    >
      <UserLoggedInAsWarning baseUrl={baseUrl} className={classes.alert} />
      <NetworkConnectWarning className={classes.alert} />
    </div>
  )
}

Alerts.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  header: PropTypes.bool,
  position: PropTypes.oneOf(['auto', 'top', 'bottom']),
  isBannerVisible: PropTypes.bool
}

Alerts.defaultProps = {
  header: false,
  position: 'auto',
  isBannerVisible: false
}

export default Alerts
