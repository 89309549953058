import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchPropertyCitiesApi } from '@doinn/shared/src/api/property-cities'
import {
  PROPERTY_CITIES_FETCH_FAILED,
  PROPERTY_CITIES_FETCH_REQUESTED,
  PROPERTY_CITIES_FETCH_SUCCEEDED
} from '@doinn/shared/src/containers/property-cities/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchPropertyCitiesSaga(action) {
  try {
    const response = yield call(fetchPropertyCitiesApi)
    yield put({
      type: PROPERTY_CITIES_FETCH_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: PROPERTY_CITIES_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* propertyBusinessAccountsSaga() {
  yield all([
    takeLatest(PROPERTY_CITIES_FETCH_REQUESTED, fetchPropertyCitiesSaga)
  ])
}
