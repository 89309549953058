import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { parseISO as parseISODate } from 'date-fns'
import {
  formatSystemDate,
  locales,
  parseFormatStr,
  parseLocale
} from '@doinn/shared/src/util/date-fns'
import DateRangePicker from '@doinn/shared/src/components/common/pickers/DateRangePicker'

const DateRangeTextField = ({
  isOpened,
  startValue,
  endValue,
  onChange,
  onOpen,
  onClose,
  isRange = true
}) => {
  const { t } = useTranslation()
  const locale = locales[parseLocale()]

  const format = parseFormatStr('date.short')

  let startDate = null
  try {
    startDate = `${formatSystemDate(startValue)}T00:00`
  } catch (e) {}

  let endDate = null
  try {
    endDate = `${formatSystemDate(endValue)}T00:00`
  } catch (e) {}

  const rangeValue = [parseISODate(startDate), parseISODate(endDate)]

  const handleChangeRange = values => {
    onChange &&
      onChange('range', [
        formatSystemDate(values.begin),
        formatSystemDate(values.end)
      ])
  }

  return (
    <Box px={2} pb={1}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
        <DateRangePicker
          isRange={isRange}
          open={isOpened}
          value={rangeValue}
          format={format}
          placeholder={t('Period')}
          label={t('Period')}
          cancelLabel={t('Cancel')}
          okLabel={t('Apply')}
          fullWidth
          onChange={handleChangeRange}
          onOpen={onOpen}
          onClose={onClose}
        />
      </MuiPickersUtilsProvider>
    </Box>
  )
}

DateRangeTextField.defaultProps = {
  startValue: null,
  endValue: null
}

export default DateRangeTextField
