import React, { useCallback } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import ListFilters from 'components/reports/billing/ListFilters'
import { addOrRemove } from '@doinn/shared/src/util/array'

const ListFiltersContent = ({
  filters,
  businessIntentions,
  onChangeFilters
}) => {
  const handleChangeFilter = useCallback(
    (filterName, newValue) => {
      const oldValue = filters[filterName]
      const parsedNewValue = Array.isArray(oldValue)
        ? addOrRemove(oldValue, newValue)
        : newValue
      const params = {
        ...filters
        // page: 1 // Reset to first page but allow pagination
      }
      params[filterName] = parsedNewValue
      onChangeFilters && onChangeFilters(params)
    },
    [filters, onChangeFilters]
  )

  const filtersProps = {
    filters,
    onChange: handleChangeFilter,
    onChangeFilters: onChangeFilters
  }

  return (
    <ListFilters {...filtersProps} businessIntentions={businessIntentions} />
  )
}

const mapStateToProps = state => {
  return {
    businessIntentions: state?.user?.loggedUser?.business?.intentions
  }
}

const mapDispatchToProps = dispatch => ({})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ListFiltersContent
)
