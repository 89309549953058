import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { Box, Button, Typography, makeStyles } from '@material-ui/core'

import useAppDetector from '@doinn/shared/src/hooks/useAppDetector'
import { close as closeInvalidDialog } from '@doinn/shared/src/containers/subscription/invalid/actions'
import DialogHeader from '@doinn/shared/src/components/common/dialog/DialogHeader'

const useStyles = makeStyles(theme => {
  return {
    header: {
      padding: theme.spacing(0)
    },
    title: {
      fontSize: theme.typography.pxToRem(36)
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(3)
    }
  }
})

const SubscriptionStatusFeatureBlocked = ({ isBusinessOwner, onClick }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const isApp = useAppDetector()

  const ctaProps = useMemo(() => {
    if (!isBusinessOwner) {
      return {}
    }
    return isApp ? { target: '_blank', href: '/plans' } : { onClick: onClick }
  }, [isApp, isBusinessOwner, onClick])

  const handleCloseClick = useCallback(() => {
    dispatch(closeInvalidDialog())
  }, [dispatch])

  return (
    <>
      <DialogHeader
        classname={classes.header}
        title={
          <Box className={classes.header}>
            <Typography
              component='div'
              align='center'
              className={classes.title}
            >
              <Box sx={{ lineHeight: 'normal' }}>
                <b>{t('Upgrade your plan to access this feature')}</b>
              </Box>
            </Typography>
          </Box>
        }
        onClose={handleCloseClick}
      />
      <Box pt={2}>
        <Typography component='div' variant='body1'>
          <Box sx={{ lineHeight: 'normal' }}>
            <Trans defaults='Want to continue using our all-in-one property operation platform to increase your productivity? <br/>There are a variety of plans to fit any of your needs' />
          </Box>
        </Typography>
      </Box>
      <Box className={classes.actions}>
        {isBusinessOwner && (
          <>
            <Box pl={1} />
            <Button
              aria-label={t('See plans')}
              variant='contained'
              color='primary'
              {...ctaProps}
            >
              {t('See plans')}
            </Button>
          </>
        )}
      </Box>
    </>
  )
}

export default SubscriptionStatusFeatureBlocked
