import { all, call, put, takeLatest } from 'redux-saga/effects'
import { addChecklistMyApi } from '@doinn/host/src/api/checklists'
import {
  CHECKLIST_COPY_FAILED,
  CHECKLIST_COPY_REQUESTED,
  CHECKLIST_COPY_SUCCEEDED
} from '@doinn/host/src/containers/checklist/copy/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import i18n from '@doinn/shared/src/config/i18n'
import { fetchChecklists as fetchChecklistsAction } from '@doinn/host/src/containers/checklist/my/list/actions'

function* copyChecklist(action) {
  try {
    const { params } = action.payload
    yield call(addChecklistMyApi, params)
    yield put({
      type: CHECKLIST_COPY_SUCCEEDED,
      payload: params,
      snackbar: {
        variant: 'success',
        message: i18n.t(`"{{name}}" has been created!`, { name: params.name })
      }
    })
  } catch (e) {
    yield put({
      type: CHECKLIST_COPY_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* resetChecklists() {
  yield put(fetchChecklistsAction())
}

export default function* checklistsAddSaga() {
  yield all([
    takeLatest(CHECKLIST_COPY_REQUESTED, copyChecklist),
    takeLatest(CHECKLIST_COPY_SUCCEEDED, resetChecklists)
  ])
}
