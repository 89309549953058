import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import DateRange from '@doinn/shared/src/components/common/filter/DateRange'

const FilterDateRange = ({
  filters,
  onChangeFilters,
  periodOptions,
  variant
}) => {
  const { t } = useTranslation()
  const [parsedOptions, setParsedOptions] = useState([])

  const getPeriodOptions = useCallback(() => {
    let options = ['month_to_today', 'last_month', 'custom']
    const defaultPeriodOptions = [
      {
        id: 'before_yesterday',
        name: t('Before yesterday')
      },
      {
        id: 'yesterday',
        name: t('Yesterday')
      },
      {
        id: 'today',
        name: t('Today')
      },
      {
        id: 'tomorrow',
        name: t('Tomorrow')
      },
      {
        id: 'after_tomorrow',
        name: t('After tomorrow')
      },
      {
        id: 'month_to_today',
        name: t('Current month')
      },
      {
        id: 'current_month',
        name: t('Current month')
      },
      {
        id: 'last_month',
        name: t('Last month')
      },
      {
        id: 'next_month',
        name: t('Next month')
      },
      {
        id: 'custom',
        name: t('Custom')
      }
    ]

    if (periodOptions && !isEmpty(periodOptions)) {
      options = periodOptions
    }

    const parsedOpts = options.map(parsed => {
      const index = defaultPeriodOptions.findIndex(
        option => option.id === parsed
      )
      return defaultPeriodOptions[index]
    })

    setParsedOptions(parsedOpts)
  }, [t, periodOptions])

  React.useEffect(() => {
    getPeriodOptions()
  }, [getPeriodOptions])

  return (
    <DateRange
      selectedPeriod={filters.period}
      start={filters.start}
      end={filters.end}
      periodOptions={parsedOptions}
      variant={variant}
      onChange={onChangeFilters}
    />
  )
}

FilterDateRange.propTypes = {
  variant: PropTypes.oneOf(['list', 'menu']),
  periodOptions: PropTypes.array,
  filters: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    period: PropTypes.string
  }),
  onChangeFilters: PropTypes.func
}

FilterDateRange.defaultProps = {
  variant: 'menu'
}

export default FilterDateRange
