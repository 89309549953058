import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function fetchIntegrationsApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/host/business-integrations`, config)
}

export function getIntegrationApi({ id }) {
  const config = {}
  return DoinnApi.get(`/host/business-integrations/${id}`, config)
}

export function fetchIntegrationsCatalogApi(params = {}) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/host/integrations-catalog`, config)
}

export function addIntegrationApi({ slug, name, fields }) {
  const config = {}
  const data = { integration: slug, name, fields }
  return DoinnApi.post(`/host/business-integrations`, data, config)
}

export function activateIntegrationApi({ id }) {
  const config = {}
  return DoinnApi.put(`/host/business-integrations/${id}/activate`, {}, config)
}

export function deactivateIntegrationApi({ id, option = false }) {
  const config = {}
  return DoinnApi.put(
    `/host/business-integrations/${id}/deactivate`,
    { option },
    config
  )
}

export function deleteIntegrationApi({
  id,
  deleteFutureServices,
  disableSpaces
}) {
  const config = {
    params: {
      id,
      deleteFutureServices: deleteFutureServices ? 1 : 0,
      disableSpaces: disableSpaces ? 1 : 0
    }
  }
  return DoinnApi.delete(`/host/business-integrations/${id}`, config)
}

export function renameIntegrationApi({ integration, name }) {
  const data = { name }
  return DoinnApi.patch(
    `/host/business-integrations/${integration.id}/rename`,
    data
  )
}

export function editIntegrationConfigurationApi({
  id,
  selectedProperties,
  defaultPropertiesEnabled
}) {
  const config = {}
  const data = {
    id,
    spaces: selectedProperties,
    defaultSpaceEnabled: defaultPropertiesEnabled
  }
  return DoinnApi.patch(
    `/host/business-integrations/${id}/configuration`,
    data,
    config
  )
}

export function editIntegrationCredentialsApi({ integration, fields }) {
  const data = { integration, fields }
  return DoinnApi.patch(
    `/host/business-integrations/${integration.id}/credentials`,
    data
  )
}
