export const ONBOARDING_SERVICES_FETCH_REQUESTED =
  'ONBOARDING_SERVICES_FETCH_REQUESTED'
export const ONBOARDING_SERVICES_FETCH_SUCCEEDED =
  'ONBOARDING_SERVICES_FETCH_SUCCEEDED'
export const ONBOARDING_SERVICES_FETCH_FAILED =
  'ONBOARDING_SERVICES_FETCH_FAILED'

export const ONBOARDING_SET_SERVICES = 'ONBOARDING_SET_SERVICES'

export const ONBOARDING_TOGGLE_SERVICE = 'ONBOARDING_TOGGLE_SERVICE'
