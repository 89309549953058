export const CHAT_MESSAGES_FETCH_REQUESTED = 'CHAT_MESSAGES_FETCH_REQUESTED'
export const CHAT_MESSAGES_FETCH_SUCCEEDED = 'CHAT_MESSAGES_FETCH_SUCCEEDED'
export const CHAT_MESSAGES_FETCH_FAILED = 'CHAT_MESSAGES_FETCH_FAILED'

export const CHAT_MESSAGES_FETCH_MORE_REQUESTED =
  'CHAT_MESSAGES_FETCH_MORE_REQUESTED'
export const CHAT_MESSAGES_FETCH_MORE_SUCCEEDED =
  'CHAT_MESSAGES_FETCH_MORE_SUCCEEDED'
export const CHAT_MESSAGES_FETCH_MORE_FAILED = 'CHAT_MESSAGES_FETCH_MORE_FAILED'

export const CHAT_MESSAGES_SELECTED_CHAT_UPDATED =
  'CHAT_MESSAGES_SELECTED_CHAT_UPDATED'

export const CHAT_MESSAGES_SEND_REQUESTED = 'CHAT_MESSAGES_SEND_REQUESTED'
export const CHAT_MESSAGES_SEND_SUCCEEDED = 'CHAT_MESSAGES_SEND_SUCCEEDED'
export const CHAT_MESSAGES_SEND_FAILED = 'CHAT_MESSAGES_SEND_FAILED'

export const CHAT_MESSAGES_ATTACHMENTS_SEND_REQUESTED =
  'CHAT_MESSAGES_ATTACHMENTS_SEND_REQUESTED'
export const CHAT_MESSAGES_ATTACHMENTS_SEND_SUCCEEDED =
  'CHAT_MESSAGES_ATTACHMENTS_SEND_SUCCEEDED'
export const CHAT_MESSAGES_ATTACHMENTS_SEND_FAILED =
  'CHAT_MESSAGES_ATTACHMENTS_SEND_FAILED'

export const CHAT_MESSAGES_RESEND_REQUESTED = 'CHAT_MESSAGES_RESEND_REQUESTED'

export const CHAT_MESSAGES_NEW_MESSAGE_RECEIVED =
  'CHAT_MESSAGES_NEW_MESSAGE_RECEIVED'
export const CHAT_MESSAGES_NEW_MESSAGE_ATTACHMENT_RECEIVED =
  'CHAT_MESSAGES_NEW_MESSAGE_ATTACHMENT_RECEIVED'

export const CHAT_MESSAGES_READ_NEW_MESSAGES = 'CHAT_MESSAGES_READ_NEW_MESSAGES'

export const CHAT_MESSAGE_DIALOG_PHOTOS_OPENED =
  'CHAT_MESSAGE_DIALOG_PHOTOS_OPENED'

export const CHAT_MESSAGE_TYPE_JOBS = 'jobs'
export const CHAT_MESSAGE_TYPE_MESSAGE = 'message'
export const CHAT_MESSAGE_TYPE_PHOTOS = 'photos'
export const CHAT_MESSAGE_TYPE_SYSTEM = 'system'
export const CHAT_MESSAGE_TYPE_PROPERTIES = 'properties'
export const CHAT_MESSAGE_TYPE_EVALUATION = 'evaluation'

export const CHAT_MESSAGES_MARK_AS_READ_REQUESTED =
  'CHAT_MESSAGES_MARK_AS_READ_REQUESTED'
export const CHAT_MESSAGES_MARK_AS_READ_SUCCEEDED =
  'CHAT_MESSAGES_MARK_AS_READ_SUCCEEDED'
export const CHAT_MESSAGES_MARK_AS_READ_FAILED =
  'CHAT_MESSAGES_MARK_AS_READ_FAILED'

export const CHAT_MESSAGE_STATUS_SENDING = 'CHAT_MESSAGE_STATUS_SENDING'
export const CHAT_MESSAGE_STATUS_SENT = 'CHAT_MESSAGE_STATUS_SENT'
export const CHAT_MESSAGE_STATUS_FAILED = 'CHAT_MESSAGE_STATUS_FAILED'
