import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Box, Typography, makeStyles } from '@material-ui/core'

import LoginContainer from '@doinn/shared/src/containers/auth/Login'
import TemplateAuth from '@doinn/shared/src/components/templates/TemplateAuth'

const useStyles = makeStyles(theme => {
  return {
    text: {
      color: theme.palette.common.white
    }
  }
})

const Login = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <TemplateAuth
      drawerChildren={
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          className={classes.text}
          textAlign='center'
        >
          <Typography component='div' variant='subtitle1'>
            <Box pb={1} sx={{ lineHeight: 'normal' }}>
              <b>{t('Log in to your account')}</b>
            </Box>
          </Typography>
          <Typography component='div' variant='body2'>
            <Box sx={{ lineHeight: 'normal' }}>
              {t('Please enter your credentials to continue')}
            </Box>
          </Typography>
        </Box>
      }
    >
      <Helmet>
        <title>{t('Sign in')}</title>
      </Helmet>
      <LoginContainer />
    </TemplateAuth>
  )
}

export default Login
