import {
  JOB_ASSIGN_STAFF_EDIT_REQUESTED,
  JOB_ASSIGN_STAFF_FORM_CLOSE_REQUESTED,
  JOB_ASSIGN_STAFF_FORM_OPEN_REQUESTED,
  JOB_ASSIGN_STAFF_FORM_SEARCH_STAFF_REQUESTED
} from '@doinn/vendor/src/containers/job/assign-staff/constants'

export function openDialog() {
  return { type: JOB_ASSIGN_STAFF_FORM_OPEN_REQUESTED }
}

export function searchStaff(params) {
  return {
    type: JOB_ASSIGN_STAFF_FORM_SEARCH_STAFF_REQUESTED,
    payload: { params }
  }
}

export function closeDialog(params) {
  return { type: JOB_ASSIGN_STAFF_FORM_CLOSE_REQUESTED, payload: { params } }
}

export function editStaff(params) {
  return { type: JOB_ASSIGN_STAFF_EDIT_REQUESTED, payload: { params } }
}
