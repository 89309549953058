import {
  JOB_EDIT_ITEMS_REQUEST_DIALOG_CLOSE,
  JOB_EDIT_ITEMS_REQUEST_DIALOG_OPEN,
  JOB_EDIT_ITEMS_REQUEST_FAILED,
  JOB_EDIT_ITEMS_REQUEST_REQUESTED,
  JOB_EDIT_ITEMS_REQUEST_SUCCEEDED
} from '@doinn/vendor/src/containers/job/edit-items-request/constants'

export const STATUS = {
  IDLE: 'idle',
  SAVING: 'saving',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  isOpened: false,
  disabled: false,
  status: STATUS.IDLE,
  newItems: [],
  originalItems: [],
  orderService: {},
  newService: null
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case JOB_EDIT_ITEMS_REQUEST_DIALOG_OPEN:
      return {
        ...initialState,
        isOpened: true,
        ...(payload?.params ? payload.params : {})
      }
    case JOB_EDIT_ITEMS_REQUEST_DIALOG_CLOSE:
      return {
        ...state,
        isOpened: false,
        newItems: [],
        originalItems: [],
        orderService: {},
        newService: null
      }
    case JOB_EDIT_ITEMS_REQUEST_REQUESTED:
      return {
        ...state,
        status: STATUS.SAVING
      }
    case JOB_EDIT_ITEMS_REQUEST_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case JOB_EDIT_ITEMS_REQUEST_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
