import {
  PROPERTY_DELETE_CONFIRM_CLOSE,
  PROPERTY_DELETE_CONFIRM_OPEN,
  PROPERTY_DELETE_FAILED,
  PROPERTY_DELETE_REQUESTED,
  PROPERTY_DELETE_SUCCEEDED
} from '@doinn/shared/src/containers/property/delete/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  isOpened: false,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case PROPERTY_DELETE_CONFIRM_OPEN:
      return {
        ...initialState,
        isOpened: true
      }
    case PROPERTY_DELETE_CONFIRM_CLOSE:
      return {
        ...initialState,
        isOpened: false
      }
    case PROPERTY_DELETE_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING
      }
    case PROPERTY_DELETE_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case PROPERTY_DELETE_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
