import i18n from '@doinn/shared/src/config/i18n'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { importMembersApi } from '@doinn/vendor/src/api/membersInvitations'
import { MEMBERS_FETCH_REQUESTED } from '@doinn/vendor/src/containers/member/list/constants'
import { EMPLOYEES_FETCH_REQUESTED } from '@doinn/vendor/src/containers/staff/employees/constants'
import {
  MEMBERS_IMPORT_FAILED,
  MEMBERS_IMPORT_REQUESTED,
  MEMBERS_IMPORT_SUCCEEDED
} from './constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* importMembers(action) {
  try {
    const { file } = action.payload.params
    const response = yield call(importMembersApi, {
      file: file
    })
    const responseData = response.data.data
    yield put({
      type: MEMBERS_IMPORT_SUCCEEDED,
      payload: responseData,
      snackbar: {
        variant: 'success',
        message: i18n.t('Members have been imported successfully!')
      }
    })
    yield put({ type: MEMBERS_FETCH_REQUESTED, payload: {} })
    yield put({ type: EMPLOYEES_FETCH_REQUESTED, payload: {} })
  } catch (e) {
    if (e?.response?.status === 422) {
      yield put({
        type: MEMBERS_IMPORT_FAILED,
        payload: {
          error: {
            type: 'file',
            data: e.response.data.data
          }
        }
      })
    } else {
      yield put({
        type: MEMBERS_IMPORT_FAILED,
        payload: {
          error: {
            type: 'other'
          }
        },
        snackbar: {
          variant: 'error',
          message: getSnackbarErrorMessage(e)
        }
      })
    }
  }
}

export default function* membersImportSaga() {
  yield all([takeLatest(MEMBERS_IMPORT_REQUESTED, importMembers)])
}
