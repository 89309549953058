import { useEffect } from 'react'
import { usePrevious } from '@doinn/shared/src/hooks'

/**
 * @param effect Imperative function that can return a cleanup function
 * @param dep If present, effect will only activate if the value change to true.
 */
const useJustSucceededEffect = (effect, dep) => {
  if (!effect || typeof effect !== 'function') {
    throw new Error(
      'useJustSucceededEffect hook expects a effect function as a param'
    )
  }

  const previousDep = usePrevious(dep)

  useEffect(() => {
    if (previousDep === undefined) return
    const justSucceeded = !previousDep && dep
    if (justSucceeded) {
      return effect()
    }
  }, [effect, dep, previousDep])
}

export default useJustSucceededEffect
