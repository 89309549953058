import { createSearchAction } from 'redux-search'
import {
  MEMBERS_FETCH_REQUESTED,
  MEMBERS_LIST_UPDATE_SELECTED
} from '@doinn/vendor/src/containers/member/list/constants'

export function fetchMembers(params = {}) {
  return { type: MEMBERS_FETCH_REQUESTED, payload: { params } }
}

export function updateSelectedMembers(members) {
  return {
    type: MEMBERS_LIST_UPDATE_SELECTED,
    payload: [...members]
  }
}

export const searchMembers = createSearchAction('memberList')
