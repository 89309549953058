import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function addNoteApi(
  job,
  jobService,
  { category, note: text, photos = [] }
) {
  const request = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  const formData = new window.FormData()

  formData.append('note', text)

  if (category) {
    formData.append('category', category)
  }

  photos.forEach((photo, index) =>
    formData.append(`photos[${index}]`, photo.file)
  )

  return DoinnApi.post(
    `/jobs/${job.id}/job-services/${jobService.id}/notes`,
    formData,
    request
  )
}
