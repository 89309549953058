import { createSelector } from 'reselect'
import { APP_SCOPE } from 'config/env'

const getLoggedUser = state => state.user.loggedUser

export const getLoggedUserPermissions = createSelector(
  [getLoggedUser],
  loggedUser => {
    try {
      return loggedUser.permissions
        .reduce((carry, permission) => [...carry, permission.name], [])
        .filter(permission => {
          // filter user allowed abilities and permissions
          const isHost = APP_SCOPE === 'host'
          const isSaas = loggedUser?.business?.isSaas
          const isDoinn = loggedUser?.business?.isDoinn
          if (isHost && isDoinn && !isSaas) {
            return ![
              'create-jobs',
              'read-checklists',
              'create-checklists',
              'update-checklists',
              'delete-checklists',
              'read-integrations',
              'create-user-integrations',
              'update-user-integrations',
              'delete-user-integrations',
              'read-automations',
              'update-automations',
              'delete-automations',
              'read-catalogs',
              'create-catalogs',
              'update-catalogs',
              'delete-catalogs',
              'read-catalog-items',
              'create-catalog-items',
              'update-catalog-items',
              'delete-catalog-items'
            ].includes(permission)
          }
          return true
        })
    } catch (e) {}
    return []
  }
)
