import { all, call, put, takeLatest } from 'redux-saga/effects'
import { get } from 'lodash'
import i18n from '@doinn/shared/src/config/i18n'
import { resetPasswordApi } from '@doinn/shared/src/api/auth'
import {
  AUTH_RESET_PASSWORD_FAILED,
  AUTH_RESET_PASSWORD_REQUESTED,
  AUTH_RESET_PASSWORD_SUCCEEDED
} from '@doinn/shared/src/containers/auth/reset-password/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* resetPassword(action) {
  try {
    const { params } = action.payload
    const response = yield call(resetPasswordApi, params)
    const success = get(response, 'data.data.success')

    if (success) {
      yield put({
        type: AUTH_RESET_PASSWORD_SUCCEEDED,
        snackbar: {
          variant: 'success',
          message: i18n.t('Password reset with success!')
        }
      })
    }
  } catch (e) {
    yield put({
      type: AUTH_RESET_PASSWORD_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* resetPasswordSagas() {
  yield all([takeLatest(AUTH_RESET_PASSWORD_REQUESTED, resetPassword)])
}
