import {
  NEW_PASSWORD_DIALOG_CLOSE,
  NEW_PASSWORD_DIALOG_OPEN,
  NEW_PASSWORD_FAILED,
  NEW_PASSWORD_REQUESTED,
  NEW_PASSWORD_SUCCEEDED
} from '@doinn/shared/src/containers/new-password/constants'
import { USER_GET_SUCCEEDED } from '@doinn/shared/src/containers/user/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  isOpened: false,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case USER_GET_SUCCEEDED:
      return {
        ...initialState,
        isOpened: payload?.data?.forceResetPassword
      }
    case NEW_PASSWORD_DIALOG_OPEN:
      return {
        ...initialState,
        isOpened: true
      }
    case NEW_PASSWORD_DIALOG_CLOSE:
      return {
        ...initialState,
        isOpened: false
      }
    case NEW_PASSWORD_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING
      }
    case NEW_PASSWORD_SUCCEEDED:
      return {
        ...state,
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case NEW_PASSWORD_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
