import {
  JOB_DETAILS_CLOSE_REQUESTED,
  JOB_DETAILS_OPEN_REQUESTED
} from 'containers/job/details/constants'

export const initialState = {
  isOpened: false
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case JOB_DETAILS_OPEN_REQUESTED:
      return {
        ...initialState,
        isOpened: true
      }
    case JOB_DETAILS_CLOSE_REQUESTED:
      return {
        ...initialState,
        isOpened: false
      }
    default:
      return state
  }
}
