import { isEmpty } from 'lodash'
import {
  BANNER_SLUG,
  getBannerSlugs,
  getSubscriptionSlugs
} from '@doinn/shared/src/containers/banner/util'
import { USER_GET_SUCCEEDED } from '@doinn/shared/src/containers/user/constants'
import { GATEWAYS_GET_METHODS_SUCCEEDED } from '@doinn/shared/src/containers/gateways/constants'
import { PAYMENT_METHODS_DEFAULT_UPDATE_SUCCEEDED } from '@doinn/shared/src/containers/subscription-billing/payment/default/constants'

export const initialState = {
  slugs: [],
  show: false
}

const getSlugs = (state, payload) => {
  const slugs = getBannerSlugs(payload?.data?.business)
  return {
    ...state,
    slugs,
    show: !isEmpty(slugs)
  }
}

const getSlugsBySubscription = (state, payload) => {
  let slugs = getSubscriptionSlugs(payload?.data)

  if (state.slugs?.includes(BANNER_SLUG.trial)) {
    slugs = [...slugs, BANNER_SLUG.trial]
  }

  return {
    ...state,
    slugs,
    show: !isEmpty(slugs)
  }
}

const getSlugsWhenDefaultMethodUpdated = (state, payload) => {
  const paymentMethod = payload?.data
  let slugs = []

  if (!paymentMethod?.usage?.includes('subscription')) {
    return state
  }

  slugs = getSubscriptionSlugs([paymentMethod])

  if (state.slugs?.includes(BANNER_SLUG.trial)) {
    slugs = [...slugs, BANNER_SLUG.trial]
  }

  return {
    ...state,
    slugs,
    show: !isEmpty(slugs)
  }
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case USER_GET_SUCCEEDED:
      return getSlugs(state, payload)
    case GATEWAYS_GET_METHODS_SUCCEEDED:
      return getSlugsBySubscription(state, payload)
    case PAYMENT_METHODS_DEFAULT_UPDATE_SUCCEEDED:
      return getSlugsWhenDefaultMethodUpdated(state, payload)
    default:
      return state
  }
}
