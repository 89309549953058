import React from 'react'
import { Helmet } from 'react-helmet'
import loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'

import { lazyRetry } from '@doinn/shared/src/util/lazyImport'
import TemplateDefault from 'components/TemplateDefault'
import HelperCard from '@doinn/shared/src/components/common/helper/HelperCard'

const ChecklistsContainer = loadable(() =>
  lazyRetry(() => import('@doinn/host/src/containers/checklist/Checklists'))
)

const Checklists = () => {
  const { t } = useTranslation()
  return (
    <TemplateDefault title={t('Checklists')} helperContent={<HelperCard />}>
      <Helmet>
        <title>{t('Checklists')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <ChecklistsContainer />
      </React.Suspense>
    </TemplateDefault>
  )
}

export default Checklists
