import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchPropertiesApi } from '@doinn/shared/src/api/properties'
import {
  PROPERTIES_FETCH_FAILED,
  PROPERTIES_FETCH_MORE_FAILED,
  PROPERTIES_FETCH_MORE_REQUESTED,
  PROPERTIES_FETCH_MORE_SUCCEEDED,
  PROPERTIES_FETCH_REQUESTED,
  PROPERTIES_FETCH_SUCCEEDED
} from '@doinn/shared/src/containers/property/list/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchProperties(action) {
  try {
    const response = yield call(fetchPropertiesApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: PROPERTIES_FETCH_SUCCEEDED,
      payload: {
        data,
        meta
      }
    })
  } catch (e) {
    yield put({
      type: PROPERTIES_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* fetchMoreProperties(action) {
  try {
    const response = yield call(fetchPropertiesApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: PROPERTIES_FETCH_MORE_SUCCEEDED,
      payload: { data, meta }
    })
  } catch (e) {
    yield put({
      type: PROPERTIES_FETCH_MORE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* propertiesSaga() {
  yield all([
    takeLatest(PROPERTIES_FETCH_REQUESTED, fetchProperties),
    takeLatest(PROPERTIES_FETCH_MORE_REQUESTED, fetchMoreProperties)
  ])
}
