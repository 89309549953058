import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Button,
  DialogActions,
  DialogContent,
  makeStyles
} from '@material-ui/core'
import Dialog from '@doinn/shared/src/components/common/dialog/Dialog'
import DialogHeader from '@doinn/shared/src/components/common/dialog/DialogHeader'

const useStyles = makeStyles(theme => {
  return {
    filtersContent: {
      padding: 0
    }
  }
})

const FiltersDialog = ({
  children,
  open,
  title,
  onClear,
  onClose,
  onConfirm
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  if (!children) return null

  const parsedTitle = title || t('Filters')

  return (
    <Dialog open={open} keepMounted onClose={onClose}>
      <DialogHeader title={parsedTitle} onClose={onClose} />
      <DialogContent dividers className={classes.filtersContent}>
        {open ? children : null}
      </DialogContent>
      <DialogActions>
        {onClear ? (
          <Button variant='outlined' onClick={onClear}>
            {t('Clear filters')}
          </Button>
        ) : null}
        <Button
          variant='contained'
          color='primary'
          edge='end'
          onClick={onConfirm}
        >
          {t('Apply')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

FiltersDialog.propTypes = {
  children: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string,
  onClear: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
}

FiltersDialog.defaultProps = {
  open: false
}

export default FiltersDialog
