import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchChecklistsMyApi } from '@doinn/host/src/api/checklists'
import {
  CHECKLISTS_MY_LIST_FETCH_FAILED,
  CHECKLISTS_MY_LIST_FETCH_MORE_FAILED,
  CHECKLISTS_MY_LIST_FETCH_MORE_REQUESTED,
  CHECKLISTS_MY_LIST_FETCH_MORE_SUCCEEDED,
  CHECKLISTS_MY_LIST_FETCH_REQUESTED,
  CHECKLISTS_MY_LIST_FETCH_SUCCEEDED
} from '@doinn/host/src/containers/checklist/my/list/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import { CHECKLIST_MY_ADD_SUCCEEDED } from '@doinn/host/src/containers/checklist/my/add/constants'
import { CHECKLIST_MY_DELETE_SUCCEEDED } from '@doinn/host/src/containers/checklist/my/delete/constants'
import { CHECKLIST_MY_EDIT_SUCCEEDED } from '@doinn/host/src/containers/checklist/my/edit/constants'
import { CHECKLIST_MY_IMPORT_SUCCEEDED } from '@doinn/host/src/containers/checklist/my/import/constants'
import { fetchChecklists as fetchChecklistsAction } from '@doinn/host/src/containers/checklist/my/list/actions'

function* resetChecklists() {
  yield put(fetchChecklistsAction())
}

function* fetchChecklists(action) {
  try {
    const response = yield call(fetchChecklistsMyApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: CHECKLISTS_MY_LIST_FETCH_SUCCEEDED,
      payload: {
        data,
        meta
      }
    })
  } catch (e) {
    yield put({
      type: CHECKLISTS_MY_LIST_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* fetchMoreChecklists(action) {
  try {
    const response = yield call(fetchChecklistsMyApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: CHECKLISTS_MY_LIST_FETCH_MORE_SUCCEEDED,
      payload: { data, meta }
    })
  } catch (e) {
    yield put({
      type: CHECKLISTS_MY_LIST_FETCH_MORE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* checklistsSaga() {
  yield all([
    takeLatest(CHECKLIST_MY_ADD_SUCCEEDED, resetChecklists),
    takeLatest(CHECKLIST_MY_IMPORT_SUCCEEDED, resetChecklists),
    takeLatest(CHECKLIST_MY_EDIT_SUCCEEDED, resetChecklists),
    takeLatest(CHECKLIST_MY_DELETE_SUCCEEDED, resetChecklists),
    takeLatest(CHECKLISTS_MY_LIST_FETCH_REQUESTED, fetchChecklists),
    takeLatest(CHECKLISTS_MY_LIST_FETCH_MORE_REQUESTED, fetchMoreChecklists)
  ])
}
