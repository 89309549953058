import { all, call, put, takeLatest } from 'redux-saga/effects'
import { addAutomationApi } from '@doinn/host/src/api/automations'
import {
  AUTOMATION_ADD_FAILED,
  AUTOMATION_ADD_REQUESTED,
  AUTOMATION_ADD_SUCCEEDED
} from '@doinn/host/src/containers/automations/add/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

export function* addAutomation(action) {
  try {
    const response = yield call(addAutomationApi, action.payload.params)
    yield put({
      type: AUTOMATION_ADD_SUCCEEDED,
      payload: response.data.data
    })
  } catch (e) {
    yield put({
      type: AUTOMATION_ADD_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* automationsAddSaga() {
  yield all([takeLatest(AUTOMATION_ADD_REQUESTED, addAutomation)])
}
