import {
  DASHLET_SUMMARY_SERVICES_FETCH_FAILED,
  DASHLET_SUMMARY_SERVICES_FETCH_REQUESTED,
  DASHLET_SUMMARY_SERVICES_FETCH_SUCCEEDED
} from 'containers/dashboard/dashlets/summary-services/constants'

export const initialState = {
  data: [],
  isLoaded: false,
  isLoading: false,
  isFailed: false
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case DASHLET_SUMMARY_SERVICES_FETCH_REQUESTED:
      return {
        ...state,
        ...initialState,
        isLoaded: false,
        isLoading: true,
        isFailed: false
      }
    case DASHLET_SUMMARY_SERVICES_FETCH_SUCCEEDED:
      return {
        ...state,
        data: [...payload.data],
        isLoaded: true,
        isLoading: false,
        isFailed: false
      }
    case DASHLET_SUMMARY_SERVICES_FETCH_FAILED:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        isFailed: true
      }
    default:
      return state
  }
}
