// @flow
import React from 'react'
import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import LoadingText from '@doinn/shared/src/components/common/loading/LoadingText'

export type Props = {
  avatar: boolean,
  primary: boolean,
  secondary: boolean,
  listItemProps?: null
}

const LoadingListItem = (props: Props) => {
  const textProps = {
    component: 'div'
  }

  return (
    <ListItem component='li' {...props.listItemProps}>
      {props.avatar && (
        <ListItemAvatar>
          <Skeleton variant='circle' width={40} height={40} />
        </ListItemAvatar>
      )}
      {(props.primary || props.secondary) && (
        <ListItemText
          primary={
            props.primary && <LoadingText textVariant='body1' width='80%' />
          }
          primaryTypographyProps={textProps}
          secondary={
            props.secondary && <LoadingText textVariant='body2' width='60%' />
          }
          secondaryTypographyProps={textProps}
        />
      )}
    </ListItem>
  )
}

LoadingListItem.defaultProps = {
  avatar: false,
  primary: false,
  secondary: false
}

export default LoadingListItem
