import {
  STRIPE_KYC_CLOSE,
  STRIPE_KYC_OPEN
} from '@doinn/vendor/src/containers/stripe-kyc/constants'

export const initialState = {
  isOpened: false
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case STRIPE_KYC_CLOSE:
      return {
        isOpened: false
      }
    case STRIPE_KYC_OPEN:
      return {
        isOpened: true
      }
    default:
      return state
  }
}
