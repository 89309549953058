import {
  INITIAL_STATE_CHANGE,
  NEXT_STEP,
  OPTIONS_CHANGE,
  PREVIOUS_STEP
} from './constants'

export const defaultState = {
  step: 0,
  numberOfSteps: 0,
  options: [],
  activeOptions: [],
  initialValues: {}
}

const getActiveOptions = (options, step) =>
  options.filter(options => options.step === step)

const stepIncrement = (state, step) => {
  const newStep = step >= state.numberOfSteps ? state.numberOfSteps : step
  return {
    ...state,
    step: newStep,
    activeOptions: getActiveOptions(state.options, newStep)
  }
}

const stepDecrement = (state, step) => {
  const newStep = step >= state.numberOfSteps ? state.numberOfSteps : step
  return {
    ...state,
    step: newStep,
    activeOptions: getActiveOptions(state.options, newStep)
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case INITIAL_STATE_CHANGE: {
      const { initialState } = action.payload
      return {
        ...defaultState,
        ...initialState,
        step: initialState?.initialValues?.step ?? 0,
        activeOptions: getActiveOptions(
          initialState.options,
          initialState?.initialValues?.step ?? 0
        )
      }
    }
    case NEXT_STEP:
      return stepIncrement(state, state.step + 1)
    case PREVIOUS_STEP:
      return stepDecrement(state, state.step - 1)
    case OPTIONS_CHANGE:
      return {
        ...state,
        options: action.payload.options,
        numberOfSteps: action.payload.numberOfSteps
      }
    default:
      return state
  }
}
