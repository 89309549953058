import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { DialogContent, makeStyles } from '@material-ui/core'

import { ERROR_SLUG } from '@doinn/shared/src/containers/subscription/invalid/reducers'
import Dialog from '@doinn/shared/src/components/common/dialog/Dialog'
import SubscriptionStatusInvalid from '@doinn/shared/src/containers/subscription/invalid/components/SubscriptionStatusInvalid'
import SubscriptionStatusTrialEnded from '@doinn/shared/src/containers/subscription/invalid/components/SubscriptionStatusTrialEnded'
import SubscriptionStatusFeatureBlocked from '@doinn/shared/src/containers/subscription/invalid/components/SubscriptionStatusFeatureBlocked'

const useStyles = makeStyles(theme => {
  return {
    dialogContent: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(0),
      textAlign: 'center',

      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }
    }
  }
})

const SubscriptionInvalidDialog = () => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { isOpened, errorSlug } = useSelector(
    state => state.subscriptionInvalid
  )
  const { loggedUser } = useSelector(state => state.user)

  const isBusinessOwner = useMemo(
    () => loggedUser?.roles.includes('business_owner') ?? false,
    [loggedUser?.roles]
  )
  const opened = useMemo(
    () => isOpened && !location.pathname.includes('/plans'),
    [isOpened, location.pathname]
  )

  const handleCtaPlansClick = useCallback(() => {
    history.push({
      pathname: '/plans',
      state: {
        closePath: `${location.pathname}${location.search}`
      }
    })
  }, [history, location?.pathname, location?.search])

  const handleClose = useCallback((event, reason) => {
    if (reason && reason === 'backdropClick') {
      event.preventDefault()
    }
  }, [])

  const renderContent = useCallback(() => {
    switch (errorSlug) {
      case ERROR_SLUG.trial_ended:
        return (
          <SubscriptionStatusTrialEnded
            loggedUser={loggedUser}
            isBusinessOwner={isBusinessOwner}
            onClick={handleCtaPlansClick}
          />
        )
      case ERROR_SLUG.invalid_subscription:
        return (
          <SubscriptionStatusInvalid
            loggedUser={loggedUser}
            isBusinessOwner={isBusinessOwner}
            onClick={handleCtaPlansClick}
          />
        )
      case ERROR_SLUG.feature_blacklisted:
        return (
          <SubscriptionStatusFeatureBlocked
            loggedUser={loggedUser}
            isBusinessOwner={isBusinessOwner}
            onClick={handleCtaPlansClick}
          />
        )
      default:
        return (
          <SubscriptionStatusTrialEnded
            loggedUser={loggedUser}
            isBusinessOwner={isBusinessOwner}
            onClick={handleCtaPlansClick}
          />
        )
    }
  }, [errorSlug, handleCtaPlansClick, isBusinessOwner, loggedUser])

  return (
    <Dialog open={opened} onClose={handleClose} disableEscapeKeyDown>
      <DialogContent className={classes.dialogContent}>
        {renderContent()}
      </DialogContent>
    </Dialog>
  )
}

export default SubscriptionInvalidDialog
