import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function getJobChecklistApi({ jobId, userRole = 'vendor' }) {
  const config = {}
  if (userRole === 'host') {
    return DoinnApi.get(`/host/jobs/${jobId}/checklist`, config)
  }
  return DoinnApi.get(`/vendor/jobs/${jobId}/checklist`, config)
}

export function getJobHistoryApi({ jobId }) {
  const config = {}
  return DoinnApi.get(`/vendor/jobs/${jobId}/history`, config)
}
