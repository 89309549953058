export const ONBOARDING_SET_CURRENT_STEP = 'ONBOARDING_SET_CURRENT_STEP'
export const ONBOARDING_PREVIOUS_STEP = 'ONBOARDING_PREVIOUS_STEP'
export const ONBOARDING_RESET_STEPPER = 'ONBOARDING_RESET_STEPPER'

export const ONBOARDING_NEXT_STEP_REQUESTED = 'ONBOARDING_NEXT_STEP_REQUESTED'
export const ONBOARDING_NEXT_STEP_SUCCEEDED = 'ONBOARDING_NEXT_STEP_SUCCEEDED'
export const ONBOARDING_NEXT_STEP_FAILED = 'ONBOARDING_NEXT_STEP_FAILED'

export const ONBOARDING_GET_STEPS_REQUESTED = 'ONBOARDING_GET_STEPS_REQUESTED'
export const ONBOARDING_GET_STEPS_SUCCEEDED = 'ONBOARDING_GET_STEPS_SUCCEEDED'
export const ONBOARDING_GET_STEPS_FAILED = 'ONBOARDING_GET_STEPS_FAILED'

export const ONBOARDING_FINISH_REQUESTED = 'ONBOARDING_FINISH_REQUESTED'
export const ONBOARDING_FINISH_SUCCEEDED = 'ONBOARDING_FINISH_SUCCEEDED'
export const ONBOARDING_FINISH_FAILED = 'ONBOARDING_FINISH_FAILED'
