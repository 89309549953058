import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  deleteJobServiceApi,
  fetchJobServiceDetailsApi
} from 'api/job-services'
import { getJobApi } from 'api/job'
import i18n from '@doinn/shared/src/config/i18n'
import {
  JOB_SERVICE_DELETE_CONFIRM_OPEN,
  JOB_SERVICE_DELETE_FAILED,
  JOB_SERVICE_DELETE_REQUESTED,
  JOB_SERVICE_DELETE_SUCCEEDED,
  JOB_SERVICE_DETAILS_FAILED,
  JOB_SERVICE_DETAILS_SUCCEEDED
} from '@doinn/vendor/src/containers/job/delete/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* getJobServiceDetails(action) {
  try {
    const { job, orderService } = action.payload.params
    const response = yield call(fetchJobServiceDetailsApi, job, orderService)
    yield put({
      type: JOB_SERVICE_DETAILS_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: JOB_SERVICE_DETAILS_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* deleteJobService(action) {
  try {
    const { job, orderService } = action.payload
    const response = yield call(deleteJobServiceApi, job, orderService)

    if (response.data.data.success) {
      const responseUpdatedJob = yield call(getJobApi, job.id)
      const { data: updatedJob } = responseUpdatedJob.data
      yield put({
        type: JOB_SERVICE_DELETE_SUCCEEDED,
        payload: {
          job: updatedJob
        },
        snackbar: {
          variant: 'success',
          message: i18n.t('The services have been canceled successfully!')
        }
      })
    }
  } catch (e) {
    yield put({
      type: JOB_SERVICE_DELETE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* orderServiceDeleteSaga() {
  yield all([
    takeLatest(JOB_SERVICE_DELETE_CONFIRM_OPEN, getJobServiceDetails),
    takeLatest(JOB_SERVICE_DELETE_REQUESTED, deleteJobService)
  ])
}
