import { all, call, put, takeLatest } from 'redux-saga/effects'
import { deleteMemberApi } from '@doinn/shared/src/api/members'
import {
  MEMBERS_DELETE_FAILED,
  MEMBERS_DELETE_REQUESTED,
  MEMBERS_DELETE_SUCCEEDED
} from '@doinn/shared/src/containers/members/delete/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* deleteMember(action) {
  try {
    const { memberId } = action.payload
    yield call(deleteMemberApi, memberId)
    yield put({
      type: MEMBERS_DELETE_SUCCEEDED,
      payload: action.payload
    })
  } catch (e) {
    yield put({
      type: MEMBERS_DELETE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* membersDeleteSaga() {
  yield all([takeLatest(MEMBERS_DELETE_REQUESTED, deleteMember)])
}
