import convertToCamelCaseKeys from '@doinn/shared/src/util/convertToCamelCaseKeys'
import {
  JOB_CLEAR_SELECTED_REQUESTED,
  JOB_GET_REQUESTED,
  JOB_UPDATE_ESTIMATED_START_REQUESTED,
  JOB_UPDATE_EXECUTION_REQUESTED,
  JOB_UPDATE_EXECUTION_SUCCEEDED,
  JOB_UPDATE_REQUESTED_START_REQUESTED
} from 'containers/job/constants'

export function getJob(params) {
  return { type: JOB_GET_REQUESTED, payload: { params } }
}

export function clearJob() {
  return { type: JOB_CLEAR_SELECTED_REQUESTED }
}

export function updateJobExecution(id, action) {
  return { type: JOB_UPDATE_EXECUTION_REQUESTED, payload: { id, action } }
}

export function updateJobExecutionWithData(data) {
  return {
    type: JOB_UPDATE_EXECUTION_SUCCEEDED,
    payload: {
      data: convertToCamelCaseKeys(data.data)
    }
  }
}

export function updateJobRequestedStart(
  job,
  datetime,
  datetimeEnd = null,
  timeWindowId = null
) {
  return {
    type: JOB_UPDATE_REQUESTED_START_REQUESTED,
    payload: {
      job,
      datetime,
      datetimeEnd,
      timeWindowId
    }
  }
}

export function updateJobEstimatedStart({ jobId, scheduleStartTime }) {
  return {
    type: JOB_UPDATE_ESTIMATED_START_REQUESTED,
    payload: {
      params: {
        jobId,
        scheduleStartTime
      }
    }
  }
}
