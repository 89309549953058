import { map, pick } from 'lodash'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import citiesSagas from 'containers/onboarding/steps/Cities/sagas'
import cityServicesSagas from 'containers/onboarding/steps/CityServices/sagas'
import countrySagas from 'containers/onboarding/steps/Country/sagas'
import documentsSagas from 'containers/onboarding/steps/Documents/sagas'
import pricesSagas from 'containers/onboarding/steps/Prices/sagas'
import propertyTypesSagas from 'containers/onboarding/steps/PropertyTypes/sagas'
import servicesSagas from 'containers/onboarding/steps/Services/sagas'
import termsSagas from 'containers/onboarding/steps/Terms/sagas'
import {
  finishOnboardingApi,
  getOnboardingStepsApi,
  updateOnboardingStepsApi
} from 'api/onboarding'
import {
  ONBOARDING_FINISH_FAILED,
  ONBOARDING_FINISH_REQUESTED,
  ONBOARDING_FINISH_SUCCEEDED,
  ONBOARDING_GET_STEPS_FAILED,
  ONBOARDING_GET_STEPS_REQUESTED,
  ONBOARDING_GET_STEPS_SUCCEEDED,
  ONBOARDING_NEXT_STEP_FAILED,
  ONBOARDING_NEXT_STEP_REQUESTED,
  ONBOARDING_NEXT_STEP_SUCCEEDED
} from 'containers/onboarding/constants'
import {
  getOnboardingCurrentStep as getOnboardingCurrentStepSelector,
  getOnboardingSteps as getOnboardingStepsSelector
} from 'containers/onboarding/selectors'
import { getLoggedUser } from '@doinn/shared/src/containers/user/actions'
import { acceptTermsConditions } from 'containers/onboarding/steps/Terms/actions'

function* goToNextStep() {
  try {
    const steps = yield select(getOnboardingStepsSelector)
    const currentStep = yield select(getOnboardingCurrentStepSelector)
    const mappedSteps = map(steps, step =>
      pick(step, ['completed', 'data', 'slug'])
    )
    mappedSteps[currentStep].completed = true
    const response = yield call(updateOnboardingStepsApi, mappedSteps)
    const remoteSteps = response.data.data

    yield put({
      type: ONBOARDING_NEXT_STEP_SUCCEEDED,
      payload: { steps: remoteSteps }
    })
  } catch (e) {
    yield put({ type: ONBOARDING_NEXT_STEP_FAILED })
  }
}

function* getOnboardingSteps() {
  try {
    const response = yield call(getOnboardingStepsApi)
    const steps = response.data.data

    yield put({
      type: ONBOARDING_GET_STEPS_SUCCEEDED,
      payload: { steps }
    })
  } catch (e) {
    yield put({ type: ONBOARDING_GET_STEPS_FAILED })
  }
}

function* finishOnboarding() {
  try {
    const responseFinish = yield call(finishOnboardingApi)
    if (responseFinish.data.data.success) {
      yield put(acceptTermsConditions()) // TODO: potential bug when parallel with next step
      yield put({ type: ONBOARDING_NEXT_STEP_REQUESTED }) // TODO: potential bug when parallel with accept terms
      yield put({ type: ONBOARDING_FINISH_SUCCEEDED })
      yield put(getLoggedUser())
    }
  } catch (e) {
    yield put({ type: ONBOARDING_FINISH_FAILED })
  }
}

export default function* onboardingSaga() {
  yield all([
    citiesSagas(),
    cityServicesSagas(),
    countrySagas(),
    documentsSagas(),
    pricesSagas(),
    propertyTypesSagas(),
    servicesSagas(),
    termsSagas(),
    takeLatest(ONBOARDING_NEXT_STEP_REQUESTED, goToNextStep),
    takeLatest(ONBOARDING_GET_STEPS_REQUESTED, getOnboardingSteps),
    takeLatest(ONBOARDING_FINISH_REQUESTED, finishOnboarding)
  ])
}
