import { all, call, put, takeLatest } from 'redux-saga/effects'
import { editIntegrationConfigurationApi } from '@doinn/host/src/api/integrations'
import {
  INTEGRATION_EDIT_FAILED,
  INTEGRATION_EDIT_REQUESTED,
  INTEGRATION_EDIT_SUCCEEDED
} from '@doinn/host/src/containers/integrations/edit/configuration/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* editIntegration(action) {
  try {
    const { id, selectedProperties, defaultPropertiesEnabled } = action.payload
    const response = yield call(editIntegrationConfigurationApi, {
      id,
      selectedProperties,
      defaultPropertiesEnabled
    })

    yield put({
      type: INTEGRATION_EDIT_SUCCEEDED,
      payload: response.data.data
    })
  } catch (e) {
    yield put({
      type: INTEGRATION_EDIT_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* integrationsEditSaga() {
  yield all([takeLatest(INTEGRATION_EDIT_REQUESTED, editIntegration)])
}
