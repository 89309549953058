import React from 'react'
import PropTypes from 'prop-types'

const Address = ({ address, city, complement, region, country, zipcode }) => {
  return (
    <React.Fragment>
      {address && `${address}`}
      {complement && ` - ${complement}`}
      {zipcode && `, ${zipcode}`}
      {city && ` ${city.name}`}
      {region && ` ${city.region}`}
      {country && `, ${country.name}`}
    </React.Fragment>
  )
}

Address.propTypes = {
  address: PropTypes.string,
  complement: PropTypes.string,
  zipcode: PropTypes.string,
  city: PropTypes.shape({
    name: PropTypes.string.isRequired,
    region: PropTypes.string
  }),
  country: PropTypes.shape({
    name: PropTypes.string.isRequired
  })
}

export default React.memo(Address)
