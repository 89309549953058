import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function getProfileDetailsApi() {
  const config = {}
  return DoinnApi.get(`/profile/details`, config)
}

export function deleteNotificationEmailApi(id) {
  const config = {}
  return DoinnApi.delete(`/users/notification-emails/${id}`, config)
}

export function createNotificationEmailApi(email) {
  const config = {}
  return DoinnApi.post(`/users/notification-emails`, { email }, config)
}

export function updateUserPasswordApi(payload) {
  const config = {}
  return DoinnApi.post(`/users/password`, payload, config)
}

export function updateProfileDetailsApi(payload) {
  const config = {}
  return DoinnApi.patch(`/profile`, payload, config)
}

export function updateProfileTranslationLanguageApi(translationLanguage) {
  const config = {}
  return DoinnApi.patch(
    `/profile/translation-language`,
    { translationLanguage },
    config
  )
}
