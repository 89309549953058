import DoinnApi, { DoinnBlobApi } from '@doinn/shared/src/config/doinnApi'

export function fetchPropertyInstructionsApi(params = {}) {
  const { flags, userRole, ...otherParams } = params
  const config = {
    params: otherParams
  }
  const parsedFlags = flags ? `?${flags.join('&')}` : ''
  return DoinnApi.get(`/properties/instructions${parsedFlags}`, config)
}

export function downloadPropertiesInstructionsExcelApi() {
  const config = {}
  return DoinnBlobApi.get(`/properties/instructions/excel`, config)
}

export function uploadPropertyInstructionsExcelApi(file) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  const formData = new window.FormData()
  formData.append('file', file)
  return DoinnApi.post(`/properties/instructions/excel`, formData, config)
}
